<div class="menu-wrapper" (click)="close()">
  <div class="side-menu" (click)="$event.stopPropagation()">
    <!--
      *
      *   Header
      *
    -->
    <header>
      <div (click)="close()" class="close-button cursor-pointer">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.6">
            <path d="M11.9963 7.99585L8.00293 11.9892" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M11.9974 11.9916L8.00073 7.99414" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M13.612 2.29199H6.38783C3.87033 2.29199 2.29199 4.07449 2.29199 6.59699V13.4037C2.29199 15.9262 3.86283 17.7087 6.38783 17.7087H13.6112C16.137 17.7087 17.7087 15.9262 17.7087 13.4037V6.59699C17.7087 4.07449 16.137 2.29199 13.612 2.29199Z"
              stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </svg>
        <span transloco="close"></span>
      </div>

      <div class="user">
        <p>
          <span class="username">{{ userData?.username }}</span>
          <span class="user-id">#{{ userData?.id }}</span>
        </p>
      </div>
    </header>

    <!--
      *
      *   Balance
      *
    -->
    <div class="balance">
      <div class="total-balance">
        <h4 transloco="Total Balance"></h4>
        <div class="value">{{ mainWalletFunds$ | async | currencyFormat }}</div>

        <div class="buttons">
          <div (click)="openWithdrawalPage()" class="secondary-button cursor-pointer" transloco="Withdrawal"></div>
          <!-- <div (click)="openDepositPage()" class="primary-button cursor-pointer" transloco="Deposit"></div> -->
        </div>
      </div>

      <div class="wallets">
        <div class="wallet" *ngFor="let wallet of bonusWallets$ | async">
          <span class="label">{{ wallet.name }}: </span>
          <span class="value">{{ wallet.balance | currencyFormat }} </span>
        </div>
      </div>
    </div>

    <!--
      *
      *   Links
      *
    -->
    <div class="links">
      <div class="group">
        <!-- Transactions -->
        <div (click)="showTransactions()" class="link cursor-pointer">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <path
                  d="M16.84 20.164V6.546M20.917 16.068l-4.078 4.097-4.077-4.097M6.911 3.833V17.45M2.833 7.929l4.078-4.097L10.99 7.93" />
              </g>
            </svg>
          </div>
          <span transloco="Transactions"></span>
        </div>
        <!-- Bonusi -->
        <div (click)="close()" class="link cursor-pointer" [routerLink]="['/account/bonus']">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <g fill="#fff" fill-rule="evenodd" clip-rule="evenodd">
                <path
                  d="M2.25 8.25a1.5 1.5 0 0 1 1.5-1.5h16.5a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5v-3Zm18 0H3.75v3h16.5v-3Z" />
                <path
                  d="M4.5 11.25a.75.75 0 0 1 .75.75v6.75h13.5V12a.75.75 0 0 1 1.5 0v6.75a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5V12a.75.75 0 0 1 .75-.75Z" />
                <path d="M12 6.75a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-1.5 0v-12a.75.75 0 0 1 .75-.75Z" />
                <path
                  d="M13.59 3.782c-.145.148-.298.42-.431.822-.13.39-.222.84-.284 1.28-.043.298-.071.584-.09.83.247-.018.533-.046.832-.089a7.534 7.534 0 0 0 1.279-.284c.402-.133.674-.286.822-.432a1.504 1.504 0 0 0-2.127-2.127Zm2.657 2.659.53.53a3.004 3.004 0 0 0-4.248-4.248l-.002.002c-.384.387-.628.913-.792 1.406a9.018 9.018 0 0 0-.345 1.54 14.598 14.598 0 0 0-.14 1.785V7.499L12 7.5h-.75a.751.751 0 0 0 .75.75V7.5v.75h.044l.112-.002a14.618 14.618 0 0 0 1.672-.138 9.014 9.014 0 0 0 1.541-.345c.493-.164 1.019-.408 1.406-.792l-.528-.532Z" />
                <path
                  d="M7.223 2.723a3.004 3.004 0 0 1 4.248 0l.002.002c.384.387.628.913.792 1.406.168.506.275 1.051.345 1.54a14.643 14.643 0 0 1 .14 1.785V7.499L12 7.5v.75h-.044l-.112-.002a14.614 14.614 0 0 1-1.672-.138 9.012 9.012 0 0 1-1.54-.345c-.494-.164-1.02-.408-1.407-.792l.528-.532-.53.53a3.004 3.004 0 0 1 0-4.248Zm1.06 3.186c.147.146.419.299.821.432.39.13.84.222 1.28.284.298.043.584.071.83.09a12.678 12.678 0 0 0-.089-.832 7.533 7.533 0 0 0-.284-1.279c-.133-.402-.286-.674-.432-.822A1.504 1.504 0 0 0 8.282 5.91ZM12 7.5v.75a.75.75 0 0 0 .75-.75H12Z" />
              </g>
            </svg>
          </div>
          <span transloco="Bonuses"></span>
        </div>
        <!-- Saved cards -->
        <div (click)="close()" class="link cursor-pointer" [routerLink]="['/account/saved-cards']">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <path
                  d="M16.334 2.75H7.665c-3.021 0-4.915 2.14-4.915 5.166v8.168c0 3.027 1.885 5.166 4.915 5.166h8.668c3.031 0 4.917-2.139 4.917-5.166V7.916c0-3.027-1.886-5.166-4.916-5.166Z"
                  clip-rule="evenodd" />
                <path d="M10.69 12a1.852 1.852 0 1 1-1.853-1.852h.003A1.852 1.852 0 0 1 10.69 12Z"
                  clip-rule="evenodd" />
                <path d="M10.692 12h6.318v1.852M14.182 13.852V12" />
              </g>
            </svg>
          </div>
          <span transloco="Saved cards"></span>
        </div>
      </div>
      <div class="group">
        <!-- Messages -->
        <div (click)="close()" class="link cursor-pointer" [routerLink]="['/message-center']">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <path d="m17.903 8.851-4.444 3.613c-.84.666-2.02.666-2.86 0L6.12 8.851" />
                <path
                  d="M16.909 21C19.95 21.01 22 18.51 22 15.438V8.57C22 5.5 19.95 3 16.909 3H7.09C4.05 3 2 5.5 2 8.57v6.868C2 18.51 4.05 21.008 7.091 21h9.818Z"
                  clip-rule="evenodd" />
              </g>
            </svg>
          </div>
          <span transloco="Messages"></span>
        </div>
        <!-- Verify -->
        <div *ngIf="isVerified === 'documentsNeeded' || isVerified === 'accountNumberNeeded'" (click)="close()"
          class="link cursor-pointer" [routerLink]="['/account/verification-choice']">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" fill="none">
              <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <path
                  d="M12.983 21.606s8.311-2.323 8.311-8.727c0-6.405.302-6.905-.365-7.521-.667-.616-6.856-2.608-7.946-2.608-1.09 0-7.279 1.992-7.945 2.608-.667.616-.366 1.116-.366 7.521 0 6.404 8.311 8.727 8.311 8.727Z"
                  clip-rule="evenodd" />
                <path d="m10.168 11.875 2.05 1.895 4.223-3.9" />
              </g>
            </svg>
          </div>
          <span>{{ 'Verify yourself' | transloco }}</span>
        </div>
        <!-- Edit -->
        <div (click)="close()" class="link cursor-pointer" [routerLink]="['/account/edit-profile']">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <path
                  d="M11.492 2.789H7.753c-3.075 0-5.003 2.177-5.003 5.259v8.314c0 3.082 1.92 5.259 5.003 5.259h8.824c3.085 0 5.004-2.177 5.004-5.26v-4.027" />
                <path
                  d="M8.828 10.92 16.3 3.449c.93-.93 2.44-.93 3.37 0l1.218 1.217a2.383 2.383 0 0 1 0 3.37l-7.51 7.51a2.17 2.17 0 0 1-1.534.636H8.099l.094-3.78a2.17 2.17 0 0 1 .635-1.48Z"
                  clip-rule="evenodd" />
                <path d="m15.165 4.602 4.566 4.566" />
              </g>
            </svg>
          </div>
          <span transloco="Account"></span>
        </div>
      </div>
    </div>

    <!--
      *
      *   Info Links
      *
    -->
    <div class="info-links">
      <!-- INFO -->
      <div (click)="openInfos()" class="support-link">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" fill="none">
            <path fill="white" fill-rule="evenodd"
              d="M13.128 4.063a8.937 8.937 0 1 0 0 17.874 8.937 8.937 0 0 0 0-17.875ZM2.565 13c0-5.834 4.73-10.563 10.563-10.563S23.69 7.168 23.69 13c0 5.834-4.729 10.563-10.562 10.563-5.834 0-10.563-4.73-10.563-10.563Z"
              clip-rule="evenodd" />
            <path fill="white" fill-rule="evenodd"
              d="M11.503 12.188c0-.45.364-.813.812-.813h.813c.449 0 .812.364.812.813v4.874a.812.812 0 1 1 0 1.625h-.812a.813.813 0 0 1-.813-.812V13a.813.813 0 0 1-.812-.813Z"
              clip-rule="evenodd" />
            <path fill="white" d="M12.925 9.75a1.219 1.219 0 1 0 0-2.438 1.219 1.219 0 0 0 0 2.438Z" />
          </svg>
        </div>
        <span transloco="Info"></span>
      </div>
    </div>

    <!--
      *
      *   Support
      *
    -->
    <div class="support cursor-pointer" (click)="close()" [routerLink]="['/help/contact-us' | transloco]">
      <div class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none">
          <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            d="M2.75 16.5V11a8.25 8.25 0 1 1 16.5 0v5.5" />
          <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            d="M19.25 17.417a1.834 1.834 0 0 1-1.833 1.833H16.5a1.834 1.834 0 0 1-1.833-1.833v-2.75a1.834 1.834 0 0 1 1.833-1.834h2.75v4.584Zm-16.5 0a1.833 1.833 0 0 0 1.833 1.833H5.5a1.833 1.833 0 0 0 1.833-1.833v-2.75A1.834 1.834 0 0 0 5.5 12.833H2.75v4.584Z" />
        </svg>
      </div>
      <div class="title text-gradient-secondary" transloco="Customer Support"></div>
      <div class="subtitle" transloco="Customer Support Working Hours"></div>
    </div>

    <!--
      *
      *   Account panel
      *
    -->
    <div class="account-panel">
      <div>
        <span class="name">{{ userData.name }} {{ userData.surname }}</span>
        <span class="last-login">{{ 'Last Login:' | transloco }} {{ loginDate$ | async }}</span>
      </div>
      <div>
        <div (click)="logout()" class="logout-button cursor-pointer">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                <path
                  d="M15.016 7.39v-.934a3.685 3.685 0 0 0-3.685-3.685H6.456a3.685 3.685 0 0 0-3.684 3.685v11.13a3.685 3.685 0 0 0 3.684 3.686h4.885a3.675 3.675 0 0 0 3.675-3.674v-.944M21.81 12.021H9.769M18.881 9.106l2.928 2.915-2.928 2.916" />
              </g>
            </svg>
          </div>
          <span transloco="Log Out"></span>
        </div>
      </div>
    </div>
  </div>
</div>