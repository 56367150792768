import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { DynamicScriptLoaderService } from 'src/app/core/services/dynamic-script-loader.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { APIType } from 'src/app/shared/models/api.model';
import { ApplicationUIState, CmsModel, ConfigUIModel, MenuMethodCalls, SidebarBox } from 'src/app/shared/models/application.model';
import { MenuItemModel } from 'src/app/shared/models/menu.model';

import { AppConfigService } from './app-config.service';
import { ExpandedAccountStatementModel } from 'src/app/shared/models/account.model';
import { PopupBanner } from 'src/app/shared/components/popup-banners/popup-banners.component';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  hideNavBar$: Subject<boolean> = new Subject();
  showAZMenu$: Subject<boolean> = new Subject();
  showAccountMenu$: Subject<boolean> = new Subject();
  showCoupon$: Subject<boolean> = new Subject();
  showTransactionsMenu$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showLanguagePicker$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showMyBets$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  showPopupBanner$: BehaviorSubject<PopupBanner | undefined> = new BehaviorSubject(undefined);
  showPromoPayoutModal$: Subject<{
    open: boolean;
    promoDetails: {
      transactionId: number;
      transactionDate: Date;
      qualified: boolean;
      closedDate: Date;
      cashAward: number;
      spinsAward: number;
    };
  }> = new BehaviorSubject({
    open: false,
    promoDetails: null
  });
  routeHistory: string[] = [];
  sidebar$: BehaviorSubject<SidebarBox[]> = new BehaviorSubject(undefined);
  isDesktop$: BehaviorSubject<boolean | undefined> = new BehaviorSubject(undefined);

  menuMethodCalls: MenuMethodCalls = {
    openBetSlip: () => {
      this.showCoupon();
    }
  };

  enableSlideUps = false;

  constructor(
    private readonly applicationStore: ApplicationStore,
    private readonly applicationQuery: ApplicationQuery,
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly languageService: LanguageService
  ) {}

  initialize(): void {
    this.enableSlideUps = this.appConfigService.get('enableSlideUps');
  }

  updateUI(ui: Partial<ApplicationUIState>): void {
    this.applicationStore.updateUI(ui);
  }

  updateActiveUrl(activeUrl: string[]): void {
    this.applicationStore.updateActiveUrl(activeUrl);
  }

  updateCms(cmsCacheTTL: Partial<CmsModel>): void {
    this.applicationStore.updateCms(cmsCacheTTL);
  }

  updateConfigUI(configUI: ConfigUIModel): void {
    this.applicationStore.updateConfigUI(configUI);
  }

  closeSlideUp(type: string): void {
    this.applicationStore.closeSlideUp(type);
  }

  closeAnySlideUps(): void {
    this.applicationStore.closeAnySlideUps();
  }

  toggleLoginState(): void {
    const newLoginState = this.applicationQuery.loginState === 'Login' ? 'Password Recovery' : 'Login';
    this.applicationStore.updateUI({ loginState: newLoginState });
  }

  showSlideUp(): void {
    if (this.accountQuery.isAuthenticated) {
      this.showAccountMenu();
    } else {
      this.showLogin();
    }
  }

  showAccountMenu(): void {
    if (this.router.url === '/account') {
      this.closeAnySlideUps();
      return;
    }

    let updateUI: ApplicationUIState;
    if (this.applicationQuery.showAccountMenu) {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
    } else {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showAccountMenu: true, landingMenuExpanded: false });
    }
    this.updateUI(updateUI);
  }

  showMenu(): void {
    let updateUI: ApplicationUIState;
    if (this.applicationQuery.showMenu) {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
    } else {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showMenu: true, landingMenuExpanded: false });
    }
    this.updateUI(updateUI);
  }

  showCoupon({ isVirtuals }: { isVirtuals?: boolean } = {}): void {
    // TODO: slideup coupon is not implemented for virtuals
    if (this.enableSlideUps) {
      let updateUI: ApplicationUIState;
      if (this.applicationQuery.showCoupon) {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
      } else {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showCoupon: true, landingMenuExpanded: false });
      }
      this.updateUI(updateUI);
    } else {
      isVirtuals ? this.router.navigate(['/virtual/coupon']) : this.router.navigate(['/coupon']);
    }
  }

  showQuickCoupon(showQuickCoupon: boolean): void {
    const enableQuickCouponForPrematch: Boolean = this.appConfigService.get('enableQuickCouponForPrematch');
    const enableQuickCouponForLive: Boolean = this.appConfigService.get('enableQuickCouponForLive');

    if (
      (this.router.url.includes('/live') && enableQuickCouponForLive) ||
      (!this.router.url.includes('/live') && enableQuickCouponForPrematch)
    ) {
      let updateUI: ApplicationUIState;
      if (showQuickCoupon) {
        updateUI = new ApplicationUIState({ showQuickCoupon: true });
      } else {
        updateUI = new ApplicationUIState({ showQuickCoupon: false });
      }
      this.updateUI(updateUI);
    }
  }

  showMyBets(): void {
    if (!this.accountQuery.isAuthenticated) {
      return;
    }

    let updateUI: ApplicationUIState;
    if (this.applicationQuery.showMyBets) {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
    } else {
      updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showMyBets: true, landingMenuExpanded: false });
    }
    this.updateUI(updateUI);
  }

  showLogin(): void {
    if (this.router.url === '/account/login') {
      this.closeAnySlideUps();
      return;
    }

    if (!this.appConfigService.get('enableSlideUps')) {
      this.navigateTo('/account/login');
    } else {
      let updateUI: ApplicationUIState;
      if (this.applicationQuery.showLogin) {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, landingMenuExpanded: false });
      } else {
        updateUI = new ApplicationUIState({ ...this.applicationQuery.slideUpResetObject, showLogin: true, landingMenuExpanded: false });
      }
      this.updateUI(updateUI);
    }
  }

  toggleDropdownOverlay(): void {
    const updateUI = new ApplicationUIState({
      ...this.applicationQuery.slideUpResetObject,
      showDropdownOverlay: !this.applicationQuery.showDropdownOverlay,
      landingMenuExpanded: false
    });
    this.updateUI(updateUI);
  }

  navigateTo(link: string, queryStrings?: any): void {
    if (!link) {
      return;
    }
    if (link === 'desktop') {
      window.open(this.appConfigService.get('desktopUrl'), '_blank');
    } else {
      this.closeAnySlideUps();

      if (!queryStrings) {
        this.router.navigate([link]);
      } else {
        this.router.navigate([link], { queryParams: queryStrings });
      }
    }
  }

  getSidebarLinks(): void {
    this.applicationStore.updateUI({ loadingQuicklinks: true });

    //const endpoint = this.languageService.selectedLanguage.language === 'en' ? 'menus/15' : 'menus/3';
    const menuId = this.appConfigService.get('cmsMenuIds')[this.languageService.selectedLanguage.language]['site_menu_quicklinks'];
    const endpoint = `menus/${menuId}`;

    this.apiService
      .get(APIType.CMS, endpoint)
      .pipe(
        catchError(err => {
          this.getMenuItemsFromConfig();
          return throwError(err);
        }),
        tap((res: any) => {
          if (res && res.Menu && res.Menu.length > 0) {
            const menuItems = [];
            res.Menu.forEach(menuItem => {
              menuItems.push(
                new MenuItemModel({
                  title: menuItem.title,
                  icon: menuItem.icon,
                  iconSuffixText: menuItem.icon_suffix,
                  isFontAwesomeIcon: menuItem.font_awesome,
                  linkURL: menuItem.link,
                  functionName: menuItem.function,
                  newTab: menuItem.new_tab,
                  showWhenLoggedIn: menuItem.show_logged_in,
                  showWhenLoggedOut: menuItem.show_logged_out,
                  isNew: false,
                  isLocked: this.isAccountMenuLocked(menuItem.link),
                  showWarningIcon: menuItem.showWarningIcon
                })
              );
            });

            this.applicationStore.updateSidebarQuickLinks(menuItems);
            this.applicationStore.updateUI({ loadingQuicklinks: false });
          }
        })
      )
      .subscribe();
  }

  isAccountMenuLocked(linkURL: any): boolean {
    let itemLocked: boolean = false;
    if (
      this.accountQuery.userData &&
      this.accountQuery.userData.mobile &&
      this.accountQuery.userData.mobile.verifyType === 'LCKD' &&
      this.appConfigService.get('account').routeWhiteListForLockedUsers.find(url => linkURL.endsWith(url)) === undefined
    ) {
      itemLocked = true;
    }

    return itemLocked;
  }

  getEpochTime(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  getEpochTimeDifference(epochTime: number): number {
    // If there was not any api call yet we interpret it that it happened Infinite time ago (to be able to compare values)
    return isNaN(epochTime) ? Infinity : this.getEpochTime() - epochTime;
  }

  getSideMenuTabs(): void {
    // const endpoint = this.languageService.selectedLanguage.language === 'en' ? 'menus/16' : 'menus/4';
    const menuId = this.appConfigService.get('cmsMenuIds')[this.languageService.selectedLanguage.language]['site_menu_tabs'];
    const endpoint = `menus/${menuId}`;

    this.apiService
      .get(APIType.CMS, endpoint)
      .pipe(first())
      .subscribe(data => {
        if (data !== undefined && data.Menu && data.Menu.length > 0) {
          const tabs = [];
          data.Menu.forEach(tab => {
            tabs.push({ title: tab.title, isNew: false, type: tab.type });
          });
          this.applicationStore.updateSideBarMenu(tabs);
        }
      });
  }

  resetBannerUpdateCaches(): void {
    this.updateCms({
      lastPaymentsBannerRotatorUpdate: undefined,
      lastSportsBannerRotatorUpdate: undefined
    });
  }

  isInIFrame(): boolean {
    return window.self !== window.top;
  }

  isInPWA(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  fetchSidebar(): void {
    this.apiService
      .get(APIType.CMS, 'sidebar-promos', { skipLocale: true })
      .pipe(
        map(this.mapSidebarResponse.bind(this)),
        tap(sidebar => {
          this.sidebar$.next(sidebar as SidebarBox[]);
        })
      )
      .subscribe();
  }

  private getMenuItemsFromConfig(): void {
    this.applicationStore.updateSidebarQuickLinks(
      this.appConfigService.get('menuDefaults').sidebarQuicklinks.map(mi => new MenuItemModel(mi))
    );
  }

  private mapSidebarResponse(sidebarBox: any[]): SidebarBox[] {
    return sidebarBox.map(box => ({
      title: box.title,
      subtitle: box.subtitle,
      thumb: box.thumb
        ? {
            url: box.thumb.url,
            alt: box.thumb.alternativeText
          }
        : undefined,
      background: box.background
        ? {
            url: box.background.url,
            alt: box.background.alternativeText
          }
        : undefined,
      fullImage: box.full_image
        ? {
            url: box.full_image.url,
            alt: box.full_image.alternativeText
          }
        : undefined,
      exactRoutes: box.exact_routes,
      startWithRoutes: box.start_with_routes,
      side: box.side,
      buttons: box.buttons.map(button => ({
        text: button.text,
        style: button.style,
        url: button.url
      }))
    }));
  }
}
