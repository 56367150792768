import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap, tap } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { PersonalizedDefaultStake, PersonalizedDefaultStakeReq } from 'src/app/shared/models/account.model';
import { AnalyticsEventAction } from 'src/app/shared/models/analytics.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonalizedStakeService {
  private personalizedDefaultStake: PersonalizedDefaultStake;

  constructor(private readonly httpService: HttpClient, private readonly analyticsService: AnalyticsService) {}

  getPersonalizedCouponStake(data: PersonalizedDefaultStakeReq): Observable<number> {
    if (!data?.UserId) {
      return of(undefined);
    }

    return this.hasPersonalizedStakeForCurrentUser(data.UserId)
      ? of(this.calculatePersonalizeDefaultStake(data))
      : this.fetchPersonalizedDefaultStake(data.UserId).pipe(
          first(),
          tap(this.setPersonalizedDefaultStake.bind(this)),
          mergeMap(() => of(this.calculatePersonalizeDefaultStake(data))),
          tap(this.reportPersonalizedDefaultStake.bind(this))
        );
  }

  private reportPersonalizedDefaultStake(stake: number): void {
    if (!stake) {
      return;
    }

    this.analyticsService.sendEvent({
      Action: AnalyticsEventAction.DefaultStakeApplied,
      Value: stake
    });
  }

  private fetchPersonalizedDefaultStake(userId: number): Observable<PersonalizedDefaultStake> {
    return this.httpService.get(`${this.analyticsURL}/stake/default/${userId}`).pipe(
      map(response => response as PersonalizedDefaultStake),
      catchError(
        (error: HttpErrorResponse) =>
          error.status === 404 &&
          of({
            UserId: userId,
            StakeOfferSingle: undefined,
            StakeOfferMultiple: undefined
          })
      )
    );
  }

  private setPersonalizedDefaultStake(stake: PersonalizedDefaultStake): void {
    this.personalizedDefaultStake = stake;
  }

  private calculatePersonalizeDefaultStake(data: PersonalizedDefaultStakeReq): number {
    if (this.isSingleOfferCoupon(data)) {
      return this.stakeOfferSingle;
    } else {
      if (this.stakeOfferSingle) {
        return this.isSingleOfferUnchanged(data) ? this.stakeOfferMultiple || this.stakeOfferSingle : undefined;
      } else {
        return this.isDefaultStakeUnchanged(data) ? this.stakeOfferMultiple : undefined;
      }
    }
  }

  private hasPersonalizedStakeForCurrentUser(userId: number): boolean {
    return this.personalizedDefaultStake?.UserId === userId;
  }

  private isSingleOfferCoupon(data: PersonalizedDefaultStakeReq): boolean {
    return data.OldCouponData === undefined;
  }

  private isSingleOfferUnchanged(data: PersonalizedDefaultStakeReq): boolean {
    return data.OldCouponData?.Stake === this.stakeOfferSingle;
  }

  private isDefaultStakeUnchanged(data: PersonalizedDefaultStakeReq): boolean {
    return data.UpdatedCouponData?.Stake === data?.DefaultStake;
  }

  private get stakeOfferSingle(): number {
    return this.personalizedDefaultStake?.StakeOfferSingle;
  }

  private get stakeOfferMultiple(): number {
    return this.personalizedDefaultStake?.StakeOfferMultiple;
  }

  private get analyticsURL(): string {
    return environment.appConfigDefaults.apiBaseUrl.analyticsHTTP;
  }
}
