import { SportId } from 'src/app/shared/models/sport-id.model';

export const environment = {
  production: true,
  appConfigPath: 'assets/configs/app.prod.conf',
  signUpUrl: 'https://login.victory.rs/',
  // The following value can be used to force cache busting on the config file.
  // Generate a value from: https://randomkeygen.com/
  appConfigCacheBuster: 'yzCR8Xg6Lp',
  appConfigDefaults: {
    // These default settings will be used if a value is not found in the config file
    siteVersion: '1.5.3',
    apiBaseUrl: {
      cms: 'https://victory-cms.fly.dev',
      guardian: 'https://auth-api-vic247srb.victorybet.services',
      platform: 'https://website-api-vic247srb.victorybet.services',
      sportsbook: 'https://sportsapi.victorybet.services',
      sportsbookFeed: 'https://sportsapi-feeds.victorybet.services',
      betRadarStatistics: 'https://s5.sir.sportradar.com/victoryserbia',
      betRadarResults: 'https://ls.fn.sportradar.com/victoryserbia',
      betRadarTeamLogo: 'https://img.sportradar.com/ls',
      betRadarWidgets: 'https://widgets.sir.sportradar.com/8291e456ef20ffd848b311f769db64c9/widgetloader',
      analyticsWS: 'wss://prod-victory-analytics.fly.dev',
      analyticsHTTP: 'https://prod-victory-analytics.fly.dev',
      zendesk: 'https://victory7201.zendesk.com',
      promotionsEngine: 'https://prod-victory-promotion-engine.fly.dev',
      liveApiBaseUrl: 'https://program.victorybet.services/live'
    },
    bombBonusUrl: 'https://bonus-ui.vercel.app/',
    bonusGamesSubCategoryId: '76',
    maintenanceMode: false,
    VAPID: 'BBnSKNKVfQWTgcg_ZAqzo2sEcOAIe9H2Fgpgdtw6qui9RPwQqrMCi5oFAm06ln3tgsdx1h4WzoBWVnaym-UioIA',
    account: {
      accountNumberValidationRegex: '^[0-9]{10}$',
      depositDelimiters: [500, 700, 1000, 5000, 10000],
      insufficientBalanceThreshold: 500,
      withdrawalsDelimiters: [5000, 7000, 10000, 50000, 100000],
      accountTypes: {
        master: 'G_MASTER',
        superAgent: 'G_SUPER_AG',
        shopOwner: 'G_SHOP_OWN',
        cashier: 'G_CASHIER'
      },
      paymentMethods: {
        depositAllSecureBaseURL: 'https://asxgw.com',
        depositAllSecurePublicIntegrationKey: 'YpfULRpgBb4G9Lt7hR8p',
        blockedCardTypes: ['mastercard'],
        depositSpinePayBaseURL: 'https://pgw.spinepay.com/ips/pay',
        depositSpinePayPublicIntegrationKey: '0170001'
      },
      depositRedirect: '',
      routeWhiteListForLockedUsers: ['withdrawal', 'change-phone-number'],
      profileEditability: 'One-Time', // Can be 'Off', 'One-Time', 'Always'
      mobilePhoneEditability: true,
      nameSurnameEditability: true,
      loginPrefix: ''
    },
    appInsights: {
      enabled: true,
      instrumentationKey: '',
      samplingPercentage: 20
    },
    brandId: 1001,
    activitySource: 'mobileplus',
    desktopUrl: '//www.victory.rs',
    enableOTPbySms: false,
    cmsToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjI5MTE0NDY3LCJleHAiOjE2MzE3MDY0Njd9.-of3D0JscUd1VBqWvxroPrYmutPDvE5S6amXEXziuZw',
    sports: {
      mostPopular: {
        count: 6,
        tournamnentId: 2398310,
        enabled: true,
        interval: 60000
      },
      upcomingEvents: {
        count: 6,
        enabled: true,
        interval: 60000
      },
      liveBetting: {
        count: 6,
        enabled: true,
        interval: 6000
      },
      lastMinute: {
        count: 50,
        sportIds: [1, 2, 3, 4, 5, 16]
      },
      live: {
        betRadarWidgetHideIds: [3, 13, 16, 19, 20, 21, 22, 24, 29, 31, 32, 34, 109, 111, 112]
      },
      statistics: {
        type: 2,
        enabled: true,
        maximumRows: 5
      },
      coupon: {
        fixedStakes: [100, 500, 2000],
        showStakeReduction: false,
        oddChangesTimer: 2000,
        liveOddChangesTimer: 2000,
        canBookWhenLoggedIn: false,
        defaultCurrency: 'RSD',
        stakeTaxPercentageToShow: '',
        showBookedBetRetailMessage: true,
        disablePlaceBetForUnverifiedUsers: false,
        hideMarketSpreadIds: [
          56, 60, 9641, 61, 1020, 9814, 9815, 9812, 9615, 9289, 9534, 9336, 9338, 9535, 9337, 9339, 9582, 9920, 9918, 9639, 9640, 93
        ],
        hideMarketSpreadIdsFromRecentBets: [9364, 9699, 9869, 9870],
        hideMarketSpreadIdsFromHeader: [60, 9582, 9920, 9918, 9814, 9815, 9812]
      },
      cashout: {
        blackListedUserTypes: ['cashier'],
        enabled: true,
        unavailableCodes: [
          0, 2, 3, 4, 100, 101, 102, 103, 104, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 200, 201, 202, 300, 302, 303, 304,
          305, 306, 307, 308, 309, 310
        ]
      },
      marketGroups: {
        [SportId.Soccer]: [
          {
            id: 11,
            name: 'Basic Games',
            marketIds: [10, 46, 60, 61, 43, 9961, 44, 47, 9534, 9535, 9615, 9289, 9938],
            offerPage: false
          },
          {
            id: 12,
            name: 'Outcome',
            marketIds: [
              10, 46, 691, 9761, 9693, 44, 42, 1010, 1016, 9649, 47, 9642, 9643, 9644, 628, 627, 41, 2, 1009, 9788, 9787, 654, 9673, 9674,
              9675, 51, 641, 9651, 9653
            ],
            offerPage: {
              regionId: 134,
              areaId: 1243
            }
          },
          {
            id: 13,
            name: 'Total Goals',
            marketIds: [60, 56, 9647, 9938, 9939, 61, 9639, 9617, 1020, 9618, 9616, 9641, 642, 9634, 9635, 9636, 9637, 9638, 9926, 9927],
            offerPage: {
              regionId: 135,
              areaId: 1190
            }
          },
          {
            id: 14,
            name: 'Home Goals',
            marketIds: [9534, 9619, 48, 9336, 9338, 624],
            offerPage: {
              regionId: 135,
              areaId: 1211
            }
          },
          {
            id: 15,
            name: 'Away Goals',
            marketIds: [9535, 9620, 49, 9337, 9339, 623],
            offerPage: {
              regionId: 135,
              areaId: 1212
            }
          },
          {
            id: 16,
            name: 'Both Teams To Score',
            marketIds: [43, 9961, 9285, 9905, 1012, 1022],
            offerPage: {
              regionId: 135,
              areaId: 1249
            }
          },
          {
            id: 17,
            name: 'Combinations',
            marketIds: [9615, 9910, 9936, 722, 9289, 9911, 9288, 9937, 9813, 9814, 9815, 9812, 9962, 9334, 9917, 9963],
            offerPage: {
              regionId: 138,
              areaId: 1460
            }
          },
          {
            id: 18,
            name: 'Specials',
            marketIds: [
              657, 9774, 9775, 612, 633, 660, 9789, 9790, 9671, 648, 655, 9628, 9757, 9758, 10271, 9629, 9744, 9745, 10272, 9630, 9699, 699
            ],
            offerPage: false
          }
        ],
        [SportId.Basketball]: [
          {
            id: 21,
            name: 'Outcome',
            marketIds: [20, 1140, 52, 51, 9663, 9664],
            offerPage: {
              regionId: 14,
              areaId: 633
            }
          },
          {
            id: 22,
            name: 'Total Points',
            marketIds: [52, 9534, 9535, 711, 712],
            offerPage: {
              regionId: 14,
              areaId: 635
            }
          },
          {
            id: 23,
            name: 'Halftime',
            marketIds: [42, 54, 53, 1013, 1016, 9335],
            offerPage: false
          },
          {
            id: 24,
            name: 'Quarters',
            marketIds: [686, 9552, 9555, 9572, 9556, 9567, 9573, 9557, 9568, 9574, 9558, 9569],
            offerPage: false
          },
          {
            id: 25,
            name: 'Combinations',
            marketIds: [44, 9943],
            offerPage: false
          },
          {
            id: 26,
            name: 'Handicap',
            marketIds: [51, 53, 9335],
            offerPage: {
              regionId: 14,
              areaId: 634
            }
          }
        ],
        [SportId.Tennis]: [
          {
            id: 51,
            name: 'Outcome',
            marketIds: [20, 9696, 51, 70, 9697, 385, 45],
            offerPage: {
              areaId: 667,
              regionId: 14
            }
          },
          {
            id: 52,
            name: 'Set',
            marketIds: [383, 403, 9695, 9611, 9694, 9825],
            offerPage: false
          },
          {
            id: 53,
            name: 'Home / Away',
            marketIds: [9821, 9822, 9534, 9535],
            offerPage: false
          },
          {
            id: 54,
            name: 'Combinations',
            marketIds: [9612, 9364, 387],
            offerPage: false
          },
          {
            id: 55,
            name: 'Gems Handicap',
            marketIds: [51],
            offerPage: {
              areaId: 1461,
              regionId: 14
            }
          },
          {
            id: 56,
            name: 'Total Gems',
            marketIds: [70, 9534, 9535],
            offerPage: {
              areaId: 672,
              regionId: 14
            }
          }
        ],
        [SportId.IceHockey]: [
          {
            id: 41,
            name: 'Outcome',
            marketIds: [10, 46, 47, 51, 9819],
            offerPage: {
              areaId: 717,
              regionId: 14
            }
          },
          {
            id: 42,
            name: 'Total Goals',
            marketIds: [9811, 382, 9534, 9535],
            offerPage: {
              areaId: 722,
              regionId: 2
            }
          },
          {
            id: 43,
            name: 'Thirds',
            marketIds: [356, 359, 357, 360, 358, 361],
            offerPage: {
              dropdownOptionName: 'First Third',
              areaId: 723,
              regionId: 40
            }
          }
        ],
        [SportId.Volleyball]: [
          {
            id: 231,
            name: 'Outcome',
            marketIds: [20, 51, 70, 688, 386],
            offerPage: {
              areaId: 702,
              regionId: 14
            }
          },
          {
            id: 232,
            name: 'Total Points',
            marketIds: [70, 403],
            offerPage: {
              areaId: 705,
              regionId: 14
            }
          },
          {
            id: 233,
            name: 'Sets',
            marketIds: [383, 403, 384, 402, 398, 401],
            offerPage: {
              dropdownOptionName: 'First set',
              areaId: 708,
              regionId: 35
            }
          }
        ],
        [SportId.Handball]: [
          {
            id: 61,
            name: 'Outcome',
            marketIds: [10, 46, 47, 51, 44],
            offerPage: {
              areaId: 686,
              regionId: 14
            }
          },
          {
            id: 62,
            name: 'Total Goals',
            marketIds: [60, 9534, 9535, 45],
            offerPage: {
              areaId: 689,
              regionId: 14
            }
          },
          {
            id: 63,
            name: 'Halftime',
            marketIds: [42, 54, 53, 1016],
            offerPage: false
          }
        ],
        [SportId.Baseball]: [
          {
            id: 31,
            name: 'Outcome',
            marketIds: [20, 676, 664, 9943, 389],
            offerPage: {
              areaId: 974,
              regionId: 14
            }
          },
          {
            id: 32,
            name: 'Total Points',
            marketIds: [664, 9534, 9535],
            offerPage: {
              areaId: 1084,
              regionId: 96
            }
          }
        ],
        [SportId.Waterpolo]: [
          {
            id: 261,
            name: 'Outcome',
            marketIds: [10, 60, 51, 9610],
            offerPage: false
          }
        ],
        [SportId.Cycling]: [
          {
            id: 171,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.TableTennis]: [
          {
            id: 201,

            name: 'Outcome',

            marketIds: [
              20,

              52
            ],

            offerPage: {
              regionId: 14,

              areaId: 770
            }
          }
        ],

        [SportId.Football]: [
          {
            id: 161,

            name: 'Outcome',

            marketIds: [
              20,

              664,

              9534,

              9535,

              676,

              53,

              42
            ],

            offerPage: {
              regionId: 14,

              areaId: 678
            }
          }
        ],

        [SportId.Rugby]: [
          {
            id: 2541,
            name: 'Outcome',
            marketIds: [10, 60, 51, 46, 45],
            offerPage: {
              regionId: 14,
              areaId: 772
            }
          }
        ],
        [SportId.Badminton]: [
          {
            id: 311,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.Golf]: [
          {
            id: 91,
            name: 'Outcome',
            marketIds: [10],
            offerPage: false
          }
        ],
        [SportId.Boxing]: [
          {
            id: 831,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.AussieRules]: [
          {
            id: 131,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.Cricket]: [
          {
            id: 211,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.Darts]: [
          {
            id: 221,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.Pesapallo]: [
          {
            id: 611,
            name: 'Outcome',
            marketIds: [10],
            offerPage: false
          }
        ],
        [SportId.MMA]: [
          {
            id: 1171,
            name: 'Outcome',
            marketIds: [20, 60, 10781, 10782, 10784, 10786, 10788],
            offerPage: {
              regionId: 14,
              areaId: 766
            }
          }
        ],
        [SportId.ESportCounterStrike]: [
          {
            id: 1091,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.BeachSoccer]: [
          {
            id: 601,
            name: 'Outcome',
            marketIds: [10, 51],
            offerPage: false
          }
        ],
        [SportId.Basketball3x3]: [
          {
            id: 1551,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.Floorball]: [
          {
            id: 71,
            name: 'Outcome',
            marketIds: [10],
            offerPage: false
          }
        ],
        [SportId.FieldHockey]: [
          {
            id: 241,
            name: 'Outcome',
            marketIds: [10],
            offerPage: false
          }
        ],
        [SportId.ESportLeagueOfLegends]: [
          {
            id: 1111,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.Squash]: [
          {
            id: 371,

            name: 'Outcome',

            marketIds: [20],

            offerPage: false
          }
        ],
        [SportId.Snooker]: [
          {
            id: 191,
            name: 'Outcome',
            marketIds: [20, 52],
            offerPage: false
          }
        ],
        [SportId.Futsal]: [
          {
            id: 291,

            name: 'Outcome',

            marketIds: [
              10,

              60,

              51,

              9660,

              9661,

              9662
            ],

            offerPage: {
              regionId: 14,

              areaId: 760
            }
          }
        ],
        [SportId.Biathlon]: [
          {
            id: 441,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.SkiJumping]: [
          {
            id: 481,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.CrossCountry]: [
          {
            id: 461,
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ],
        [SportId.Alpine]: [
          {
            id: '',
            name: 'Outcome',
            marketIds: [20],
            offerPage: false
          }
        ]
      },
      marketGroupNumberOfSelections: {
        9647: 2,
        52: 2,
        51: 2,
        70: 2,
        664: 2,
        9811: 2
      },
      specialsMarketIds: [
        9700, 9744, 9745, 9746, 9747, 9748, 9749, 9750, 9751, 9752, 9753, 9754, 9756, 9776, 9796, 9802, 9803, 9804, 9807, 9808, 9809, 9810,
        9829, 9830, 9831, 9832, 9833, 9834, 9835, 9836, 9837, 9838, 9839, 9840, 9868, 9869, 9870, 10782, 9805, 9806
      ],
      liveGroupingTypeIds: [
        160, 10283, 10284, 161, 305, 316, 10290, 10291, 10302, 10303, 9280, 190, 191, 10332, 10333, 148, 10326, 10327, 9322, 9302, 10400,
        10401, 344, 10403, 10404, 10406, 10414, 10417, 10418, 9966, 9980, 9983, 285, 10424, 10425, 10431, 10432, 9393, 10461, 10462, 10488,
        10489, 10500, 10538, 10537, 10621, 10620, 10619, 10617, 10618, 10629, 10627, 10630, 10626, 10628, 10289, 10718, 10719, 10722, 10723,
        10728, 10730, 10598, 10571, 10596, 10555, 10553, 10554, 10597, 10600, 10602, 10568, 10574, 10570, 10581, 10573, 10603, 10590, 10569,
        10601, 10599, 10604, 10636, 10637, 10511, 10512, 10518, 10519, 10018, 10016, 10017, 10729, 10847, 10848, 10846, 10845, 10844, 10843,
        10842, 10841
      ],
      topCompetitionsLeagueCount: 10,
      allowSameMatchSelections: true,
      allowCombinationBets: true,
      enableCustomMenu: false,
      splitCategoryIdsWhiteList: [1618383, 2957993, 3354258, 3796350, 4520017]
    },
    messages: {
      defaultPageAmount: 10
    },
    registration: {
      defaultCountry: 'SRB',
      disableInternationalNumbers: 'true',
      mobileRegex: '^0?[0-9]{8,9}$',
      deadlineDate: '',
      successRedirectUrl: '/',
      registrationType: 'normal',
      registrationRedirect: '',
      showDNDNotice: false,
      promoCode: true
    },
    helpPageUrls: {
      en: {
        privacyPolicy: '/help/privacy-policy',
        termsAndConditions: '/help/terms-and-conditions',
        contactUs: '/help/contact-us',
        howToRegister: '/help/registration',
        contactForm: 'https://formcrafts.com/a/vxygygr'
      },
      sr: {
        privacyPolicy: '/help/politika-privatnosti',
        termsAndConditions: '/help/opsti-uslovi-koriscenja',
        contactUs: '/help/kontakt',
        howToRegister: '/help/registracija',
        contactForm: 'https://formcrafts.com/a/mhaqyyy'
      }
    },
    // The applicationServiceMethod means that the menu item will call a method in the application service
    // If you adding a new applicationServiceMethod calls for an item, you need to declare the given method in the application service
    menuDefaults: {
      landingPage: [
        {
          title: 'Soccer',
          icon: 'sport',
          linkURL: '/sports/soccer'
        },
        {
          title: 'Live',
          icon: 'live',
          linkURL: '/live'
        },
        {
          title: 'Today',
          icon: 'today',
          linkURL: '/betting'
        },
        {
          title: 'Book a Bet',
          icon: 'book-a-bet',
          linkURL: '/book-bet'
        },
        {
          title: 'Odds Less Than',
          icon: 'odds-less-than',
          linkURL: '/odds-less-than'
        },
        {
          title: 'Search',
          icon: 'search',
          linkURL: '/search'
        },
        {
          title: 'Last Minute',
          icon: 'last-minute',
          linkURL: '/last-minute'
        },
        {
          title: 'Quick Bet',
          icon: 'quick-bet',
          linkURL: '/quick-bet'
        },
        {
          title: 'Coupon Check',
          icon: 'coupon-check',
          linkURL: '/coupon-check'
        },
        {
          title: 'News',
          icon: 'news',
          linkURL: 'http://news.victory.rs/'
        },
        {
          title: 'Support',
          icon: 'live-chat',
          linkURL: '/help/contact-us'
        },
        {
          title: 'Help',
          icon: 'help',
          linkURL: '/help'
        }
      ],
      sidebarQuicklinks: [
        {
          title: 'Register',
          icon: 'user-plus',
          isFontAwesomeIcon: true,
          linkURL: '/register',
          showWhenLoggedIn: false,
          showWhenLoggedOut: true
        },
        {
          title: 'Betslip',
          icon: 'betslip',
          functionName: 'openBetSlip',
          showWhenLoggedIn: false,
          showWhenLoggedOut: true
        },
        {
          title: 'Bet List',
          icon: 'search',
          isFontAwesomeIcon: true,
          linkURL: '/account/bet-search',
          showWhenLoggedIn: true,
          showWhenLoggedOut: false
        },
        {
          title: 'Withdraw',
          icon: 'minus-circle',
          isFontAwesomeIcon: true,
          linkURL: '/account/withdrawal',
          showWhenLoggedIn: true,
          showWhenLoggedOut: false,
          showWarningIcon: true
        },
        {
          title: 'Desktop Site',
          icon: 'desktop',
          isFontAwesomeIcon: true,
          linkURL: 'https://www.victory.rs/?mobile=no',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
          newTab: true
        },
        {
          title: 'Coupon Check',
          icon: 'check-square-o',
          isFontAwesomeIcon: true,
          linkURL: '/coupon-check',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true
        },
        {
          title: 'Book a Bet',
          icon: 'calendar-check-o',
          isFontAwesomeIcon: true,
          linkURL: 'book-bet',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true
        },
        {
          title: 'Statistics',
          icon: 'line-chart',
          isFontAwesomeIcon: true,
          linkURL: 'https://s5.sir.sportradar.com/victory/',
          showWhenLoggedIn: false,
          showWhenLoggedOut: false,
          newTab: true
        },
        {
          title: 'Results',
          icon: 'bar-chart',
          isFontAwesomeIcon: true,
          linkURL: '/results/sports',
          showWhenLoggedIn: false,
          showWhenLoggedOut: false
        },
        {
          title: 'Less Than 2',
          icon: 'chevron-left',
          isFontAwesomeIcon: true,
          linkURL: '/odds-less-than',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
          iconSuffixText: '2'
        },
        {
          title: 'Contact Us',
          icon: 'envelope-o',
          isFontAwesomeIcon: true,
          linkURL: 'help/contact-us',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true
        },
        {
          title: 'Live Chat',
          icon: 'comment',
          isFontAwesomeIcon: true,
          functionName: 'loadLiveChat',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true
        },
        {
          title: 'Help',
          icon: 'question-circle-o',
          isFontAwesomeIcon: true,
          linkURL: '/help',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true
        },
        {
          title: 'Give Us Feedback',
          icon: 'commenting-o',
          isFontAwesomeIcon: true,
          linkURL: 'https://forms.gle/3xVMezJhiHApd4Kg7',
          showWhenLoggedIn: true,
          showWhenLoggedOut: true,
          newTab: true
        }
      ]
    },
    correctScoreAreaIds: [],
    live: {
      pollingTimer: 2000,
      useServerSideCoupon: true
    },
    selectAllMaxItemCount: 50,
    liveChat: {
      liveChatIntegration: 'zendesk'
    },
    logging: {
      enabled: true,
      minLogLevel: 'Warning',
      endpoints: ['AppInsights']
    },
    cmsCacheTTL: 1800,
    fallbackTimer: 10,
    enableDataLayer: true,
    enableSlideUps: false,
    enableQuickCouponForPrematch: false,
    enableQuickCouponForLive: true,
    maximumNumberOfToasts: 5,
    canonicalUrl: '',
    languages: [
      {
        language: 'sr',
        languageDescription: 'Serbian',
        languageIsoCode: 'srp',
        locale: 'sr-latn',
        umbracoLocale: 'sr-latn',
        dateLocale: 'srLatn',
        languageBetradar: 'srl'
      },
      {
        language: 'en',
        languageDescription: 'English',
        languageIsoCode: 'eng',
        locale: 'en-gb',
        umbracoLocale: 'en-us',
        dateLocale: 'enGB',
        languageBetradar: 'en'
      }
    ],
    social: {
      facebook: 'https://www.facebook.com/Victoryrs-119488599961948',
      instagram: 'https://www.instagram.com/victory.rs',
      linkedin: 'https://www.linkedin.com/company/victoryrs',
      twitter: 'https://twitter.com/rs_victory',
      whatsapp: 'https://api.whatsapp.com/send?phone=%2B38162242424',
      viber: 'viber://chat?number=%2B38162242424',
      youtube: ''
    },
    ui: {
      landing: {
        headerType: 'left'
      },
      live: {
        showLiveBadge: false
      },
      footer: {
        showBrandLogo: true,
        showAwardIcons: false,
        footerShowPaymentMethodImage: true
      }
    },
    analytics: {
      googleAnalyticsTags: ['G-MEWKD9Y2JR', 'UA-157944101-4'],
      appName: 'Victory'
    },
    chat: {
      enabled: false,
      idleDelay: 15,
      errorDelay: 3,
      simpleDelay: 45,
      routes: {
        onIdle: ['register'],
        onError: ['register']
      }
    }
  }
};
