import { EntityState, ID } from '@datorama/akita';
import { SweetAlertIcon } from 'sweetalert2';

export class NotificationSettings {
  allowBackdropClose: boolean = true;
  background: string;
  cancelButtonCallback: Function;
  cancelButtonText: string;
  closeButtonCallback: Function;
  confirmButtonText: string;
  confirmButtonCallback: Function;
  // if both contentHtml and contentText are provided, contentText will take priority
  contentHtml: string;
  contentText: string;
  branded: boolean;
  showCancelButton: boolean;
  showCloseButton: boolean;
  showConfirmButton: boolean;
  timeout: number;
  title: string;
  type: SweetAlertIcon;
  toast: boolean = false;

  constructor(init: Partial<NotificationSettings>) {
    Object.assign(this, init);
  }
}

export interface ToastNotificationState extends EntityState<ToastNotification> {}

export class ToastNotification {
  id: ID;
  message: string;
  duration: number;
  priority: ToastNotificationType;
  position: ToastNotificationPosition;

  constructor(init: Partial<ToastNotification>) {
    Object.assign(this, init);
  }
}

export enum ToastNotificationType {
  Info = 0,
  Warning = 1,
  Critical = 2,
  Error = 3,
  Success = 4
}
export enum ToastNotificationPosition {
  Bottom = 1,
  Top = 0
}
