import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private readonly currencies = {
    NGN: {
      inverse: false,
      format: '1.2-2',
      symbol: '₦',
      hasSpace: true
    },
    ETB: {
      inverse: true,
      format: '1.2-2',
      symbol: 'ብር',
      hasSpace: false
    },
    RSD: {
      inverse: false,
      format: '1.2-2',
      symbol: 'RSD',
      hasSpace: true
    },
    KES: {
      inverse: true,
      format: '1.2-2',
      symbol: 'KSh',
      hasSpace: false
    }
  };

  getCurrency(
    code: string
  ): {
    inverse: boolean;
    format: string;
    symbol: string;
    hasSpace: boolean;
  } {
    return this.currencies ? this.currencies[code] : undefined;
  }
}
