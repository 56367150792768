<div class="wrapper" id="wrap" [class.fullscreen]="casinoService.fullscreen$ | async" [class.safari]="isSafari$ | async"
  [class.no-padding]="noPadding"
  [class.block-scroll]="(applicationQuery.isSlideUpVisible$ | async) || (showSignIn$ | async)"
  [class.coupon-fab-visible]="(betSlipBadgeCount$ | async) > 0">
  <router-outlet></router-outlet>
</div>

<div class="app-header" *ngIf="applicationQuery.showNavBar$ | async">
  <app-header></app-header>
</div>

<div *ngIf="showAZMenu$ | async" class="menu">
  <app-a-z-menu></app-a-z-menu>
</div>

<div *ngIf="showAccountMenu$ | async" class="menu">
  <app-account-side-menu></app-account-side-menu>
</div>

<div *ngIf="showTransactionsSideMenu$ | async" class="menu">
  <app-transactions-side-menu></app-transactions-side-menu>
</div>

<div *ngIf="showLanguagePickerMenu$ | async" class="menu">
  <app-language-picker-menu></app-language-picker-menu>
</div>

<div *ngIf="showMyBets$ | async" class="menu">
  <app-my-bets-menu></app-my-bets-menu>
</div>



<!-- If you need to add additional slide up elements, all you need is to do add the additional ui state property in the ApplicationStore -->
<!-- And create the additional open function in the ApplicationService file following the pattern -->
<ng-container *ngIf="{
    showMenu: applicationQuery.showMenu$ | async,
    showLogin: applicationQuery.showLogin$ | async,
    showCoupon: applicationQuery.showCoupon$ | async,
    showQuickCoupon: applicationQuery.showQuickCoupon$ | async,
    showMyBets: applicationQuery.showMyBets$ | async,
    showAccountMenu: applicationQuery.showAccountMenu$ | async,
    activeUrl: applicationQuery.activeUrl$ | async,
    loginState: applicationQuery.loginState$ | async
  } as subscriptions">
  <app-slide-up [title]="'Menu' | transloco" type="showMenu" [@slideInToggle]="subscriptions.showMenu"
    *ngIf="subscriptions.showMenu">
    <app-menu></app-menu>
  </app-slide-up>

  <app-slide-up *ngIf="subscriptions.showLogin && !accountQuery.isAuthenticated"
    [@slideInToggle]="subscriptions.showLogin" [title]="subscriptions.loginState" type="showLogin">
    <app-login></app-login>
  </app-slide-up>

  <app-slide-up *ngIf="subscriptions.showMyBets" [@slideInToggle]="subscriptions.showMyBets" title="My Bets"
    type="showMyBets">
    <app-my-bets></app-my-bets>
  </app-slide-up>

  <app-slide-up *ngIf="subscriptions.showAccountMenu && accountQuery.isAuthenticated"
    [@slideInToggle]="subscriptions.showAccountMenu" title="Account" type="showAccountMenu">
    <app-account-menu></app-account-menu>
  </app-slide-up>

  <app-proceed-bars-container
    *ngIf="subscriptions.activeUrl && subscriptions.activeUrl[0] === 'sports' && subscriptions.activeUrl[1] !== 'match'">
  </app-proceed-bars-container>
</ng-container>

<app-phone-verification-popup></app-phone-verification-popup>
<app-bottom-sheet *ngIf="bottomSheetQuery.isVisible$ | async"></app-bottom-sheet>
<app-chat-popup *ngIf="(chatPopupQuery.isVisible$ | async) && !(chatPopupQuery.isSuspended$ | async)"></app-chat-popup>

<!-- <app-awards></app-awards> -->
<app-cms-notifications></app-cms-notifications>
<div *ngIf="showSignIn$ | async">
  <app-sign-in></app-sign-in>
</div>
<app-toast-notification></app-toast-notification>

<ng-container *ngIf="applicationQuery.loaderState$ | async as loader">
  <app-loading-animation [noDelay]="loader.noDelay" [show]="loader.show"></app-loading-animation>
</ng-container>
<div [class.hide-if-login-iframe]="accountService.showLogin$ | async">
  <app-footer-section *ngIf="applicationService.isDesktop$ | async"></app-footer-section>
</div>