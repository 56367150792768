import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { fadeIn } from 'src/app/shared/animations';

@Component({
  selector: 'app-account-page-wrapper',
  templateUrl: './account-page-wrapper.component.html',
  styleUrls: ['./account-page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()]
})
export class AccountPageWrapperComponent {

  constructor(private readonly accountService: AccountService, private readonly accountQuery: AccountQuery) { }

  ngOnInit(): void {
    this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
  }
}
