import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { BehaviorSubject } from 'rxjs';
import { fadeOut } from 'src/app/shared/animations';

@Component({
  selector: 'app-loading-animation',
  templateUrl: './loading-animation.component.html',
  styleUrls: ['./loading-animation.component.scss'],
  animations: [fadeOut()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingAnimationComponent implements OnChanges {
  @Input() show = false;
  @Input() zIndex = 2000;
  @Input() notFullscreen = false;
  @Input() noDelay = false;

  options: AnimationOptions = {
    path: '/assets/images/victory-animation.json'
  };

  show$ = new BehaviorSubject(false);
  private readonly delay = 500;
  private showTimeout: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show) {
      if (changes.show.currentValue) {
        this.showLoader();
      } else {
        this.hideLoader();
      }
    }
  }

  private showLoader(): void {
    if (this.noDelay) {
      this.show$.next(true);
    } else {
      this.showTimeout = setTimeout(() => {
        this.show$.next(true);
      }, this.delay);
    }
  }

  private hideLoader(): void {
    this.show$.next(false);
    if (this.showTimeout) {
      clearTimeout(this.showTimeout);
    }
  }
}
