import {Component, OnDestroy, OnInit} from '@angular/core';

import {ID} from '@datorama/akita';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {NotificationService} from 'src/app/core/services/notification.service';
import {fadeIn} from 'src/app/shared/animations';
import {ToastNotification, ToastNotificationPosition} from 'src/app/shared/models/notification.model';
import {ToastNotificationQuery} from './../../../core/state/toast-notifications/toast-notifications.query';
import {ToastNotificationType} from './../../models/notification.model';

@Component({
  selector: 'app-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrls: ['./toast-notification.component.scss'],
  animations: [fadeIn()]
})
export class ToastNotificationComponent implements OnInit, OnDestroy {
  toastType = ToastNotificationType;
  toastPosition = ToastNotificationPosition;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly toastNotificationQuery: ToastNotificationQuery,
    readonly notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    this.toastNotificationQuery.list$
      .pipe(
        map((list: ToastNotification[]) => {
          list.forEach((toast: ToastNotification) => {
            this.notificationService.toastDeleteAfterDuration(toast.id);
          });
        }),
        takeUntil(this.destroy$)
      ).subscribe();
  }

  closeToast(id: ID): void {
    this.notificationService.removeToast(id);
  }

  toastTrackBy(toast: ToastNotification): ID {
    return toast.id;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
