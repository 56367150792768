import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from './../../../../core/services/notification.service';

import { BetCoupon, CouponType } from 'clientside-coupon';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { AnalyticsEventAction } from 'src/app/shared/models/analytics.model';
import { ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-coupon-totals',
  templateUrl: './coupon-totals.component.html',
  styleUrls: ['./coupon-totals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponTotalsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stakeInput', { static: false }) stakeInput: ElementRef;
  @Input() couponData: BetCoupon;
  @Input() bookedCoupon: boolean = false;
  @Input() bookedCouponCode: string;
  @Input() bookedCouponDate: string;
  fixedStakes: number[];
  currencySymbol: string;
  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  showStakeReduction: boolean = false;
  stakeButtonStyle: any = {
    fontSize: '16px',
    height: '34px',
    margin: '2px',
    flex: '1 1 100%',
    padding: '0',
    minWidth: '0'
  };
  resetButtonStyle: any = {
    height: '34px',
    padding: '0px 2px 0px 5px',
    minWidth: '0',
    textAlign: 'center'
  };
  firstStakePress$ = new BehaviorSubject<boolean>(true);
  taxPercentageToShow = this.appConfigService.get('sports').coupon.stakeTaxPercentageToShow;

  private readonly unsubscribe$: Subject<boolean> = new Subject();

  constructor(
    readonly couponQuery: CouponQuery,
    private readonly appConfigService: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly currencyService: CurrencyService,
    private readonly couponService: CouponService,
    private readonly notificationService: NotificationService,
    private readonly analyticsService: AnalyticsService
  ) {
    this.showStakeReduction = this.appConfigService.get('sports').coupon.showStakeReduction;
    this.fixedStakes = this.appConfigService.get('sports').coupon.fixedStakes;
    this.currencySymbol = this.currencyService.getCurrency(this.applicationQuery.currency).symbol;
  }

  ngOnInit(): void {
    // TODO: totals
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.reportStakeInputBlur();
  }

  updateStakeValue(stakeValue: number): void {
    this.couponService.updateStakeValue(stakeValue);
  }

  resetStakeValue(): void {
    this.updateStakeValue(this.couponQuery.globalVariables.MinBetStake);
    this.firstStakePress$.next(true);
  }

  addStakeValue(amount: string): void {
    this.updateStakeValue(this.firstStakePress$.getValue() ? parseFloat(amount) : this.couponData.StakeGross + parseFloat(amount));
    this.firstStakePress$.next(false);
  }

  updateInputStakeValue(event: any): void {
    event.preventDefault();
    this.couponService.updateStakeValue(event.currentTarget.value);
  }

  updateCouponSetting(key: string, value: any): void {
    this.couponService.updateCouponSetting(key, value);
  }

  setHighlightText(event: any): void {
    this.reportStakeInputFocus();

    event.preventDefault();
    event.currentTarget.select();
  }

  indexTrackBy(index: string): string {
    return index;
  }

  truncate(value: number): number {
    return Math.trunc(value);
  }

  private reportStakeInputFocus(): void {
    this.analyticsService.sendEvent({
      Action: AnalyticsEventAction.InputFocus,
      Category: 'Coupon Stake Input'
    });
  }

  private reportStakeInputBlur(): void {
    fromEvent(this.stakeInput.nativeElement, 'focusout')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.analyticsService.sendEvent({
          Action: AnalyticsEventAction.InputBlur,
          Category: 'Coupon Stake Input',
          Value: this.couponData.StakeGross
        });
      });
  }
}
