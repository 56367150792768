import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { format, subYears } from 'date-fns';
import { map, skip } from 'rxjs/operators';
import {
  AccountMenuLinkModel,
  AccountState,
  AccountStatementFilterModel,
  AccountUIState,
  BetSearchState,
  UserModel,
  UserType
} from 'src/app/shared/models/account.model';
import { BonusModel } from 'src/app/shared/models/bonus.model';

import { VerifyAccountModel } from './../../../shared/models/account.model';
import { AccountStore } from './account.store';

@Injectable({ providedIn: 'root' })
export class AccountQuery extends Query<AccountState> {
  userData$ = this.select(state => state.userData);
  hasValidId$ = this.select(state => state.userData?.citizenIdNumber?.value);
  hasValidMobilePhone$ = this.select(state => state.userData?.mobile?.verifyType === 'VER');
  emailVerified$ = this.select(state => state.userData?.emailVerified);
  skipSessionOne$ = this.select(state => state.userData?.skipSessionOne);

  canDeposit$ = this.select(
    state =>
      state.userData?.skipSessionOne ||
      (state.userData?.citizenIdNumber?.value && state.userData?.mobile?.verifyType === 'VER' && state.userData?.emailVerified)
  );
  verifyAccountState$ = this.select(state => state.verifyAccountState);
  showUnverifiedTooltip$ = this.select(state => state.showUnverifiedTooltip);
  multipleUnconfirmedUsers$ = this.select(state => state.multipleUnconfirmedUsers);
  isAuthenticated$ = this.select(state => state.isAuthenticated);
  accessToken$ = this.userData$.pipe(map(userData => (userData && userData.accessToken) || undefined));
  userId$ = this.userData$.pipe(map(userData => (userData && userData.id) || undefined));
  betSearch$ = this.select(state => state.betSearch);
  menuItems$ = this.select(state => state.menuItems);
  helpMenuItems$ = this.select(state => state.helpMenuItems);
  transferTypes$ = this.select(state => state.transferTypes);

  accountStatements$ = this.select(state => state.accountStatements);
  approvedAndRejectedTransactions$ = this.select(state => state.financialAccountStatements).pipe(
    map(statements => {
      return statements.filter(
        statement =>
          (statement.transactionStatusCode === 'COMPLETE' && statement.amount < 0) ||
          ['REJECTED', 'ONHOLD', 'VALIDATED', 'CANCELLED'].includes(statement.transactionStatusCode)
      );
    })
  );

  viewingBonus$ = this.select(state => state.viewingBonus);
  bonusUI$ = this.select(state => state.ui.bonus);
  loading$ = this.selectLoading();
  noStatements$ = this.select(state => state.ui.noStatements);
  error$ = this.selectError();
  refreshingBalance$ = this.select(state => state.ui.refreshingBalance);
  resetPasswordOption$ = this.select(s => s.resetPasswordOption);
  resetOptionTriggered$ = this.select(s => s.resetOptionTriggered);
  changePhoneNumberOption$ = this.select(s => s.changePhoneNumberOption);
  phoneOptionTriggered$ = this.select(s => s.phoneOptionTriggered);
  isShopOwner$ = this.select(s => (s.userData ? s.userData.userTypeCode === UserType.ShopOwner : false));

  hasResetPasswordOption =
    this.getValue().resetPasswordOption !== undefined &&
    this.getValue().resetPasswordOption !== 'none' &&
    this.getValue().resetPasswordOption.length > 0;

  hasChangePhoneNumberOption =
    this.getValue().changePhoneNumberOption !== undefined &&
    this.getValue().changePhoneNumberOption !== 'none' &&
    this.getValue().changePhoneNumberOption.length > 0;
  bonuses$ = this.select(state => state.bonuses);
  currentBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isActivated && !(bonus.isExpired || bonus.isCancelled)) : []
  );
  activeBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isActivated && !(bonus.isExpired || bonus.isCancelled || bonus.isPaused)) : []
  );
  pausedBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isActivated && !(bonus.isExpired || bonus.isCancelled) && bonus.isPaused) : []
  );
  previousBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isActivated && bonus.isExpired && !bonus.isCancelled) : []
  );
  inactiveBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => !(bonus.isActivated || bonus.isExpired || bonus.isCancelled)) : []
  );
  missedBonuses$ = this.select(state =>
    state.bonuses ? state.bonuses.filter(bonus => bonus.isCancelled || (!bonus.isActivated && bonus.isExpired)) : []
  );
  bonusInfo$ = this.select(state => state.bonusInfo);
  hasBonusInfo$ = this.select(state => state.bonusInfo !== undefined && this.getValue().bonusInfo.length > 0);
  totalFunds$ = this.select(state => {
    let result = 0;
    if (state.userData) {
      state.userData.wallets.forEach(wallet => {
        result += wallet.balance;
      });
    }
    return result;
  });
  wallets$ = this.select(state => (state.userData ? state.userData.wallets : []));
  transferCandidates$ = this.select(state => state.transferCandidates);

  constructor(protected store: AccountStore) {
    super(store);
  }
  get hasValidId(): boolean {
    return !!this.getValue().userData?.citizenIdNumber?.value;
  }
  get skipSessionOne(): boolean {
    return !!this.getValue().userData?.skipSessionOne;
  }
  get hasValidMobilePhone(): boolean {
    return this.getValue().userData?.mobile?.verifyType === 'VER';
  }
  get emailVerified(): boolean {
    return this.getValue().userData?.emailVerified;
  }

  get canDeposit(): boolean {
    return (
      this.getValue().userData?.skipSessionOne ||
      (this.getValue().userData?.citizenIdNumber?.value &&
        this.getValue().userData?.mobile?.verifyType === 'VER' &&
        this.getValue().userData?.emailVerified)
    );
  }

  get resetPasswordOption(): string {
    return this.getValue().resetPasswordOption;
  }

  get changePhoneNumberOption(): string {
    return this.getValue().changePhoneNumberOption;
  }

  get userData(): UserModel {
    return this.getValue().userData;
  }

  get verifyAccountState(): VerifyAccountModel {
    return this.getValue().verifyAccountState;
  }

  get showUnverifiedTooltip(): boolean {
    return this.getValue().showUnverifiedTooltip;
  }

  get multipleUnconfirmedUsers(): boolean {
    return this.getValue().multipleUnconfirmedUsers;
  }

  get betSearch(): BetSearchState {
    return this.getValue().betSearch;
  }

  get menuItems(): AccountMenuLinkModel[] {
    return this.getValue().menuItems;
  }

  get isAuthenticated(): boolean {
    return this.getValue().isAuthenticated;
  }

  get uiState(): AccountUIState {
    return this.getValue().ui;
  }

  get accessToken(): string {
    if (this.isAuthenticated) {
      return this.userData.accessToken;
    }
    return undefined;
  }

  get accountStatementFilter(): AccountStatementFilterModel {
    return this.getValue().accountStatementFilter;
  }

  get transferTypes(): number[] {
    return this.getValue().transferTypes.map(item => item.id);
  }

  get isViewingAccountStatement(): boolean {
    return this.getValue().accountStatements.length > 0;
  }

  get isViewingBonus(): boolean {
    return this.getValue().viewingBonus !== undefined;
  }

  get refreshingBalance(): boolean {
    return this.getValue().ui.refreshingBalance;
  }

  get dobUpperLimit(): string {
    return format(subYears(new Date(), 18), 'yyyy-MM-dd');
  }

  get bonusInfo(): BonusModel[] {
    return this.getValue().bonusInfo;
  }

  get hasBonusInfo(): boolean {
    return this.getValue().bonusInfo !== undefined && this.getValue().bonusInfo.length > 0;
  }

  getBonus(id: number): BonusModel {
    return this.getValue().bonuses.find(bonus => bonus.id === id);
  }

  bonusInfoByCode(code: number | string): BonusModel {
    return this.getValue().bonusInfo && this.getValue().bonusInfo.find(bi => bi.bonusCode === code);
  }

  transferCandidateBalance(userId: string): number {
    return this.getValue().transferCandidates !== undefined && this.getValue().transferCandidates.find(tc => tc.userId === userId).balance;
  }
}
