<app-breadcrumb [title]="'Password reset' | transloco" icon="password-reset"></app-breadcrumb>
<div class="reset-password-container">
  <div class="header" *ngIf="!isAuthenticatedStaticValue" transloco="Reset Password"></div>
  <div class="header" *ngIf="isAuthenticatedStaticValue" transloco="Change Password"></div>
  <div
    *ngIf="((tokenValidationResult$ | async)?.isValid || (accountQuery.isAuthenticated$ | async)) && (passwordResetSuccess$ | async) === undefined">
    <div class="title" *ngIf="(accountQuery.isAuthenticated$ | async) === false">
      <span transloco="You have reached this page following your password recovery request for your username:"></span>
      <span class="user-name">“{{ (tokenValidationResult$ | async)?.tokenOwnerName }}”. </span>
      <span transloco="Please enter your new password."></span>
    </div>
    <div class="password-reset-form" (keydown.enter)="onSubmit()">
      <div class="logged-in-part input" *ngIf="accountQuery.isAuthenticated$ | async">
        <span class="label" transloco="Enter Your Current Password"></span>
        <div class="login-element current-password">
          <input [type]="(showPassword$ | async) ? 'text' : 'password'" [formControl]="currentPassword"
            [placeholder]="'Current password' | transloco" class="current-password">
          <span (click)="togglePasswordVisibility()" class="fa toggle-password"
            [class.fa-eye]="(showPassword$ | async) === false" [class.fa-eye-slash]="(showPassword$ | async)"></span>
        </div>
        <!-- <app-form-message type="danger" *ngIf="currentPassword.touched && !!currentPassword.errors?.pattern"
          text="The password does not match the required criteria below. Please try with a different password.">
        </app-form-message> -->
        <app-form-message type="danger" *ngIf="currentPasswordError$ | async"
          [text]="'Wrong current password' | transloco">
        </app-form-message>
      </div>
      <form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()"
        [ngClass]="{'valid-form': (newPasswordForm.statusChanges | async) === 'VALID'}">
        <span class="input">
          <span class="label" transloco="New Password"></span>
          <div class="login-element">
            <input type="password" formControlName="newPassword" [placeholder]="'New Password' | transloco"
              class="password">
          </div>
          <app-form-message type="danger" *ngIf="newPasswordForm.controls['newPassword'].touched
                  && newPasswordForm.controls['newPassword'].errors?.required"
            [text]="'The password is required' | transloco">
          </app-form-message>
          <app-form-message type="danger" *ngIf="newPasswordForm.controls['newPassword'].touched
                  && newPasswordForm.controls['newPassword'].errors?.minlength
                  && !(newPasswordForm.controls['newPassword'].errors?.required)"
            [text]="'Password must be between 6 to 25 characters long' | transloco"></app-form-message>
          <app-form-message type="danger" *ngIf="newPasswordForm.controls['newPassword'].touched
                  && newPasswordForm.controls['newPassword'].errors?.maxlength
                  && !(newPasswordForm.controls['newPassword'].errors?.required)"
            [text]="'Password must be between 6 to 25 characters long' | transloco"></app-form-message>
          <app-form-message type="danger" *ngIf="newPasswordForm.controls['newPassword'].touched
                  && newPasswordForm.controls['newPassword'].errors?.needsUppercase
                  && !(newPasswordForm.controls['newPassword'].errors?.required)"
            [text]="'The password must contain an UPPERCASE letter' | transloco"></app-form-message>
          <app-form-message type="danger" *ngIf="newPasswordForm.controls['newPassword'].touched
                  && newPasswordForm.controls['newPassword'].errors?.needsLowercase
                  && !(newPasswordForm.controls['newPassword'].errors?.required)"
            [text]="'The password must contain a LOWERCASE letter' | transloco"></app-form-message>
          <app-form-message type="danger" *ngIf="newPasswordForm.controls['newPassword'].touched
                  && (newPasswordForm.controls['newPassword'].errors?.hasSpaces)
                  && !(newPasswordForm.controls['newPassword'].errors?.required)"
            [text]="'The password must not contain spaces' | transloco"></app-form-message>
          <app-form-message type="danger" *ngIf="!!newPasswordForm.errors?.containsUsername"
            [text]="'The password must not contain the username' | transloco"></app-form-message>
        </span>
        <span class="input">
          <span class="label" transloco="Confirm New Password"></span>
          <div class="login-element">
            <input type="password" formControlName="confirmNewPassword"
              [placeholder]="'Confirm New Password' | transloco" class="password">
          </div>
          <div *ngIf="newPasswordForm.controls['confirmNewPassword'].touched">
            <app-form-message type="danger" *ngIf="!!newPasswordForm.errors?.mismatch"
              [text]="'Passwords not matching' | transloco">
            </app-form-message>
            <!-- <app-form-message type="danger"
                *ngIf="newPasswordForm.controls['confirmNewPassword'].touched && newPasswordForm.controls['confirmNewPassword'].errors?.pattern"
                text="The password does not match the required criteria below. Please try with a different password.">
              </app-form-message> -->
            <!-- <app-form-message type="danger" *ngIf="newPasswordForm.controls['confirmNewPassword'].errors?.minlength"
              text="The password contains less than the minimum number of required characters."></app-form-message>
            <app-form-message type="danger" *ngIf="newPasswordForm.controls['confirmNewPassword'].errors?.maxlength"
              text="The password exceeds the maximum number of permitted characters."></app-form-message> -->
          </div>
        </span>
      </form>
    </div>
    <div class="subtitle"
      transloco="Password must be between 6 to 25 characters long, one uppercase letter and one lowercase letter, must not contain spaces, and must not contain the username.">
    </div>
    <div class="action-buttons">
      <app-button (btnClick)="onSubmit()" [disabled]="newPasswordForm.invalid" [text]="'Change Password' | transloco"
        buttonType="success" class="reset-password-button"></app-button>
    </div>
  </div>
  <div class="fill-the-space"
    *ngIf="(tokenValidationResult$ | async) && !(tokenValidationResult$ | async)?.isValid && (accountQuery.isAuthenticated$ | async) === false">
    <app-form-message type="danger" *ngIf="!(tokenValidationResult$ | async).expired"
      text="The url token is not valid.<br />Please try resetting your password again."></app-form-message>
    <app-form-message type="danger" *ngIf="(tokenValidationResult$ | async).expired"
      text="The email link has expired.<br />Please try resetting your password again."></app-form-message>
  </div>
</div>