import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { SportradarAnaylyticsDto } from 'src/app/shared/models/analytics.model';
import { environment } from 'src/environments/environment';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class SportradarAnalyticsService {

  constructor(private readonly appConfig: AppConfigService) { }

  init(): void {
    const id: string = this.appConfig.get('sportRadarAnalyticsId');
    const url: string = this.appConfig.get('apiBaseUrl')?.sportRadarAnalytics;
    if (id && url) {
      this.loadScripts(url, id);
    }
    else {
      console.log('Programatic SDK not loaded')
    }
  }

  loadScripts(url: string, id: string) {
    window.srtmCommands = window.srtmCommands ?? [];
    const script1 = document.createElement('script');
    script1.src = `${url}?id=${id}`;
    const script2 = document.getElementsByTagName("script")[0];
    script1.setAttribute("data-srtmn", "srtmCommands");
    script2.parentNode.insertBefore(script1, script2);
  }

  sendEvent(event: SportradarAnaylyticsDto): void {
    if (!window.srtmCommands) window.srtmCommands = [];
    window?.srtmCommands?.push(event);
  }

  sendRegistrationStartEvent(): void {
    this.sendEvent(
      {
        event: "track.user.registration",
        payload: {
          action: "start"
        }
      }
    )
  }

  sendRegistrationSuccessEvent(userId: number): void {
    this.sendEvent(
      {
        event: "track.user.registration",
        payload: {
          action: "complete",
          userId: userId.toString()
        }
      }
    )
  }

  sendLoginSuccessEvent(userId: number): void {
    this.sendEvent(
      {
        event: "track.user.login",
        payload: {
          action: "complete",
          userId: userId.toString()
        }
      }
    )
  }
}
