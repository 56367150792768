import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { HttpClient } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class PayoutPromoService {
  promotionsEngineBaseUrl: string;
  constructor(private readonly notificationService: NotificationService, private readonly http: HttpClient) {
    this.promotionsEngineBaseUrl = environment.appConfigDefaults.apiBaseUrl.promotionsEngine;
  }

  async getShareData(transactionId: number): Promise<{ transactionId: number; hash: string }> {
    return this.http
      .get(`${this.promotionsEngineBaseUrl}/payout/share-transaction/${transactionId}`)
      .pipe(
        first(),
        map((response: { transactionId: number; hash: string }) => ({
          transactionId: response.transactionId,
          hash: response.hash
        }))
      )
      .toPromise();
  }
  async getIfTransactionQualifies(transactionId: number): Promise<{ qualifies: string }> {
    return this.http
      .get(`${this.promotionsEngineBaseUrl}/payout/${transactionId}`)
      .pipe(
        first(),
        map((response: { qualifies: string }) => ({
          qualifies: response.qualifies
        }))
      )
      .toPromise();
  }

  public async sharePayoutPromo(transactionId: number): Promise<void> {
    const shareResult = await this.getShareData(transactionId);
    const url = `${window.location.origin}/account/withdrawal/promotion/shared/${shareResult.transactionId}?sh=${shareResult.hash}`;
    const anyWindow = window as any;
    const sharePayload = {
      url: url,
      title: 'Keš na vreme'
    };
    if (anyWindow?.navigator?.canShare?.(sharePayload)) {
      window.navigator
        .share(sharePayload)
        .then(() => {
          // this.notificationService.showSuccess(translate('account.share.success'));
          console.log('share success');
        })
        .catch(() => {
          console.log('share error');
          // this.notificationService.showError(translate('account.share.error'));
        });
    } else {
      window.navigator.clipboard.writeText(`Keš na vreme, na Victoryju! ${url}`).then(() => {
        this.notificationService.showSuccessMessage('Link ka tvojoj isplati je kopiran. Podeli sa ekipom!', 3000);
      });
    }
  }
}
