import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ButtonModel, ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit, OnChanges {
  @Input() text: string;
  @Input() faIcon: string;
  @Input() type: string | 'button';
  @Input() buttonType: ButtonType | 'success' | 'danger' | 'info';
  @Input() disabled: boolean;
  @Input() fullWidth: boolean;
  @Input() customButtonStyle: any = {};
  @Input() loading = false;
  @Input() allCaps = false;
  @Output() readonly btnClick = new EventEmitter();
  buttonSettings: ButtonModel;

  ngOnChanges(changes: SimpleChanges): void {
    // If the button text changes, it's updates it
    if (this.buttonSettings && changes.text) {
      this.buttonSettings.text = changes.text.currentValue;
    }

    if (this.buttonSettings && changes.loading) {
      this.buttonSettings.loading = changes.loading.currentValue;
    }
  }

  ngOnInit(): void {
    switch (this.buttonType) {
      case 'success':
        this.buttonType = ButtonType.Success;
        break;
      case 'danger':
        this.buttonType = ButtonType.Danger;
        break;
      case 'info':
        this.buttonType = ButtonType.Accent;
        break;
      default:
        break;
    }
    this.buttonSettings = new ButtonModel({
      text: this.text,
      faIcon: this.faIcon,
      buttonType: this.buttonType,
      type: this.type,
      loading: this.loading
    });
  }

  onClick(): void {
    if (this.disabled) {
      return;
    }

    this.btnClick.emit();
  }
}
