<app-breadcrumb title="{{ paymentTitle }}" faIcon="{{ paymentIcon }}"></app-breadcrumb>

<!-- Witdrawal -->
<div
  class="no-kyc-documents-message"
  [ngClass]="[accountService.getKYCDocumentsStatus()]"
  *ngIf="!isDeposit && accountService.getKYCDocumentsStatus() !== 'verified'"
>
  <div class="warning-message">
    <span
      *ngIf="KYCDocumentStatus === 'accountNumberNeeded'"
      transloco="To make a withdrawal, you need to verify your bank account information."
    ></span>
    <span
      *ngIf="KYCDocumentStatus === 'documentsNeeded' || !(isSessionOneComplete$ | async)"
      transloco="In order to make a withdrawal, you need to complete registration and verify your personal information."
    ></span>
    <span
      *ngIf="KYCDocumentStatus === 'pending'"
      transloco="Victory operators are verifying your data. You will soon be notified about the status of your verification via e-mail."
    ></span>
    <span
      *ngIf="KYCDocumentStatus === 'fraud'"
      transloco="Your identity was not successfully verified. Victory Customer Support operators will contact you soon with all the details."
    ></span>
    <!-- <span
      *ngIf="!(isSessionOneComplete$ | async)"
      transloco="In order to make a withdrawal, you need to complete registration and verify your personal information."
    ></span> -->
  </div>
  <div
    class="secondary-button large"
    *ngIf="KYCDocumentStatus === 'documentsNeeded' || KYCDocumentStatus === 'accountNumberNeeded'"
    (click)="startBlinkingSession()"
  >
    <span class="btn-text" transloco="VERIFY DATA"></span>
  </div>
</div>

<!-- Deposit -->
<div
  class="no-kyc-documents-message"
  [ngClass]="[accountService.getKYCDocumentsStatus()]"
  *ngIf="
    isDeposit && accountService.getKYCDocumentsStatus() !== 'verified' && accountService.getKYCDocumentsStatus() !== 'accountNumberNeeded'
  "
>
  <div class="warning-message">
    <span
      *ngIf="accountService.getKYCDocumentsStatus() === 'documentsNeeded'"
      transloco="In order to make a deposit, you need to verify your personal information."
    ></span>
    <span
      *ngIf="accountService.getKYCDocumentsStatus() === 'pending'"
      transloco="Victory operators are verifying your data. You will soon be notified about the status of your verification via e-mail."
    ></span>
    <span
      *ngIf="accountService.getKYCDocumentsStatus() === 'fraud'"
      transloco="Your identity was not successfully verified. Victory Customer Support operators will contact you soon with all the details."
    ></span>
  </div>
  <div class="secondary-button large" *ngIf="accountService.getKYCDocumentsStatus() === 'documentsNeeded'" (click)="startBlinkingSession()">
    <span class="btn-text" transloco="VERIFY DATA"></span>
  </div>
</div>
<div class="payout-and-banner-container">
  <div
    class="banners"
    [ngClass]="{ show: withdrawalBannerAvailable }"
    *ngIf="(applicationService.isDesktop$ | async) && (shouldBannerBeVisible$ | async)"
  >
    <app-banner-rotator></app-banner-rotator>
  </div>
  <div class="payout-and-request-wrapper">
    <div class="payment-tab-wrapper">
      <div
        class="grouping-tab"
        transloco="Payment"
        (click)="showTab('paymentTab')"
        [class.selected]="activeGroupingTab === 'paymentTab'"
      ></div>
      <div
        class="grouping-tab"
        transloco="Request"
        (click)="showTab('requestsTab')"
        [class.selected]="activeGroupingTab === 'requestsTab'"
      ></div>
    </div>
    <div class="payment-wrapper" *ngIf="activeGroupingTab === 'paymentTab'">
      <app-card [canCollapse]="false">
        <div body>
          <ng-container *ngIf="{ cmsData: cmsData$ | async } as subscription">
            <app-loading-bar [show]="!subscription.cmsData || !subscription.cmsData.length || subscription.cmsData.length === 0">
            </app-loading-bar>
            <app-banner-rotator *ngIf="!(applicationService.isDesktop$ | async)"></app-banner-rotator>

            <app-withdrawal
              *ngFor="let payment of subscription.cmsData; let i = index; trackBy: paymentListTrackBy"
              [ngClass]="{ disabled: (enablePayments$ | async) === false || payment.status === -1 }"
              (onContinue)="showRequestsTab()"
            ></app-withdrawal>

            <div *ngIf="!hideContent">
              <div
                class="content"
                *ngFor="let payment of subscription.cmsData; let i = index; trackBy: paymentListTrackBy"
                [@fadeIn]
                [ngClass]="{ disabled: (enablePayments$ | async) === false || payment.status === -1 }"
              >
                <div class="payment-title">
                  <span>{{ payment.name }}</span>
                </div>
                <div class="payment-description" ini>
                  <span class="show-less">{{ payment.description }}</span>
                  <button class="btn-more" appShowMore transloco="more"></button>
                </div>
                <div class="payment-content">
                  <div class="payment-image">
                    <img src="{{ payment.imageIdentifer }}" alt="{{ payment.identifier }}" />
                  </div>
                  <div class="payment-details">
                    <div class="text-details-fees-section">
                      <span class="header" transloco="Fees:"> </span>
                      <span class="value" [ngSwitch]="payment.fees">
                        <span *ngSwitchCase="'None'" transloco="None"></span>
                        <span *ngSwitchCase="'Bank fees may be deducted'" transloco="Bank fees may be deducted"></span>
                        <span *ngSwitchDefault>{{ payment.fees }}</span>
                      </span>
                    </div>
                    <div class="text-details-section">
                      <span class="minimum-deposit-header">{{ 'Minimum' | transloco }} {{ paymentTitle }}:</span>
                      <span class="minimum-deposit-value">
                        {{ payment.minimumLimit || 0 | currencyFormat }}
                      </span>
                    </div>
                    <div class="btn-section">
                      <button
                        class="primary-button large"
                        (click)="redirect(payment.identifier, payment.minimumLimit, payment.maximumLimit)"
                        [disabled]="(enablePayments$ | async) === false || payment.status === -1"
                        analytics
                        [click]="true"
                        [category]="'Deposit Now Button'"
                        [label]="payment.name"
                      >
                        {{ paymentButton }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="disabled-disclaimer" *ngIf="payment.status === -1">
                  ( {{ paymentTitle }} {{ 'Channel Currently Unavailable' | transloco }} )
                </div>

                <div class="disabled-overlay" *ngIf="(enablePayments$ | async) === false"></div>
              </div>
              <div *ngIf="subscription.cmsData">
                <div class="bottom-disclaimer" *ngIf="isDeposit">
                  {{ accountPaymentQuery.paymentsListExtraDetails$ | async }}
                </div>
                <div class="bottom-disclaimer" *ngIf="!isDeposit">
                  {{ accountPaymentQuery.paymentsListExtraDetails$ | async }}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </app-card>
    </div>
    <div class="requests-wrapper" *ngIf="activeGroupingTab === 'requestsTab'">
      <div
        class="empty-requests"
        *ngIf="
          !(accountPaymentQuery.hasPendingWithdrawals$ | async) && (accountQuery.approvedAndRejectedTransactions$ | async)?.length === 0
        "
      >
        <p>
          {{ 'There are currently no payout requests to view.' | transloco }}
        </p>
      </div>
      <div class="pending-withdrawals-wrapper" *ngIf="accountPaymentQuery.hasPendingWithdrawals$ | async" [@fadeIn]>
        <div
          class="pending-withrawal-container"
          [@fadeIn]
          *ngFor="let reWithdrawal of accountPaymentQuery.pendingWithdrawals$ | async; let i = index; trackBy: pendingWithdrawalsTrackBy"
        >
          <div class="info-container">
            <span transloco="Payment in processing"></span>
          </div>

          <div class="details-container">
            <div class="date-wrapper">
              <span class="date-label" transloco="Created:"> </span>
              <span class="date-value">{{ reWithdrawal.transectionDate | date : 'dd.MM.yyyy, HH:mm:ss' }}</span>
            </div>
            <div class="amount-wrapper">
              <span class="amount-label" transloco="Payout amount:"> </span>
              <span class="amount-value">{{ reWithdrawal.amount * -1 | currencyFormat }}</span>
            </div>
          </div>

          <div class="button-container">
            <div class="promotion-btn-wrapper" *ngIf="isPayoutQualified(reWithdrawal)">
              <iframe class="timer-iframe" [src]="getIframeUrl(reWithdrawal)" frameborder="0"></iframe>
              <button class="promo-btn" (click)="goToPendingPromoPayoutDetails(reWithdrawal)" transloco="See promotion"></button>
            </div>
            <div class="single-btn-wrapper">
              <button (click)="initiateWithdrawalCancelation(reWithdrawal)" transloco="Cancel withdrawal"></button>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let statement of payoutTransactionsWithPromoDetails$ | async; trackBy: statementsTrackBy">
        <div class="completed-withdrawals-wrapper" [@fadeIn] *ngIf="statementApproved(statement)">
          <div class="completed-withrawal-container" [@fadeIn]>
            <div class="info-container">
              <span transloco="Payment approved"></span>
            </div>
            <div class="details-container">
              <div class="date-wrapper">
                <span class="date-label" transloco="Created:"> </span>
                <span class="date-value">{{ statement.transactionDate | date : 'dd.MM.yyyy, HH:mm:ss' }}</span>
              </div>
              <div class="date-wrapper" *ngIf="statement.qualified">
                <span class="date-label" transloco="Approved:"> </span>
                <span class="date-value">{{ statement.closedDate | date : 'dd.MM.yyyy, HH:mm:ss' }}</span>
              </div>
              <div class="amount-wrapper">
                <span class="amount-label" transloco="Payout amount:"> </span>
                <span class="amount-value">{{ statement.amount * -1 | currencyFormat }}</span>
              </div>
              <div class="cash-on-time-wrapper" *ngIf="statement.qualified">
                <div class="cash-won">
                  <span class="cash-won-label" transloco="Cash on time:"></span>
                  <span class="cash-won-value">{{ statement.cashAward | currencyFormat }}</span>
                </div>
                <div class="fs-won">
                  <span class="fs-label" transloco="Free spins:"></span>
                  <span class="fs-value">{{ statement.spinsAward ?? 0 }}</span>
                </div>
                <div class="buttons-wrapper">
                  <button class="details" (click)="showPromoPayoutDetails(statement)" transloco="Payout details"></button>
                  <button class="share" (click)="sharePayoutPromo(statement)">
                    <img src="../../../../../assets/images/payments/share-icon.svg" alt="" />
                    {{ 'Share ticket' | transloco }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rejected-withdrawals-wrapper" [@fadeIn] *ngIf="statementDenied(statement)">
          <div class="rejected-withrawal-container" [@fadeIn]>
            <div class="info-container">
              <span transloco="Payment rejected"></span>
            </div>
            <div class="details-container">
              <div class="date-wrapper">
                <span class="date-label" transloco="Created:"> </span>
                <span class="date-value">{{ statement.transactionDate | date : 'dd.MM.yyyy, HH:mm:ss' }}</span>
              </div>
              <div class="date-wrapper" *ngIf="statement.qualified">
                <span class="date-label" transloco="Rejected:"> </span>
                <span class="date-value">{{ statement.closedDate | date : 'dd.MM.yyyy, HH:mm:ss' }}</span>
              </div>
              <div class="amount-wrapper">
                <span class="amount-label" transloco="Payout amount:"> </span>
                <span class="amount-value">{{ statement.amount * -1 | currencyFormat }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
