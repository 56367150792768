<ng-container *ngIf="(sportQuery.selectedQuicklink$ | async) && (sportQuery.eventSelectionDepth$ | async) === 1">
  <app-proceed-bar [depth]="1"
    *ngIf="((sportQuery.selectedQuicklink$ | async).id === 2 && (sportQuery.competitionsAZSelectedElements$ | async) && (sportQuery.competitionsAZSelectedElements$ | async).length)">
  </app-proceed-bar>

  <app-proceed-bar [depth]="1"
    *ngIf="((sportQuery.selectedQuicklink$ | async).id === 0 && (sportQuery.topCompetitionsSelectedElements$ | async) && (sportQuery.topCompetitionsSelectedElements$ | async).length)">
  </app-proceed-bar>

  <app-proceed-bar [depth]="1" eventType="prematch" *ngIf="((sportQuery.selectedQuicklink$ | async).id === 4 &&
    (sportQuery.allCompetitionByCountrySelectedElements$ | async) &&
    (sportQuery.allCompetitionByCountrySelectedElements$ | async).length)">
  </app-proceed-bar>

  <app-proceed-bar [depth]="1" eventType="outright"
    *ngIf="((sportQuery.selectedQuicklink$ | async).id === 5 && (sportQuery.outrightsSelectedElements$ | async) && (sportQuery.outrightsSelectedElements$ | async).length)">
  </app-proceed-bar>

  <app-proceed-bar [depth]="1" eventType="special"
    *ngIf="((sportQuery.selectedQuicklink$ | async).id === 6 && (sportQuery.specialSportsSelectedElements$ | async) && (sportQuery.specialSportsSelectedElements$ | async).length)">
  </app-proceed-bar>

  <app-proceed-bar [depth]="1" eventType="special"
    *ngIf="((sportQuery.selectedQuicklink$ | async).id === 7 && (sportQuery.goalscorerSportSelectedElements$ | async) && (sportQuery.goalscorerSportSelectedElements$ | async).length)">
  </app-proceed-bar>
</ng-container>

<ng-container *ngIf="(sportQuery.eventSelectionDepth$ | async) === 2">
  <app-proceed-bar [depth]="2"
    *ngIf="
  ((sportQuery.allCompetitionByCountrySelectedElementsDepth2$ | async) && (sportQuery.allCompetitionByCountrySelectedElementsDepth2$ | async).length) ||
  ((sportQuery.outrightsSelectedElementsDepth2$ | async) && (sportQuery.outrightsSelectedElementsDepth2$ | async).length)">
  </app-proceed-bar>

  <app-proceed-bar [depth]="2"
    *ngIf="
  ((sportQuery.specialSportsSelectedElementsDepth2$ | async) && (sportQuery.specialSportsSelectedElementsDepth2$ | async).length) ||
  ((sportQuery.goalscorerSportSelectedElementsDepth2$ | async) && (sportQuery.goalscorerSportSelectedElementsDepth2$ | async).length)">
  </app-proceed-bar>
</ng-container>


<app-proceed-bar [depth]="3" *ngIf="(sportQuery.eventSelectionDepth$ | async) === 3 &&
  ((sportQuery.selectedPrematchSelectedElementCount$ | async) || (sportQuery.selectedPrematchPlayersDataSelectedElementCount$ | async)) &&
  (sportQuery.filterToDepthFour$ | async) === false">
</app-proceed-bar>
