import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MyOpenBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';
import { MyOpenBetsStore } from './my-open-bets.store';

@Injectable({ providedIn: 'root' })
export class MyOpenBetsQuery extends QueryEntity<MyOpenBetsState, RecentBetModel> {
  bets$ = this.selectAll();
  openBetsCount$ = this.selectCount();
  isLoading$ = this.selectLoading();

  constructor(protected store: MyOpenBetsStore) {
    super(store);
  }
}
