import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { kebabCase } from 'lodash-es';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { SearchQuery } from 'src/app/core/state/search/search.query';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { TodaysEventsQuery } from 'src/app/core/state/todays-events/todays-events.query';
import { MatchModel } from 'src/app/shared/models/sport.model';
import { SortType } from 'src/app/shared/models/todays-events.model';

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss']
})
export class EventHeaderComponent implements OnInit {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showOddCount: boolean = true;
  @Input() showDate: boolean = false;
  @Input() fullVersion: boolean = true;
  @Input() showTournament: boolean = true;
  @Input() specials?: boolean;
  productIconClass: string;
  sortType = SortType;

  constructor(
    readonly statisticsQuery: StatisticsQuery,
    readonly statisticsService: StatisticsService,
    readonly searchQuery: SearchQuery,
    readonly todaysEventsQuery: TodaysEventsQuery,
    private readonly matchService: MatchService,
    private readonly appConfigService: AppConfigService,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.productIconClass = `icon-${this.sportId}`;
  }

  openStatistics(): void {
    this.statisticsService.statisticsBetRadar(this.event.externalId);
  }

  navigateToMatch(): void {
    this.matchService.navigateToMatch(this.event.id, this.event.name);
  }

  navigateToEvent(): void {
    if (!this.event.odds[0]) {
      return;
    }

    const sport = kebabCase(this.event.odds[0].sportName);
    const category = kebabCase(this.event.categoryName);
    const tournament = kebabCase(this.event.tournamentName);
    const url = `/sports/events/prematch/${sport}/${category}/${tournament}`;

    this.router.navigate([url]);
  }

  get isHomeRoute(): boolean {
    return this.router.url === '/';
  }

  get isOutright(): boolean {
    return this.router.url.includes('/outright/');
  }
}
