/* tslint:disable */
import { Injectable } from '@angular/core';

import { translate } from '@ngneat/transloco';
import { BehaviorSubject, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, finalize, first, map } from 'rxjs/operators';
import { DynamicScriptLoaderService } from 'src/app/core/services/dynamic-script-loader.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountPaymentStore } from 'src/app/core/state/account/account.payments.store';
import {
  BankDetailsModel,
  BanksModel,
  ChannelsModel,
  DepositPaymentType,
  PaymentDetailsModel,
  PaymentDisplayState,
  PaymentListModel,
  PaymentStatus,
  PaymentUIState,
  PayoutPromoConfig,
  PayoutPromotionTransaction,
  PendingWithdrawalsModel,
  WithdrawalPaymentType
} from 'src/app/shared/models/account.model';
import { APIType } from 'src/app/shared/models/api.model';

import { DebitCardModel } from '../../../shared/models/account.model';
import { APIService } from '../api.service';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AccountPaymentService {
  showingIframe$ = new BehaviorSubject<boolean>(false);
  constructor(
    private readonly apiService: APIService,
    private readonly httpService: HttpClient,
    private readonly accountPaymentStore: AccountPaymentStore,
    private readonly notificationService: NotificationService,
    private readonly accountPaymentQuery: AccountPaymentQuery,
    private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
    private readonly accountQuery: AccountQuery
  ) {}

  getPendingWithdrawals(qtyPerPage: any = ''): Observable<any> {
    this.accountPaymentStore.updatePayments({ pendingWithdrawals: [] });

    const body = {
      PageNumber: '',
      PageSize: qtyPerPage
    };

    return this.apiService.post<any>(APIType.Platform, 'api/Finance/Transactions/GetPendingWithdrawals', body).pipe(
      map(response => {
        if (response === undefined || !response.Result) {
          return undefined;
        }

        const pendingWithdrawals: PendingWithdrawalsModel[] = [];
        response.Result.forEach(reWithdrawalData => {
          pendingWithdrawals.push(this.mapPandingWithdrawalToModel(reWithdrawalData));
        });

        this.accountPaymentStore.updatePayments({ pendingWithdrawals });
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(translate('Pending Withdrawals Error'), error.statusText);
        return throwError(error);
      })
    );
  }

  getQualifiedWithdrawals(): Observable<any> {
    this.accountPaymentStore.updateQualifiedWithdrawals([]);

    return this.httpService.get<any>(`${this.promotionsEngineUrl}/payout/qualified-transactions`).pipe(
      map(response => {
        if (response === undefined) {
          return undefined;
        }

        const qualifiedPromotions: PayoutPromotionTransaction[] = [];
        response.forEach(rePayoutTransaction => {
          qualifiedPromotions.push(new PayoutPromotionTransaction(rePayoutTransaction));
        });

        this.accountPaymentStore.updateQualifiedWithdrawals(qualifiedPromotions);
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(translate('Qualified Withdrawals Error'), error.statusText);
        return throwError(error);
      })
    );
  }
  getPayoutPromoConfig(): Observable<any> {
    this.accountPaymentStore.updatePayoutPromoConfig(null);
    return this.httpService.get<any>(`${this.promotionsEngineUrl}/payout`).pipe(
      map(response => {
        if (response === undefined) {
          return undefined;
        }

        const payoutPromoConfig: PayoutPromoConfig = response;
        this.accountPaymentStore.updatePayoutPromoConfig(payoutPromoConfig);
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(translate('Qualified Withdrawals Error'), error.statusText);
        return throwError(error);
      })
    );
  }
  cancelPendingWithdrawal(id: number, transactionTypeId: number): Observable<any> {
    const body = {
      TransactionId: id,
      TransactionTypeId: transactionTypeId
    };
    return this.apiService.post<any>(APIType.Platform, '/api/Finance/Transactions/CancelWithdrawal', body);
  }

  clearPayments(): void {
    this.accountPaymentStore.update({ payments: undefined });
  }
  isVerified(): boolean {
    return (
      (this.accountQuery.hasValidId && this.accountQuery.emailVerified && this.accountQuery.hasValidMobilePhone) ||
      this.accountQuery.skipSessionOne
    );
  }

  getDepositPaymentList(enabledPayments: boolean): Observable<any> {
    this.accountPaymentStore.setLoading(true);

    let apiCall: string;
    if (enabledPayments && this.isVerified()) {
      apiCall = '/api/PaymentVendors/PaymentMethods/Deposit';
    } else {
      apiCall = '/api/PaymentVendors/PaymentMethods/AllDepositVendors';
    }

    return forkJoin([this.apiService.get<any>(APIType.Platform, apiCall), this.apiService.get(APIType.CMS, 'account')]).pipe(
      map(res => {
        const platformDataDeposit = res[0];
        const cmsDataDeposit = res[1].Deposit;

        if (platformDataDeposit.Result === undefined || cmsDataDeposit.methods === undefined) {
          return undefined;
        }

        const paymentsDeposit: PaymentListModel[] = [];

        platformDataDeposit.Result.forEach((data: { Identifier: any }, index) => {
          const cmsPayment: any = cmsDataDeposit.methods.find((dataCMS: { payment_id: any }) => dataCMS.payment_id === data.Identifier);
          if (cmsPayment) {
            paymentsDeposit.push(
              this.mapPaymentDataToModel(
                data,
                {
                  ...cmsPayment,
                  status: this.getPaymentMethodStatus(cmsPayment.status)
                },
                index + 1
              )
            );
          }
        });
        this.accountPaymentStore.updateHints({ cvv: res[1].CvvHint, saveCard: res[1].SaveCardHint, spinePay: res[1].SpinePayHint });
        this.accountPaymentStore.updateSavedCardsDisclaimer(res[1].savedCardsDisclaimer);
        this.accountPaymentStore.updatePayments({ paymentListExtraDetailsModel: cmsDataDeposit.instructions });

        this.accountPaymentStore.updatePayments({
          paymentsListDeposit: paymentsDeposit.sort((a, b) => a.displayOrder - b.displayOrder)
        });
        this.accountPaymentStore.setLoading(false);
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(
          translate('Could not retrieve payment method list'),
          translate('An error has occurred. Please try again.')
        );
        return throwError(error);
      })
    );
  }

  getWithdrawalPaymentList(enabledPayments: boolean): Observable<any> {
    this.accountPaymentStore.setLoading(true);

    let apiCall: string;
    if (enabledPayments) {
      apiCall = '/api/PaymentVendors/PaymentMethods/Withdrawal';
    } else {
      apiCall = '/api/PaymentVendors/PaymentMethods/AllWithdrawalVendors';
    }

    return forkJoin([this.apiService.get<any>(APIType.Platform, apiCall), this.apiService.get(APIType.CMS, 'account')]).pipe(
      finalize(() => {
        this.accountPaymentStore.setLoading(false);
      }),
      map(res => {
        const platformDataWithdrawal = res[0];
        const cmsDataWithdrawal = res[1].Withdrawal;
        if (platformDataWithdrawal.Result === undefined) {
          return undefined;
        }

        const paymentsWithdrawal: PaymentListModel[] = [];

        platformDataWithdrawal.Result.forEach((data: { Identifier: any }, index) => {
          const cmsPayment: any = cmsDataWithdrawal.methods.find((dataCMS: { payment_id: any }) => dataCMS.payment_id === data.Identifier);

          if (cmsPayment) {
            paymentsWithdrawal.push(
              this.mapPaymentDataToModel(
                data,
                {
                  ...cmsPayment,
                  status: this.getPaymentMethodStatus(cmsPayment.status)
                },
                index + 1
              )
            );
          }
        });

        this.accountPaymentStore.updatePayments({ paymentListExtraDetailsModel: cmsDataWithdrawal.instructions });

        this.accountPaymentStore.updatePayments({
          paymentsListWithdrawal: paymentsWithdrawal.sort((a, b) => a.displayOrder - b.displayOrder)
        });
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(
          translate('Could not retrieve payment method list'),
          translate('An error has occurred. Please try again.')
        );
        return throwError(error);
      })
    );
  }

  getPaymentDetails(paymentId: string, isDeposit: boolean): Observable<any> {
    this.accountPaymentStore.setLoading(true);
    const paymentUI: any = this.setPaymentUIState(paymentId, isDeposit ? 'deposit' : 'withdrawal');

    if (isDeposit) {
      if (this.accountPaymentQuery.hasDepositPaymentsLists) {
        this.accountPaymentStore.setLoading(false);
        return this.retrieveDepositPaymentsList(paymentId, paymentUI);
      } else {
        return this.getDepositPaymentList(true).pipe(
          first(),
          map(() => {
            this.retrieveDepositPaymentsList(paymentId, paymentUI);
            this.accountPaymentStore.setLoading(false);
          })
        );
      }
    } else {
      if (this.accountPaymentQuery.hasWithdrawalsPaymentsLists) {
        this.accountPaymentStore.setLoading(false);
        return this.retrieveWithdrawalPaymentsList(paymentId, paymentUI);
      } else {
        return this.getWithdrawalPaymentList(true).pipe(
          first(),
          map(() => {
            this.retrieveWithdrawalPaymentsList(paymentId, paymentUI);
            this.accountPaymentStore.setLoading(false);
          })
        );
      }
    }
  }

  // the following rule disable is need, because the following function is exceeding the maximum length allowed
  // and the function must be set as whole
  // tslint:disable-next-line: cyclomatic-complexity
  setPaymentUIState(id: string, type: string): any {
    let paymentUI = {};
    if (type === 'withdrawal') {
      switch (id) {
        case WithdrawalPaymentType.InterswitchQuickTeller: {
          paymentUI = new PaymentUIState({
            identifier: WithdrawalPaymentType.InterswitchQuickTeller,
            displayOptions: PaymentDisplayState.AmountAndBanks
          });
          break;
        }
        case WithdrawalPaymentType.Paystack: {
          paymentUI = new PaymentUIState({
            identifier: WithdrawalPaymentType.Paystack,
            displayOptions: PaymentDisplayState.AmountBankChannels
          });
          break;
        }
        case WithdrawalPaymentType.MpesaWithdrawal: {
          paymentUI = new PaymentUIState({
            identifier: WithdrawalPaymentType.MpesaWithdrawal,
            displayOptions: PaymentDisplayState.InfoFeeCalculator
          });
          break;
        }
        case WithdrawalPaymentType.ZenithBankCIB: {
          paymentUI = new PaymentUIState({
            identifier: WithdrawalPaymentType.ZenithBankCIB,
            displayOptions: PaymentDisplayState.AmountAndBanks
          });
          break;
        }
        case WithdrawalPaymentType.BankTransfer: {
          paymentUI = new PaymentUIState({
            identifier: WithdrawalPaymentType.BankTransfer,
            displayOptions: PaymentDisplayState.AmountAndUserBankAccount
          });
          break;
        }
        default: {
          break;
        }
      }
    } else {
      switch (id) {
        case DepositPaymentType.InterswitchWebPay: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.InterswitchWebPay,
            displayOptions: PaymentDisplayState.AmountOnly
          });
          break;
        }
        case DepositPaymentType.InterswitchATMTransfer: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.InterswitchATMTransfer,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.InterswitchQuickTeller: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.InterswitchQuickTeller,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.InterswitchBank: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.InterswitchBank,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.GTCollections: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.GTCollections,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.GTBUSSD: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.GTBUSSD,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.GTPay: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.GTPay,
            displayOptions: PaymentDisplayState.AmountOnly
          });
          break;
        }
        case DepositPaymentType.UBADeposit: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.UBADeposit,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.UBAInternetBanking: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.UBAInternetBanking,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.UBAUSSD: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.UBAUSSD,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.ZenithBankUSSD: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.ZenithBankUSSD,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.ZenithBankXPath: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.ZenithBankXPath,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.BanksInstant: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.BanksInstant,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case DepositPaymentType.Paystack: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.Paystack,
            displayOptions: PaymentDisplayState.AmountChannels
          });
          break;
        }
        case DepositPaymentType.AllSecureDeposit: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.AllSecureDeposit,
            displayOptions: PaymentDisplayState.AmountOnly
          });
          break;
        }
        case DepositPaymentType.SpinePayDeposit: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.SpinePayDeposit,
            displayOptions: PaymentDisplayState.AmountOnly
          });
          break;
        }
        case DepositPaymentType.MpesaDeposit: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.MpesaDeposit,
            displayOptions: PaymentDisplayState.InfoFeeCalculator
          });
          break;
        }
        case DepositPaymentType.MpesaExpress: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.MpesaExpress,
            displayOptions: PaymentDisplayState.AmountFeeCalculator
          });
          break;
        }
        case DepositPaymentType.OPayDeposit: {
          paymentUI = new PaymentUIState({
            identifier: DepositPaymentType.OPayDeposit,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        case WithdrawalPaymentType.BankTransfer: {
          paymentUI = new PaymentUIState({
            identifier: WithdrawalPaymentType.BankTransfer,
            displayOptions: PaymentDisplayState.InfoOnly
          });
          break;
        }
        default: {
          break;
        }
      }
    }

    return paymentUI;
  }

  getCalculateFee(paymentId: string, amount: number): Observable<any> {
    return this.apiService
      .post(APIType.Platform, 'api/PaymentVendors/Safaricom/GetFeeBands', {
        PaymentMethod: paymentId,
        Amount: amount
      })
      .pipe(
        map(fee => {
          if (fee === undefined) {
            return undefined;
          }
          return fee.Result.Tariff;
        }),
        catchError(error => {
          this.notificationService.showErrorNotification(translate('Fee calculation Error'), error.statusText);
          return throwError(error);
        })
      );
  }

  getPaymentBanks(): Observable<any> {
    return forkJoin(
      this.apiService.get<any>(APIType.Platform, 'api/PaymentVendors/Paystack/PaymentChannels'),
      this.apiService.get<any>(APIType.Platform, 'api/PaymentVendors/Interswitch/GetQuicktellerWithdrawalInfo')
    ).pipe(
      map(([channels, banks]) => {
        if (banks === undefined || banks.Result === undefined) {
          return undefined;
        }
        const banksList: BanksModel[] = [];
        banks.Result.BankDetails.forEach(bankDetail => {
          banksList.push(this.mapBanksToModel(bankDetail));
        });

        const channelsList: ChannelsModel[] = [];
        channels.Result.channels.forEach(channel => {
          channelsList.push(this.mapChannelsToModel(channel));
        });

        const banksDetails: any = new BankDetailsModel({
          lastAccNumber: banks.Result.AccountNumberForQuicktellerWithdrawal,
          lastBankId: banks.Result.BankIdForQuicktellerWithdrawal
        });

        this.accountPaymentStore.updatePayments({ banksDetails, banksList, channelsList });
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(translate('Payment Bank List Error'), error.statusText);
        return throwError(error);
      })
    );
  }

  loadPaymentScript(): Observable<any> {
    const returnObj: any = {};

    return this.dynamicScriptLoaderService.loadAllSecureScript().pipe(
      map(res => {
        if (res === undefined || !res.loaded) {
          return undefined;
        }

        returnObj.scriptsLoaded = res.loaded;

        return returnObj;
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(translate('SDK Load Error'), error.statusText);
        return throwError(error);
      })
    );
  }
  registerAllSecureDeposit(body: any): Observable<any> {
    return this.apiService.post<any>(APIType.Platform, '/api/PaymentVendors/AllSecure/RegisterDeposit', body);
  }
  queryAllSecureDeposit(transactionId: number): Observable<any> {
    return this.apiService.get<any>(APIType.Platform, `/api/PaymentVendors/AllSecure/QueryDeposit?txId=${transactionId}`);
  }

  registerSpinePayIPSDeposit(body: any): Observable<any> {
    return this.apiService.post<any>(APIType.Platform, '/api/PaymentVendors/SpinePay/RegisterDeposit', body);
  }

  querySpinePayIPSDeposit(transactionId: number): Observable<any> {
    return this.apiService.get<any>(APIType.Platform, `/api/PaymentVendors/SpinePay/QueryDeposit?txId=${transactionId}`);
  }

  getRegisteredCards(): Observable<any> {
    return this.apiService.get<any>(APIType.Platform, `/api/PaymentVendors/AllSecure/RegisteredCards`).pipe(
      map(res => {
        this.accountPaymentStore.updateCards(res.Result.Cards.map(item => this.mapResponseToCardModel(item)));
        return res.Result.Cards.map(item => this.mapResponseToCardModel(item));
      }),
      catchError(error => {
        this.notificationService.showErrorNotification(
          translate('Could not retrieve credit card list'),
          translate('An error has occurred. Please try again.')
        );
        return throwError(error);
      })
    );
  }

  deleteSavedCard(cardId: number): Observable<any> {
    return this.apiService.post(APIType.Platform, '/api/PaymentVendors/AllSecure/DeregisterCard', { cardId });
  }

  private mapResponseToCardModel(res): DebitCardModel {
    return {
      id: res.CardId,
      firstName: res.CardHolderFirstName,
      lastName: res.CardHolderLastName,
      lastDigits: res.LastDigits,
      referenceUuid: res.ReferenceUuid,
      address: res.CardHolderAddress,
      type: res.CardType,
      city: res.CardHolderCity,
      country: res.CardHolderCountry,
      month: res.ExpiryDate.split('/')[0],

      year: res.ExpiryDate.split('/')[1]
    };
  }
  performDeposit(data: any): Observable<any> {
    const body = {
      amount: data.amount,
      identifier: data.identifier,
      accountNumber: data.accountNumber,
      bank: data.bank,
      channels: data.channels,
      siteRedirectUrl: data.siteRedirectUrl,
      returnURL: data.siteRedirectUrl
    };
    if (data.identifier === 'InterswitchWebpayDirectDeposit') {
      return this.apiService.post<any>(APIType.Platform, 'api/PaymentVendors/Interswitch/RegisterWebPayDirectDeposit', body);
    }
    if (data.identifier === 'GTPay') {
      return forkJoin(
        this.apiService.get<any>(APIType.Platform, 'api/PaymentVendors/GTPay/GetGTPayIframeURL'),
        this.apiService.post<any>(APIType.Platform, 'api/PaymentVendors/GTPay/RegisterDeposit', body)
      ).pipe(
        map(([iFrameUrl, apiData]) => {
          if (iFrameUrl.Result === undefined || apiData.Result === undefined) {
            return undefined;
          }

          if (iFrameUrl.Result !== undefined) {
            apiData.Result.authorization_url = iFrameUrl.Result;
          }

          return apiData;
        })
      );
    }
    if (data.identifier === 'Paystack') {
      return this.apiService.post<any>(APIType.Platform, 'api/PaymentVendors/Paystack/RegisterDeposit', body);
    }
    if (data.identifier === 'MpesaDepositOnline') {
      return this.apiService.post<any>(APIType.Platform, 'api/PaymentVendors/Safaricom/RegisterDepositOnline', body);
    } else {
      return undefined;
    }
  }

  performWithdrawal(data: any): Observable<any> {
    this.accountPaymentStore.setLoading(true);

    if (data.identifier === 'BankTransfer') {
      const btBody = {
        Amount: data.amount,
        BankTransferDetailsId: data.bankDetailsId
      };

      return this.apiService
        .post<any>(APIType.Platform, '/api/PaymentVendors/BankTransfer/RegisterWithdrawalWithExistingBankAccountDetails', btBody)
        .pipe(
          finalize(() => {
            this.accountPaymentStore.setLoading(false);
          }),
          catchError(error => {
            const errorMsg = error.error.SystemMessage || error.error.ResponseMessage;
            this.notificationService.showErrorNotification(errorMsg);
            return throwError(error);
          })
        );
    } else {
      return undefined;
    }
  }

  toggleLoading(loadingState: boolean): void {
    if (loadingState) {
      this.accountPaymentStore.setLoading(true);
    } else {
      this.accountPaymentStore.setLoading(false);
    }
  }

  private getPaymentMethodStatus(str: string): number {
    switch (str) {
      case 'online':
        return PaymentStatus.Online;
      case 'offline':
        return PaymentStatus.Offline;
      case 'disabled':
        return PaymentStatus.Disabled;
      default:
        return PaymentStatus.Online;
    }
  }

  private retrieveDepositPaymentsList(paymentId: string, paymentUI: any): Observable<any> {
    return this.accountPaymentQuery.paymentsListDeposit$.pipe(
      first(),
      map(payments => {
        if (payments) {
          const returningData: PaymentListModel = payments.find(list => list.identifier === paymentId);

          this.accountPaymentStore.updatePayments({
            paymentDetails: this.mapPaymentDetailsModel(returningData, translate('Deposit'), paymentUI)
          });
        }
      })
    );
  }

  private retrieveWithdrawalPaymentsList(paymentId: string, paymentUI: any): Observable<any> {
    return this.accountPaymentQuery.paymentsListWithdrawal$.pipe(
      first(),
      map(payments => {
        if (payments) {
          const returningData: PaymentListModel = payments.find(list => list.identifier === paymentId);

          this.accountPaymentStore.updatePayments({
            paymentDetails: this.mapPaymentDetailsModel(returningData, translate('Withdrawal'), paymentUI)
          });
        }
      })
    );
  }

  private mapPaymentDetailsModel(data: any, title: string, paymentUI: any): PaymentDetailsModel {
    return new PaymentDetailsModel({
      content: data?.Content || data?.content,
      contentPopup: data?.ContentPopup || data?.contentPopup,
      title: title,
      paymentTitle: data?.PaymentTitle || data?.name,
      paymentShortDesc: data?.PaymentShortDesc || data?.description,
      paymentLogoUrl: data?.PaymentLogoUrl || data?.imageIdentifer,
      paymentId: paymentUI.identifier,
      displayOptions: paymentUI.displayOptions,
      status: data?.Status || data?.status
    });
  }

  private mapPaymentDataToModel(platformData: any, cmsData: any, sortOrder: number): PaymentListModel {
    return new PaymentListModel({
      name: cmsData.title,
      status: cmsData.status,
      description: cmsData.short_description,
      imageIdentifer: cmsData.logo.url,
      fees: platformData.Fees,
      identifier: cmsData.payment_id,
      displayOrder: sortOrder,
      content: cmsData.content,
      contentPopup: cmsData.popup,
      curreny: platformData.MaximumLimit.Currency,
      currencySymbol: platformData.MaximumLimit.CurrencySymbol,
      minimumLimit: Math.round(platformData.MinimumLimit.Amount * 100) / 100,
      maximumLimit: Math.round(platformData.MaximumLimit.Amount * 100) / 100
    });
  }

  private mapPandingWithdrawalToModel(reWithdrawal: any): PendingWithdrawalsModel {
    return new PendingWithdrawalsModel({
      amount: reWithdrawal.Amount,
      furtherDetails: reWithdrawal.FurtherDetails,
      description: reWithdrawal.Description,
      transectionDate: reWithdrawal.TransactionDate,
      id: reWithdrawal.Id,
      transactionTypeId: reWithdrawal.TransactionTypeId
    });
  }

  private mapChannelsToModel(channel: any): ChannelsModel {
    return new ChannelsModel({
      channels: channel
    });
  }

  private mapBanksToModel(bank: any): BanksModel {
    return new BanksModel({
      id: bank.Id,
      bankName: bank.Name
    });
  }

  private get promotionsEngineUrl(): string {
    return environment.appConfigDefaults.apiBaseUrl.promotionsEngine;
  }
}
