import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { ThirdPartyState, UploadedDetailsModel } from 'src/app/shared/models/third-party.model';

function createInitialState(): ThirdPartyState {
  return {
    uploadFile: undefined,
    deleteFile: undefined,
    submitFiles: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'third-party' })
export class ThirdPartyStore extends Store<ThirdPartyState> {
  constructor() {
    super(createInitialState());
  }

  updateUploadFile(uploadFile: UploadedDetailsModel): void {
    this.update({ uploadFile });
  }

  updateDeleteFile(deleteFile: any): void {
    this.update({ deleteFile });
  }

  updateSubmitFiles(submitFiles: any): void {
    this.update({ submitFiles });
  }
}
