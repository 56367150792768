import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { AwardsComponent } from './awards.component';

@NgModule({
  declarations: [AwardsComponent],
  imports: [CommonModule, SharedModule],
  exports: [AwardsComponent]
})
export class AwardsModule {}
