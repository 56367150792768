import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AccountQuery } from 'src/app/core/state/account/account.query';

import { BehaviorSubject } from 'rxjs';

import { BonusService } from 'src/app/core/services/bonus.service';
import { VariablesService } from 'src/app/core/services/variables.service';
import { expandCollapse } from 'src/app/shared/animations';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'account-bonus-section-external',
  templateUrl: './bonus-section-external.component.html',
  styleUrls: ['./bonus-section-external.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [expandCollapse()]
})
export class BonusSectionExternalComponent implements OnInit {
  @Input() faIcon: string;
  @Input() sectionTitle: string;
  @Input() isViewing: boolean;
  @Input() sectionId: 'active' | 'paused' | 'inactive' | 'previous' | 'missed';
  @Input() lastSection: boolean = false;
  @Input() noBonusesText: string;
  @Output() readonly sectionClicked = new EventEmitter();

  url: SafeResourceUrl;
  isShown$ = new BehaviorSubject<boolean>(false);
  constructor(
    readonly accountQuery: AccountQuery,
    readonly variablesService: VariablesService,
    private readonly sanitizer: DomSanitizer,
    private readonly appConfig: AppConfigService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.getIframeUrl();
  }

  onClick(event: any): void {
    this.sectionClicked.emit(event);
  }

  @HostListener('window:message', ['$event']) onWindowMessage(event: any): void {
    this.handleIframeMessage(event);
  }

  private getIframeUrl() {
    const iframeUrl = this.appConfig.get('bombBonusUrl');
    console.log(iframeUrl);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${iframeUrl}?token=${this.accountQuery.accessToken}`);
  }

  private navigateTo(link: string): void {
    this.router.navigate([link]);
  }

  private handleIframeMessage(e: {
    data: {
      type: string;
      message: any;

    }
  }): void {
    const { data: { type } } = e;
    if (type === 'go-to-slot') {
      this.navigateTo('slot');
    }
    else if (type === 'deposit') {
      this.navigateTo('/account/deposit');
    }
    else if (type === 'show-bonus') {
      this.isShown$.next(true);
    }
  }
}
