import { Injectable } from '@angular/core';
import { GoogleAnalyticsEventDTO, GoogleAnalyticsPageViewDTO } from 'src/app/shared/models/analytics.model';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  tags: string[];

  init(): void {
    this.initTags();
    this.initAnalytics();
  }

  sendPageView(page: GoogleAnalyticsPageViewDTO): void {
    gtag('event', 'page_view', {
      'page_title': page.Page.Title,
      'page_location': page.Page.Location,
      'page_path': page.Page.Path
    });

    gtag('event', 'screen_view', {
      'screen_name': page.Page.Title
    });
  }

  sendEvent(event: GoogleAnalyticsEventDTO): void {
    gtag('event', event.Event.Action, {
      'event_category': event.Event.Category,
      'event_label': event.Event.Label,
      'value': event.Event.Value
    });
  }

  private initTags(): void {
    this.tags = environment
      .appConfigDefaults
      .analytics
      .googleAnalyticsTags;
  }

  private initAnalytics(): void {
    this.tags.forEach(tag =>
      gtag('config', tag, {
        send_page_view: false,
        app_name: environment.appConfigDefaults.analytics.appName
      })
    );
  }
}
