import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AccountQuery } from 'src/app/core/state/account/account.query';

/**
 * This guard ensures the user does not have a valid auth token
 * before allowing access to a route. This is particularly useful
 * to stop access to the login page if a user is already logged in.
 */
@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private readonly accountQuery: AccountQuery, private readonly router: Router) {}

  canActivate(): boolean {
    try {
      if (this.accountQuery.isAuthenticated) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    } catch {
      this.router.navigate(['/']);
      return false;
    }
  }
}
