<app-breadcrumb [title]="'Coupon Check' | transloco" icon="coupon-check"></app-breadcrumb>
<div class="coupon-check-container">
  <div class="title" transloco="Coupon Check">
  </div>
  <div class="content">
    <input [formControl]="couponCode" type="text" [placeholder]="'Input your coupon code' | transloco">
    <div class="check-button" [class.disabled]="couponCode.invalid" (click)="checkCoupon()" transloco="CHECK">
    </div>
  </div>
</div>
