import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LanguageModel } from 'src/app/shared/models/application.model';

@Component({
  selector: 'app-language-picker-menu',
  templateUrl: './language-picker-menu.component.html',
  styleUrls: ['./language-picker-menu.component.scss']
})
export class LanguagePickerMenuComponent implements OnInit, OnDestroy {
  languages$: BehaviorSubject<LanguageModel[]> = new BehaviorSubject([]);

  constructor(private readonly appService: ApplicationService, public languageService: LanguageService) {}

  ngOnInit(): void {
    document.body?.classList?.add('disable-scrolling');

    this.languages$.next(this.languageService.getLanguages());
    console.log(this.languageService.getLanguages());
  }

  ngOnDestroy(): void {
    document.body?.classList?.remove('disable-scrolling');
  }

  close(): void {
    this.appService.showLanguagePicker$.next(false);
  }

  selectLanguage(language: string): void {
    this.languageService.saveLanguageSelection(language);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
