import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { fadeIn } from 'src/app/shared/animations';
import { brandInfo } from 'src/brand-info';
import { Location } from '@angular/common';
import { AnalyticsTrackingService } from 'src/app/core/services/analytics/analytics-tracking.service';

@Component({
  selector: 'app-spinepay-payment-completed',
  templateUrl: './spinepay-payment-completed.component.html',
  styleUrls: ['./spinepay-payment-completed.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinePayPaymentCompletedComponent implements OnInit, OnDestroy {
  completeType: string;
  transactionId: number;

  brandInfo = brandInfo;

  orderReference$ = new BehaviorSubject<string>(undefined);
  status$ = new BehaviorSubject<string>(undefined);
  amountCharged$ = new BehaviorSubject<string>(undefined);
  date$ = new BehaviorSubject<string>(undefined);
  errorMessage$ = new BehaviorSubject<string>(undefined);

  destroy$: Subject<boolean> = new Subject<boolean>();
  isInIframe: boolean;
  constructor(
    readonly accountPaymentQuery: AccountPaymentQuery,
    readonly accountQuery: AccountQuery,
    private readonly applicationService: ApplicationService,
    private readonly accountPaymentService: AccountPaymentService,
    private readonly appConfigService: AppConfigService,
    private readonly dataLayerService: DataLayerService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly location: Location,
    private readonly analyticsTrackingService: AnalyticsTrackingService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.completeType = params.type;
      this.transactionId = params.id;

      if (this.transactionId) {
        this.getSpinePayTransationInfo();
      }
    });
    this.applicationService.updateUI({ showNavBar: true });
  }
  goBack() {
    this.location.back();
  }
  continuePlaying() {
    this.router.navigate(['/']);
  }
  navigateToDeposit() {
    this.router.navigate(['account/deposit']);
  }
  getSpinePayTransationInfo(): void {
    this.accountPaymentService
      .querySpinePayIPSDeposit(this.transactionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        // tslint:disable-next-line: cyclomatic-complexity
        response => {
          if (response.Result) {
            const transactionInfo = response.Result;
            this.orderReference$.next(transactionInfo.ExternalTxId ? transactionInfo.ExternalTxId : '');
            this.status$.next(transactionInfo.Status ? transactionInfo.Status : '');
            this.amountCharged$.next(transactionInfo.Amount ? transactionInfo.Amount : '');
            this.date$.next(transactionInfo.LastUpdate ? transactionInfo.LastUpdate : '');
            this.errorMessage$.next(transactionInfo.ErrorMessage ? transactionInfo.ErrorMessage : '');
            if (this.appConfigService.get('enableDataLayer') && !transactionInfo.ErrorMessage) {
              this.dataLayerService.pushToDataLayer('deposit payment', 'successful');
            } else if (this.appConfigService.get('enableDataLayer') && transactionInfo.ErrorMessage) {
              this.dataLayerService.pushToDataLayer('deposit payment', 'failed');
            }

            if (!transactionInfo.ErrorMessage) {
              this.analyticsTrackingService.logMetaEvent('Purchase', { currency: 'RSD', value: transactionInfo.Amount });

              this.analyticsTrackingService.logGTMEvent({ ecommerce: null }, false);
              this.analyticsTrackingService.logGTMEvent(
                {
                  event: 'purchase',
                  ecommerce: {
                    transaction_id: transactionInfo.ExternalTxId, // Unique Victory transaction ID
                    affiliation: 'Victory.rs',
                    value: transactionInfo.Amount,
                    tax: 0,
                    shipping: 0,
                    currency: 'RSD',
                    items: [
                      {
                        item_id: 'IPS', // Fill with IPS or Card or Vcash or Uplatnica
                        item_name: 'Deposit',
                        affiliation: 'Victory.rs',
                        currency: 'RSD',
                        quantity: 1
                      }
                    ]
                  }
                },
                true
              );
            }
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
