import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';

import { format } from 'date-fns';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, first, map } from 'rxjs/operators';

import { APIService } from 'src/app/core/services/api.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { AccountStatementFilterModel, AccountStatementModel, AccountStatementTransferTypeModel } from 'src/app/shared/models/account.model';
import { APISettings, APIType } from 'src/app/shared/models/api.model';

@Injectable({
  providedIn: 'root'
})
export class AccountStatementService {
  private readonly transactionTypes: any = {
    0: translate('All'),
    1: translate('Payment Methods'),
    2: translate('Commissions'),
    4: translate('Player-2-Player'),
    5: translate('Played/Won-Bets'),
    6: translate('Bonus'),
    8: translate('Other Payments')
  };

  constructor(
    private readonly apiService: APIService,
    private readonly accountStore: AccountStore,
    private readonly accountQuery: AccountQuery
  ) {}

  get defaultFilter(): AccountStatementFilterModel {
    return this.accountStore.defaultAccountStatementFilter;
  }

  clearStatements(): void {
    this.accountStore.update({ accountStatements: [] });
  }

  getTransactionTypes(): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    this.accountStore.setError(undefined);
    this.accountStore.setLoading(true);
    return this.apiService.get(APIType.Platform, '/api/Finance/TransactionsTypes', apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.setLoading(false);
      }),
      map(response => {
        if (response && response.Result) {
          const transferTypes: AccountStatementTransferTypeModel[] = [];

          response.Result.forEach(element => {
            transferTypes.push(this.mapTransferTypeDataToModel(element));
          });

          this.accountStore.updateTransferTypes(transferTypes);
        }
      }),
      catchError(error => {
        this.accountStore.setError(error);
        return throwError(error);
      })
    );
  }

  getAccountStatements(payoutOnly = false): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    this.accountStore.setError(undefined);
    this.accountStore.setLoading(true);
    this.accountStore.updateUIPartial({ noStatements: false });

    const body = {
      DateFrom: `${format(new Date(this.accountQuery.accountStatementFilter.dateFrom), 'yyyy-MM-dd')}T00:00:00Z`,
      DateTo: `${format(new Date(this.accountQuery.accountStatementFilter.dateTo), 'yyyy-MM-dd')}T23:59:59Z`,
      MainTxTypesIdentifiers: payoutOnly
        ? [1]
        : this.accountQuery.accountStatementFilter.transferType === '0'
        ? this.accountQuery.transferTypes
        : [this.accountQuery.accountStatementFilter.transferType]
    };
    return this.apiService.post(APIType.Platform, '/api/Finance/Transactions/UserTransactions', body, apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.setLoading(false);
      }),
      map(response => {
        this.accountStore.updateAccountStatements([]);
        if (response && response.Result) {
          const statements: AccountStatementModel[] = [];

          if (response.Result.length > 0) {
            response.Result.forEach(element => {
              statements.push(this.mapUserTransactions(element));
            });
            if (payoutOnly) {
              this.accountStore.updatePayoutAccountStatements(statements);
            } else {
              this.accountStore.updateAccountStatements(statements);
            }
          } else {
            this.accountStore.updateUIPartial({ noStatements: true });
          }
        }
      }),
      catchError(error => {
        this.accountStore.setError(error);
        return throwError(error);
      })
    );
  }

  updateAccountStatementFilter(accountStatementFilterModel: AccountStatementFilterModel): void {
    this.accountStore.updateAccountStatementFilter(accountStatementFilterModel);
  }

  private mapTransferTypeDataToModel(responseData: any): AccountStatementTransferTypeModel {
    return new AccountStatementTransferTypeModel({
      id: responseData.Id,
      description: this.transactionTypes[responseData.Id]
    });
  }

  private mapUserTransactions(responseData: any): AccountStatementModel {
    return new AccountStatementModel({
      id: responseData.Id,
      description: responseData.Description,
      amount: responseData.Amount,
      transactionDate: responseData.TransactionDate,
      transactionStatusCode: responseData.TransactionStatusCode,
      transactionTypeIdentifier: responseData.TransactionTypeIdentifier,
      rollingBalance: responseData.RollingBalance,
      couponCode: responseData.CouponCode
    });
  }
}
