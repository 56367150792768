import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import { ShopOwnerState, SubUserModel } from '../../../shared/models/shop-owner.model';

function createInitialState(): ShopOwnerState {
  return {
    subUsersList: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'shop-owner' })
export class ShopOwnerStore extends Store<ShopOwnerState> {

  constructor() {
    super(createInitialState());
  }

  updateSubUsersList(subUsersList: SubUserModel[]): void {
    this.update({subUsersList});
  }

  clearSubUsersList(): void {
    this.update({
      subUsersList: createInitialState().subUsersList
    });
  }
}
