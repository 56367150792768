<div class="footer-section">
  <div class="section-1">
    <!-- <div class="brand-logo" *ngIf="applicationQuery.showBrandLogo$ | async"></div> -->
    <div class="play-responsibly">
      <span class="age-icon">
        <span class="icon"></span>
      </span>
      <span class="label" transloco="Play Responsibly"></span>
    </div>
    <div class="award-icons" *ngIf="applicationQuery.showAwardIcons$ | async">
      <span class="award-icon icon-1"></span>
      <span class="award-icon icon-2"></span>
    </div>
  </div>
  <div class="section-2"></div>
  <div class="section-3" *ngIf="applicationQuery.footerShowPaymentMethodImage$ | async">
    <div class="payment-icons">
      <div class="bank-icons">
        <img class="bank" src="../../../../assets/images/footer-icons/bank-icons.svg" alt="" />
        <img class="ips" src="../../../../assets/images/footer-icons/ips-beli.png" alt="" />
      </div>
    </div>
  </div>
  <div class="section-4" *ngIf="applicationQuery.footerShowPaymentMethodImage$ | async">
    <div class="payment-icons">
      <div class="card-icons">
        <div class="card-icons-secure">
          <a class="payment-method-link" target="_blank" href="http://www.mastercard.com/rs/consumer/credit-cards.html">
            <span class="mastercard-icon"></span>
          </a>
          <a class="payment-method-link" target="_blank"
            href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html">
            <span class="visa-icon"></span>
          </a>
          <a class="payment-method-link" class="vCash" target="_blank" href="https://www.vcash.rs/">
            <span class="vcash-icon">
              <img src="/assets/images/account/vcash-logo-white.svg" alt="" />
            </span>
          </a>
        </div>

        <!-- <span class="payment-card-icons"></span> -->
        <img class="payment-cards" src="/assets/images/footer-icons/payment-card-icons.svg" />
      </div>
    </div>
  </div>
  <div class="section-5" *ngIf="brandInfo.footerNote">
    <span>{{ brandInfo.footerNote }}</span>
  </div>
  <div class="section-6">
    <div>{{ 'copyright1' | transloco : { copyrightYear: brandInfo.copyrightYear, brandName: brandInfo.brandName } }}
    </div>
    <div>
      {{
      'copyright2'
      | transloco
      : {
      companyName: brandInfo.companyName,
      companyLicenseNo: brandInfo.companyLicenseNo,
      companyTIN: brandInfo.companyTIN,
      companyAddress: brandInfo.companyAddress
      }
      }}
    </div>
  </div>
</div>
<!---FOOTER FOR DESKTOP-->
<div class="footer-section-desktop">
  <div class="section-one">
    <div class="title">VICTORY.RS</div>
    <div class="rules" transloco="Terms & Conditions" (click)="navigateTo('/help/opsti-uslovi-koriscenja')"></div>
    <div class="responsible-betting" transloco="Responsible betting" (click)="navigateTo('/help/odgovorno-kladenje')">
    </div>
    <div class="privacy" transloco="Privacy Policy" (click)="navigateTo('/help/politika-privatnosti')"></div>
    <div class="rules-on-organizing" transloco="Rules on organizing special games"
      (click)="navigateTo('/help/pravila-o-priredivanju-posebnih-igara-na-srecu')"></div>
  </div>
  <div class="section-two" *ngIf="applicationQuery.footerShowPaymentMethodImage$ | async">
    <div class="title" transloco="Methods payment"></div>
    <div class="payment-icons">
      <div class="card-icons">
        <!-- <span class="payment-card-icons"></span> -->
        <img class="payment-cards" src="/assets/images/footer-icons/payment-card-icons.svg" />
        <div class="card-icons-secure">
          <a class="payment-method-link" target="_blank" href="http://www.mastercard.com/rs/consumer/credit-cards.html">
            <span class="mastercard-icon">
              <img src="/assets/images/footer-icons/mastercard-icon.svg" alt="" />
            </span>
          </a>
          <a class="payment-method-link" target="_blank"
            href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html">
            <span class="visa-icon">
              <img src="/assets/images/footer-icons/visa-icon.svg" alt="" />
            </span>
          </a>
          <a class="payment-method-link" target="_blank" href="https://www.vcash.rs/">
            <span class="vcash-icon">
              <img src="/assets/images/account/vcash-logo-white.svg" alt="" />
            </span>
          </a>
        </div>
      </div>
      <div class="bank-icons">
        <img class="bank" src="../../../../assets/images/footer-icons/bank-icons.svg" alt="" />
        <img class="ips" src="../../../../assets/images/account/ips.svg" alt="" />
      </div>
    </div>
  </div>

  <div class="section-three">
    <div class="title" transloco="Contact Us"></div>
    <div class="support">
      <a href="mailto:{{ brandInfo.documentsEmail }}" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg"
          width="17" height="11" viewBox="0 0 15 11" fill="none">
          <path
            d="M13.6816 0.226562H1.31836C0.589951 0.226562 0 0.820176 0 1.54492V9.45508C0 10.1841 0.594287 10.7734 1.31836 10.7734H13.6816C14.404 10.7734 15 10.1866 15 9.45508V1.54492C15 0.821465 14.4124 0.226562 13.6816 0.226562ZM13.497 1.10547L8.43223 6.14356C8.1832 6.39259 7.85215 6.5297 7.5 6.5297C7.14785 6.5297 6.8168 6.39256 6.56695 6.14274L1.50299 1.10547H13.497ZM0.878906 9.27619V1.72437L4.67689 5.50234L0.878906 9.27619ZM1.50354 9.89453L5.30004 6.12218L5.9463 6.76504C6.36132 7.18006 6.9131 7.4086 7.5 7.4086C8.0869 7.4086 8.63868 7.18006 9.05288 6.76586L9.69996 6.12218L13.4965 9.89453H1.50354ZM14.1211 9.27619L10.3231 5.50234L14.1211 1.72437V9.27619Z"
            fill="white" />
        </svg>
        podrska&#64;victory.rs
      </a>
    </div>
    <div class="social-network-wrapper"></div>
  </div>

  <div class="section-four">
    <div class="logo-wrapper">
      <svg (click)="navigateTo('/')" width="153" height="34" viewBox="0 0 153 34" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_33_2115)">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.8816 -6.10352e-05C15.0555 -6.10352e-05 14.539 0.863104 14.9519 1.55314L20.2934 10.4824C20.7063 11.1724 21.7391 11.1724 22.152 10.4824L27.4935 1.55314C27.9064 0.863104 27.3907 -6.10352e-05 26.5647 -6.10352e-05H15.8816Z"
            fill="white"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M28.2043 7.99994C27.8174 7.99994 27.4601 8.19947 27.2665 8.52351L22.1612 17.0793C21.7446 17.7777 20.7031 17.7777 20.2863 17.0793L15.1801 8.52351C14.9865 8.19947 14.6294 7.99994 14.2428 7.99994H1.0844C0.251085 7.99994 -0.269988 8.87283 0.146526 9.57065L3.78944 15.6748C3.98305 15.9985 4.34039 16.1984 4.72703 16.1984H9.97281H9.9751L20.2863 33.4762C20.7031 34.1745 21.7446 34.1745 22.1612 33.4762L32.4715 16.1984H37.7184C38.1053 16.1984 38.4626 15.9985 38.6563 15.6748L42.2983 9.57065C42.7154 8.87283 42.1943 7.99994 41.3613 7.99994H28.2043Z"
            fill="url(#paint0_linear_33_2115)"></path>
          <mask id="mask0_33_2115" maskUnits="userSpaceOnUse" x="47" y="13" width="20" height="12"
            style="mask-type: alpha">
            <path d="M47.3809 13.2139H66.8V24.1729H47.3809V13.2139Z" fill="white"></path>
          </mask>
          <g mask="url(#mask0_33_2115)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M59.6382 22.8128C59.1118 23.5326 58.2785 24.1727 57.0065 24.1727C55.7344 24.1727 54.9888 23.5728 54.4186 22.8128L47.4467 13.732C47.2792 13.514 47.4505 13.2143 47.7425 13.2143H51.8592C51.9785 13.2143 52.0899 13.268 52.1572 13.3578L56.7597 19.5052C56.9008 19.6937 57.2047 19.6971 57.3512 19.5117L62.206 13.3514C62.2738 13.2652 62.383 13.2143 62.4993 13.2143H66.4388C66.7323 13.2143 66.9031 13.5165 66.7329 13.7343L59.6382 22.8128Z"
              fill="white"></path>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M68.1099 23.2523V13.7476C68.1099 13.3348 68.4575 12.9999 68.8864 12.9999H71.2817C71.7106 12.9999 72.0582 13.3348 72.0582 13.7476V23.2523C72.0582 23.6653 71.7106 23.9999 71.2817 23.9999H68.8864C68.4575 23.9999 68.1099 23.6653 68.1099 23.2523ZM80.2067 23.9999C76.0581 23.9999 73.0453 21.5873 73.0453 18.3979C73.0453 15.1673 76.1017 12.9999 80.2067 12.9999H87.0902C87.7211 12.9999 88.0773 13.6778 87.6926 14.1462L86.2428 15.9117C86.0986 16.0868 85.876 16.1894 85.6399 16.1894H80.2067C78.3288 16.1894 77.1062 16.7619 77.1062 18.3979C77.1062 20.0334 78.3288 20.8514 80.2067 20.8514H87.078C87.7111 20.8514 88.0668 21.5333 87.6777 22.0012L86.2428 23.7258C86.0986 23.8989 85.8773 23.9999 85.6428 23.9999H80.2067Z"
            fill="white"></path>
          <mask id="mask1_33_2115" maskUnits="userSpaceOnUse" x="102" y="13" width="19" height="12"
            style="mask-type: alpha">
            <path d="M102.9 13.0539H120.489V24.1729H102.9V13.0539Z" fill="white"></path>
          </mask>
          <g mask="url(#mask1_33_2115)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M111.694 16.1737C108.194 16.1737 106.969 16.4538 106.969 18.5735C106.969 20.693 108.15 21.013 111.694 21.013C115.238 21.013 116.42 20.693 116.42 18.5735C116.42 16.4538 115.238 16.1737 111.694 16.1737ZM111.694 24.1726C105.831 24.1726 102.899 22.4529 102.899 18.6135C102.899 14.7738 105.831 13.0544 111.694 13.0544C117.557 13.0544 120.489 14.7738 120.489 18.6135C120.489 22.4529 117.557 24.1726 111.694 24.1726Z"
              fill="white"></path>
          </g>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M132.845 23.9592L130.849 21.483C130.72 21.3234 130.52 21.2295 130.307 21.2295H126.105C125.73 21.2295 125.425 21.5174 125.425 21.8722V23.3168C125.425 23.6716 125.12 23.9592 124.745 23.9592H122.094C121.718 23.9592 121.413 23.6716 121.413 23.3168V18.8575C121.413 18.5026 121.718 18.2148 122.094 18.2148H131.895C132.845 18.2148 133.362 17.8887 133.362 17.1964C133.362 16.5036 132.845 16.1776 131.895 16.1776H122.094C121.718 16.1776 121.413 15.89 121.413 15.5349V13.6427C121.413 13.2878 121.718 12.9999 122.094 12.9999H131.421C134.052 12.9999 137.546 13.7332 137.546 16.9926C137.546 18.3066 136.873 19.5146 135.663 20.1469C135.259 20.3576 135.17 20.8617 135.497 21.1683C135.932 21.576 136.542 22.1904 137.374 23.1038L138.194 23.9999H132.845V23.9592Z"
            fill="white"></path>
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M142.71 20.5448C142.71 20.4611 142.678 20.3804 142.62 20.3179L136.311 13.567C136.106 13.348 136.268 12.9999 136.575 12.9999H140.715C140.821 12.9999 140.921 13.0455 140.989 13.1236L143.475 16.0147C143.801 16.3835 144.188 16.8509 144.465 17.2248C144.607 17.4157 144.899 17.4175 145.04 17.2258C145.318 16.8464 145.683 16.3896 145.983 16.0147L148.343 13.1302C148.41 13.0477 148.513 12.9999 148.622 12.9999H152.644C152.949 12.9999 153.112 13.3429 152.912 13.563L146.751 20.3597C146.695 20.4216 146.664 20.5005 146.664 20.5825V23.6597C146.664 23.8479 146.505 23.9999 146.309 23.9999H143.096C142.883 23.9999 142.71 23.8348 142.71 23.6306V20.5448ZM93.9625 23.4726V16.717C93.9625 16.4255 93.7076 16.1897 93.3931 16.1897H89.7941C89.5158 16.1897 89.2784 16.0033 89.2323 15.7492L88.8468 13.6143C88.7887 13.2927 89.0563 12.9999 89.4086 12.9999H103.075C103.464 12.9999 103.739 13.3531 103.615 13.6945L102.844 15.8297C102.766 16.0444 102.549 16.1897 102.304 16.1897H98.639C98.3246 16.1897 98.0693 16.4255 98.0693 16.717V23.4726C98.0693 23.7638 97.8146 23.9999 97.4999 23.9999H94.532C94.2175 23.9999 93.9625 23.7638 93.9625 23.4726Z"
            fill="white"></path>
        </g>
        <defs>
          <linearGradient id="paint0_linear_33_2115" x1="3.73828e-08" y1="8.49994" x2="42.2383" y2="33.5159"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF8744"></stop>
            <stop offset="1" stop-color="#E95220"></stop>
          </linearGradient>
          <clipPath id="clip0_33_2115">
            <rect width="153" height="34" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
      <div class="play-responsibly">
        <span class="age-icon">
          <span class="icon"></span>
        </span>
        <span class="label" transloco="Play Responsibly"></span>
      </div>
    </div>
    <div class="footer-large-txt">
      <span *ngIf="brandInfo.footerNote">{{ brandInfo.footerNote }}</span>

      <div>{{ 'copyright1' | transloco : { copyrightYear: brandInfo.copyrightYear, brandName: brandInfo.brandName } }}
      </div>
      <div>
        {{
        'copyright2'
        | transloco
        : {
        companyName: brandInfo.companyName,
        companyLicenseNo: brandInfo.companyLicenseNo,
        companyTIN: brandInfo.companyTIN,
        companyAddress: brandInfo.companyAddress
        }
        }}
      </div>
    </div>
  </div>
</div>