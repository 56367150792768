import { Injectable } from '@angular/core';

import { Query } from '@datorama/akita';

import { ShopOwnerState, SubUserModel } from '../../../shared/models/shop-owner.model';
import { ShopOwnerStore } from './shop-owner.store';

@Injectable({ providedIn: 'root' })
export class ShopOwnerQuery extends Query<ShopOwnerState> {
  subUsersList$ = this.select(s => s.subUsersList);

  constructor(
    protected store: ShopOwnerStore,
  ) {
    super(store);
  }

  get subUsersList(): SubUserModel[]{
    return this.getValue().subUsersList;
  }
}
