import { Injectable, OnDestroy } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { format } from 'date-fns';
import { orderBy, trim } from 'lodash-es';
import { BehaviorSubject, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { ResolverService } from 'src/app/core/services/resolver.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  CategoryModel,
  CorrectScoreOddsModel,
  EventSelectionState,
  EventSummaryModel,
  FlattenedSportModel,
  MarketModel,
  MatchModel,
  PresetType,
  QuicklinksState,
  QuicklinkType,
  RegionModel,
  SelectionModel,
  SportModel,
  SportQuicklink,
  SportQuicklinks,
  SpreadValueGroupedOdd,
  TournamentModel
} from 'src/app/shared/models/sport.model';

import { LiveSportsModel } from '../../shared/models/landing.model';

@Injectable({
  providedIn: 'root'
})
export class SportService implements OnDestroy {
  activeMatchIdMarketGroup$: Subject<number | undefined> = new Subject();
  areaIdRegionIdHash: { [areaId: number]: number } = {};

  readonly presetTypeMap: any = {
    0: translate('Top Competitions'),
    1: translate('Todays Games'),
    2: translate('A-Z Competitions'),
    3: translate('Live Now'),
    4: translate('All By Country'),
    5: translate('Outrights'),
    6: translate('Special Sports'),
    7: translate('Goal Scorer')
  };

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly regionsCache: any = [];
  private readonly areasCache: any = [];
  private readonly isPlayerAreaSub$: Subscription = undefined;
  private initialPrematchRequestMade: boolean;

  refreshingList$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly sportStore: SportStore,
    private readonly apiService: APIService,
    private readonly couponService: CouponService,
    private readonly sportQuery: SportQuery,
    private readonly applicationQuery: ApplicationQuery,
    private readonly resolverService: ResolverService
  ) {
    this.isPlayerAreaSub$ = this.sportQuery.isPlayerArea$.subscribe(isPlayerAreaSub => {
      if (!isPlayerAreaSub) {
        this.sportStore.clearPlayersData();
      }
    });
  }

  restoreAreaRegionsCacheToggle(bool: boolean): void {
    this.sportStore.updateEventSelection({ restoreAreaRegionsCache: bool });
  }

  restoreAreaRegionsCache(): void {
    this.restoreAreaRegionsCacheToggle(true);
    const newAreas = [];
    const newRegions = [];
    // const regionAndAreaCaches = [];
    this.areasCache.forEach(areas => {
      areas.forEach(area => {
        const newArea = new AreaModel({
          id: area.AreaID,
          name: area.AreaName,
          order: area.AreaOrder
        });
        newAreas.push(newArea);
      });

      this.regionsCache.forEach(regions => {
        regions.forEach(region => {
          const newRegion = new RegionModel({
            areaIds: region.AreaIds,
            id: region.RegionID,
            name: region.RegionName,
            order: region.RegionOrder
          });
          newRegions.push(newRegion);
        });
      });
      const reducedAreas = newAreas.reduce((acc, x) => acc.concat(acc.find(y => y.id === x.id && y.name === x.name) ? [] : [x]), []);

      const reducedRegions = newRegions.reduce((acc, x) => acc.concat(acc.find(y => y.id === x.id && y.name === x.name) ? [] : [x]), []);

      // const reducedAreasWithOwnership = reducedAreas.map(area => {
      //   const belongsTo = [];
      //   regionAndAreaCaches.forEach((prematch, index) => {
      //     prematch.areas.forEach(prematchArea => {
      //       if (area.id === prematchArea.id) {
      //          belongsTo.push(index);
      //       }
      //     });
      //   });
      //   return { ...area, belongsTo };
      // });

      // const reducedRegionsWithOwnership = reducedRegions.map(region => {
      //   const belongsTo = [];
      //   regionAndAreaCaches.forEach((prematch, index) => {
      //     prematch.regions.forEach(prematchRegion => {
      //       if (region.id === prematchRegion.id) {
      //          belongsTo.push(index);
      //       }
      //     });
      //   });
      //   return { ...region, belongsTo };
      // });

      this.sportStore.updateAreas(reducedAreas);
      this.sportStore.updateRegions(reducedRegions);
    });
  }

  getPrematchEventData(
    language: string = 'en',
    scheduleTimeFrame: number,
    leagueIds: string[],
    areaId: number = 0,
    regionId: number = 0,
    cacheRegionsAndAreas: boolean = false,
    initialRegionArea: boolean = false
  ): Observable<any[]> {
    // If we make a request for defaults with areaId 0 and regionId 0, after updating the selected area in the store, don't make an
    // additional request with the changed selected area id
    if (this.initialPrematchRequestMade) {
      this.initialPrematchRequestMade = false;
      // return new Observable();
    }
    if ((areaId === 0 && regionId === 0) || initialRegionArea) {
      this.initialPrematchRequestMade = true;
    }
    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true
    });

    const apiCalls = [];

    let allSports = [];

    leagueIds.forEach(leagueId => {
      const url = `api/feeds/prematch/${language}/${scheduleTimeFrame}/${leagueId}/${areaId}/${regionId}`;

      const call = this.apiService.get(APIType.SportsbookFeed, url, apiSettings).pipe(
        tap(data => (allSports = data.Sports)),
        map(data => {
          // data.Regions = [];
          const region = data.Regions[0];
          if (region) {
            region.AreaIds.forEach((id: number) => {
              this.areaIdRegionIdHash[id] = region.RegionID;
            });
          }

          data.Sports = [...(allSports || [])];
          return data;
        })
      );
      apiCalls.push(call);
    });

    return forkJoin(apiCalls).pipe(
      tap((dataArray: any) => {
        const newAreas = [];
        const newRegions = [];
        const parsed = [];
        const regionAndAreaCaches = [];
        const newDataArray = dataArray.map(data => ({
          ...data,
          Sports: allSports
        }));
        newDataArray.forEach((data, index) => {
          if (data.AreaMatches.length === 0) {
            return;
          }
          const cache = { areas: [], regions: [], visible: true };
          if (data.Areas.length) {
            this.areasCache[index] = data.Areas;

            this.areasCache[index].forEach(area => {
              const newArea = new AreaModel({
                id: area.AreaID,
                name: area.AreaName,
                order: area.AreaOrder
              });
              newAreas.push(newArea);
              cache.areas.push(newArea);
            });

            if (data.Regions.length) {
              this.regionsCache[index] = data.Regions;

              this.regionsCache[index].forEach(region => {
                const newRegion = new RegionModel({
                  areaIds: region.AreaIds,
                  id: region.RegionID,
                  name: region.RegionName,
                  order: region.RegionOrder
                });
                newRegions.push(newRegion);
                cache.regions.push(newRegion);
              });

              const duplicateCache = regionAndAreaCaches.find(regionArea => JSON.stringify(cache) === JSON.stringify(regionArea));
              if (!duplicateCache) {
                regionAndAreaCaches.push(cache);
              }
            }
          }

          if (data.AreaMatches[0]) {
            parsed.push(this.mapMatchSummaryDataToModel(data.AreaMatches[0]));
          } else {
            parsed.push({ ...this.sportQuery.selectedPrematch[index] });
          }
          if (!parsed) {
            return;
          }

          if (parsed[index] && parsed[index].area) {
            const correctScoreAreaIds = this.appConfig.get('correctScoreAreaIds');
            correctScoreAreaIds.forEach((id: number) => {
              if (parsed[index].area.id === id) {
                this.sportStore.updateIsItCorrectScore(true);
              } else {
                this.sportStore.updateIsItCorrectScore(false);
              }
            });
          }
        });

        if (cacheRegionsAndAreas) {
          this.sportStore.updateEventSelection({ areaAndRegionCache: regionAndAreaCaches });
        }

        const reducedAreas = newAreas.reduce((acc, x) => acc.concat(acc.find(y => y.id === x.id && y.name === x.name) ? [] : [x]), []);

        const reducedRegions = newRegions.reduce((acc, x) => acc.concat(acc.find(y => y.id === x.id && y.name === x.name) ? [] : [x]), []);

        // const reducedAreasWithOwnership = reducedAreas.map(area => {
        //   const belongsTo = [];
        //   regionAndAreaCaches.forEach((prematch, index) => {
        //     prematch.areas.forEach(prematchArea => {
        //       if (area.id === prematchArea.id) {
        //         // belongsTo.push(index);
        //       }
        //     });
        //   });
        //   return { ...area, belongsTo };
        // });

        // const reducedRegionsWithOwnership = reducedRegions.map(region => {
        //   const belongsTo = [];
        //   regionAndAreaCaches.forEach((prematch, index) => {
        //     prematch.regions.forEach(prematchRegion => {
        //       if (region.id === prematchRegion.id) {
        //         // belongsTo.push(index);
        //       }
        //     });
        //   });
        //   return { ...region, belongsTo };
        // });

        this.sportStore.updateAreas(reducedAreas);
        this.sportStore.updateRegions(reducedRegions);

        this.sportStore.updateSelectedPrematch(parsed);

        if (this.sportQuery.selectedArea) {
          parsed.forEach(data => {
            if (data.area.id === this.sportQuery.selectedArea.id) {
              this.sportStore.updateEventSelection({
                selectedMarket: data.area.markets[0],
                areaMarkets: data.area.markets,
                selectedAreaId: data.area.id
              });
            }
          });
        } else {
          if (parsed[0]) {
            this.sportStore.updateEventSelection({
              selectedMarket: parsed[0].area.markets[0],
              areaMarkets: parsed[0].area.markets,
              selectedAreaId: parsed[0].area.id
            });
          }
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  mapMatchSummaryDataToModel(responseData: any, tournamentId?: number): EventSummaryModel {
    if (!responseData) {
      return;
    }
    const marketData = responseData.AreaMarkets[0];
    const isItPlayerMultiLineType = responseData.GroupingType === 2 && marketData.OddsType.MultilineType === 1;
    let matches: MatchModel[] = [];
    responseData.Items.forEach(item => {
      if (!tournamentId || item.TournamentId === tournamentId) {
        matches.push(this.mapMatchDataToModel(item, responseData.SportID, responseData.SportName, isItPlayerMultiLineType));
      }
    });

    if (tournamentId) {
      matches = orderBy(matches, 'date');
    }

    const markets = [];
    let multiLineType = false;
    responseData.AreaMarkets.forEach(market => {
      const selections: SelectionModel[] = [];

      if (!multiLineType && market.Markets.length > 3) {
        multiLineType = true;
      }

      market.Markets.forEach(selection => selections.push(this.mapSelectionDataToModel(selection)));

      const overUnderType =
        market.SpecialCount > 0 ||
        (market.Markets[0].OddAttribute.SpecialValueDisplay !== '' && market.Markets[0].OddAttribute.SpecialValueDisplay !== '0')
          ? 1
          : 0;

      markets.push(
        new MarketModel({
          id: market.OddsType.OddsTypeID,
          typeId: market.OddsType.OddsTypeID,
          name: market.OddsType.OddsTypeName,
          description: market.OddsType.OddsDescription,
          spreadDisplayValue: market.SpecialValueDisplay,
          spreadValue: market.SpecialBetValue,
          overUnderType,
          selections
        })
      );
    });

    const correctScoreType = this.appConfig.get('correctScoreAreaIds').filter(data => data === responseData.Area.AreaID).length ? 1 : 0;

    return new EventSummaryModel({
      sportId: responseData.SportID,
      sportName: responseData.SportName,
      groupingType: responseData.GroupingType,
      multiLineType: multiLineType ? 1 : 0,
      correctScoreType,
      area: new AreaModel({
        id: responseData.Area.AreaID,
        name: responseData.Area.AreaName,
        order: responseData.Area.AreaOrder,
        isDefault: true,
        markets
      }),
      matches
    });
  }

  mapLiveSportsDataToModel(responseData: any): LiveSportsModel[] {
    if (!responseData || responseData.length === 0) {
      return;
    }
    const sports = [];
    responseData.Sports.forEach(item => {
      const newSport = new LiveSportsModel({
        id: item.Id,
        name: item.Name,
        events: this.mapLiveMatchSummaryDataToModel(responseData.Events.filter(ev => ev.SportId === item.Id))
      });
      sports.push(newSport);
    });
    return sports;
  }

  mapLiveMatchSummaryDataToModel(responseData: any): EventSummaryModel {
    if (!responseData || responseData.length === 0) {
      return;
    }
    const matches: MatchModel[] = [];
    responseData.forEach(item => matches.push(this.mapLiveMatchDataToModel(item)));
    const markets = [];
    const selections: SelectionModel[] = [];
    const matchWithTwoOdds = matches.find(match => match.odds && match.odds.length === 2);
    const matchWithThreeOdds = matches.find(match => match.odds && match.odds.length === 3);

    if (!!matchWithTwoOdds) {
      selections.push(
        new SelectionModel({
          id: 1,
          name: '1'
        })
      );

      selections.push(
        new SelectionModel({
          id: 2,
          name: '2'
        })
      );

      markets.push(
        new MarketModel({
          id: matchWithTwoOdds.odds[0].marketTypeId,
          typeId: matchWithTwoOdds.odds[0].marketTypeId,
          name: '1 2',
          description: '',
          selections
        })
      );
    } else if (!!matchWithThreeOdds) {
      selections.push(
        new SelectionModel({
          id: 1,
          name: '1'
        })
      );

      selections.push(
        new SelectionModel({
          id: 2,
          name: 'X'
        })
      );

      selections.push(
        new SelectionModel({
          id: 3,
          name: '2'
        })
      );

      markets.push(
        new MarketModel({
          id: matchWithThreeOdds.odds[0].marketTypeId,
          typeId: matchWithThreeOdds.odds[0].marketTypeId,
          name: '1 X 2',
          description: '',
          selections
        })
      );
    }
    return new EventSummaryModel({
      sportId: responseData[0].SportId,
      area: new AreaModel({
        id: 1,
        name: 'Main',
        order: 0,
        isDefault: true,
        markets
      }),
      matches
    });
  }

  mapSelectionDataToModel(responseData: any): SelectionModel {
    return new SelectionModel({
      id: responseData.OddAttribute.OddTypeID,
      name: responseData.OddAttribute.OddName,
      spreadValue: responseData.OddAttribute.SpecialValue,
      spreadDisplayValue: responseData.OddAttribute.SpecialValueDisplay,
      order: responseData.OddAttribute.Order
    });
  }

  mapMatchDataToModel(
    responseData: any, // responseData: 1 of AreaMatches.Items
    sportId?: number,
    sportName?: string,
    isItPlayerMultiLineType?: boolean
  ): MatchModel {
    const odds: OddModel[] = [];
    let correctScoreOdds: CorrectScoreOddsModel;

    responseData.OddsCollection.forEach(oc => {
      odds.push(...this.mapOddDataItemsToModel(responseData, oc, sportId, sportName));
    });

    let newMatchModel: MatchModel;

    const teams = responseData.ItemName.split(' - ');

    if (isItPlayerMultiLineType) {
      correctScoreOdds = this.parseOddsForCorrectScore(odds);
      newMatchModel = new MatchModel({
        id: responseData.ItemID,
        date: responseData.ItemDate,
        name: responseData.ItemName,
        homeTeam: teams[0],
        awayTeam: teams.length > 1 ? teams[1] : undefined,
        smartBetCode: responseData.SmartBetCode,
        oddCount: responseData.TotalOdds,
        categoryId: responseData.CategoryId,
        categoryName: responseData.CategoryName,
        categoryOrder: responseData.CategoryOrder,
        tournamentId: responseData.TournamentId,
        tournamentName: responseData.TournamentName,
        tournamentOrder: responseData.TournamentOrder,
        externalId: responseData.ExtProvIDItem,
        extParentItemID: responseData.ExtParentItemID,
        extParentTeamID: responseData.ExtParentTeamID,
        extTeamOrder: responseData.ExtTeamOrder,
        odds,
        correctScoreOdds,
        ...(this.sportQuery.selectedIdsAtDepthFour && {
          selectedInView: this.sportQuery.selectedIdsAtDepthFour.includes(responseData.ItemID)
        })
      });
    } else {
      newMatchModel = new MatchModel({
        id: responseData.ItemID,
        date: responseData.ItemDate,
        name: responseData.ItemName,
        homeTeam: teams[0],
        awayTeam: teams.length > 1 ? teams[1] : undefined,
        smartBetCode: responseData.SmartBetCode,
        oddCount: responseData.TotalOdds,
        categoryId: responseData.CategoryId,
        categoryName: responseData.CategoryName,
        categoryOrder: responseData.CategoryOrder,
        tournamentId: responseData.TournamentId,
        tournamentName: responseData.TournamentName,
        tournamentOrder: responseData.TournamentOrder,
        externalId: responseData.ExtProvIDItem,
        extParentItemID: responseData.ExtParentItemID,
        extParentTeamID: responseData.ExtParentTeamID,
        extTeamOrder: responseData.ExtTeamOrder,
        odds,
        ...(this.sportQuery.selectedIdsAtDepthFour && {
          selectedInView: this.sportQuery.selectedIdsAtDepthFour.includes(responseData.ItemID)
        })
      });
    }

    return newMatchModel;
  }

  mapLiveMatchDataToModel(itemData: any): MatchModel {
    const odds: OddModel[] = [];

    itemData.Markets.forEach(market => {
      odds.push(...this.mapLiveOddDataItemsToModel(itemData, market));
    });

    const teamHome = itemData.Teams.find(t => t.ItemOrder === 1);
    const teamAway = itemData.Teams.find(t => t.ItemOrder === 2);

    const newMatchModel = new MatchModel({
      id: itemData.Id,
      date: itemData.Date,
      name: itemData.Name,
      homeTeam: teamHome.Name,
      awayTeam: teamAway.Name,
      categoryId: itemData.CategoryId,
      categoryName: itemData.CategoryName,
      tournamentId: itemData.TournamentId,
      tournamentName: itemData.TournamentName,
      externalId: itemData.ProviderId,
      matchTime: itemData.MatchTime,
      matchStatus: itemData.MatchStatus,
      score: itemData.Score,
      oddCount: itemData.SelectionCount,
      sportId: itemData.SportId,
      odds
    });

    return newMatchModel;
  }

  mapOddDataItemsToModel(
    responseDataItem: any, // responseDataItem: 1 of AreaMatches.Items
    responseDataOddsCollection: any, // responseDataOC: 1 of AreaMatches.Items.OddsCollection
    sportId?: number,
    sportName?: string
  ): OddModel[] {
    const matchOdds: OddModel[] = [];

    responseDataOddsCollection.MatchOdds.forEach(matchOdd => {
      const marketId = responseDataOddsCollection.OddsType.OddsTypeID;
      const marketName = responseDataOddsCollection.OddsType.OddsTypeName;
      const selectionId = matchOdd.OddAttribute.OddTypeID;

      const originalMarket = this.parseOriginalMarket(
        marketName,
        marketId,
        selectionId,
        responseDataOddsCollection.SelectionGroupingMarkets,
        matchOdd.MatchOddsID
      );
      let originalMarketId: number;
      let originalMarketName: string;
      let originalSelectionId: number;

      if (originalMarket) {
        originalMarketId = originalMarket.marketId;
        originalMarketName = originalMarket.marketName;
        originalSelectionId = originalMarket.selectionId;
      }

      matchOdds.push(
        new OddModel({
          id: matchOdd.MatchOddsID,
          value: matchOdd.Outcome ? matchOdd.Outcome.OddOutcome : undefined,
          spreadValue: parseFloat(matchOdd.OddAttribute.SpecialValueDisplay),
          spreadValueDisplay: matchOdd.OddAttribute.SpecialValueDisplay,
          sportId,
          sportName,
          categoryId: responseDataItem.CategoryId,
          categoryName: responseDataItem.CategoryName,
          tournamentId: responseDataItem.TournamentId,
          tournamentName: responseDataItem.TournamentName,
          matchId: responseDataItem.ItemID,
          matchName: responseDataItem.ItemName,
          matchDate: responseDataItem.ItemDate,
          marketTypeId: responseDataOddsCollection.OddsType.IDGroupMarketType,
          marketId: marketId,
          marketName: marketName,
          originalMarketName: originalMarketName,
          originalMarketId: originalMarketId,
          smartCode: responseDataItem.SmartBetCode,
          eventCategory: responseDataItem.EventCategory,
          combinability: responseDataOddsCollection.Combinability,
          selectionId: selectionId,
          originalSelectionId: originalSelectionId,
          selectionName: matchOdd.OddAttribute.OddName,
          incompatibleEvents: responseDataItem.IncompatibleEvents,
          selected: this.couponService.isOddInCoupon(matchOdd.MatchOddsID),
          enabled: true
        })
      );
    });

    return matchOdds;
  }

  parseOriginalMarket(marketName: string, marketId: number, selectionId: number, selectionGroupingMarkets: any, matchOddsID: number): any {
    let originalMarketId = marketId;
    let originalMarketName = marketName;
    let originalSelectionId = selectionId;

    if (selectionGroupingMarkets) {
      let foundMarketName = false;
      selectionGroupingMarkets.forEach(sgm => {
        if (!foundMarketName) {
          sgm.SelectionIDs.forEach(sid => {
            if (!foundMarketName && sid === matchOddsID) {
              foundMarketName = true;
              originalMarketId = sgm.MarketType.OddsTypeID;
              originalMarketName = sgm.MarketType.OddsTypeName;
              originalSelectionId = sgm.IDSelectionType;
            }
          });
        }
      });
    }

    return {
      marketId: originalMarketId,
      marketName: originalMarketName,
      selectionId: originalSelectionId
    };
  }

  mapLiveOddDataItemsToModel(itemData: any, market: any): OddModel[] {
    const matchOdds: OddModel[] = [];

    market.Selections.forEach(selection => {
      matchOdds.push(
        new OddModel({
          id: selection.Id,
          value: selection.Odds ? selection.Odds[0].Value : undefined,
          spreadValue: market.SpecialValue,
          sportId: itemData.SportId,
          sportName: itemData.SportName,
          categoryId: itemData.CategoryId,
          categoryName: itemData.CategoryName,
          tournamentId: itemData.TournamentId,
          tournamentName: itemData.TournamentName,
          matchId: itemData.Id,
          matchName: itemData.Name,
          matchDate: itemData.Date,
          marketId: market.Id,
          marketTypeId: market.TypeId,
          marketName: market.Name,
          selectionId: selection.TypeId,
          selectionName: selection.Name,
          selected: this.couponService.isOddInCoupon(selection.Id),
          enabled: selection.Odds ? Boolean(selection.Odds[0].Status) : false,
          eventCategory: 'L'
        })
      );
    });

    return matchOdds;
  }

  parseOddsForCorrectScore(odds: OddModel[]): CorrectScoreOddsModel {
    const homeToWin = [];
    const awayToWin = [];
    const draw = [];
    const others = [];
    odds.forEach((odd: OddModel) => {
      if (odd?.selectionName === 'Others') {
        others.push(odd);
      } else {
        if (odd?.selectionName.length < 4) {
          const selectionName = odd?.selectionName.split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          }
        } else {
          const selections = odd?.selectionName.split('/');
          const selectionName = selections[selections.length - 1].split(':');

          if (selectionName[0] > selectionName[1]) {
            homeToWin.push(odd);
          } else if (selectionName[0] < selectionName[1]) {
            awayToWin.push(odd);
          } else if (selectionName[0] === selectionName[1]) {
            draw.push(odd);
          } else if (selectionName[0] === 'Other') {
            others.push(odd);
          }
        }
      }
    });

    const homeLength = homeToWin.length;
    const awayLength = awayToWin.length;
    let difference;
    if (homeLength > awayLength) {
      difference = homeLength - awayLength;
      for (let i = 0; i < difference; i++) {
        awayToWin.push({});
      }
      const differenceForDraw = homeLength - draw.length - 1; // -1 for the 'Other' odd
      for (let i = 0; i < differenceForDraw; i++) {
        draw.push({});
      }
    } else {
      difference = awayLength - homeLength;
      for (let i = 0; i < difference; i++) {
        homeToWin.push({});
      }
      const differenceForDraw = homeLength - draw.length - 1; // -1 for the 'Other' odd
      for (let i = 0; i < differenceForDraw; i++) {
        draw.push({});
      }
    }

    const newCorrectScoreModel = new CorrectScoreOddsModel({
      homeToWin,
      awayToWin,
      draw,
      others
    });

    return newCorrectScoreModel;
  }

  groupBySpreadValue(odds: OddModel[], selectedMarketId?: number): SpreadValueGroupedOdd[] {
    const key = 'spreadValue';
    const oddsGroupedBySpreadValue = [];
    const flags = [];
    const uniqueSpreadValues = [];

    for (const odd of odds) {
      if (flags[odd[key]]) {
        continue;
      }
      flags[odd[key]] = true;
      uniqueSpreadValues.push(odd[key]);
    }

    uniqueSpreadValues.forEach(spreadValue => {
      const spreadGroup = [];
      odds.forEach(odd => {
        if (odd[key] === spreadValue && (selectedMarketId === undefined || odd.marketTypeId === selectedMarketId)) {
          spreadGroup.push(odd);
        }
      });

      if (spreadGroup.length > 0) {
        oddsGroupedBySpreadValue.push(spreadGroup);
      }
    });

    const returnValue = oddsGroupedBySpreadValue.map(group => {
      if (this.appConfig.get('sports')?.prematchSelectionsSwap.indexOf(group[0].marketTypeId) !== -1) {
        return [group[1], group[0]];
      }
      return group;
    });

    return returnValue;
  }

  getSportsList(language: string = 'en', scheduleTimeFrame: number = 4): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });

    let extendUrl: string = '';

    if (this.appConfig.get('sports').enableCustomMenu) {
      extendUrl = 'custommenu/';
    }

    return forkJoin(
      this.apiService.get<any>(
        APIType.SportsbookFeed,
        `api/feeds/prematch/matches/${extendUrl}${language}/${scheduleTimeFrame}`,
        apiSettings
      ),
      this.apiService.get(APIType.SportsbookFeed, `api/feeds/prematch/outrights/${language}/${scheduleTimeFrame}`)
    ).pipe(
      map(responseData => {
        if (!responseData) {
          return false;
        }

        const sportsListData: SportModel[] = [];
        const specialSports: SportModel[] = [];
        const goalscorerSport: SportModel[] = [];
        const competitionsAZ: FlattenedSportModel[] = [];
        const allCompetitionByCountry: SportModel[] = [];

        responseData[0].Sports.forEach(sport => {
          if (sport.MenuUniqueID.indexOf('_') === -1) {
            sportsListData.push(this.parsePrematchMatch(sport));

            competitionsAZ.push(this.parsePrematchMatchFlattened(sport));
            allCompetitionByCountry.push(this.parsePrematchMatch(sport));
          } else {
            if (sport.MenuUniqueID === '2_0') {
              goalscorerSport.push(this.parsePrematchMatch(sport));
            } else {
              specialSports.push(this.parsePrematchMatch(sport));
            }
          }
        });

        const outrights = this.parsePrematchMatches(responseData[1]);

        this.sportStore.updateSportsList(sportsListData);

        this.updateEventSelection({ specialSports, goalscorerSport, competitionsAZ, allCompetitionByCountry, outrights });
        this.resolverService.buildCache(
          this.sportQuery.allCompetitionByCountry,
          this.sportQuery.outrights,
          this.sportQuery.specialSports,
          this.sportQuery.goalscorerSport
        );

        return sportsListData.concat(specialSports);
      })
    );
  }

  parsePrematchMatches(data: any): SportModel[] {
    const retVal: SportModel[] = [];
    data.Sports.forEach(sport => {
      const newSport: SportModel = {
        id: sport.SportID,
        menuUniqueId: sport.MenuUniqueID,
        name: sport.SportName,
        groupingType: sport.GroupingType,
        oddCount: sport.NoOfOdds,
        order: sport.Order,
        categories: []
      };

      sport.Category.forEach(category => {
        const newCategory = new CategoryModel({
          id: category.ItemID,
          name: category.ItemName,
          oddCount: category.NoOfOdds,
          sportId: sport.SportID,
          menuUniqueId: sport.MenuUniqueID,
          tournaments: []
        });

        category.Tournaments.forEach(tournament => {
          const newTournament = new TournamentModel({
            eventCount: tournament.ItemEventCount,
            id: tournament.ItemID,
            name: tournament.ItemName,
            categoryId: category.ItemID,
            oddCount: tournament.NoOfOdds,
            initRegionId: tournament.InitRegionID,
            initAreaId: tournament.InitAreaID
          });
          newCategory.tournaments.push(newTournament);
        });
        newSport.categories.push(newCategory);
      });

      retVal.push(newSport);
    });

    return retVal;
  }

  parsePrematchMatch(data: any): SportModel {
    const retVal: SportModel = {
      id: data.SportID,
      menuUniqueId: data.MenuUniqueID,
      name: data.SportName,
      groupingType: data.GroupingType,
      oddCount: data.NoOfOdds,
      order: data.Order,
      categories: []
    };

    data.Category.forEach(category => {
      const newCategory = new CategoryModel({
        id: category.ItemID,
        name: category.ItemName,
        oddCount: category.NoOfOdds,
        sportId: data.SportID,
        menuUniqueId: data.MenuUniqueID,
        tournaments: []
      });

      category.Tournaments.forEach(tournament => {
        const newTournament = new TournamentModel({
          eventCount: tournament.ItemEventCount,
          id: tournament.ItemID,
          name: tournament.ItemName,
          categoryId: category.ItemID,
          oddCount: tournament.NoOfOdds,
          initRegionId: tournament.InitRegionID,
          initAreaId: tournament.InitAreaID
        });
        newCategory.tournaments.push(newTournament);
      });
      retVal.categories.push(newCategory);
    });

    return retVal;
  }

  parsePrematchMatchFlattened(data: any): FlattenedSportModel {
    const retVal: FlattenedSportModel = {
      name: data.SportName,
      id: data.SportID,
      order: data.Order,
      oddCount: data.NoOfOdds,
      tournaments: []
    };

    data.Category.forEach(category => {
      category.Tournaments.forEach(tournament => {
        const newTournament = new TournamentModel({
          eventCount: tournament.ItemEventCount,
          id: tournament.ItemID,
          name: tournament.ItemName,
          categoryId: category.ItemID,
          oddCount: tournament.NoOfOdds,
          initRegionId: tournament.InitRegionID,
          initAreaId: tournament.InitAreaID,
          order: tournament.Order
        });
        retVal.tournaments.push(newTournament);
      });
    });

    retVal.tournaments.sort((t1, t2) => t1.order - t2.order);

    // Sorting tournaments list by oddCount

    const newFlattenedSport = new FlattenedSportModel(retVal);

    return newFlattenedSport;
  }

  getQuicklinkType(val: string): number {
    switch (val) {
      case 'preset_collection':
        return QuicklinkType.PresetCollection;
      case 'custom_link':
        return QuicklinkType.CustomLink;
      default:
        return QuicklinkType.CustomLink;
    }
  }

  getPresetType(val: string): number {
    switch (val) {
      case 'all_by_country':
        return PresetType.AllByCountry;
      case 'a_z_competitions':
        return PresetType.AZCompetitions;
      case 'goal_scorer':
        return PresetType.GoalScorer;
      case 'live_now':
        return PresetType.LiveNow;
      case 'special_sports':
        return PresetType.SpecialSports;
      case 'outrights':
        return PresetType.Outrights;
      case 'todays-events':
        return PresetType.TodaysGames;
      default:
        return PresetType.AllByCountry;
    }
  }

  getSportQuicklinks(sportId: number): Observable<any> {
    return this.apiService.get(APIType.CMS, 'sport-quicklinks').pipe(
      map(response => {
        const quicklinks: SportQuicklinks = {
          sportId,
          quicklinks: []
        };
        const currentQuicklink =
          response.SportLinks.find(link => link.sportID === sportId) || response.SportLinks.find(link => link.sportID === 0);
        if (currentQuicklink) {
          currentQuicklink.quicklinks.forEach(sportQuicklinks => {
            const quicklink: SportQuicklink = {
              name:
                this.getQuicklinkType(sportQuicklinks.quicklink_type) === 0
                  ? this.presetTypeMap[this.getPresetType(sportQuicklinks.preset_type)]
                  : undefined,
              type: this.getQuicklinkType(sportQuicklinks.quicklink_type) === 0 ? QuicklinkType.PresetCollection : QuicklinkType.CustomLink,
              id: this.getPresetType(sportQuicklinks.preset_type),
              linkText: sportQuicklinks.link_text
            };
            if (this.getQuicklinkType(sportQuicklinks.quicklink_type) === 1) {
              quicklink.linkUrl = sportQuicklinks.link_url;
            }
            const newQuicklink = new SportQuicklink(quicklink);
            quicklinks.quicklinks.push(newQuicklink);
          });

          const newQuicklinks = new SportQuicklinks(quicklinks);

          let firstCollectionFound = false;
          let initiallySelectedQuicklink: SportQuicklink;

          newQuicklinks.quicklinks.forEach(quicklink => {
            if (quicklink.type === QuicklinkType.PresetCollection && !firstCollectionFound) {
              firstCollectionFound = true;
              initiallySelectedQuicklink = quicklink;
            }
          });

          if (this.applicationQuery.activeUrl[2] === 'leagues' && this.applicationQuery.activeUrl[3] === 'outright') {
            initiallySelectedQuicklink = newQuicklinks.quicklinks.find(link => link.id === 5);
          }

          if (this.applicationQuery.activeUrl[2] === 'leagues' && this.applicationQuery.activeUrl[3] === 'prematch') {
            initiallySelectedQuicklink = newQuicklinks.quicklinks.find(link => link.id === 4);
          }

          const isSpecialSport = this.sportQuery.selectedSport.menuUniqueId
            ? this.sportQuery.selectedSport.menuUniqueId.indexOf('_') > -1
            : false;

          if (isSpecialSport) {
            if (this.sportQuery.selectedSport.menuUniqueId === '2_0') {
              this.sportStore.updateEventSelection({
                eventSelectionQuicklinks: newQuicklinks,
                quickLinksState: QuicklinksState.Success,
                selectedQuicklink: newQuicklinks.quicklinks.find(link => link.id === 7)
              });
            } else {
              this.sportStore.updateEventSelection({
                eventSelectionQuicklinks: newQuicklinks,
                quickLinksState: QuicklinksState.Success,
                selectedQuicklink: newQuicklinks.quicklinks.find(link => link.id === 6)
              });
            }
          } else {
            const previouslySelectedQuickLink = this.sportQuery.selectedQuicklink;
            this.sportStore.updateEventSelection({
              eventSelectionQuicklinks: newQuicklinks,
              quickLinksState: QuicklinksState.Success,
              selectedQuicklink: previouslySelectedQuickLink ? previouslySelectedQuickLink : initiallySelectedQuicklink
            });
          }
        }
      })
    );
  }

  updateEventSelection(eventSelection: Partial<EventSelectionState>): void {
    this.sportStore.updateEventSelection(eventSelection);
  }

  updateSelectedPrematch(selectedPrematch: EventSummaryModel[]): void {
    this.sportStore.updateSelectedPrematch(selectedPrematch);
  }

  getMostPopularEvents(language: string, sportId: number, numberOfEvents: number, date: Date = new Date()): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });

    const numberOfTournaments = this.appConfig.get('sports').topCompetitionsLeagueCount;

    return this.apiService
      .get(
        APIType.SportsbookFeed,
        `api/feeds/prematch/mostpopularsports/${language}/${sportId}/${numberOfTournaments}/${numberOfEvents}/${format(
          new Date(date),
          'yyyy-MM-dd'
        )}`,
        apiSettings
      )
      .pipe(
        tap((responseData: any) => {
          if (!responseData || !responseData.length) {
            this.sportStore.updateEventSelection({ topCompetitions: [] });
            return;
          }
          const topCompetitions = this.mapMatchSummaryDataToModel(responseData[0].AreaMatches[0]);

          const tournaments = topCompetitions.matches.reduce(
            (acc, x) => acc.concat(acc.find(y => trim(y.tournamentName) === trim(x.tournamentName)) ? [] : [x]),
            []
          );

          this.sportStore.updateEventSelection({ topCompetitions: tournaments });
        })
      );
  }

  toggleCheckbox(prematchIndex: number, matchIndex: number): void {
    const prematchUpdate: EventSummaryModel = { ...this.sportQuery.selectedPrematch[prematchIndex] };
    const matchUpdate = { ...prematchUpdate.matches[matchIndex] };
    matchUpdate.selectedInView = !matchUpdate.selectedInView;
    const matchesUpdate = [...prematchUpdate.matches];
    matchesUpdate[matchIndex] = new MatchModel(matchUpdate);
    prematchUpdate.matches = matchesUpdate;

    const newPreMatches = [...this.sportQuery.selectedPrematch];
    newPreMatches[prematchIndex] = prematchUpdate;
    this.updateSelectedPrematch(newPreMatches);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    this.isPlayerAreaSub$.unsubscribe();
  }
}
