import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';

import { CouponDetailsService } from 'src/app/core/services/coupon/coupon-details.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MyBetsService } from 'src/app/core/services/my-bets.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponDetailsQuery } from 'src/app/core/state/coupon-details/coupon-details.query';
import { MyBetsQuery } from 'src/app/core/state/my-bets/my-bets.query';
import { RecentBetModel } from 'src/app/shared/models/my-bets.model';

@Component({
  selector: 'app-coupon-details-wrapper',
  templateUrl: './coupon-details-wrapper.component.html',
  styleUrls: ['./coupon-details-wrapper.component.scss']
})
export class CouponDetailsWrapperComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  sharedCoupon$: ReplaySubject<boolean> = new ReplaySubject();

  getCouponDetails$: BehaviorSubject<RecentBetModel[]> = new BehaviorSubject([]);
  showNoCouponMessage$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public couponDetailsQuery: CouponDetailsQuery,
    private readonly activatedRoute: ActivatedRoute,
    private readonly couponDetailsService: CouponDetailsService,
    private readonly myBetsService: MyBetsService,
    readonly myBetsQuery: MyBetsQuery,
    private readonly languageService: LanguageService,
    private readonly appService: ApplicationService,
    private readonly accountQuery: AccountQuery
  ) {}

  ngOnInit(): void {
    const noCheck = this.activatedRoute.snapshot.data.noCheck;

    this.activatedRoute.paramMap
      .pipe(
        takeUntil(this.destroy$),
        switchMap(params => {
          const couponCode = params.get('couponCode');

          return this.myBetsService.getBetDetailsByCode(couponCode, this.languageService.selectedLanguage.language, noCheck);
        })
      )
      .subscribe(res => {
        if (res && res.couponCode !== null) {
          const response: RecentBetModel[] = [res];
          this.getCouponDetails$.next(response);
          this.showNoCouponMessage$.next(false);
        } else {
          this.showNoCouponMessage$.next(true);
        }
      });
  }
  openLiveChat(): void {}
  ngOnDestroy(): void {
    this.couponDetailsService.clearCashouts();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
