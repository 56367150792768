import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { ChatPopupState } from '../../../shared/models/chat-popup';
import { ChatPopupStore } from './chat-popup.store';

@Injectable({ providedIn: 'root' })
export class ChatPopupQuery extends Query<ChatPopupState> {
  state$ = this.select(state => state);
  isVisible$ = this.select(state => state.visible);
  isSuspended$ = this.select(state => state.suspend);
  shouldSuspendAfterClose$ = this.select(state => state.suspendAfterClose);

  label$ = this.select(state => state.label);

  constructor(protected store: ChatPopupStore) {
    super(store);
  }

  get visible(): boolean {
    return this.getValue().visible;
  }

  get suspend(): boolean {
    return this.getValue().suspend;
  }

  get suspendAfterClose(): boolean {
    return this.getValue().suspendAfterClose;
  }
}
