import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { addDays, format, isAfter, isBefore, subDays, subMonths, subWeeks } from 'date-fns';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { MyBetsService, MyBetsTimePeriod } from 'src/app/core/services/my-bets.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MyBetsQuery } from 'src/app/core/state/my-bets/my-bets.query';
import { MyBetsStore } from 'src/app/core/state/my-bets/my-bets.store';
import { fadeIn } from 'src/app/shared/animations';
import { ButtonType } from 'src/app/shared/models/button.model';
import { BetsTab } from 'src/app/shared/models/my-bets.model';

@Component({
  selector: 'app-my-bets',
  templateUrl: './my-bets.component.html',
  styleUrls: ['./my-bets.component.scss'],
  animations: [fadeIn()],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyBetsComponent implements OnInit, OnDestroy {
  @Input() preview?: boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();
  buttonType: typeof ButtonType = ButtonType;
  betsTab: typeof BetsTab = BetsTab;
  betsTabSelected: BetsTab = BetsTab.All;
  firstTimeLoading$ = new BehaviorSubject(true);

  TimePeriod = MyBetsTimePeriod;
  timePeriod$: BehaviorSubject<MyBetsTimePeriod> = new BehaviorSubject(MyBetsTimePeriod.TODAY);
  dateForm: FormGroup;
  today = format(new Date(), 'yyyy-MM-dd');
  loading$ = this.accountQuery.betSearch$.pipe(map(state => state.isLoading));
  oneWeekBeforeToday = format(subWeeks(new Date(), 1), 'yyyy-MM-dd');
  minFromDate$ = new BehaviorSubject(format(subMonths(new Date(this.today), 12), 'yyyy-MM-dd'));
  openCloseDateFilter$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingCoupons$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ifScrolledLeft$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ifScrolledRight$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor(
    private readonly myBetsService: MyBetsService,
    public myBetsQuery: MyBetsQuery,
    public myBetsStore: MyBetsStore,
    public accountQuery: AccountQuery,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    // this.fetchActiveTabBets();
    this.observeBetsUpdate();
    this.handleFirstTimeLoading();
    this.dateForm = this.initialiseDateForm();
    this.checkMinFromDate();
    this.timePeriod$.pipe(takeUntil(this.destroy$)).subscribe(this.fetchActiveTabBets.bind(this));
  }

  ngOnDestroy(): void {
    this.myBetsService.clearCashouts();
    this.myBetsService.clearSettledBets();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onRefresh(): void {
    this.fetchActiveTabBets();
  }

  private fetchActiveTabBets(): void {
    const period = this.timePeriod$.value;
    const from = this.dateForm.value.dateFrom;
    const to = this.dateForm.value.dateTo;
    switch (this.betsTabSelected) {
      case BetsTab.All:
        this.myBetsService.getAllBets(period, from, to);
        break;
      case BetsTab.Open:
        this.myBetsService.getOpenBets(period, from, to);
        break;
      case BetsTab.Cashout:
      case BetsTab.Won:
        this.myBetsService.getWonBets(period, from, to);
        break;
      case BetsTab.Lost:
        this.myBetsService.getLostBets(period, from, to);
        break;
      default:
        break;
    }
  }
  changeElSize(): boolean {
    return window.location.pathname === '/my-bets';
  }
  onKeydown(event: KeyboardEvent): void {
    event.preventDefault();
  }
  private observeBetsUpdate(): void {
    this.myBetsService.updateOpenBets$
      .pipe(
        filter(() => this.selectedTab === BetsTab.All || this.selectedTab === BetsTab.Open),
        takeUntil(this.destroy$)
      )
      .subscribe(this.fetchActiveTabBets.bind(this));
  }

  private handleFirstTimeLoading(): void {
    this.myBetsQuery.openLoading$
      .pipe(
        filter(l => !l),
        take(1)
      )
      .subscribe(() => {
        this.firstTimeLoading$.next(false);
        this.firstTimeLoading$.complete();
      });
  }
  initialiseDateForm(): FormGroup {
    return new FormGroup({
      dateFrom: new FormControl(this.oneWeekBeforeToday, Validators.required),
      dateTo: new FormControl(this.today, Validators.required)
    });
  }
  checkMinFromDate(): void {
    this.dateForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.dateFrom === '') {
        this.dateForm.controls.dateFrom.setValue(
          isAfter(new Date(data.dateTo), new Date(this.oneWeekBeforeToday))
            ? this.oneWeekBeforeToday
            : format(subDays(new Date(data.dateTo), 1), 'yyyy-MM-dd')
        );
      } else if (data.dateTo === '') {
        this.dateForm.controls.dateTo.setValue(
          isBefore(new Date(data.dateFrom), new Date(this.today)) ? this.today : format(subDays(new Date(data.dateFrom), 1), 'yyyy-MM-dd')
        );
      } else {
        this.minFromDate$.next(format(addDays(subMonths(new Date(data.dateTo), 12), 1), 'yyyy-MM-dd'));
      }
    });
  }
  isScrollHappened(event: Event): void {
    const betTabContainer = this.elementRef.nativeElement.querySelector('.bets-tab-container');
    const isAtLeftEnd = betTabContainer.scrollLeft === 0;
    const isAtRightEnd = betTabContainer.scrollLeft >= betTabContainer.scrollWidth - betTabContainer.clientWidth;

    this.ifScrolledLeft$.next(!isAtLeftEnd);
    this.ifScrolledRight$.next(!isAtRightEnd);
  }

  scrollLeft(): void {
    this.scrollToPosition(0);
  }

  scrollRight(): void {
    const betTabContainer = this.elementRef.nativeElement.querySelector('.bets-tab-container');
    const scrollWidth = betTabContainer.scrollWidth - betTabContainer.clientWidth;

    this.scrollToPosition(scrollWidth);
  }

  private scrollToPosition(position: number): void {
    const betTabContainer = this.elementRef.nativeElement.querySelector('.bets-tab-container');
    this.renderer.setProperty(betTabContainer, 'scrollLeft', position);

    this.isScrollHappened(null); // Trigger scroll checks after scrolling
  }
  setTimePeriod(period: MyBetsTimePeriod): void {
    this.timePeriod$.next(period);
    if (period === this.TimePeriod.CUSTOM_PERIOD) {
      this.openCloseDateFilter$.next(true);
    } else {
      this.openCloseDateFilter$.next(false);
    }
  }

  searchTimePeriod(period: MyBetsTimePeriod): void {
    const from = this.dateForm.value.dateFrom;
    const to = this.dateForm.value.dateTo;

    this.loadingCoupons$.next(true);
    setTimeout(() => {
      this.loadingCoupons$.next(false);
      this.openCloseDateFilter$.next(false);
    }, 1000);
    this.timePeriod$.next(period);
    this.myBetsService.getAllBets(period, from, to);
  }

  isTimePeriod$(period: MyBetsTimePeriod): Observable<boolean> {
    return this.timePeriod$.pipe(map(selectedPeriod => selectedPeriod === period));
  }

  closeDateFilter() {
    this.openCloseDateFilter$.next(false);
  }

  setTab(tab: BetsTab): void {
    this.myBetsStore.changeTab(tab);
    this.betsTabSelected = tab;
    this.fetchActiveTabBets();
  }

  get selectedTab(): BetsTab {
    return this.myBetsQuery.selectedTab;
  }
}
