import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { fadeIn } from 'src/app/shared/animations';

@Component({
  selector: 'app-login-page-wrapper',
  templateUrl: './login-page-wrapper.component.html',
  styleUrls: ['./login-page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()]
})
export class LoginPageWrapperComponent {
  urlAfterLogin$ = this.route.queryParams.pipe(map(params => params.urlAfterLogin));
  presetFunctionAfterLogin$ = this.route.queryParams.pipe(map(params => params.presetFunctionAfterLogin));
  inForgotPassword = this.route.snapshot.data.inForgotPassword;

  constructor(private readonly route: ActivatedRoute) { }
}
