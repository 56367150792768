import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BottomSheetState } from '../../../shared/models/bottom-sheet';
import { BottomSheetStore } from './bottom-sheet.store';

@Injectable({ providedIn: 'root' })
export class BottomSheetQuery extends Query<BottomSheetState> {
  state$ = this.select(state => state);
  isVisible$ = this.select(state => state.visible);

  constructor(protected store: BottomSheetStore) {
    super(store);
  }
}
