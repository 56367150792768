import { formatCurrency } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';

import { translate } from '@ngneat/transloco';
import {
  BetCoupon,
  BetCouponGlobalVariable,
  BetCouponGroup,
  Bonus,
  ClientsideCouponService,
  CouponAction,
  CouponType,
  Dictionary,
  Selection,
  UpdateCouponRequest,
  UpdateCouponResponse
} from 'clientside-coupon';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, first, map } from 'rxjs/operators';

import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { PersonalizedStakeService } from 'src/app/core/services/coupon/personalized-stake.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { MyBetsService } from 'src/app/core/services/my-bets.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { PersonalizedDefaultStakeReq } from 'src/app/shared/models/account.model';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  BookedCoupon,
  CouponGroupingType,
  CouponOddsModel,
  CouponSettings,
  CouponUIState,
  OddChanges,
  OddModel
} from 'src/app/shared/models/coupon.model';
import { APIService } from '../api.service';
import { DefaultCouponStake } from './../../../shared/models/coupon.model';
import { CurrencyFormatPipe } from './../../../shared/pipes/currency-format.pipe';

@Injectable({
  providedIn: 'root'
})
export class CouponService implements OnDestroy {
  loading: boolean = false;
  enforceSingleCombination: boolean = false;
  allowCompleteDeselectOfEventOdds: boolean = true;
  lockedCouponSelections$: BehaviorSubject<{ [SelectionID: number]: boolean }> = new BehaviorSubject({});
  skipOddsChangedUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly insertCouponStatusCodes: any = {
    0: translate('An error has occurred, please try again'),
    1: translate('Bet was successful'),
    2: translate('At this time you cannot place bets. Please contact customer support for more information'),
    3: translate('One of the chosen events has expired'),
    4: translate('One of the odds has changed.'),
    5: translate('Wrong Password'),
    6: translate('Username Equals Agent name'),
    7: translate('Timeout For Coupon Cancellation'),
    8: translate('Wrong User Network'),
    9: translate('Wrong User Information'),
    10: translate('Username Already Exists'),
    11: translate('Account Insert Data Error'),
    12: translate('Error Inserting Contact User'),
    13: translate('Error Inserting Parameters User'),
    14: translate('Error Updating User'),
    15: translate('Wrong Currency'),
    16: translate('Bet not successful: Insufficient account balance. Deposit now to complete your bet'),
    17: translate('Bet Not Paid By System'),
    18: translate('Agent Bettor Mismatch'),
    19: translate('Bet Already Paid'),
    20: translate('Max Number Of Coupons Cancelled'),
    21: translate('Bet Lost or Open'),
    22: translate('Banker Not Allowed On Integrals'),
    23: translate('Email Already Exist'),
    24: translate('MinLimit By Player Reached'),
    25: translate('MaxLimit By Player Reached'),
    26: translate('Single Bet Max Limit By User'),
    27: translate('Multiple Bet Max Limit By User'),
    28: translate('Combination Bet Max Limit By User'),
    29: translate('Concurrency Issue'),
    30: translate('Team Already Exists'),
    31: translate('Error Inserting User Risk Settings'),
    32: translate('Results Already Exists'),
    33: translate('Error Inserting User Limits'),
    34: translate('Missing Mappings'),
    35: translate('Stake Too High To Cancel'),
    36: translate('Operation Already Done'),
    37: translate('Cannot Cancel Live Bet'),
    38: translate('Coupon Already Paid'),
    39: translate('Max Coupon Loss Limit Exceeded'),
    40: translate('Incorrect number of events'),
    41: translate('Role Already Exists'),
    43: translate('Operator Already Exists'),
    44: translate('Country Mismatch'),
    45: translate('Over Max Win Threshold'),
    46: translate('Has To Change Password'),
    49: translate('Maximum combinability exceeded'),
    50: translate('Cannot Cancel Has Sub Events'),
    51: translate('Cannot Cancel Has Markets'),
    52: translate('Maximum stake allowed exceeded'),
    53: translate('Maximum winning allowed exceeded'),
    54: translate('Stake is lower than amount allowed'),
    55: translate('Stake for each group is lower than amount allowed'),
    69: translate('The maximum number of events allowed has been exceeded'),
    70: translate('Incompatible events in the coupon'),
    200: translate('Record Not Found'),
    201: translate('Operation Not Allowed')
  };

  readonly updateCouponStatusCodes: any = {
    0: translate('Success'),
    1: translate('Operation Not Allowed'),
    2: translate('Selection has already been added'),
    3: translate('OutrightError'),
    4: translate('Cannot add a selection with a unique event'),
    5: translate('Cannot add a unique event'),
    6: translate('Max number of selections reached'),
    7: translate('Cannot add more than 30 events'),
    8: translate('Banker not allow in cross combination'),
    9: translate('Cannot insert cross combination on live'),
    10: translate('Banker not allowed on live'),
    11: translate('Related events are incompatible'),
    12: translate('Minimum combinability not reached'),
    13: translate('Odd Does Not Exist'),
    14: translate('Could not remove odd'),
    15: translate('Could not remove grouping'),
    16: translate('Wrong game play. Requires more events.'),
    17: translate('One of the odds has changed'),
    18: translate('One of the chosen events has expired'),
    19: translate('Stake under minimum amount allowed'),
    20: translate('Stake over maximum amount allowed'),
    21: translate('Over maximum winning amount'),
    22: translate('Group stake under minimum amount allowed'),
    23: translate('Group stake over maximum amount allowed'),
    405: translate('Booking code is no longer available. All events are expired.')
  };
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly apiService: APIService,
    private readonly couponStore: CouponStore,
    private readonly couponQuery: CouponQuery,
    private readonly accountService: AccountService,
    private readonly accountQuery: AccountQuery,
    private readonly notificationService: NotificationService,
    private readonly evaluationService: EvaluationService,
    private readonly applicationService: ApplicationService,
    private readonly myBetsService: MyBetsService,
    private readonly personalizedStakeService: PersonalizedStakeService,
    private readonly dataLayerService: DataLayerService,
    private readonly currencyFormat: CurrencyFormatPipe
  ) {
    this.populateSportsbookVariables().subscribe(() => {
      this.couponStore.updateCouponInitialized(true);
    });

    this.couponQuery.couponSettings$.subscribe(sub => {
      this.couponStore.updateCouponSettings(sub);
    });
  }

  get currencySymbol(): string {
    return this.accountQuery.userData && this.accountQuery.userData.currency.symbol
      ? this.accountQuery.userData.currency.symbol
      : this.appConfig.get('sports').coupon.defaultCurrency;
  }

  delayOddsChangedUpdate(): void {
    this.skipOddsChangedUpdate$.next(true);
    setTimeout(() => {
      this.skipOddsChangedUpdate$.next(false);
    }, 1000);
  }

  rebetCoupon(couponCode: string, language: string = 'en'): Observable<any> {
    return this.apiService.get(APIType.Sportsbook, `api/coupons/rebet/byCode/${couponCode}/language/${language}`).pipe(
      map(data => {
        if (data.BetCoupon) {
          let oddChange: OddChanges[] = [];

          data.BetCoupon?.Odds?.forEach(odd => {
            odd.SelectionName = this.getSelectionName(odd);

            if (this.isRebetSpecialsWithMatchNameToBeParsed(odd)) {
              odd.MatchName = this.parseRebetSpecialsMatchName(odd);
            }
            oddChange.push(
              new OddChanges({
                oddId: odd.SelectionId,
                initialOddValue: odd.OddValue,
                firstOddValue: odd.OddValue,
                latestOddValue: odd.OddValue,
                valueChanged: false
              })
            );
          });

          this.updateCoupon(data.BetCoupon);
          this.couponStore.updateOddChanges(oddChange);
          return data;
        } else {
          return false;
        }
      })
    );
  }

  isOddInCoupon(oddId: number): boolean {
    if (this.couponQuery.couponData === undefined || this.couponQuery.couponData.Odds === undefined) {
      return false;
    }
    return this.couponQuery.couponData.Odds.findIndex(o => o.SelectionId === oddId) > -1;
  }

  addOdd(odd: OddModel, language: string = 'en'): UpdateCouponResponse {
    // potential specials where we have to parse match name into another format
    // first checking spreadValue to reduce unnecessary computing for most of the odds
    if (odd.spreadValue && this.isSpecialsWithMatchNameToBeParsed(odd)) {
      odd.matchName = this.parseSpecialsMatchName(odd);
    }

    this.removeExistingSelectionsFromSameMatch(odd);

    if (odd.eventCategory && odd.eventCategory.toUpperCase() === 'L' && this.appConfig.get('live').useServerSideCoupon) {
      this.addOddServerSide(odd, language).subscribe();
      return new UpdateCouponResponse({});
    } else {
      return this.addOddClientSide(odd);
    }
  }

  removeExistingSelectionsFromSameMatch(odd: any): void {
    if (!this.couponQuery.couponData) {
      return;
    }

    const selectedOddsFromSameMatch = this.couponQuery.couponData.Odds.filter(couponOdd => couponOdd.MatchId === odd.matchId) || [];
    selectedOddsFromSameMatch.forEach(odd => {
      this.removeOdd(odd.SelectionId);
    });
  }

  parseSpecialsMatchName(odd: OddModel): string {
    let [homeTeam, awayTeam] = odd.matchName.split(' - ');
    if (homeTeam.includes('[') && homeTeam.includes(']')) {
      awayTeam = this.getPlayerNameFromTeamName(homeTeam);
      homeTeam = homeTeam.replace(`[${awayTeam}]`, '');
    } else if (awayTeam.includes('[') && awayTeam.includes(']')) {
      homeTeam = awayTeam;
      awayTeam = this.getPlayerNameFromTeamName(homeTeam);
      homeTeam = homeTeam.replace(`[${awayTeam}]`, '');
    }
    return `${homeTeam} - ${awayTeam}`;
  }

  parseRebetSpecialsMatchName(odd: any): string {
    let [homeTeam, awayTeam] = odd.MatchName.split(' - ');
    if (homeTeam.includes('[') && homeTeam.includes(']')) {
      awayTeam = this.getPlayerNameFromTeamName(homeTeam);
      homeTeam = homeTeam.replace(`[${awayTeam}]`, '');
    } else if (awayTeam.includes('[') && awayTeam.includes(']')) {
      homeTeam = awayTeam;
      awayTeam = this.getPlayerNameFromTeamName(homeTeam);
      homeTeam = homeTeam.replace(`[${awayTeam}]`, '');
    }
    return `${homeTeam} - ${awayTeam}`;
  }

  addToOddChanges(oddId: number, oddValue: number): void {
    this.couponStore.addToOddChanges(oddId, oddValue);
  }

  removeOdd(oddId: number, marketId?: number): UpdateCouponResponse {
    let proceedWithRemoval = true;
    if (!this.allowCompleteDeselectOfEventOdds) {
      // See whether this market has any selections left in the coupon
      proceedWithRemoval = this.couponQuery.couponData.Odds.filter(o => o.MarketId === marketId).length > 1;
    }

    if (proceedWithRemoval) {
      this.couponStore.clearGroupingTab();

      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: this.couponQuery.couponData,
          bonusList: this.couponQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection
        })
      );

      this.couponStore.updateCouponData(response.updatedCoupon);

      this.removeFromOddChanges(oddId);

      this.applicationService.showQuickCoupon(false);

      return response;
    } else {
      return undefined;
    }
  }

  removeOdds(oddIds: number[]): UpdateCouponResponse {
    let updatedCoupon: BetCoupon = this.couponQuery.couponData;
    let allOddsRemoved: boolean = true;

    this.couponStore.clearGroupingTab();

    oddIds.forEach(oddId => {
      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: updatedCoupon,
          bonusList: this.couponQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection
        })
      );

      updatedCoupon = response.updatedCoupon;
      if (response.success) {
        this.removeFromOddChanges(oddId);
      } else {
        allOddsRemoved = false;
      }
    });

    this.couponStore.updateCouponData(updatedCoupon);
    this.applicationService.showQuickCoupon(false);

    return new UpdateCouponResponse({
      success: allOddsRemoved,
      updatedCoupon: updatedCoupon
    });
  }

  removeFromOddChanges(oddId: number): void {
    this.couponStore.removeFromOddChanges(oddId);
  }

  updateOddBankerStatus(oddId: number, isBanker: boolean): UpdateCouponResponse {
    const selection = new Selection();
    selection.oddId = oddId;

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateOddBankerStatus,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: selection,
        isBanker: isBanker
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  clearAllBankers(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ClearAllBankers,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateStakeValue(stakeValue: number): UpdateCouponResponse {
    if (!stakeValue) {
      return;
    }
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateStakeValue,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        stakeValue: stakeValue
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);

    return response;
  }

  updateGroupings(groupings: BetCouponGroup[]): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupings,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        groupings: groupings
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateGroupingStakeValue(grouping: BetCouponGroup): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupingStakeValue,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        groupings: [grouping],
        groupingStakeValue: grouping.Stake
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  isGroupingVisible(grouping: BetCouponGroup, isLast: boolean): boolean {
    const groupingsTabSelected = this.couponQuery.groupingsTabSelected;

    if (groupingsTabSelected === CouponGroupingType.Split && isLast) {
      return true;
    } else if (groupingsTabSelected === CouponGroupingType.Combination) {
      if (!isLast && grouping.Grouping !== 1) {
        return true;
      }
    } else if (groupingsTabSelected === CouponGroupingType.Singles && grouping.Grouping === 1) {
      return true;
    }

    return false;
  }

  validateCoupon(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix
      })
    );

    return response;
  }

  validateAndPostCoupon(): Observable<boolean> {
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const validation = this.validateCoupon();

    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postCoupon();
    }
  }

  validateAndPostBookCoupon(): Observable<any> {
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const bookedCouponCount = this.couponQuery.bookedCoupons.length;
    const maxNumberOfBookedBets = 20;

    if (bookedCouponCount >= maxNumberOfBookedBets) {
      this.notificationService.showErrorNotification(
        translate('Maximum number of booked bets reached. Please remove previously booked bets.')
      );
      return of(false);
    }

    const validation = this.validateCoupon();
    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postBookCoupon();
    }
  }

  updateUI(ui: CouponUIState): void {
    this.couponStore.update({ ui });
  }

  updateCouponSetting(couponSettingKey: string, couponSetting: any): void {
    const setting = new CouponSettings({});
    setting[couponSettingKey] = couponSetting;
    this.couponStore.updateCouponSetting(setting);
  }

  clearCouponData(): void {
    this.couponStore.clearCouponData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  removeBookedCoupon(couponCode: string): void {
    this.couponStore.removeBookedCoupon(couponCode);
  }

  updateDefaultCouponStake(defaultCouponStake: DefaultCouponStake): void {
    this.couponStore.updateDefaultCouponStake(defaultCouponStake);
  }

  getStatus(statusCode: number): string {
    return this.getInsertCouponStatus(statusCode);
  }

  updateCoupon(couponData: any): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  getOddsChanged(couponOdds: CouponOddsModel[], language: string = 'en'): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });
    const bodyData = couponOdds;

    return this.apiService
      .post<any>(APIType.Sportsbook, `api/feeds/oddsChangedLocked/${language}?includeAllOddsWithErrorCodes=true`, bodyData, apiSettings)
      .pipe(
        map(responseData => {
          if (!responseData || responseData.length === 0) {
            return;
          }

          const couponDataCopy: BetCoupon = cloneDeep(this.couponQuery.couponData);
          if (!couponDataCopy) {
            return;
          }

          responseData.forEach(changedOdd => {
            const couponOdd = (couponDataCopy.Odds ?? []).find(o => o.SelectionId === changedOdd.SelectionID);

            const lockedSelections = { ...this.lockedCouponSelections$.value };
            lockedSelections[changedOdd.SelectionID] = changedOdd.IsLocked || changedOdd.SelectionValue == 0;
            this.lockedCouponSelections$.next(lockedSelections);

            if (couponOdd) {
              couponOdd.OddValue = changedOdd.SelectionValue;
              couponOdd.ConfirmedOddValue = changedOdd.SelectionValue;
            }

            this.couponStore.updateOddChange(changedOdd.SelectionID, changedOdd.SelectionValue);
          });

          this.couponStore.updateCouponData(couponDataCopy);

          // TODO:this has to be changed with a new clientside method.
          this.updateStakeValue(this.couponQuery.couponData.StakeGross);
        })
      );
  }

  acceptOddChanges(): void {
    const oddChangesCopy: OddChanges[] = cloneDeep(this.couponQuery.oddChanges);

    oddChangesCopy.forEach(o => {
      o.firstOddValue = o.latestOddValue;
      o.valueChanged = false;
    });

    this.couponStore.updateOddChanges(oddChangesCopy);
  }

  getInsertCouponStatus(statusCode: number): string {
    return this.insertCouponStatusCodes[statusCode];
  }

  getUpdateCouponStatus(statusCode: number): string {
    return statusCode === 21
      ? translate('Max winning amount of {{amount}} is exceeded', {
          amount:
            this.couponQuery.couponData.CouponType === CouponType.Single
              ? this.currencyFormat.transform(this.couponQuery.globalVariables.MaxSingleBetWin)
              : this.couponQuery.couponData.CouponType === CouponType.Multiple
              ? this.currencyFormat.transform(this.couponQuery.globalVariables.MaxMultipleBetWin)
              : this.currencyFormat.transform(this.couponQuery.globalVariables.MaxCombinationBetWin)
        })
      : this.updateCouponStatusCodes[statusCode];
  }

  checkBonusStake(postFn: Function): void {
    if (
      this.couponQuery.couponSettings.useBonusFunds &&
      this.accountQuery.userData.wallets[1].balance > 0 &&
      this.couponQuery.couponData.Stake > this.accountQuery.userData.wallets[1].balance
    ) {
      this.notificationService.showSuccessNotification(
        translate(
          'You do not have enough bonus funds. {{bonusFunds}} will be subtracted from your bonus account, and {{regularFunds}} will be substrate from your regular account.',
          {
            bonusFunds: this.currencyFormat.transform(this.accountQuery.userData.wallets[1].balance),
            regularFunds: this.currencyFormat.transform(this.couponQuery.couponData.Stake - this.accountQuery.userData.wallets[1].balance)
          }
        ),
        '',
        postFn
      );
    } else if (this.couponQuery.couponSettings.useBonusFunds && this.accountQuery.userData.wallets[1].balance === 0) {
      this.notificationService.showSuccessNotification(
        translate('You do not have enough bonus funds. The bet will be placed from your standard account'),
        '',
        postFn
      );
    } else {
      postFn();
    }
  }

  checkCouponStake(): boolean {
    if (this.couponQuery.couponData.Stake < this.getMinBetStake()) {
      this.notificationService.showErrorNotification(
        translate('{{statusCode}} of {{minStake}}', { statusCode: this.updateCouponStatusCodes[19], minStake: this.getFormattedMinStake() })
      );
      return false;
    }

    if (this.couponQuery.couponData.Stake > this.couponQuery.globalVariables.MaxBetStake) {
      this.notificationService.showErrorNotification(
        translate('{{statusCode}} of {{maxStake}}', { statusCode: this.updateCouponStatusCodes[19], maxStake: this.getFormattedMaxStake() })
      );
      return false;
    }
    return true;
  }

  private getSelectionName(odd: any): string {
    const hasSpread = odd.SpecialValue !== '';
    const hideSpread =
      hasSpread && odd.IDSport === 1 && this.appConfig.get('sports')?.coupon?.hideMarketSpreadIds.includes(odd.MarketTypeId);

    return hasSpread && !hideSpread
      ? `${odd?.selectionName || odd?.SelectionName} (${odd.SpecialValue})`
      : odd?.selectionName || odd?.SelectionName;
  }

  private addOddClientSide(odd: OddModel): UpdateCouponResponse {
    this.couponStore.clearGroupingTab();

    const oldCouponData = this.couponQuery.couponData;

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.AddOdd,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.couponQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: odd.toSelection(),
        allowSameMatchSelections: this.appConfig.get('sports').allowSameMatchSelections
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);

    this.handlePersonalizedStake({
      UserId: this.userId,
      OldCouponData: oldCouponData,
      UpdatedCouponData: response.updatedCoupon,
      DefaultStake: oldCouponData?.Stake || response?.updatedCoupon?.Stake
    });

    if (!response.success) {
      this.handleErrorMessage(response.statusCode);
    }

    const newOdd = response.updatedCoupon.Odds.find(o => o.SelectionId === odd.id);
    if (newOdd) {
      this.addToOddChanges(odd.id, newOdd.OddValue);
    }

    if (response.updatedCoupon.Odds.length === 1) {
      this.applicationService.showQuickCoupon(true);
    } else {
      this.applicationService.showQuickCoupon(false);
    }

    return response;
  }

  private addOddServerSide(odd: OddModel, language: string = 'en'): Observable<UpdateCouponResponse> {
    // If allowSameMatchSelections is set to false and there is already an odd with the same
    // match of the new odd, remove the previous odd before calling the server
    if (!this.appConfig.get('sports').allowSameMatchSelections) {
      const prevSelection = this.couponQuery.getSameMatchSelection(odd.matchId);
      if (prevSelection) {
        this.removeOdd(prevSelection.SelectionId);
      }
    }

    const couponEmpty = this.couponQuery.couponData === undefined;
    const url = `api/LiveCoupons/${couponEmpty ? 'AddSelections' : 'AddSelectionsToCoupon'}/${language}?selectionIds[0]=${odd.id}`;
    const bodyData = this.couponQuery.couponData || {};
    const oldCouponData = this.couponQuery.couponData;
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });

    this.addOddClientSide(odd);

    return this.apiService.post<any>(APIType.Sportsbook, url, bodyData, apiSettings).pipe(
      map(responseData => {
        if (!responseData.BetCoupon) {
          return new UpdateCouponResponse({
            success: false,
            statusCode: responseData.ResponseStatus
          });
        }

        if (!isNaN(responseData.BetCoupon.MinBonusPerc)) {
          responseData.BetCoupon.MaxPercentageBonus = responseData.BetCoupon.MaxBonusPerc;
          delete responseData.BetCoupon.MaxBonusPerc;
        }

        if (!isNaN(responseData.BetCoupon.MinBonusPerc)) {
          responseData.BetCoupon.MinPercentageBonus = responseData.BetCoupon.MinBonusPerc;
          delete responseData.BetCoupon.MinBonusPerc;
        }

        const updatedCoupon = this.clientsideCouponService.formatCoupon(responseData.BetCoupon);
        this.couponStore.updateCouponData(updatedCoupon);
        const newOdd = updatedCoupon.Odds.find(o => o.SelectionId === odd.id);
        if (newOdd) {
          this.addToOddChanges(odd.id, newOdd.OddValue);
        }

        if (updatedCoupon.Odds.length === 1) {
          this.applicationService.showQuickCoupon(true);
        } else {
          this.applicationService.showQuickCoupon(false);
        }

        this.handlePersonalizedStake({
          UserId: this.userId,
          OldCouponData: oldCouponData,
          UpdatedCouponData: updatedCoupon,
          DefaultStake: oldCouponData?.Stake || updatedCoupon?.Stake
        });

        return new UpdateCouponResponse({
          success: true,
          updatedCoupon: updatedCoupon,
          statusCode: responseData.ResponseStatus
        });
      })
    );
  }

  private postCoupon(): Observable<boolean> {
    this.loading = true;
    return this.syncCouponToServer().pipe(
      concatMap(syncResponse => {
        if (!syncResponse.success) {
          this.loading = false;
          this.handleErrorMessage(syncResponse.statusCode);
          return of(false);
        }

        return this.insertCoupon().pipe(
          map(insertResponse => {
            this.loading = false;
            if (!insertResponse.success) {
              this.notificationService.showErrorNotification(
                this.getInsertCouponStatus(insertResponse.statusCode),
                translate('Coupon Not Posted')
              );

              return false;
            }

            const betResponseStatus = insertResponse.statusCode;
            const coupon = insertResponse.updatedBetCoupon;

            if (betResponseStatus === 1) {
              // Coupon saved successfully.

              const inReserva = coupon != undefined && coupon.CurrentEvalReason !== 0;
              if (inReserva) {
                coupon.CouponId = insertResponse.couponId;
                coupon.CouponCode = insertResponse.couponCode;
                coupon.CouponDate = insertResponse.couponDate;

                this.evaluationService.addToEvaluation(coupon);
                this.evaluationService.runEvaluation().subscribe();

                this.notificationService.showInfoNotification(
                  translate('Coupon is being evaluated by operator'),
                  translate('Coupon in Evaluation')
                );
              } else {
                this.accountService.updateBalance();
                this.notificationService.showSuccessNotification(this.getStatus(betResponseStatus), translate('Coupon Posted'));
                this.myBetsService.addNewBet(insertResponse.couponCode);
                this.dataLayerService.setValueToDataLayer('betPlaced', insertResponse.updatedBetCoupon.StakeGross);
              }
            } else if (betResponseStatus === 3) {
              // One of the chosen events has expired.
              if (coupon != undefined) {
                this.updateCoupon(coupon);
              }
              this.notificationService.showErrorNotification(this.getStatus(betResponseStatus), translate('Coupon Not Posted'));
            } else if (betResponseStatus === 4) {
              // One of the odds has changed.
              if (coupon != undefined) {
                this.updateCoupon(coupon);
              }

              this.notificationService.showErrorNotification(this.getStatus(betResponseStatus), translate('Coupon Not Posted'));
            } else {
              if (coupon != undefined) {
                this.updateCoupon(coupon);
              }
              this.notificationService.showErrorNotification(this.getStatus(betResponseStatus), translate('Coupon Not Posted'));
            }
            return true;
          })
        );
      })
    );
  }

  private postBookCoupon(): Observable<any> {
    this.loading = true;

    return this.bookCoupon().pipe(
      map(bookResponse => {
        this.loading = false;
        if (!bookResponse.success) {
          this.notificationService.showErrorNotification(
            this.getInsertCouponStatus(bookResponse.statusCode),
            translate('Coupon Not Booked')
          );
          return false;
        }

        const bookedCoupon = new BookedCoupon({
          couponCode: bookResponse.couponCode,
          date: format(new Date(), 'dd/MM/yy')
        });

        this.couponStore.updateBookedCoupon(bookedCoupon);
        return bookedCoupon;
      })
    );
  }

  private handleErrorMessage(statusCode: number): void {
    const errorMessage = this.getUpdateCouponStatus(statusCode);

    // Show error message according to the status code returned
    if (statusCode === 19) {
      // 19 => 'Stake under minimum amount allowed'. In that case we include the minimum stake amount
      this.notificationService.showErrorNotification(
        translate('{{statusCode}} of {{minStake}}', { statusCode: errorMessage, minStake: this.getFormattedMinStake() }),
        translate('Coupon Error')
      );
    } else if (statusCode === 22) {
      // 22 => 'Group stake under minimum amount allowed'. In that case we include the minimum group stake amount
      const minMessage = translate('The minimum acceptable total stake is {{minStake}}.', {
        minStake: this.getFormattedTotalStake()
      });
      this.notificationService.showErrorNotification(
        `${errorMessage} of ${this.getFormattedMinGroupStake()}. ${minMessage}`,
        translate('Coupon Error')
      );
    } else {
      this.notificationService.showErrorNotification(errorMessage, translate('Coupon Error'));
    }
  }

  private populateSportsbookVariables(): Observable<any> {
    const apiCalls = [
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/bonuslist`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/globalvariables`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/incompatiblemarketexceptions`)
    ];
    const correctScoreOddsMatrix = this.couponQuery.correctScoreOddsMatrix;
    if (
      !correctScoreOddsMatrix ||
      correctScoreOddsMatrix.cacheVersion !== this.appConfig.get('siteVersion') ||
      correctScoreOddsMatrix.data === undefined
    ) {
      apiCalls.push(this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/selectionCompatibilityMatrix`));
    }

    return forkJoin(apiCalls).pipe(
      map(([bonusListData, globalVariablesData, marketExceptionsData, correctScoreOddsMatrixData]) => {
        if (bonusListData != undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.couponStore.updateBonusList(bonusList);
        }

        if (globalVariablesData != undefined) {
          const globalVariables: BetCouponGlobalVariable = globalVariablesData;
          this.couponStore.updateGlobalVariables(globalVariables);
        }

        if (marketExceptionsData != undefined) {
          const marketExceptions: Dictionary<number, number[]> = marketExceptionsData;
          this.couponStore.updateMarketExceptions(marketExceptions);
        }

        if (correctScoreOddsMatrixData != undefined) {
          this.couponStore.updateCorrectScoreOddsMatrix({
            cacheVersion: this.appConfig.get('siteVersion'),
            data: correctScoreOddsMatrixData
          });
        }
      })
    );
  }

  syncCouponToServer(): Observable<any> {
    if (!this.couponQuery.couponData || !this.couponQuery.couponData.Odds || this.couponQuery.couponData.Odds.length === 0) {
      return of(null);
    }

    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    return this.apiService.put<any>(APIType.Sportsbook, `api/BetCoupons/UpdateCoupon`, this.couponQuery.couponData, apiSettings).pipe(
      map(response => {
        let couponSuccess = false;
        if (response.ResponseStatus === 0) {
          // BTK-1299: The following line has been temporarily commented out because the response returned by the api
          // doesn't yet match our model structure, resulting in lost/misplaced data
          // this.setCouponData(this.clientsideCouponService.formatCoupon(response.BetCoupon));

          // BTK-1419: Temporarily creating a copy of the OddValue field in a new field called ConfirmedOddValue.
          // This is usually done by the api but for now we have to do it manually due to the above commented out line
          const couponData = cloneDeep(this.couponQuery.couponData);
          couponData.Odds.forEach(odd => {
            odd.ConfirmedOddValue = odd.OddValue;
          });
          this.couponStore.updateCouponData(couponData);

          couponSuccess = true;
        } else if (response.ResponseStatus === 17) {
          // One of the odds has changed.
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          if (this.couponQuery.couponSettings.allowOddChanges) {
            couponSuccess = true;
          }
        } else {
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }
        }

        return {
          success: couponSuccess,
          statusCode: response.ResponseStatus
        };
      })
    );
  }

  private insertCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    const bodyData = {
      AllowOddChanges: this.couponQuery.couponSettings.allowOddChanges,
      AllowStakeReduction: this.couponQuery.couponSettings.allowStakeReduction,
      BetCoupon: this.couponQuery.couponData,
      RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
      TransferStakeFromAgent: this.couponQuery.couponSettings.transferUserId === null ? false : true
    };

    return this.apiService.post<any>(APIType.Sportsbook, `api/coupons/InsertCoupon`, bodyData, apiSettings).pipe(
      map(responseData => {
        let couponPosted = false;
        if (responseData.ResponseStatus === 1) {
          couponPosted = true;
          this.couponStore.clearCouponData(); // Clear the coupon if it was posted successfully
        }

        return {
          success: couponPosted,
          statusCode: responseData.ResponseStatus,
          couponId: responseData.CouponId,
          couponCode: responseData.CouponCode,
          couponDate: responseData.CouponDate,
          updatedBetCoupon: responseData.UpdatedBetCoupon
        };
      }),
      catchError(() =>
        of({
          success: false
        })
      )
    );
  }

  private bookCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    const requestTransactionId = this.clientsideCouponService.generateTransactionId(); // TODO: don't regenerate every time
    const bodyData = this.couponQuery.couponData;

    return this.apiService.post<any>(APIType.Sportsbook, `api/BetCoupons/Book/${requestTransactionId}`, bodyData, apiSettings).pipe(
      map(responseData => {
        let couponBooked = false;
        if (responseData.ResponseStatus === 1) {
          couponBooked = true;
        }

        return {
          success: couponBooked,
          statusCode: responseData.ResponseStatus,
          couponCode: responseData.BookedCouponCode
        };
      })
    );
  }

  private getFormattedMinStake(): string {
    return this.currencyFormat.transform(this.getMinBetStake());
  }

  private getMinBetStake(): number {
    let minBetStake: number;
    switch (this.couponQuery.couponData.CouponType) {
      case CouponType.Single:
        minBetStake = this.couponQuery.globalVariables.MinBetStakeSingle;
        break;
      case CouponType.Multiple:
        minBetStake = this.couponQuery.globalVariables.MinBetStakeMultiple;
        break;
      case CouponType.System:
        minBetStake = this.couponQuery.globalVariables.MinBetStakeCombination;
        break;
      default:
        minBetStake = this.couponQuery.globalVariables.MinBetStake;
        break;
    }

    return minBetStake;
  }

  private getFormattedMaxStake(): string {
    return this.currencyFormat.transform(this.couponQuery.globalVariables.MaxBetStake);
  }

  private getFormattedMinGroupStake(): string {
    return this.currencyFormat.transform(this.couponQuery.globalVariables.MinGroupingsBetStake);
  }

  private getFormattedTotalStake(): string {
    const minAllowedGroupingStake = this.couponQuery.globalVariables.MinGroupingsBetStake;
    let totalCombinations = 0;
    let totalStake = 0;

    this.couponQuery.couponData.Groupings.forEach(group => {
      totalCombinations += group.Combinations;
    });

    totalStake = minAllowedGroupingStake * totalCombinations;

    return formatCurrency(totalStake, 'en-GB', this.currencySymbol, '1.2-2');
  }

  private handlePersonalizedStake(data: PersonalizedDefaultStakeReq): void {
    if (!this.userId) {
      return;
    }

    this.personalizedStakeService
      .getPersonalizedCouponStake(data)
      .pipe(first())
      .subscribe(personalizedDefaultStake => personalizedDefaultStake && this.updateStakeValue(personalizedDefaultStake));
  }

  private get userId(): number {
    return this.accountQuery.userData?.id;
  }

  private isSpecialsWithMatchNameToBeParsed(odd: OddModel): boolean {
    return odd.matchName.includes('[') && odd.matchName.includes(']');
  }

  private isRebetSpecialsWithMatchNameToBeParsed(odd: any): boolean {
    return odd.MatchName.includes('[') && odd.MatchName.includes(']');
  }

  private getPlayerNameFromTeamName(teamName: string): string {
    const openingIdx = teamName.indexOf('[');
    const closingIdx = teamName.indexOf(']');
    return teamName.slice(openingIdx + 1, closingIdx);
  }
}
