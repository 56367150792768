import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { AnalyticsEventAction } from 'src/app/shared/models/analytics.model';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[analytics]'
})
export class AnalyticsDirective implements OnInit {
  @Input() action: string;
  @Input() click: boolean;
  @Input() focus: boolean;
  @Input() blur: boolean;
  @Input() emitError: boolean;
  @Input() category: string;
  @Input() label: string;
  @Input() value: number;
  @Input() metadata: any;

  constructor(private readonly element: ElementRef, private readonly analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    if (this.emitError) {
      this.handleErrorEvent();
    }

    if (this.click) {
      this.registerClickEvent();
    }

    if (this.focus) {
      this.registerFocusEvent();
    }

    if (this.focus) {
      this.registerBlurEvent();
    }
  }

  private handleErrorEvent(): void {
    this.emitEvent(AnalyticsEventAction.Error);
  }

  private registerClickEvent(): void {
    fromEvent(this.element.nativeElement, 'click').subscribe(() => {
      this.emitEvent(AnalyticsEventAction.Click);
    });
  }

  private registerFocusEvent(): void {
    fromEvent(this.element.nativeElement, 'focusin').subscribe(() => {
      this.emitEvent(AnalyticsEventAction.InputFocus);
    });
  }

  private registerBlurEvent(): void {
    fromEvent(this.element.nativeElement, 'focusout').subscribe(() => {
      this.emitEvent(AnalyticsEventAction.InputBlur);
    });
  }

  private emitEvent(eventAction: AnalyticsEventAction): void {
    this.analyticsService.sendEvent(
      {
        Action: eventAction,
        Category: this.category,
        Label: this.label,
        Value: this.value
      },
      this.metadata
    );
  }
}
