export class PromoFabState {
    hide: boolean;
    enabled: boolean;
    redirectURL: string;
    image1: string
    image2: string
    image3: string
  
    constructor(init: Partial<PromoFabState>) {
      Object.assign(this, init);
    }
}
