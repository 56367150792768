<div class="spinepay-completed-wrapper">
  <app-breadcrumb [title]="'Deposit' | transloco"> </app-breadcrumb>

  <div class="payment-error">
    <div class="info-wrapper">
      <div class="error-title">{{ 'An error has occured' | transloco }}</div>
      <p class="error-text">
      </p>
    </div>
  </div>
</div>