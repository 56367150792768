export class BonusModel {
  name: string;
  canPause: boolean;
  expirationDate: Date;
  id: number;
  isActivated: boolean;
  isCancelled: boolean;
  isExpired: boolean;
  isPaused: boolean;
  bonusCode: string;
  currentProgress: number;
  nextReleaseAmount: number;
  imageURL: string;
  content: string;
  contentTitle: string;
  summary: string;
  isBonusTransferPossible: boolean;

  constructor(init: Partial<BonusModel>) {
    Object.assign(this, init);
  }
}
