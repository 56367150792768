import { OddModel } from 'src/app/shared/models/coupon.model';
import { AreaModel, MarketModel, MatchModel, RegionModel, SelectionModel } from 'src/app/shared/models/sport.model';

export class TodaysEventsState {
  areaMatches: AreaMatchModel[];
  regionsWithAreas: RegionsWithAreasModel[];
  areas: AreaModel[];
  regions: RegionModel[];
  sports: SportModel[];
  tournamentNames: string[];
  matchesByTournament: MatchByTournamentModel[];
  matchesByTime: MatchModel[];
  selectedDay: DayModel;
  selectedSport: SportModel;
  selectedArea: AreaModel;
  selectedRegion: { region: RegionModel; areas: AreaModel[] };
  selectedMarket: AreaMarketModel;
  selectedTournamentName: string;
  sortType: SortType;
  isItMultiline: boolean;
  isItCorrectScores: boolean;
  ui: TodaysEventsUIState;

  constructor(init: Partial<TodaysEventsState>) {
    Object.assign(this, init);
  }
}

export enum SortType {
  ByTime,
  ByTournament,
  ByOddsLowestFirst,
  ByOddsHighestFirst
}

export class TodaysEventsUIState {
  isEventsLoading: boolean;
  firstTimeLoadIsInProgress: boolean;

  constructor(init: Partial<TodaysEventsUIState>) {
    Object.assign(this, init);
  }
}

export class RegionsWithAreasModel {
  areas: AreaModel[];
  regions: RegionModel[];

  constructor(init: Partial<RegionsWithAreasModel>) {
    Object.assign(this, init);
  }
}

export class MatchByTournamentModel {
  tournamentName: string;
  matches: MatchModel[];

  constructor(init: Partial<MatchByTournamentModel>) {
    Object.assign(this, init);
  }
}

export class SportModel {
  sportId: number;
  sportName: string;

  constructor(init: Partial<SportModel>) {
    Object.assign(this, init);
  }
}

export class AreaMarketModel {
  oddsType: OddsTypeModel;
  specialBetValue: number;
  specialCount: number;
  selections: SelectionModel[];
  groupNo: number;
  specialValueDisplay: string;

  constructor(init: Partial<AreaMarketModel>) {
    Object.assign(this, init);
  }

  toSportMarketModel(): MarketModel {
    return new MarketModel({
      description: this.oddsType.oddsDescription,
      id: this.oddsType.idGroup,
      marketType: this.oddsType.idGroupMarketType,
      name: this.oddsType.marketName,
      selections: this.selections,
      order: this.oddsType.oddsTypeOrder,
      spreadDisplayValue: this.specialValueDisplay,
      spreadValue: this.specialBetValue,
      typeId: this.oddsType.oddsTypeId
    });
  }
}

export class OddsTypeModel {
  oddsTypeId: number;
  marketName: string;
  oddsDescription: string;
  isOutright: boolean;
  isGoalScorer: boolean;
  idGroup?: any;
  idGroupMarketType: number;
  templateType: number;
  oddsTypeOrder: number;
  onlyRegularTime: boolean;
  multilineType: number;

  constructor(init: Partial<OddsTypeModel>) {
    Object.assign(this, init);
  }
}

export class OutcomeModel {
  outcomeId: number;
  oddOutcome: number;
  scheduleStatus: number;

  constructor(init: Partial<OutcomeModel>) {
    Object.assign(this, init);
  }
}

export class MatchOddModel {
  matchOddsId: number;
  smartBetCode: number;
  oddAttribute: SelectionModel;
  outcome: OutcomeModel;
  markup: number;
  pricePerShare: number;
  availableShares: number;

  constructor(init: Partial<MatchOddModel>) {
    Object.assign(this, init);
  }
}

export class OddsCollectionModel {
  oddCollectionId: number;
  oddsType: OddsTypeModel;
  scheduleStatus: number;
  specialBetValue: number;
  specialValueDisplay: string;
  combinability: number;
  matchOdds: OddModel[];
  selectionNoWinValues?: any;
  groupNo: number;
  marketCurrency?: any;
  compatibleMarkets: any[];

  constructor(init: Partial<OddsCollectionModel>) {
    Object.assign(this, init);
  }
}

export class AreaMatchModel {
  sportId: number;
  sportName: string;
  areaMarkets: AreaMarketModel[];
  groupingType: number;
  area: AreaModel;
  region: RegionModel;
  items: MatchModel[];
  containsOnlyOutright: boolean;
  areaMarketsType: number;

  constructor(init: Partial<AreaMatchModel>) {
    Object.assign(this, init);
  }
}

export class GetEventsAPIResponse {
  sports: SportModel[];
  areaMatches: AreaMatchModel[];
  areas: AreaModel[];
  regions: RegionModel[];
  totalNoOfItems: number;

  constructor(init: Partial<GetEventsAPIResponse>) {
    Object.assign(this, init);
  }
}

export class DayModel {
  id?: number;
  name: string;
  date?: string;
  timeFrame?: TimeFrame;

  constructor(init: Partial<DayModel>) {
    Object.assign(this, init);
  }
}

export enum TimeFrame {
  Today = 1,
  TodayTomorrow = 2,
  CurrentWeek = 3,
  All = 4,
  NextHour = 5,
  NextThreeHours = 6,
  NextThreeDays = 7,
  In2Days = 8,
  In3Days = 9,
  In4Days = 10,
  In5Days = 11,
  In6Days = 12,
  In7Days = 13
}

export interface MarketGroupOffer {
  regionId: number;
  areaId: number;
}

export interface MarketGroup {
  id: number;
  name: string;
  marketIds: number[];
  offerPage: boolean | MarketGroupOffer;
}
export class BettingShortcut {
  id: number;
  name: string;
  order: number;
  url: string;
  availableUntil?: string;
  visible: boolean;
  icon: {
    url: string;
    width: number;
    height: number;
  };

  constructor(init: Partial<BettingShortcut>) {
    Object.assign(this, init);
  }
}

export class BettingShortcutGroup {
  id: number;
  name?: string;
  availableUntil?: string;
  order: number;
  betting_shortcuts: BettingShortcut[];

  constructor(init: Partial<BettingShortcut>) {
    Object.assign(this, init);
  }
}
