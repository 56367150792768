/* tslint:disable */
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin, pipe } from 'rxjs';
import { takeUntil, filter, map, startWith, take } from 'rxjs/operators';
import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { HelpService } from 'src/app/core/services/help.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { fadeIn } from 'src/app/shared/animations';
import {
  AccountStatementModel,
  ExpandedAccountStatementModel,
  PaymentListModel,
  PendingWithdrawalsModel
} from 'src/app/shared/models/account.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { AccountStatementService } from 'src/app/core/services/account/account-statement.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { PayoutPromoService } from 'src/app/core/services/payout-promo/payout-promo.service';
import { BannerRotatorQuery } from 'src/app/core/state/banner-rotator/banner-rotator.query';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsListComponent implements OnInit, OnDestroy {
  isDeposit: boolean;
  paymentTitle: string;
  paymentButton: string;
  paymentIcon: string;
  bottomDisclamer: string;
  showPendingWithdrawal: boolean = false;
  enablePayments$ = new BehaviorSubject<boolean>(false);
  cmsData$: Observable<PaymentListModel[]> = undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isSessionOneComplete$ = new BehaviorSubject<boolean>(false);
  activeGroupingTab: string = 'paymentTab';
  hideContent: boolean = true;
  completedTransactions$: Observable<AccountStatementModel[]>;
  bonusUiBaseUrl = this.appConfig.get('bombBonusUrl');
  KYCDocumentStatus: string;
  shouldBannerBeVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  withdrawalBannerAvailable: boolean;

  constructor(
    private currencyFormat: CurrencyFormatPipe,
    readonly accountPaymentQuery: AccountPaymentQuery,
    readonly accountService: AccountService,
    readonly accountQuery: AccountQuery,
    private readonly accountStatementsService: AccountStatementService,
    readonly applicationService: ApplicationService,
    private readonly helpService: HelpService,
    private readonly registrationService: RegistrationService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly accountPaymentService: AccountPaymentService,
    private readonly notificationService: NotificationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer,
    private readonly appConfig: AppConfigService,
    private readonly payoutPromoService: PayoutPromoService,
    private readonly bannerRotatorQuery: BannerRotatorQuery
  ) {}

  ngOnInit(): void {
    this.withdrawalBannersAvailable$
      .pipe(
        filter(available => available),
        takeUntil(this.destroy$)
      )
      .subscribe(() => (this.withdrawalBannerAvailable = true));

    this.accountStatementsService.getTransactionTypes().subscribe(() => {
      this.accountStatementsService.getAccountStatements(true).subscribe();
    });
    this.getPendingWithdrawals();
    this.getQualifiedWithdrawals();
    this.loadPayments();
    this.observeSessionOne();
    this.accountService.getUserProfile(this.accountQuery.accessToken).subscribe(res => {
      this.checkSessionOne(res);
    });

    const defaultTab = 'paymentTab'; // change this to the name of your default tab
    this.showTab(this.activatedRoute.snapshot.queryParamMap.get('activeTab') || defaultTab);
  }

  async sharePayoutPromo(statement: ExpandedAccountStatementModel): Promise<void> {
    const shareResult = await this.payoutPromoService.getShareData(statement.id);
    const url = `${window.location.origin}/account/withdrawal/promotion/shared/${statement.id}?sh=${shareResult.hash}`;
    const anyWindow = window as any;
    const sharePayload = {
      url: url,
      title: 'Keš na vreme'
    };
    if (anyWindow?.navigator?.canShare?.(sharePayload)) {
      window.navigator
        .share(sharePayload)
        .then(() => {
          // this.notificationService.showSuccess(translate('account.share.success'));
          console.log('share success');
        })
        .catch(() => {
          console.log('share error');
          // this.notificationService.showError(translate('account.share.error'));
        });
    } else {
      window.navigator.clipboard.writeText(`Keš na vreme, na Victoryju! ${url}`).then(() => {
        this.notificationService.showSuccessMessage('Link ka tvojoj isplati je kopiran. Podeli sa ekipom!', 3000);
      });
    }
  }

  showTab(tab: string) {
    if (tab === 'requestsTab') {
      this.shouldBannerBeVisible$.next(false);
    } else {
      this.shouldBannerBeVisible$.next(true);
    }
    this.activeGroupingTab = tab;
    const queryParams = { activeTab: tab };
    this.router.navigate([], { queryParams: queryParams });
  }
  getIframeUrl(withdrawal: PendingWithdrawalsModel) {
    const date = new Date(withdrawal.transectionDate).toISOString();
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.bonusUiBaseUrl}payout-promo/watch-preview?date=${date}`);
  }
  showRequestsTab() {
    this.showTab('requestsTab');
    this.getPendingWithdrawals();
  }
  showPromoPayoutDetails(statement: ExpandedAccountStatementModel) {
    this.applicationService.showPromoPayoutModal$.next({
      open: true,
      promoDetails: {
        cashAward: statement.cashAward,
        closedDate: statement.closedDate,
        qualified: statement.qualified,
        spinsAward: statement.spinsAward,
        transactionDate: statement.transactionDate,
        transactionId: statement.id
      }
    });
  }
  goToPendingPromoPayoutDetails(withdrawal: PendingWithdrawalsModel) {
    this.router.navigate(['/account/withdrawal/promotion', withdrawal.id]);
  }

  get withdrawalBannersAvailable$(): Observable<boolean> {
    return this.bannerRotatorQuery.withdrawalBannerLength$.pipe(map(length => length > 0));
  }
  isPayoutQualified(reWithdrawal: any): boolean {
    if (!reWithdrawal || !reWithdrawal.furtherDetails) {
      return false;
    }
    return JSON.parse(reWithdrawal.furtherDetails).PayoutPromoQualified;
  }

  checkBlinkingDetails(KYCDocumentsStatus: string): void {
    if (KYCDocumentsStatus !== 'verified') {
      if (this.isDeposit && KYCDocumentsStatus === 'accountNumberNeeded') {
        // Deposit Page
        this.enablePayments$.next(true);
      } else {
        this.enablePayments$.next(false);
      }
    } else {
      this.enablePayments$.next(true);
    }
  }

  // tslint:disable-next-line: cyclomatic-complexity
  startBlinkingSession(): void {
    this.registrationService.getRegisterDetails().subscribe(() => {
      this.registrationService.getRegisterDetails().subscribe(() => {
        if ((!this.accountQuery.hasValidId || !this.accountQuery.userData.mobile) && !this.accountQuery.skipSessionOne) {
          this.router.navigate(['/strenghten-profile']);
        } else if (
          this.accountQuery.hasValidId &&
          (!this.accountQuery.emailVerified || !this.accountQuery.hasValidMobilePhone) &&
          !this.accountQuery.skipSessionOne
        ) {
          this.router.navigate(['/register']);
        } else if (this.isSessionOneComplete$.value) {
          this.router.navigate(['/account/verification-choice']);
        }
        // from edit profile page, the button will be shown only for unverified users and the user will always open '1: Main Session'
      });
    });
  }
  checkSessionOne(data: any) {
    const emailVerified: boolean = !!data?.ExtraDetails.find(item => item.PropertyName === 'EmailVerifiedDate');
    const skipSessionOne: boolean = !!data?.ExtraDetails.find(item => item.PropertyName === 'SkipSessionOneCheck');
    const mobileVerified: boolean =
      data?.PhoneContactDetails?.length && data?.PhoneContactDetails[0].PhoneContactVerificationStatus === 'VER';
    const citizenIdNumber: any = data && !!data?.ExtraDetails?.find((item: any) => item.PropertyName === 'Blinking.CitizenId');
    this.isSessionOneComplete$.next(skipSessionOne || (emailVerified && mobileVerified && citizenIdNumber));
  }

  observeSessionOne() {
    this.accountQuery.canDeposit$.subscribe(res => {
      this.isSessionOneComplete$.next(!!res);
    });
  }

  loadPayments(): void {
    this.activatedRoute.data.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.populateView(data.paymentMethods);

      this.accountService.getKYCStatus(this.accountQuery.accessToken).subscribe(() => {
        let KYCDocumentStatus = this.accountService.getKYCDocumentsStatus();
        if (KYCDocumentStatus === 'documentsNeeded' || KYCDocumentStatus === 'accountNumberNeeded') {
          this.accountService.getBlinkingSessionStatus(this.accountQuery.accessToken).subscribe(() => {
            // check status again if Session Status is needed
            KYCDocumentStatus = this.accountService.getKYCDocumentsStatus();
            this.checkBlinkingDetails(KYCDocumentStatus);
            this.loadpaymentCalls(data);
            this.KYCDocumentStatus = KYCDocumentStatus;
          });
        } else {
          this.checkBlinkingDetails(KYCDocumentStatus);
          this.loadpaymentCalls(data);
          this.KYCDocumentStatus = KYCDocumentStatus;
        }
      });
    });
  }

  loadpaymentCalls(data: any): void {
    if (data.paymentMethods === 'deposit') {
      if (!this.accountPaymentQuery.hasDepositPaymentsLists) {
        this.accountPaymentService.getDepositPaymentList(this.enablePayments$.value).pipe(takeUntil(this.destroy$)).subscribe();
      }
    } else {
      if (!this.accountPaymentQuery.hasWithdrawalsPaymentsLists) {
        this.accountPaymentService.getWithdrawalPaymentList(this.enablePayments$.value).pipe(takeUntil(this.destroy$)).subscribe();
      }
    }
  }

  populateView(paymentType: string): void {
    this.isDeposit = paymentType === 'deposit' ? true : false;
    this.paymentTitle = paymentType === 'deposit' ? translate('Deposit') : translate('Withdrawal');
    this.paymentButton = paymentType === 'deposit' ? translate('Deposit Now') : translate('Withdraw Now');
    this.paymentIcon = paymentType === 'deposit' ? 'fa fa-money' : 'fa fa-minus-circle';
    this.cmsData$ =
      paymentType === 'deposit' ? this.accountPaymentQuery.paymentsListDeposit$ : this.accountPaymentQuery.paymentsListWithdrawal$;
  }

  getPendingWithdrawals(): void {
    this.accountPaymentService
      .getPendingWithdrawals()
      .pipe(takeUntil(this.destroy$))
      .subscribe(error => {
        if (error) {
          this.notificationService.showErrorNotification(
            translate('Something went wrong! Please try again or contact support.'),
            error.statusText
          );
        }
      });
  }

  getQualifiedWithdrawals(): void {
    this.accountPaymentService
      .getQualifiedWithdrawals()
      .pipe(takeUntil(this.destroy$))
      .subscribe(error => {
        if (error) {
          this.notificationService.showErrorNotification(
            translate('Something went wrong! Please try again or contact support.'),
            error.statusText
          );
        }
      });
  }

  cancelWithdrawal(id: number, transactionTypeId: number, amount: number): void {
    this.accountPaymentService
      .cancelPendingWithdrawal(id, transactionTypeId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          this.loadPayments();
          this.getPendingWithdrawals();
          const formatedAmount = this.currencyFormat.transform(amount, '');
          this.notificationService.showSuccesReWithdrawalNotification(
            translate("Your withdrawal request of <span class='amount'> {{formattedAmount}}</span><br> has been successfully cancelled.", {
              formatedAmount: formatedAmount
            }),
            translate('Successful!')
          );
        },
        error => {
          const formatedAmount = this.currencyFormat.transform(amount, '');
          this.notificationService.showErrorReWithdrawalNotification(
            translate("Your withdrawal request of <span class='amount'>{{formattedAmount}}</span><br> was not successfully canceled.", {
              formatedAmount: formatedAmount
            }),
            translate('Error!')
          );
        }
      );
  }

  initiateWithdrawalCancelation(reWithdrawal: any) {
    const amount = reWithdrawal.amount * -1;
    this.notificationService.showWithdrawalQuestionNotification(translate('Are you sure you want to<br> cancel the payment?'), () => {
      this.cancelWithdrawal(reWithdrawal.id, reWithdrawal.transactionTypeId, amount);
    });
  }

  redirect(Identifier: string): void {
    if (Identifier) {
      if (this.isDeposit) {
        const pathD = `account/deposit/${Identifier}`;
        this.router.navigate([pathD]);
      } else if (!this.isDeposit) {
        const pathW = `account/withdrawal/${Identifier}`;
        this.router.navigate([pathW]);
      }
    }
  }

  pendingWithdrawalsTrackBy(index: number, pendingWithdrawal: PendingWithdrawalsModel): number {
    return pendingWithdrawal.id;
  }

  paymentListTrackBy(index: number, payment: PaymentListModel): string {
    return payment.identifier;
  }

  statementApproved(statement: AccountStatementModel): boolean {
    return ['COMPLETE', 'ONHOLD', 'VALIDATED'].includes(statement.transactionStatusCode);
  }
  statementDenied(statement: AccountStatementModel): boolean {
    return ['REJECTED', 'CANCELLED'].includes(statement.transactionStatusCode);
  }

  get payoutTransactionsWithPromoDetails$(): Observable<ExpandedAccountStatementModel[]> {
    return combineLatest([this.accountQuery.approvedAndRejectedTransactions$, this.accountPaymentQuery.qualifiedWithdrawals$]).pipe(
      map(([transactions, qualifiedWithdrawals]) =>
        transactions.map(transaction => {
          const matchingWithdrawal = qualifiedWithdrawals?.find(withdrawal => withdrawal.platformId === transaction.id);
          if (matchingWithdrawal === undefined) {
            return { ...transaction, qualified: false, closedDate: null, cashAward: null, spinsAward: null };
          }
          return {
            ...transaction,
            qualified: true,
            closedDate: new Date(matchingWithdrawal.closedDate),
            cashAward: matchingWithdrawal.cashAward,
            spinsAward: matchingWithdrawal.spinsAward
          };
        })
      )
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
