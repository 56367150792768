<div class="spread-value" *ngIf="spreadValue">
  <span>{{ spreadValue }}</span>
</div>
<app-odd
  *ngFor="let odd of parsedOdds; let first = first; let last = last; trackBy: trackById"
  [odd]="odd"
  [multiple]="odds.length > 0"
  [first]="first"
  [last]="last"
  [specials]="specials"
>
</app-odd>
