import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Subject } from 'rxjs';

import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { expandCollapse, fadeInOutBy } from 'src/app/shared/animations';
import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-correct-score-event-with-selector',
  templateUrl: './correct-score-event-with-selector.component.html',
  styleUrls: ['./correct-score-event-with-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse(), fadeInOutBy()]
})
export class CorrectScoreEventWithSelectorComponent {
  @Input() event: MatchModel;
  @Input() showDetails: boolean = true;
  @Input() isAvailable: boolean = true;
  @Output() readonly toggleSelector = new EventEmitter();
  destroy$ = new Subject<boolean>();

  constructor(
    readonly statisticsQuery: StatisticsQuery,
    readonly statisticsService: StatisticsService,
    private readonly matchService: MatchService
  ) {}

  toggleCheckbox(): void {
    this.toggleSelector.emit();
  }

  marketTrackBy(index: number, market: MarketModel): number {
    return market.id;
  }

  oddTrackBy(index: number, odd: MatchModel): number {
    return odd.id;
  }

  navigateToMatch(): void {
    this.matchService.navigateToMatch(this.event.id, this.event.name);
  }

  openStatistics(): void {
    this.statisticsService.statisticsBetRadar(this.event.externalId);
  }
}
