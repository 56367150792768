import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, finalize, first, map, mergeMap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BonusModel } from 'src/app/shared/models/bonus.model';
import { Promotion } from 'src/app/shared/models/cms.model';

import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BonusService {
  constructor(
    private readonly apiService: APIService,
    private readonly accountStore: AccountStore,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
  ) { }

  getUserBonuses(): Observable<any> {
    this.accountStore.setLoading(true);

    if (!this.accountQuery.hasBonusInfo) {
      this.getBonuses().subscribe();
    }

    return this.userProgressData();
  }

  getBonuses(): Observable<any> {
    return this.apiService.get(APIType.CMS, 'promotions').pipe(
      first(),
      map(res => {
        this.accountStore.updateBonusInfo(
          res.filter(bonus => bonus.Promotion.enabled).map(bonusInfo => this.mapBonusInfoToModel(bonusInfo.Promotion))
        );
      })
    );
  }

  getBonus(id: number): void {
    this.accountStore.updateViewingBonus(this.accountQuery.getBonus(id));
  }

  clearViewingBonus(): void {
    this.accountStore.updateViewingBonus(undefined);
  }

  activateBonus(id: number): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    this.accountStore.setLoading(true);

    return this.apiService.post(APIType.Platform, 'api/Bonus/Claims/Activate', { ClaimId: id }, apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.setLoading(false);
        this.accountService.updateBalance();
      }),
      map(responseData => {
        if (responseData.ResponseCode !== 0) {
          this.accountStore.setError('Bonus Activation Failed');
        }
      }),
      catchError(err => {
        this.accountStore.setError(err);
        return throwError(err);
      })
    );
  }

  pauseBonus(id: number): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    this.accountStore.setLoading(true);

    return this.apiService.post(APIType.Platform, 'api/Bonus/Claims/Pause', { ClaimId: id }, apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.setLoading(false);
        this.accountService.updateBalance();
      }),
      map(responseData => {
        if (responseData.ResponseCode !== 0) {
          this.accountStore.setError('Pausing of Bonus Failed');
        }
      }),
      catchError(err => {
        this.accountStore.setError(err);
        return throwError(err);
      })
    );
  }

  resumeBonus(id: number): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    this.accountStore.setLoading(true);

    return this.apiService.post(APIType.Platform, 'api/Bonus/Claims/UnPause', { ClaimId: id }, apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.setLoading(false);
        this.accountService.updateBalance();
      }),
      map(responseData => {
        if (responseData.ResponseCode !== 0) {
          this.accountStore.setError('Failed to Resume Bonus');
        }
      }),
      catchError(err => {
        this.accountStore.setError(err);
        return throwError(err);
      })
    );
  }

  clearError(): void {
    this.accountStore.setError(undefined);
  }

  updateViewingActive(isViewingActive: boolean): void {
    this.accountStore.updateBonusUI({ isViewingActive });
  }

  updateViewingPaused(isViewingPaused: boolean): void {
    this.accountStore.updateBonusUI({ isViewingPaused });
  }

  updateViewingInactive(isViewingInactive: boolean): void {
    this.accountStore.updateBonusUI({ isViewingInactive });
  }

  updateViewingPrevious(isViewingPrevious: boolean): void {
    this.accountStore.updateBonusUI({ isViewingPrevious });
  }

  updateViewingMissed(isViewingMissed: boolean): void {
    this.accountStore.updateBonusUI({ isViewingMissed });
  }

  updateViewingBomb(isViewingBomb: boolean): void {
    this.accountStore.updateBonusUI({ isViewingBomb });
  }

  resetBonusUI(): void {
    this.accountStore.updateBonusUI({
      isViewingActive: false,
      isViewingPaused: false,
      isViewingInactive: false,
      isViewingPrevious: false,
      isViewingMissed: false,
      isViewingBomb: false
    });
  }

  clearUserBonuses(): void {
    this.accountStore.updateBonuses(undefined);
  }

  private userProgressData(): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    return this.apiService.get(APIType.Platform, 'api/Bonus/Claims/', apiSettings).pipe(
      first(),
      finalize(() => {
        this.accountStore.setLoading(false);
      }),
      mergeMap(responseBonusData => {
        if (!(responseBonusData === undefined || responseBonusData.Result === undefined)) {
          if (responseBonusData.Result.length > 0) {
            const apiCalls: any[] = [];
            const bonuses: BonusModel[] = [];

            responseBonusData.Result.forEach(bonus => {
              bonuses.push(this.mapBonusDataToModel(bonus));

              apiCalls.push(this.apiService.get(APIType.Platform, `api/Bonus/Claims/${bonus.Id}/Progress`, apiSettings));
            });

            return forkJoin(apiCalls).pipe(
              first(),
              map(responseProgressData => {
                responseProgressData.forEach((progressData: any) => {
                  if (!(progressData === undefined || progressData.Result === undefined)) {
                    this.mapProgressDataToModel(
                      bonuses.find(bonus => bonus.id === progressData.Result.BonusClaimId),
                      progressData.Result
                    );
                  }
                });
                this.accountStore.updateBonuses(bonuses);
              })
            );
          }
        }

        return new Observable<any>();
      }),
      catchError(err => {
        this.accountStore.setError(err);
        return throwError(err);
      })
    );
  }

  private mapBonusDataToModel(bonus: any): BonusModel {
    return new BonusModel({
      canPause: bonus.CanPauseClaim,
      expirationDate: bonus.ExpiresOn,
      id: bonus.Id,
      isActivated: bonus.IsActivated,
      isCancelled: bonus.IsCancelled,
      isExpired: bonus.IsExpired,
      isPaused: bonus.IsPaused,
      bonusCode: bonus.TermsCode
    });
  }

  private mapBonusInfoToModel(promoInfo: Promotion): BonusModel {
    return new BonusModel({
      bonusCode: promoInfo.code,
      name: promoInfo.title,
      imageURL: promoInfo.image.url,
      summary: promoInfo.summary,
      contentTitle: promoInfo.content_title,
      content: promoInfo.content
    });
  }

  private mapProgressDataToModel(bonus: BonusModel, progress: any): void {
    bonus.currentProgress = progress.CurrentContributionAmount;
    bonus.nextReleaseAmount = progress.NextReleaseContributionAmount;
    bonus.isBonusTransferPossible = progress.IsBonusTransferPossible;
  }
}
