<div class="contact-support-container">
  <div class="title" transloco="Need help?"></div>
  <div class="support-links">
    <div *ngFor="let link of supportLinks; trackBy: indexTrackBy">
      <a *ngIf="!link.hasCallback" [href]="link.href" analytics [click]='true' [category]="'Support Link'"
        [label]='link.href'>
        <i *ngIf="link.FAIcon" class="fa fa-{{link.FAIcon}}"></i>
        <div *ngIf="link.hasSVGIcon" [appDynamicSvgBackground]="'icons/' + link.name"></div>
      </a>
    </div>
  </div>
</div>