import { Observable } from 'rxjs';

export function createEventSourceObservable<T>(url: string, parseJson = true): Observable<T> {
  let eventSource: EventSource;
  let subscriberCount = 0;

  return new Observable(observer => {
    if (!eventSource) {
      eventSource = new EventSource(url);
    }

    const messageHandler = (event: MessageEvent) => {
      if (parseJson) {
        observer.next(JSON.parse(event.data));
      } else {
        observer.next(event.data);
      }
    };

    eventSource.addEventListener('message', messageHandler);

    subscriberCount++;

    return () => {
      subscriberCount--;

      if (subscriberCount === 0) {
        eventSource.removeEventListener('message', messageHandler);
        eventSource.close();
        eventSource = null;
      }
    };
  });
}
