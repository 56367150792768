<div class="promos-container">
  <div class="promos-card-container" body>
    <div class="promos-available">
      <app-choose-your-promo-section></app-choose-your-promo-section>

      <ng-container *ngFor="let bonus of visibleBonuses$ | async; trackBy: bonusTrackBy">
        <app-card [titleHeaderText]="bonus.name" id="{{ bonus.bonusCode }}">
          <div body>
            <div class="image">
              <img class="promo-image" [attr.src]="accountQuery.bonusInfoByCode(bonus.bonusCode).imageURL" alt="Promo" />
            </div>
            <div class="bonus-summary" [@expandCollapse]="viewBonusCode !== bonus.bonusCode ? 'expanded' : 'collapsed'">
              {{ bonus.summary }}
            </div>
            <div
              [@expandCollapse]="viewBonusCode === bonus.bonusCode ? 'expanded' : 'collapsed'"
              [innerHTML]="bonus.content"
              class="bonus-content"
            ></div>
            <div class="buttons">
              <app-button
                (btnClick)="onMoreInfoClicked(bonus)"
                *ngIf="viewBonusCode !== bonus.bonusCode"
                [allCaps]="false"
                [category]="'Menu - Promos - Promo More Info Button'"
                [click]="true"
                [label]="bonus.name"
                [text]="'More Info' | transloco"
                analytics
              >
              </app-button>
              <app-button
                (btnClick)="onLessInfoClicked()"
                *ngIf="viewBonusCode === bonus.bonusCode"
                [allCaps]="false"
                [category]="'Menu - Promos - Promo Less Info Button'"
                [click]="true"
                [label]="bonus.name"
                [text]="'Less Info' | transloco"
                analytics
              ></app-button>
            </div>
          </div>
        </app-card>
      </ng-container>
    </div>

    <div
      *ngIf="(accountQuery.hasBonusInfo$ | async) === false"
      class="promos-unavailable"
      transloco="There are currently no promotions available. Please check back soon later."
    ></div>
  </div>
</div>
