import { Observable } from 'rxjs';
import { MenuItemModel as CMSMenuItem } from 'src/app/shared/models/cms.model';

export class MenuState {
  selectedTab: MenuTab;
  quickLinks: QuickLinkModel[];
  ui: MenuUIState;

  constructor(init: Partial<MenuState>) {
    Object.assign(this, init);
  }
}

export enum MenuTab {
  SportsAZ,
  Promos,
  Virtuals,
  QuickLinks,
  HowToPlay
}

export class MenuUIState {
  isSportsLoading: boolean;

  constructor(init: Partial<MenuUIState>) {
    Object.assign(this, init);
  }
}

export class MenuItemsResponseModel {
  site: {
    landing_page_menu_items: CMSMenuItem[];
  };
}
export class MenuQuicklinksResponseModel {
  site: {
    landing_page_menu_quicklinks: CMSMenuItem[];
  };
}
export class MenuItemModel {
  title: string;
  icon: string;
  iconSuffixText: string;
  isFontAwesomeIcon: boolean;
  linkURL?: string;
  functionName?: string;
  newTab: boolean;
  showWhenLoggedIn: boolean;
  showWhenLoggedOut: boolean;
  isNew: boolean;
  isLocked: boolean = false;
  showWarningIcon: boolean = false;
  type?: number;

  constructor(init: Partial<MenuItemModel>) {
    init.showWhenLoggedIn = init.showWhenLoggedIn === undefined ? true : init.showWhenLoggedIn;
    init.showWhenLoggedOut = init.showWhenLoggedOut === undefined ? true : init.showWhenLoggedOut;
    Object.assign(this, init);
  }
}

export class MenuTabModel {
  title: string;
  type: MenuTab;
  isNew?: boolean;
}

export class QuickLinkModel {
  title: string;
  linkURL: string;

  constructor(init: Partial<QuickLinkModel>) {
    Object.assign(this, init);
  }
}

export class NavBarItemModel {
  id: number;
  icon: string;
  action: string;
  type: NavBarItemActionType;
  title: string;
  badge$?: Observable<number | string>;
  isSelected$?: Observable<boolean>;
  showLoggedIn: boolean;
  showLoggedOut: boolean;
}

export enum NavBarItemActionType {
  Navigation,
  FunctionCall
}
