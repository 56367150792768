import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';

import { addDays, format, isAfter, isBefore, subDays, subMonths } from 'date-fns';
import { AccountStatementService } from 'src/app/core/services/account/account-statement.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { expandCollapse, fadeInOut } from 'src/app/shared/animations';
import { AccountStatementTransferTypeModel } from 'src/app/shared/models/account.model';

@Component({
  selector: 'account-statement-search',
  templateUrl: './account-statement-search.component.html',
  styleUrls: ['./account-statement-search.component.scss'],
  animations: [expandCollapse(), fadeInOut()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountStatementSearchComponent implements OnInit, OnDestroy {
  accountStatementSearchForm: FormGroup;
  dateFromMaxLimit = this.accountStatementService.defaultFilter.dateTo;
  dateFromMinLimit = format(addDays(subMonths(new Date(), 30), 1), 'yyyy-MM-dd');
  dateToLimit = this.accountStatementService.defaultFilter.dateTo;
  showDateFilters = true;

  destroy$ = new Subject<boolean>();

  constructor(public accountQuery: AccountQuery, private readonly accountStatementService: AccountStatementService) {}

  ngOnInit(): void {
    this.accountStatementSearchForm = this.initialiseForm();
    this.accountStatementSearchForm.controls.period.valueChanges.subscribe(sub => {
      this.showDateFilters = sub === '0';
    });

    this.accountStatementService.getTransactionTypes().subscribe();
    this.accountStatementSearchForm.valueChanges.subscribe(sub => {
      if (sub.dateFrom === '') {
        this.accountStatementSearchForm.controls.dateFrom.setValue(
          isAfter(new Date(sub.dateTo), new Date(this.accountStatementService.defaultFilter.dateFrom))
            ? this.accountStatementService.defaultFilter.dateFrom
            : format(subDays(new Date(sub.dateTo), 1), 'yyyy-MM-dd')
        );
      } else if (sub.dateTo === '') {
        this.accountStatementSearchForm.controls.dateTo.setValue(
          isBefore(new Date(sub.dateFrom), new Date(this.accountStatementService.defaultFilter.dateTo))
            ? this.accountStatementService.defaultFilter.dateTo
            : format(addDays(new Date(sub.dateFrom), 1), 'yyyy-MM-dd')
        );
      } else {
        this.dateFromMaxLimit = sub.dateTo;
        this.accountStatementService.updateAccountStatementFilter(sub);
      }
    });
  }

  toggleRadioButton(event: any): void {
    const radio = event.target.parentElement.querySelector('input[type="radio"]');
    radio.checked = radio.checked ? false : true;
    this.accountStatementSearchForm.controls[radio.getAttribute('formcontrolname')].setValue(radio.value);

    // Change date reange depending on period selected
    switch (radio.value) {
      case '0':
        // Default in case of selecting manual
        this.accountStatementSearchForm.controls.dateFrom.setValue(this.accountStatementService.defaultFilter.dateFrom);
        this.accountStatementSearchForm.controls.dateTo.setValue(this.accountStatementService.defaultFilter.dateTo);
        break;
      case '1':
        this.accountStatementSearchForm.controls.dateFrom.setValue(format(new Date(), 'yyyy-MM-dd'));
        this.accountStatementSearchForm.controls.dateTo.setValue(format(new Date(), 'yyyy-MM-dd'));
        break;
      case '2':
        this.accountStatementSearchForm.controls.dateFrom.setValue(format(subDays(new Date(), 2), 'yyyy-MM-dd'));
        this.accountStatementSearchForm.controls.dateTo.setValue(format(new Date(), 'yyyy-MM-dd'));
        break;
      default:
        break;
    }

    this.accountStatementSearchForm.controls[radio.getAttribute('formcontrolname')].markAsDirty();
  }

  onReset(): void {
    this.accountStatementSearchForm.reset({
      transferType: this.accountStatementService.defaultFilter.transferType,
      period: this.accountStatementService.defaultFilter.period,
      dateFrom: this.accountStatementService.defaultFilter.dateFrom,
      dateTo: this.accountStatementService.defaultFilter.dateTo
    });
  }

  onSubmit(): void {
    this.accountStatementService.getAccountStatements().subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  transferTypeTrackBy(item: AccountStatementTransferTypeModel): number {
    return item.id;
  }

  private initialiseForm(): FormGroup {
    return new FormGroup({
      transferType: new FormControl(this.accountStatementService.defaultFilter.transferType.toString()),
      period: new FormControl(this.accountStatementService.defaultFilter.period.toString()),
      dateFrom: new FormControl(this.accountStatementService.defaultFilter.dateFrom),
      dateTo: new FormControl(this.accountStatementService.defaultFilter.dateTo)
    });
  }
}
