<div class="unverified-account-tooltip"
  *ngIf="(accountQuery.isAuthenticated$ | async) && (accountQuery.showUnverifiedTooltip$ | async) && verifyData && verifyData.type === verifyAccountType.VerifyInProgress">
  <div>
    <i class="fa fa-warning" aria-hidden="true"></i>
    <div>
      {{'Please verify your account within' | transloco}}
      <ng-container *ngIf="verifyData.daysLeft !== 0"><span>{{ verifyData.daysLeft }}</span> {{'days' | transloco}}</ng-container>
      <ng-container *ngIf="verifyData.daysLeft !== 0 && verifyData.hoursLeft !== 0"> {{'and' | transloco}} </ng-container>
      <ng-container *ngIf="verifyData.hoursLeft !== 0"><span>{{ verifyData.hoursLeft }}</span> {{'hours' | transloco}}</ng-container>
      {{'so it does not become inactive.' | transloco}}
    </div>
  </div>

  <button class="btn" (click)="verifyExistingPhoneNumber()">
    <span class="btn-text" transloco="Verify Existing Phone Number"></span>
  </button>
</div>
