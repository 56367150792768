import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { PromoFabState } from 'src/app/shared/models/promo-fab.model';

import { APIType } from '../../shared/models/api.model';
import { PromoFAB } from '../../shared/models/cms.model';
import { PromoFabStore } from '../state/promo-fab/promo-fab.store';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class PromoFabService {
  private readonly hideStorageKey = 'hidePromoFab';

  constructor(
    private store: PromoFabStore,
    private localStorage: LocalStorageService,
    private apiService: APIService,
  ) {}

  initialize(): void {
    this.isHiddenByUser ? this.hide() : this.fetchPromoFabProps();
  }

  dismiss(): void {
    this.hide();
    this.localStorage.store(this.hideStorageKey, true);
  }

  private fetchPromoFabProps(): void {
    this.apiService.get(APIType.CMS, 'promo-fab').subscribe(res => {
      this.handlePromoFabProps(res.Promo_FAB);
    });
  }

  private handlePromoFabProps(props: PromoFAB): void {
    const promoFabState = new PromoFabState({
      enabled: props.enabled,
      redirectURL: props.url,
      image1: props.image1.url,
      image2: props.image2.url,
      image3: props.image3.url
    });
    this.store.updateUI(promoFabState);
  }

  private hide(): void {
    this.store.updateUI({ hide: true });
  }

  get isHiddenByUser(): boolean {
    return !!this.localStorage.retrieve(this.hideStorageKey);
  }
}
