import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { BonusService } from 'src/app/core/services/bonus.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { BonusModel } from 'src/app/shared/models/bonus.model';

@Component({
  selector: 'account-bonus-view',
  templateUrl: './bonus-view.component.html',
  styleUrls: ['./bonus-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusViewComponent implements OnInit {
  status: 'active' | 'paused' | 'missed' | 'inactive' | 'previous' | undefined;
  bonusInfo: BonusModel;

  constructor(private readonly bonusService: BonusService, readonly accountQuery: AccountQuery) {
    accountQuery.viewingBonus$.pipe(first()).subscribe(sub => {
      if (sub.isActivated && !(sub.isExpired || sub.isCancelled || sub.isPaused)) {
        this.status = 'active';
      }
      if (sub.isActivated && !(sub.isExpired || sub.isCancelled) && sub.isPaused) {
        this.status = 'paused';
      }
      if (sub.isActivated && sub.isExpired && !sub.isCancelled) {
        this.status = 'previous';
      }
      if (!(sub.isActivated || sub.isExpired || sub.isCancelled)) {
        this.status = 'inactive';
      }
      if (sub.isCancelled || (!sub.isActivated && sub.isExpired)) {
        this.status = 'missed';
      }
    });
  }

  ngOnInit(): void {
    this.accountQuery.viewingBonus$.pipe(first()).subscribe(vb => {
      this.bonusInfo = this.accountQuery.bonusInfoByCode(vb.bonusCode);
    });
  }

  onActivateClicked(id: number): void {
    this.bonusService.activateBonus(id).subscribe(() => {
      this.actionButtonClicked(() => {
        setTimeout(() => {
          this.bonusService.updateViewingActive(true);
        });
      });
    });
  }

  onResumeClicked(id: number): void {
    this.bonusService.resumeBonus(id).subscribe(() => {
      this.actionButtonClicked(() => {
        setTimeout(() => {
          this.bonusService.updateViewingActive(true);
        });
      });
    });
  }

  onPauseClicked(id: number): void {
    this.bonusService.pauseBonus(id).subscribe(() => {
      this.actionButtonClicked(() => {
        setTimeout(() => {
          this.bonusService.updateViewingPaused(true);
        });
      });
    });
  }

  private actionButtonClicked(callback: Function): void {
    this.bonusService.resetBonusUI();
    this.bonusService.getUserBonuses().subscribe(() => {
      this.bonusService.clearViewingBonus();
      callback();
    });
  }
}
