import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';
import { MyBetsService } from 'src/app/core/services/my-bets.service';
import { MyBetsQuery } from 'src/app/core/state/my-bets/my-bets.query';
import { EvaluationModel, EvaluationState, EvaluationStatus } from 'src/app/shared/models/evaluation.model';

function createInitialState(): EvaluationState {
  return {
    evaluationList: [],
    awaitingReplyList: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'evaluation' })
export class EvaluationStore extends Store<EvaluationState> {
  readonly evaluationListKey: string = 'evaluationList';

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly myBetsQuery: MyBetsQuery,
    private readonly myBetsService: MyBetsService
  ) {
    super(createInitialState());

    this.localStorage.observe(this.evaluationListKey).subscribe(evaluationList => {
      this.update({ evaluationList });
    });
    this.updateEvaluationList(this.localStorage.retrieve(this.evaluationListKey));
  }

  updateEvaluationList(evaluationList: EvaluationModel[]): void {
    if (!evaluationList) {
      this.clearEvaluationList();
      return;
    }

    this.localStorage.store(this.evaluationListKey, evaluationList);
  }

  addToEvaluation(coupon: any): void {
    this.update(state => {
      const isInEvaluation = state.evaluationList.find(i => coupon.CouponCode != undefined && i.coupon.CouponCode === coupon.CouponCode);

      if (isInEvaluation !== undefined) {
        return;
      }

      if (state.evaluationList.length >= 10) {
        // get first non-pending item
        const firstItem = state.evaluationList.find(i => i.status === EvaluationStatus.accepted || i.status === EvaluationStatus.declined);
        if (firstItem === undefined) {
          // no items are able to be removed...there are 10 pending items
          // loggingService.logError('Unable to add new items to the evaluation list - limit reached');
          return;
        }
        this.removeFromEvaluation(firstItem.coupon.CouponCode);
      }

      const evaluationList = [
        ...state.evaluationList,
        new EvaluationModel({
          status: EvaluationStatus.pending,
          coupon: coupon
        })
      ];

      this.localStorage.store(this.evaluationListKey, evaluationList);
      return { evaluationList };
    });
  }

  removeFromEvaluation(couponCode: string): void {
    this.update(state => {
      const evaluationList = state.evaluationList.filter(o => o.coupon.CouponCode !== couponCode);
      this.localStorage.store(this.evaluationListKey, evaluationList);
      return { evaluationList };
    });
  }

  updateCouponStatus(couponCode: string, status: EvaluationStatus): void {
    this.update(state => {
      const evaluationListCopy: any = cloneDeep(state.evaluationList);

      evaluationListCopy.forEach(evaluation => {
        if (evaluation.coupon.CouponCode === couponCode) {
          evaluation.status = status;
        }
      });

      this.localStorage.store(this.evaluationListKey, evaluationListCopy);
      return { evaluationList: evaluationListCopy };
    });
  }

  updateAwaitingReplyList(coupon: any): void {
    this.update(state => {
      const foundCoupon = state.awaitingReplyList.find(i => coupon.CouponCode != undefined && i.CouponCode === coupon.CouponCode);

      if (foundCoupon !== undefined) {
        return; // item already in list
      }

      const awaitingReplyList = [...state.awaitingReplyList, coupon];

      return { awaitingReplyList };
    });
  }

  removeAwaitingReply(couponCode: string): void {
    this.update(state => {
      const awaitingReplyList = state.awaitingReplyList.filter(o => o.CouponCode !== couponCode);
      return { awaitingReplyList };
    });
  }

  clearEvaluationList(): void {
    this.localStorage.store(this.evaluationListKey, []);
  }

  clear(): void {
    this.update(createInitialState());
  }
}
