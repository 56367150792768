import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CorrectScoreEventService } from 'src/app/core/services/correct-score-event.service';
import { SportStore } from './../state/sport/sport.store';

import { catchError, finalize, first } from 'rxjs/operators';

import { kebabCase } from 'lodash-es';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { SportId } from 'src/app/shared/models/sport-id.model';
import {
  AreaModel,
  CategoryModel,
  MarketModel,
  MatchModel,
  RegionModel,
  SelectionModel,
  SportModel,
  TournamentModel
} from 'src/app/shared/models/sport.model';
import { environment } from 'src/environments/environment';
import { APIService } from './api.service';

interface SportMarketGroupId {
  [sportId: number]: number;
}

@Injectable({
  providedIn: 'root'
})
export class MatchService {
  activeMarketGroupId$: BehaviorSubject<SportMarketGroupId> = new BehaviorSubject({
    [SportId.Soccer]: environment.appConfigDefaults.sports.marketGroups[SportId.Soccer][0].id,
    [SportId.Basketball]: environment.appConfigDefaults.sports.marketGroups[SportId.Basketball][0].id,
    [SportId.Tennis]: environment.appConfigDefaults.sports.marketGroups[SportId.Tennis][0].id,
    [SportId.IceHockey]: environment.appConfigDefaults.sports.marketGroups[SportId.IceHockey][0].id,
    [SportId.Volleyball]: environment.appConfigDefaults.sports.marketGroups[SportId.Volleyball][0].id,
    [SportId.Handball]: environment.appConfigDefaults.sports.marketGroups[SportId.Handball][0].id,
    [SportId.Baseball]: environment.appConfigDefaults.sports.marketGroups[SportId.Baseball][0].id,
    [SportId.Waterpolo]: environment.appConfigDefaults.sports.marketGroups[SportId.Waterpolo][0].id,
    [SportId.Cycling]: environment.appConfigDefaults.sports.marketGroups[SportId.Cycling][0].id,
    [SportId.Football]: environment.appConfigDefaults.sports.marketGroups[SportId.Football][0].id,
    [SportId.Rugby]: environment.appConfigDefaults.sports.marketGroups[SportId.Rugby][0].id,
    [SportId.Badminton]: environment.appConfigDefaults.sports.marketGroups[SportId.Badminton][0].id,
    [SportId.Golf]: environment.appConfigDefaults.sports.marketGroups[SportId.Golf][0].id,
    [SportId.Boxing]: environment.appConfigDefaults.sports.marketGroups[SportId.Boxing][0].id,
    [SportId.AussieRules]: environment.appConfigDefaults.sports.marketGroups[SportId.AussieRules][0].id,
    [SportId.Cricket]: environment.appConfigDefaults.sports.marketGroups[SportId.Cricket][0].id,
    [SportId.TableTennis]: environment.appConfigDefaults.sports.marketGroups[SportId.TableTennis][0].id,
    [SportId.Darts]: environment.appConfigDefaults.sports.marketGroups[SportId.Darts][0].id,
    [SportId.Futsal]: environment.appConfigDefaults.sports.marketGroups[SportId.Futsal][0].id,
    [SportId.Pesapallo]: environment.appConfigDefaults.sports.marketGroups[SportId.Pesapallo][0].id,
    [SportId.MMA]: environment.appConfigDefaults.sports.marketGroups[SportId.MMA][0].id,
    [SportId.ESportCounterStrike]: environment.appConfigDefaults.sports.marketGroups[SportId.ESportCounterStrike][0].id,
    [SportId.BeachSoccer]: environment.appConfigDefaults.sports.marketGroups[SportId.BeachSoccer][0].id
  });

  lastOpenedPrematchId: number;
  matchUpdated$: Subject<boolean> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly apiService: APIService,
    private readonly sportStore: SportStore,
    private readonly correctScoreEventService: CorrectScoreEventService,
    private readonly languageService: LanguageService
  ) {}

  collapseAll(): void {
    this.sportStore.updateUI({
      preMatchCloseAll: true
    });
  }

  expandAll(): void {
    this.sportStore.updateUI({
      preMatchCloseAll: false
    });
  }

  clearError(): void {
    this.sportStore.setError(undefined);
  }

  clearData(): void {
    this.sportStore.updateMatch(undefined);
    this.sportStore.updateUI({ preMatchCloseAll: false });
  }

  navigateToMatch(matchId: number, name?: string): void {
    // In case navigation happens from Sports section, this must always be set to false
    // so that navigating using the back button still works.
    this.sportStore.updateEventSelection({ filterToDepthFour: false });

    const kebabCaseName = kebabCase(name);
    this.router.navigate([`sports/match/${matchId}${kebabCaseName ? `/${kebabCaseName}` : ''}`]);
  }

  getMatch(matchId: number): void {
    this.sportStore.setLoading(true);

    this.apiService
      .get(APIType.SportsbookFeed, `api/feeds/prematch/lite/event/ungrouped/${this.languageService.selectedLanguage.language}/4/${matchId}`)
      .pipe(
        first(),
        catchError(error => {
          this.sportStore.setError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.sportStore.setLoading(false);
        })
      )
      .subscribe(data => {
        const match: SportModel = new SportModel({});
        const odds = this.mapOddsDataToModel(data.AM[0].IT[0], data.AM[0].SI, data.AM[0].SN);
        // There will only be one sport, category, and tournament for a single match
        match.id = data.AM[0].SI;
        match.name = data.AM[0].SN;
        match.groupingType = data.AM[0].GT;
        match.categories = [
          new CategoryModel({
            id: data.AM[0].IT[0].CI,
            name: data.AM[0].IT[0].CN,
            tournaments: [
              new TournamentModel({
                id: data.AM[0].IT[0].TI,
                name: data.AM[0].IT[0].TN,
                regions: this.mapMarketDataToModel(data.AM[0].IT[0].OC),
                matches: [
                  new MatchModel({
                    id: data.AM[0].IT[0].II,
                    date: data.AM[0].IT[0].ID,
                    name: data.AM[0].IT[0].IN,
                    smartBetCode: data.AM[0].IT[0].SB,
                    eventCategory: data.AM[0].IT[0].EC,
                    categoryName: data.AM[0].IT[0].CN,
                    tournamentName: data.AM[0].IT[0].TN.trim(),
                    externalId: data.AM[0].IT[0].EI,
                    extParentItemID: data.AM[0].IT[0].PI,
                    extParentTeamID: data.AM[0].IT[0].PT,
                    extTeamOrder: data.AM[0].IT[0].TO,
                    odds,
                    correctScoreOdds: this.correctScoreEventService.parseOddsForCorrectScoreView(odds)
                  })
                ]
              })
            ]
          })
        ];
        this.sportStore.updateMatch(match);
        this.matchUpdated$.next(true);
      });
  }

  filterMarkets(filterQuery: string): void {
    this.sportStore.updateUI({
      filterQuery
    });
  }

  private mapMarketDataToModel(data: any): RegionModel[] {
    return [
      new RegionModel({
        isDefault: true,
        areas: [
          new AreaModel({
            isDefault: true,
            markets: data.map(
              oc =>
                new MarketModel({
                  id: oc.OT.OI,
                  name: oc.OT.ON,
                  marketType: oc.OT.TT, // Maps to MultilineType
                  spreadValue: oc.SB,
                  spreadDisplayValue: oc.MO[0].OA.VD,
                  selections: oc.MO.map(
                    selection =>
                      new SelectionModel({
                        id: oc.OT.OI,
                        name: selection.OA.ON,
                        spreadValue: selection.OA.SV,
                        spreadDisplayValue: selection.OA.VD
                      })
                  ),
                  description: oc.OT.OD,
                  typeId: oc.OT.MT,
                  order: oc.OO
                })
            )
          })
        ]
      })
    ];
  }

  private mapOddsDataToModel(data: any, sportId: number, sportName: string): OddModel[] {
    const odds: OddModel[] = [];
    data.OC.forEach(oddCollection => {
      oddCollection.MO.forEach(odd => {
        odds.push(
          new OddModel({
            id: odd.OI,
            value: odd.OT.OO,
            spreadValue: odd.OA.SV,
            spreadValueDisplay: odd.OA.VD,
            sportId,
            sportName,
            categoryId: data.CI,
            categoryName: data.CN,
            tournamentId: data.TI,
            tournamentName: data.TN.trim(),
            matchId: data.II,
            matchName: data.IN,
            matchDate: data.ID,
            marketId: oddCollection.OI,
            marketTypeId: oddCollection.OT.OI,
            marketName: oddCollection.OT.ON,
            originalMarketName: oddCollection.OT.ON,
            originalMarketId: oddCollection.OI,
            smartCode: odd.SB,
            eventCategory: data.EC,
            combinability: oddCollection.CB,
            selectionId: odd.OA.OI,
            originalSelectionId: odd.OA.OI,
            selectionTypeId: odd.OT.OI,
            selectionName: odd.OA.ON,
            incompatibleEvents: data.IE
          })
        );
      });
    });
    return odds;
  }
}
