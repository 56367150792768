import { Injectable, OnDestroy } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Subject } from 'rxjs';
import { PromoFabState } from 'src/app/shared/models/promo-fab.model';

function createInitialState(): PromoFabState {
  return {
    enabled: false,
    hide: false,
    image1: undefined,
    image2: undefined,
    image3: undefined,
    redirectURL: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'promoFab' })
export class PromoFabStore extends Store<PromoFabState> implements OnDestroy {
  destroy$ = new Subject<boolean>();

  constructor() {
    super(createInitialState());
  }

  updateUI(fabState: Partial<PromoFabState>): void {
    this.update(() => ({ ...fabState }));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
