<div *ngIf="bets" class="bet-search-results-container">
  <ng-container *ngIf="bets.length > 0; else noResult">
    <div class="header">
      <div class="legend first-empty-cell"></div>
      <div class="placed-date cell" transloco="Placed Date"></div>
      <div class="stake cell">({{ accountQuery.userData.currency.symbol }}) {{ 'Stake' | transloco }}</div>
      <div class="winnings cell">({{ accountQuery.userData.currency.symbol }}) {{ 'Winnings' | transloco }}</div>
      <div class="coupon-details empty-cell"></div>
    </div>
    <ng-container *ngFor="let result of bets; let i = index; trackBy: resultsTrackBy">
      <div *ngIf="!(result.betFinalState === betFinalState.InEvaluation)" class="result-row" (click)="navigateTo(result)">
        <div class="legend cell" [ngClass]="'bet-final-state-' + result.betFinalState">
          <i *ngIf="result.couponStatusId === 1" class="fa fa-clock-o" aria-hidden="true"></i>
        </div>
        <div class="placed-date cell">{{ result.couponDate | date : 'dd/MM/yyyy HH:mm' }}</div>
        <div class="stake cell">
          {{ result.stake | number : '1.2-2' }}
        </div>
        <div class="winnings cell">
          {{ result.won | number : '1.2-2' }}
        </div>
        <div class="coupon-details cell last-cell-in-a-row">
          <i class="fa fa-angle-right" aria-hidden="true" *ngIf="result.betFinalState !== betFinalState.InEvaluation"></i>
        </div>
      </div>
    </ng-container>
    <div class="totals-row">
      <div class="description last-row" transloco="Totals"></div>
      <div class="total total-cell last-row stake">{{ totals.stake | number : '1.2-2' }}</div>
      <div class="total total-cell last-row winnings">
        {{ totals.winnings | number : '1.2-2' }}
      </div>
      <div class="empty-cell last-total-cell"></div>
    </div>
    <app-legend-table></app-legend-table>
  </ng-container>
</div>
<ng-template #noResult>
  <div style="margin: 20px 0">
    <app-form-message type="warning" *ngIf="bets.length === 0" [text]="'No results for current search parameters.' | transloco">
    </app-form-message>
  </div>
</ng-template>
