/* tslint:disable */
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { AccountService } from 'src/app/core/services/account/account.service';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { fadeIn } from 'src/app/shared/animations';
import { BankDetailsModel, BanksModel, ChannelsModel, PaymentDisplayState, PaymentListModel } from 'src/app/shared/models/account.model';
import { PayoutPromoService } from 'src/app/core/services/payout-promo/payout-promo.service';
@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  amount: number;
  zenithBank: any = { id: '057', name: 'ZenithBankCIB' };
  paymentForm: FormGroup;
  minAmount: number = 1;
  maxAmount: number;
  fees: string;
  paymentId: string = '';
  delimiters: any = [];
  invalidateProceed: boolean = true;
  showAmountInputs: boolean = false;
  showAmountDelimiters: boolean = false;
  showBankDetails: boolean = false;
  showEmailCurrency: boolean = false;
  showBankAccountNumber: boolean = false;
  showAvailableAmount: boolean = false;
  showFees: boolean = false;
  showMinimumWithdrawal: boolean = false;
  showDepositChannels: boolean = false;
  showFeeCalculator: boolean = false;
  showProceedBtn: boolean = false;
  currentFee$ = new BehaviorSubject<number>(0);
  proceedBtn$ = new BehaviorSubject<string>(translate('Disburse funds'));
  loading$ = new BehaviorSubject<boolean>(false);

  bankAccountDetails$: BehaviorSubject<BankDetailsModel> = new BehaviorSubject<BankDetailsModel>(undefined);
  channelDetails$: BehaviorSubject<BankDetailsModel> = new BehaviorSubject<BankDetailsModel>(undefined);
  destroy$: Subject<boolean> = new Subject<boolean>();
  load$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showHideAccountData: boolean = false;
  showHideInfoNote: boolean = false;

  @Output() onContinue = new EventEmitter();

  constructor(
    private currencyFormat: CurrencyFormatPipe,
    readonly accountPaymentQuery: AccountPaymentQuery,
    readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly accountPaymentService: AccountPaymentService,
    private readonly notificationService: NotificationService,
    private readonly appConfigService: AppConfigService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly payoutPromoService: PayoutPromoService
  ) {}

  showHideAccData() {
    this.showHideAccountData = !this.showHideAccountData;
    this.showHideInfoNote = false;
  }
  showHideNote() {
    this.showHideInfoNote = !this.showHideInfoNote;
    this.showHideAccountData = false;
  }

  getPaymentLimits(paymentId: string): void {
    this.accountPaymentQuery.paymentsListWithdrawal$.pipe(takeUntil(this.destroy$)).subscribe(payments => {
      if (payments) {
        const returningData: PaymentListModel = payments.find(list => list.identifier === paymentId);
        this.minAmount = returningData.minimumLimit;
        this.maxAmount = returningData.maximumLimit;
        this.fees = returningData.fees;
      }

      this.initialiseForm(this.minAmount, this.maxAmount);
    });
  }

  setAmount(value: number): void {
    if (this.paymentForm.controls.amount) {
      this.paymentForm.controls.amount.setValue(value);
      this.amount = value; // amount is used only for the quick buttons delimiters
    }
  }

  setAccountNo(value: number): void {
    if (this.paymentForm.controls.accountNumber) {
      this.paymentForm.controls.accountNumber.setValue(value);
    }
  }

  setBank(value: any): void {
    if (this.paymentForm.controls.bank) {
      this.paymentForm.controls.bank.setValue(value);
    }
  }

  setChannels(value: any): void {
    if (this.paymentForm.controls.channel) {
      this.paymentForm.controls.channel.setValue(value);
    }
  }

  onCancel(): void {
    this.router.navigate(['/account/withdrawal']);
  }
  goToPromoPayoutDetails(transactionId: number): void {
    this.router.navigate(['/account/withdrawal/promotion', transactionId]);
  }
  performWithdrawal(): void {
    const amount = this.paymentForm.controls.amount.value;
    const formatedAmount = this.currencyFormat.transform(amount, '');
    if (this.paymentForm.controls.amount.value > this.maxAmount) {
      return;
    }
    this.proceedBtn$.next(translate('Processing'));
    this.loading$.next(true);
    if (this.paymentForm.valid) {
      const withdrawalData = {
        amount: this.paymentForm.controls.amount.value,
        identifier: this.paymentId,
        channel: this.paymentForm.controls.channel ? this.paymentForm.controls.channel.value : undefined,
        accountNumber: this.paymentForm.controls.accountNumber ? this.paymentForm.controls.accountNumber.value : undefined,
        bank: this.paymentForm.controls.bank ? this.paymentForm.controls.bank.value : undefined,
        siteRedirectUrl: window.location.href,
        bankDetailsId: this.accountQuery.userData.bankAccountDetails.Id
      };
      this.accountPaymentService
        .performWithdrawal(withdrawalData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          result => {
            if (!result || result.error) {
              const errorMsg = result.error.SystemMessage || result.error.ResponseMessage;
              this.accountPaymentService.toggleLoading(false);
              this.notificationService.showWithdrawalErrorNotification(
                translate(
                  "Your withdrawal request of <span class='amount'>{{formattedAmount}} </span><br> was rejected due to {{errorMsg}}.",
                  {
                    formatedAmount: formatedAmount,
                    errorMsg: errorMsg
                  }
                ),
                translate('Request rejected!'),
                () => {
                  this.performWithdrawal();
                },
                () => {
                  window.location.href = window.location.href;
                }
              );
            } else {
              const transactionId = result.Result.TransactionId;
              this.payoutPromoService
                .getIfTransactionQualifies(transactionId)
                .then(response => {
                  if (response.qualifies === 'qualified') {
                    this.notificationService.showPromoWithdrawalSuccessNotification(
                      translate(
                        "<p class='sub-title'> Time starts working for you - now!</p>Your payout request of <span class='amount'>{{formattedAmount}}</span> has <br> been successful qualified for promotion <br> CASH ON TIME.<div><img class='timer' src='../../../../../assets/images/payments/Timer-full.svg' alt=''/></div>",
                        {
                          formatedAmount: formatedAmount
                        }
                      ),
                      translate('Congrats!'),
                      () => {
                        this.goToPromoPayoutDetails(transactionId);
                      },
                      () => {
                        setTimeout(() => {
                          this.onContinue.emit(null);
                        }, 1500);
                      }
                    );
                  } else {
                    this.notificationService.showWithdrawalSuccessNotification(
                      translate("Your payment request of <span class='amount'>{{formattedAmount}}</span><br> is being processed.", {
                        formatedAmount: formatedAmount
                      }),
                      translate('Successful request'),
                      () => {
                        setTimeout(() => {
                          this.onContinue.emit(null);
                        }, 1500);
                      },
                      () => {
                        window.location.href = window.location.href;
                      }
                    );
                  }
                })
                .catch(() => {
                  this.notificationService.showWithdrawalSuccessNotification(
                    translate("Your payment request of <span class='amount'>{{formattedAmount}}</span><br> is being processed.", {
                      formatedAmount: formatedAmount
                    }),
                    translate('Successful request'),
                    () => {
                      setTimeout(() => {
                        this.onContinue.emit(null);
                      }, 1500);
                    },
                    () => {
                      window.location.href = window.location.href;
                    }
                  );
                });
            }
          },
          error => {
            this.proceedBtn$.next(translate('Disburse funds'));

            this.loading$.next(false);
          }
        );
    }
  }

  getUIDetails(): void {
    this.accountPaymentService
      .getPaymentBanks()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setAccountNo(this.accountPaymentQuery.lastAccNumber);
        this.setBank(this.paymentId === this.zenithBank.name ? this.zenithBank.id : this.accountPaymentQuery.lastBankId);
      });
  }

  setUIDetails(): void {
    this.accountPaymentQuery.paymentDetails$.pipe(takeUntil(this.destroy$)).subscribe(details => {
      if (details) {
        if (details.displayOptions === PaymentDisplayState.AmountAndUserBankAccount) {
          this.showAmountInputs = true;
          this.showProceedBtn = true;
          this.showEmailCurrency = true;
          this.showBankAccountNumber = true;
          this.showAvailableAmount = true;
          this.showFees = true;
          this.showMinimumWithdrawal = true;
          return;
        }

        if (details.displayOptions !== PaymentDisplayState.InfoOnly) {
          this.getUIDetails();
          this.showAmountInputs = true;
          this.showAmountDelimiters = true;
          this.showProceedBtn = false;
          if (
            details.displayOptions === PaymentDisplayState.AmountAndBanks ||
            details.displayOptions === PaymentDisplayState.AmountBankChannels
          ) {
            this.showBankDetails = true;
            this.showProceedBtn = true;
            if (details.displayOptions === PaymentDisplayState.AmountBankChannels) {
              this.setChannels(this.accountPaymentQuery.channelsList$[0]);
              this.showDepositChannels = true;
            }
          }
          if (details.displayOptions === PaymentDisplayState.AmountChannels) {
            this.setChannels(this.accountPaymentQuery.channelsList$[0]);
            this.showDepositChannels = true;
            this.showProceedBtn = true;
          }
          if (details.displayOptions === PaymentDisplayState.InfoFeeCalculator) {
            this.showFeeCalculator = true;
            this.showProceedBtn = true;
            this.paymentForm.controls.amount.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(s => {
              if (!this.paymentForm.controls.amount.invalid) {
                this.calculateFee(s);
              }
            });
          }
        }

        if (details.status !== 1) {
          this.onCancel();
        }
      } else {
        this.getPaymentDetails();
      }
    });
  }

  delimiterTrackBy(index: number, delimiter: number): number {
    return index;
  }

  channelsTrackBy(index: number, channel: ChannelsModel): number {
    return index;
  }

  banksTrackBy(index: number, bank: BanksModel): number {
    return bank.id;
  }

  getPaymentDetails(): void {
    this.accountPaymentService
      .getPaymentDetails(this.paymentId, false)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.setUIDetails();
        },
        error => {
          this.onCancel();
          this.notificationService.showErrorNotification('general.someThingWentWrong', error);
        }
      );
  }

  checkBlinkingDetails(KYCDocumentsStatus: string): void {
    if (KYCDocumentsStatus !== 'verified') {
      this.router.navigate(['/account/withdrawal']);
      return;
    }
  }

  ngOnInit(): void {
    this.accountPaymentService.getPayoutPromoConfig().subscribe();

    this.accountService.getKYCStatus(this.accountQuery.accessToken).subscribe(() => {
      let KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
      if (KYCDocumentsStatus === 'documentsNeeded' || KYCDocumentsStatus === 'accountNumberNeeded') {
        this.accountService.getBlinkingSessionStatus(this.accountQuery.accessToken).subscribe(() => {
          // check status again if Session Status is needed
          KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
          this.checkBlinkingDetails(KYCDocumentsStatus);
        });
      } else {
        this.checkBlinkingDetails(KYCDocumentsStatus);
      }
    });

    this.paymentId = this.route.snapshot.paramMap.get('paymentCodeId');
    if (!this.paymentId) {
      this.paymentId = 'BankTransfer';
    }

    this.getPaymentLimits(this.paymentId);
    this.delimiters = this.appConfigService.get('account').withdrawalsDelimiters;

    // required default values on accNo, bank and channel
    this.setAccountNo(9999999999);
    this.setBank('000');
    this.setChannels('bank');
    this.getPaymentDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initialiseForm(min: number, max: number): void {
    if (this.paymentForm) {
      return;
    }

    switch (this.paymentId) {
      case 'BankTransfer':
      case 'MpesaWithdrawal': {
        this.paymentForm = new FormGroup({
          amount: new FormControl(min, [Validators.required, Validators.min(min), Validators.max(max)])
        });
        break;
      }
      case 'ZenithBankCIB': {
        this.paymentForm = new FormGroup({
          amount: new FormControl(min, [Validators.required, Validators.min(min), Validators.max(max)]),
          accountNumber: new FormControl('', [
            Validators.required,
            Validators.pattern(new RegExp(this.appConfigService.get('account').accountNumberValidationRegex))
          ]),
          bank: new FormControl(Validators.required)
        });
        break;
      }
      default: {
        this.paymentForm = new FormGroup({
          amount: new FormControl(min, [Validators.required, Validators.min(min), Validators.max(max)]),
          accountNumber: new FormControl('', [
            Validators.required,
            Validators.pattern(new RegExp(this.appConfigService.get('account').accountNumberValidationRegex))
          ]),
          bank: new FormControl('', Validators.required),
          channel: new FormControl('', Validators.required)
        });
        break;
      }
    }
  }

  private calculateFee(amount: number): void {
    this.accountPaymentService
      .getCalculateFee(this.paymentId, amount)
      .pipe(first())
      .subscribe(fee => {
        this.currentFee$.next(fee);
      });
  }
}
