import { ChangeDetectionStrategy, Component } from '@angular/core';

import { LanguageService } from 'src/app/core/services/language.service';
import { LanguageModel } from 'src/app/shared/models/application.model';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent {
  constructor(readonly languageService: LanguageService) { }

  languageTrackBy(item: LanguageModel): string {
    return item.language;
  }

  selectLanguage(language: string): void {
    this.languageService.saveLanguageSelection(language);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }
}
