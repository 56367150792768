<div>
  <div class="event">
    <div class="event-tournament-details">
      <app-event-header [event]="event" [sportId]="sportId" *ngIf="showDetails && sportId">
      </app-event-header>
    </div>
    <div class="event-info">
      <div class="event-details">
        <app-event-details [event]="event" *ngIf="showDetails" [showDate]="showDate" (click)="navigateToMatch()"
          analytics [click]='true' [category]="'Match Details'">
        </app-event-details>
      </div>
      <app-multi-line-market-odds *ngIf="isAvailable" [odds]="event.odds" [markets]="markets">
      </app-multi-line-market-odds>
      <app-not-available-message *ngIf="!isAvailable"></app-not-available-message>
    </div>
  </div>
  <app-statistics *ngIf="event.externalId === (statisticsQuery.selectedExternalId$ | async)"
    [externalId]="event.externalId"></app-statistics>
</div>