import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';

import { BetCouponGroup, CouponType } from 'clientside-coupon';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AnalyticsTrackingService } from 'src/app/core/services/analytics/analytics-tracking.service';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponEditService } from 'src/app/core/services/coupon/coupon-edit.service';
import { CouponSelectionService } from 'src/app/core/services/coupon/coupon-selections.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { fadeIn } from 'src/app/shared/animations';
import { UserModel, Wallet } from 'src/app/shared/models/account.model';
import { AnalyticsEventAction } from 'src/app/shared/models/analytics.model';
import { ButtonType } from 'src/app/shared/models/button.model';
import { BookedCouponModel, CouponGroupingType, CouponOddsModel, GroupingTabsVisibleModel } from 'src/app/shared/models/coupon.model';
import { brandInfo } from 'src/brand-info';

const allowedBonusWalletNames = ['Bonus Sports', 'Bonus Sportovi'];
@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('betInProgress') betInProgress: TemplateRef<any>;

  buttonType: typeof ButtonType = ButtonType;
  couponType: typeof CouponType = CouponType;
  groupingType: typeof CouponGroupingType = CouponGroupingType;
  currentGroupingsTabSelected: CouponGroupingType;
  canPostCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  canBookCoupon$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  hasOddChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasOdds$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  bookedCouponData: BookedCouponModel = undefined;
  bookedCouponData$: Subject<BookedCouponModel> = new Subject();
  canBookWhenLoggedIn: boolean = false;
  canPlaceBet: boolean = true;
  overlayRef: OverlayRef;
  overlayTimeout: any;
  brandInfo = brandInfo;
  viewInitialized$ = new BehaviorSubject(false);
  allowCombinationBets = this.appConfigService.get('sports').allowCombinationBets;
  showBookedBetRetailMessage = this.appConfigService.get('sports').coupon.showBookedBetRetailMessage;
  currencySymbol: string;
  groupingTabsVisible: GroupingTabsVisibleModel = {
    multiple: false,
    split: false,
    singles: false,
    combination: false
  };
  actionButtonStyle: any = {
    flex: '1 1 100%',
    fontSize: '14px',
    height: '40px',
    margin: '3px'
  };

  oddChangesInterval = undefined;
  enableSlideUps = this.appConfigService.get('enableSlideUps');
  postingCoupon$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  firstStakePress$ = new BehaviorSubject<boolean>(true);
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private oddChangesTimer;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationService: ApplicationService,
    readonly couponQuery: CouponQuery,
    readonly appConfigService: AppConfigService,
    private readonly accountService: AccountService,
    private readonly couponService: CouponService,
    private readonly couponStore: CouponStore,
    private readonly notificationService: NotificationService,
    private readonly couponEditService: CouponEditService,
    private readonly couponSelectionService: CouponSelectionService,
    private readonly overlay: Overlay,
    private readonly vcr: ViewContainerRef,
    private readonly analyticsService: AnalyticsService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly analyticsTrackingService: AnalyticsTrackingService
  ) {
    this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
    this.canBookWhenLoggedIn =
      this.appConfigService.get('sports').coupon.canBookWhenLoggedIn ||
      (this.accountQuery.userData && this.accountQuery.userData.mobile && this.accountQuery.userData.mobile.verifyType === 'LCKD');
    this.canPlaceBet = !(
      this.accountQuery.userData &&
      this.accountQuery.userData.mobile &&
      this.accountQuery.userData.mobile.verifyType === 'LCKD'
    );
    this.currencySymbol = this.currencyService.getCurrency(this.applicationQuery.currency).symbol;
  }

  ngOnInit(): void {
    // this.scrollToBottomAfterInitialization();
    // TODO: Coupon Init
    this.bookedCouponData = undefined;
    this.bookedCouponData$.next(undefined);
    this.hasOdds$.next(false);
    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(couponData => {
      if (couponData) {
        this.hasOdds$.next(couponData.Odds.length > 0);
        setTimeout(() => {
          // This had to be done so that the parsing is done in a separate digest cycle
          // if the coupon is edited within the betslip screen
          this.couponSelectionService.parseSelections(couponData.Odds);
        });

        if (couponData.CouponType === CouponType.Single || couponData.AllGroupings === null) {
          return;
        }

        if (
          this.couponQuery.groupingsTabSelected === undefined ||
          this.currentGroupingsTabSelected !== this.couponQuery.groupingsTabSelected
        ) {
          // determine which combination tab to show
          const lastGrouping = couponData.AllGroupings[couponData.AllGroupings.length - 1];
          const singlesGrouping = couponData.AllGroupings.find(g => g.Grouping === 1);

          if (couponData.Groupings.length === 1 && lastGrouping.Selected) {
            if (lastGrouping.Combinations === 1) {
              this.setGroupingTab(CouponGroupingType.Multiple);
            } else if (lastGrouping === singlesGrouping) {
              this.setGroupingTab(CouponGroupingType.Singles);
            } else {
              this.setGroupingTab(CouponGroupingType.Split);
            }
          } else if (couponData.Groupings.length === 1 && singlesGrouping && singlesGrouping.Selected) {
            this.setGroupingTab(CouponGroupingType.Singles);
          } else {
            this.setGroupingTab(CouponGroupingType.Combination);
          }
        }

        // check group tabs visability
        this.groupingTabsVisible.multiple = this.isGroupingButtonVisible(CouponGroupingType.Multiple);
        this.groupingTabsVisible.split = this.isGroupingButtonVisible(CouponGroupingType.Split);
        this.groupingTabsVisible.singles = this.isGroupingButtonVisible(CouponGroupingType.Singles);
        this.groupingTabsVisible.combination = this.isGroupingButtonVisible(CouponGroupingType.Combination);
      } else {
        this.hasOdds$.next(false);
      }
    });

    this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());

    this.currentGroupingsTabSelected = this.couponQuery.groupingsTabSelected;

    this.couponQuery.groupingsTabSelected$.pipe(takeUntil(this.destroy$)).subscribe(groupingType => {
      if (groupingType !== undefined && groupingType !== this.currentGroupingsTabSelected) {
        this.currentGroupingsTabSelected = groupingType;
        this.updateGroupingsTab(groupingType);
      }
    });

    this.couponQuery.oddChanges$.subscribe(oddChanges => {
      if (oddChanges) {
        this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());
      }
    });

    if (this.accountQuery.accessToken) {
      this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
    }
    this.userData$.subscribe(data => {
      if (data) {
        this.isLoggedIn$.next(true);
      }
    });

    // if (this.couponQuery.oddChanges !== undefined && this.couponQuery.oddChanges.length > 0) {
    //   this.runCouponOddChanges();
    // } else {
    //   this.stopCouponOddChanges();
    // }

    let prevCouponSelectionIds = 0;
    this.couponQuery.couponSelectionIds$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (prevCouponSelectionIds !== state.length) {
        prevCouponSelectionIds = state.length;

        if (
          this.isGroupingButtonVisible(CouponGroupingType.Multiple) &&
          this.couponQuery.groupingsTabSelected === CouponGroupingType.Split
        ) {
          this.couponStore.updateGroupingTab(CouponGroupingType.Multiple);
        } else if (
          this.isGroupingButtonVisible(CouponGroupingType.Split) &&
          this.couponQuery.groupingsTabSelected === CouponGroupingType.Multiple
        ) {
          this.couponStore.updateGroupingTab(CouponGroupingType.Split);
        }
      }
    });
    setTimeout(() => {
      this.viewInitialized$.next(true);
    });
  }

  // scrollToBottomAfterInitialization(): void {
  //   const initializationSub = this.viewInitialized$.subscribe(initialized => {
  //     if (initialized) {
  //       window.scrollTo(0, window.document.body.scrollHeight);
  //       initializationSub.unsubscribe();
  //     }
  //   });
  // }

  continueBetting(): void {
    this.applicationService.showCoupon$.next(false);
  }

  close(): void {
    if (this.enableSlideUps) {
      this.applicationService.closeAnySlideUps();
    }
  }

  // runCouponOddChanges(): void {
  //   this.stopCouponOddChanges();

  //   const couponOdds = this.getCouponOdds();

  //   if (couponOdds !== undefined) {
  //     const containsLiveEvents = couponOdds.some(odd => odd.isLive);

  //     if (containsLiveEvents) {
  //       this.oddChangesTimer = this.appConfigService.get('sports').coupon.liveOddChangesTimer;
  //     } else {
  //       this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
  //     }

  //     this.couponService.getOddsChanged(couponOdds, this.languageService.selectedLanguage.language).subscribe();
  //   }

  //   this.oddChangesInterval = window.setInterval(() => {
  //     this.runCouponOddChanges();
  //   }, this.oddChangesTimer);
  // }

  // stopCouponOddChanges(): void {
  //   if (this.oddChangesInterval) {
  //     clearInterval(this.oddChangesInterval);
  //   }
  // }

  acceptOddChanges(): void {
    this.couponService.acceptOddChanges();
  }

  getCouponOdds(): CouponOddsModel[] {
    if (this.couponQuery.couponData === undefined) {
      return undefined;
    }

    const couponOdds: CouponOddsModel[] = [];
    this.couponQuery.couponData.Odds.forEach(odd => {
      couponOdds.push({
        isLive: odd.EventCategory.toLowerCase() === 'l',
        matchId: odd.MatchId,
        marketId: odd.MarketId,
        selectionId: odd.SelectionId
        // selectionValue: odd.OddValue
      });
    });

    return couponOdds;
  }

  updateGroupingsTab(groupingType: CouponGroupingType): void {
    const groupUpdates: BetCouponGroup[] = [];

    this.couponQuery.couponData.AllGroupings.forEach(group => {
      if (!group.Selected) {
        return;
      }

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = false;

      groupUpdates.push(newGrouping);
    });

    if (groupingType === CouponGroupingType.Multiple || groupingType === CouponGroupingType.Split) {
      const lastGroup = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 1];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = lastGroup.Grouping;
      newGrouping.Combinations = lastGroup.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Singles) {
      const singlesGrouping = this.couponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = singlesGrouping.Grouping;
      newGrouping.Combinations = singlesGrouping.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    } else if (groupingType === CouponGroupingType.Combination) {
      const group = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 2];

      const newGrouping: BetCouponGroup = new BetCouponGroup();
      newGrouping.Grouping = group.Grouping;
      newGrouping.Combinations = group.Combinations;
      newGrouping.Selected = true;

      groupUpdates.push(newGrouping);
    }

    if (groupUpdates.length > 0) {
      this.updateGroupings(groupUpdates);
    }
  }

  updateGroupings(groupings: BetCouponGroup[]): void {
    this.couponService.updateGroupings(groupings);
  }

  isGroupingButtonVisible(groupingButtonType: CouponGroupingType): boolean {
    if (this.couponQuery.couponData) {
      const lastGrouping = this.couponQuery.couponData.AllGroupings[this.couponQuery.couponData.AllGroupings.length - 1];
      const singlesGrouping = this.couponQuery.couponData.AllGroupings.find(g => g.Grouping === 1);

      if (groupingButtonType === CouponGroupingType.Multiple) {
        if (lastGrouping.Combinations === 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Split) {
        if (lastGrouping !== singlesGrouping && lastGrouping.Combinations > 1) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Singles) {
        if (singlesGrouping !== null) {
          return true;
        }
      } else if (groupingButtonType === CouponGroupingType.Combination) {
        if (this.couponQuery.couponData.AllGroupings.filter(g => g.Grouping !== 1).length > 1) {
          return true;
        }
      }
    }
    return false;
  }

  groupingTabClicked(groupingType: CouponGroupingType): void {
    if (groupingType !== this.couponQuery.groupingsTabSelected) {
      if (groupingType === CouponGroupingType.Combination) {
        this.couponService.enforceSingleCombination = true;
      }
      this.couponService.clearAllBankers();
      this.setGroupingTab(groupingType);
    }
  }

  setGroupingTab(groupingType: CouponGroupingType): void {
    if (groupingType !== this.couponQuery.groupingsTabSelected) {
      this.couponStore.updateGroupingTab(groupingType);
    }
  }

  clearCouponData(): void {
    this.couponService.clearCouponData();
  }

  isAcceptingOddChanges(): boolean {
    return this.couponQuery.couponContainsOddChanges() && this.couponQuery.couponSettings.allowOddChanges === false;
  }

  checkBonusAndPostCoupon(couponData: any): void {
    const isAcceptingOddChanges = this.isAcceptingOddChanges();
    if (isAcceptingOddChanges) {
      this.acceptOddChanges();
      return;
    }

    if (this.postingCoupon$.value || couponData.MaxWinNet == 0 || couponData.TotalOdds == 0) {
      return;
    }

    const callback: Function = () => {
      this.postCoupon();
      this.reportCouponPost();
    };
    this.couponService.checkBonusStake(callback);
  }

  postCoupon(): void {
    if (!this.couponService.checkCouponStake()) {
      return;
    }

    if (!this.canPostCoupon$) {
      return;
    }

    if (this.accountQuery.isAuthenticated) {
      if (
        this.appConfigService.get('sports').coupon.disablePlaceBetForUnverifiedUsers === false ||
        this.accountService.getKYCDocumentsStatus() === 'verified'
      ) {
        this.canPostCoupon$.next(false);
        this.postingCoupon$.next(true);

        this.overlayTimeout = setTimeout(() => {
          this.showOverlay();
        }, 1000);

        this.couponService
          .validateAndPostCoupon()
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            success => {
              this.canPostCoupon$.next(true);
              this.postingCoupon$.next(false);
              this.disposeOverlay();
              if (success) {
                this.continueBetting();
              }
            },
            error => {
              this.canPostCoupon$.next(true);
              this.postingCoupon$.next(false);
              this.disposeOverlay();
              this.notificationService.showErrorNotification(
                translate('An error has occurred. Please try again.'),
                translate('Coupon Not Posted')
              );
            }
          );
      } else {
        this.notificationService.showErrorNotification(
          translate(
            'Bets cannot be placed. You must first complete the registration process and supply the necessary documentation before being able to place bets.'
          ),
          translate('Coupon Not Posted')
        );
      }
    } else {
      this.accountService.openLogin();
    }
  }

  bookCoupon(): void {
    if (!this.canBookCoupon$) {
      return;
    }

    this.canBookCoupon$.next(false);
    this.couponService
      .validateAndPostBookCoupon()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        response => {
          this.canBookCoupon$.next(true);
          if (response) {
            this.bookedCouponData = new BookedCouponModel({
              couponData: cloneDeep(this.couponQuery.couponData),
              bookedCouponCode: response.couponCode,
              bookedCouponDate: response.date
            });
            this.bookedCouponData$.next(this.bookedCouponData);

            this.couponSelectionService.parseSelections(this.bookedCouponData.couponData.Odds);
            this.couponService.clearCouponData();
          }
        },
        error => {
          this.canBookCoupon$.next(true);
          this.notificationService.showErrorNotification(
            translate('An error has occurred. Please try again.'),
            translate('Coupon Not Booked')
          );
        }
      );
  }

  ngOnDestroy(): void {
    this.couponEditService.clearEditData();
    // this.stopCouponOddChanges();
    this.disposeOverlay();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  updateCouponSetting(key: string, value: any): void {
    this.couponService.updateCouponSetting(key, value);
  }

  updateInputStakeValue(event: any): void {
    event.preventDefault();
    this.couponService.updateStakeValue(event.currentTarget.value);
    if (!event.currentTarget.value) {
      this.firstStakePress$.next(true);
    }
  }
  setHighlightText(event: any): void {
    this.reportStakeInputFocus();

    event.preventDefault();
    event.currentTarget.select();
  }
  updateStakeValue(stakeValue: number): void {
    this.couponService.updateStakeValue(stakeValue);
  }

  resetStakeValue(): void {
    this.updateStakeValue(this.couponQuery.globalVariables.MinBetStake);
    this.firstStakePress$.next(true);
  }

  addStakeValue(amount: string): void {
    this.updateStakeValue(
      this.firstStakePress$.getValue() ? parseFloat(amount) : this.couponQuery.couponData.StakeGross + parseFloat(amount)
    );

    this.firstStakePress$.next(false);
  }

  truncate(value: number): number {
    return Math.trunc(value);
  }

  closeCoupon(): void {
    this.applicationService.showCoupon$.next(false);
  }

  areBonusFundsSelected(): boolean {
    return this.couponQuery.couponSettings.useBonusFunds;
  }

  get totalFunds$(): Observable<number> {
    return this.accountQuery.totalFunds$;
  }

  get activeWalletFunds$(): Observable<number> {
    return this.accountQuery.wallets$.pipe(
      map(
        wallets =>
          wallets.find(wallet => {
            if (
              (this.areBonusFundsSelected() && this.isSportsBonusWallet(wallet)) ||
              (!this.areBonusFundsSelected() && !wallet.isBonusWallet)
            ) {
              return wallet;
            }
          }).balance
      )
    );
  }

  get userData$(): Observable<UserModel> {
    return this.accountQuery.userData$;
  }

  private isSportsBonusWallet(wallet: any) {
    return wallet.isBonusWallet && allowedBonusWalletNames.includes(wallet.name);
  }

  private reportStakeInputFocus(): void {
    this.analyticsService.sendEvent({
      Action: AnalyticsEventAction.InputFocus,
      Category: 'Coupon Stake Input'
    });
  }
  private showOverlay(): void {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'overlay',
      panelClass: 'bet-loading',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const betInProgress = new TemplatePortal(this.betInProgress, this.vcr);
    this.overlayRef.attach(betInProgress);
  }

  private disposeOverlay(): void {
    if (this.overlayTimeout) {
      clearTimeout(this.overlayTimeout);
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = undefined;
    }
  }

  private reportCouponPost(): void {
    this.analyticsService.sendEvent(
      {
        Action: AnalyticsEventAction.Click,
        Category: 'Coupon Post'
      },
      {
        coupon: {
          odds: this.couponQuery.couponData.Odds,
          stake: this.couponQuery.couponData.Stake
        }
      }
    );
  }
}
