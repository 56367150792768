import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

/**
 * This guard ensures the user does not have a valid auth token
 * before allowing access to a route. This is particularly useful
 * to stop access to the login page if a user is already logged in.
 */
@Injectable()
export class DepositRouteGuard implements CanActivate {
  constructor(private readonly appConfigService: AppConfigService, private readonly router: Router) { }

  canActivate(): boolean {
    try {
      const depositRedirect = this.appConfigService.get('account').depositRedirect;
      if (depositRedirect) {
        this.router.navigate([depositRedirect]);
        return false;
      }
      return true;
    } catch {
      this.router.navigate(['/']);
      return false;
    }
  }
}
