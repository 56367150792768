import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { APIService } from 'src/app/core/services/api.service';
import { APIType } from 'src/app/shared/models/api.model';
import { ApplicationService } from 'src/app/core/services/application.service';
import { filter, takeUntil } from 'rxjs/operators';
import { differenceInHours, isSameDay } from 'date-fns';
import { DepositPromoService } from 'src/app/core/services/deposit-promo/deposit-promo.service';
import { DepositPromotion } from 'src/app/shared/models/account.model';
import { AccountQuery } from 'src/app/core/state/account/account.query';

interface PopupBannerImage {
  url: string;
  width: number;
  height: number;
}

export interface PopupBanner {
  id: number;
  key?: string;

  availableFrom: string;
  availableUntil: string;

  redirectURL: string;

  bannerImage: PopupBannerImage;
  fabImage: PopupBannerImage;
}

@Component({
  selector: 'app-popup-banners',
  templateUrl: './popup-banners.component.html',
  styleUrls: ['./popup-banners.component.scss']
})
export class PopupBannersComponent implements OnInit, OnDestroy {
  displayBanner$: BehaviorSubject<PopupBanner | undefined> = new BehaviorSubject(undefined);
  displayFAB$: BehaviorSubject<PopupBanner | undefined> = new BehaviorSubject(undefined);
  loadedAvailablePromotions$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  availablePromotions: DepositPromotion[] = [];

  cachedBanners: PopupBanner[];

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly apiService: APIService,
    private readonly router: Router,
    private readonly appService: ApplicationService,
    public readonly depositPromoService: DepositPromoService,
    private readonly accountQuery: AccountQuery
  ) {}

  ngOnInit(): void {
    document.body?.classList?.add('disable-scrolling');
    this.fetchAvailablePromotions();

    this.appService.showPopupBanner$.pipe(takeUntil(this.destroy$)).subscribe(banner => {
      if (!banner) {
        return;
      }

      this.displayFAB$.next(undefined);
      this.displayBanner(banner);
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.cachedBanners) {
          this.handleBanners(this.cachedBanners);
        }
      });
  }

  ngOnDestroy(): void {
    document.body?.classList?.remove('disable-scrolling');

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  handleBannerClick(banner: PopupBanner): void {
    if (banner.redirectURL) {
      this.dismissBanner(banner);
      this.router.navigateByUrl(banner.redirectURL);
    }
  }

  closeBanner(banner: PopupBanner): void {
    this.dismissBanner(banner);
    this.displayFAB(banner);
  }

  private fetchBanners() {
    this.apiService.get(APIType.CMS, 'popup-banners').subscribe(this.handleBanners.bind(this));
  }
  private fetchAvailablePromotions() {
    this.accountQuery.isAuthenticated$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.depositPromoService.getAvailableDepositPromotions().subscribe(this.handleAvailablePromotions.bind(this));
      } else {
        this.depositPromoService.getPublishedDepositPromotions().subscribe(this.handleAvailablePromotions.bind(this));
      }
    });
  }

  private handleAvailablePromotions(data: { promotions: DepositPromotion[] }) {
    this.availablePromotions = data.promotions;
    this.loadedAvailablePromotions$.next(true);

    this.fetchBanners();
  }

  private handleBanners(banners: PopupBanner[]) {
    if (!this.cachedBanners) {
      this.cachedBanners = [...banners];
    }

    const activeBanner = banners.find(banner => {
      if (this.isBannerInTimeRange(banner.availableFrom, banner.availableUntil)) {
        return banner;
      }
    });

    if (window.location !== window.parent.location) {
      // Don't display banners if in iframe
      return;
    }

    if (!activeBanner || activeBanner.redirectURL === this.router.url || this.displayBanner$.value || this.displayFAB$.value) {
      return;
    }

    if (this.shouldShowBanner(activeBanner)) {
      this.displayBanner(activeBanner);
    } else if (this.shouldShowFAB(activeBanner)) {
      this.displayFAB(activeBanner);
    }
  }

  private isBannerInTimeRange(availableFrom: string, availableUntil: string): boolean {
    const now = new Date().getTime();
    return now > new Date(availableFrom).getTime() && now < new Date(availableUntil).getTime();
  }

  private dismissBanner(banner: PopupBanner): void {
    localStorage.setItem(`popup-banner-dismissed-${banner.id}`, `${new Date().getTime()}`);
    this.displayBanner$.next(undefined);
  }

  private displayBanner(banner: PopupBanner): void {
    this.displayBanner$.next(banner);
  }

  private displayFAB(banner: PopupBanner): void {
    if (banner.fabImage) {
      this.displayFAB$.next(banner);
    }
  }

  private shouldShowBanner(banner: PopupBanner): boolean {
    if (!this.availablePromotions || this.availablePromotions.length === 0) {
      return false;
    }

    const dismissedAt = localStorage.getItem(`popup-banner-dismissed-${banner.id}`);

    if (!dismissedAt) {
      this.resetFAB(banner);
      return true;
    }

    if (isSameDay(new Date(+dismissedAt), new Date())) {
      return false;
    }

    if (Math.abs(differenceInHours(new Date(+dismissedAt), new Date())) < 8) {
      this.resetFAB(banner);
      return false;
    }

    this.resetFAB(banner);
    return true;
  }

  private shouldShowFAB(banner: PopupBanner): boolean {
    if (!this.availablePromotions || this.availablePromotions.length === 0) {
      return false;
    }
    const dismissedAt = localStorage.getItem(`popup-banner-fab-dismissed-${banner.id}`);
    return !dismissedAt;
  }

  private resetFAB(banner: PopupBanner): void {
    localStorage.removeItem(`popup-banner-fab-dismissed-${banner.id}`);
  }
}
