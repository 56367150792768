import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { ID } from '@datorama/akita';
import { Subject } from 'rxjs';

import { map, takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MyBetsService } from 'src/app/core/services/my-bets.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CashoutQuery } from 'src/app/core/state/cashout/cashout.query';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { EvaluationQuery } from 'src/app/core/state/evaluation/evaluation.query';
import { MyBetsQuery } from 'src/app/core/state/my-bets/my-bets.query';
import { expandCollapse } from 'src/app/shared/animations';
import { BetFinalState, CouponStatus, RecentBetModel } from 'src/app/shared/models/my-bets.model';
import { isSameDay, isToday } from 'date-fns';

@Component({
  selector: 'app-recent-bets',
  templateUrl: './recent-bets.component.html',
  styleUrls: ['./recent-bets.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RecentBetsComponent implements OnInit, OnDestroy {
  @Input() bets: RecentBetModel[];
  @Input() loading: boolean;
  @Input() isSettled: boolean = false;
  @Input() firstTimeLoading?: boolean;
  @Input() displayEvaluationBets?: boolean;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();

  couponStatus: typeof CouponStatus = CouponStatus;
  betFinalState: typeof BetFinalState = BetFinalState;
  selectedCouponStatus: BetFinalState = BetFinalState.Won;
  evaluationCouponCode: string[] = [];
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public cashoutStore: CashoutStore,
    public myBetsQuery: MyBetsQuery,
    public evaluationQuery: EvaluationQuery,
    public accountQuery: AccountQuery,
    private readonly myBetsService: MyBetsService,
    private readonly cashoutQuery: CashoutQuery,
    private readonly router: Router,
    private readonly applicationService: ApplicationService,
    private readonly languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.evaluationQuery.evaluationList$.pipe(takeUntil(this.destroy$)).subscribe(val => {
      for (const item of val) {
        if (item.coupon.CouponCode) {
          this.evaluationCouponCode.push(item.coupon.CouponCode);
        }
      }
    });
    this.myBetsQuery.selectedSettledStatus$.pipe(takeUntil(this.destroy$)).subscribe(status => {
      this.selectedCouponStatus = status;
    });
  }

  getBetDetails(item: RecentBetModel): void {
    const cashoutRequested = this.cashoutQuery.isInPendingList(undefined, item.couponCode);

    this.cashoutStore.cashoutRequested(cashoutRequested);

    if (!item.betDetails) {
      this.myBetsService.getBetDetails(item, this.languageService.selectedLanguage.language).subscribe();
    } else {
      this.myBetsService.toggleCollapsed(item);
    }
  }

  onRefresh(): void {
    this.refresh.emit(true);
  }
  isBetNotInEvaluation(bet: RecentBetModel) {
    return this.evaluationCouponCode.indexOf(bet.couponCode) === -1;
  }
  navigateToBetDetails(bet: RecentBetModel): void {
    let couponCode = bet.couponCode;

    if (bet.userId !== this.accountQuery.userData.id.toString()) {
      couponCode = [couponCode, bet.userId].join(',');
    }

    const url = `/account/bet-search/coupon-details/${couponCode}`;
    this.navigateToRoute(url);
  }

  navigateToRoute(route: string): void {
    if (route) {
      this.router.navigate([route]);
      this.applicationService.closeAnySlideUps();
    }
  }
  couponCheckOrDetailsPage(): boolean {
    return window.location.pathname.includes('coupon-check') || window.location.pathname.includes('coupon-details');
  }

  // selectCouponStatus(status: BetFinalState): void {
  //   this.myBetsService.setSelectedCouponStatus(status);
  // }

  myBetsTrackBy(index: number, item: RecentBetModel): ID {
    return item.couponCode;
  }

  indexTrackBy(index: string): string {
    return index;
  }

  isNotSameDay(bet1: RecentBetModel, bet2: RecentBetModel): boolean {
    return !isSameDay(new Date(bet1.couponDate), new Date(bet2.couponDate));
  }

  isBetToday(date: string): boolean {
    return isToday(new Date(date));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
