export enum ButtonType {
  Primary,
  Secondary,
  Accent,
  Brand,
  Success,
  Danger,
  Warn
}

export class ButtonModel {
  text: string;
  faIcon: string;
  buttonType: ButtonType = ButtonType.Primary;
  type: string;
  loading: boolean;

  constructor(init: Partial<ButtonModel>) {
    Object.assign(this, init);
  }

  get hasIcon(): boolean {
    // icon identifier must start with 'fa-' to be valid
    return (this.faIcon && this.faIcon.startsWith('fa-')) || this.loading;
  }

  get icon(): string {
    return this.loading ? 'fa-spinner fa-pulse' : this.faIcon;
  }

  get buttonClass(): string {
    switch (this.buttonType) {
      case ButtonType.Success:
        return 'btn-success';
      case ButtonType.Danger:
        return 'btn-danger';
      case ButtonType.Warn:
        return 'btn-warning';
      case ButtonType.Accent:
        return 'btn-accent';
        case ButtonType.Brand:
        return 'btn-brand';
      case ButtonType.Secondary:
        return 'btn-secondary';
      default:
        return 'btn-primary';
    }
  }
}
