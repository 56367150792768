import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
@Injectable()
export class RestrictRolesGuard implements CanActivate {
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly notificationService: NotificationService,

  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    try {
      if (!this.accountQuery.isAuthenticated) {
        throw new Error();
      }

      const restrictedRoles = route.data && route.data.restrictedRoles;
      if (Array.isArray(restrictedRoles)) {
        if (restrictedRoles.includes(this.accountQuery.userData.userTypeCode)) {
          this.notificationService.showInfoNotification(translate('Feature not available'));
          this.router.navigate(['/account']);
          return false;
        }
      }

      return true;
    } catch {
      this.router.navigate(['/']);
      return false;
    }
  }
}
