import { Injectable } from '@angular/core';

import { SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject } from 'rxjs';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { enGB, et, srLatn } from 'date-fns/locale';
import { LoggerService } from 'src/app/core/services/logger.service';
import { LogTypeModel } from 'src/app/shared/models/log.model';
import { LanguageModel } from './../../shared/models/application.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  enableLanguageSelection$: BehaviorSubject<boolean>;
  selectedLanguage$: BehaviorSubject<LanguageModel>;

  private readonly LANGUAGE_STORAGE_KEY = 'siteLanguage';

  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly sessionStorage: SessionStorageService,
    private readonly loggerService: LoggerService
  ) {
    this.enableLanguageSelection$ = new BehaviorSubject<boolean>(false);
    this.selectedLanguage$ = new BehaviorSubject<LanguageModel>(undefined);
  }

  initialize(): void {
    this.enableLanguageSelection$.next(this.getLanguages().length > 1);
    const currentSiteLang = this.getLanguageFromCookie() || this.getLanguageFromSession() || this.getDefaultLanguage();
    this.setLanguage(currentSiteLang);
  }

  get selectedLanguage(): LanguageModel {
    return this.selectedLanguage$.getValue();
  }

  getLanguages(): LanguageModel[] {
    const languages = this.appConfigService.get('languages');
    if (languages && Array.isArray(languages)) {
      return languages;
    }
    return [];
  }

  saveLanguageSelection(language: string): void {
    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie = `${this.LANGUAGE_STORAGE_KEY}=${language};expires=${d.toUTCString()};path=/`;

    this.sessionStorage.store(this.LANGUAGE_STORAGE_KEY, language);

    this.setLanguage(language);
  }

  private getDefaultLanguage(): string {
    const languages = this.getLanguages();
    if (languages.length > 0) {
      return languages[0].language;
    }
    return 'en';
  }

  private setLanguage(language: string): void {
    const selectedLanguage = this.appConfigService.get('languages').find((l: LanguageModel) => l.language === language);
    if (selectedLanguage) {
      switch(selectedLanguage.dateLocale) {
        case 'srLatn':
          selectedLanguage.dateLocale = srLatn;
        break;
        case 'et':
          selectedLanguage.dateLocale = et;
        break;
        default:
          selectedLanguage.dateLocale = enGB;
        break;
      }

      this.selectedLanguage$.next(selectedLanguage);
    }
  }

  private getLanguageFromSession(): string {
    return this.sessionStorage.retrieve(this.LANGUAGE_STORAGE_KEY);
  }

  private getLanguageFromCookie(): string {
    try {
      const name = `${this.LANGUAGE_STORAGE_KEY}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let c of ca) {
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }

      return '';
    } catch (err) {
      // This should not happen, apart from in SSR when using Domino.
      this.loggerService.logEvent(
        'Could not retrieve language from document.cookie',
        err.message,
        SeverityLevel.Warning,
        LogTypeModel.Application
      );
      return '';
    }
  }
}
