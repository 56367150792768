<ng-container
  *ngIf="{
    paymentDetails: accountPaymentQuery.paymentDetails$ | async
  } as subscriptions"
>
  <div *ngIf="subscriptions.paymentDetails" [@fadeIn] class="deposit-wrapper" id="deposit-wrapper">
    <div *ngIf="subscriptions.paymentDetails.paymentLogoUrl">
      <img
        [@fadeIn]
        alt="payment-image"
        class="payment-logo"
        onerror="this.src='assets/images/payments/noImage.svg'"
        src="{{ subscriptions.paymentDetails.paymentLogoUrl }}"
      />
    </div>
    <app-card [titleHeaderText]="subscriptions.paymentDetails.paymentTitle">
      <div body>
        <ng-container>
          <div [innerHTML]="subscriptions.paymentDetails.paymentShortDesc" class="info-note top"></div>
          <form [formGroup]="paymentForm">
            <div class="user-labels-container">
              <span class="label plus" transloco="Email:"></span>
              <span class="value plus">{{ email }}</span>
            </div>

            <div class="user-labels-container">
              <span class="label plus" transloco="Currency:"></span>
              <span class="value plus">{{ accountQuery.userData.currency.symbol }}</span>
            </div>

            <div class="user-labels-container">
              <span class="label plus" transloco="Fees:"></span>
              <span [ngSwitch]="fees" class="value plus">
                <span *ngSwitchCase="'None'" transloco="None"></span>
                <span *ngSwitchCase="'Bank fees may be deducted'" transloco="Bank fees may be deducted"></span>
                <span *ngSwitchDefault>{{ fees }}</span>
              </span>
            </div>

            <div class="user-labels-container">
              <span class="label plus" transloco="Minimum Deposit:"></span>
              <span class="value plus">{{ minAmount | currencyFormat }}</span>
            </div>

            <div class="user-labels-container">
              <span class="label plus" transloco="Account Name:"></span>
              <span class="value plus">{{ brandInfo.companyAccountName }}</span>
            </div>

            <div class="user-labels-container">
              <span class="label plus" transloco="Account Number:"></span>
              <span class="value plus">{{ brandInfo.companyAccountNumber }}</span>
            </div>

            <div class="user-labels-container">
              <span class="label plus" transloco="Payment Purpose:"></span>
              <span class="value plus" transloco="Deposit onto player account"></span>
            </div>

            <div class="user-labels-container border-btm-close">
              <span class="label plus" transloco="Reference Number:"></span>
              <span class="value plus">{{ userId }}</span>
            </div>
          </form>
          <div [innerHTML]="(accountPaymentQuery.paymentDetails$ | async).content | safeHTML" class="info-note"></div>
        </ng-container>
      </div>
    </app-card>
  </div>
</ng-container>
