import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';
import { LocalStorageService } from 'ngx-webstorage';

import {
  BetCashoutModel,
  BetCashoutResponseModel,
  CashoutModel,
  CashoutState
} from 'src/app/shared/models/cashout.model';

function createInitialState(): CashoutState {
  return {
    pendingCashoutList: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cashout' })
export class CashoutStore extends EntityStore<CashoutState, CashoutModel> {
  readonly pendingCashoutListKey: string = 'pendingCashoutList';

  constructor(private readonly localStorage: LocalStorageService) {
    super(createInitialState());

    this.updatePendingCashoutList(this.localStorage.retrieve(this.pendingCashoutListKey));
    this.localStorage.observe(this.pendingCashoutListKey).subscribe(value => {
      this.updatePendingCashoutList(value);
    });
  }

  updatePendingCashoutList(pendingCashoutList: CashoutModel[]): void {
    if (!pendingCashoutList) {
      this.clearPendingCashoutList();
      return;
    }

    this.update({ pendingCashoutList });
    this.localStorage.store(this.pendingCashoutListKey, pendingCashoutList);
  }

  addToPendingList(cashoutId: number, couponCode: string): void {
    this.update(state => {
      const isInPending = state.pendingCashoutList.find(
        i => (cashoutId != undefined && i.cashoutId === cashoutId) || (couponCode != undefined && i.couponCode === couponCode)
      );

      if (isInPending !== undefined) {
        return;
      }

      const pendingCashoutList = [
        ...state.pendingCashoutList,
        new CashoutModel({
          cashoutId: cashoutId,
          couponCode: couponCode
        })
      ];

      this.localStorage.store(this.pendingCashoutListKey, pendingCashoutList);
      return { pendingCashoutList };
    });
  }

  removeFromPendingList(cashoutId: number): void {
    this.update(state => {
      const pendingCashoutList = state.pendingCashoutList.filter(o => o.cashoutId !== cashoutId);
      this.localStorage.store(this.pendingCashoutListKey, pendingCashoutList);
      return { pendingCashoutList };
    });
  }

  clearPendingCashoutList(): void {
    this.update({ pendingCashoutList: [] });
    this.localStorage.store(this.pendingCashoutListKey, []);
  }

  addCashoutData(cashout: CashoutModel): void {
    this.add(cashout);
  }

  updateBetCashoutData(betCashout: BetCashoutModel): void {
    if (betCashout) {
      this.updateActive(() => ({
        betCashout
      }));
    }
  }

  updateCashoutResponseData(cashoutResponse: BetCashoutResponseModel): void {
    if (cashoutResponse) {
      this.updateActive(() => ({
        cashoutResponse
      }));
    }
  }

  cashoutInProgress(value: boolean): void {
    this.updateActive(() => ({
      cashoutInProgress: value
    }));
  }

  cashoutRequested(value: boolean): void {
    this.updateActive(() => ({
      cashoutRequested: value
    }));
  }

  clear(): void {
    this.update(createInitialState());
  }
}
