import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  colorBrand = '#ee5c25'; // $color-brand
  colorBrandDark = '#0e1416'; // $color-brand-dark
  colorSuccess = '#60d283'; // $color-success
  colorWarning = '#ffbc16'; // $color-warning
  colorDanger = '#ff423d'; // $color-danger
  colorBrandAccent = '#9cba7e'; // $color-brand-accent
  colorTextSeconday = '#fff'; // $color-text-secondary
  colorContainerBackground = '#fff'; // $color-container-background

  screenWidth = 340;
}

export const animationDuration: number = 600;
export const arrowAnimationDuration: Number = 1000;
export const MATCH_END_STATUS = 30;
