<div class="sidebar-box">
  <div *ngIf="isFullImage()">
    <img *ngIf="!getFirstButtonUrl()" [src]="sidebarBox.fullImage.url" [alt]="sidebarBox.fullImage.alt" />

    <a *ngIf="getFirstButtonUrl()" [href]="getFirstButtonUrl()">
      <img [src]="sidebarBox.fullImage.url" [alt]="sidebarBox.fullImage.alt" />
    </a>
  </div>
  <div *ngIf="!isFullImage()" class="sidebar-box-content" [class.with-thumb]="hasThumb()" [class.with-bg]="hasBackground()">
    <img *ngIf="hasBackground()" class="bg" [src]="sidebarBox.background.url" [alt]="sidebarBox.background.alt" />

    <div class="content">
      <img *ngIf="hasThumb()" class="thumb" [src]="sidebarBox.thumb.url" [alt]="sidebarBox.thumb.alt" />
      <div *ngIf="hasTitle() || hasSubtitle()" class="text">
        <div class="title" *ngIf="hasTitle()">{{ sidebarBox.title }}</div>
        <div class="subtitle" *ngIf="hasSubtitle()">{{ sidebarBox.subtitle }}</div>
      </div>
    </div>

    <div class="buttons">
      <ng-container *ngFor="let button of sidebarBox.buttons">
        <a
          class="sidebar-link"
          [class.primary-link]="button.style === 'primary_link'"
          [class.secondary-link]="button.style === 'secondary_link'"
          [class.tertiary-link]="button.style === 'tertiary_link'"
          [href]="button.url"
        >
          <span>{{ button.text }}</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.20938 14.7698C6.92228 14.4713 6.93159 13.9965 7.23017 13.7094L11.1679 10L7.23017 6.29062C6.93159 6.00353 6.92228 5.52875 7.20938 5.23017C7.49647 4.93159 7.97125 4.92228 8.26983 5.20937L12.7698 9.45937C12.9169 9.60078 13 9.79599 13 10C13 10.204 12.9169 10.3992 12.7698 10.5406L8.26983 14.7906C7.97125 15.0777 7.49647 15.0684 7.20938 14.7698Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </ng-container>
    </div>
  </div>
</div>
