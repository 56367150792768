<app-breadcrumb faIcon="fa-pencil-square-o" [title]="'Payment Details' | transloco">
</app-breadcrumb>

<div class="change-bank-account-number-container">
  <form [formGroup]="changeBankAccountNumberForm" class="change-bank-account-number-form">
    <app-card [titleHeaderText]="'Payment Details' | transloco">
      <div class="change-bank-account-number-card-container" body>
        <!-- Bank Account Number -->
        <div class="form-item bank-account-number">
          <label transloco="Bank Account Number"></label>
          <div class="form-input">
            <input type="text" #bankAccountNumber [placeholder]="'xxx' | transloco" minlength="3" maxlength="3" data-elem="1"
              formControlName="bankAccountNumberPart1" (keyup)="onKey($event)">
            <span>-</span>
            <input type="text" #bankAccountNumber [placeholder]="'xxxxxxxxxxxxx' | transloco" minlength="1" maxlength="13" data-elem="2"
              formControlName="bankAccountNumberPart2" (keyup)="onKey($event)" (blur)="addZeros_blur($event)">
            <span>-</span>
            <input type="text" #bankAccountNumber [placeholder]="'xx' | transloco" minlength="2" maxlength="2" data-elem="3"
              formControlName="bankAccountNumberPart3" (keyup)="onKey($event)">
          </div>
          <app-form-message type="danger" *ngIf="changeBankAccountNumberForm.controls['bankAccountNumberPart1'].touched
            && changeBankAccountNumberForm.controls['bankAccountNumberPart1'].errors?.required" [text]="'This field is required' | transloco">
          </app-form-message>
          <app-form-message type="danger" *ngIf="changeBankAccountNumberForm.controls['bankAccountNumberPart1'].touched
              && changeBankAccountNumberForm.controls['bankAccountNumberPart1'].errors?.pattern" [text]="'This field can only contain numbers' | transloco">
          </app-form-message>
          <app-form-message type="danger" *ngIf="changeBankAccountNumberForm.controls['bankAccountNumberPart1'].touched
              && changeBankAccountNumberForm.controls['bankAccountNumberPart1'].errors?.maxlength" [text]="'Bank Account Number must be 18 digits long' | transloco">
          </app-form-message>

          <app-form-message type="danger" *ngIf="changeBankAccountNumberForm.controls['bankAccountNumberPart1'].valid
              && changeBankAccountNumberForm.controls['bankAccountNumberPart2'].touched
              && changeBankAccountNumberForm.controls['bankAccountNumberPart2'].errors?.pattern" [text]="'This field can only contain numbers' | transloco">
          </app-form-message>
          <app-form-message type="danger" *ngIf="changeBankAccountNumberForm.controls['bankAccountNumberPart1'].valid
              && changeBankAccountNumberForm.controls['bankAccountNumberPart2'].touched
              && changeBankAccountNumberForm.controls['bankAccountNumberPart2'].errors?.maxlength" [text]="'Bank Account Number must be 18 digits long' | transloco">
          </app-form-message>

          <app-form-message type="danger" *ngIf="changeBankAccountNumberForm.controls['bankAccountNumberPart1'].valid
              && changeBankAccountNumberForm.controls['bankAccountNumberPart2'].valid
              && changeBankAccountNumberForm.controls['bankAccountNumberPart3'].touched
              && changeBankAccountNumberForm.controls['bankAccountNumberPart3'].errors?.pattern" [text]="'This field can only contain numbers' | transloco">
          </app-form-message>
          <app-form-message type="danger" *ngIf="changeBankAccountNumberForm.controls['bankAccountNumberPart1'].valid
              && changeBankAccountNumberForm.controls['bankAccountNumberPart2'].valid
              && changeBankAccountNumberForm.controls['bankAccountNumberPart3'].touched
              && changeBankAccountNumberForm.controls['bankAccountNumberPart3'].errors?.maxlength" [text]="'Bank Account Number must be 18 digits long' | transloco">
          </app-form-message>
        </div>
      </div>
      <div class="edit-profile-card-container no-shadow" body>
        <div class="buttons">
          <div>
            <app-button [disabled]="changeBankAccountNumberForm.invalid || changeBankAccountNumberForm.pristine" [text]="'Update' | transloco"
              (btnClick)="onSubmit()" [buttonType]="buttonType.Brand"></app-button>
          </div>
        </div>
      </div>

      <div class="edit-profile-card-container" *ngIf="accountService.getKYCDocumentsStatus() !== 'verified'" body>
        <!-- Upload Documents -->
        <app-upload-documents [page]="'withdrawal'" class="upload-documents"></app-upload-documents>
      </div>
    </app-card>
  </form>
</div>

<app-contact-support></app-contact-support>


<ng-template #loading>
  <app-loading-bar [show]="true"></app-loading-bar>
</ng-template>
