import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';

import {
  ApplicationState,
  CmsModel,
  ConfigUIModel,
  LanguageModel,
  LoaderState,
  ProductType
} from 'src/app/shared/models/application.model';
import { MenuItemModel, MenuTab, MenuTabModel } from 'src/app/shared/models/menu.model';

export function createInitialApplicationState(): ApplicationState {
  return {
    ui: {
      configUI: {
        landing: {
          headerType: 'center'
        },
        live: {
          showLiveBadge: true
        },
        footer: {
          footerShowPaymentMethodImage: true,
          showAwardIcons: true,
          showBrandLogo: false
        }
      },
      showMenu: false,
      showAccountMenu: false,
      showNavBar: true,
      showLogin: false,
      showCoupon: false,
      showQuickCoupon: false,
      showMyBets: false,
      showDropdownOverlay: false,
      showVirtualsBetslip: false,
      isZohoLiveChatLoading: false,
      loadingSFKChat: false,
      loginState: 'Login',
      loadingQuicklinks: false,
      landingMenuExpanded: false,
      showSFKChat: false,
      scrollY: 0,
      loader: {
        show: false,
        noDelay: false
      }
    },
    sideBarQuickLinks: [],
    sideBarMenu: [
      {
        title: 'A-Z Sports',
        type: MenuTab.SportsAZ
      },
      {
        title: 'Quicklinks',
        type: MenuTab.QuickLinks
      },
      {
        title: 'Promos',
        type: MenuTab.Promos
      },
      {
        title: 'How to Play',
        type: MenuTab.HowToPlay
      }

    ],
    activeUrl: undefined,
    siteMessages: {
      general: [],
      sports: []
    },
    cms: {
      cacheTTL: undefined,
      fallbackTimer: 10,
      lastQuicklinksUpdate: undefined,
      lastSportsBannerRotatorUpdate: undefined,
      lastPaymentsBannerRotatorUpdate: undefined,
      lastLandingPageMenuItemsUpdate: undefined,
      lastSidebarQuicklinksUpdate: undefined
    },
    selectedLanguage: undefined,
    activeProduct: ProductType.SportsBook
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application' })
export class ApplicationStore extends Store<ApplicationState> {
  constructor() {
    super(createInitialApplicationState());
  }

  updateUI(ui: Partial<ApplicationState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

  updateCms(cms: Partial<CmsModel>): void {
    this.update(state => ({
      cms: {
        ...state.cms,
        ...cms
      }
    }));
  }

  updateSiteMessages(siteMessages: Partial<ApplicationState['siteMessages']>): void {
    this.update(state => ({
      siteMessages: {
        ...state.siteMessages,
        ...siteMessages
      }
    }));
  }

  updateLoader(loader: Partial<LoaderState>): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        loader: {
          ...state.ui.loader,
          ...loader
        }
      }
    }));
  }

  closeSlideUp(type: string): void {
    const ui = {};
    ui[type] = false;
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

  closeAnySlideUps(): void {
    const ui = createInitialApplicationState().ui;
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

  updateSidebarQuickLinks(sideBarQuickLinks: MenuItemModel[]): void {
    this.update({ sideBarQuickLinks });
  }

  updateSideBarMenu(sideBarMenu: MenuTabModel[]): void {
    this.update({ sideBarMenu });
  }

  updateActiveUrl(activeUrl: string[]): void {
    this.update({ activeUrl });
  }

  updateSelectedLanguage(selectedLanguage: LanguageModel): void {
    this.update({ selectedLanguage });
  }

  updateConfigUI(configUI: ConfigUIModel): void {
    this.update(state => ({
      ...state,
      ui: {
        ...state.ui,
        configUI
      }
    }));
  }
}
