<div class="system">
  <ng-container *ngFor="let group of groups; trackBy: indexTrackBy; let isLast = last">
    <div
      class="system-group"
      [class.active]="group.Selected"
      [hidden]="!(group | isGroupingVisible : isLast : couponQuery.groupingsTabSelected)"
      (click)="groupSelector($event, group)"
      *ngIf="group.Combinations > 0"
    >
      <span>{{ group.Grouping - (numberOfFixedOdds$ | async) }}/{{ numberOfNotFixedOdds$ | async }}</span>
    </div>
  </ng-container>
</div>
