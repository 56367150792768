/* tslint:disable */
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PayoutPromoService } from 'src/app/core/services/payout-promo/payout-promo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payments-list-promo',
  templateUrl: './payments-list-promo.component.html',
  styleUrls: ['./payments-list-promo.component.scss']
})
export class PaymentsListPromoComponent implements OnInit, OnDestroy {
  promoIframeUrl$ = new ReplaySubject<SafeResourceUrl | null>(null);
  hasPromoIframeUrl$ = this.promoIframeUrl$.pipe(map(url => !!url));
  promotionsEngineBaseUrl: string;
  constructor(
    private readonly route: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly payoutPromoService: PayoutPromoService,
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService
  ) {
    this.promotionsEngineBaseUrl = environment.appConfigDefaults.apiBaseUrl.promotionsEngine;
  }
  ngOnInit(): void {
    this.setIframeUrl();
    window.addEventListener(
      'message',
      event => {
        console.log('got msg 2233', event);
        if (event.data.type === 'promotion-details') {
          window.location.href = 'lp/kes-na-vreme?btag=lp_kešnavreme_';
          return;
        }
        if (event.data.type === 'share') {
          this.sharePayoutPromo();
          return;
        }
      },
      false
    );
  }
  ngOnDestroy(): void {}
  private async setIframeUrl() {
    const transactionId = this.route.snapshot.paramMap.get('transactionId');
    const transactionIdInt = parseInt(transactionId, 10);
    let sh = '';
    if (!isNaN(transactionIdInt)) {
      const shareData = await this.payoutPromoService.getShareData(transactionIdInt);
      sh = shareData.hash;
    }
    const iframeBaseUrl = this.appConfig.get('bombBonusUrl');
    this.promoIframeUrl$.next(this.sanitizer.bypassSecurityTrustResourceUrl(`${iframeBaseUrl}payout-promo/${transactionId}/?sh=${sh}`));
  }
  private async getShareData(transactionId: number): Promise<{ transactionId: number; hash: string }> {
    return this.http
      .get(`${this.promotionsEngineBaseUrl}/payout/share-transaction/${transactionId}`)
      .pipe(
        first(),
        map((response: { transactionId: number; hash: string }) => ({
          transactionId: response.transactionId,
          hash: response.hash
        }))
      )
      .toPromise();
  }
  private async sharePayoutPromo() {
    const transactionId = this.route.snapshot.paramMap.get('transactionId');
    const shareResult = await this.getShareData(parseInt(transactionId));
    const url = `${window.location.origin}/account/withdrawal/promotion/shared/${shareResult.transactionId}?sh=${shareResult.hash}`;
    const anyWindow = window as any;
    const sharePayload = {
      url: url,
      title: 'Keš na vreme'
    };
    if (anyWindow?.navigator?.canShare?.(sharePayload)) {
      window.navigator
        .share(sharePayload)
        .then(() => {
          // this.notificationService.showSuccess(translate('account.share.success'));
          console.log('share success');
        })
        .catch(() => {
          console.log('share error');
          // this.notificationService.showError(translate('account.share.error'));
        });
    } else {
      window.navigator.clipboard.writeText(`Keš na vreme, na Victoryju! ${url}`).then(() => {
        this.notificationService.showSuccessMessage('Link ka tvojoj isplati je kopiran. Podeli sa ekipom!', 3000);
      });
    }
  }
}
