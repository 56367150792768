import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { ChatPopupState } from '../../../shared/models/chat-popup';

function createInitialState(): ChatPopupState {
  return {
    visible : false,
    suspend: false,
    suspendAfterClose: false,
    label: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'chatPopup' })
export class ChatPopupStore extends Store<ChatPopupState> {
  constructor() {
    super(createInitialState());
  }

  updateChatPopup(state: Partial<ChatPopupState>): void {
    this.update(currentState => ({ ...currentState, ...state }));
  }

}
