import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { APIType } from 'src/app/shared/models/api.model';

import { AnalyticsEventAction } from '../../shared/models/analytics.model';
import { ChatPopupOptions } from '../../shared/models/chat-popup';
import { ChatPopupQuery } from '../state/chat-popup/chat-popup.query';
import { ChatPopupStore } from '../state/chat-popup/chat-popup.store';
import { AnalyticsService } from './analytics/analytics.service';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';
import { IdleService } from './idle.service';

@Injectable({
  providedIn: 'root'
})
export class ChatPopupService {

  formSubmitSubscriber: Subscription;
  errorSubscriber: Subscription;

  isFormSubmitted = false;
  dynamicSuggestionEnabled = false;

  constructor(
    private store: ChatPopupStore,
    private query: ChatPopupQuery,
    private idleService: IdleService,
    private analyticsService: AnalyticsService,
    private appConfig: AppConfigService,
    private router: Router,
    private apiService: APIService
  ) {
  }

  initialize(): void {
    const chatConfig = this.appConfig.get('chat');

    if (chatConfig.enabled) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const showChatOnIdle = !!chatConfig.routes.onIdle.find(item => event.url.includes(item));
          const showChatOnError = !!chatConfig.routes.onError.find(item => event.url.includes(item));

          if (showChatOnIdle) {
            this.observeFormSubmit();
            this.openOnIdle({ idleDelay: chatConfig.idleDelay });
            setTimeout(() => {
              if (!this.isFormSubmitted && this.dynamicSuggestionEnabled) {
                this.open();
              }
            }, chatConfig.simpleDelay * 1000);
          }
          if (showChatOnError) {
            this.openOnError({ errorDelay: chatConfig.errorDelay });
          }
          this.dynamicSuggestionEnabled = showChatOnError || showChatOnIdle;
        }
        if ((event instanceof NavigationStart) && this.dynamicSuggestionEnabled) {
          this.disableDynamicSuggestion();
          this.errorSubscriber.unsubscribe()
          this.formSubmitSubscriber.unsubscribe()
        }
      });
    }
  }
  getPopupLabel(): Observable<any> {
    let label = '';
    return this.apiService.get(APIType.CMS, 'account').pipe(
      first(),
      map(responseData => {
        if (responseData) {
          label = responseData.ChatPopupLabel;
        }

        this.store.updateChatPopup({label});
      }),
      catchError((err: HttpErrorResponse) => {
        this.store.setError(err);
        this.store.updateChatPopup({label});
        return throwError(err);
      })
    );
  }
  disableDynamicSuggestion(): void {
    this.idleService.stopTimer();
    this.isFormSubmitted = false;
    this.unsuspend();
    this.suspendAfterClose();
    this.dynamicSuggestionEnabled = false;
  }

  suspend(): void {
    this.store.updateChatPopup({ suspend: true });
  }

  unsuspend(): void {
    this.store.updateChatPopup({ suspend: false });
  }

  open(): void {
    if (!this.query.suspend) {
      this.store.updateChatPopup({ visible: true });
    }
  }

  openOnIdle(options: ChatPopupOptions): void {
    this.idleService.initialize({ idleDuration: options.idleDelay });
    this.idleService.userIdlenessChecker.subscribe((status: string) => {
      if (status === 'STOPPED_TIMER' && !this.query.visible) {
        this.open();
      }
    });
  }

  openOnError(options: ChatPopupOptions): void {
    let errorCount = 0;
    this.errorSubscriber = this.analyticsService.sendEvent$.subscribe(res => {
      const isError = res.data.Action === AnalyticsEventAction.Error;
      errorCount = isError ? errorCount + 1 : errorCount;
      if (isError && !this.query.visible && errorCount > options.errorDelay) {
        this.open();
      }
    });
  }

  observeFormSubmit(): void {
    this.formSubmitSubscriber = this.analyticsService.sendEvent$.subscribe(res => {
      if (res.data.Action === AnalyticsEventAction.FormSubmit) {
        this.isFormSubmitted = true;
      }
    });
  }

  close(): void {
    if (this.query.suspendAfterClose) {
      this.suspend();
    }
    this.store.updateChatPopup({ visible: false });
  }

  suspendAfterClose(): void {
    this.store.updateChatPopup({ suspendAfterClose: true });
  }

  dontSuspendAfterClose(): void {
    this.store.updateChatPopup({ suspendAfterClose: false });
  }
}
