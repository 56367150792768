import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { SearchState } from 'src/app/shared/models/search.model';

function createInitialState(): SearchState {
  return {
    searchResults: undefined,
    searchKeyword: undefined,
    ui: {
      noSearchResultsFor: undefined,
      lastBadgeClicked: undefined,
      searchIsLoading: false
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'search' })
export class SearchStore extends Store<SearchState> {
  constructor() {
    super(createInitialState());
  }

  updateSearchResults(searchResults: any, searchKeyword: string): void {
    this.update({
      searchResults,
      searchKeyword
    });
  }

  updateUI(ui: Partial<SearchState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }
}
