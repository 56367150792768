<div class="selection-container">
  <ng-container *ngFor="let selection of couponQuery.selections$ | async; trackBy: selectionTrackBy">
    <!-- <div class="sport-name" *ngIf="(couponQuery.selections$ | async).length > 1 && selection.name !== 'Unknown'">
      <div class="sport-icon icon-{{ selection.id }}"></div>
      <span>{{ selection.name }}</span>
    </div> -->

    <ng-container *ngFor="let category of selection.categories">
      <ng-container *ngFor="let tournament of category.tournaments">
        <!-- <div class="tournament-name">
          <span>{{ category.name }} - {{ tournament.name}}</span>
        </div> -->
        <ng-container *ngFor="let match of tournament.matches; trackBy: matchTrackBy">
          <div class="selection-content">
            <div class="event-details-container">
              <div class="event-details">
                <div class="match-name">
                  <div *ngIf="selection.name !== 'Unknown'" class="sport-icon icon-{{ selection.id }}"></div>
                  <ng-container *ngIf="match.name">
                    <span>{{ match.name.split(' - ')[0] }}<br />{{ match.name.split(' - ')[1] }}</span>
                  </ng-container>
                </div>
                <div class="event-date">
                  <svg
                    *ngIf="match.eventCategory === 'L'"
                    class="live"
                    fill="none"
                    height="12"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect height="11" rx="1.5" stroke="#95B475" width="15" x=".5" y=".5" />
                    <path
                      d="M6.666 3.765v4.47c0 .34.286.547.506.362l2.675-2.235c.203-.168.203-.556 0-.729l-2.675-2.23c-.22-.186-.506.021-.506.362Z"
                      fill="#95B475"
                    />
                    <rect height="4.333" rx=".5" stroke="#95B475" width="2.333" x=".5" y="3.833" />
                    <rect height="4.333" rx=".5" stroke="#95B475" width="2.333" x="13.166" y="3.833" />
                  </svg>
                  <!-- <span class="smart-code" *ngIf="match.eventCategory !== 'L'">{{ match.smartBetCode }} |</span> -->
                  <div *ngIf="match.date" class="event-time">
                    <span class="event-time">{{ match.date | formatCustomDateTime }}</span>
                    <span class="event-time time"> {{ match.date | formatDate : 'HH:mm' }}</span>
                  </div>
                </div>
              </div>

              <button
                class="banker cursor-pointer"
                [class.not-visible]="
                  (couponQuery.groupingsTabSelected$ | async) !== groupingType.Combination || (numberOfMatchesInCoupon$ | async) < 4
                "
                [disabled]="match.isBankerDisabled"
                [class.selected]="match.fixed"
                [class.locked]="!match.allowFixed"
                (click)="updateOddBankerStatus(match.odds[0].id, !match.fixed)"
              >
                <span *ngIf="match.allowFixed" transloco="F"></span>
                <i class="fa fa-lock" *ngIf="!match.allowFixed" aria-hidden="true"></i>
              </button>
            </div>
            <div class="market-odds-container">
              <ng-container *ngFor="let marketOddMap of couponQuery.selectionMarketMatches$ | async; trackBy: marketOddMapTrackBy">
                <ng-container *ngFor="let matchId of marketOddMap.matchIds">
                  <ng-container *ngIf="matchId === match.id">
                    <ng-container *ngFor="let market of tournament.regions[0].areas[0].markets">
                      <div class="markets" *ngIf="marketOddMap.marketId === market.id">
                        <div class="market-details">
                          <div></div>
                          <!-- <div *ngIf="!(!bookedCoupon && !(match.eventCategory === 'L'))"></div>
                          <div
                            class="edit-odd"
                            *ngIf="!bookedCoupon && !(match.eventCategory === 'L')"
                            analytics
                            [click]="true"
                            [category]="'Edit Match Button'"
                            (click)="editOdd(match.smartBetCode, market.typeId, market.spreadValue)"
                            [class.selected]="
                              (couponQuery.couponforEdit$ | async)?.matchId === match.smartBetCode &&
                              (couponQuery.couponforEdit$ | async)?.marketTypeId === market.typeId
                            "
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
                              <path
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M11.646 2.232a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .707l-6 6a.5.5 0 0 1-.354.147H6a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .146-.354l6-6ZM6.5 8.793v1.293h1.293l5.5-5.5L12 3.293l-5.5 5.5Z"
                                clip-rule="evenodd"
                              />
                              <path
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M10.146 3.732a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.707l-2-2a.5.5 0 0 1 0-.707Z"
                                clip-rule="evenodd"
                              />
                              <path
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M2.293 2.879A1 1 0 0 1 3 2.586h5.5a.5.5 0 0 1 0 1H3v10h10v-5.5a.5.5 0 0 1 1 0v5.5a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-10a1 1 0 0 1 .293-.707Z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </div> -->
                          <div class="market-name">
                            <span>{{ market.name }}</span>
                          </div>
                        </div>
                        <div class="selection-details">
                          <ng-container *ngFor="let odd of match.odds; trackBy: oddTrackBy">
                            <ng-container *ngIf="market.id === odd.marketId">
                              <div class="odd-details-row">
                                <div class="odd-details" [ngClass]="hasOddChanges$ | async | oddChanged : odd.id">
                                  <div class="market-selection">
                                    <span *ngIf="odd?.selectionName === '+'">{{ 'Over' | transloco }}</span>
                                    <span *ngIf="odd?.selectionName === '-'">{{ 'Under' | transloco }}</span>
                                    <span *ngIf="odd?.selectionName !== '+' && odd?.selectionName !== '-'">{{
                                      getSelectionName(odd)
                                    }}</span>
                                    <!-- <span *ngIf="odd">{{ odd | json }}</span> -->
                                  </div>
                                  <div *ngIf="!(isLocked$(odd) | async) && odd.value >= 1" class="odd-value">
                                    {{ odd.value | number : '1.2-2' }}
                                  </div>
                                  <div *ngIf="(isLocked$(odd) | async) || odd.value < 1" class="odd-value">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                                      <path
                                        fill="#fff"
                                        d="M5.5 22c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 4 20.5V9.65c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h1.75v-2.4c0-1.314.463-2.434 1.39-3.36C9.567 1.462 10.688 1 12.003 1s2.434.463 3.36 1.39c.925.926 1.387 2.046 1.387 3.36v2.4h1.75c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06V20.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-13Zm0-1.5h13V9.65h-13V20.5Zm6.504-3.5c.53 0 .984-.184 1.359-.55.375-.368.562-.81.562-1.325 0-.5-.189-.954-.567-1.363-.378-.408-.832-.612-1.362-.612-.53 0-.984.204-1.359.612-.375.409-.562.867-.562 1.375 0 .509.189.946.567 1.313.378.367.832.55 1.362.55ZM8.75 8.15h6.5v-2.4c0-.903-.316-1.67-.947-2.302-.631-.632-1.398-.948-2.3-.948a3.14 3.14 0 0 0-2.303.948 3.13 3.13 0 0 0-.95 2.302v2.4Z"
                                      />
                                    </svg>
                                  </div>
                                  <!-- <div class="odd-value">DJOKA</div> -->
                                </div>
                                <div
                                  class="close-odd"
                                  *ngIf="!bookedCoupon"
                                  (click)="removeOdd(odd.id)"
                                  transloco="✕"
                                  analytics
                                  [click]="true"
                                  [category]="'Remove Match Button'"
                                ></div>
                              </div>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div
                        class="edit-odd-container"
                        [class.expanded]="
                          (couponQuery.couponforEdit$ | async)?.matchId === match.smartBetCode &&
                          (couponQuery.couponforEdit$ | async)?.marketTypeId === market.typeId
                        "
                        [@expandCollapse]="{
                          value:
                            (couponQuery.couponforEdit$ | async)?.matchId === match.smartBetCode &&
                            (couponQuery.couponforEdit$ | async)?.marketTypeId === market.typeId
                              ? 'expanded'
                              : 'collapsed',
                          params: { minHeight: '0px' }
                        }"
                        *ngIf="marketOddMap.marketId === market.id && !bookedCoupon"
                      >
                        <ng-container
                          *ngIf="
                            (couponQuery.editCouponData$ | async)?.smartBetCode == match.smartBetCode &&
                            ((couponQuery.editCouponData$ | async)?.odds[0]?.marketTypeId === market.typeId ||
                              (couponQuery.editCouponData$ | async)?.correctScoreOdds?.homeToWin[0]?.marketTypeId === market.typeId)
                          "
                        >
                          <div class="edit-odds">
                            <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.correctScoreOdds !== undefined">
                              <!-- Correct score -->
                              <app-correct-score-event [showDetails]="false" [event]="couponQuery.editCouponData$ | async">
                              </app-correct-score-event>
                            </ng-container>
                            <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.odds.length > 0">
                              <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.odds.length < 4">
                                <!-- Single-line -->
                                <div class="events-markets-header">
                                  <app-events-markets-header
                                    [markets]="(couponQuery.editCouponSelectionHeaderData$ | async)?.selections"
                                    [separatorText]="
                                      (couponQuery.editCouponData$ | async)?.odds.length === 1 ? 'Obriši selekciju?' : 'Menjaš selekciju?'
                                    "
                                  >
                                  </app-events-markets-header>
                                </div>
                                <div class="odds-container">
                                  <div class="single-line">
                                    <app-single-line-event
                                      [showDetails]="false"
                                      [showBorder]="false"
                                      [editing]="true"
                                      [event]="couponQuery.editCouponData$ | async"
                                    >
                                    </app-single-line-event>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="(couponQuery.editCouponData$ | async)?.odds.length >= 4">
                                <!-- Multi-line -->
                                <div class="odds-container">
                                  <div class="multi-line">
                                    <span class="multi-line-selection-message" transloco="Make new selection?"></span>
                                    <app-multi-line-event
                                      [showDetails]="false"
                                      [selectedMarket]="couponQuery.editCouponSelectionHeaderData$ | async"
                                      [event]="couponQuery.editCouponData$ | async"
                                    >
                                    </app-multi-line-event>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                          <div
                            class="market-closed"
                            *ngIf="
                              (couponQuery.editCouponData$ | async)?.odds.length === 0 &&
                              (couponQuery.editCouponData$ | async)?.correctScoreOdds === undefined
                            "
                          >
                            <div class="outright-message" *ngIf="(couponQuery.editCouponData$ | async).isOutright">
                              <ng-container *ngIf="couponQuery.editCouponData$ | async as data">
                                <span transloco="Outright selections can't be modified in the coupon. Please"></span>
                                <a (click)="goToPage(data.id, data.name)" transloco="navigate"></a>
                                <span transloco="to the outright page to modify your selections."></span>
                              </ng-container>
                            </div>
                            <span
                              *ngIf="(couponQuery.editCouponData$ | async).isOutright === false"
                              transloco="Selections are no longer available"
                            >
                            </span>
                          </div>
                          <div class="edit-buttons" *ngIf="couponQuery.editCouponData$ | async as data">
                            <div (click)="clearEditData()" transloco="close"></div>
                            <div class="go-to-match" (click)="goToPage(data.id, data.name)" transloco="Go To Match"></div>
                          </div>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            (couponQuery.editCouponData$ | async)?.isOutright && (couponQuery.editCouponData$ | async)?.odds.length === 0
                          "
                        >
                          <div class="outright-not-available" transloco="Coupon editing is not available for outright markets"></div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
