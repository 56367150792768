<div class="menu-wrapper" (click)="close()">
  <div class="side-menu" (click)="$event.stopPropagation()">
    <!--
      *
      *   Header
      *
    -->
    <header>
      <div (click)="close()" class="close-button cursor-pointer">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.6">
            <path d="M11.9963 7.99585L8.00293 11.9892" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.9974 11.9916L8.00073 7.99414" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.612 2.29199H6.38783C3.87033 2.29199 2.29199 4.07449 2.29199 6.59699V13.4037C2.29199 15.9262 3.86283 17.7087 6.38783 17.7087H13.6112C16.137 17.7087 17.7087 15.9262 17.7087 13.4037V6.59699C17.7087 4.07449 16.137 2.29199 13.612 2.29199Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
        <span transloco="close"></span>
      </div>
    </header>

    <!--
      *
      *   Top
      *
    -->
    <div class="top-section-picker">
      <h2>{{ 'Transactions' | transloco }}</h2>
      <div class="scroll-left-button" id="scroll-left-button" (click)="scrollLeft()" *ngIf="ifScrolledLeft$ | async">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 20 20" fill="currentColor" class="arrow">
          <path
            fillRule="evenodd"
            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      <div class="scroll-right-button" id="scroll-right-button" (click)="scrollRight()" *ngIf="ifScrolledRight$ | async">
        <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="3 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="scroll-container" (scroll)="isScrollHappened($event)">
        <div class="type-tabs" *ngIf="activeType$ | async as active">
          <div class="tab" [class.active]="active === 'all'" (click)="setType('all')" transloco="All"></div>
          <div class="tab" [class.active]="active === 'bets'" (click)="setType('bets')" transloco="My Bets"></div>
          <div class="tab" [class.active]="active === 'slot'" (click)="setType('slot')" transloco="Slot"></div>
          <div class="tab" [class.active]="active === 'slot-bonus'" (click)="setType('slot-bonus')" transloco="Slot Bonus"></div>
          <div class="tab" [class.active]="active === 'deposit'" (click)="setType('deposit')" transloco="Deposit/ Withdrawal"></div>
        </div>
      </div>

      <div class="date-tabs" *ngIf="activeDate$ | async as active">
        <div class="tab" [class.active]="active === 'today'" (click)="setDate('today')" transloco="Today"></div>
        <div class="tab" [class.active]="active === '3-days'" (click)="setDate('3-days')" transloco="3 days"></div>
        <div class="tab" [class.active]="active === '7-days'" (click)="setDate('7-days')" transloco="7 days"></div>
        <div class="date-picker" [class.active]="active === 'custom-date'" (click)="setDate('custom-date')">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path
              fill="#fff"
              fill-rule="evenodd"
              d="M20.666 9.904H2.842a.75.75 0 0 1 0-1.5h17.824a.75.75 0 0 1 0 1.5ZM16.201 13.81a.754.754 0 0 1-.754-.75c0-.414.331-.75.745-.75h.01a.75.75 0 0 1 0 1.5ZM11.764 13.81a.754.754 0 0 1-.754-.75c0-.414.33-.75.745-.75h.009a.75.75 0 0 1 0 1.5ZM7.317 13.81a.755.755 0 0 1-.755-.75c0-.414.332-.75.746-.75h.009a.75.75 0 0 1 0 1.5ZM16.201 17.696a.754.754 0 0 1-.754-.75c0-.414.331-.75.745-.75h.01a.75.75 0 0 1 0 1.5ZM11.764 17.696a.754.754 0 0 1-.754-.75c0-.414.33-.75.745-.75h.009a.75.75 0 0 1 0 1.5ZM7.317 17.696a.755.755 0 0 1-.755-.75c0-.414.332-.75.746-.75h.009a.75.75 0 0 1 0 1.5ZM15.793 5.791a.75.75 0 0 1-.75-.75v-3.29a.75.75 0 0 1 1.5 0v3.29a.75.75 0 0 1-.75.75ZM7.715 5.791a.75.75 0 0 1-.75-.75v-3.29a.75.75 0 0 1 1.5 0v3.29a.75.75 0 0 1-.75.75Z"
              clip-rule="evenodd"
            />
            <mask id="a" width="20" height="21" x="2" y="2" maskUnits="userSpaceOnUse" style="mask-type: luminance">
              <path fill="#fff" fill-rule="evenodd" d="M2 2.58h19.5V22.5H2V2.58Z" clip-rule="evenodd" />
            </mask>
            <g mask="url(#a)">
              <path
                fill="#fff"
                fill-rule="evenodd"
                d="M7.521 4.08C4.928 4.08 3.5 5.461 3.5 7.972v9.05C3.5 19.587 4.928 21 7.521 21h8.458C18.572 21 20 19.614 20 17.098V7.973c.004-1.235-.328-2.195-.987-2.855-.678-.68-1.723-1.039-3.025-1.039H7.521Zm8.458 18.42H7.521C4.116 22.5 2 20.401 2 17.022V7.973C2 4.645 4.116 2.58 7.521 2.58h8.467c1.709 0 3.122.512 4.087 1.48.937.94 1.43 2.293 1.425 3.916v9.123c0 3.332-2.116 5.402-5.521 5.402Z"
                clip-rule="evenodd"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <ng-container *ngIf="activeDate$ | async as active">
      <form *ngIf="active === 'custom-date' && (openCloseDateFilter$ | async)" class="form" [formGroup]="dateForm">
        <div class="date-from">
          <label transloco="Date From"></label>
          <input type="date" formControlName="dateFrom" [min]="dateFromMinLimit" [max]="dateFromMaxLimit" onkeydown="return false;" />
        </div>
        <div class="date-to">
          <label transloco="Date To"></label>
          <input type="date" formControlName="dateTo" [min]="dateForm.value.dateFrom" [max]="dateToLimit" onkeydown="return false;" />
        </div>
        <button class="search-date" (click)="searchByTimePeriod()">{{ 'Begin search' | transloco }}</button>
      </form>
    </ng-container>

    <div *ngIf="loading$ | async" class="loader-wrapper">
      <div class="loader"></div>
    </div>

    <p *ngIf="!(loading$ | async) && !(transactions$ | async).length" class="no-transactions" transloco="No Transactions"></p>

    <div class="list-of-transactions">
      <ng-container *ngIf="!(loading$ | async)">
        <div class="transaction" *ngFor="let transaction of transactions$ | async; trackBy: transactionsTrackBy">
          <app-transaction-preview [transaction]="transaction"></app-transaction-preview>
        </div>
      </ng-container>
    </div>
  </div>
</div>
