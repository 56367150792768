import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { fadeIn } from 'src/app/shared/animations';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.success.component.html',
  styleUrls: ['./deposit.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DepositSuccessComponent implements OnInit, OnDestroy {
  delimiters: any = [];
  loading: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  paymentId: string = '';

  showExtraDetails: boolean = false;
  extraDetailsTitle: string = '';
  extraDetailsBody: string = '';

  constructor(
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly route: ActivatedRoute,
    public accountPaymentQuery: AccountPaymentQuery,
    public accountQuery: AccountQuery,
    
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.setRedirectPaymentMessage(params);
    });
    this.paymentId = this.route.snapshot.paramMap.get('paymentCodeId');
  }

  setRedirectPaymentMessage(params: any): void {
    if (!window.location.href.split('?')[1]) {
      return;
    } else {
      this.showExtraDetails = true;
    }

    if (!this.paymentId) {
      this.paymentId = window.location.href.split('?')[0].split('/')[window.location.href.split('?')[0].split('/').length - 1];
    }
    if (this.paymentId === 'InterswitchWebpayDirectDeposit') {
      const translationOptions = { transaction: params.txReference };
      this.extraDetailsTitle = translate('Transaction: {{transaction}}', translationOptions);
      this.extraDetailsBody = params.message;
    }
    if (this.paymentId === 'GTPay') {
      const translationOptions = { params: { transaction: params.txStatusMessage } };
      this.extraDetailsTitle = undefined;
      this.extraDetailsBody = translate('Transaction {{transaction}} successful', translationOptions);
    }
    if (this.paymentId === 'Paystack' && params.trxref !== undefined) {
      const translationOptions = { params: { transaction: params.trxref } };
      this.extraDetailsTitle = undefined;
      this.extraDetailsBody = translate('Transaction {{transaction}} successful', translationOptions);
    }
  }

  returnDepositList(): void {
    this.router.navigate(['/account/deposit']);
  }

  returnSportsPage(): void {
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
