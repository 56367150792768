import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CasinoService {
  openGameId: string;
  fullscreen$: Subject<boolean> = new Subject();
  checkPromotion$: Subject<boolean> = new Subject();
}
