import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { LanguageModel } from 'src/app/shared/models/application.model';
import { brandInfo } from 'src/brand-info';

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.scss']
})
export class FooterSectionComponent implements OnInit {
  brandInfo = brandInfo;
  social = this.appConfigService.get('social');
  selectedLanguage: string;
  constructor(
    readonly applicationQuery: ApplicationQuery,
    private readonly appConfigService: AppConfigService,
    private readonly appService: ApplicationService,
    private readonly router: Router,
    private readonly languageService: LanguageService
  ) {}
  ngOnInit(): void {
    this.languageService.selectedLanguage$.subscribe(lang => (this.selectedLanguage = lang.language));
  }
  openLiveChat(): void {}

  navigateTo(input: string): void {
    if (this.selectedLanguage === 'en') {
      if (input === '/help/opsti-uslovi-koriscenja') {
        this.router.navigate(['/help/terms-and-conditions']);
      } else if (input === '/help/odgovorno-kladenje') {
        this.router.navigate(['/help/responsible-gaming']);
      } else if (input === '/help/politika-privatnosti') {
        this.router.navigate(['/help/privacy-policy']);
      } else if (input === '/help/pravila-o-priredivanju-posebnih-igara-na-srecu') {
        this.router.navigate(['/help/rules-on-organizing-games-of-chance']);
      }
    } else {
      this.router.navigate([input]);
    }
  }
}
