import { Injectable } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';
import {
  AreaModel,
  EventSummaryModel,
  PlayerViewModel,
  QuicklinksState,
  RegionModel,
  SportModel,
  SportState
} from 'src/app/shared/models/sport.model';

import { LocalStorageService } from 'ngx-webstorage';

function createInitialState(): SportState {
  return {
    match: undefined,
    selectedPrematch: undefined,
    sportsList: undefined,
    eventSelection: {
      selectedSport: undefined,
      selectedQuicklink: undefined,
      isSportsListOpened: false,
      quickLinksState: QuicklinksState.Pending,
      eventSelectionQuicklinks: undefined,
      topCompetitions: undefined,
      competitionsAZ: undefined,
      allCompetitionByCountry: undefined,
      outrights: undefined,
      specialSports: undefined,
      goalscorerSport: undefined,
      eventSelectionDepth: 1,
      selectedMarket: undefined,
      selectedArea: undefined,
      selectedAreaId: undefined,
      areaMarkets: undefined,
      filterToDepthFour: false,
      areaAndRegionCache: undefined,
      visibleCardsAtDepthFour: undefined,
      selectedIdsAtDepthFour: undefined,
      restoreAreaRegionsCache: false,
      eventSelectionLoaded: false,
      autoForwardHappened: false
    },
    ui: {
      preMatchCloseAll: false,
      filterQuery: ''
    },
    playersData: [],
    isItCorrectScore: undefined,
    areas: undefined,
    regions: undefined,
    selectedPlayerIds: [],
    favouriteSports: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'sport' })
export class SportStore extends Store<SportState> {
  private readonly favouriteSportsKey = 'favouriteSports';

  constructor(private readonly localStorage: LocalStorageService) {
    super(createInitialState());

    this.readFavouritesFromLocalStorage();
  }

  updateSportsList(sportsList: SportModel[]): void {
    this.update(state => ({ sportsList }));
  }

  updateIsItCorrectScore(isItCorrectScore: boolean): void {
    this.update({ isItCorrectScore });
  }

  updatePlayersData(playersData: PlayerViewModel): void {
    const currentData = [...this._value().playersData];
    const index = currentData.findIndex(data => data.tournamentId === playersData.tournamentId);
    if (index > -1) {
      currentData[index] = playersData;
    } else {
      currentData.push(playersData);
    }
    this.update({ playersData: currentData });
  }

  clearPlayersData(): void {
    this.update({ playersData: [] });
  }

  updateSelectedPrematch(selectedPrematch: EventSummaryModel[]): void {
    this.update({ selectedPrematch });
  }

  updateEventSelection(eventSelection: Partial<SportState['eventSelection']>): void {
    this.update(state => ({
      eventSelection: {
        ...state.eventSelection,
        ...eventSelection
      }
    }));
  }

  updateMatch(match: SportModel): void {
    this.update({ match });
  }

  updateAreas(areas: AreaModel[]): void {
    this.update({ areas });
  }

  updateRegions(regions: RegionModel[]): void {
    this.update({ regions });
  }

  updateUI(ui: Partial<SportState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

  updateFavourites(favouriteSports: number[]): void {
    this.localStorage.store(this.favouriteSportsKey, favouriteSports);
    this.update({ favouriteSports });
  }

  private readFavouritesFromLocalStorage(): void {
    const ids = this.localStorage.retrieve(this.favouriteSportsKey);

    if (Array.isArray(ids)) {
      this.updateFavourites(ids);
    }
  }
}
