import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { BookedBetsComponent } from './components/booked-bets/booked-bets.component';
import { MyBetsPageWrapperComponent } from './components/my-bets-page-wrapper/my-bets-page-wrapper.component';
import { RecentBetsComponent } from './components/recent-bets/recent-bets.component';
import { MyBetsRoutingModule } from './my-bets-routing.module';
import { MyBetsComponent } from './my-bets.component';
import { RecentBetComponent } from './components/recent-bet/recent-bet.component';
import { RecentBetDetailsComponent } from './components/recent-bet-details/recent-bet-details.component';
import { MyBetsMenuComponent } from 'src/app/modules/my-bets/components/my-bets-menu/my-bets-menu.component';
import { CouponBetsTabsComponent } from 'src/app/modules/my-bets/components/coupon-bets-tabs/coupon-bets-tabs.component';
import { CouponModule } from 'src/app/modules/coupon/coupon.module';
import { CouponDetailsWrapperComponent } from 'src/app/shared/components/coupon-details-wrapper/coupon-details-wrapper.component';

const COMPONENTS = [
  MyBetsComponent,
  RecentBetsComponent,
  BookedBetsComponent,
  RecentBetComponent,
  MyBetsMenuComponent,
  CouponBetsTabsComponent,
  CouponDetailsWrapperComponent
];

@NgModule({
  imports: [MyBetsRoutingModule, CouponModule, SharedModule],
  declarations: [...COMPONENTS, MyBetsPageWrapperComponent, RecentBetDetailsComponent],
  exports: [...COMPONENTS]
})
export class MyBetsModule {}
