import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { kebabCase } from 'lodash-es';
import { ApplicationService } from 'src/app/core/services/application.service';
import { MatchService } from 'src/app/core/services/match.service';
import { SearchQuery } from 'src/app/core/state/search/search.query';
import { TodaysEventsQuery } from 'src/app/core/state/todays-events/todays-events.query';
import { MatchModel } from 'src/app/shared/models/sport.model';
import { SortType } from 'src/app/shared/models/todays-events.model';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent {
  @Input() event: MatchModel;
  @Input() showDate = true;
  @Input() hasTeamAway = true;
  @Input() specials?: boolean;

  sortType = SortType;

  constructor(
    readonly searchQuery: SearchQuery,
    private readonly router: Router,
    private readonly matchService: MatchService,
    private readonly appService: ApplicationService,
    readonly todaysEventsQuery: TodaysEventsQuery
  ) {}

  navigateToOutright(event: MatchModel): void {
    const sportName = kebabCase(event.sportName);
    const categoryName = kebabCase(event.categoryName);
    const tournamentName = kebabCase(event.tournamentName);
    const url = `/sports/events/outright/${sportName}/${categoryName}/${tournamentName}`;
    this.router.navigateByUrl(url);
  }

  navigateToMatch(): void {
    if (this.specials) {
      return;
    }

    if (this.appService.isDesktop$.value) {
      this.todaysEventsQuery.selectedMatch$.next(this.event);
    } else {
      this.matchService.navigateToMatch(this.event.id, this.event.name);
    }
  }

  get isHomeRoute(): boolean {
    return this.router.url === '/';
  }

  get isSpecialsRoute(): boolean {
    return this.router.url.startsWith('/sports/events/prematch');
  }
}
