<div class="totals-container">
  <div class="stake-options-container" [class.open]="delimitersOpen"
    [@expandCollapse]="delimitersToggle ? 'expanded' : 'collapsed'">
    <div class="delimiters-content">
      <app-button *ngFor="let amount of fixedStakes | slice:0:5; trackBy: indexTrackBy" [allCaps]="false"
        [customButtonStyle]="stakeButtonStyle" [text]="amount" [buttonType]="buttonType.Primary"
        (click)="addStakeValue(amount)"></app-button>
    </div>
    <div class="calculator-content">
      <div class="numpad-wrapper">
        <div class="numpad-container">
          <div *ngFor="let num of calculatorNumbers; trackBy: indexTrackBy" (click)="addNumberValue(num)">{{ num }}
          </div>
        </div>
      </div>
      <div class="delete" (click)="removeNumberValue()">
        <span class="delete-icon"></span>
        {{'Del.' | transloco}}
      </div>
    </div>
  </div>
  <div class="amount-container">

    <div class="stake-amount">
      <span class="label">{{'Stake' | transloco}} ({{ currencyCode }})</span>
      <div class="value">
        <div class="stake" (click)="delimitersOpen = true; delimitersToggle = !delimitersToggle">
          {{ stakeGrossDisplay }}</div>
        <div class="reset" [hidden]="(firstStakePress$ | async) && (firstNumberPress$ | async)"
          (click)="resetStakeValue()">
          <i class="fa fa-times"></i> {{'Clear' | transloco}}
        </div>
      </div>
    </div>

    <div class="details-row">
      <div class="content">
        <div class="label" transloco="Net Potential Winnings"></div>
        <div class="value">{{ couponData.MaxWinNet | currencyFormat }}</div>
      </div>
    </div>
  </div>


</div>
