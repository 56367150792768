<div>
  <div class="event-status" *ngIf="!specials">
    <div>
      <!-- <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_257_2390)">
          <path
            d="M5.995 1C3.235 1 1 3.24 1 6C1 8.76 3.235 11 5.995 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 5.995 1ZM6 10C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2C8.21 2 10 3.79 10 6C10 8.21 8.21 10 6 10ZM6.25 3.5H5.5V6.5L8.125 8.075L8.5 7.46L6.25 6.125V3.5Z"
            fill="#66737C"
          />
        </g>
        <defs>
          <clipPath id="clip0_257_2390">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg> -->

      <div *ngIf="event.date" class="event-time">
        <span class="event-time">{{ event.date | formatDate : 'dd MMM' }},</span>
        <span class="event-time time">{{ event.date | formatDate : 'HH:mm' }}</span>
      </div>
    </div>
  </div>
  <div class="details" (click)="navigateToMatch()">
    <div *ngIf="hasTeamAway" class="event-competitors">
      <div
        [innerHTML]="event.homeTeam | highlight : (searchQuery.searchKeyword$ | async)"
        class="competitor-one"
        [class.no-truncate]="isSpecialsRoute"
      ></div>
      <div
        [innerHTML]="event.awayTeam | highlight : (searchQuery.searchKeyword$ | async)"
        class="competitor-two"
        [class.no-truncate]="isSpecialsRoute"
      ></div>
    </div>
    <div *ngIf="!hasTeamAway" class="tournament-outcome ripple">
      <span [innerHTML]="event.name | highlight : (searchQuery.searchKeyword$ | async)" class="ellipsis"></span>
    </div>
  </div>
</div>
