import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VariablesService } from 'src/app/core/services/variables.service';
import { expandCollapse } from 'src/app/shared/animations';
import { Location } from '@angular/common';

@Component({
  selector: 'app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent implements OnInit {
  @Input() titleHeaderText: string;
  @Input() rightIcon: string;
  @Input() loading: boolean;
  @Input() collapsible = false;
  @Input() leftSportIconId: number = undefined;
  @Input() minFullHeight: boolean;
  @Input() flat: boolean;
  @Input() canCollapse: boolean = true;
  @Input() withBackground: boolean = false;

  @Output() closed: EventEmitter<boolean> = new EventEmitter();

  collapsed = false;

  constructor(public variablesService: VariablesService, private readonly location: Location) {}

  ngOnInit(): void {
    if (!this.rightIcon && this.collapsible) {
      this.rightIcon = 'fa fa-caret-right';
    }
  }

  toggleWidget(): void {
    if (this.collapsible) {
      this.collapsed = !this.collapsed;
    }

    if (this.collapsed) {
      this.closed.emit(true);
    }
  }
}
