import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appResizeOnScroll]'
})
export class ResizeOnScrollDirective implements AfterContentInit, OnDestroy {
  /** The minimum height of the element */
  @Input() minHeight: number;

  /** The scroll to resize ratio */
  @Input() scrollRatio: number = 1 / 7;

  private initialHeight = 0;
  private initialTop = 0;
  private isPinned = false;
  private readonly destroy$ = new Subject<any>();

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly scrollDispatcher: ScrollDispatcher,
    private readonly renderer: Renderer2
  ) {}

  ngAfterContentInit(): void {
    if (!this.minHeight && this.minHeight !== 0) {
      throw new Error('ResizeOnScrollDirective - minHeight not provided!');
    }

    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    this.initialHeight = rect.height;
    this.initialTop = this.elementRef.nativeElement.offsetTop;

    this.scrollDispatcher
      .scrolled(0)
      .pipe(
        map(event => {
          let scrolledElementTop = 0;

          if (event) {
            scrolledElementTop = event.getElementRef().nativeElement.scrollTop;
          } else {
            scrolledElementTop = window.scrollY > this.initialTop ? window.scrollY - this.initialTop : 0;
          }

          const scrollTop = scrolledElementTop * this.scrollRatio;
          const height = Math.max(this.initialHeight - scrollTop, this.minHeight);

          return { height, isSlideUp: Boolean(event) };
        }),
        distinctUntilChanged(({ height }, { height: height2 }) => height === height2),
        takeUntil(this.destroy$)
      )
      .subscribe(({ height, isSlideUp }) => {
        this.setHeight(height);

        if (!isSlideUp) {
          this.setPinned(height < this.initialHeight);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setHeight(height: number): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${height}px`);
  }

  private setPinned(pinned: boolean): void {
    if (pinned !== this.isPinned) {
      this.isPinned = pinned;
      pinned
        ? this.renderer.addClass(this.elementRef.nativeElement, 'pinned-to-top')
        : this.renderer.removeClass(this.elementRef.nativeElement, 'pinned-to-top');
    }
  }
}
