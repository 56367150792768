import { Injectable } from '@angular/core';
import { applyTransaction, ID, Store, StoreConfig } from '@datorama/akita';
import { MyAllBetsStore } from 'src/app/core/state/my-bets/my-all-bets.store';
import { MyAllOpenBetsStore } from 'src/app/core/state/my-bets/my-all-open-bets.store';
import { MyOpenBetsStore } from 'src/app/core/state/my-bets/my-open-bets.store';
import { MySettledBetsStore } from 'src/app/core/state/my-bets/my-settled-bets.store';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';
import { BetDetailsModel, BetsTab, MyBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';

function createInitialState(): MyBetsState {
  return { selectedTab: BetsTab.All };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-bets' })
export class MyBetsStore extends Store<any> {
  constructor(
    private readonly myOpenBetsStore: MyOpenBetsStore,
    private readonly mySettledBetsStore: MySettledBetsStore,
    private readonly myAllBetsStore: MyAllBetsStore,
    private readonly myAllOpenBetsStore: MyAllOpenBetsStore
  ) {
    super(createInitialState());
  }

  setAllBets(myBetsData: RecentBetModel[]): void {
    this.myAllBetsStore.set(myBetsData);
  }

  setOpenBets(myBetsData: RecentBetModel[]): void {
    this.myOpenBetsStore.set(myBetsData);
  }
  setAllOpenBets(myBetsData: RecentBetModel[]): void {
    this.myAllOpenBetsStore.set(myBetsData);
  }
  setOpenLoading(loading: boolean = true): void {
    this.myOpenBetsStore.setLoading(loading);
  }
  setAllOpenLoading(loading: boolean = true): void {
    this.myAllOpenBetsStore.setLoading(loading);
  }
  setAllBetsLoading(loading: boolean = true): void {
    this.myAllBetsStore.setLoading(loading);
  }

  setSettledBets(myBetsData: RecentBetModel[]): void {
    this.mySettledBetsStore.set(myBetsData);
  }

  addLostBets(myBetsData: RecentBetModel[]): void {
    applyTransaction(() => {
      this.mySettledBetsStore.setLoading(false);
      this.mySettledBetsStore.update({ lostBetsLoaded: true });
      this.mySettledBetsStore.set(myBetsData);
    });
  }

  setSettledLoading(loading: boolean = true): void {
    this.mySettledBetsStore.setLoading(loading);
  }

  addNewBet(couponCode: string): void {
    // this.myOpenBetsStore.upsert(couponCode, new RecentBetModel({ couponCode }));
    this.myAllOpenBetsStore.upsert(couponCode, new RecentBetModel({ couponCode }));
  }

  removeOpenBet(couponCode: string): void {
    this.myOpenBetsStore.removeByCouponCode(couponCode);
    this.myAllOpenBetsStore.removeByCouponCode(couponCode);
  }

  updateBetDetails(id: ID, betDetails: BetDetailsModel, betFinalState: BetFinalState): void {
    const store = this.getStoreFromBetFinalState(betFinalState);

    store.setActive(id);
    this.myAllBetsStore.setActive(id);

    if (betDetails) {
      store.updateActive(() => ({ betDetails }));
      this.myAllBetsStore.updateActive(() => ({ betDetails }));
    }
  }

  setActive(bet: RecentBetModel): any {
    const store = this.getStoreFromBetFinalState(bet.betFinalState);

    store.setActive(bet.id);
    this.myAllBetsStore.setActive(bet.id);

    return store;
  }

  toggleCollapsed(bet: RecentBetModel): void {
    const store = this.setActive(bet);

    this.myAllBetsStore.toggleCollapsed();
    store.toggleCollapsed();
  }

  setSelectedCouponStatus(status: BetFinalState): void {
    this.mySettledBetsStore.setSelectedCouponStatus(status);
  }

  clearOpenBets(): void {
    this.myOpenBetsStore.clear();
    this.myAllOpenBetsStore.clear();
  }

  clearSettledBets(): void {
    this.mySettledBetsStore.clear();
  }

  clear(): void {
    this.myAllOpenBetsStore.clear();
    this.myOpenBetsStore.clear();
    this.mySettledBetsStore.clear();
  }

  changeTab(selectedTab: BetsTab): void {
    this.update({ selectedTab });
  }

  private getStoreFromBetFinalState(betFinalState: BetFinalState): MyOpenBetsStore | MySettledBetsStore | MyAllBetsStore {
    return betFinalState === BetFinalState.Placed ? this.myOpenBetsStore : this.mySettledBetsStore;
  }
}
