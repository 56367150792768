<div class="confirm-book-bet-container" *ngIf="bookedBetData">
  <div class="warning-label">
    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    <span transloco="An event in your booked coupon has expired."></span>
  </div>
  <div class="container split">
    <div class="content">
      <div class="label" transloco="Events requested:"></div>
      <div class="value">{{ bookedBetData.originalEventCount }}</div>
    </div>
    <div class="content">
      <div class="label" transloco="Events still available:"></div>
      <div class="value">{{ bookedBetData.availableEventCount }}</div>
    </div>
  </div>
  <div class="container events-expired">
    <div class="title" transloco="The following events are unavailable:"></div>
    <div class="content" *ngFor="let eventName of bookedBetData.removedEvents; trackBy: indexTrackBy">
      <div class="value">{{ eventName }}</div>
    </div>
  </div>

  <div class="confirm-title" transloco="Would you like to continue?"></div>

  <div class="buttons">
    <app-button text="Cancel" [buttonType]="buttonType.Danger" (click)="cancel.emit()"></app-button>
    <app-button text="Proceed" [buttonType]="buttonType.Success" (click)="confirm.emit()"></app-button>
  </div>
</div>
