import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { VictoryAnalyticsService } from 'src/app/core/services/analytics/victory-analytics.service';
import { BottomSheetService } from 'src/app/core/services/bottom-sheet.service';
import { PwaService } from 'src/app/core/services/pwa.service';
import { BottomSheetQuery } from 'src/app/core/state/bottom-sheet/bottom-sheet.query';
import { AnalyticsEventAction } from '../../models/analytics.model';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit, OnDestroy {
  closing$: Subject<boolean> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly pwaService: PwaService,
    private readonly analyticsService: AnalyticsService,
    public bottomSheetQuery: BottomSheetQuery,
    public bottomSheetService: BottomSheetService
  ) {}

  ngOnInit(): void {
    this.closeOnRouteChange();
    document.body.classList.add('scroll-disabled');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('scroll-disabled');
  }

  close(): void {
    this.reportCloseInstallPWA();
    this.closing$.next(true);

    setTimeout(() => {
      this.bottomSheetService.close();
    }, 300);
  }

  installPWA(): void {
    this.pwaService.openPrompt$.next(true);
    this.closing$.next(true);

    setTimeout(() => {
      this.bottomSheetService.close();
    }, 300);
  }

  private reportCloseInstallPWA(): void {
    this.bottomSheetQuery.state$
      .pipe(first())
      .subscribe(state =>
          state.installPWA &&
          this.analyticsService.sendEvent({
            Action: AnalyticsEventAction.Click,
            Category: 'Close Install PWA'
          }
      ));
  }

  private closeOnRouteChange(): void {
    this.router.events.pipe(first()).subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.close();
      }
    });
  }
}
