import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { webSocket } from 'rxjs/webSocket';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { getBrowser, getDeviceType, getMobileOS } from 'src/app/core/utils/device';
import { SessionDto, VictoryAnalyticsEventDTO } from 'src/app/shared/models/analytics.model';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class VictoryAnalyticsService {
  sessionID: string;
  connectionID: string;

  sessionCreated$: Subject<boolean> = new ReplaySubject();

  private readonly sessionStorageKey = 'analytics_ws_session';
  private readonly browserUIDStorageKey = 'analytics_browser_uid';
  private websocket$: Subject<any> = new Subject();

  constructor(private readonly accountQuery: AccountQuery, private readonly route: ActivatedRoute) {}

  init(): void {
    this.sessionID = localStorage.getItem(this.sessionStorageKey);

    this.websocket$ = webSocket(this.wsURL);
    this.websocket$.subscribe(this.handleWsMessage.bind(this));
  }

  sendEvent(data: VictoryAnalyticsEventDTO): void {
    if (!this.connectionID || !this.sessionID) {
      this.retrySendEvent(data);
      return;
    }

    this.sendWsMessage({
      event: 'report',
      data: {
        ...data,
        ConnectionID: this.connectionID,
        SessionID: this.sessionID
      }
    });
  }

  addUserDataToSession(userID: string, username: string): void {
    if (!this.sessionID) {
      return;
    }

    const data = {
      SessionID: this.sessionID,
      UserID: userID,
      Username: username
    };

    this.sendWsMessage({ event: 'addUserToSession', data });
  }

  private retrySendEvent(data: VictoryAnalyticsEventDTO): void {
    setTimeout(() => {
      this.sendEvent(data);
    }, 1000);
  }

  private sendWsMessage(data: any): void {
    this.websocket$.next(data);
  }

  private handleWsMessage(message: any): void {
    if (message.event === 'connected') {
      this.handleConnection(message.connectionID);
    } else if (message.event === 'session') {
      this.handleSession(message.sessionID);
    }
  }

  private handleConnection(connectionID: string): void {
    if (!connectionID) {
      return;
    }

    this.connectionID = connectionID;
    this.initSession();
  }

  private handleSession(sessionID: string): void {
    if (!sessionID) {
      return;
    }

    this.sessionID = sessionID;
    this.sessionCreated$.next(true);
    localStorage.setItem(this.sessionStorageKey, sessionID);
  }

  private initSession(): void {
    const data: SessionDto = { ConnectionID: this.connectionID };

    this.handleSessionID(data);
    this.handleSessionUserData(data);
    this.handleSessionDevice(data);
    this.handleSessionReferral(data);

    this.sendWsMessage({ event: 'session', data });
  }

  private handleSessionID(data: SessionDto): void {
    if (this.sessionID) {
      data.ID = this.sessionID;
    }
  }

  private handleSessionUserData(data: SessionDto): void {
    if (!this.accountQuery.userData) {
      return;
    }

    if (this.accountQuery.userData.id) {
      data.UserID = `${this.accountQuery.userData.id}`;
    }

    if (this.accountQuery.userData.username) {
      data.Username = this.accountQuery.userData.username;
    }
  }

  private handleSessionDevice(data: SessionDto): void {
    if (!navigator.userAgent) {
      return;
    }

    data.UserAgent = navigator.userAgent;
    data.BrowserUID = this.getBrowserUID();
    data.Browser = getBrowser();
    data.MobileOS = getMobileOS();
    data.DeviceType = getDeviceType();
  }

  private handleSessionReferral(data: SessionDto): void {
    if (document.referrer) {
      data.Referrer = document.referrer;
    }

    const utmSource = this.route.snapshot.queryParamMap.get('utm_source');
    const utmCampaign = this.route.snapshot.queryParamMap.get('utm_campaign');

    if (utmSource && utmCampaign) {
      data.UTM = `${utmCampaign} / ${utmSource}`;
    }
  }

  private getBrowserUID(): string {
    const browserUID = localStorage.getItem(this.browserUIDStorageKey);
    if (browserUID) {
      return browserUID;
    }

    const newBrowserUID = uuidv4();
    localStorage.setItem(this.browserUIDStorageKey, newBrowserUID);
    return newBrowserUID;
  }

  private get wsURL(): string {
    return environment.appConfigDefaults.apiBaseUrl.analyticsWS;
  }
}
