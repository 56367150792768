import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { ToastNotificationStore } from 'src/app/core/state/toast-notifications/toast-notifications.store';
import { ToastNotification, ToastNotificationState } from 'src/app/shared/models/notification.model';
import { AppConfigService } from './../../services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationQuery extends QueryEntity<ToastNotificationState, ToastNotification> {
  protected limit: number = this.appConfigService.get("maximumNumberOfToasts");

  list$ = this.selectAll({
    limitTo: this.limit
  });

  constructor(
    protected store: ToastNotificationStore,
    private readonly appConfigService: AppConfigService
  ) {
    super(store);
  }
}
