import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BookBetService {
  constructor(
    private readonly apiService: APIService,
    
    private readonly notificationService: NotificationService,
    private readonly couponService: CouponService,
    private readonly applicationService: ApplicationService
  ) {}

  getBookBet(bookCode: string, language: string = 'en'): Observable<BookBetModel> {
    if (!bookCode) {
      return;
    }

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });

    return this.apiService.get<any>(APIType.Sportsbook, `api/BetCoupons/Booked/${bookCode}/${language}`, apiSettings).pipe(
      first(),
      map(responseData => {
        if (!responseData) {
          return;
        }

        const removedEvents: string[] = [];

        if (responseData.RemovedEvents) {
          responseData.RemovedEvents.forEach(eventName => {
            removedEvents.push(eventName);
          });
        }

        const hasExpiredEvents = responseData.ResponseStatus === 404 && responseData.BookedCoupon !== null;

        const bookBetData = new BookBetModel({
          responseStatus: responseData.ResponseStatus,
          bookCode: bookCode,
          availableEventCount: responseData.AvailableEventCount,
          bookedCoupon: responseData.BookedCoupon,
          originalEventCount: responseData.OriginalEventCount,
          removedEvents: removedEvents,
          hasExpiredEvents
        });

        return bookBetData;
      }),
      tap(this.handleBookBetResponse)
    );
  }

  loadBookedCoupon(res: BookBetModel): void {
    if (!res.bookedCoupon) {
      return;
    }

    this.couponService.updateCoupon(res.bookedCoupon);
    this.applicationService.showCoupon();
    this.notificationService.showSuccessNotification(
      translate('Successfully loaded booked Coupon: {{bookCode}}', { bookCode: res.bookCode })
    );
  }

  private readonly handleBookBetResponse = (res: BookBetModel): void => {
    if (res.responseStatus === 1 && res.bookedCoupon !== null) {
      // all events are not expired
      this.loadBookedCoupon(res);
    } else if (res.responseStatus === 405) {
      // all events are expired
      this.notificationService.showErrorNotification(translate('Booking code is no longer available. All events are expired.'));
    } else if (!res.hasExpiredEvents) {
      this.notificationService.showErrorNotification(translate('Invalid Code!'));
    }
  };
}
