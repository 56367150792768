// Documentation for the Today's events module: https://confluence.betagy.com/confluence/display/FT/Today%27s+Events

import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, first, map, tap } from 'rxjs/operators';

import { ActivatedRoute } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { orderBy, trim } from 'lodash-es';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MatchService } from 'src/app/core/services/match.service';
import { SportService } from 'src/app/core/services/sport.service';
import { ResolverQuery } from 'src/app/core/state/resolver/resolver.query';
import { TodaysEventsQuery } from 'src/app/core/state/todays-events/todays-events.query';
import { TodaysEventsStore } from 'src/app/core/state/todays-events/todays-events.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { CategoryTournamentsFilter } from 'src/app/shared/models/category-tournaments-filter';
import { AreaModel, MatchModel, RegionModel, SelectionModel } from 'src/app/shared/models/sport.model';
import {
  AreaMarketModel,
  AreaMatchModel,
  BettingShortcut,
  BettingShortcutGroup,
  DayModel,
  MarketGroup,
  MarketGroupOffer,
  OddsTypeModel,
  SortType,
  SportModel,
  TodaysEventsUIState
} from 'src/app/shared/models/todays-events.model';
import { environment } from 'src/environments/environment';
import { APIService } from './api.service';
import { CorrectScoreEventService } from './correct-score-event.service';

interface SportMarketGroup {
  [sportId: number]: MarketGroup;
}

@Injectable({
  providedIn: 'root'
})
export class TodaysEventsService implements OnDestroy {
  parameterSignature: string;
  sportChangeInProgress = false;
  firstRun = true;
  firstParse = true;
  initialSelectedSport: SportModel;
  initialSelectedDay: DayModel;
  destroy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  correctScoreAreaIds = this.appConfig.get('correctScoreAreaIds');
  sportMarketGroupSelected: SportMarketGroup = {};
  marketGroupSelected$: BehaviorSubject<MarketGroup | undefined> = new BehaviorSubject(undefined);
  searchActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fetchEvents$: Subject<boolean> = new Subject();
  wrapperScrollY: Subject<number> = new Subject();
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  marketGroupPickerOpened$: Subject<boolean> = new Subject();
  datePickerOpened$: Subject<boolean> = new Subject();
  selectedSportId: number;

  numberOfLeaguesToLoad = 8;
  byTournamentPage$: BehaviorSubject<number> = new BehaviorSubject(this.numberOfLeaguesToLoad);
  byTimePage$: BehaviorSubject<number> = new BehaviorSubject(this.numberOfLeaguesToLoad);
  numberOfEventsToLoad = 20;
  fetchingEvents = false;
  forcingToday = false;
  firstEventsFetch = true;

  activeSidebarTab$: BehaviorSubject<'coupon' | 'my-bets'> = new BehaviorSubject('coupon');
  bettingShortcutGroups$: BehaviorSubject<BettingShortcutGroup[]> = new BehaviorSubject(undefined);
  prevSelectedTimeFrame: DayModel[] = [];

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly todaysEventsStore: TodaysEventsStore,
    private readonly todaysEventsQuery: TodaysEventsQuery,
    private readonly sportService: SportService,
    private readonly route: ActivatedRoute,
    private readonly correctScoreEventService: CorrectScoreEventService,
    private readonly resolverQuery: ResolverQuery,
    private readonly matchService: MatchService
  ) {
    this.fetchEvents$.subscribe(() => {
      if (!this.todaysEventsQuery.selectedDay) {
        return;
      }

      this.handleGetEvents()
        .pipe(first())
        .subscribe(() => {
          this.loading$.next(false);
        });
    });

    this.todaysEventsQuery.selectedDay$.subscribe(() => {
      if (!this.todaysEventsQuery.selectedDay) {
        return;
      }

      if (this.forcingToday && !this.firstRun) {
        return;
      }

      this.handleGetEvents()
        .pipe(first())
        .subscribe(() => {
          this.loading$.next(false);
        });
    });

    this.todaysEventsQuery.selectedSport$.subscribe(selectedSport => {
      if (!this.todaysEventsQuery.selectedDay || this.firstRun || this.firstEventsFetch) {
        return;
      }

      this.handleGetEvents()
        .pipe(first())
        .subscribe(() => {
          this.todaysEventsStore.updateSelectedTournamentName(translate('All'));
          this.sportChangeInProgress = true;
          this.loading$.next(false);
          this.forcingToday = false;
        });
    });

    this.marketGroupSelected$.subscribe(marketGroup => {
      if (!marketGroup || !this.todaysEventsQuery.selectedDay || this.firstRun || this.firstEventsFetch) {
        return;
      }

      this.handleGetEvents()
        .pipe(first())
        .subscribe(() => {
          this.loading$.next(false);
        });
    });
  }

  getEvents(
    language: string = 'en',
    dayModel: DayModel = this.todaysEventsQuery.selectedDay,
    areaId: number = 0,
    regionId: number = 0,
    sportId: number = 1,
    updateRegionArea?: boolean,
    isItFullReset?: boolean,
    skipIt?: number,
    take?: number
  ): Observable<any> {
    this.updateUI({ isEventsLoading: true });
    this.loading$.next(true);
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });

    const leaguesSelection = this.route.snapshot.queryParams.selection;

    let url = `api/feeds/prematch/GetPagedEventsTrim/${language}`;

    const sortBy = 1;

    if (dayModel?.date) {
      url = `${url}/${dayModel.date}/${areaId}/${regionId}/${sportId}`;
    } else if (dayModel?.timeFrame) {
      url = `${url}/${dayModel.timeFrame}/${sortBy}/${areaId}/${regionId}/${sportId}`;
    } else {
      url = `${url}/${1}/${sortBy}/${areaId}/${regionId}/${sportId}`;
    }

    if (skipIt !== undefined && take !== undefined) {
      url = `${url}/${skipIt}/${take}`;
    }

    if (isItFullReset) {
      this.firstParse = true;
      this.firstRun = true;
      this.sportChangeInProgress = true;
      this.updateSelectedSport(this.initialSelectedSport);
      this.updateSelectedDay(this.initialSelectedDay);
    }

    let apiCall: Observable<any>;

    if (leaguesSelection) {
      apiCall = this.sportService
        .getPrematchEventData(
          language,
          dayModel.timeFrame,
          (leaguesSelection as string).split('-').map(selection => selection.split(',')[2]),
          areaId,
          regionId
        )
        .pipe(
          map(allData => {
            const mergedLeagueItems = allData.map(data => [...(data.AreaMatches[0]?.Items ?? [])]);
            const items = [].concat.apply([], mergedLeagueItems);
            const newData = allData[0];
            if (newData?.AreaMatches?.length) {
              newData.AreaMatches[0].Items = items;
            }

            return newData;
          })
        );
    } else {
      apiCall = this.apiService.get(APIType.SportsbookFeed, url, apiSettings);
    }

    return apiCall.pipe(
      first(),
      tap(response => {
        this.firstRun = false;
        this.parseEvents(response, updateRegionArea);
      }),
      finalize(() => {
        this.updateUI({ isEventsLoading: false });
        if (this.todaysEventsQuery.firstTimeLoadIsInProgress) {
          this.todaysEventsStore.updateFirstTimeLoadIsInProgress(false);
        }
      })
    );
  }

  updateUI(uiUpdate: Partial<TodaysEventsUIState>): void {
    this.todaysEventsStore.updateUI(uiUpdate);
  }

  updateSelectedRegion(selectedRegion: { region: RegionModel; areas: AreaModel[] }): void {
    this.todaysEventsStore.updateSelectedRegion(selectedRegion);
  }

  updateSelectedArea(selectedArea: AreaModel): void {
    this.todaysEventsStore.updateSelectedArea(selectedArea);
  }

  updateSortType(sortType: SortType): void {
    this.todaysEventsStore.updateSortType(sortType);
  }

  parseEvents(response: any, updateRegionArea: boolean = false): void {
    const sports = [];
    response.Sports?.forEach(sport => {
      const newSport = new SportModel({
        sportId: sport.SportID,
        sportName: sport.SportName
      });
      sports.push(newSport);
    });
    this.updateSports(sports);

    let areaMatches = [];

    if (!response.AreaMatches.length) {
      this.updateAreaMatches(undefined);
      this.updateSelectedMarket(undefined);
      this.todaysEventsStore.updateTournamentNames(undefined);
      this.todaysEventsStore.updateMatchesByTournament(undefined);
      this.todaysEventsStore.updateRegionsWithAreas(undefined);
    }

    response.AreaMatches.forEach(areaMatch => {
      const newAreaMatch: AreaMatchModel = {
        area: new AreaModel({ id: areaMatch.Area.AreaID, name: areaMatch.Area.AreaName, order: areaMatch.Area.AreaOrder }),
        areaMarkets: [],
        areaMarketsType: areaMatch.AreaMarketsType,
        containsOnlyOutright: areaMatch.ContainsOnlyOutright,
        groupingType: areaMatch?.GroupingType,
        items: [],
        region: areaMatch.Region
          ? new RegionModel({
              id: areaMatch.Region.RegionID,
              name: areaMatch.Region.RegionName,
              order: areaMatch.Region.RegionOrder
            })
          : undefined,
        sportId: areaMatch.SportID,
        sportName: areaMatch.SportName
      };
      areaMatch.AreaMarkets.forEach(areaMarket => {
        const newAreaMarket = new AreaMarketModel({
          groupNo: areaMarket.GroupNo,
          selections: [],
          oddsType: new OddsTypeModel({
            idGroup: areaMarket.OddsType.idGroup,
            idGroupMarketType: areaMarket.OddsType.IDGroupMarketType,
            isGoalScorer: areaMarket.OddsType.IsGoalScorer,
            isOutright: areaMarket.OddsType.IsOutright,
            multilineType: areaMarket.OddsType.MultilineType,
            oddsDescription: areaMarket.OddsType.OddsDescription,
            oddsTypeId: areaMarket.OddsType.OddsTypeID,
            marketName: areaMarket.OddsType.OddsTypeName,
            oddsTypeOrder: areaMarket.OddsType.OddsTypeOrder,
            onlyRegularTime: areaMarket.OddsType.OnlyRegularTime,
            templateType: areaMarket.OddsType.TemplateType
          }),
          specialBetValue: areaMarket.SpecialBetValue,
          specialCount: areaMarket.SpecialCount,
          specialValueDisplay: areaMarket.SpecialValueDisplay
        });
        areaMarket.Markets.forEach(market => {
          const newMarket = new SelectionModel({
            id: market.OddAttribute.OddTypeID,
            name: market.OddAttribute.OddName,
            order: market.OddAttribute.Order,
            spreadValue: market.OddAttribute.SpecialValue,
            spreadDisplayValue: market.OddAttribute.SpecialValueDisplay
          });
          newAreaMarket.selections.push(newMarket);
        });
        newAreaMatch.areaMarkets.push(newAreaMarket);
      });

      areaMatch.Items.forEach(item => {
        const newItem = this.sportService.mapMatchDataToModel(item, areaMatch.SportID, areaMatch.SportName);
        newAreaMatch.items.push(newItem);
      });
      areaMatches = [...areaMatches, newAreaMatch];

      this.updateAreaMatches(areaMatches);
      this.updateSelectedMarket(areaMatches[0].areaMarkets[0]);

      // const categoriesAndTournaments: { [categoryName: string]: { categoryName: string; tournament }  } = {}
      const categoryTournamentsFilter: CategoryTournamentsFilter[] = [];
      const addedCategoryFilter: { [categoryId: string]: boolean } = {};

      const flags = {};
      const tournaments = [];
      for (const item of areaMatches[0].items) {
        if (flags[trim(item.tournamentName)]) {
          continue;
        }

        flags[trim(item.tournamentName)] = true;
        tournaments.push({
          tournamentName: trim(item.tournamentName),
          tournamentOrder: item.tournamentOrder,
          categoryOrder: item.categoryOrder,
          categoryName: item.categoryName
        });

        if (addedCategoryFilter[item.categoryId]) {
          const categoryIdx = categoryTournamentsFilter.findIndex(c => c.category.id === item.categoryId);
          if (categoryIdx > -1) {
            categoryTournamentsFilter[categoryIdx].tournaments.push({
              id: item.tournamentId,
              name: item.tournamentName,
              order: item.tournamentOrder
            });
          }
        } else {
          const cachedFilter = this.todaysEventsQuery.categoryTournamentsFilter$.value.find(value => value.category.id === item.categoryId);

          categoryTournamentsFilter.push({
            category: {
              id: item.categoryId,
              name: item.categoryName,
              order: item.categoryOrder,
              expanded: cachedFilter?.category.expanded ?? false,
              selected: cachedFilter?.category.selected ?? false
            },
            tournaments: [
              {
                id: item.tournamentId,
                name: item.tournamentName,
                order: item.tournamentOrder
              }
            ],
            selectedTournaments: cachedFilter?.selectedTournaments ? [...cachedFilter.selectedTournaments] : []
          });

          addedCategoryFilter[item.categoryId] = true;
        }
      }

      const categoryTournamentsFilterOrdered = orderBy(categoryTournamentsFilter, ['category.order']);
      categoryTournamentsFilterOrdered.forEach(category => {
        const categoryTournamentsOrdered = orderBy(category.tournaments, ['order']);
        category.tournaments = categoryTournamentsOrdered;
      });
      this.todaysEventsQuery.categoryTournamentsFilter$.next(categoryTournamentsFilterOrdered);

      const tournamentsOrdered = orderBy(tournaments, ['tournamentOrder', 'categoryOrder']);
      const tournamentNames = [];
      for (const item of tournamentsOrdered) {
        tournamentNames.push(item.tournamentName);
      }
      this.todaysEventsStore.updateTournamentNames(tournamentNames);

      const matchesByTournamentName = [];
      tournamentNames.forEach(tournamentName => {
        const matches = { tournamentName, matches: [] };
        areaMatches[0].items.forEach(item => {
          if (item.tournamentName.trim() === tournamentName) {
            const parseItem = this.correctScoreEventService.matchFactoryForCorrectScoreView(item);
            matches.matches.push(new MatchModel(parseItem));
          }
        });
        matchesByTournamentName.push(matches);
      });

      this.todaysEventsStore.updateMatchesByTournament(matchesByTournamentName);

      const newAreas = [];
      response.Areas.forEach(area => {
        const newArea = new AreaModel({
          id: area.AreaID,
          name: area.AreaName,
          order: area.AreaOrder
        });
        newAreas.push(newArea);
      });

      const newRegions = [];

      response.Regions.forEach(region => {
        const newRegion = new RegionModel({
          areaIds: region.AreaIds,
          id: region.RegionID,
          name: region.RegionName,
          order: region.RegionOrder
        });
        newRegions.push(newRegion);
      });

      this.todaysEventsStore.updateAreas(newAreas);
      this.todaysEventsStore.updateRegions(newRegions);

      const newRegionsWithAreas = [];

      newRegions.forEach((region: RegionModel) => {
        const newRegionWithArea = { region, areas: [] };
        region.areaIds.forEach(areaId => {
          newAreas.forEach((area: AreaModel) => {
            if (areaId === area.id) {
              newRegionWithArea.areas.push(area);
            }
          });
        });
        newRegionsWithAreas.push(newRegionWithArea);
      });

      if (newRegionsWithAreas.length) {
        this.todaysEventsStore.updateRegionsWithAreas(newRegionsWithAreas);
        if (updateRegionArea) {
          this.todaysEventsStore.updateSelectedRegion(newRegionsWithAreas[0]);
          this.todaysEventsStore.updateSelectedArea(newRegionsWithAreas[0].areas[0]);

          this.firstParse = false;
        }
      } else {
        this.todaysEventsStore.updateRegionsWithAreas(undefined);
        this.todaysEventsStore.updateSelectedRegion(undefined);
        this.todaysEventsStore.updateSelectedArea(this.todaysEventsQuery.selectedArea);
      }
    });
  }

  toggleCheckboxForEventOddsPlayer(player: MatchModel): void {
    const selectedInView = !player.selectedInView;
    const matchUpdate = new MatchModel({ ...player, selectedInView });

    const newAreaMatches2 = new AreaMatchModel({
      area: this.todaysEventsQuery.areaMatches[0].area,
      areaMarkets: this.todaysEventsQuery.areaMatches[0].areaMarkets,
      areaMarketsType: this.todaysEventsQuery.areaMatches[0].areaMarketsType,
      containsOnlyOutright: this.todaysEventsQuery.areaMatches[0].containsOnlyOutright,
      groupingType: this.todaysEventsQuery.areaMatches[0].groupingType,
      items: [],
      region: this.todaysEventsQuery.areaMatches[0].region,
      sportId: this.todaysEventsQuery.areaMatches[0].sportId,
      sportName: this.todaysEventsQuery.areaMatches[0].sportName
    });

    this.todaysEventsQuery.areaMatches[0].items.forEach((match: MatchModel, index: number) => {
      if (player.smartBetCode === match.smartBetCode) {
        newAreaMatches2.items.push(matchUpdate);
      } else {
        newAreaMatches2.items.push(match);
      }
    });

    this.updateAreaMatches([newAreaMatches2]);
  }

  parseForEventOddsPlayer(data: any): any {
    if (!data) {
      return;
    }
    const reducedTeamsData = [];
    const flags = [];
    const reducedEvens: any = [];
    const l = data.items.length;
    let i: number;
    for (i = 0; i < l; i++) {
      if (flags[data.items[i].extParentItemID]) {
        continue;
      }
      flags[data.items[i].extParentItemID] = true;
      reducedEvens.push({ extParentItemID: data.items[i].extParentItemID, data: [] });
    }
    data.items.forEach(element => {
      reducedEvens.forEach(event => {
        if (element.extParentItemID === event.extParentItemID) {
          event.data.push(element);
        }
      });
    });

    reducedEvens.forEach((eventData, index) => {
      eventData.data.forEach(innerEventData => {
        const flags2 = [];
        const reducedEvens2: any = [];
        const l2 = eventData.data.length;
        let i2: number;
        for (i2 = 0; i2 < l2; i2++) {
          if (flags2[eventData.data[i2].extParentTeamID]) {
            continue;
          }
          flags2[eventData.data[i2].extParentTeamID] = true;
          reducedEvens2.push({
            extParentTeamID: eventData.data[i2].extParentTeamID,
            data: [],
            tournamentName: eventData.data[i2].tournamentName
          });
        }
        reducedTeamsData[index] = [].concat(reducedEvens2);
      });
    });

    reducedEvens.forEach((eventData2, index) => {
      eventData2.data.forEach(innerEventData => {
        reducedTeamsData.forEach(id => {
          id.forEach((elem, index2) => {
            if (elem.extParentTeamID === innerEventData.extParentTeamID) {
              reducedTeamsData[index][index2].data.push(innerEventData);
            }
          });
        });
      });
    });

    reducedTeamsData.forEach(teamData => {
      let allTypeOfOdds = [];
      teamData.forEach(matchData => {
        matchData.data.forEach(oddsData => {
          if (oddsData.odds.length > allTypeOfOdds.length) {
            allTypeOfOdds = oddsData.odds;
          }
        });
      });
      teamData.push(allTypeOfOdds);
    });
    const reorderedTeamData = [];
    reducedTeamsData.forEach(teams => {
      let homeTeam;
      let awayTeam;
      const odds = [...teams[teams.length - 1]];
      if (teams[0].data[0].extTeamOrder === 2) {
        awayTeam = { ...teams[0] };
        homeTeam = { ...teams[1] };
      }
      if (teams[0].data[0].extTeamOrder === 1) {
        awayTeam = { ...teams[1] };
        homeTeam = { ...teams[0] };
      }
      const newTeamOrder = [homeTeam, awayTeam, odds];
      reorderedTeamData.push(newTeamOrder);
    });

    return reorderedTeamData;
  }

  parseForPlayerOddsByTournaments(teamData: any): any {
    const matchesByTournaments = [];
    this.todaysEventsQuery.tournamentNames.forEach(tournamentName => {
      const matchesByTournament = { tournamentName, matches: [] };
      teamData.forEach(teams => {
        if (teams[0].data[0].tournamentName === tournamentName) {
          matchesByTournament.matches.push(teams);
        }
      });
      matchesByTournaments.push(matchesByTournament);
    });
    return matchesByTournaments;
  }

  updateSelectedTournamentName(selectedTournamentName: string): void {
    this.todaysEventsStore.updateSelectedTournamentName(selectedTournamentName);
  }

  updateSports(sports: SportModel[]): void {
    this.todaysEventsStore.updateSports(sports);
  }

  updateSelectedMarket(selectedMarket: AreaMarketModel): void {
    this.todaysEventsStore.updateSelectedMarket(selectedMarket);
  }

  updateAreaMatches(areaMatches: AreaMatchModel[]): void {
    this.todaysEventsStore.updateAreaMatches(areaMatches);
  }

  updateSelectedSport(selectedSport: SportModel): void {
    if (this.firstParse) {
      this.initialSelectedSport = selectedSport;
    }

    this.selectedSportId = selectedSport.sportId;
    this.todaysEventsStore.updateSelectedSports(selectedSport);
  }

  updateSelectedDay(selectedDay: DayModel): void {
    if (this.firstParse) {
      this.initialSelectedDay = selectedDay;
    }
    this.todaysEventsStore.updateSelectedDay(selectedDay);
  }

  isMarketGroupsAvailable(): boolean {
    const sportId = this.todaysEventsQuery.selectedSport?.sportId;
    return !!environment.appConfigDefaults.sports.marketGroups[sportId];
  }

  isMarketGroupsOfferAvailable(): boolean {
    const sportId = this.todaysEventsQuery.selectedSport?.sportId;
    const sportSelectedMarketGroupId = this.matchService.activeMarketGroupId$.getValue()[sportId];
    // if (sportId === SportId.Soccer && !sportSelectedMarketGroup) {
    //   return true;
    // }

    const marketGroup = environment.appConfigDefaults.sports.marketGroups[sportId].find(
      group => group?.id && group.id === sportSelectedMarketGroupId
    );
    return !!marketGroup;
  }

  toggleExpandCategoryFilter(categoryId: number): void {
    const filters = this.todaysEventsQuery.categoryTournamentsFilter$.value;
    const categoryIdx = filters.findIndex(filter => filter.category.id === categoryId);
    if (categoryIdx > -1) {
      filters[categoryIdx].category.expanded = !filters[categoryIdx].category.expanded;
      this.todaysEventsQuery.categoryTournamentsFilter$.next(filters);
    }
  }

  toggleCategoryFilter(categoryId: number): void {
    const filters = this.todaysEventsQuery.categoryTournamentsFilter$.value;
    const categoryIdx = filters.findIndex(filter => filter.category.id === categoryId);
    if (categoryIdx > -1) {
      const tournamentIds = filters[categoryIdx].tournaments.map(tournament => tournament.id);
      filters[categoryIdx].category.selected = !filters[categoryIdx].category.selected;

      if (filters[categoryIdx].category.selected) {
        filters[categoryIdx].selectedTournaments.push(...tournamentIds);
      } else {
        filters[categoryIdx].selectedTournaments = filters[categoryIdx].selectedTournaments.filter(
          tournamentId => !tournamentIds.includes(tournamentId)
        );
      }
    }
    this.todaysEventsQuery.filterMatchesByTournament();
    this.todaysEventsQuery.filterMatches();
  }

  toggleCategoryTournamentFilter(categoryId: number, tournamentId: number): void {
    const filters = this.todaysEventsQuery.categoryTournamentsFilter$.value;
    const categoryIdx = filters.findIndex(filter => filter.category.id === categoryId);
    if (categoryIdx > -1) {
      const hasTournament = filters[categoryIdx].selectedTournaments.includes(tournamentId);
      if (hasTournament) {
        filters[categoryIdx].selectedTournaments = filters[categoryIdx].selectedTournaments.filter(id => tournamentId !== id);
      } else {
        filters[categoryIdx].selectedTournaments.push(tournamentId);
      }

      // Handle category selection based on number of tournaments selected in it
      if (filters[categoryIdx].selectedTournaments.length === 0 && filters[categoryIdx].category.selected) {
        filters[categoryIdx].category.selected = false;
      } else if (
        filters[categoryIdx].selectedTournaments.length === filters[categoryIdx].tournaments.length &&
        !filters[categoryIdx].category.selected
      ) {
        filters[categoryIdx].category.selected = true;
      }
    }
    this.todaysEventsQuery.filterMatchesByTournament();
    this.todaysEventsQuery.filterMatches();
  }

  deselectAllCategoriesAndTournaments(): void {
    this.todaysEventsQuery.categoryTournamentsFilter$.next([]);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  handleGetEvents(): Observable<any> {
    const marketGroup = this.sportMarketGroupSelected[this.todaysEventsQuery.selectedSport?.sportId];
    const marketGroupOffer = marketGroup?.offerPage as MarketGroupOffer;

    if (this.fetchingEvents) {
      return of(undefined);
    }

    this.fetchingEvents = true;
    return this.getEvents(
      this.languageService.selectedLanguage.language,
      this.todaysEventsQuery.selectedDay,
      marketGroupOffer?.areaId ?? 0,
      marketGroupOffer?.regionId ?? 0,
      this.todaysEventsQuery.selectedSport?.sportId ?? 1,
      true,
      false,
      0,
      10000
    ).pipe(
      finalize(() => {
        this.fetchingEvents = false;
        setTimeout(() => {
          this.firstEventsFetch = false;
        }, 1000);
      })
    );
  }

  fetchShortcutsFromCMS(): void {
    if (this.bettingShortcutGroups$.value) {
      return;
    }

    this.apiService
      .get(APIType.CMS, 'betting-shortcut-groups')
      .pipe(first())
      .subscribe((response: BettingShortcutGroup[]) => {
        const groups = response
          .filter(group => !group.availableUntil || new Date(group.availableUntil).getTime() > new Date().getTime())
          .sort((group1: BettingShortcutGroup, group2: BettingShortcutGroup) => group1.order - group2.order)
          .map(group => ({
            ...group,
            betting_shortcuts: group.betting_shortcuts
              .filter(
                shortcut =>
                  shortcut.visible && (!shortcut.availableUntil || new Date(shortcut.availableUntil).getTime() > new Date().getTime())
              )
              .sort((shortcut1: BettingShortcut, shortcut2: BettingShortcut) => shortcut1.order - shortcut2.order)
          }))
          .filter(group => group.betting_shortcuts.length > 0);

        this.bettingShortcutGroups$.next(groups);
      });
  }
}
