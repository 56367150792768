import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { MyAllBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-all-bets', resettable: true })
export class MyAllBetsStore extends EntityStore<MyAllBetsState, RecentBetModel> {
  constructor() {
    super();
  }

  toggleCollapsed(): void {
    this.updateActive(entity => ({
      collapsed: !entity.collapsed
    }));
  }

  removeByCouponCode(couponCode: string): void {
    this.remove(bet => bet.couponCode === couponCode);
  }

  clear(): void {
    this.reset();
  }
}
