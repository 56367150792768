export enum SportId {
  Soccer = 1,
  Basketball = 2,
  Baseball = 3,
  IceHockey = 4,
  Tennis = 5,
  Handball = 6,
  Floorball = 7,
  Golf = 9,
  MotorSport = 11,
  RugbyUnion = 12,
  AussieRules = 13,
  WinterSports = 14,
  Bandy = 15,
  Football = 16,
  Cycling = 17,
  Snooker = 19,
  TableTennis = 20,
  Cricket = 21,
  Darts = 22,
  Volleyball = 23,
  FieldHockey = 24,
  Pool = 25,
  Waterpolo = 26,
  Curling = 28,
  Futsal = 29,
  Olympics = 30,
  Badminton = 31,
  Bowls = 32,
  BeachVolley = 34,
  Squash = 37,
  Formula1 = 40,
  Alpine = 43,
  Biathlon = 44,
  Bobsleigh = 45,
  CrossCountry = 46,
  NordicCombined = 47,
  SkiJumping = 48,
  Snowboard = 49,
  SpeedSkating = 50,
  Luge = 51,
  RugbyLeague = 59,
  BeachSoccer = 60,
  Pesapallo = 61,
  Lottery = 80,
  Athletics = 81,
  Boxing = 83,
  Specials = 85,
  Wrestling = 86,
  UFC = 87,
  Boxe = 101,
  Swimming = 103,
  Sailing = 106,
  ESportCounterStrike = 109,
  Poker = 110,
  ESportLeagueOfLegends = 111,
  ESportDota2 = 112,
  ESportStreetFighterV = 113,
  FreestyleSkiing = 114,
  MMA = 117,
  FigureSkating = 118,
  Skeleton = 119,
  ShortTrackSpeedSkating = 120,
  ESportSoccer = 137,
  FormulaE = 142,
  ESportBasketball = 153,
  Basketball3x3 = 155,
  ESportProEvolutionSoccer = 166,
  ESportIceHockey = 195,
  ESportStarCraft = 200,
  ESportHearthstone = 201,
  ESportHeroesOfTheStorm = 202,
  ESportWorldOfTanks = 203,
  ESportCallOfDuty = 204,
  ESportSmite = 205,
  ESportVainglory = 206,
  ESportOverwatch = 207,
  ESportWarCraftIII = 208,
  ESportCrossfire = 209,
  ESportHalo = 210,
  ESportRainbowSix = 211,
  ESportRocketLeague = 212,
  ESportGearsOfWar = 213,
  ESportClashRoyale = 214,
  ESportKingOfGlory = 215,
  ESportFIFA = 216,
  ESportQuake = 217,
  ESportPlayerUnknownsBattlegrounds = 218,
  Soccerbet = 252,
  FastLeague = 253,
  Rugby = 254
}
