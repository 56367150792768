import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AccountQuery } from 'src/app/core/state/account/account.query';

@Component({
  selector: 'account-bonus-progress',
  templateUrl: './bonus-progress.component.html',
  styleUrls: ['./bonus-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusProgressComponent {
  @Input() currentProgress: number = 0;
  @Input() nextRelease: number = 0;

  constructor(public accountQuery: AccountQuery) {}
}
