import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from 'src/app/core/services/language.service';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { environment } from 'src/environments/environment';

import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class APIService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfigService,
    private readonly languageService: LanguageService
  ) { }

  get<T>(apiType: APIType, apiEndpoint: string, settings: APISettings = {}): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint, settings ? settings.thirdPartyBaseUrl : undefined, settings);
    if (apiType === APIType.CMS) {
      settings.noAuthToken = true;
    }
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings)
    };

    return this.httpClient.get<T>(apiUrl, httpOptions);
  }

  post<T>(apiType: APIType, apiEndpoint: string, data: any, settings?: APISettings): Observable<any> {
    const apiSettings: APISettings = settings || new APISettings();
    if (!apiSettings.contentType) {
      apiSettings.contentType = 'application/json';
    }

    const apiUrl = this.generateUrl(apiType, apiEndpoint, settings ? settings.thirdPartyBaseUrl : undefined);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, apiSettings)
    };

    return this.httpClient.post<T>(apiUrl, data, httpOptions);
  }

  delete<T>(apiType: APIType, apiEndpoint: string, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint, settings ? settings.thirdPartyBaseUrl : undefined);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings)
    };

    return this.httpClient.delete<T>(apiUrl, httpOptions);
  }

  put<T>(apiType: APIType, apiEndpoint: string, data: any, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint, settings ? settings.thirdPartyBaseUrl : undefined, settings);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings)
    };

    return this.httpClient.put<T>(apiUrl, data, httpOptions);
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private generateUrl(apiType: APIType, apiEndpoint: string, thirdPartyBaseUrl?: string, settings: APISettings = {}): string {
    const apiBaseUrl = this.appConfig.get('apiBaseUrl');

    let baseUrl: string;
    switch (apiType) {
      case APIType.BetRadarResults:
        baseUrl = apiBaseUrl.betRadarResults;
        break;
      case APIType.BetRadarStatistics:
        baseUrl = apiBaseUrl.betRadarStatistics;
        break;
      case APIType.Analytics:
        baseUrl = apiBaseUrl.analyticsHTTP;
        break;
      case APIType.CMS:
        if (environment.production) {
          baseUrl = apiBaseUrl.cms;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdCMSUrl');
          baseUrl = overrideUrl || apiBaseUrl.cms;
        }
        break;
      case APIType.ThirdParty:
        if (!thirdPartyBaseUrl) {
          baseUrl = '';
          console.error(`No valid base url provided for third party endpoint '${apiEndpoint}'`);
          break;
        }
        baseUrl = thirdPartyBaseUrl;
        break;
      case APIType.Guardian:
        if (environment.production) {
          baseUrl = apiBaseUrl.guardian;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdGuardianUrl');
          baseUrl = overrideUrl || apiBaseUrl.guardian;
        }
        break;
      case APIType.Local:
        baseUrl = '';
        break;
      case APIType.Platform:
        if (environment.production) {
          baseUrl = apiBaseUrl.platform;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdPlatformUrl');
          baseUrl = overrideUrl || apiBaseUrl.platform;
        }
        break;
      case APIType.Sportsbook:
        if (environment.production) {
          baseUrl = apiBaseUrl.sportsbook;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSportsbookUrl');
          baseUrl = overrideUrl || apiBaseUrl.sportsbook;
        }
        break;
      case APIType.SportsbookFeed:
        if (environment.production) {
          baseUrl = apiBaseUrl.sportsbookFeed;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSportsbookFeedUrl');
          baseUrl = overrideUrl || apiBaseUrl.sportsbookFeed;
        }
        break;
      default:
        baseUrl = '';
        console.error(`No valid APIType provided for api endpoint '${apiEndpoint}'`);
        break;
    }

    if (apiType !== APIType.CMS || !this.languageService.enableLanguageSelection$.value) {
      return `${baseUrl}/${apiEndpoint}`;
    }
    const language = this.languageService.selectedLanguage.locale === 'sr-latn' ? 'sr-Latn' : 'en';
    let languageQS: string =
      apiType === APIType.CMS ? `_locale=${language}` : `language=${this.languageService.selectedLanguage.umbracoLocale.toLowerCase()}`;

    if (settings.skipLocale) {
      languageQS = '';
    }

    return `${baseUrl}/${apiEndpoint}${apiEndpoint.indexOf('?') > -1 ? '&' : '?'}${languageQS}`;
  }

  private getLocalStorageOverride(key: string): string {
    // making use of window.localStorage directly in order to avoid having
    // to include the ngx-webstorage prefix value to the keys
    return window.localStorage.getItem(key);
  }

  private generateHeaders(apiType: APIType, settings?: APISettings): HttpHeaders {
    // using VND naming convention for headers which are used only for application logic,
    // following the convention specified in RFC6648 - https://tools.ietf.org/html/rfc6648
    const apiSettings: APISettings = settings || new APISettings();
    let httpHeaders = new HttpHeaders();

    if (apiType === APIType.Platform) {
      const brandId = this.appConfig.get('brandId').toString();

      if (brandId) {
        httpHeaders = httpHeaders.append('X-BRAND-ID', brandId);
      }

      httpHeaders = httpHeaders.append('X-API-LANGUAGE', this.languageService.selectedLanguage.locale);
    }

    if (apiSettings.contentType) {
      httpHeaders = httpHeaders.append('Content-Type', apiSettings.contentType);
    }

    if (apiSettings.inBehalfOf) {
      httpHeaders = httpHeaders.append('inBehalfOf', apiSettings.inBehalfOf);
    }

    if (apiSettings.noAuthToken) {
      httpHeaders = httpHeaders.append('VND.noAuthToken', 'true');
    } else if (apiSettings.forceAuthToken) {
      httpHeaders = httpHeaders.append('VND.forceAuthToken', apiSettings.forceAuthToken);
    }

    return httpHeaders;
  }
}
