<div [class.floating]="!showTournament" class="event-header-details">
  <div (click)="(!fullVersion)" class="header-details">
    <!-- <div class="bet-identifier" (click)="navigateToMatch()">{{ event.smartBetCode }}</div> -->
    <ng-container>
      <div *ngIf="sportId && fullVersion" [ngClass]="productIconClass" class="product-icon sport-icon icon-{{ sportId }}"></div>
      <div
        (click)="navigateToEvent()"
        (click)="navigateToMatch()"
        *ngIf="fullVersion"
        [category]="'Match Tournament'"
        [click]="true"
        analytics
        class="location"
      >
        <div class="category-tournament">
          <span [class.hide]="!showTournament" [innerHTML]="event.tournamentName | highlight : (searchQuery.searchKeyword$ | async)"></span>
        </div>
      </div>
      <div
        (click)="navigateToMatch()"
        *ngIf="!fullVersion && !showDate && !specials"
        [category]="'Match Time'"
        [click]="true"
        analytics
        class="match-time"
      >
        {{ event.date | date : 'HH:mm' }}
      </div>
    </ng-container>
    <div (click)="navigateToMatch()" *ngIf="showDate && !specials" [category]="'Match Time'" [click]="true" analytics class="match-time">
      &nbsp;|&nbsp;{{ event.date | formatDate : 'dd MMM HH:mm' }}
    </div>
    <div *ngIf="showDate && specials" class="date">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_490_2237)">
          <path
            d="M5.995 1C3.235 1 1 3.24 1 6C1 8.76 3.235 11 5.995 11C8.76 11 11 8.76 11 6C11 3.24 8.76 1 5.995 1ZM6 10C3.79 10 2 8.21 2 6C2 3.79 3.79 2 6 2C8.21 2 10 3.79 10 6C10 8.21 8.21 10 6 10ZM6.25 3.5H5.5V6.5L8.125 8.075L8.5 7.46L6.25 6.125V3.5Z"
            fill="#66737C"
          />
        </g>
        <defs>
          <clipPath id="clip0_490_2237">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div *ngIf="event.date" class="event-time">
        <span class="event-time">{{ event.date | formatDate : 'dd MMM' }},</span>
        <span class="event-time time">{{ event.date | formatDate : 'HH:mm' }}</span>
      </div>
    </div>
  </div>
</div>
