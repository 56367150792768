import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { I18nRedirectComponent } from 'src/app/components/i18n-redirect/i18n-redirect.component';
import { CouponCheckComponent } from 'src/app/shared/components/coupon-check/coupon-check.component';
import { LanguagePageComponent } from 'src/app/shared/components/langauge-page/language-page.component';
import { ProductType } from 'src/app/shared/models/application.model';

import { CouponDetailsWrapperComponent } from './shared/components/coupon-details-wrapper/coupon-details-wrapper.component';
import { MenuPageWrapperComponent } from './shared/components/menu/menu-page-wrapper/menu-page-wrapper.component';
import { MaintenanceGuard } from 'src/app/core/guards/maintenance-guard.service';
import { NoMaintenanceGuard } from 'src/app/core/guards/no-maintenance-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/good-bye/good-bye.module').then(module => module.GoodByeModule),
    pathMatch: 'full',
    data: {},
    canActivate: []
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(module => module.AccountModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'reset-password',
    redirectTo: 'account/password-reset',
    pathMatch: 'full'
  },
  {
    path: 'reset-password/:token',
    redirectTo: 'account/password-reset/:token',
    pathMatch: 'full'
  },
  {
    path: 'coupon',
    loadChildren: () => import('./modules/coupon/coupon.module').then(module => module.CouponModule),
    data: { productType: ProductType.SportsBook },
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./modules/maintenance/maintenance.module').then(module => module.MaintenanceModule),
    canActivate: [NoMaintenanceGuard]
  },
  {
    path: 'quick-coupon',
    loadChildren: () => import('./modules/quick-coupon/quick-coupon.module').then(module => module.QuickCouponModule),
    data: { productType: ProductType.SportsBook },
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'my-bets',
    loadChildren: () => import('./modules/my-bets/my-bets.module').then(module => module.MyBetsModule),
    data: { productType: ProductType.SportsBook },
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'coupon-check',
    component: CouponCheckComponent
    // canActivate: [MaintenanceGuard]
  },
  {
    path: 'coupon-check/:couponCode',
    component: CouponDetailsWrapperComponent,
    data: { noCheck: true }
    // canActivate: [MaintenanceGuard]
  },
  {
    path: 'strenghten-profile',
    loadChildren: () => import('./modules/strenghten-profile/strenghten-profile.module').then(module => module.StrenghtenProfileModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'activate',
    loadChildren: () =>
      import('./modules/registration-activation/registration-activation.module').then(module => module.RegistrationActivationModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'verify',
    loadChildren: () => import('./modules/verification/verification.module').then(module => module.VerificationModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'voucher',
    loadChildren: () => import('./modules/voucher/voucher.module').then(module => module.VoucherModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'message-center',
    loadChildren: () => import('./modules/message-center/message-center.module').then(module => module.MessageCenterModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./modules/help/help.module').then(module => module.HelpModule),
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'menu',
    component: MenuPageWrapperComponent,
    pathMatch: 'full',
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'menu/:tab',
    component: MenuPageWrapperComponent,
    pathMatch: 'full',
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'menu/:tab/:section',
    component: MenuPageWrapperComponent,
    pathMatch: 'full',
    canActivate: [MaintenanceGuard]
  },
  {
    path: 'language',
    component: LanguagePageComponent,
    canActivate: [MaintenanceGuard]
  },
  {
    path: '**',
    component: I18nRedirectComponent
  }
];

const config: ExtraOptions = {
  useHash: false,
  relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
