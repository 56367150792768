import { Component, Input, OnInit } from '@angular/core';
import { CMSNotificationsService } from 'src/app/core/services/cms-notifications.service';
import {
  CMSNotification,
  NotificationButton,
  NotificationButtonAction,
  NotificationEventType,
  NotificationRecurrence,
  NotificationStyle
} from 'src/app/shared/models/cms-notification';

@Component({
  selector: 'app-notification-toast',
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss']
})
export class NotificationToastComponent implements OnInit {
  @Input() toast: CMSNotification;

  hasDontShowMeAgain = false;
  dontShowMeAgain = false;

  notificationRecurrence = NotificationRecurrence;
  notificationStyle = NotificationStyle;

  constructor(private readonly notificationsService: CMSNotificationsService) {}

  ngOnInit(): void {
    this.handleToastAutoClose();
  }

  handleButtonClick(button: NotificationButton): void {
    if (button.action === NotificationButtonAction.Redirect && button.redirectURL) {
      window.open(button.redirectURL);
    }

    if (button.action === NotificationButtonAction.InCodeAction && button.inCodeAction) {
      this.notificationsService.emitEvent({
        type: NotificationEventType.Action,
        name: button.inCodeAction
      });
    }

    this.close();
  }

  close(): void {
    this.notificationsService.remove(this.toast, this.dontShowMeAgain);
  }

  toggleDontShowMeAgain(): void {
    this.dontShowMeAgain = !this.dontShowMeAgain;
  }

  private handleToastAutoClose(): void {
    if (this.toast.toastDuration) {
      setTimeout(this.close.bind(this), this.toast.toastDuration);
    }
  }
}
