import { Directive, HostListener, OnDestroy, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Directive({
  selector: '[decimalFormatter]'
})
export class DecimalFormatterDirective implements OnDestroy {

  private destroy$ = new Subject();

  constructor(@Self() private ngControl: NgControl) {
  }

  ngAfterViewInit() {
    this.setValue(this.format(this.ngControl.value))
  }

  @HostListener('focus') onFocus() {
    this.setValue(this.unformatValue(this.ngControl.value));
  }

  @HostListener('blur') onBlur() {
    this.setValue(this.format(this.ngControl.value));
  }

  format(v) {
    return v ? Number.parseFloat(v).toFixed(2) : null;
  }

  unformatValue(v) {
    return v ? Number.parseFloat(v).toFixed(0) : null;
  }


  setValue(v) {
    this.ngControl.control.setValue(v, { emitEvent: false })
  }

  ngOnDestroy() {
    this.setValue(this.unformatValue(this.ngControl.value));
    this.destroy$.next();
    this.destroy$.complete();
  }

}
