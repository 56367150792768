import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LiveStore } from 'src/app/core/state/live/live.store';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { EventModel, LiveFeedModel, LiveState, MarketModel, SelectionModel, TournamentModel } from 'src/app/shared/models/live.model';

@Injectable({ providedIn: 'root' })
export class LiveQuery extends Query<LiveState> {
  favourites$ = this.select(state => state.favourites);
  showFavourites$ = this.select(state => state.showFavourites);
  liveFeeds$ = this.select(state => state.liveFeeds);
  selectedFeedId$ = this.select(state => state.selectedFeed);
  selectedMarket$ = this.select(state => state.selectedMarket);
  selectedFeed$ = combineLatest(this.liveFeeds$, this.selectedFeedId$, this.selectedMarket$).pipe(
    map(([feeds, selectedFeedId, market]) => this.mapFeedToSelectedMarket(feeds, selectedFeedId, market))
  );
  isSpreadValueMarket$ = this.select(state => state.selectedMarket).pipe(map(market => this.isSpreadValueMarket(market)));
  selectedAreaInMatchView$ = this.select(state => state.selectedAreaInMatchView);
  selectedLiveEvent$ = this.select(state => state.selectedLiveEvent);
  selectedLiveEventVisibleMarkets$ = this.select(state =>
    state.selectedLiveEvent ? state.selectedLiveEvent.tournaments[0].events[0].markets : []
  );
  selectedLiveEventAreas$ = this.select(state => state.selectedLiveEvent?.areas);
  liveViewEnabledByUser$ = this.select(state => state.liveViewEnabledByUser);
  noLiveFeedData$ = this.select(state => state.ui.noLiveFeedData);
  notValidEventId$ = this.select(state => state.ui.notValidEventId);
  liveFeedsLoading$ = this.select(state => state.ui.liveFeedsLoading);
  selectedLiveEventLoading$ = this.select(state => state.ui.selectedLiveEventLoading);

  get selectedFeed(): any {
    return this.getValue().selectedFeed;
  }

  get favourites(): any {
    return this.getValue().favourites;
  }

  get showFavourites(): boolean {
    return this.getValue().showFavourites;
  }

  get selectedMarket(): any {
    return this.getValue().selectedMarket;
  }

  get selectedAreaInMatchView(): any {
    return this.getValue().selectedAreaInMatchView;
  }

  get selectedLiveEvent(): any {
    return this.getValue().selectedLiveEvent;
  }

  get selectedLiveEventAreas(): any {
    return this.getValue().selectedLiveEvent.areas;
  }

  get liveViewEnabledByUser(): any {
    return this.getValue().liveViewEnabledByUser;
  }

  get noLiveFeedData(): any {
    return this.getValue().ui.noLiveFeedData;
  }

  get notValidEventId(): any {
    return this.getValue().ui.notValidEventId;
  }

  get liveFeedsLoading(): any {
    return this.getValue().ui.liveFeedsLoading;
  }

  selectIsFavourite(id: number): Observable<boolean> {
    return this.select(state => !!state.favourites.find(ev => ev.id === id));
  }

  isTournamentWithFavourites$(id: number): Observable<boolean> {
    return this.favourites$.pipe(map(favourites => !!favourites.find(ev => ev.tournamentId === id)));
  }

  isSportWithFavourites$(id: number): Observable<boolean> {
    return this.favourites$.pipe(map(favourites => !!favourites.find(ev => ev.sportId === id)));
  }

  constructor(protected store: LiveStore) {
    super(store);
  }

  mapFeedToSelectedMarket(feeds: LiveFeedModel[], selectedFeedId: number, selectedMarket: MarketModel): LiveFeedModel {
    if (!feeds) {
      return;
    }

    const feed = feeds.find(f => f.id === selectedFeedId);

    return {
      ...feed,
      tournaments: feed?.tournaments.map(t => this.mapTournament(t, selectedMarket))
    };
  }

  private isSpreadValueMarket(selectedMarket: MarketModel): boolean {
    return selectedMarket.selections && selectedMarket.selections.length === 2 && Boolean(selectedMarket.specialValue);
  }

  private readonly mapTournament = (tournament: TournamentModel, selectedMarket: MarketModel): TournamentModel => ({
    ...tournament,
    events: tournament.events.map(e => this.mapEvent(e, selectedMarket))
  });

  private readonly mapEvent = (event: EventModel, selectedMarket: MarketModel): EventModel => ({
    ...event,
    markets: this.mapToSelectedMarket(event.markets, selectedMarket)
  });

  private readonly getOddsDiff = (market): number => Math.abs(market.selections[0]?.odds[0].value - market.selections[1]?.odds[0].value);

  private readonly hasOddValues = (market): number => market.selections[0]?.odds[0].value && market.selections[1]?.odds[0].value;

  private readonly mapToSelectedMarket = (markets: MarketModel[], selectedMarket: MarketModel): MarketModel[] => {
    if (!markets || !selectedMarket) {
      return;
    }
    const filteredMarkets = markets.filter(m => m.typeId === selectedMarket.typeId);
    let market = filteredMarkets[0];
    if (market?.specialValue && market?.selections.length === 2) {
      let oddsDiff = this.getOddsDiff(market);
      filteredMarkets.forEach(filteredMarket => {
        const currentOddsDiff = this.getOddsDiff(filteredMarket);
        if (!this.hasOddValues(market) && this.hasOddValues(filteredMarket)) {
          oddsDiff = currentOddsDiff;
          market = filteredMarket;
        } else if (this.hasOddValues(filteredMarket) && currentOddsDiff <= oddsDiff) {
          oddsDiff = currentOddsDiff;
          market = filteredMarket;
        }
      });
    }

    market = {
      ...(market || selectedMarket),
      selections: [...this.mapSelections(selectedMarket.selections, market)]
    };

    return [market];
  };

  private readonly mapSelections = (selections: SelectionModel[], market?: MarketModel) =>
    selections.map(s => {
      let selection;

      if (market) {
        selection = market.selections.find(ms => ms.typeId === s.typeId);
      } else {
        selection = {
          ...s,
          specialValue: '',
          odds: [
            new OddModel({
              id: 0,
              value: undefined,
              enabled: false
            })
          ]
        };
      }

      return selection;
    });
}
