// CouponStatusID, BetFinalState and EventOutcome represents the same enums at the backend

export enum CouponStatus {
  Unknown,
  Running,
  Lost,
  Won,
  Cancelled,
  SystemEvaluation,
  Refused,
  UserEvaluation,
  ReOpened
}

export enum CouponType {
  Unknown = 0,
  Single = 1,
  Multiple = 2,
  System = 3
}

export enum BetFinalState {
  Placed = -1,
  InEvaluation = 0,
  Won = 1,
  PartiallyWon = 2,
  Lost = 3,
  Void = 4,
  Cashout = 5,
  Promotion = 6
}

export enum EventOutcome {
  Unknown = -3,
  NoResult = -2,
  Void = -1,
  Lost = 0,
  Won = 1,
  Push = 2,
  HalfLost = 3,
  HalfWon = 4
}

export class CouponDetailsModel {
  betFinalState: number;
  couponCode: string;
  couponDate: string;
  couponStatus: CouponStatus = CouponStatus.Unknown;
  couponType: string;
  couponTypeId: number;
  currencySymbol: string;
  groups: CouponDetailsGroupModel[];
  maxBonus: number;
  maxOdd: number;
  maxPotWin: number;
  maxPotWinNet: number;
  maxPotWinTax: number;
  maxWin: number;
  minBonus: number;
  minOdd: number;
  minWin: number;
  minWinNet: number;
  minWinTax: number;
  netStakeMaxWin: number;
  netStakeMinWin: number;
  odds: CouponDetailsOddModel[];
  paymentDate: string;
  rePrint: boolean;
  rebetEnabled: boolean = false;
  stake: number;
  stakeNet: number;
  stakeTax: number;
  totalCombinations: number;
  totalOdds: number;
  userId: number;
  userName: string;
  won: number;
  wonTax: number;

  constructor(init: Partial<CouponDetailsModel>) {
    Object.assign(this, init);
  }
}

export class CouponDetailsOddModel {
  eventId: number;
  orderId: number;
  sportName: string;
  categoryName: string;
  tournamentName: string;
  roundNumber: number;
  leagueNo: number;
  eventCategory: string;
  eventDate: string;
  eventName: string;
  isBanker: boolean;
  marketId: string;
  marketName: string;
  selectionName: string;
  oddValue: number;
  resultStatus: string;
  resultStatusId: number;
  sportId: number;
  resultHTScore: {
    teamOne: number;
    teamTwo: number;
  };
  resultFTScore: {
    teamOne: number;
    teamTwo: number;
  };
  results: object;
  spreadValue?: string;
  marketTypeId?: number;
  constructor(init: Partial<CouponDetailsOddModel>) {
    Object.assign(this, init);
  }
}

export class OddResultsModel {
  value: number;
  family: string;
  symbol: string;

  constructor(init: Partial<OddResultsModel>) {
    Object.assign(this, init);
  }
}

export class CouponDetailsGroupModel {
  combinations: number;
  grouping: number;
  maxBonus: number;
  maxWin: number;
  minBonus: number;
  minWin: number;
  netStakeMaxWin: number;
  netStakeMinWin: number;
  stake: number;
  stakeNet: number;
  stakeTax: number;

  constructor(init: Partial<CouponDetailsGroupModel>) {
    Object.assign(this, init);
  }
}

export class RecentBetsModel {
  couponCode: string;
  couponStatus: CouponStatus = CouponStatus.Unknown;
  stake: number;
  currencySymbol: string;
  won: number;

  constructor(init: Partial<RecentBetsModel>) {
    Object.assign(this, init);
  }
}

export class CouponDetailsState {
  couponDetails: CouponDetailsModel;
  ui: CouponDetailsUIState;

  constructor(init: Partial<CouponDetailsState>) {
    Object.assign(this, init);
  }
}

export class CouponDetailsUIState {
  isLoading: boolean;
  wrongCouponCode: boolean;
  checkOutValueLoading: boolean;

  constructor(init: Partial<CouponDetailsUIState>) {
    Object.assign(this, init);
  }
}
