import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { NotificationSettings } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'app-phone-verification',
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneVerificationComponent implements OnInit, OnDestroy {
  @ViewChildren('verificationCode') verificationCode: QueryList<any>;
  @Input() mobileNumber: string;
  @Input() username: string;
  @Input() OTPOption: number;
  @Input() limitExceeded: boolean;

  verificationForm: FormGroup;
  avoidCallsWithSameCode: string;
  showLoader: boolean = false;

  invalidCode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  limitExceeded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  intervalSub: Subscription;
  resendCodeNumOfSeconds: number = 60;
  resendCodeTimer$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  showDNDNotice = this.appConfigService.get('registration').showDNDNotice;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accountService: AccountService,
    private readonly accountQuery: AccountQuery,
    private readonly notificationService: NotificationService,
    
    private readonly registrationQuery: RegistrationQuery,
    private readonly registrationService: RegistrationService,
    private readonly appConfigService: AppConfigService
  ) {
    this.resendCodeTimer$.next(this.resendCodeNumOfSeconds);
  }

  ngOnInit(): void {
    if (this.limitExceeded) {
      this.limitExceeded$.next(true);
    } else {
      this.calculateCountdown();
    }

    this.verificationForm = this.initialiseForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  calculateCountdown(): void {
    this.intervalSub = interval(1000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.resendCodeTimer$.next(this.resendCodeTimer$.value - 1);

        if (this.resendCodeTimer$.value <= 0) {
          this.intervalSub.unsubscribe();
        }
      });
  }

  resendCode(): void {
    const body: any = {
      RequestTypeOption: this.OTPOption,
      Data: {}
    };

    switch (this.OTPOption) {
      case 2: // Reset Password
      case 3: // Registration
        body.Data = {
          Username: this.username
        };
        break;
      case 4: // Change Phone Number
      case 5: // Verify Phone Number
        body.Data = {
          UserId: this.accountQuery.userData.id,
          Mobile: this.mobileNumber
        };
        break;
      default:
        break;
    }

    this.accountService
      .regenerateTokenForService(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.limitExceeded$.next(false);
          this.resendCodeTimer$.next(this.resendCodeNumOfSeconds);
          this.calculateCountdown();

          this.clearCode();
        },
        error => {
          if (error.error.ResponseCode === 20805) {
            this.limitExceeded$.next(true);
            this.clearCode();
          }
        }
      );
  }

  clearCode(): void {
    Object.keys(this.verificationForm.controls).forEach(key => {
      this.verificationForm.controls[key].setValue('');
    });

    setTimeout(() => {
      this.verificationCode.first.nativeElement.focus();
    });
  }

  pasteFromClipboard(event: any): void {
    const code = event.clipboardData
      .getData('text/plain')
      .trim()
      .replace('-', '');

    let count = 0;
    Object.keys(this.verificationForm.controls).forEach(key => {
      this.verificationForm.controls[key].setValue(code.charAt(count));
      count++;
    });

    setTimeout(() => {
      this.verificationCode.last.nativeElement.focus();
    });

    this.verifyCode();
  }

  onKey(event: any): void {
    const keyCode = event.which || event.keyCode;

    let char;
    if (keyCode === 8 || keyCode === 37) {
      char = this.verificationCode.find(g => parseInt(g.nativeElement.dataset.elem, 10) === parseInt(event.target.dataset.elem, 10) - 1);
    } else if (
      keyCode === 39 ||
      (keyCode >= 48 && keyCode <= 90) ||
      (keyCode >= 96 && keyCode <= 111) ||
      (keyCode >= 186 && keyCode <= 222)
    ) {
      if (event.target.value !== '') {
        char = this.verificationCode.find(g => parseInt(g.nativeElement.dataset.elem, 10) === parseInt(event.target.dataset.elem, 10) + 1);
      }
    }

    if (char) {
      char.nativeElement.focus();
      char.nativeElement.select();
    }

    this.verifyCode();
  }

  verifyCode(): void {
    let hasValue = true;
    let code = '';
    Object.keys(this.verificationForm.controls).forEach(key => {
      if (hasValue) {
        if (this.verificationForm.controls[key].value.length > 0) {
          hasValue = true;
          code += this.verificationForm.controls[key].value;
        } else {
          hasValue = false;
        }
      }
    });

    if (hasValue && this.avoidCallsWithSameCode !== code) {
      this.avoidCallsWithSameCode = code;

      this.showLoader = true;

      this.accountService
        .validateOneTimePassword(this.username, code, this.OTPOption)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.showLoader = false;

            this.invalidCode$.next(false);

            if (this.OTPOption === 2) {
              // Reset Password
              window.location.href = `/account/password-reset/${this.username}/${code}`;
            } else if (this.OTPOption === 3) {
              // Registration
              this.accountService
                .login(this.registrationQuery.credentials.user, this.registrationQuery.credentials.pass, true)
                .subscribe(() => {
                  this.registrationService.clearCredentials();
                  window.location.href = '/register-success';
                });
            } else if (this.OTPOption === 4) {
              // Change Phone Number
              this.accountService.getUserData(this.accountQuery.accessToken).subscribe(() => {
                this.notificationService.showNotification(
                  new NotificationSettings({
                    allowBackdropClose: true,
                    contentText: translate('Phone number changed successful'),
                    type: 'success',
                    showConfirmButton: true
                  })
                );

                window.location.href = `/account/change-phone-number`;
              });
            } else if (this.OTPOption === 5) {
              // Verify Phone Number
              this.accountService.getUserData(this.accountQuery.accessToken).subscribe(() => {
                this.notificationService.showNotification(
                  new NotificationSettings({
                    allowBackdropClose: true,
                    contentText: translate('Phone verified successful'),
                    type: 'success',
                    showConfirmButton: true
                  })
                );

                window.location.href = `/`;
              });
            }
          },
          error => {
            this.showLoader = false;
            this.invalidCode$.next(true);
          }
        );
    }
  }

  private initialiseForm(): FormGroup {
    return new FormGroup({
      char1: new FormControl({ value: '', disabled: this.limitExceeded$.value }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1)
      ]),
      char2: new FormControl({ value: '', disabled: this.limitExceeded$.value }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1)
      ]),
      char3: new FormControl({ value: '', disabled: this.limitExceeded$.value }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1)
      ]),
      char4: new FormControl({ value: '', disabled: this.limitExceeded$.value }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1)
      ]),
      char5: new FormControl({ value: '', disabled: this.limitExceeded$.value }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1)
      ]),
      char6: new FormControl({ value: '', disabled: this.limitExceeded$.value }, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(1)
      ])
    });
  }
}
