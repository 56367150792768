/* tslint:disable */
import {
  animate,
  AnimationTriggerMetadata,
  group,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { animationDuration, arrowAnimationDuration } from 'src/app/core/services/variables.service';

export function fadeIn(): AnimationTriggerMetadata {
  return trigger('fadeIn', [transition('void => *', [style({ opacity: 0 }), animate('.3s ease-in', style({ opacity: 1 }))])]);
}
export function fadeInCardForm(): AnimationTriggerMetadata {
  return trigger('fadeInCardForm', [
    state('visible', style({ opacity: 1 })),
    state('hidden', style({ opacity: 0 })),
    transition('hidden => visible', [style({ opacity: 0, display: 'block' }), animate('.3s ease-in', style({ opacity: 1 }))]),
    transition('visible => hidden', [animate('.3s ease-out', style({ opacity: 0 })), style({ display: 'none' })])
  ]);
}
export function fadeInBy(): AnimationTriggerMetadata {
  return trigger('fadeInBy', [transition('void => *', [style({ opacity: 0 }), animate('{{duration}}ms ease-in', style({ opacity: 1 }))])]);
}

export function fadeInByStaggered(): AnimationTriggerMetadata {
  return trigger('fadeInByStaggered', [
    transition('void => *', [
      query('.staggered', [style({ opacity: 0 }), stagger(25, [animate('{{duration}}ms ease-in', style({ opacity: 1 }))])], {
        optional: true,
        limit: 15
      })
    ])
  ]);
}

export function fadeOut(): AnimationTriggerMetadata {
  return trigger('fadeOut', [transition('* => void', [style({ opacity: 1 }), animate('.4s ease-in', style({ opacity: 0 }))])]);
}

export function fadeOutBy(): AnimationTriggerMetadata {
  return trigger('fadeOutBy', [transition('* => void', [style({ opacity: 1 }), animate('{{duration}}ms ease-in', style({ opacity: 0 }))])]);
}

export function fadeInOut(): AnimationTriggerMetadata {
  return trigger('fadeInOut', [
    transition('* => void', [style({ opacity: 1 }), animate('.4s ease-in', style({ opacity: 0 }))]),
    transition('void => *', [style({ opacity: 0 }), animate('.4s ease-in', style({ opacity: 1 }))])
  ]);
}

export function fadeInOutBy(): AnimationTriggerMetadata {
  return trigger('fadeInOutBy', [
    transition('void => *', [style({ opacity: 0 }), animate('{{duration}}ms ease-in', style({ opacity: 1 }))]),
    transition('* => void', [style({ opacity: 1 }), animate('{{duration}}ms ease-out', style({ opacity: 0 }))])
  ]);
}

export function fadeInOutDelay(): AnimationTriggerMetadata {
  return trigger('fadeInOutDelay', [
    transition('void => *', [style({ opacity: 0 }), animate('.2s {{delay}}ms ease-in', style({ opacity: 1 }))]),
    transition('* => void', [style({ opacity: 1 }), animate('.2s ease-out', style({ opacity: 0 }))])
  ]);
}

export function slideInToggle(): AnimationTriggerMetadata {
  return trigger('slideInToggle', [
    transition('void => *', [style({ transform: 'translateY(100%)' }), animate('300ms ease-in', style({ transform: 'translateY(0%)' }))]),
    transition('* => void', [style({ opacity: 1 }), animate('400ms ease-in-out', style({ opacity: 0 }))])
  ]);
}

export function slideInOut(): AnimationTriggerMetadata {
  return trigger('slideInOut', [
    transition('void => *', [style({ transform: 'translateX(100%)' }), animate('400ms ease', style({ transform: 'translateX(0%)' }))]),
    transition('* => void', [style({ transform: 'translateX(0%)' }), animate('400ms ease', style({ transform: 'translateX(100%)' }))])
  ]);
}

export function slideUp(): AnimationTriggerMetadata {
  return trigger('slideUp', [
    transition('void => *', [style({ transform: 'translateY(100%)' }), animate('200ms ease', style({ transform: 'translateY(0%)' }))]),
    transition('* => void', [style({ transform: 'translateY(0%)' }), animate('200ms ease', style({ transform: 'translateY(100%)' }))])
  ]);
}

export function slideInOutAnimation() {
  return trigger('slideInOutAnimation', [
    state(
      'in',
      style({
        height: '*',
        opacity: '1',
        visibility: 'visible'
      })
    ),
    state(
      'out',
      style({
        height: '0px',
        opacity: '0',
        visibility: 'hidden'
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            height: '0px'
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden'
          })
        )
      ])
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible'
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            height: '*'
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1'
          })
        )
      ])
    ])
  ]);
}
export function tooltip() {
  /** Animation that transitions a tooltip in and out. */
  return trigger('tooltip', [
    transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
    transition(':leave', [animate(300, style({ opacity: 0 }))])
  ]);
}
export function slidePanelSide(): AnimationTriggerMetadata {
  return trigger('slidePanelSide', [
    state('left', style({ transform: 'translateX(0)' })),
    state('right', style({ transform: 'translateX(-50%)' })),
    transition('* => *', animate('300ms ease-in-out'))
  ]);
}

export function expandCollapse(): AnimationTriggerMetadata {
  return trigger('expandCollapse', [
    state('cancel-animation', style({ height: '0px', minHeight: '0px' }), { params: { minHeight: '0px' } }),
    state('collapsed', style({ height: '{{ minHeight }}', minHeight: '{{ minHeight }}' }), { params: { minHeight: '0px' } }),
    state('expanded', style({ height: '*', minHeight: '*' })),
    transition('expanded <=> collapsed', animate(`${animationDuration}ms ease-in-out`)),
    transition('void => *', animate(`0ms`))
  ]);
}

export function expandCollapseSimpler(): AnimationTriggerMetadata {
  return trigger('expandCollapseSimpler', [
    transition('void => *', [
      style({ height: '0', minHeight: '0' }),
      animate(`${animationDuration}ms ease-in-out`, style({ height: '*', minHeight: '*' }))
    ]),
    transition('* => void', [
      style({ height: '*', minHeight: '*' }),
      animate(`${animationDuration}ms ease-in-out`, style({ height: '0', minHeight: '0' }))
    ])
  ]);
}

export function growAnimation(): AnimationTriggerMetadata {
  return trigger('growAnimation', [transition(':increment', [style({ transform: 'scale(1.5)' }), animate('0.8s ease-out', style('*'))])]);
}

export function preventInitialChildAnimations(): AnimationTriggerMetadata {
  return trigger('preventInitialChildAnimations', [transition(':enter', [query(':enter', [], { optional: true })])]);
}

const blinking = [
  style({ opacity: 0, offset: 0 }),
  style({ opacity: 0, offset: 0.25 }),
  style({ opacity: 0, offset: 0.5 }),
  style({ opacity: 0, offset: 0.75 }),
  style({ opacity: 0, offset: 1 })
];

export function oddChange(): AnimationTriggerMetadata {
  return trigger('oddChange', [
    transition(
      ':increment',
      group([
        query('.odd-wrapper', [animate(`${animationDuration}ms ease-out`, style({ opacity: 0 }))]),
        query(
          '.animation-helper',
          [
            style({ bottom: '0rem', display: 'block', color: `#FFF`, opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ bottom: '1.2rem', opacity: 1 }))
          ],
          { optional: true }
        ),
        query(
          '.animation-helper-color',
          [
            style({ display: 'block', color: `#FFF`, bottom: '-2rem', right: '1.1rem', opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ bottom: '0', opacity: 1 }))
          ],
          { optional: true }
        ),

        query(
          '.increased',
          [
            style({ top: '36px', display: 'block', opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ top: '20px', opacity: 1 })),
            animate(`3s ease-out`, keyframes(blinking)),
            animate(`${animationDuration}ms ease-out`, style({ top: '0', display: 'none', opacity: 0 }))
          ],
          { optional: true }
        ),
        query(
          '.top-arrow-wrapp',
          [
            style({
              top: '-10px',
              right: '-10px',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center', // Aligns along the main axis (horizontal) to the end
              alignItems: 'center',
              opacity: 0,
              transform: 'rotate(45deg)',
              width: '20px',
              height: '20px',
              borderBottom: '4px solid #1a1c1f',
              backgroundColor: '#1a1c1f'
            }),
            animate(`${arrowAnimationDuration}ms ease-out`, style({ opacity: 1 })),
            animate('2s', style({ opacity: 1 })), // Delay for 2 seconds after opacity animation
            animate(`${arrowAnimationDuration}ms ease-out`, style({ opacity: 0 }))
          ],
          { optional: true }
        ),
        query(
          '.right-top-arrow',
          [
            style({
              width: '16px',
              height: '16px',
              display: 'block',
              opacity: 0,
              backgroundColor: '#60d283'
            }),
            animate(`${arrowAnimationDuration}ms ease-out`, style({ opacity: 1 }))
          ],
          { optional: true }
        ),
        query('.change-background', [style({ backgroundColor: '#1e2122', borderBottom: '3px solid #1e2122' })], { optional: true })
      ])
    ),
    transition(
      ':decrement',
      group([
        query('.odd-wrapper', [animate(`${animationDuration}ms ease-out`, style({ opacity: 0 }))]),
        query(
          '.animation-helper',
          [
            style({ bottom: '2.4rem', display: 'block', color: `#FFF`, opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ bottom: '1.2rem', opacity: 1 }))
          ],
          { optional: true }
        ),
        query(
          '.animation-helper-color',
          [
            style({ display: 'block', right: '1.1rem', bottom: '2rem', color: `#FFF`, opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ bottom: '0', opacity: 1 }))
          ],
          { optional: true }
        ),
        query(
          '.decreased',
          [
            style({ bottom: '36px', display: 'block', opacity: 0 }),
            animate(`${animationDuration}ms ease-out`, style({ bottom: '20px', opacity: 0 })),
            animate(`3s ease-out`, keyframes(blinking)),
            animate(`${animationDuration}ms ease-out`, style({ bottom: '0', display: 'none', opacity: 0 }))
          ],
          { optional: true }
        ),
        query(
          '.bottom-arrow-wrapp',
          [
            style({
              bottom: '-10px',
              right: '-10px',
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center', // Aligns along the main axis (horizontal) to the end
              alignItems: 'center',
              opacity: 0,
              transform: 'rotate(-45deg)',
              width: '20px',
              height: '20px',
              borderTop: '4px solid #1a1c1f',
              backgroundColor: '#1a1c1f'
            }),
            animate(`${arrowAnimationDuration}ms ease-out`, style({ opacity: 1 })),
            animate('2s', style({ opacity: 1 })), // Delay for 2 seconds after opacity animation
            animate(`${arrowAnimationDuration}ms ease-out`, style({ opacity: 0 }))
          ],
          { optional: true }
        ),
        query(
          '.right-bottom-arrow',
          [
            style({
              width: '17px',
              height: '17px',
              display: 'block',
              opacity: 0,
              backgroundColor: '#ff5955'
            }),
            animate(`${arrowAnimationDuration}ms ease-out`, style({ opacity: 1 }))
          ],
          { optional: true }
        ),
        query('.change-background', [style({ backgroundColor: '#1e2122', borderTop: '3px solid #1e2122' })], { optional: true })
      ])
    )
  ]);
}
