<div
  [ngClass]="[(accountQuery.userData$ | async) && (accountQuery.userData$ | async).userStatus === 'PCHANG' ? 'disable-container' : '']"
  class="breadcrumb-container">
  <!--
    *****
    *****   Left section (back and home buttons)
    *****
  -->
  <div class="edge-container nav-icons">
    <i (click)="goBack()" [category]="'Header'" [click]="true" [label]="'Go Back Button'" [title]="'Back' | transloco"
      analytics aria-hidden="true" class="fa fa-caret-left"></i>
    <div (click)="goHome()" [category]="'Header'" [click]="true" [label]="'Home Button'" [title]="'Home' | transloco"
      analytics class="logo"></div>
  </div>

  <!--
    *****
    *****   Title
    *****
  -->
  <div (click)="goHome()" [category]="'Header0'" [click]="true" [label]="'Title'" analytics class="breadcrumb-title">
    <div class="text">{{ title }}</div>
  </div>

  <div class="edge-container flex">
    <!-- <div *ngIf="accountQuery.isAuthenticated$ | async; else loggedOutActionTemplate" class="authenticated">
      <div [class.hide]="isAccountPage || isBonusPage" [routerLink]="['/account/bonus']" class="account-section">

        <app-bomb-bonus [name]="'Bonus'"
          [surname]="'!!!'"></app-bomb-bonus>
      </div>
    </div> -->
    <div *ngIf="accountQuery.isAuthenticated$ | async; else loggedOutActionTemplate" class="authenticated">
      <div [class.hide]="isAccountPage" [routerLink]="['/account']" class="account-section">

        <app-user-avatar [name]="(accountQuery.userData$ | async).name"
          [surname]="(accountQuery.userData$ | async).surname"></app-user-avatar>
      </div>
    </div>
  </div>
</div>

<app-phone-unverified-tooltip></app-phone-unverified-tooltip>

<ng-template #loggedOutActionTemplate>
  <ng-container [ngSwitch]="loggedOutAction">
    <!--
      *****
      *****   Login & Register buttons
      *****
    -->
    <span (click)="navigateToLogin()" *ngSwitchCase="'login'" [category]="'Header'" [click]="true"
      [label]="'Log In Button'" analytics class="sign-in">
      <svg class="login-icon" fill="none" height="18" width="20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 4L7.6 5.4 10.2 8H0v2h10.2l-2.6 2.6L9 14l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2h-8v2h8v14z"
          fill="#D0DADE" />
      </svg>
      <span class="text" transloco="Sign In"></span>
    </span>
    <span *ngSwitchCase="'register'" [category]="'Header'" [click]="true" [label]="'Register Button'"
      [routerLink]="['/register']" analytics class="sign-in hide">
      <i class="fa fa-user-plus icon" aria-hidden="true"></i>
      <span class="text" transloco="Register"></span>
    </span>
  </ng-container>
</ng-template>