import { CityModel, CountryModel, StateModel, TelephoneExtensionModel } from './location.model';

export interface RegistrationState {
  activationOption: string;
  registerOptionTriggered: boolean;
  verificationInProgress: boolean;
  registerConfirmation: boolean;
  isActivated: boolean;
  resendSucceeded: boolean;
  activationErrorMessage: string;
  resendErrorMessage: string;
  activationUsername: string;
  blinkingServiceEnabled: boolean;
  currencies: CurrencyModel[];
  countries: CountryModel[];
  statesOfResidence: StateModel[];
  cities: CityModel[];
  telephoneExtensions: TelephoneExtensionModel[];
  newAgentForm: {
    title: string;
    formContent: string;
  };
  credentials: {
    user: string;
    pass: string;
  };
}

export class CurrencyModel {
  isoCode: string;
  name: string;
  symbol: string;

  constructor(init: CurrencyModel) {
    Object.assign(this, init);
  }
}

export class RegistrationModel {
  username: string;
  password: string;
  email: string;
  currency: string;
  mobilePrefix: string;
  mobileNumber: number;
  isPoliticallyExposed: boolean;
  receiveEmails: string;
  bannerTag: string;
  promoCode?: number;

  constructor(init: Partial<RegistrationModel>) {
    Object.assign(this, init);
  }
}

export class LongFormRegistrationModel {
  username: string;
  password: string;
  consent: boolean;
  mobilePrefix: string;
  mobileNumber: number;
  currency: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: Date;
  country: string;
  state: string;
  city: string;
  address: string;
  confirmation: boolean;
  promotionCode: string | undefined;
  email: string;
  citizenIDNumber: number;
  bankAccountNumber: string;
  useStandardVerification: boolean;

  constructor(init: Partial<LongFormRegistrationModel>) {
    Object.assign(this, init);
  }
}

export class QuickRegistrationModel {
  consent: boolean;
  password: string;
  mobilePrefix: string;
  mobileNumber: number;
  confirmation: boolean;
  promotionCode: string | undefined;
  currency: string;
  country: string;

  constructor(init: Partial<QuickRegistrationModel>) {
    Object.assign(this, init);
  }
}

export class RegistrationApiModel {
  UserDetails: UserDetailsModel;
  UserAddressDetails: UserAddressDetailsModel[];
  PhoneContactDetails?: PhoneContactDetailsModel[];
  ExtraDetails: ExtraDetailsModel[];
  SecurityDetails: SecurityDetailsModel;

  constructor(init: Partial<RegistrationApiModel>) {
    Object.assign(this, init);
  }
}

export class UserDetailsModel {
  BirthDate: string;
  Name: string;
  Surname: string;
  GenderCode: string;
  Currency: any;
  Username: string;
  Email?: string;
  CountryCode: any;
  LanguageCode: string;

  constructor(init: Partial<UserDetailsModel>) {
    Object.assign(this, init);
  }
}

export class UserAddressDetailsModel {
  Id: number;
  CountryCode: string;
  Region: string;
  Province: string;
  City: string;
  Line1: string;
  PostalCode: string;
  AddressTypeCode: string;

  constructor(init: Partial<UserAddressDetailsModel>) {
    Object.assign(this, init);
  }
}

export class PhoneContactDetailsModel {
  Id: number;
  PhoneContactTypeCode: string;
  ContactNumber: string;

  constructor(init: Partial<PhoneContactDetailsModel>) {
    Object.assign(this, init);
  }
}

export class ExtraDetailsModel {
  Id?: number;
  PropertyName: string;
  PropertyValue: string;

  constructor(init: Partial<ExtraDetailsModel>) {
    Object.assign(this, init);
  }
}

export class SecurityDetailsModel {
  SecurityQuestionCode: string;
  SecurityAnswer: string;

  constructor(init: Partial<SecurityDetailsModel>) {
    Object.assign(this, init);
  }
}
