import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { BlogArticle } from 'src/app/modules/blog/models/blog-article.model';
import { BlogCategory } from 'src/app/modules/blog/models/blog-category.model';
import { BlogConfig } from 'src/app/modules/blog/models/blog-config.model';
import { APIType } from 'src/app/shared/models/api.model';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  config$: BehaviorSubject<BlogConfig> = new BehaviorSubject(undefined);
  latestBlogArticles$: BehaviorSubject<BlogArticle[]> = new BehaviorSubject([]);
  popularBlogArticles$: BehaviorSubject<BlogArticle[]> = new BehaviorSubject([]);

  categories$: BehaviorSubject<{ [slug: string]: BlogCategory }> = new BehaviorSubject({});

  constructor(private readonly apiService: APIService) {}

  fetchConfig(): void {
    this.apiService
      .get(APIType.CMS, 'blog-config', { skipLocale: true })
      .pipe(map(this.mapBlogConfigResponse.bind(this)))
      .subscribe();
  }

  fetchLatestArticles(): void {
    if (this.latestBlogArticles$.value.length > 0) {
      return;
    }

    this.apiService
      .get(APIType.CMS, 'blog-articles?_sort=published_at:DESC', { skipLocale: true })
      .pipe(
        map(this.mapCMSArticlesToModel.bind(this)),
        tap((articles: BlogArticle[]) => {
          this.latestBlogArticles$.next(articles);
        })
      )
      .subscribe();
  }

  fetchPopularArticles(): void {
    if (this.popularBlogArticles$.value.length > 0) {
      return;
    }

    this.apiService
      .get(APIType.CMS, 'blog-articles?popular_eq=true&_sort=published_at:DESC', { skipLocale: true })
      .pipe(
        map(this.mapCMSArticlesToModel.bind(this)),
        tap((articles: BlogArticle[]) => {
          this.popularBlogArticles$.next(articles);
        })
      )
      .subscribe();
  }

  fetchCategory(slug: string): void {
    if (this.categories$.value[slug]) {
      this.categories$.next({
        ...this.categories$.value,
        [slug]: this.categories$.value[slug]
      });
      return;
    }

    this.apiService
      .get(APIType.CMS, `blog-categories?slug_eq=${slug}`, { skipLocale: true })
      .pipe(
        map(response => {
          if (response?.length > 0) {
            return this.mapCMSCategoryToModel(response[0]);
          }
          return response;
        }),
        tap((category: BlogCategory) => {
          this.categories$.next({
            ...this.categories$.value,
            [category.slug]: category
          });
        })
      )
      .subscribe();
  }

  getArticle(slug: string): Observable<BlogArticle | undefined> {
    return this.apiService.get(APIType.CMS, `blog-articles?slug_eq=${slug}`, { skipLocale: true }).pipe(
      map(response => {
        if (response?.length > 0) {
          return this.mapCMSArticlesToModel(response)[0];
        } else {
          return undefined;
        }
      })
    );
  }

  private mapCMSArticlesToModel(articles: any[]): BlogArticle[] {
    return articles.map(this.mapCMSArticleToModel.bind(this));
  }

  private mapCMSArticleToModel(article: any): BlogArticle {
    return {
      id: article.id,
      publishedAt: article.published_at,
      slug: article.slug,
      title: article.title,
      subtitle: article.subtitle,
      content: article.BlogArticleContent.map(content => ({
        id: content.id,
        imageUrl: content.image?.formats?.large?.url || content.image?.url || undefined,
        imageAlternativeText: content.image?.alternativeText || undefined,
        text: content.text || undefined
      })),
      previewImageUrl: article.image.formats.medium?.url || article.image.formats.thumbnail?.url || article.image.url,
      thumbnailImageUrl: article.image.formats.thumbnail?.url,
      imageUrl: article.image.formats?.large?.url || article.image.url,
      alternativeText: article.image.alternativeText,
      popular: article.image.popular || false,
      ...(article.blog_category && {
        category: {
          id: article.blog_category.id,
          order: article.blog_category.order,
          slug: article.blog_category.slug,
          title: article.blog_category.title,
          subtitle: article.blog_category.subtitle
        }
      })
    };
  }

  private mapCMSCategoryToModel(category: any): BlogCategory {
    return {
      id: category.id,
      order: category.order,
      slug: category.slug,
      title: category.title,
      subtitle: category.subtitle,
      articles: (category.blog_articles || []).map(this.mapCMSArticleToModel.bind(this))
    };
  }

  private mapBlogConfigResponse(config: any): void {
    this.config$.next({
      visible: config.visible,
      showPopular: config.show_popular
    });
  }
}
