import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

// Sentry.init({
//   dsn: 'https://74febffb596c4a298360a70ae8944c1c@o4505431700013056.ingest.sentry.io/4505441862090752',
//   environment: environment.production ? 'production' : 'development',
//   release: 'latest',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//       routingInstrumentation: Sentry.routingInstrumentation
//     }),
//     new Sentry.Replay({
//       maskAllText: false,
//       maskAllInputs: false
//     })
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 0.5,
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => {
      console.error(err);
    });
});
