/* tslint:disable */
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AccountSideMenuComponent } from 'src/app/shared/components/account-side-menu/account-side-menu.component';
import { BombBonusComponent } from 'src/app/shared/components/bomb-bonus/bomb-bonus.component';
import { BonusComponent } from 'src/app/shared/components/bonus/bonus.component';
import { BonusDetailsComponent } from 'src/app/shared/components/bonus/components/bonus-details/bonus-details.component';
import { BonusProgressComponent } from 'src/app/shared/components/bonus/components/bonus-progress/bonus-progress.component';
import { BonusSectionExternalComponent } from 'src/app/shared/components/bonus/components/bonus-section-external/bonus-section-external.component';
import { BonusSectionComponent } from 'src/app/shared/components/bonus/components/bonus-section/bonus-section.component';
import { BonusViewComponent } from 'src/app/shared/components/bonus/components/bonus-view/bonus-view.component';
import { SingleBonusComponent } from 'src/app/shared/components/bonus/components/single-bonus/single-bonus.component';
import { CouponDetailsComponent } from 'src/app/shared/components/coupon-details-wrapper/coupon-details/coupon-details.component';
import { CouponEvaluationComponent } from 'src/app/shared/components/coupon-evaluation/coupon-evaluation.component';
import { DraggableElementComponent } from 'src/app/shared/components/draggable-element/draggable-element.component';
import { EventDetailsLiveComponent } from 'src/app/shared/components/events/event-details-live/event-details-live.component';
import { EventHeaderLiveComponent } from 'src/app/shared/components/events/event-header-live/event-header-live.component';
import { EventMatchInfoComponent } from 'src/app/shared/components/events/event-match-info/event-match-info.component';
import { EventsSummaryLiveComponent } from 'src/app/shared/components/events/events-summary/live-widget/events-summary-live.component';
import { LiveEventsSummaryComponent } from 'src/app/shared/components/events/events-summary/live/live-events-summary.component';
import { OutrightEventWithSelectorComponent } from 'src/app/shared/components/events/layouts/outrights/outright-event-with-selector/outright-event-with-selector.component';
import { OutrightEventWithoutSelectorComponent } from 'src/app/shared/components/events/layouts/outrights/outright-event-without-selector/outright-event-without-selector.component';
import { OutrightEventComponent } from 'src/app/shared/components/events/layouts/outrights/outright-event.component';
import { SingleLineEventLiveComponent } from 'src/app/shared/components/events/layouts/single-line-event-live/single-line-event-live.component';
import { MultiLineLiveMarketOddsComponent } from 'src/app/shared/components/events/market-odds/live/multi-line/multi-line-live-market-odds.component';
import { SingleLineLiveMarketOddsComponent } from 'src/app/shared/components/events/market-odds/live/single-line/single-line-live-market-odds.component';
import { OutrightMarketOddsComponent } from 'src/app/shared/components/events/market-odds/pre-match/outright/outright-market-odds.component';
import { NotAvailableMessageComponent } from 'src/app/shared/components/events/not-available-message/not-available-message.component';
import { HelpItemsComponent } from 'src/app/shared/components/help-items/help-items.component';
import { InsufficientBalanceComponent } from 'src/app/shared/components/insufficient-balance/insufficient-balance.component';
import { LanguagePageComponent } from 'src/app/shared/components/langauge-page/language-page.component';
import { LanguageSelectorComponent } from 'src/app/shared/components/langauge-selector/language-selector.component';
import { LoadingBarComponent } from 'src/app/shared/components/loading/loading-bar/loading-bar.component';
import { MenuHowToPlayComponent } from 'src/app/shared/components/menu/menu-how-to-play/menu-how-to-play.component';
import { MenuPromosComponent } from 'src/app/shared/components/menu/menu-promos/menu-promos.component';
import { MenuQuicklinksComponent } from 'src/app/shared/components/menu/menu-quicklinks/menu-quicklinks.component';
import { MenuSportsComponent } from 'src/app/shared/components/menu/menu-sports/menu-sports.component';
import { MenuTabsComponent } from 'src/app/shared/components/menu/menu-tabs/menu-tabs.component';
import { MenuVirtualsComponent } from 'src/app/shared/components/menu/menu-virtuals/menu-virtuals.component';
import { MenuWrapperComponent } from 'src/app/shared/components/menu/menu-wrapper/menu-wrapper.component';
import { LiveOddComponent } from 'src/app/shared/components/odds/live/live-odd/live-odd.component';
import { LiveOddsWidgetComponent } from 'src/app/shared/components/odds/live/live-odds-widget/live-odds-widget.component';
import { LiveOddsComponent } from 'src/app/shared/components/odds/live/live-odds/live-odds.component';
import { OddWithLabelComponent } from 'src/app/shared/components/odds/pre-match/odd-with-label/odd-with-label.component';
import { PhoneUnverifiedTooltipComponent } from 'src/app/shared/components/phone-unverified-tooltip/phone-unverified-tooltip.component';
import { PhoneVerificationComponent } from 'src/app/shared/components/phone-verification/phone-verification.component';
import { ProceedBarComponent } from 'src/app/shared/components/proceed-bar/proceed-bar.component';
import { RecentBetMatchComponent } from 'src/app/shared/components/recent-bet-match/recent-bet-match.component';
import { ExpiredBookedBetPromptComponent } from 'src/app/shared/components/saved-coupons/expired-booked-bet-prompt/expired-booked-bet-prompt.component';
import { SEONavShortcutsComponent } from 'src/app/shared/components/seo-nav-shortcuts/seo-nav-shortcuts.component';
import { ServerTimeComponent } from 'src/app/shared/components/server-time/server-time.component';
import { TransactionsSideMenuComponent } from 'src/app/shared/components/transactions-side-menu/transactions-side-menu.component';
import { UserAvatarComponent } from 'src/app/shared/components/user-avatar/user-avatar.component';
import { DynamicSvgMaskDirective } from 'src/app/shared/directives/dynamic-svg-mask.directive';
import { ExpiryDateInputMask } from 'src/app/shared/directives/expiry-date-input-mask.directive';
import { HideIfEmptyDirective } from 'src/app/shared/directives/hide-if-empty.directive';
import { PaymentPopulatorDirective } from 'src/app/shared/directives/payment-populator.directive';
import { ResizeOnScrollDirective } from 'src/app/shared/directives/resize-on-scroll.directive';
import { ScrollToTopPositionerDirective } from 'src/app/shared/directives/scroll-to-top-positioner.directive';
import { SelectAllDirective } from 'src/app/shared/directives/select-all.directive';
import { SelectedTabCenterizerDirective } from 'src/app/shared/directives/selected-tab-centerizer.directive';
import { ShowMoreDirective } from 'src/app/shared/directives/show-more.directive';
import { VirtualsLoaderDirective } from 'src/app/shared/directives/virtuals-loader.directive';
import { ZIndexDirective } from 'src/app/shared/directives/z-index.directive';
import { FilterBySearchOnTitlePipe } from 'src/app/shared/pipes/filter-by-search-title.pipe';
import { FilterByTermAndPropertyPipe } from 'src/app/shared/pipes/filter-by-term-property.pipe';
import { FilterSearchPipe } from 'src/app/shared/pipes/filter-search.pipe';
import { FormatBytesPipe } from 'src/app/shared/pipes/format-bytes.pipe';
import { FormatCustomDateTimePipe } from 'src/app/shared/pipes/format-custom-date-time.pipe';
import { FormatDatePipe } from 'src/app/shared/pipes/format-date.pipe';
import { FormatOddPipe } from 'src/app/shared/pipes/format-odd.pipe';
import { GroupNamePipe } from 'src/app/shared/pipes/group-name.pipe';
import { GroupOddsByMarketPipe } from 'src/app/shared/pipes/group-odds-by-market.pipe';
import { HasGroupingsPipe } from 'src/app/shared/pipes/has-groupings.pipe';
import { IncludesPipe } from 'src/app/shared/pipes/includes.pipe';
import { IsGroupingVisiblePipe } from 'src/app/shared/pipes/is-grouping-visible.pipe';
import { IsOddSelectedPipe } from 'src/app/shared/pipes/is-odd-selected.pipe';
import { LastMinuteSortByPipe } from 'src/app/shared/pipes/last-minute-sort-by.pipe';
import { LiveTeamNameOrderPipe } from 'src/app/shared/pipes/live-team-name-order.pipe';
import { OddChangedPipe } from 'src/app/shared/pipes/odd-changed.pipe';
import { OddPipe } from 'src/app/shared/pipes/odd.pipe';
import { RemoveCommaPipe } from 'src/app/shared/pipes/remove-comma.pipe';
import { SortByOddPipe } from 'src/app/shared/pipes/sort-by-odd-value.pipe';
import { TeamNameOrderPipe } from 'src/app/shared/pipes/team-name-order.pipe';
import { TitleCasePipe } from 'src/app/shared/pipes/title-case.pipe';
import { WeekDaysPipe } from 'src/app/shared/pipes/weekdays.pipe';
import { SwiperModule } from 'swiper/angular';
import { AZMenuComponent } from './components/a-z-menu/a-z-menu.component';
import { CardComponent } from './components/app-card/app-card.component';
import { BannerRotatorComponent } from './components/banner-rotator/banner-rotator.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ButtonComponent } from './components/button/button.component';
import { CashoutComponent } from './components/cashout/cashout.component';
import { ChatPopupComponent } from './components/chat-popup/chat-popup.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { CouponCheckComponent } from './components/coupon-check/coupon-check.component';
import { CouponDetailsEventsComponent } from './components/coupon-details-wrapper/coupon-details-events/coupon-details-events.component';
import { CouponDetailsMatchesComponent } from './components/coupon-details-wrapper/coupon-details-matches/coupon-details-matches.component';
import { CouponDetailsStandardComponent } from './components/coupon-details-wrapper/coupon-details-standard/coupon-details-standard.component';
import { CouponDetailsSystemComponent } from './components/coupon-details-wrapper/coupon-details-system/coupon-details-system.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { EventDetailsComponent } from './components/events/event-details/event-details.component';
import { EventHeaderComponent } from './components/events/event-header/event-header.component';
import { EventsMarketsHeaderComponent } from './components/events/events-markets-header/events-markets-header.component';
import { EventsSummaryComponent } from './components/events/events-summary/pre-match/events-summary.component';
import { CorrectScoreEventComponent } from './components/events/layouts/correct-scores-event/correct-score-event.component';
import { CorrectScoreEventWithSelectorComponent } from './components/events/layouts/correct-scores-event/with-selector/correct-score-event-with-selector.component';
import { CorrectScoreEventWithoutSelectorComponent } from './components/events/layouts/correct-scores-event/without-selector/correct-score-event-without-selector.component';
import { MultiLineEventComponent } from './components/events/layouts/multi-line-event/multi-line-event.component';
import { MultiLineEventWithSelectorComponent } from './components/events/layouts/multi-line-event/with-selector/multi-line-event-with-selector.component';
import { MultiLineEventWithoutSelectorComponent } from './components/events/layouts/multi-line-event/without-selector/multi-line-event-without-selector.component';
import { SingleLineEventComponent } from './components/events/layouts/single-line-event/single-line-event.component';
import { SingleLineEventWithSelectorComponent } from './components/events/layouts/single-line-event/with-selector/single-line-event-with-selector.component';
import { SingleLineEventWithoutSelectorComponent } from './components/events/layouts/single-line-event/without-selector/single-line-event-without-selector.component';
import { TabularEventComponent } from './components/events/layouts/tabular-event/tabular-event.component';
import { TabularEventWithSelectorComponent } from './components/events/layouts/tabular-event/with-selector/tabular-event-with-selector.component';
import { TabularEventWithoutSelectorComponent } from './components/events/layouts/tabular-event/without-selector/tabular-event-without-selector.component';
import { CorrectScoresMarketOddsComponent } from './components/events/market-odds/pre-match/correct-scores/correct-scores-market-odds.component';
import { MultiLineMarketOddsComponent } from './components/events/market-odds/pre-match/multi-line/multi-line-market-odds.component';
import { SingleLineMarketOddsComponent } from './components/events/market-odds/pre-match/single-line/single-line-market-odds.component';
import { TabularMarketOddsComponent } from './components/events/market-odds/pre-match/tabular/tabular-market-odds.component';
import { FooterSectionComponent } from './components/footer-section/footer-section.component';
import { FormItemGroupComponent } from './components/form-item-group/form-item-group.component';
import { FormMessageComponent } from './components/form-message/form-message.component';
import { HeaderComponent } from './components/header/header.component';
import { InnerCardComponent } from './components/inner-card/inner-card.component';
import { LegendTableComponent } from './components/legend-table/legend-table.component';
import { LiveFavouriteToggleComponent } from './components/liveFavouriteToggle/live-favourite-toggle.component';
import { MenuPageWrapperComponent } from './components/menu/menu-page-wrapper/menu-page-wrapper.component';
import { ChooseYourPromoSectionComponent } from './components/menu/menu-promos/choose-your-promo-section/choose-your-promo-section.component';
import { AccountInfoLineComponent } from './components/nav-bar/account-info-line/account-info-line.component';
import { NavBarItemsComponent } from './components/nav-bar/nav-bar-items/nav-bar-items.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { OddGroupComponent } from './components/odds/pre-match/odd-group/odd-group.component';
import { OddComponent } from './components/odds/pre-match/odd/odd.component';
import { OverlayDropdownComponent } from './components/overlay-dropdown/overlay-dropdown.component';
import { PhoneVerificationPopupComponent } from './components/phone-verification-popup/phone-verification-popup.component';
import { PlaceholderComponent } from './components/placeholder/placeholder.component';
import { ProceedBarsContainerComponent } from './components/proceed-bars-container/proceed-bars-container.component';
import { PromoFabComponent } from './components/promo-fab/promo-fab.component';
import { PromoPaymentFabComponent } from './components/promo-payment-fab/promo-payment-fab.component';
import { RecentSearchesComponent } from './components/recent-searches/recent-searches.component';
import { RegionAreaDropdownComponent } from './components/region-area-dropdown/region-area-dropdown.component';
import { SavedCouponsComponent } from './components/saved-coupons/saved-coupons.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SectionOverviewComponent } from './components/section-overview/section-overview.component';
import { SfkChatComponent } from './components/sfk-chat/sfk-chat.component';
import { SidebarBoxComponent } from './components/sidebar-box/sidebar-box.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { FavouriteToggleComponent } from './components/sports/favourite-toggle/favourite-toggle.component';
import { SportLinkComponent } from './components/sports/sport-link/sport-link.component';
import { SportsListComponent } from './components/sports/sports-list/sports-list.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { ToastNotificationComponent } from './components/toast-notification/toast-notification.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { VideoComponent } from './components/video/video.component';
import { WarningMessageComponent } from './components/warning-message/warning-message.component';
import { WebNotificationsTriggerComponent } from './components/web-notifications-trigger/web-notifications-trigger.component';
import { AmountInputMask } from './directives/amount-input-mask.directive';
import { AnalyticsDirective } from './directives/analytics.directive';
import { ArrowRotateDirective } from 'src/app/shared/directives/fa-arrow-rotation.directive';
import { BadgeDirective } from './directives/badge.directive';
import { DropdownArrowRotatorDirective } from './directives/dropdown-arrow-rotator.directive';
import { DynamicSvgBackgroundDirective } from './directives/dynamic-svg-background.directive';
import { NavBarSvgLoaderDirective } from './directives/navbar-svg-loader.directive';
import { RotatorContainerDirective } from './directives/rotator-container.directive';
import { DecimalFormatterDirective } from './directives/two-decimal-formatter.directive';
import { UnselectAllDirective } from './directives/unselect-all.directive';
import { BetFinalStateToUI } from './pipes/bet-final-state-to-ui.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { FilterMatchesPipe } from './pipes/filter-matches.pipe';
import { Highlight } from './pipes/highlight.pipe';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SafeHTML } from './pipes/safe-html.pipe';
import { DisplayScorePipe } from './pipes/score.pipe';
import { TransactionPreviewComponent } from './components/transactions-side-menu/transaction-preview/transaction-preview.component';
import { LanguagePickerMenuComponent } from 'src/app/shared/components/language-picker-menu/language-picker-menu.component';
import { SportModule } from 'src/app/modules/sport/sport.module';
import { PopupBannersComponent } from 'src/app/shared/components/popup-banners/popup-banners.component';
import { PopupBannerFabComponent } from 'src/app/shared/components/popup-banner-fab/popup-banner-fab.component';

// Components
// Events
// Directives
// Pipes
const DIRECTIVES = [
  BadgeDirective,
  DropdownArrowRotatorDirective,
  DynamicSvgBackgroundDirective,
  DynamicSvgMaskDirective,
  HideIfEmptyDirective,
  NavBarSvgLoaderDirective,
  PaymentPopulatorDirective,
  ResizeOnScrollDirective,
  RotatorContainerDirective,
  ScrollToTopPositionerDirective,
  SelectAllDirective,
  SelectedTabCenterizerDirective,
  UnselectAllDirective,
  ShowMoreDirective,
  VirtualsLoaderDirective,
  ZIndexDirective,
  AnalyticsDirective,
  DecimalFormatterDirective,
  ExpiryDateInputMask,
  AmountInputMask,
  ArrowRotateDirective
];

const COMPONENTS = [
  AccountInfoLineComponent,
  BannerRotatorComponent,
  BonusComponent,
  BombBonusComponent,
  BonusDetailsComponent,
  BonusProgressComponent,
  BonusSectionComponent,
  BonusSectionExternalComponent,
  SingleBonusComponent,
  BonusViewComponent,
  BreadcrumbComponent,
  ServerTimeComponent,
  ButtonComponent,
  ChatPopupComponent,
  CardComponent,
  CashoutComponent,
  CouponCheckComponent,
  CouponCheckComponent,
  CouponDetailsEventsComponent,
  CouponEvaluationComponent,
  ContactSupportComponent,
  DropdownComponent,
  ExpiredBookedBetPromptComponent,
  FavouriteToggleComponent,
  FooterSectionComponent,
  FormItemGroupComponent,
  FormMessageComponent,
  HelpItemsComponent,
  HelpItemsComponent,
  InnerCardComponent,
  PopupBannersComponent,
  PopupBannerFabComponent,
  InsufficientBalanceComponent,
  LanguagePageComponent,
  LanguagePageComponent,
  LanguageSelectorComponent,
  LegendTableComponent,
  LiveOddComponent,
  LiveOddsComponent,
  LiveFavouriteToggleComponent,
  LiveOddsWidgetComponent,
  LoadingBarComponent,
  MenuHowToPlayComponent,
  MenuPageWrapperComponent,
  MenuPromosComponent,
  MenuQuicklinksComponent,
  MenuSportsComponent,
  MenuTabsComponent,
  MenuVirtualsComponent,
  MenuWrapperComponent,
  NavBarComponent,
  NavBarItemsComponent,
  OddComponent,
  OddWithLabelComponent,
  OddGroupComponent,
  OverlayDropdownComponent,
  PhoneUnverifiedTooltipComponent,
  PhoneVerificationComponent,
  PhoneVerificationPopupComponent,
  PlaceholderComponent,
  PlaceholderComponent,
  ProceedBarComponent,
  ProceedBarsContainerComponent,
  RecentSearchesComponent,
  RegionAreaDropdownComponent,
  SavedCouponsComponent,
  ScrollToTopComponent,
  SfkChatComponent,
  SlidePanelComponent,
  SportLinkComponent,
  SportsListComponent,
  StatisticsComponent,
  ToastNotificationComponent,
  ToggleSwitchComponent,
  WarningMessageComponent,
  PromoFabComponent,
  PromoPaymentFabComponent,
  BottomSheetComponent,
  CouponDetailsComponent,
  CouponDetailsMatchesComponent,
  RecentBetMatchComponent,
  DraggableElementComponent,
  SectionOverviewComponent,
  WebNotificationsTriggerComponent,
  VideoComponent,
  HeaderComponent,
  AZMenuComponent,
  LanguagePickerMenuComponent,
  SEONavShortcutsComponent,
  AccountSideMenuComponent,
  SidebarComponent,
  SidebarBoxComponent,
  TransactionsSideMenuComponent
];

const EVENTS_COMPONENTS = [
  CorrectScoreEventComponent,
  CorrectScoreEventWithSelectorComponent,
  CorrectScoreEventWithoutSelectorComponent,
  CorrectScoresMarketOddsComponent,
  EventDetailsComponent,
  EventDetailsLiveComponent,
  EventHeaderComponent,
  EventHeaderLiveComponent,
  EventMatchInfoComponent,
  EventsMarketsHeaderComponent,
  EventsSummaryComponent,
  EventsSummaryLiveComponent,
  LiveEventsSummaryComponent,
  MultiLineEventComponent,
  MultiLineEventWithSelectorComponent,
  MultiLineEventWithoutSelectorComponent,
  MultiLineLiveMarketOddsComponent,
  MultiLineMarketOddsComponent,
  NotAvailableMessageComponent,
  OutrightEventComponent,
  OutrightEventWithSelectorComponent,
  OutrightEventWithoutSelectorComponent,
  OutrightMarketOddsComponent,
  SingleLineEventComponent,
  SingleLineEventLiveComponent,
  SingleLineEventWithSelectorComponent,
  SingleLineEventWithoutSelectorComponent,
  SingleLineLiveMarketOddsComponent,
  SingleLineMarketOddsComponent,
  TabularEventComponent,
  TabularEventWithSelectorComponent,
  TabularEventWithoutSelectorComponent,
  TabularMarketOddsComponent,
  CouponDetailsStandardComponent,
  CouponDetailsSystemComponent,
  UserAvatarComponent
];

const PIPES = [
  BetFinalStateToUI,
  CurrencyFormatPipe,
  CurrencyFormatPipe,
  DisplayScorePipe,
  FilterBySearchOnTitlePipe,
  FilterByTermAndPropertyPipe,
  FilterMatchesPipe,
  FilterSearchPipe,
  FormatOddPipe,
  FormatBytesPipe,
  GroupNamePipe,
  GroupOddsByMarketPipe,
  HasGroupingsPipe,
  Highlight,
  IncludesPipe,
  IsGroupingVisiblePipe,
  IsOddSelectedPipe,
  LastMinuteSortByPipe,
  ObjectKeysPipe,
  OddChangedPipe,
  FormatCustomDateTimePipe,
  OddPipe,
  OrderByPipe,
  OrderByPipe,
  RemoveCommaPipe,
  SafeHTML,
  SortByOddPipe,
  TeamNameOrderPipe,
  LiveTeamNameOrderPipe,
  TitleCasePipe,
  WeekDaysPipe,
  FormatDatePipe
];

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  SweetAlert2Module,
  ScrollingModule,
  TranslocoModule,
  SwiperModule
];

@NgModule({
  imports: [...MODULES],
  exports: [...COMPONENTS, ...EVENTS_COMPONENTS, ...DIRECTIVES, ...PIPES, ...MODULES],
  declarations: [
    ...COMPONENTS,
    ...EVENTS_COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ChooseYourPromoSectionComponent,
    HeaderComponent,
    AZMenuComponent,
    LanguagePickerMenuComponent,
    TransactionPreviewComponent
  ]
})
export class SharedModule {}
