import {
    Directive,
    ElementRef,
    HostListener,
    OnDestroy,
    Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appAmountInputMask]',
})
export class AmountInputMask implements OnDestroy {


    constructor(@Self() private ngControl: NgControl) { }

    ngAfterViewInit() {
    }

    @HostListener('keyup', ['$event']) keyUp(event) {
        const value = event.target.value
            .replace(/[^\d\/]|^[\/]*$/g, '')
        this.setValue(value);
    }

    setValue(v) {
        this.ngControl.control.setValue(v, { emitEvent: false });
    }

    ngOnDestroy() {
        this.setValue('');
    }
}
