import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

/**
 * This guard ensures the user has a valid auth token before
 * allowing access to a route.
 */
@Injectable()
export class NoMaintenanceGuard implements CanActivate {
  constructor(private readonly appConfig: AppConfigService, private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    try {
      if (!this.appConfig.get('maintenanceMode') && state.url === '/maintenance') {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    } catch {
      this.router.navigate(['/']);
      return false;
    }
  }
}
