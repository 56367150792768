export function smoothScrollTo({ top }: { top?: number } = {}): void {
  if ('scrollBehavior' in document.documentElement.style) {
    window.scrollTo({ top, behavior: 'smooth' });
  } else {
    scrollTo({ top });
  }
}

function scrollTo({ top = 0, duration = 400 }: { top?: number; duration?: number } = {}): void {
  let startTime: number;
  const initialScroll = window.scrollY;
  const distance = top - initialScroll;

  // If the distance is less than 80% of the screen, reduce the animation duration up to half
  const adjustedDuration = Math.max(Math.min(Math.abs(distance) / window.innerHeight / 0.8, 1) * duration, duration * 0.5);

  const timing = (t: number) => t * (2 - t); // ease out function
  const animate = (time: number) => {
    if (!startTime) {
      startTime = time;
    }

    let timeFraction = (time - startTime) / adjustedDuration;
    if (timeFraction > 1) {
      timeFraction = 1;
    }

    const progress = timing(timeFraction);
    window.scrollTo({ top: initialScroll + progress * distance });

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  };

  requestAnimationFrame(animate);
}
