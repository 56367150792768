import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountQuery } from 'src/app/core/state/account/account.query';

import { Observable } from 'rxjs';

import { BonusService } from 'src/app/core/services/bonus.service';
import { VariablesService } from 'src/app/core/services/variables.service';
import { expandCollapse } from 'src/app/shared/animations';
import { BonusModel } from 'src/app/shared/models/bonus.model';
import { ButtonType } from "../../../../models/button.model";

@Component({
  selector: 'account-bonus-section',
  templateUrl: './bonus-section.component.html',
  styleUrls: ['./bonus-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse()]
})
export class BonusSectionComponent {
  @Input() faIcon: string;
  @Input() sectionTitle: string;
  @Input() isViewing: boolean;
  @Input() bonuses: Observable<BonusModel[]>;
  @Input() sectionId: 'active' | 'paused' | 'inactive' | 'previous' | 'missed';
  @Input() lastSection: boolean = false;
  @Input() noBonusesText: string;
  @Output() readonly sectionClicked = new EventEmitter();

  readonly resumeButtonStyle = {
    background: this.variablesService.colorBrandAccent
  }
  buttonType = ButtonType;

  constructor(
    private readonly bonusService: BonusService,
    readonly accountQuery: AccountQuery,
    readonly variablesService: VariablesService
  ) { }

  onClick(event: any): void {
    this.sectionClicked.emit(event);
  }

  bonusTrackBy(item: BonusModel): number {
    return item.id;
  }

  onMoreInfoClicked(id: number): void {
    this.bonusService.getBonus(id);
  }

  onActivateClicked(id: number): void {

    this.bonusService.resetBonusUI();
    this.bonusService.activateBonus(id).subscribe(() => {
      this.bonusService.getUserBonuses().subscribe(() => {
        this.bonusService.updateViewingActive(true);
      });
    });
  }

  onResumeClicked(id: number): void {
    this.bonusService.resetBonusUI();
    this.bonusService.resumeBonus(id).subscribe(() => {
      this.bonusService.getUserBonuses().subscribe(() => {
        this.bonusService.updateViewingActive(true);
      });
    });
  }

  onPauseClicked(id: number): void {
    this.bonusService.resetBonusUI();
    this.bonusService.pauseBonus(id).subscribe(() => {
      this.bonusService.getUserBonuses().subscribe(() => {
        this.bonusService.updateViewingPaused(true);
      });
    });
  }
}
