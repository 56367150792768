import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MyAllBetsStore } from 'src/app/core/state/my-bets/my-all-bets.store';
import { MyAllBetsState, MyOpenBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';

@Injectable({ providedIn: 'root' })
export class MyAllBetsQuery extends QueryEntity<MyAllBetsState, RecentBetModel> {
  bets$ = this.selectAll();
  allBetsCount$ = this.selectCount();
  isLoading$ = this.selectLoading();

  constructor(protected store: MyAllBetsStore) {
    super(store);
  }
}
