import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth-guard.service';
import { DepositRouteGuard } from 'src/app/core/guards/deposit-route-guard.service';
import { NoAuthGuard } from 'src/app/core/guards/no-auth-guard.service';
import { RestrictRolesGuard } from 'src/app/core/guards/restrict-roles-guard.service';
import { VerifiedGuard } from 'src/app/core/guards/verified-guard.service';
import { AccountStatementComponent } from 'src/app/modules/account/components/account-statement/account-statement.component';
import { ChangeBankAccountNumberComponent } from 'src/app/modules/account/components/change-bank-account-number/change-bank-account-number.component';
import { ChangePhoneNumberComponent } from 'src/app/modules/account/components/change-phone-number/change-phone-number.component';
import { BankTransferComponent } from 'src/app/modules/account/components/deposit/components/bank-transfer/bank-transfer.component';
import { PaymentCompletedComponent } from 'src/app/modules/account/components/payment-completed/payment-completed.component';
import { PhoneNumberVerificationComponent } from 'src/app/modules/account/components/phone-number-verification/phone-number-verification.component';
import { VerificationChoiceComponent } from 'src/app/modules/account/components/verification-choice/verification-choice.component';
import { SingleBonusComponent } from 'src/app/shared/components/bonus/components/single-bonus/single-bonus.component';
import { CouponDetailsWrapperComponent } from 'src/app/shared/components/coupon-details-wrapper/coupon-details-wrapper.component';
import { UserType } from 'src/app/shared/models/account.model';
import { ProductType } from 'src/app/shared/models/application.model';
import { AccountPageWrapperComponent } from './components/account-page-wrapper/account-page-wrapper.component';
import { BetSearchWrapperComponent } from './components/bet-search-wrapper/bet-search-wrapper.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { DepositSuccessComponent } from './components/deposit/deposit.success.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { FundTransferComponent } from './components/fund-transfer/fund-transfer.component';
import { LoginPageWrapperComponent } from './components/login-page-wrapper/login-page-wrapper.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PaymentsListComponent } from './components/payments-list/payments-list.component';
import { PaymentsListPromoComponent } from 'src/app/modules/account/components/payment-list-promo/payments-list-promo.component';
import { SavedCardsComponent } from './components/saved-cards/saved-cards.component';
import { WithdrawalComponent } from './components/withdrawal/withdrawal.component';
import { BonusComponent } from 'src/app/shared/components/bonus/bonus.component';
import { PaymentsSharedPromoComponent } from './components/payment-shared-promo/payments-shared-promo.component';
import { SpinePayPaymentCompletedComponent } from 'src/app/modules/account/components/spinepay-payment-completed/spinepay-payment-completed.component';
import { SpinepayError } from 'src/app/modules/account/components/spinepay-payment-completed/spinepay-error/spinepay-error.component';
const routes: Routes = [
  {
    path: '',
    component: AccountPageWrapperComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login/forgot-password',
    component: LoginPageWrapperComponent,
    canActivate: [NoAuthGuard],
    data: { inForgotPassword: true }
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'password-reset/:token',
    component: PasswordResetComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'password-reset/:username/:onetimepassword',
    component: PasswordResetComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'change-phone-number',
    component: ChangePhoneNumberComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'phone-number-verification',
    component: PhoneNumberVerificationComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'phone-number-verification/:verifynumber',
    component: PhoneNumberVerificationComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'bet-search',
    component: BetSearchWrapperComponent,
    canActivate: [AuthGuard, VerifiedGuard],
    data: {
      productType: ProductType.SportsBook
    }
  },
  {
    path: 'virtuals-bet-search',
    component: BetSearchWrapperComponent,
    canActivate: [AuthGuard, VerifiedGuard],
    data: {
      productType: ProductType.Virtuals
    }
  },
  {
    path: 'bet-search/coupon-details/:couponCode',
    component: CouponDetailsWrapperComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'account-statement',
    component: AccountStatementComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'bonus',
    component: BonusComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'single-bonus',
    component: SingleBonusComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'change-bank-account-number',
    component: ChangeBankAccountNumberComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'verification-choice',
    component: VerificationChoiceComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'deposit',
    // component: DepositComponent,
    // canActivate: [RestrictRolesGuard, VerifiedGuard, DepositRouteGuard],
    // data: { paymentMethods: 'deposit' }
    redirectTo: 'account'
  },
  {
    path: 'deposit-success/:paymentCodeId',
    component: DepositSuccessComponent,
    canActivate: [RestrictRolesGuard],
    data: { paymentMethods: 'deposit' }
  },
  {
    path: 'withdrawal',
    component: PaymentsListComponent,
    canActivate: [RestrictRolesGuard, VerifiedGuard],
    data: { paymentMethods: 'withdrawal', restrictedRoles: [UserType.Cashier] }
  },
  {
    path: 'withdrawal/promotion/shared/:transactionId',
    component: PaymentsSharedPromoComponent,
    canActivate: [],
    data: { paymentMethods: 'withdrawal' }
  },
  {
    path: 'withdrawal/promotion/:transactionId',
    component: PaymentsListPromoComponent,
    canActivate: [RestrictRolesGuard, VerifiedGuard],
    data: { paymentMethods: 'withdrawal' }
  },
  {
    path: 'deposit/:paymentCodeId',
    component: BankTransferComponent,
    canActivate: [AuthGuard, VerifiedGuard],
    data: { paymentMethods: 'deposit' }
  },
  {
    path: 'payment-completed',
    component: PaymentCompletedComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'spinepay-payment-completed',
    component: SpinePayPaymentCompletedComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'spinepay-payment-completed/spinepay-error',
    component: SpinepayError,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  {
    path: 'withdrawal/:paymentCodeId',
    component: WithdrawalComponent,
    canActivate: [AuthGuard, VerifiedGuard],
    data: { paymentMethods: 'withdrawal' }
  },
  {
    path: 'transfer',
    component: FundTransferComponent,
    canActivate: [AuthGuard, RestrictRolesGuard],
    data: { restrictedRoles: [UserType.Cashier, UserType.User] }
  },
  {
    path: 'saved-cards',
    component: SavedCardsComponent,
    canActivate: [AuthGuard, VerifiedGuard]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
