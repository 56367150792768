<app-breadcrumb faIcon="fa-pencil-square-o" [title]="'My Profile' | transloco">
</app-breadcrumb>

<div class="verification-choice-container">

  <app-card [titleHeaderText]="'Personal Data Verification' | transloco">
    <div class="verification-choice-card-container" body>
      <div class="top-description-container">
        <div transloco="To start playing, you must verify your personal information."></div>
        <div
          transloco="Our highest priority is the safety of your data and they are protected by security protocols and high standards of encryption, all under the Law on Personal Data Protection of the Republic of Serbia.">
        </div>
        <div
          transloco="We have provided two ways to verify your data. You will need a personal document (ID card or Passport).">
        </div>
      </div>
    </div>
  </app-card>

  <form [formGroup]="verificationChoiceForm" class="verification-choice-form">
    <div class="buttons-container">
      <div class="radio-group">
        <div class="radio-item-group" (click)="toggleRadioButton($event)"
          [class.selected]="verificationChoiceForm.controls['verificationChoice'].value === 'dataCapture'" analytics
          [click]='true' [category]="'Verification Choice'" [label]="'Quick Verification'">
          <input type="radio" name="verificationChoice" value="dataCapture" formControlName="verificationChoice">
          <span class="radio-visual"></span>
          <div class="radio-text">
            <div class="title" transloco="Quick Verification"></div>
            <div class="description">
              <div transloco="- The system automatically recognizes data from a personal document"></div>
              <div transloco="- You can complete it in 3 steps!"></div>
              <div transloco="- Expected time: ~2 minutes"></div>
            </div>
            <div class="poweredBy" transloco="Powered by Blinking"></div>
          </div>
        </div>
      </div>

      <div class="buttons">
        <app-button [text]="'Verify Data' | transloco" (btnClick)="onVerificationChoice()"
          [buttonType]="buttonType.Brand" [customButtonStyle]="{width: '200px', fontSize: '15px'}" analytics
          [click]='true' [category]="'Verify Data Button'"
          [label]="verificationChoiceForm.controls['verificationChoice'].value === 'standardVerification' ? 'Standard Verification' : 'Quick Verification'">
        </app-button>
      </div>
    </div>
  </form>

  <app-contact-support></app-contact-support>

</div>