<div class="bonus-details-container">
  <!-- Row -->
  <div class="label" transloco="Progress"></div>
  <div class="info progress">
    <account-bonus-progress [currentProgress]="currentProgress" [nextRelease]="nextRelease"></account-bonus-progress>
  </div>
  <!-- Row -->
  <div *ngIf="expirationDate" class="label" transloco="Expires"></div>
  <div *ngIf="expirationDate" class="info"><strong>{{ expirationDate | date: 'hh:mm, dd/MM/yyyy' }}</strong></div>
  <!-- Row -->
  <ng-container *ngIf="status !== undefined">
    <div class="label" transloco="Status"></div>
    <div class="info" [ngSwitch]="status">
      <span *ngSwitchCase="'active'" transloco="Active"></span>
      <span *ngSwitchCase="'paused'" transloco="Paused"></span>
      <span *ngSwitchCase="'missed'" transloco="Missed"></span>
      <span *ngSwitchCase="'inactive'" transloco="Inactive"></span>
      <span *ngSwitchCase="'previous'" transloco="Previous"></span>
      <span *ngSwitchDefault>{{ status | titlecase }}</span>
    </div>
  </ng-container>
</div>
