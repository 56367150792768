import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, fromEvent, Subject, timer } from 'rxjs';

import { debounce, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { fadeInOut } from 'src/app/shared/animations';
import { smoothScrollTo } from 'src/app/shared/utils/smooth-scroll-to';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
  animations: [fadeInOut()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollToTopComponent implements OnInit, OnDestroy {
  showButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroy$ = new Subject();

  constructor(private readonly applicationService: ApplicationService) {}

  ngOnInit(): void {
    fromEvent(window, 'scroll')
      .pipe(
        distinctUntilChanged(),
        debounce(() => timer(200)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        const scrollY = window.scrollY;
        // Because of the debounce call, the reference for the event.currentTarget is lost
        this.showButton$.next(scrollY > 350);
        this.applicationService.updateUI({ scrollY });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  scrollToTopClick(): void {
    smoothScrollTo({ top: 0 });
  }
}
