<div class='cms-notifications'>

    <div class='background-overlay' (click)="close()"></div>

    <div class='dialog'
        [class.display-pass-and-code]="(isUsernameConfirmed$ | async) && (displayPassAndCode$ | async) ? 'display-pass-and-code' : ''"
        [class.display-pass]="(isUsernameConfirmed$ | async) && (displayPass$ | async) ? 'display-pass' : ''"
        [class.display-code]="(isUsernameConfirmed$ | async) && (displayCode$ | async) ? 'display-code' : ''"
        [class.display-forgot-password]="(displayForgotPassword$ | async) ? 'display-forgot-password' : ''">
        <div class='inner'
            [class.display-pass-and-code]="(isUsernameConfirmed$ | async) && (displayPassAndCode$ | async) ? 'display-pass-and-code' : ''"
            [class.display-pass]="(isUsernameConfirmed$ | async) && (displayPass$ | async) ? 'display-pass' : ''"
            [class.display-code]="(isUsernameConfirmed$ | async) && (displayCode$ | async) ? 'display-code' : ''"
            [class.display-forgot-password]="(displayForgotPassword$ | async) ? 'display-forgot-password' : ''">
            <iframe id="sign-in-iframe" [src]="url" frameborder="0" *ngIf="accountService.showLogin$ | async"></iframe>
        </div>
    </div>
</div>