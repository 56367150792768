import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-i18n-redirect',
  templateUrl: './i18n-redirect.component.html',
  styleUrls: ['./i18n-redirect.component.scss']
})
export class I18nRedirectComponent {
  constructor(private readonly router: Router) {
    this.handleMissingRoute();
  }

  private handleMissingRoute(): void {
    const path = window.location.pathname;
    const i18nPath = path.slice(0, 4);

    if (i18nPath === '/sr/' || i18nPath === '/en/') {
      this.router.navigate(['/']);
    }
    this.router.navigate(['/']);
  }
}
