import { OverlayModule } from '@angular/cdk/overlay';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeAm from '@angular/common/locales/am';
import localeSr from '@angular/common/locales/sr';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslocoService } from '@ngneat/transloco';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import * as Hammer from 'hammerjs';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { MiniSlideUpComponent } from 'src/app/components/app-mini-slide-up/mini-slide-up.component';
import { ChooseYourPromoComponent } from 'src/app/components/choose-your-promo/choose-your-promo.component';
import { I18nRedirectComponent } from 'src/app/components/i18n-redirect/i18n-redirect.component';
import { LoadingAnimationComponent } from 'src/app/components/loading-animation/loading-animation.component';
import { ErrorHandlerWithLogger } from 'src/app/core/services/error-handler.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AwardsModule } from 'src/app/modules/awards/awards.module';
import { MetaLoader, MetaModule, MetaStaticLoader, PageTitlePositioning } from 'src/app/modules/meta/meta.module';
import { QuickCouponModule } from 'src/app/modules/quick-coupon/quick-coupon.module';
import { brandInfo } from 'src/brand-info';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SlideUpComponent } from './components/app-slide-up/slide-up.component';
import { WrapperComponent } from './components/app-wrapper/wrapper.component';
import { CmsNotificationsComponent } from './components/cms-notifications/cms-notifications.component';
import { NotificationDialogComponent } from './components/cms-notifications/notification-dialog/notification-dialog.component';
import { NotificationToastComponent } from './components/cms-notifications/notification-toast/notification-toast.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { CoreModule } from './core/core.module';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { HttpOAuth2Interceptor } from './core/interceptors/http-oauth2.interceptor';
import { AccountModule } from './modules/account/account.module';
import { CouponModule } from './modules/coupon/coupon.module';
import { MyBetsModule } from './modules/my-bets/my-bets.module';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';
// import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { TutorialService } from './modules/tutorial/tutorial.service';

export function playerFactory(): any {
  return player;
}

export function initLanguage(transloco: TranslocoService, languageService: LanguageService): Function {
  return () => {
    languageService.initialize();
    const activeLang = languageService.selectedLanguage.language || 'sr';
    transloco.setActiveLang(activeLang);
    return transloco.load(activeLang).toPromise();
  };
}

// All locales need to have their data registered here so that they can be used in SSR by formatting pipes as necessary
registerLocaleData(localeAm);
registerLocaleData(localeSr);
registerLocaleData(localeRu);

export function metaDefaultsFactory(): MetaLoader {
  return new MetaStaticLoader({
    pageTitlePositioning: PageTitlePositioning.PrependPageTitle,
    pageTitleSeparator: ' - ',
    applicationName: `Mobile ${brandInfo.brandName}`,
    defaults: brandInfo.metaDataDefaults
  });
}

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): Hammer {
    // userDrag element is used to allow the scrolling when we use hammerjs feature.
    const mc = new Hammer(element, {
      userDrag: 'element'
    });
    return mc;
  }
}
@NgModule({
  declarations: [
    AppComponent,
    WrapperComponent,
    ChooseYourPromoComponent,
    SlideUpComponent,
    MiniSlideUpComponent,
    I18nRedirectComponent,
    LoadingAnimationComponent,
    CmsNotificationsComponent,
    NotificationDialogComponent,
    NotificationToastComponent,
    SignInComponent
  ],
  imports: [
    // angular modules
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    BrowserAnimationsModule,
    OverlayModule,
    HammerModule,
    SwiperModule,
    LottieModule.forRoot({ player: playerFactory }),

    // 3rd party modules
    NgxWebstorageModule.forRoot({ prefix: 'n', separator: '_', caseSensitive: true }),
    SweetAlert2Module.forRoot(),

    // project modules
    AppRoutingModule,
    CoreModule,
    SharedModule,
    MetaModule.forRoot({
      provide: MetaLoader,
      useFactory: metaDefaultsFactory
    }),
    AccountModule,
    CouponModule,
    QuickCouponModule,
    MyBetsModule,
    AwardsModule,
    TranslocoRootModule
  ],
  providers: [
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router]
    // },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initLanguage,
      // deps: [TranslocoService, LanguageService, Sentry.TraceService]
      deps: [TranslocoService, LanguageService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpOAuth2Interceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false
    //   })
    // },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
    I18n,
    TutorialService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
