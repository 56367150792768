import { Injectable } from '@angular/core';
import { kebabCase } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { HelpQuery } from 'src/app/core/state/help/help.query';
import { HelpStore } from 'src/app/core/state/help/help.store';
import { APIType } from 'src/app/shared/models/api.model';
import { HelpSection } from 'src/app/shared/models/help.model';

import { HelpPage } from '../../shared/models/cms.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(
    private readonly apiService: APIService,
    private readonly helpStore: HelpStore,
    private readonly helpQuery: HelpQuery,
    private readonly appConfig: AppConfigService,
    private readonly languageService: LanguageService
  ) { }

  getHelp(): Observable<any> {
    return this.helpQuery.sections$.pipe(
      take(1),
      switchMap(sections => (sections.length > 0 ? of(sections) : this.getHelpFromApi()))
    );
  }

  getHelpPageUrls(): any {
    const HelpPageUrls = this.appConfig.get('helpPageUrls');

    if (HelpPageUrls) {
      return HelpPageUrls[this.languageService.selectedLanguage.language];
    }
  }

  private getHelpFromApi(): Observable<HelpSection[]> {
    return this.apiService.get(APIType.CMS, 'help-pages?_sort=rank').pipe(
      map(res => res.map(page => this.mapHelpContent(page.HelpPage))),
      tap(sections => {
        this.helpStore.setHelp(sections);
      })
    );
  }

  private readonly mapHelpContent = (response: HelpPage): HelpSection => ({
    title: response.title,
    contents: response.content.map(res => ({ main: res.editor, title: res.title })),
    url: kebabCase(response.title),
    children: undefined,
    id: +response.id
  });
}
