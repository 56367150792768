export interface CMSNotification {
  id: number;
  title?: string;
  text?: string;
  toastDuration?: number;
  trigger?: NotificationTrigger;
  buttons: NotificationButton[];
  customTriggerEvent?: string;
  startsAt?: string;
  endsAt?: string;
  pageRoute?: string;
  recurrence?: NotificationRecurrence;
  style: NotificationStyle;
  type: NotificationType;
  userStatus?: NotificationUserStatus;
}

export interface NotificationButton {
  label: string;
  style: NotificationButtonStyle;
  redirectURL?: string;
  inCodeAction?: string;
  action: NotificationButtonAction;
}

export enum NotificationButtonStyle {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Flat = 'flat'
}

export enum NotificationButtonAction {
  Close = 'close',
  Redirect = 'redirect',
  InCodeAction = 'inCodeAction'
}

export enum NotificationRecurrence {
  Once = 'once',
  Always = 'always',
  AlwaysWithDismissOption = 'alwaysWithDismissOption'
}

export enum NotificationStyle {
  Standard = 'standard',
  Warning = 'warning',
  Error = 'error'
}

export enum NotificationTrigger {
  WhenAppOpens = 'whenAppOpens',
  WhenPageOpens = 'whenPageOpens',
  AfterLogin = 'afterLogin',
  CustomEvent = 'customEvent'
}

export enum NotificationType {
  Dialog = 'dialog',
  Toast = 'toast'
}

export enum NotificationUserStatus {
  All = 'all',
  Player = 'player',
  Guest = 'guest'
}

export interface NotificationEvent {
  type: NotificationEventType;
  name: string;
}

export enum NotificationEventType {
  Trigger = 'trigger',
  Action = 'action'
}

export enum NotificationEventName {
  GoToLogin = 'go-to-login',
  CheckSaveCard = 'check-save-card'
}
