import { Injectable } from '@angular/core';
import { AllowedButtons, driver } from 'driver.js';
import { first } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { APIType } from 'src/app/shared/models/api.model';
import { TutorialWalkthrough } from './tutorial.model';
import { Router, NavigationEnd } from '@angular/router';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationService } from 'src/app/core/services/application.service';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  tutorialWalkthroughs: Promise<TutorialWalkthrough[]>;

  constructor(
    private apiService: APIService,
    private router: Router,
    private accountQuery: AccountQuery,
    private appService: ApplicationService
  ) {}

  initialize() {
    this.tutorialWalkthroughs = new Promise((resolve, reject) => {
      this.apiService
        .get(APIType.CMS, 'walkthrough-tutorials')
        .pipe(first())
        .subscribe((res: TutorialWalkthrough[]) => {
          if (res) {
            resolve(res);
          }
        });
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleRouteChange(event.urlAfterRedirects);
      }
    });
  }

  matchTutorialPath(url: string, t: TutorialWalkthrough): boolean {
    if (t.path === null) {
      return true;
    }
    let pathToCompare = t.path.startsWith('/') ? t.path : `/${t.path}`;
    let matchPartialUrl = false;
    if (pathToCompare.endsWith('*')) {
      matchPartialUrl = true;
      pathToCompare = pathToCompare.slice(0, -1);
    }

    const urlWithoutQueryParams = url.split('?')[0];
    return matchPartialUrl ? urlWithoutQueryParams.startsWith(pathToCompare) : urlWithoutQueryParams === pathToCompare;
  }

  async handleRouteChange(url: string) {
    const tutorials = await this.tutorialWalkthroughs;
    const dismissedTutorials: string[] = JSON.parse(localStorage.getItem('dismissedTutorials')) || [];

    const qualifyingTutorials = tutorials.filter(t => {
      if (dismissedTutorials.includes(t.name)) {
        return false;
      }

      const pathMatches = this.matchTutorialPath(url, t);
      if (!pathMatches) {
        return false;
      }

      if (t.user_authentication === 'authenticated' && !this.accountQuery.isAuthenticated) {
        return false;
      } else if (t.user_authentication === 'guest' && this.accountQuery.isAuthenticated) {
        return false;
      }

      if (t.platform === 'desktop' && !this.appService.isDesktop$.value) {
        return false;
      } else if (t.platform === 'mobile' && this.appService.isDesktop$.value) {
        return false;
      }

      if (t.test_users_only) {
        if (localStorage.getItem('show_test_tutorials') !== 'true') {
          return false;
        }
      }

      return true;
    });

    if (qualifyingTutorials.length > 0) {
      const tutorial = qualifyingTutorials[0];
      setTimeout(() => {
        const buttons: AllowedButtons[] = ['next'];
        if (tutorial.dismissable) {
          buttons.push('close');
        }
        if ((tutorial.Steps?.length ?? 0) > 1) {
          buttons.push('previous');
        }
        console.log('going with', tutorial, buttons);
        const driverObj = driver({
          showProgress: tutorial.show_progress && (tutorial.Steps?.length ?? 0) > 1,
          popoverClass: 'victory-theme',
          allowClose: tutorial.dismissable,
          progressText: '{{current}} / {{total}}',
          nextBtnText: '→',
          prevBtnText: '←',
          doneBtnText: '✔',
          showButtons: buttons,
          onHighlightStarted: () => {
            this.appService.showAZMenu$.next(false);
          },
          onDestroyed: () => {
            const dismissedTutorials: string[] = JSON.parse(localStorage.getItem('dismissedTutorials')) || [];
            dismissedTutorials.push(tutorial.name);
            localStorage.setItem('dismissedTutorials', JSON.stringify(dismissedTutorials));
          },
          steps: tutorial.Steps.map(s => ({
            element: s.mobile_selector,
            popover: {
              title: s.title,
              description: s.text
            }
          }))
        });
        driverObj.drive();
      }, 1000);
    }
  }
}
