import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';

/**
 * This guard ensures the user has a valid auth token before
 * allowing access to a route.
 */
@Injectable()
export class MaintenanceGuard implements CanActivate {
  constructor(private readonly appConfig: AppConfigService, private readonly router: Router) { }

  canActivate(): boolean {
    try {
      if (this.appConfig.get('maintenanceMode')) {
        this.router.navigate(['/maintenance']);
        return false;
      }
      return true;
    } catch {
      this.router.navigate(['/maintenance']);
      return false;
    }
  }
}
