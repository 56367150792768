<div
  class="multi-line-odds"
  [class.bordered]="bordered"
  [class.three-selections]="selections?.length === 3"
  [class.one-line]="displayOddsInSingleLine$ | async"
>
  <div
    class="market-wrapper"
    [class.even]="displayMarkets.length !== 1 && displayMarkets.length % 2 === 0"
    [class.single]="displayMarkets.length === 1"
    *ngFor="let market of displayMarkets; let last = last; trackBy: marketTrackBy"
  >
    <ng-container *ngFor="let odd of selections; trackBy: oddTrackBy">
      <ng-container *ngIf="odd && (odd.selectionId === market.id || odd.spreadValue === market.spreadValue)">
        <app-odd-with-label
          [longestSelectionName]="getLongestSelectionNameLength()"
          ngStyle
          *ngIf="odd?.selectionName === market.name"
          [selectionName]="odd?.selectionName"
          [fullWidthLabel]="displayOddsInSingleLine$ | async"
          [odd]="odd"
          [noborder]="true"
        >
        </app-odd-with-label>
      </ng-container>
    </ng-container>
  </div>
</div>
