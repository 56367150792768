import { Pipe, PipeTransform } from '@angular/core';
import { format } from 'date-fns';
import { LanguageService } from 'src/app/core/services/language.service';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {

  constructor(private readonly languageService: LanguageService) {}

  transform(date: Date, formatValue: string): string {
    const currDate = new Date(date);

    return format(currDate, formatValue, { locale: this.languageService.selectedLanguage.dateLocale });
  }
}
