<!-- <div class="bet" [class.first]="isFirst" *ngIf="!(state === betFinalState.InEvaluation && !evaluation)"> -->
<div class="bet" [class.first]="isFirst">
  <!--
      *****
      *****   Overview
      *****
  -->
  <section class="overview">
    <div class="coupon-bet-header">
      <!-- <div class="coupon-code">
        <span class="line-label" transloco="Coupon Code"></span>
        <span class="line-label">: </span>
        <span class="line-value">{{ bet.couponCode }}</span>
      </div> -->
      <div class="repeat-bet-wrapper">
        <div
          class="repeat-bet"
          *ngIf="state === betFinalState.Placed"
          (click)="repeatBet()"
          analytics
          [click]="true"
          [category]="'Repeat Bet'"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none">
            <g fill="#fff" fill-rule="evenodd" clip-path="url(#a)" clip-rule="evenodd">
              <path
                d="M9.021.792a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1 0 .708l-2 2a.5.5 0 1 1-.708-.708l1.647-1.646L9.02 1.5a.5.5 0 0 1 0-.708Z"
              />
              <path
                d="M4.375 3.646a1.5 1.5 0 0 0-1.5 1.5v1a.5.5 0 0 1-1 0v-1a2.5 2.5 0 0 1 2.5-2.5h7a.5.5 0 0 1 0 1h-7ZM4.729 7.792a.5.5 0 0 1 0 .708l-1.647 1.646 1.647 1.646a.5.5 0 1 1-.708.707l-2-2a.5.5 0 0 1 0-.707l2-2a.5.5 0 0 1 .708 0Z"
              />
              <path
                d="M11.375 6.646a.5.5 0 0 1 .5.5v1a2.5 2.5 0 0 1-2.5 2.5h-7a.5.5 0 0 1 0-1h7a1.5 1.5 0 0 0 1.5-1.5v-1a.5.5 0 0 1 .5-.5Z"
              />
            </g>
            <defs>
              <clipPath id="a"><path fill="#fff" d="M.875.646h12v12h-12z" /></clipPath>
            </defs>
          </svg>
          <span transloco="Repeat Bet"></span>
        </div>
      </div>
      <div class="share-to-friends-wrapper">
        <div *ngIf="!evaluation" class="share-coupon" (click)="shareCoupon()" analytics [click]="true" [category]="'Share Coupon'" [label]="bet.couponCode">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
            <g clip-path="url(#a)">
              <path
                fill="#fff"
                d="M11.14 9.903c-.443 0-.84.175-1.143.45L5.838 7.932a1.91 1.91 0 0 0 .053-.409c0-.14-.024-.274-.053-.408l4.113-2.397a1.748 1.748 0 0 0 2.94-1.278c0-.968-.782-1.75-1.75-1.75-.969 0-1.75.782-1.75 1.75 0 .14.023.274.052.408L5.331 6.246a1.748 1.748 0 0 0-2.94 1.277 1.748 1.748 0 0 0 2.94 1.278l4.153 2.427c-.03.122-.047.25-.047.379 0 .939.764 1.703 1.704 1.703.939 0 1.703-.764 1.703-1.703 0-.94-.764-1.704-1.703-1.704Z"
              />
            </g>
            <defs>
              <clipPath id="a"><path fill="#fff" d="M.64.523h14v14h-14z" /></clipPath>
            </defs>
          </svg>
          <span transloco="Share Coupon"></span>
        </div>
      </div>
    </div>

    <div class="coupon-bet-status">
      <!-- Status -->
      <h4 class="status" *ngIf="evaluation">
        <span class="evaluation" transloco="In Evaluation"></span>
      </h4>
      <h4 class="status" *ngIf="!evaluation">
        <span *ngIf="state === 0" class="rejected" transloco="Rejected"></span>
        <span *ngIf="state === betFinalState.Placed" class="active" transloco="Active"></span>
        <span *ngIf="state === betFinalState.Won" class="won" transloco="Won"></span>
        <span *ngIf="state === betFinalState.PartiallyWon" class="partially-won" transloco="Won"></span>
        <span *ngIf="state === betFinalState.Lost" class="lost" transloco="Lost"></span>
        <span *ngIf="state === betFinalState.Void" class="void" transloco="Cancelled"></span>
        <span *ngIf="state === betFinalState.Cashout" class="cashout" transloco="Cashout"></span>
        <span *ngIf="state === betFinalState.Promotion" class="promo" transloco="Promotion"></span>
      </h4>
      <!-- Date and time -->
      <!-- <div class="coupon-date-time">
        <span *ngIf="dateTime$ | async">{{ dateTime$ | async }}</span>
      </div> -->
      <!-- Is Live -->
      <div>
        <div class="is-live" [class.active]="state === betFinalState.Placed" *ngIf="isLive">
          <div class="live-circle"></div>
          <span transloco="Live"></span>
        </div>
      </div>
    </div>

    <div class="deposit-win-values">
      <!--
          ***
          ***   Deposit
          ***
      -->
      <div class="deposit-amount">
        <h6 class="label" transloco="Total Stake"></h6>
        <p class="value">{{ bet.stakeGross || bet.StakeGross | currencyFormat }}</p>
      </div>

      <!--
          ***
          ***   Win amount
          ***
      -->
      <div class="win-amount" (click)="toggleExpand()">
        <h6 class="label" *ngIf="state !== betFinalState.Placed && !evaluation" transloco="Winnings"></h6>
        <h6 class="label" *ngIf="evaluation" transloco="Potential Winnings"></h6>
        <p *ngIf="state !== betFinalState.Placed" class="value">{{ bet.wonAmount || bet.MaxWinNet | currencyFormat }}</p>

        <h6 class="label" *ngIf="state === betFinalState.Placed && isSystem()" transloco="Max.potential winnings"></h6>
        <h6 class="label" *ngIf="state === betFinalState.Placed && isRegular()" transloco="Potential Winnings"></h6>

        <p *ngIf="state === betFinalState.Placed" class="value">{{ bet.overviewWin || bet.MaxWinNet | currencyFormat }}</p>
      </div>
    </div>

    <!--
        ***
        ***   Actions
        ***
    -->
    <div class="actions">
      <div>
        <!-- Odds -->
        <div *ngIf="!(evaluation && isSystem())" class="total-odds"><span transloco="Odds:"></span> {{ bet.totalOdds || bet.TotalOdds | number : '1.2-2' }}</div>
      </div>

      <!-- Buttons -->
      <div class="buttons">
        <button
          (click)="toggleExpand()"
          *ngIf="!evaluation"
          analytics
          [click]="true"
          [category]="'Coupon Overview ' + (bet.collapsed ? 'Expand' : 'Collapse')"
        >
          <span transloco="View Coupon"></span>
          <svg
            class="arrow-icon"
            [class.revert]="!bet.collapsed && collapseElement$ | async"
            width="10"
            height="6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity=".8"
              d="M1.532.267l3.472 3.535L8.477.267a.88.88 0 011.261 0 .92.92 0 010 1.284L5.631 5.733a.88.88 0 01-1.262 0L.262 1.551a.92.92 0 010-1.284.899.899 0 011.27 0z"
              fill="#FBFFF8"
            />
          </svg>
        </button>

        <div class="loader" *ngIf="evaluation"></div>
      </div>
    </div>

    <!--
        ***
        ***   Repeat bet
        ***
    -->
    <!-- <div class="repeat-bet" *ngIf="state === betFinalState.Placed" (click)="repeatBet()" analytics [click]="true" [category]="'Repeat Bet'">
      <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.238 4.763A5.968 5.968 0 009 3a5.992 5.992 0 00-5.992 6c0 3.315 2.677 6 5.992 6a5.987 5.987 0 005.797-4.5h-1.56A4.493 4.493 0 019 13.5 4.504 4.504 0 014.5 9c0-2.482 2.018-4.5 4.5-4.5 1.245 0 2.355.518 3.165 1.335L9.75 8.25H15V3l-1.762 1.763z"
          fill="#fff"
          opacity=".7"
        />
      </svg>
    </div> -->
  </section>

  <!--
      *****
      *****   Details
      *****
  -->
  <section [@expandCollapseSimpler] class="details" *ngIf="!bet.collapsed && !evaluation && collapseElement$ | async">
    <app-loading-bar [show]="!bet.betDetails" [transparent]="true"></app-loading-bar>
    <app-recent-bet-details (refresh)="onRefresh()" *ngIf="bet.betDetails" [bet]="bet"></app-recent-bet-details>
  </section>
</div>
