import { Component, NgZone, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { akitaDevtools } from '@datorama/akita';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, throttleTime } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { SoliticsAnalyticsService } from 'src/app/core/services/analytics/solitics-analytics.service';
import { SportradarAnalyticsService } from 'src/app/core/services/analytics/sportradar-analytics.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { CMSNotificationsService } from 'src/app/core/services/cms-notifications.service';
import { DynamicScriptLoaderService } from 'src/app/core/services/dynamic-script-loader.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ProductTypeService } from 'src/app/core/services/product-type.service';
import { PromoFabService } from 'src/app/core/services/promo-fab.service';
import { PwaService } from 'src/app/core/services/pwa.service';
import { RedirectService } from 'src/app/core/services/redirect.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { ShopOwnerService } from 'src/app/core/services/shop-owner.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { BlogService } from 'src/app/modules/blog/blog.service';
import { MetaService } from 'src/app/modules/meta/meta.service';
import { AnalyticsEventAction } from 'src/app/shared/models/analytics.model';
import { NotificationEventType, NotificationTrigger } from 'src/app/shared/models/cms-notification';
import { environment } from 'src/environments/environment';

import { ChatPopupService } from './core/services/chat-popup.service';
import { IdleService } from './core/services/idle.service';
import { ChatPopupQuery } from './core/state/chat-popup/chat-popup.query';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  template: `
    <app-wrapper></app-wrapper>
    <!-- <app-sfk-chat *ngIf="sfkEnabled"></app-sfk-chat> -->
  `
})
export class AppComponent implements OnInit {
  title = 'mobile';
  // sfkEnabled = this.appConfig.get('liveChat').liveChatIntegration === 'sfk';

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly appConfigService: AppConfigService,
    private readonly appInsightsService: AppInsightsService,
    private readonly applicationService: ApplicationService,
    private readonly cashoutService: CashoutService,
    private readonly evaluationService: EvaluationService,
    private readonly languageService: LanguageService,
    private readonly loadingService: LoadingService,
    private readonly metaService: MetaService,
    private readonly ngZone: NgZone,
    private readonly productTypeService: ProductTypeService,
    private readonly registrationService: RegistrationService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly router: Router,
    private readonly shopOwnerService: ShopOwnerService,
    private readonly dynamicScriptLoaderService: DynamicScriptLoaderService,
    private readonly promoFabService: PromoFabService,
    private readonly pwaService: PwaService,
    private readonly analyticsService: AnalyticsService,
    private readonly sportradarAnalyticsService: SportradarAnalyticsService,
    private readonly soliticsAnalyticsService: SoliticsAnalyticsService,
    private readonly idleService: IdleService,
    private readonly chatPopup: ChatPopupService,
    private readonly notificationsService: CMSNotificationsService,
    private readonly blogService: BlogService,
    public chatPopupQuery: ChatPopupQuery,
    public redirectService: RedirectService
  ) {
    if (!environment.production) {
      akitaDevtools(ngZone);
    }
  }

  ngOnInit(): void {
    this.sportradarAnalyticsService.init();
    this.soliticsAnalyticsService.init();
    this.redirectService.init();
    this.dynamicScriptLoaderService.initialise();
    this.loadingService.initialize();
    this.appInsightsService.initialize();
    this.productTypeService.initialize();
    this.evaluationService.checkEvaluation();
    this.cashoutService.checkPendingCashouts();
    this.metaService.initialize(this.appConfig.get('apiBaseUrl').cms, true, this.languageService.selectedLanguage.umbracoLocale);
    this.shopOwnerService.initialize();
    this.promoFabService.initialize();
    this.handleNotificationsInitialized();

    this.chatPopup.initialize();

    if (environment.production) {
      this.metaService.setCanonicalURL(this.appConfig.get('canonicalUrl'));
    }
    const cacheTTL = this.appConfig.get('cmsCacheTTL');
    const fallbackTimer = this.appConfig.get('fallbackTimer');
    this.applicationService.updateCms({ cacheTTL, fallbackTimer });
    this.applicationService.updateConfigUI(this.appConfigService.get('ui'));

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.accountQuery.userData) {
          switch (this.accountQuery.userData.userStatus) {
            case 'PCHANG': // Password changed
              if (event.url !== '/account/password-reset') {
                this.router.navigateByUrl('/account/password-reset');
              }
              break;
            case 'UNCONF': // Account unconfirmed
              if (this.appConfig.get('enableOTPbySms') && event.url !== '/account/phone-number-verification/true') {
                if (this.registrationQuery.activationOption !== 'none') {
                  if (this.registrationQuery.activationOption === 'BySms') {
                    this.router.navigateByUrl('/account/phone-number-verification/true');
                  }
                } else {
                  this.registrationService.getActivationOption().subscribe(() => {
                    if (this.registrationQuery.activationOption === 'BySms') {
                      this.router.navigateByUrl('/account/phone-number-verification/true');
                    }
                  });
                }
              }
              break;
            default:
              break;
          }
        }
      } else if (event instanceof NavigationEnd) {
        this.sendPageView(event.url);
        this.handlePhoneNumberVerificationTooltip(event);
      }
    });

    this.trackPageScroll();
    this.blogService.fetchConfig();
    this.applicationService.fetchSidebar();
  }

  private handleNotificationsInitialized(): void {
    this.notificationsService.initialized$.subscribe(() => {
      this.emitAppOpensNotificationTrigger();
      this.emitPageViewNotificationTrigger();
      this.handlePageViewsNavigationTrigger();
    });
  }

  private emitAppOpensNotificationTrigger(): void {
    this.notificationsService.emitEvent({
      type: NotificationEventType.Trigger,
      name: NotificationTrigger.WhenAppOpens
    });
  }

  private handlePageViewsNavigationTrigger(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(this.emitPageViewNotificationTrigger.bind(this));
  }

  private emitPageViewNotificationTrigger(): void {
    this.notificationsService.emitEvent({
      type: NotificationEventType.Trigger,
      name: NotificationTrigger.WhenPageOpens
    });
  }

  private sendPageView(url: string): void {
    this.analyticsService.sendPageView(url);
  }

  private handlePhoneNumberVerificationTooltip(event: NavigationEnd): void {
    this.accountService.updateShowUnverifiedTooltip(event.url.indexOf('phone-number-verification') === -1);
  }

  private trackPageScroll(): void {
    fromEvent(window, 'scroll')
      .pipe(
        map(() => window.scrollY),
        distinctUntilChanged(),
        shareReplay(1),
        throttleTime(1500, undefined, { leading: false, trailing: true }),
        filter(scrollY => scrollY !== null && scrollY !== undefined)
      )
      .subscribe(this.reportPageScroll.bind(this));
  }

  private reportPageScroll(scrollY: number): void {
    this.analyticsService.sendEvent({
      Action: AnalyticsEventAction.Scroll,
      Value: Math.round((scrollY * 100) / (document.documentElement.scrollHeight - window.innerHeight))
    });
  }
}
