import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';
import { BonusModel } from 'src/app/shared/models/bonus.model';

import { BehaviorSubject, Subject } from 'rxjs';

import { translate } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { BonusService } from 'src/app/core/services/bonus.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { expandCollapse } from 'src/app/shared/animations';
import { NotificationSettings } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'account-bonus',
  templateUrl: './bonus.component.html',
  styleUrls: ['./bonus.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse()]
})
export class BonusComponent implements OnInit, OnDestroy {
  viewingActive$ = new BehaviorSubject<boolean>(false);
  viewingPaused$ = new BehaviorSubject<boolean>(false);
  viewingInactive$ = new BehaviorSubject<boolean>(false);
  viewingPrevious$ = new BehaviorSubject<boolean>(false);
  viewingMissed$ = new BehaviorSubject<boolean>(false);
  viewingBomb$ = new BehaviorSubject<boolean>(false);

  cardName: string = 'Bonus';

  private readonly destroy$ = new Subject<boolean>();

  constructor(
    private readonly bonusService: BonusService,
    private readonly location: Location,
    private readonly notificationService: NotificationService,
    readonly accountQuery: AccountQuery,

  ) { }

  ngOnInit(): void {
    this.bonusService.getUserBonuses().subscribe();
    this.accountQuery.viewingBonus$.pipe(takeUntil(this.destroy$)).subscribe(sub => {
      this.cardName = sub === undefined ? 'Bonus' : this.accountQuery.bonusInfoByCode(sub.bonusCode).contentTitle;
    });
    this.accountQuery.error$.pipe(takeUntil(this.destroy$)).subscribe(err => {
      if (err) {
        const callback = () => {
          this.bonusService.clearError();
        };

        this.notificationService.showNotification(
          new NotificationSettings({
            allowBackdropClose: true,
            closeButtonCallback: callback,
            confirmButtonCallback: callback,
            contentText: translate('Something went wrong! Please try again or contact support.'),
            type: 'error',
            showConfirmButton: true
          })
        );
      }
    });
  }

  onSectionClicked(event: any): void {
    this.bonusService.resetBonusUI();

    if (!event.currentTarget.classList.contains('selected')) {
      switch (event.currentTarget.id) {
        case 'active':
          this.bonusService.updateViewingActive(true);
          break;
        case 'paused':
          this.bonusService.updateViewingPaused(true);
          break;
        case 'inactive':
          this.bonusService.updateViewingInactive(true);
          break;
        case 'previous':
          this.bonusService.updateViewingPrevious(true);
          break;
        case 'missed':
          this.bonusService.updateViewingMissed(true);
          break;
        case 'bomb':
          this.bonusService.updateViewingBomb(true);
          break;
        default:
          break;
      }
    }
  }

  onMoreInfoClicked(id: number): void {
    this.bonusService.getBonus(id);
    this.bonusService.resetBonusUI();
  }

  bonusTrackBy(item: BonusModel): number {
    return item.id;
  }

  backPressed(): void {
    const isViewing = this.accountQuery.isViewingBonus;
    if (isViewing) {
      this.bonusService.clearViewingBonus();
    } else {
      this.location.back();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.bonusService.resetBonusUI();
    this.bonusService.clearViewingBonus();
    this.bonusService.clearUserBonuses();
  }
}
