import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ProductType } from 'src/app/shared/models/application.model';
import { BetSearchPage, BetSearchParams } from 'src/app/shared/models/bet-search.model';

@Component({
  selector: 'account-bet-search-wrapper',
  templateUrl: './bet-search-wrapper.component.html',
  styleUrls: ['./bet-search-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetSearchWrapperComponent {
  betSearchPage = BetSearchPage;
  params$: Observable<Partial<BetSearchParams>> = this.route.queryParams.pipe(
    map(params => (params.couponStatus ? { ...params, couponStatus: +params.couponStatus } : params))
  );
  currentState$ = this.params$.pipe(map(params => (Object.keys(params).length > 0 ? BetSearchPage.Results : BetSearchPage.Search)));
  isVirtuals$ = this.route.data.pipe(map((data: any) => data.productType === ProductType.Virtuals));

  constructor(readonly accountQuery: AccountQuery, private readonly route: ActivatedRoute) {}
}
