<ng-container
  *ngIf="{ betSearch: accountQuery.betSearch$ | async, currentState: currentState$ | async, isVirtuals: isVirtuals$ | async} as vm">
  <app-breadcrumb [title]="'Bet List' | transloco" icon="search" *ngIf="!vm.isVirtuals">
  </app-breadcrumb>
  <app-breadcrumb [title]="'Bet List' | transloco" icon="virtuals" *ngIf="vm.isVirtuals">
  </app-breadcrumb>
  <div class="bet-search-container">
    <app-card [titleHeaderText]="'Search for a bet' | transloco" *ngIf="vm.currentState === betSearchPage.Search">
      <account-bet-search-form [isVirtuals]="vm.isVirtuals" body></account-bet-search-form>
    </app-card>
    <app-card [titleHeaderText]="(vm.isVirtuals ? 'Virtuals Bet List Results' : 'Bet List Results') | transloco"
      *ngIf="vm.currentState === betSearchPage.Results" [loading]="vm.betSearch.isLoading">
      <account-bet-search-results [bets]="vm.betSearch.betSearchResult" [params]="params$ | async"
        [isVirtuals]="vm.isVirtuals" body>
      </account-bet-search-results>
    </app-card>
  </div>
</ng-container>
