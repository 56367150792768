import { OnDestroy } from '@angular/core';
import { PageTitlePositioning } from './../../../meta/models/page-title-positioning.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-verification-choice',
  templateUrl: './verification-choice.component.html',
  styleUrls: ['./verification-choice.component.scss']
})
export class VerificationChoiceComponent implements OnInit, OnDestroy {
  sessionType: number;
  verificationChoiceForm: FormGroup;
  buttonType = ButtonType;
  destroy$ = new Subject<boolean>();
  constructor(
    readonly registrationQuery: RegistrationQuery,
    readonly applicationService: ApplicationService,
    private readonly registrationService: RegistrationService,
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.verificationChoiceForm = this.initialiseForm();
    this.accountQuery.canDeposit$.pipe(takeUntil(this.destroy$)).subscribe(res => {

      if (this.accountQuery?.skipSessionOne) {
        return
      }
      if (!this.accountQuery.hasValidId || !this.accountQuery.userData.mobile) {
        this.router.navigate(['/strenghten-profile']);
      } else if (this.accountQuery.hasValidId && (!this.accountQuery.emailVerified || !this.accountQuery.hasValidMobilePhone)) {
        this.router.navigate(['/register']);
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  toggleRadioButton(event: any): void {
    const radio = event.currentTarget.querySelector('input[type="radio"]');
    if (!radio.disabled) {
      radio.checked = radio.checked ? false : true;
      this.verificationChoiceForm.controls[radio.getAttribute('formcontrolname')].setValue(radio.value);
      this.verificationChoiceForm.controls[radio.getAttribute('formcontrolname')].markAsDirty();
    }
  }

  onVerificationChoice(): void {
    this.startBlinkingSession();
  }

  private openEditProfile(): void {
    // TODO: save the choice done by the user
    this.router.navigate(['/account/edit-profile']);
  }

  private startBlinkingSession(): void {
    // TODO: save the choice done by the user
    this.registrationService.getRegisterDetails().subscribe(() => {
      if (this.registrationQuery.blinkingServiceEnabled) {
        // from edit profile page, the button will be shown only for unverified users and the user will always open '1: Main Session'
        this.registrationService.startBlinkingSession(this.accountQuery.skipSessionOne ? 2 : 10).subscribe();
      } else {
        this.router.navigate(['/account/edit-profile']);
      }
    });
  }

  private initialiseForm(): FormGroup {
    return new FormGroup(
      {
        verificationChoice: new FormControl('dataCapture', Validators.required)
      }
    );
  }
}
