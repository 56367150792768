import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { MenuQuery } from 'src/app/core/state/menu/menu.query';
import { fadeInByStaggered } from 'src/app/shared/animations';
import { MenuItemModel, MenuTab } from 'src/app/shared/models/menu.model';

@Component({
  selector: 'app-menu-virtuals',
  templateUrl: './menu-virtuals.component.html',
  styleUrls: ['./menu-virtuals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInByStaggered()]
})
export class MenuVirtualsComponent implements OnInit {
  menuTab = MenuTab;
  virtuals = [
    // {
    //   title: translate('Virtuals League'),
    //   link: '/virtual/league',
    //   isNew: true
    // },
    // {
    //   title: translate('Color-Color'),
    //   icon: 'gamepad',
    //   link: '/virtual/color-color'
    // },
    // {
    //   title: translate('Super 6'),
    //   icon: 'gamepad',
    //   link: '/virtual/super-six'
    // },
    // {
    //   title: translate('Dog Races'),
    //   icon: 'gamepad',
    //   link: '/virtual/greyhounds-6'
    // }
  ];

  showRibbon$ = this.applicationQuery.sideBarMenu$.pipe(
    map(menuItems => {
      if (!menuItems) {
        return false;
      }

      const virtualTab = menuItems.find(item => item.type === MenuTab.Virtuals);

      return virtualTab && Boolean(virtualTab.isNew);
    })
  );

  constructor(
    public menuQuery: MenuQuery,
    public applicationService: ApplicationService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly appConfigService: AppConfigService,
    
  ) {}

  ngOnInit(): void {
    /*
        This goes against our general design pattern when it comes to handling multi-brand requirements
        It was done like this since it will very soon be replaced by a new implementation of the Virtuals Menu in a near-future epic
        and this is the way to implement this as quickly and least code-heavy way as possible.
    */
    if (this.appConfigService.get('brandId') === 1903) {
      this.virtuals = [this.virtuals[0]];
    }
  }

  virtualsTrackBy(index: number, virtual: MenuItemModel): string {
    return virtual.linkURL;
  }
}
