export enum Browser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  IE = 'Internet Explorer',
  Edge = 'Edge',
  Safari = 'Safari',
  Opera = 'Opera',
  Unknown = 'Unknown'
}

export enum MobileOS {
  Android = 'Android',
  iOS = 'iOS',
  Unknown = 'Unknown'
}

export enum DeviceType {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
  Unknown = 'Unknown'
}

export const isIOS = (): boolean => {
  const iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];

  if (!!navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        return true;
      }
    }
  }

  return false;
};

export const isAndroid = (): boolean => /(android)/i.test(navigator.userAgent);

export const getBrowser = (): Browser => {
  const userAgent = ((navigator && navigator.userAgent) || '').toLowerCase();
  const vendor = ((navigator && navigator.vendor) || '').toLowerCase();

  const chrome = /google inc/.test(vendor) ? userAgent.match(/(?:chrome|crios)\/(\d+)/) : undefined;
  if (chrome) {
    return Browser.Chrome;
  }

  const safari = userAgent.match(/version\/(\d+).+?safari/);
  if (safari) {
    return Browser.Safari;
  }

  const firefox = userAgent.match(/(?:firefox|fxios)\/(\d+)/);
  if (firefox) {
    return Browser.Firefox;
  }

  const opera = userAgent.match(/(?:^opera.+?version|opr)\/(\d+)/);
  if (opera) {
    return Browser.Opera;
  }

  const ie = userAgent.match(/(?:msie |trident.+?; rv:)(\d+)/);
  if (ie) {
    return Browser.IE;
  }

  const edge = userAgent.match(/edge\/(\d+)/);
  if (edge) {
    return Browser.Edge;
  }

  return Browser.Unknown;
};

export const getMobileOS = (): MobileOS => {
  if (isAndroid()) {
    return MobileOS.Android;
  } else if (isIOS()) {
    return MobileOS.iOS;
  } else {
    return MobileOS.Unknown;
  }
};

export const getDeviceType = () => {
  const ua = navigator.userAgent;

  let deviceType: string;

  if (!ua) {
    deviceType = DeviceType.Unknown;
  }

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    deviceType = DeviceType.Tablet;
  } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    deviceType = DeviceType.Mobile;
  } else {
    deviceType = DeviceType.Desktop;
  }

  if (isPWA()) {
    deviceType = `${deviceType} (PWA)`;
  }

  return deviceType;
};

export const isPWA = (): boolean => (navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;
