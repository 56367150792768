<div [id]="sectionId" class="bonus-section-container"
  [ngClass]="{'selected' : isViewing, 'last': lastSection, 'inactive-bonus': sectionId === 'inactive'}"
  (click)="onClick($event)">
  <div class="icon">
    <i class="fa {{ faIcon }}" aria-hidden="true"></i>
  </div>
  <div class="text">
    {{ sectionTitle }}
  </div>
 
  <div class="caret">
    <i class="fa fa-caret-right" aria-hidden="true" [appDropdownArrowRotator]="isViewing"></i>
  </div>
</div>
<div class="bonus-card" [@expandCollapse]="isViewing ? 'expanded' : 'collapsed'">
  <div class="bonus-card-content"   [ngClass]="{visible: (isShown$ | async)}">
    <iframe id="bonus-section-iframe" [src]="url" frameborder="0" *ngIf="isViewing"></iframe>
    <!-- <app-form-message type='info' [text]="noBonusesText" *ngIf="(bonuses | async).length === 1"></app-form-message> -->
  </div>
</div>