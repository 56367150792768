import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { ButtonType } from 'src/app/shared/models/button.model';

@Component({
  selector: 'app-expired-booked-bet-prompt',
  templateUrl: './expired-booked-bet-prompt.component.html',
  styleUrls: ['./expired-booked-bet-prompt.component.scss']
})
export class ExpiredBookedBetPromptComponent {
  @Input() bookedBetData: BookBetModel;
  @Output() readonly cancel = new EventEmitter();
  @Output() readonly confirm = new EventEmitter();

  buttonType = ButtonType;

  indexTrackBy(index: number): number {
    return index;
  }
}
