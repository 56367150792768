import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/core/services/language.service';
import { SportService } from 'src/app/core/services/sport.service';
import { MenuQuery } from 'src/app/core/state/menu/menu.query';
import { fadeIn } from 'src/app/shared/animations';
import { MenuTab } from 'src/app/shared/models/menu.model';

@Component({
  selector: 'app-menu',
  templateUrl: './menu-wrapper.component.html',
  styleUrls: ['./menu-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()]
})
export class MenuWrapperComponent implements OnInit {
  menuTab = MenuTab;

  constructor(
    public menuQuery: MenuQuery,
    private readonly sportService: SportService,
    private readonly languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.sportService.getSportsList(this.languageService.selectedLanguage.language).subscribe();
  }
}
