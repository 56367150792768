<div
  (click)="close()"
  [class.closing]="closing$ | async"
  class="bottom-sheet-window"
>
  <div class="bottom-sheet-panel">
    <ng-container *ngIf="!(bottomSheetQuery.state$ | async).installPWA">
      <div class="wrapper">
        <header>
          <h6>{{ (bottomSheetQuery.state$ | async).uptitle }}</h6>
          <h1>{{ (bottomSheetQuery.state$ | async).title }}</h1>
          <h4>{{ (bottomSheetQuery.state$ | async).subtitle }}</h4>
        </header>

        <div [innerHTML]="(bottomSheetQuery.state$ | async).content" class="content"></div>
      </div>

      <button (click)="close()" [class.closing]="closing$ | async" class="close-button">
        <span transloco="Close"></span>
        <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 10l5 5 5-5H7z" fill="#fff" opacity=".7" />
        </svg>
      </button>
    </ng-container>

    <!--
      ***
      ***   Install PWA
      ***
    -->
    <ng-container *ngIf="(bottomSheetQuery.state$ | async).installPWA">
      <div class="wrapper">
        <header class="pwa">
          <img class="logo" src="assets/images/logo/brand-logo-landing-full.svg" />
          <h6 class="pwa-title" transloco="Install PWA Title"></h6>
        </header>
      </div>

      <div [class.closing]="closing$ | async" class="buttons">
        <div (click)="installPWA()" [category]="'Install PWA'" [click]="true" analytics class="primary-button large">
          <span transloco="Install PWA CTA"></span>
        </div>
      </div>

      <svg
        (click)="close()"
        class="close-icon"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.7338 10.2753C21.3788 9.92036 20.8055 9.92036 20.4505 10.2753L16 14.7167L11.5495 10.2662C11.1945 9.91126 10.6212 9.91126 10.2662 10.2662C9.91126 10.6212 9.91126 11.1945 10.2662 11.5495L14.7167 16L10.2662 20.4505C9.91126 20.8055 9.91126 21.3788 10.2662 21.7338C10.6212 22.0887 11.1945 22.0887 11.5495 21.7338L16 17.2833L20.4505 21.7338C20.8055 22.0887 21.3788 22.0887 21.7338 21.7338C22.0887 21.3788 22.0887 20.8055 21.7338 20.4505L17.2833 16L21.7338 11.5495C22.0796 11.2036 22.0796 10.6212 21.7338 10.2753V10.2753Z"
          fill="#6C7578"
        />
      </svg>
    </ng-container>
  </div>
</div>
