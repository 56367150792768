<swal #phoneVerification [swalOptions]="options">
  <div *swalPartial class="phone-verification-popup-container">
    <div class="title-header" *ngIf="verifyData.type !== verifyAccountType.Locked">
      <i class="fa fa-warning"></i>
      <span transloco="Important"></span>
    </div>
    <div class="title-header" *ngIf="verifyData.type === verifyAccountType.Locked">
      <i class="fa fa-lock"></i>
      <span transloco="Account Locked!"></span>
    </div>

    <div class="description" *ngIf="verifyData.type === verifyAccountType.VerifyInProgress">
      <div transloco="Please note that the phone number associated with this account has not yet been verified."></div>
      <div transloco="Select an option below to proceed."></div>
    </div>
    <div class="description" *ngIf="verifyData.type === verifyAccountType.AlreadyRegistered">
      <div transloco="Your Highness,"></div>
      <div><b>{{ verifyData.mobileNumber }}</b> {{'is already registered on another account. If you choose to proceed,
        all other accounts associated with this phone number will become inactive.' | transloco}}</div>
    </div>
    <div class="description" *ngIf="verifyData.type === verifyAccountType.Locked">
      <div
        transloco="Your account is locked as the registered phone number is already verified against another online account. To unlock your account, you may update your phone number or contact customer support.">
      </div>
    </div>

    <div class="buttons">
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.VerifyInProgress"
        (click)="verifyExistingPhoneNumber()">
        <span class="btn-text" transloco="Verify Existing Phone Number"></span>
      </button>
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.AlreadyRegistered"
        (click)="verifyData.callback(); closePopup();">
        <span class="btn-text" transloco="Complete Registration"></span>
      </button>
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.Locked"
        (click)="navigateTo('/account/change-phone-number')">
        <span class="btn-text" transloco="Update Phone Number"></span>
      </button>
      <div class="separator" *ngIf="verifyData.type !== verifyAccountType.AlreadyRegistered" transloco="or"></div>
      <button class="btn" *ngIf="verifyData.type === verifyAccountType.VerifyInProgress"
        (click)="navigateTo('/account/change-phone-number')">
        <span class="btn-text" transloco="Change Phone Number"></span>
      </button>
      <button class="btn cancel" *ngIf="verifyData.type === verifyAccountType.AlreadyRegistered" (click)="closePopup()">
        <span class="btn-text" transloco="Cancel"></span>
      </button>
    </div>

    <div class="footer">
      <div *ngIf="verifyData.type === verifyAccountType.VerifyInProgress">
        {{'Please verify your account within' | transloco}}
        <ng-container *ngIf="verifyData.daysLeft !== 0"><span>{{ verifyData.daysLeft }}</span> {{'days' |
          transloco}}</ng-container>
        <ng-container *ngIf="verifyData.daysLeft !== 0 && verifyData.hoursLeft !== 0"> {{'and' | transloco}}
        </ng-container>
        <ng-container *ngIf="verifyData.hoursLeft !== 0"><span>{{ verifyData.hoursLeft }}</span> {{'hours' |
          transloco}}</ng-container>
        {{'so it does not become inactive.' | transloco}}
      </div>
      <div *ngIf="verifyData.type === verifyAccountType.Locked">
        <span class="contact-btn" (click)="navigateTo(helpService.getHelpPageUrls().contactUs)"
          transloco='Contact'></span> {{'customer support.' | transloco}}
      </div>
    </div>
  </div>
</swal>