import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, first, map, takeUntil } from 'rxjs/operators';

import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ThirdPartyStore } from 'src/app/core/state/third-party/third-party.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { UploadedDetailsModel } from 'src/app/shared/models/third-party.model';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {

  apiBaseUrl: any;

  private readonly endpoints = {
    UPLOAD_FILE: 'api/v2/uploads.json?filename={{fileName}}',
    DELETE_FILE: 'api/v2/uploads/{{token}}',
    SUBMIT_FILES: 'api/v2/requests'
  };

  private readonly loadingQueue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly thirdPartyStore: ThirdPartyStore
  ) {
    this.apiBaseUrl = this.appConfig.get('apiBaseUrl');
  }

  initService(): void {
    this.destroy$ = new Subject<boolean>();

    this.loadingQueue
      .pipe(
        map(m => {
          this.thirdPartyStore.setLoading(m > 0);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  clearOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  uploadFile(file: File): Observable<any> {
    this.queueLoading();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/binary',
      thirdPartyBaseUrl: this.apiBaseUrl.zendesk,
      noAuthToken: true
    });

    return this.apiService.post(APIType.ThirdParty, this.endpoints.UPLOAD_FILE.replace('{{fileName}}', file.name), file, apiSettings).pipe(
      first(),
      map(responseData => {
        if (responseData) {
          const thumbnails = responseData.upload.attachment.thumbnails;

          const uploadedDetails: UploadedDetailsModel = {
            thumbnail: thumbnails.length > 0 ? thumbnails[0].content_url : false,
            token: responseData.upload.token
          }
          this.thirdPartyStore.updateUploadFile(uploadedDetails);
        }

        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.thirdPartyStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  deleteFile(token: string): Observable<any> {
    this.queueLoading();

    const apiSettings: APISettings = new APISettings({
      thirdPartyBaseUrl: this.apiBaseUrl.zendesk,
      noAuthToken: true
    });

    return this.apiService.delete(APIType.ThirdParty, this.endpoints.DELETE_FILE.replace('{{token}}', token), apiSettings).pipe(
      first(),
      map(responseData => {
        this.thirdPartyStore.updateDeleteFile(responseData.Result);
        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.thirdPartyStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  submitFiles(submitFilesRequestModel: any): Observable<any> {
    this.queueLoading();

    const apiSettings: APISettings = new APISettings({
      thirdPartyBaseUrl: this.apiBaseUrl.zendesk,
      noAuthToken: true
    });

    return this.apiService.post(APIType.ThirdParty, this.endpoints.SUBMIT_FILES, submitFilesRequestModel, apiSettings).pipe(
      first(),
      map(responseData => {
        this.thirdPartyStore.updateUploadFile(responseData.Result);
        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.thirdPartyStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  private queueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value + 1);
  }

  private dequeueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value - 1);
  }
}
