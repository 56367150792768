import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MyAllOpenBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';
import { MyAllOpenBetsStore } from 'src/app/core/state/my-bets/my-all-open-bets.store';

@Injectable({ providedIn: 'root' })
export class MyAllOpenBetsQuery extends QueryEntity<MyAllOpenBetsState, RecentBetModel> {
  bets$ = this.selectAll();
  allOpenBetsCount$ = this.selectCount();
  isLoading$ = this.selectLoading();

  constructor(protected store: MyAllOpenBetsStore) {
    super(store);
  }
}
