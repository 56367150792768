import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { translate } from '@ngneat/transloco';
import { AccountStatementService } from 'src/app/core/services/account/account-statement.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { NotificationSettings } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'account-statement',
  templateUrl: './account-statement.component.html',
  styleUrls: ['./account-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountStatementComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public accountQuery: AccountQuery,
    private readonly accountStatementService: AccountStatementService,
    private readonly accountService: AccountService,
    private readonly location: Location,
    private readonly notificationService: NotificationService,
    
  ) {}

  ngOnInit(): void {
    this.accountQuery
      .selectError()
      .pipe(takeUntil(this.destroy$))
      .subscribe(err => {
        if (err) {
          const callback: Function = () => {
            this.accountService.clearError();
          };

          this.notificationService.showNotification(
            new NotificationSettings({
              allowBackdropClose: true,
              closeButtonCallback: callback,
              confirmButtonCallback: callback,
              contentText: translate('Something went wrong! Please try again or contact support.'),
              type: 'error',
              showConfirmButton: true
            })
          );
        }
      });
  }

  backPressed(): void {
    const isViewing = this.accountQuery.isViewingAccountStatement;
    this.accountStatementService.clearStatements();
    if (!isViewing) {
      this.location.back();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
