import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { APIType } from 'src/app/shared/models/api.model';
import {
  CMSNotification,
  NotificationEvent,
  NotificationRecurrence,
  NotificationType,
  NotificationUserStatus
} from 'src/app/shared/models/cms-notification';
import { APIService } from './api.service';

import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { ReplaySubject, Subject } from 'rxjs';
import { AccountQuery } from 'src/app/core/state/account/account.query';

@Injectable({
  providedIn: 'root'
})
export class CMSNotificationsService {
  dialogs$: Subject<CMSNotification[]> = new Subject();
  toasts$: Subject<CMSNotification[]> = new Subject();
  initialized$: Subject<boolean> = new Subject();
  events$: ReplaySubject<NotificationEvent> = new ReplaySubject();

  private allNotifications: CMSNotification[] = [];
  private activeNotifications: CMSNotification[] = [];

  private lastEvent: NotificationEvent;

  private readonly cmsNotificationViewedStoragePrefix = 'cms-notification-viewed-';
  private readonly cmsNotificationDontShowAgainStoragePrefix = 'cms-notification-dismissed-';

  constructor(private readonly apiService: APIService, private readonly accountQuery: AccountQuery, private readonly router: Router) {}

  init(): void {
    this.fetchNotifications();
    this.events$.subscribe(this.observeEvent.bind(this));
  }

  remove(notification: CMSNotification, dontShowAgain?: boolean): void {
    this.activeNotifications = this.activeNotifications.filter(n => n.id !== notification.id);

    localStorage.setItem(`${this.cmsNotificationViewedStoragePrefix}${notification.id}`, 'true');

    if (dontShowAgain) {
      localStorage.setItem(`${this.cmsNotificationDontShowAgainStoragePrefix}${notification.id}`, 'true');
    }

    this.observeEvent(this.lastEvent);
  }

  emitEvent(event: NotificationEvent): void {
    this.events$.next(event);
  }

  private fetchNotifications(): void {
    this.apiService.get(APIType.CMS, 'notifications').pipe(first()).subscribe(this.handleNotifications.bind(this));
  }

  private handleNotifications(response: any): void {
    this.allNotifications = response
      .map((notificationGroup: any) => notificationGroup.notifications as CMSNotification[])
      .reduce((a: CMSNotification[], b: CMSNotification[]) => [...b, ...a], [])
      .filter(this.isNotificationActive.bind(this));

    this.activeNotifications = [...this.allNotifications];

    this.initialized$.next(true);
  }

  private observeEvent(event: NotificationEvent): void {
    this.lastEvent = event;
    this.emitNotifications(event.name);
  }

  private emitNotifications(trigger: string): void {
    if (!trigger) {
      return;
    }

    const dialogs = this.activeNotifications.filter(
      notification =>
        notification.type === NotificationType.Dialog &&
        (notification.trigger === trigger || notification.customTriggerEvent === trigger) &&
        (!notification.pageRoute || notification.pageRoute === this.router.url) &&
        !localStorage.getItem(`${this.cmsNotificationDontShowAgainStoragePrefix}${notification.id}`) &&
        (notification.userStatus === NotificationUserStatus.All ||
          (notification.userStatus === NotificationUserStatus.Guest && !this.accountQuery.isAuthenticated) ||
          (notification.userStatus === NotificationUserStatus.Player && this.accountQuery.isAuthenticated))
    );

    const toasts = this.activeNotifications.filter(
      notification =>
        notification.type === NotificationType.Toast &&
        (notification.trigger === trigger || notification.customTriggerEvent === trigger) &&
        (!notification.pageRoute || notification.pageRoute === this.router.url) &&
        !localStorage.getItem(`${this.cmsNotificationDontShowAgainStoragePrefix}${notification.id}`) &&
        (notification.userStatus === NotificationUserStatus.All ||
          (notification.userStatus === NotificationUserStatus.Guest && !this.accountQuery.isAuthenticated) ||
          (notification.userStatus === NotificationUserStatus.Player && this.accountQuery.isAuthenticated))
    );

    if (!dialogs.length && !toasts.length) {
      this.activeNotifications = [...this.allNotifications];
    }

    this.dialogs$.next(dialogs);
    this.toasts$.next(toasts);
  }

  private isNotificationActive(notification: CMSNotification): boolean {
    const now = dayjs();

    if (notification.startsAt && dayjs(notification.startsAt).isAfter(now)) {
      return false;
    }

    if (notification.endsAt && dayjs(notification.endsAt).isBefore(now)) {
      return false;
    }

    if (
      notification.recurrence === NotificationRecurrence.Once &&
      localStorage.getItem(`${this.cmsNotificationViewedStoragePrefix}${notification.id}`)
    ) {
      return false;
    }

    if (
      notification.recurrence === NotificationRecurrence.AlwaysWithDismissOption &&
      localStorage.getItem(`${this.cmsNotificationDontShowAgainStoragePrefix}${notification.id}`)
    ) {
      return false;
    }

    return true;
  }
}
