import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { differenceInDays, format } from 'date-fns';
import { LanguageService } from 'src/app/core/services/language.service';

@Pipe({ name: 'formatCustomDateTime' })
export class FormatCustomDateTimePipe implements PipeTransform {
  constructor(private readonly languageService: LanguageService) {}

  transform(date: Date, hasSeconds: boolean): any {
    if (date) {
      const formatDate = new Date(date);
      const formatDateNoTime = new Date(formatDate.getFullYear(), formatDate.getMonth(), formatDate.getDate(), 0, 0, 0);

      const currentTime = new Date();
      const currentTimeNoTime = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0);

      const diffInDays = differenceInDays(formatDateNoTime, currentTimeNoTime);

      let seconds = '';
      if (hasSeconds) {
        seconds = ':ss';
      }

      if (diffInDays === 0) {
        return `${translate('Today')}${format(formatDate, `,`)}`;
      } else if (diffInDays === 1) {
        return `${translate('Tomorrow')}${format(formatDate, `,`)}`;
      } else if (diffInDays > 0 && diffInDays < 7) {
        return format(formatDate, `EEE,`, { locale: this.languageService.selectedLanguage.dateLocale });
      } else if (diffInDays < 0 || diffInDays >= 7) {
        return format(formatDate, `dd/MM/yy,`);
      }
    } else {
      return date;
    }
  }
}
