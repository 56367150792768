import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { CouponType } from 'clientside-coupon';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponEditService } from 'src/app/core/services/coupon/coupon-edit.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MatchService } from 'src/app/core/services/match.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { expandCollapse } from 'src/app/shared/animations';
import { CouponGroupingType, CouponOddsModel, OddModel } from 'src/app/shared/models/coupon.model';
import { MatchModel, SportModel, TournamentModel } from 'src/app/shared/models/sport.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-coupon-selections',
  templateUrl: './coupon-selections.component.html',
  styleUrls: ['./coupon-selections.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponSelectionsComponent implements OnInit {
  @Input() bookedCoupon: boolean = false;
  couponType: typeof CouponType = CouponType;
  hasOddChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  groupingType: typeof CouponGroupingType = CouponGroupingType;
  oddChangesInterval = undefined;
  numberOfMatchesInCoupon$: Observable<number> = this.couponQuery.couponData$.pipe(
    map(coupon => (coupon && coupon.Odds && coupon.Odds.length) || 0)
  );
  private oddChangesTimer;

  constructor(
    private readonly appConfig: AppConfigService,
    readonly couponQuery: CouponQuery,
    private readonly matchService: MatchService,
    private readonly couponService: CouponService,
    private readonly couponEditService: CouponEditService,
    private readonly applicationService: ApplicationService,
    private readonly languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());

    this.couponQuery.oddChanges$.subscribe(oddChanges => {
      if (oddChanges) {
        this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());
      }
    });
    // if (this.applicationService.isDesktop$.value) {
    //   if (this.couponQuery.oddChanges !== undefined && this.couponQuery.oddChanges.length > 0) {
    //     this.runCouponOddChanges();
    //   } else {
    //     this.stopCouponOddChanges();
    //   }
    // }
  }
  runCouponOddChanges(): void {
    this.stopCouponOddChanges();

    const couponOdds = this.getCouponOdds();

    if (couponOdds !== undefined) {
      const containsLiveEvents = couponOdds.some(odd => odd.isLive);

      if (containsLiveEvents) {
        this.oddChangesTimer = this.appConfig.get('sports').coupon.liveOddChangesTimer;
      } else {
        this.oddChangesTimer = this.appConfig.get('sports').coupon.oddChangesTimer;
      }

      this.couponService.getOddsChanged(couponOdds, this.languageService.selectedLanguage.language).subscribe();
    }

    this.oddChangesInterval = window.setInterval(() => {
      this.runCouponOddChanges();
    }, this.oddChangesTimer);
  }

  stopCouponOddChanges(): void {
    if (this.oddChangesInterval) {
      clearInterval(this.oddChangesInterval);
    }
  }
  getCouponOdds(): CouponOddsModel[] {
    if (this.couponQuery.couponData === undefined) {
      return undefined;
    }

    const couponOdds: CouponOddsModel[] = [];
    this.couponQuery.couponData.Odds.forEach(odd => {
      couponOdds.push({
        isLive: odd.EventCategory.toLowerCase() === 'l',
        matchId: odd.MatchId,
        marketId: odd.MarketId,
        selectionId: odd.SelectionId,
        selectionValue: odd.OddValue
      });
    });

    return couponOdds;
  }
  updateOddBankerStatus(oddId: number, banker: boolean): void {
    if (!this.bookedCoupon) {
      this.couponService.updateOddBankerStatus(oddId, banker);
    }
  }

  removeOdd(selectionId: number): void {
    if (!this.bookedCoupon) {
      this.couponService.removeOdd(selectionId);
    }
  }

  selectionTrackBy(item: SportModel): number {
    return item.id;
  }

  tournamentTrackBy(item: TournamentModel): number {
    return item.id;
  }

  matchTrackBy(item: MatchModel): number {
    return item.id;
  }

  oddTrackBy(item: OddModel): number {
    return item.id;
  }

  marketOddMapTrackBy(index: number): number {
    return index;
  }

  isLocked$(odd: OddModel): Observable<boolean> {
    return this.couponService.lockedCouponSelections$.pipe(map(lockedHash => !!lockedHash[odd.id]));
  }

  editOdd(smartBetCode: number, marketTypeId: number, spreadValue?: number): void {
    if (
      !(
        this.couponQuery.selectedForEdit !== undefined &&
        smartBetCode === this.couponQuery.selectedForEdit.matchId &&
        marketTypeId === this.couponQuery.selectedForEdit.marketTypeId
      )
    ) {
      this.couponEditService
        .getMarketSelections(
          smartBetCode,
          marketTypeId,
          spreadValue === undefined || spreadValue === 0 ? undefined : spreadValue,
          this.languageService.selectedLanguage.language
        )
        .subscribe();
      this.couponService.allowCompleteDeselectOfEventOdds = false;
    } else {
      this.couponEditService.clearEditData();
    }
  }

  getSelectionName(odd) {
    const hasSpread = odd?.selectionName.includes('(');
    const hideSpread =
      hasSpread && odd.sportId === 1 && this.appConfig.get('sports')?.coupon?.hideMarketSpreadIds.includes(odd.marketTypeId);
    const selectionNameSplit = odd?.selectionName.split(' ');
    return hideSpread ? selectionNameSplit.slice(0, selectionNameSplit.length - 1).join(' ') : odd?.selectionName;
  }

  clearEditData(): void {
    this.couponEditService.clearEditData();
  }

  goToPage(id: number, name: string): void {
    this.matchService.navigateToMatch(id, name);
    this.applicationService.closeAnySlideUps();
    this.applicationService.showCoupon$.next(false);
  }
}
