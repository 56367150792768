import { driver } from 'driver.js';

import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from 'src/app/core/services/account/account.service';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CMSNotificationsService } from 'src/app/core/services/cms-notifications.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { BottomSheetQuery } from 'src/app/core/state/bottom-sheet/bottom-sheet.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { PromoFabQuery } from 'src/app/core/state/promo-fab/promo-fab.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { Browser, getBrowser } from 'src/app/core/utils/device';
import { CasinoService } from 'src/app/modules/casino/casino.service';
import { MetaService } from 'src/app/modules/meta/meta.module';
import { fadeIn, slideUp } from 'src/app/shared/animations';
import { ProductType } from 'src/app/shared/models/application.model';
import { NotificationEvent, NotificationEventName, NotificationEventType } from 'src/app/shared/models/cms-notification';
import { SiteMessageService } from '../../core/services/site-message.service';
import { ChatPopupQuery } from '../../core/state/chat-popup/chat-popup.query';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { translate } from '@ngneat/transloco';
import { PayoutPromotionTransaction, PendingWithdrawalsModel } from 'src/app/shared/models/account.model';
import { environment } from 'src/environments/environment';
import { createEventSourceObservable } from 'src/app/core/utils/event-source-observable';
import { BookBetService } from 'src/app/core/services/book-bet.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { CouponOddsModel } from 'src/app/shared/models/coupon.model';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { APIType } from 'src/app/shared/models/api.model';
import { TutorialService } from 'src/app/modules/tutorial/tutorial.service';
import { Location } from '@angular/common';
import { TodaysEventsService } from 'src/app/core/services/todays-events.service';
import { PwaService } from 'src/app/core/services/pwa.service';
import { CouponType } from 'clientside-coupon';
@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
  animations: [fadeIn(), slideUp()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrapperComponent implements OnInit, OnDestroy {
  lastOrientation = window.screen.orientation;
  lastWidth: number = window.innerWidth;
  lastHeight: number = window.innerHeight;
  destroy$ = new Subject<boolean>();
  showNavBar$ = new BehaviorSubject<boolean>(true);
  noPadding = false;
  usedAutoLogin: Subscription;
  isSafari$ = new BehaviorSubject<boolean>(false);
  couponType: typeof CouponType = CouponType;

  qualifiedPendingWithdrawals$ = this.accountQuery.isAuthenticated$.pipe(
    switchMap(authenticated => {
      if (!authenticated) {
        return of([]);
      }
      return this.accountPaymentQuery.pendingWithdrawals$.pipe(
        map(w => {
          return w.filter(p => {
            const details = JSON.parse(p.furtherDetails);
            return details.PayoutPromoQualified;
          });
        })
      );
    })
  );
  hasQualifiedPendingWIthdrawals$ = this.qualifiedPendingWithdrawals$.pipe(map(w => w.length > 0));

  public transactionUpdate$ = combineLatest([this.accountQuery.accessToken$, this.hasQualifiedPendingWIthdrawals$]).pipe(
    filter(([token, has]) => token !== null && has),
    switchMap(([token]) => {
      return createEventSourceObservable<PayoutPromotionTransaction>(
        `${environment.appConfigDefaults.apiBaseUrl.promotionsEngine}/payout/transactions-sse?access_token=${token}`
      );
    })
  );

  coupon$: BehaviorSubject<CouponQuery> = new BehaviorSubject(this.couponQuery);
  betSlipBadgeCount$: Observable<number> = this.couponQuery.couponData$.pipe(
    map(coupon => (coupon && coupon.Odds && coupon.Odds.length) || 0)
  );

  public showSignIn$ = this.accountService.showLogin$;
  public showPromoPayoutModal$ = this.applicationService.showPromoPayoutModal$;
  public showPromoDebug$ = this.applicationService.showPromoPayoutModal$.pipe(map(show => JSON.stringify(show)));
  public showChooseYourPromo$ = this.accountService.showChooseYourPromo$;

  private oddChangesTimer: any;
  private oddChangesInterval: any;

  private hasFirstCouponSyncDone = false;

  constructor(
    public route: ActivatedRoute,
    public accountQuery: AccountQuery,
    public accountService: AccountService,
    public applicationQuery: ApplicationQuery,
    public applicationService: ApplicationService,
    public accountPaymentQuery: AccountPaymentQuery,
    private readonly accountPaymentService: AccountPaymentService,
    public notificationService: NotificationService,
    public localStorage: LocalStorageService,
    public sportQuery: SportQuery,
    public promoFabQuery: PromoFabQuery,
    public bottomSheetQuery: BottomSheetQuery,
    public chatPopupQuery: ChatPopupQuery,
    public casinoService: CasinoService,
    private readonly router: Router,
    private readonly metaService: MetaService,
    private readonly siteMessageService: SiteMessageService,
    private readonly cmsNotificationsService: CMSNotificationsService,
    private readonly apiService: APIService,
    private readonly couponQuery: CouponQuery,
    private readonly appConfig: AppConfigService,
    private readonly bookBetService: BookBetService,
    private readonly languageService: LanguageService,
    private readonly appConfigService: AppConfigService,
    private readonly couponService: CouponService,
    private readonly tutorialService: TutorialService,
    readonly location: Location,
    private readonly todaysEventsService: TodaysEventsService
  ) {}
  ngOnInit(): void {
    this.handleVisibilityChange();
    this.handleNotificationEvent;

    this.accountQuery.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated && !this.appConfig.get('maintenanceMode')) {
        this.getPendingWithdrawals();
      }
    });
    this.initCMSNotifications();
    this.observeNotificationEvents();
    this.isSafari$.next(this.isSafari());
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.accountQuery.isAuthenticated$.subscribe(isAuthenticated => {
        if (isAuthenticated) {
          this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
        }
      });
      if (!window.location.pathname.includes('/promos')) {
        this.scrollToTop();
      }
      this.applicationService.routeHistory.push(event.url);
      const urlSplit = event.url.split('/');
      urlSplit.shift();
      if (urlSplit[0] === 'virtual') {
        this.applicationService.updateUI({ showVirtualsBetslip: true });
      } else {
        this.applicationService.updateUI({ showVirtualsBetslip: false });
      }
      if (urlSplit[0] === 'lp') {
        this.applicationService.updateUI({ showNavBar: false });
        this.noPadding = true;
      } else if (urlSplit.length === 2 && urlSplit[1].includes('payment-completed')) {
        this.applicationService.updateUI({ showNavBar: false });
        this.noPadding = true;
      } else {
        this.applicationService.updateUI({ showNavBar: true });
        this.noPadding = false;
      }

      this.applicationService.updateActiveUrl(urlSplit);
      this.metaService.useDataFromCMS(event);
    });
    // this.checkNativeKeyboard();
    this.route.queryParams.subscribe(this.handleQueryParams.bind(this));

    // this.applicationQuery.activeProduct$.subscribe(activeProduct => {
    //   if (activeProduct === ProductType.SportsBook) {
    //     this.coupon$.next(this.couponQuery);
    //   }
    // });

    this.handleScreenResolution();
    // this.transactionUpdate$.subscribe(transaction => {
    //   if (!this.appConfig.get('maintenanceMode')) {
    //     this.getPendingWithdrawals();
    //   }

    //   if (
    //     transaction.closedDate &&
    //     transaction.qualifies === 'qualified' &&
    //     ['VALIDATED', 'ONHOLD', 'COMPLETE'].includes(transaction.status)
    //   )
    //     this.applicationService.showPromoPayoutModal$.next({
    //       open: true,
    //       promoDetails: {
    //         cashAward: transaction.cashAward,
    //         closedDate: new Date(transaction.closedDate),
    //         qualified: Boolean(transaction.qualifies),
    //         spinsAward: transaction.spinsAward,
    //         transactionDate: new Date(transaction.transactionDate),
    //         transactionId: transaction.platformId
    //       }
    //     });
    // });

    // this.couponQuery.couponData$.pipe(takeUntil(this.destroy$), debounceTime(100)).subscribe(couponData => {
    //   clearTimeout(this.oddChangesInterval);

    //   const couponOdds = this.getCouponOdds();
    //   if (couponOdds) {
    //     const containsLiveEvents = couponOdds.some(odd => odd.isLive);
    //     const fetchIn = containsLiveEvents
    //       ? this.appConfigService.get('sports').coupon.liveOddChangesTimer
    //       : this.appConfigService.get('sports').coupon.oddChangesTimer;

    //     this.oddChangesInterval = setTimeout(() => {
    //       this.couponService.getOddsChanged(couponOdds, this.languageService.selectedLanguage.language).pipe(first()).subscribe();
    //     }, fetchIn);
    //   }
    // });
  }
  private scrollToTop(): void {
    if (document.body) {
      document.body.scrollTop = -15;
    }
  }
  shouldNotDisplayDecimal(value: number): boolean {
    const maxOddWithDecimal = 100;
    return value > maxOddWithDecimal;
  }
  hideCouponPreviewIfNeeded(): boolean {
    return (
      window.location.pathname.includes('/my-bets') ||
      window.location.pathname.includes('/account') ||
      window.location.pathname.includes('/help') ||
      window.location.pathname.includes('/menu') ||
      window.location.pathname.includes('/message-center') ||
      window.location.pathname.includes('/results') ||
      window.location.pathname.includes('/coupon-check') ||
      window.location.pathname.includes('/slot')
    );
  }
  floorTotalOdds(value: number): number {
    return Math.floor(value);
  }

  loadBookedBetQueryParam(code: string) {
    if (!code || code === 'undefined') {
      return;
    }

    this.bookBetService.getBookBet(code, this.languageService.selectedLanguage.language).subscribe();
  }

  isVerified() {
    return (
      (this.accountQuery.hasValidId && this.accountQuery.hasValidMobilePhone && this.accountQuery.emailVerified) ||
      this.accountQuery.skipSessionOne
    );
  }
  goToPromoPayoutDetails(withdrawal: PendingWithdrawalsModel) {
    this.router.navigate(['/account/withdrawal/promotion', withdrawal.id]);
  }
  isPayoutQualified(reWithdrawal: any): boolean {
    if (!reWithdrawal || !reWithdrawal.furtherDetails) {
      return false;
    }
    return JSON.parse(reWithdrawal.furtherDetails).PayoutPromoQualified;
  }
  completeVerification() {
    if (this.accountQuery.hasValidId && this.accountQuery?.userData?.mobile) {
      this.navigateTo('register');
    } else {
      this.navigateTo('strenghten-profile');
    }
  }

  closeMiniSlideUp(): void {
    this.applicationService.showQuickCoupon(false);
  }

  checkNativeKeyboard(): void {
    fromEvent(window, 'resize')
      .pipe(
        map(() => {
          let keyboardStateChanged = false;
          if (this.lastOrientation === window.screen.orientation && this.lastWidth === window.innerWidth) {
            let heightDifference = this.lastHeight - window.innerHeight;
            if (heightDifference < 0) {
              heightDifference = heightDifference * -1;
            }
            if (heightDifference > 60) {
              keyboardStateChanged = true;
            }
          }

          this.lastHeight = window.innerHeight;
          this.lastWidth = window.innerWidth;
          this.lastOrientation = window.screen.orientation;

          return keyboardStateChanged;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(keyboardStateChanged => {
        if (keyboardStateChanged) {
          this.applicationService.updateUI({ showNavBar: !this.applicationQuery.showNavBar$ });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  getPendingWithdrawals(): void {
    this.accountPaymentService
      .getPendingWithdrawals()
      .pipe(takeUntil(this.destroy$))
      .subscribe(error => {
        if (error) {
          this.notificationService.showErrorNotification(
            translate('Something went wrong! Please try again or contact support.'),
            error.statusText
          );
        }
      });
  }
  public isSafari() {
    return getBrowser() === Browser.Safari;
  }

  closeAZMenu(): void {
    this.applicationService.showAZMenu$.next(false);
  }

  openCoupon(): void {
    this.couponService.syncCouponToServer().subscribe();
    this.applicationService.showCoupon$.next(true);
  }

  runCouponOddChanges(): void {
    this.stopCouponOddChanges();

    const couponOdds = this.getCouponOdds();

    if (couponOdds !== undefined) {
      const containsLiveEvents = couponOdds.some(odd => odd.isLive);

      if (containsLiveEvents) {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.liveOddChangesTimer;
      } else {
        this.oddChangesTimer = this.appConfigService.get('sports').coupon.oddChangesTimer;
      }

      if (this.couponService.skipOddsChangedUpdate$) {
        return;
      }
      this.couponService.getOddsChanged(couponOdds, this.languageService.selectedLanguage.language).subscribe();
    }

    this.oddChangesInterval = window.setInterval(() => {
      this.runCouponOddChanges();
    }, this.oddChangesTimer);
  }

  stopCouponOddChanges(): void {
    if (this.oddChangesInterval) {
      clearInterval(this.oddChangesInterval);
    }
  }

  getCouponOdds(): CouponOddsModel[] {
    if (this.couponQuery.couponData === undefined) {
      return undefined;
    }

    const couponOdds: CouponOddsModel[] = [];
    this.couponQuery.couponData.Odds.forEach(odd => {
      couponOdds.push({
        isLive: odd.EventCategory.toLowerCase() === 'l',
        matchId: odd.MatchId,
        marketId: odd.MarketId,
        selectionId: odd.SelectionId
        // selectionValue: odd.OddValue
      });
    });

    return couponOdds;
  }

  @HostListener('window:resize', ['$event']) onResize(event: any): void {
    this.handleScreenResolution();
    setTimeout(this.handleScreenResolution.bind(this), 100);
    setTimeout(this.handleScreenResolution.bind(this), 300);
  }

  @HostListener('window:orientationchange', ['$event']) onOrientationChange(event: any): void {
    this.handleScreenResolution();
    setTimeout(this.handleScreenResolution.bind(this), 100);
    setTimeout(this.handleScreenResolution.bind(this), 300);
  }

  get showAZMenu$(): Observable<boolean> {
    return this.applicationService.showAZMenu$;
  }

  get showAccountMenu$(): Observable<boolean> {
    return this.applicationService.showAccountMenu$;
  }

  get showCoupon$(): Observable<boolean> {
    return this.applicationService.showCoupon$;
  }

  get showTransactionsSideMenu$(): Observable<boolean> {
    return this.applicationService.showTransactionsMenu$;
  }

  get showLanguagePickerMenu$(): Observable<boolean> {
    return this.applicationService.showLanguagePicker$;
  }

  get showMyBets$(): Observable<boolean> {
    return this.applicationService.showMyBets$;
  }

  private handleQueryParams(param: any): void {
    if (param.hasOwnProperty('btag')) {
      this.localStorage.store('btag', param.btag);
    }
    if (param.hasOwnProperty('username') && param.hasOwnProperty('otp') && !this.accountQuery?.userData?.accessToken) {
      this.accountService.login(param.username, param.otp).subscribe(this.observeUserData.bind(this));
    }
    if (param.referrer) {
      this.accountQuery.accessToken ? this.updateProfileReferrer(param.referrer) : this.storeReferrer(param.referrer);
    }
    if (param.booking_code) {
      this.loadBookedBetQueryParam(param.booking_code);
    }
  }

  private readonly storeReferrer = (referrer: string): void => {
    this.localStorage.store('referrer', referrer);
  };

  private updateProfileReferrer(referrer: string): void {
    this.accountService.updateReferrer(referrer).subscribe();
  }

  private observeUserData(): void {
    this.usedAutoLogin = this.accountQuery.userData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.accountQuery?.userData?.accessToken) {
        if (!this.isVerified()) {
          this.completeVerification();
          this.usedAutoLogin.unsubscribe();
        }
      }
    });
  }

  private navigateTo(link: string): void {
    this.router.navigate([link]);
  }

  private initCMSNotifications(): void {
    this.cmsNotificationsService.init();
  }

  private observeNotificationEvents(): void {
    this.cmsNotificationsService.events$
      .pipe(
        filter(event => event.type === NotificationEventType.Action),
        takeUntil(this.destroy$)
      )
      .subscribe(this.handleNotificationEvent.bind(this));
  }

  private handleNotificationEvent(event: NotificationEvent): void {
    if (event.name === NotificationEventName.GoToLogin) {
      this.accountService.openLogin();
    }
  }

  private handleScreenResolution(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    this.applicationService.isDesktop$.next(window.innerWidth >= 1280);
  }
  handleVisibilityChange(): void {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        if (
          window.location.pathname === '/' ||
          window.location.pathname.startsWith('/sports') ||
          window.location.pathname.startsWith('/betting')
        ) {
          this.todaysEventsService
            .handleGetEvents()
            .pipe(first())
            .subscribe(() => this.todaysEventsService.loading$.next(false));
        }
      }
    });
  }
}
