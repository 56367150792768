/* tslint:disable */
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { translate } from '@ngneat/transloco';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PendingWithdrawalsModel } from 'src/app/shared/models/account.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { PopupBanner } from 'src/app/shared/components/popup-banners/popup-banners.component';
import { ApplicationService } from 'src/app/core/services/application.service';
class Point {
  x: number;
  y: number;

  constructor() {
    this.x = 0;
    this.y = 0;
  }
}

@Component({
  selector: 'app-popup-banner-fab',
  templateUrl: './popup-banner-fab.component.html',
  styleUrls: ['./popup-banner-fab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupBannerFabComponent implements OnInit, AfterViewInit, OnDestroy {
  promoIframeUrl: SafeResourceUrl;
  @Input() banner: PopupBanner;
  @ViewChild('fab', { static: true }) fabElementRef!: ElementRef;
  @ViewChild('dimissCircle', { static: true }) dismissCircleRef!: ElementRef;

  public stickAnimation$: Subject<boolean> = new Subject();
  public dismissAnimation$: Subject<boolean> = new Subject();
  public pushDownAnimation$: Subject<boolean> = new Subject();
  public isDragging: boolean;
  public isDismissing: boolean;
  private position = {
    initial: new Point(),
    current: new Point(),
    offset: new Point()
  };

  private lastDragPoints: Point[] = [];
  private readonly lastPointsToCalculate = 12;

  private lastPositionChangeTime: number;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private cdRef: ChangeDetectorRef,
    private readonly accountPaymentService: AccountPaymentService,
    private notificationService: NotificationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
    private readonly appConfig: AppConfigService,
    private readonly appService: ApplicationService
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    this.initEvents();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  handleClick() {
    if (this.lastPositionChangeTime === undefined || new Date().getTime() - this.lastPositionChangeTime > 500) {
      this.appService.showPopupBanner$.next(this.banner);
    }
  }

  private initEvents(): void {
    // Start dragging
    this.handleEvents(this.fab, ['mousedown', 'touchstart'], this.handleDragStart.bind(this));

    // End dragging
    this.handleEvents(this.fab, ['mouseup', 'touchend'], this.handleDragEnd.bind(this));

    // Dragging (mobile)
    this.handleEvents(this.fab, ['touchmove'], this.handleDragging.bind(this));

    // Dragging (desktop)
    // Mouse move calculation can be faster than FAB is able to move.
    // Thus, we bind the mouse move event to document so we don't lose the movement focus.
    this.handleEvents(document, ['mousemove'], this.handleDragging.bind(this));
  }

  private handleEvents(target: FromEventTarget<Event>, events: string[], observer: any): void {
    events.forEach(event => fromEvent(target, event).pipe(takeUntil(this.destroy$)).subscribe(observer));
  }

  private handleDragStart(event: TouchEvent | MouseEvent): void {
    this.setIsDraggingActive(true);
    this.handleInitialPosition(event);
  }

  private handleInitialPosition(event: TouchEvent | MouseEvent): void {
    let initialPosition: Point;

    if (this.isMobileEvent(event)) {
      initialPosition = {
        x: (event as TouchEvent).touches[0].clientX - this.position.offset.x,
        y: (event as TouchEvent).touches[0].clientY - this.position.offset.y
      };
    } else {
      initialPosition = {
        x: (event as MouseEvent).clientX - this.position.offset.x,
        y: (event as MouseEvent).clientY - this.position.offset.y
      };
    }

    this.setInitialPosition(initialPosition);
  }

  private handleDragEnd(event: MouseEvent): void {
    // Disable until we find a way to make it work smoother
    // if (this.shouldPushToDismiss()) {
    //   this.animatePushDownToDismiss();
    //   return;
    // }

    this.lastDragPoints = [];

    this.handleFabDismissCircle(true);

    if (this.isDismissing) {
      return;
    }

    this.stickToScreen();

    this.setInitialPosition(this.position.current);
    this.setOffsetPosition(this.position.current);
    this.moveFab(this.position.current);

    this.setIsDraggingActive(false);
  }

  private shouldPushToDismiss(): boolean {
    if (this.lastDragPoints.length < this.lastPointsToCalculate) {
      return;
    }

    const lastPoints = this.lastDragPoints.slice(this.lastDragPoints.length - this.lastPointsToCalculate, this.lastDragPoints.length);

    const averageDragAngle =
      lastPoints
        .map((point, index) => (index === 0 ? null : this.getAngleBetweenPoints(point, lastPoints[index - 1])))
        .filter(point => !!point)
        .reduce((a, b) => a + b) /
      (lastPoints.length - 1);

    const angleBetweenCircles = this.getAngleBetweenPoints(this.getElementPosition(this.dismissCircle), this.getElementPosition(this.fab));

    return Math.abs(averageDragAngle - angleBetweenCircles) < 45;
  }

  private animatePushDownToDismiss(): void {
    this.isDragging = false;
    this.pushDownAnimation$.next(true);
    setTimeout(this.dismiss.bind(this), 200);
  }

  private stickToScreen(): void {
    this.handleAnimation();

    this.setCurrentPosition({
      x: this.isFabOnLeftSideOfScreen ? 0 : window.innerWidth - this.fab.clientWidth,
      y: this.position.current.y + 30
    });

    this.handleFabOutOfScreen();
  }

  private handleFabOutOfScreen(): void {
    if (this.position.current.y + this.fab.clientHeight > window.innerHeight) {
      this.setCurrentPosition({
        x: this.position.current.x,
        y: window.innerHeight - this.fab.clientHeight
      });
    } else if (this.position.current.y < 0) {
      this.setCurrentPosition({
        x: this.position.current.x,
        y: 0
      });
    }
  }

  private handleAnimation(): void {
    this.stickAnimation$.next(true);
    setTimeout(() => this.stickAnimation$.next(false), 500);
  }

  private handleDragging(event: TouchEvent | MouseEvent): void {
    if (!this.isDragging) {
      return;
    }

    event.preventDefault();

    if (this.isMobileEvent(event)) {
      this.setCurrentPosition({
        x: (event as TouchEvent).touches[0].clientX - this.position.initial.x,
        y: (event as TouchEvent).touches[0].clientY - this.position.initial.y
      });
    } else {
      this.setCurrentPosition({
        x: (event as MouseEvent).clientX - this.position.initial.x,
        y: (event as MouseEvent).clientY - this.position.initial.y
      });
    }

    this.lastDragPoints.push(this.position.current);

    this.setOffsetPosition(this.position.current);
    this.moveFab(this.position.current);

    this.handleFabDismissCircle();
  }

  private handleFabDismissCircle(dismiss?: boolean): void {
    const fabCenter: Point = {
      x: (this.fab.getBoundingClientRect() as any).x + this.fab.clientWidth / 2,
      y: (this.fab.getBoundingClientRect() as any).y + this.fab.clientHeight / 2
    };

    const circleCenter: Point = {
      x: (this.dismissCircle.getBoundingClientRect() as any).x + this.dismissCircle.clientWidth / 2,
      y: (this.dismissCircle.getBoundingClientRect() as any).y + this.dismissCircle.clientHeight / 2
    };

    const distance = this.getDistanceBetweenPoints(fabCenter, circleCenter);

    this.dismissAnimation$.next(distance < 3 * this.fab.clientWidth);

    if (dismiss && distance < 0.75 * this.fab.clientWidth) {
      this.dismiss();
    }
  }

  private dismiss(): void {
    localStorage.setItem(`popup-banner-fab-dismissed-${this.banner.id}`, `${new Date().getTime()}`);
    this.isDismissing = true;
  }

  private setOffsetPosition(position: Point): void {
    this.position.offset = { ...position };
  }

  private setInitialPosition(position: Point): void {
    this.position.initial = { ...position };
  }

  private setCurrentPosition(position: Point): void {
    if (position.x !== 0) {
      this.lastPositionChangeTime = new Date().getTime();
    }

    this.position.current = { ...position };
  }

  private setIsDraggingActive(isActive: boolean): void {
    this.isDragging = isActive;
  }

  private moveFab(position: Point): void {
    this.fab.style.transform = `translate3d(${position.x}px, ${position.y}px, 0)`;
  }

  private getAngleBetweenPoints(pointA: Point, pointB: Point): number {
    return (Math.atan2(pointA.y - pointB.y, pointA.x - pointB.x) * 180) / Math.PI + 90;
  }

  private getDistanceBetweenPoints(pointA: Point, pointB: Point): number {
    return Math.sqrt(Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2));
  }

  private getElementPosition(element: HTMLDivElement): Point {
    return {
      x: (element.getBoundingClientRect() as any).x,
      y: (element.getBoundingClientRect() as any).y
    };
  }

  private isMobileEvent(event: MouseEvent | TouchEvent): boolean {
    return event.type === 'touchstart' || event.type === 'touchmove';
  }

  get fab(): HTMLDivElement {
    return this.fabElementRef.nativeElement;
  }

  get dismissCircle(): HTMLDivElement {
    return this.dismissCircleRef.nativeElement;
  }

  get isFabOnLeftSideOfScreen(): boolean {
    return this.position.current.x < window.innerWidth / 2;
  }
}
