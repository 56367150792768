import { Injectable } from '@angular/core';

import { Query } from '@datorama/akita';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { TodaysEventsStore } from 'src/app/core/state/todays-events/todays-events.store';
import { CategoryTournamentsFilter } from 'src/app/shared/models/category-tournaments-filter';
import { AreaModel, MatchModel } from 'src/app/shared/models/sport.model';
import {
  AreaMarketModel,
  AreaMatchModel,
  DayModel,
  MatchByTournamentModel,
  SportModel,
  TodaysEventsState
} from 'src/app/shared/models/todays-events.model';

@Injectable({ providedIn: 'root' })
export class TodaysEventsQuery extends Query<TodaysEventsState> {
  searchTerm = '';
  categoryTournamentsFilter$: BehaviorSubject<CategoryTournamentsFilter[]> = new BehaviorSubject([]);
  selectedMatch$: BehaviorSubject<MatchModel | undefined> = new BehaviorSubject(undefined);
  activeFilteredTournaments: MatchByTournamentModel[];
  activeFilteredEvents: AreaMatchModel[];
  correctScoreAreaIds = this.appConfig.get('correctScoreAreaIds');
  areaMatches$ = this.select(state => state.areaMatches).pipe(
    tap(matches => {
      this.activeFilteredEvents = matches;
      this.filterMatches();
    })
  );
  filteredAreaMatches$: BehaviorSubject<AreaMatchModel[]> = new BehaviorSubject([undefined]);
  regionsWithAreas$ = this.select(state => state.regionsWithAreas);
  areas$ = this.select(state => state.areas);
  regions$ = this.select(state => state.regions);
  sports$ = this.select(state => state.sports);
  selectedTournamentName$ = this.select(state => {
    if (state.selectedTournamentName === 'All') {
      return translate('All');
    }

    return state.selectedTournamentName;
  });
  matchesByTournament$ = this.select(state => state.matchesByTournament).pipe(
    tap((tournaments: MatchByTournamentModel[]) => {
      this.activeFilteredTournaments = tournaments;
      this.filterMatchesByTournament();
    })
  );
  filteredMatchesByTournament$: BehaviorSubject<MatchByTournamentModel[]> = new BehaviorSubject([]);
  tournamentNames$ = this.select(state => state.tournamentNames);
  selectedDay$ = this.select(state => state.selectedDay);
  selectedSport$ = this.select(state => state.selectedSport);
  selectedRegion$ = this.select(state => state.selectedRegion);
  selectedArea$ = this.select(state => state.selectedArea);
  selectedMarket$ = this.select(state => state.selectedMarket);
  selectedMarketSportModel$ = this.select(state => state.selectedMarket?.toSportMarketModel());
  sortType$ = this.select(state => state.sortType);
  isEventsLoading$ = this.select(state => state.ui.isEventsLoading);
  firstTimeLoadIsInProgress$ = this.select(state => state.ui.firstTimeLoadIsInProgress);
  flowControl$ = combineLatest(this.selectedDay$, this.selectedRegion$, this.selectedArea$);
  isItMultiline$ = this.select(state => state.isItMultiline);
  isItCorrectScores$ = this.selectedArea$.pipe(
    map(area => {
      let isItCorrectScore = false;
      this.correctScoreAreaIds.forEach(areaId => {
        if (area && areaId === area.id) {
          isItCorrectScore = true;
        }
      });
      return isItCorrectScore;
    })
  );
  isItOverUnderType$ = this.selectedMarket$.pipe(
    map(
      market =>
        market && market.selections && market.selections[0].spreadDisplayValue !== '' && market.selections[0].spreadDisplayValue !== '0'
    )
  );
  areaMatchesForPlayerViewSingleLine$ = this.areaMatches$.pipe(
    map(data => {
      if (!data || !data[0]) {
        return;
      }
      const reducedTeamsData = [];
      const flags = [];
      const reducedEvens: any = [];
      const l = data[0].items.length;
      let i: number;
      for (i = 0; i < l; i++) {
        if (flags[data[0].items[i].extParentItemID]) {
          continue;
        }
        flags[data[0].items[i].extParentItemID] = true;
        reducedEvens.push({ extParentItemID: data[0].items[i].extParentItemID, data: [] });
      }
      data[0].items.forEach(element => {
        reducedEvens.forEach(event => {
          if (element.extParentItemID === event.extParentItemID) {
            event.data.push(element);
          }
        });
      });

      reducedEvens.forEach((eventData, index) => {
        eventData.data.forEach(innerEventData => {
          const flags2 = [];
          const reducedEvens2: any = [];
          const l2 = eventData.data.length;
          let i2: number;
          for (i2 = 0; i2 < l2; i2++) {
            if (flags2[eventData.data[i2].extParentTeamID]) {
              continue;
            }
            flags2[eventData.data[i2].extParentTeamID] = true;
            reducedEvens2.push({
              extParentTeamID: eventData.data[i2].extParentTeamID,
              data: [],
              tournamentName: eventData.data[i2].tournamentName
            });
          }
          reducedTeamsData[index] = [].concat(reducedEvens2);
        });
      });

      reducedEvens.forEach((eventData2, index) => {
        eventData2.data.forEach(innerEventData => {
          reducedTeamsData.forEach(id => {
            id.forEach((elem, index2) => {
              if (elem.extParentTeamID === innerEventData.extParentTeamID) {
                reducedTeamsData[index][index2].data.push(innerEventData);
              }
            });
          });
        });
      });

      reducedTeamsData.forEach(teamData => {
        let allTypeOfOdds = [];
        teamData.forEach(matchData => {
          matchData.data.forEach(oddsData => {
            if (oddsData.odds.length > allTypeOfOdds.length) {
              allTypeOfOdds = oddsData.odds;
            }
          });
        });
        teamData.push(allTypeOfOdds);
      });
      const reorderedTeamData = [];
      reducedTeamsData.forEach(teams => {
        if (!teams || teams.length !== 3) {
          return;
        }
        let homeTeam;
        let awayTeam;
        const odds = [...teams[teams.length - 1]];
        if (teams[0].data[0].extTeamOrder === 2) {
          awayTeam = { ...teams[0] };
          homeTeam = { ...teams[1] };
        }
        if (teams[0].data[0].extTeamOrder === 1) {
          awayTeam = { ...teams[1] };
          homeTeam = { ...teams[0] };
        }
        const newTeamOrder = [homeTeam, awayTeam, odds];
        reorderedTeamData.push(newTeamOrder);
      });

      return reorderedTeamData;
    })
  );
  areaMatchesForPlayerViewSingleLineSortByTournament$ = this.areaMatchesForPlayerViewSingleLine$.pipe(
    map(teamData => {
      const matchesByTournaments = [];
      this.tournamentNames.forEach(tournamentName => {
        const matchesByTournament = { tournamentName, data: [] };
        teamData.forEach(teams => {
          if (teams[0].data[0].tournamentName === tournamentName) {
            matchesByTournament.data.push(teams);
          }
        });
        matchesByTournaments.push(matchesByTournament);
      });
      return matchesByTournaments;
    })
  );

  constructor(private readonly appConfig: AppConfigService, protected store: TodaysEventsStore) {
    super(store);
  }

  get areaMatchesForPlayerViewSortByTournament(): any {
    const teamData = this.areaMatchesForPlayerView;
    const matchesByTournaments = [];
    this.tournamentNames.forEach(tournamentName => {
      const matchesByTournament = { tournamentName, data: [] };
      teamData.forEach(teams => {
        if (teams[0].data[0].tournamentName === tournamentName) {
          matchesByTournament.data.push(teams);
        }
      });
      matchesByTournaments.push(matchesByTournament);
    });
    return matchesByTournaments;
  }

  get areaMatchesForPlayerView(): any {
    const data = this.getValue().areaMatches;
    if (!data[0]) {
      return;
    }
    const reducedTeamsData = [];
    const flags = [];
    const reducedEvens: any = [];
    const l = data[0].items.length;
    let i: number;
    for (i = 0; i < l; i++) {
      if (flags[data[0].items[i].extParentItemID]) {
        continue;
      }
      flags[data[0].items[i].extParentItemID] = true;
      reducedEvens.push({ extParentItemID: data[0].items[i].extParentItemID, data: [] });
    }
    data[0].items.forEach(element => {
      reducedEvens.forEach(event => {
        if (element.extParentItemID === event.extParentItemID) {
          event.data.push(element);
        }
      });
    });

    reducedEvens.forEach((eventData, index) => {
      eventData.data.forEach(innerEventData => {
        const flags2 = [];
        const reducedEvens2: any = [];
        const l2 = eventData.data.length;
        let i2: number;
        for (i2 = 0; i2 < l2; i2++) {
          if (flags2[eventData.data[i2].extParentTeamID]) {
            continue;
          }
          flags2[eventData.data[i2].extParentTeamID] = true;
          reducedEvens2.push({
            extParentTeamID: eventData.data[i2].extParentTeamID,
            data: [],
            tournamentName: eventData.data[i2].tournamentName
          });
        }
        reducedTeamsData[index] = [].concat(reducedEvens2);
      });
    });

    reducedEvens.forEach((eventData2, index) => {
      eventData2.data.forEach(innerEventData => {
        reducedTeamsData.forEach(id => {
          id.forEach((elem, index2) => {
            if (elem.extParentTeamID === innerEventData.extParentTeamID) {
              reducedTeamsData[index][index2].data.push(innerEventData);
            }
          });
        });
      });
    });

    reducedTeamsData.forEach(teamData => {
      let allTypeOfOdds = [];
      teamData.forEach(matchData => {
        matchData.data.forEach(oddsData => {
          if (oddsData.odds.length > allTypeOfOdds.length) {
            allTypeOfOdds = oddsData.odds;
          }
        });
      });
      teamData.push(allTypeOfOdds);
    });
    const reorderedTeamData = [];
    reducedTeamsData.forEach(teams => {
      let homeTeam;
      let awayTeam;
      const odds = [...teams[teams.length - 1]];
      if (teams[0].data[0].extTeamOrder === 2) {
        awayTeam = { ...teams[0] };
        homeTeam = { ...teams[1] };
      }
      if (teams[0].data[0].extTeamOrder === 1) {
        awayTeam = { ...teams[1] };
        homeTeam = { ...teams[0] };
      }
      const newTeamOrder = [homeTeam, awayTeam, odds];
      reorderedTeamData.push(newTeamOrder);
    });

    return reorderedTeamData;
  }

  get areaMatches(): AreaMatchModel[] {
    return this.getValue().areaMatches;
  }
  get isItMultiline(): boolean {
    return this.getValue().isItMultiline;
  }
  get isItCorrectScores(): boolean {
    return this.getValue().isItCorrectScores;
  }

  get selectedTournamentName(): string {
    if (this.getValue().selectedTournamentName === 'All') {
      return translate('All');
    }

    return this.getValue().selectedTournamentName;
  }

  get selectedDay(): DayModel {
    return this.getValue().selectedDay;
  }

  get selectedArea(): AreaModel {
    return this.getValue().selectedArea;
  }

  get selectedSport(): SportModel {
    return this.getValue().selectedSport;
  }

  get selectedMarket(): AreaMarketModel {
    return this.getValue().selectedMarket;
  }

  get tournamentNames(): string[] {
    return this.getValue().tournamentNames;
  }

  get isEventsLoading(): boolean {
    return this.getValue().ui.isEventsLoading;
  }

  get firstTimeLoadIsInProgress(): boolean {
    return this.getValue().ui.firstTimeLoadIsInProgress;
  }

  applySearchFilter(): void {
    this.filterMatchesByTournament();
    this.filterMatches();
  }

  filterMatchesByTournament(): void {
    const filtered: any[] = this.activeFilteredTournaments
      ?.filter(this.doesIncludeSearchTerm.bind(this))
      .filter(this.isTournamentSelected.bind(this))
      .map(this.tournamentWithFilteredMatchesBySearch.bind(this));

    this.filteredMatchesByTournament$.next(filtered);

    if (!this.selectedMatch$.value && filtered && filtered.length) {
      this.selectedMatch$.next(filtered[0].matches[0]);
    }
  }

  filterMatches(): void {
    const search = this.searchTerm.toLocaleLowerCase().trim();

    const events = this.activeFilteredEvents?.map(area => ({
      ...area,
      items: area.items
        .filter(match => match.name.toLocaleLowerCase().includes(search))
        .filter(match => this.isMatchInSelectedTournament(match))
    }));

    this.filteredAreaMatches$.next(events || [undefined]);
  }

  clearFilteredMatchesCache(): void {
    this.activeFilteredEvents = [];
    this.activeFilteredTournaments = [];
    this.filteredAreaMatches$.next([undefined]);
    this.filteredMatchesByTournament$.next([]);
  }

  private doesIncludeSearchTerm(tournament: MatchByTournamentModel): boolean {
    const search = this.searchTerm.toLocaleLowerCase().trim();

    if (!search) {
      return true;
    }

    return (
      tournament.tournamentName.toLocaleLowerCase().includes(search) ||
      !!tournament.matches.find(event => event.name.toLocaleLowerCase().includes(search))
    );
  }

  private tournamentWithFilteredMatchesBySearch(tournament: MatchByTournamentModel): MatchByTournamentModel {
    const search = this.searchTerm.toLocaleLowerCase().trim();

    if (!search) {
      return tournament;
    }

    return {
      ...tournament,
      matches: tournament.matches.filter(match => match.name.toLocaleLowerCase().includes(search))
    };
  }

  private isTournamentSelected(tournament: MatchByTournamentModel): boolean {
    if (!tournament.matches.length) {
      return false;
    }

    const tournamentId = tournament.matches[0].tournamentId;
    const categoryId = tournament.matches[0].categoryId;

    const categoryIdx = this.categoryTournamentsFilter$.value.findIndex(filter => filter.category.id === categoryId);
    if (categoryIdx > -1) {
      const category = this.categoryTournamentsFilter$.value[categoryIdx];
      const areAllTournamentsDeselected = this.categoryTournamentsFilter$.value.every(filter => filter.selectedTournaments.length === 0);
      return areAllTournamentsDeselected || category.selectedTournaments.includes(tournamentId);
    } else {
      return true;
    }
  }

  private isMatchInSelectedTournament(match: MatchModel): boolean {
    const tournamentId = match.tournamentId;
    const categoryId = match.categoryId;

    const categoryIdx = this.categoryTournamentsFilter$.value.findIndex(filter => filter.category.id === categoryId);
    if (categoryIdx > -1) {
      const category = this.categoryTournamentsFilter$.value[categoryIdx];
      const areAllTournamentsDeselected = this.categoryTournamentsFilter$.value.every(filter => filter.selectedTournaments.length === 0);
      return areAllTournamentsDeselected || category.selectedTournaments.includes(tournamentId);
    } else {
      return true;
    }
  }
}
