import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CouponPageWrapperComponent } from './components/coupon-page-wrapper/coupon-page-wrapper.component';

const routes: Routes = [
  {
    path: '',
    component: CouponPageWrapperComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CouponRoutingModule { }
