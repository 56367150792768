<div
  [ngClass]="{
    'collapsible-card': collapsible,
    collapsed: collapsed,
    'min-full-height': minFullHeight,
    'with-background': withBackground,
    flat: flat
  }"
  class="card"
>
  <div class="title-header" *ngIf="titleHeaderText" (click)="toggleWidget()" [class.with-background]="withBackground">
    <div class="title-details">
      <div *ngIf="leftSportIconId !== undefined" class="sport-icon icon-{{ leftSportIconId }} brand"></div>
      <h1 *ngIf="!contentReference.childNodes.length" class="ellipsis">{{ titleHeaderText }}</h1>
      <div #contentReference>
        <ng-content select="[header]"></ng-content>
      </div>
    </div>
    <svg
      [appDropdownArrowRotator]="!collapsed"
      [class.hidden]="!canCollapse"
      fill="none"
      height="12"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
      *ngIf="!withBackground"
    >
      <path
        d="M1.005 2.414l3.88 3.88-3.88 3.88a.996.996 0 101.41 1.41l4.59-4.59a.996.996 0 000-1.41L2.415.994a.996.996 0 00-1.41 0c-.38.39-.39 1.03 0 1.42z"
        fill="#41ADB4"
      />
    </svg>
    <svg
      *ngIf="withBackground"
      [appDropdownArrowRotator]="!collapsed"
      [class.hidden]="!canCollapse"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.51844 11.5261C4.34457 11.3556 4.32877 11.0889 4.47102 10.9009L4.51844 10.8471L8.44283 7.00002L4.51844 3.15295C4.34457 2.9825 4.32877 2.71578 4.47102 2.52782L4.51844 2.47397C4.69231 2.30352 4.96438 2.28803 5.15611 2.42749L5.21104 2.47397L9.48156 6.66053C9.65543 6.83098 9.67123 7.09771 9.52898 7.28566L9.48156 7.33951L5.21104 11.5261C5.01978 11.7136 4.7097 11.7136 4.51844 11.5261Z"
        fill="#6B7882"
        stroke="#6B7882"
        stroke-width="0.6"
      />
    </svg>
  </div>
  <div class="card-content" [@expandCollapse]="collapsed ? 'collapsed' : 'expanded'">
    <div class="content" *ngIf="!loading">
      <ng-content select="[body]"></ng-content>
    </div>
  </div>
  <app-loading-bar [show]="loading"></app-loading-bar>
</div>
