import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  appInsights: ApplicationInsights;
  isInitialized: boolean = false;

  constructor(private readonly appConfig: AppConfigService, @Inject(PLATFORM_ID) private readonly platform: Object) {}

  initialize(): void {
    const appInsightsConfig: any = this.appConfig.get('appInsights');
    if (appInsightsConfig && appInsightsConfig.enabled && appInsightsConfig.instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appInsightsConfig.instrumentationKey,
          samplingPercentage: appInsightsConfig.samplingPercentage,
          loggingLevelTelemetry: 1,
          autoTrackPageVisitTime: true,
          enableAutoRouteTracking: true
        }
      });
      this.appInsights.loadAppInsights();
      this.isInitialized = true;
    }
  }
}
