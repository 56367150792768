import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { ThirdPartyState, UploadedDetailsModel } from 'src/app/shared/models/third-party.model';
import { ThirdPartyStore } from './third-party.store';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyQuery extends Query<ThirdPartyState> {
  // Data
  uploadFile$ = this.select(s => s.uploadFile);
  deleteFile$ = this.select(s => s.deleteFile);
  submitFiles$ = this.select(s => s.submitFiles);

  loading$ = this.selectLoading();

  constructor(protected store: ThirdPartyStore) {
    super(store);
  }

  get uploadFile(): UploadedDetailsModel {
    return this.getValue().uploadFile;
  }

  get deleteFile(): any {
    return this.getValue().deleteFile;
  }

  get submitFiles(): any {
    return this.getValue().submitFiles;
  }
}
