<div class="event">
  <ng-container *ngIf="showDetails">
    <div class="top">
      <app-event-header [event]="event" [sportId]="sportId" *ngIf="sportId">
      </app-event-header>
      <app-event-details [event]="event" *ngIf="showDetails" [showDate]="showDate" (click)="navigateToMatch()" analytics
        [click]='true' [category]="'Match Details'">
      </app-event-details>
    </div>
    <div
      [@expandCollapse]="{ value: (statisticsQuery.expandedStatistics$ | async) ? 'expanded' : 'collapsed', params: { minHeight: '0px' }}">
      <app-statistics *ngIf="event.externalId === (statisticsQuery.selectedExternalId$ | async)"
        [externalId]="event.externalId"></app-statistics>
    </div>
  </ng-container>
  <app-correct-scores-market-odds [odd]="event.correctScoreOdds" [padding]="showDetails" *ngIf="isAvailable">
  </app-correct-scores-market-odds>
  <app-not-available-message *ngIf="!isAvailable"></app-not-available-message>
</div>