import { Injectable } from '@angular/core';
import { isNull, kebabCase } from 'lodash-es';
import { ResolverQuery } from 'src/app/core/state/resolver/resolver.query';
import { ResolverStore } from 'src/app/core/state/resolver/resolver.store';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { ResolvedModel, ResolverState } from 'src/app/shared/models/resolver.model';
import { EventType } from './../../shared/models/sport.model';

@Injectable({
  providedIn: 'root'
})
export class ResolverService {
  constructor(
    private readonly resolverStore: ResolverStore,
    private readonly resolverQuery: ResolverQuery,
    private readonly sportQuery: SportQuery
  ) {}

  buildCache(prematchData: any, outrightData: any, specialData: any, goalscorerData: any): void {
    const nameCache = {
      prematch: {
        sports: {}
      },
      special: {
        sports: {}
      },
      outright: {
        sports: {}
      },
      goalscorer: {
        sports: {}
      }
    };

    const idCache = {
      prematch: {
        sports: {}
      },
      special: {
        sports: {}
      },
      outright: {
        sports: {}
      },
      goalscorer: {
        sports: {}
      }
    };

    prematchData.forEach(sport => {
      nameCache.prematch.sports[kebabCase(sport.name)] = {
        id: sport.id,
        categories: {}
      };
      sport.categories.forEach(category => {
        nameCache.prematch.sports[kebabCase(sport.name)].categories[kebabCase(category.name)] = {
          id: category.id,
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          nameCache.prematch.sports[kebabCase(sport.name)].categories[kebabCase(category.name)].tournaments[kebabCase(tournament.name)] = {
            id: tournament.id,
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId
          };
        });
      });
    });
    prematchData.forEach(sport => {
      idCache.prematch.sports[sport.id] = {
        name: kebabCase(sport.name),
        categories: {}
      };
      sport.categories.forEach(category => {
        idCache.prematch.sports[sport.id].categories[category.id] = {
          name: kebabCase(category.name),
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          idCache.prematch.sports[sport.id].categories[category.id].tournaments[tournament.id] = {
            name: kebabCase(tournament.name),
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId
          };
        });
      });
    });

    specialData.forEach(sport => {
      nameCache.special.sports[kebabCase(sport.name)] = {
        id: sport.menuUniqueId,
        categories: {}
      };
      sport.categories.forEach(category => {
        nameCache.special.sports[kebabCase(sport.name)].categories[kebabCase(category.name)] = {
          id: category.id,
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          nameCache.special.sports[kebabCase(sport.name)].categories[kebabCase(category.name)].tournaments[kebabCase(tournament.name)] = {
            id: tournament.id,
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId
          };
        });
      });
    });
    specialData.forEach(sport => {
      idCache.special.sports[sport.menuUniqueId] = {
        name: kebabCase(sport.name),
        categories: {}
      };
      sport.categories.forEach(category => {
        idCache.special.sports[sport.menuUniqueId].categories[category.id] = {
          name: kebabCase(category.name),
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          idCache.special.sports[sport.menuUniqueId].categories[category.id].tournaments[tournament.id] = {
            name: kebabCase(tournament.name),
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId
          };
        });
      });
    });

    goalscorerData.forEach(sport => {
      nameCache.goalscorer.sports[kebabCase(sport.name)] = {
        id: sport.menuUniqueId,
        categories: {}
      };
      sport.categories.forEach(category => {
        nameCache.goalscorer.sports[kebabCase(sport.name)].categories[kebabCase(category.name)] = {
          id: category.id,
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          nameCache.goalscorer.sports[kebabCase(sport.name)].categories[kebabCase(category.name)].tournaments[kebabCase(tournament.name)] =
            {
              id: tournament.id,
              initRegionId: tournament.initRegionId,
              initAreaId: tournament.initAreaId
            };
        });
      });
    });
    goalscorerData.forEach(sport => {
      idCache.goalscorer.sports[sport.menuUniqueId] = {
        name: kebabCase(sport.name),
        categories: {}
      };
      sport.categories.forEach(category => {
        idCache.goalscorer.sports[sport.menuUniqueId].categories[category.id] = {
          name: kebabCase(category.name),
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          idCache.goalscorer.sports[sport.menuUniqueId].categories[category.id].tournaments[tournament.id] = {
            name: kebabCase(tournament.name),
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId
          };
        });
      });
    });

    outrightData.forEach(sport => {
      nameCache.outright.sports[kebabCase(sport.name)] = {
        id: sport.id,
        categories: {}
      };

      sport.categories.forEach(category => {
        nameCache.outright.sports[kebabCase(sport.name)].categories[kebabCase(category.name)] = {
          id: category.id,
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          nameCache.outright.sports[kebabCase(sport.name)].categories[kebabCase(category.name)].tournaments[kebabCase(tournament.name)] = {
            id: tournament.id,
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId
          };
        });
      });
    });

    outrightData.forEach(sport => {
      idCache.outright.sports[sport.id] = {
        name: kebabCase(sport.name),
        categories: {}
      };

      sport.categories.forEach(category => {
        idCache.outright.sports[sport.id].categories[category.id] = {
          name: kebabCase(category.name),
          tournaments: {}
        };

        category.tournaments.forEach(tournament => {
          idCache.outright.sports[sport.id].categories[category.id].tournaments[tournament.id] = {
            name: kebabCase(tournament.name),
            initRegionId: tournament.initRegionId,
            initAreaId: tournament.initAreaId
          };
        });
      });
    });

    const newResolverState = new ResolverState({ idCache, nameCache });

    this.resolverStore.updateCaches(newResolverState);
  }

  convertToIds(data: any): ResolvedModel {
    let type = data.eventType;
    if (data.eventType === 'special' && data.sport === 'goal-scorer') {
      type = 'goalscorer';
    }
    if (!this.resolverQuery.nameCache) {
      return;
    }

    const sport = this.resolverQuery.nameCache[type].sports[data.sport];

    if (!sport) {
      return;
    }

    const category = sport.categories[data.category];
    if (!category) {
      return;
    }

    const tournament = category.tournaments[data.tournament];
    if (!tournament) {
      return;
    }

    const resolverIds = new ResolvedModel({
      sport: sport.id,
      category: category.id,
      tournament: tournament.id,
      initRegionId: tournament.initRegionId,
      initAreaId: tournament.initAreaId
    });

    return resolverIds;
  }

  convertToNames(data: any, eventType: EventType = 'prematch'): ResolvedModel {
    let type = eventType;
    if (eventType === 'special' && data.sportId === '2_0') {
      type = 'goalscorer';
    }

    if (!this.resolverQuery.idCache) {
      return;
    }

    const sport = this.resolverQuery.idCache[type].sports[data.sportId];
    if (!sport) {
      return;
    }

    const category = sport.categories[data.categoryId];
    if (!category) {
      return;
    }

    const tournament = category.tournaments[data.tournamentId];
    if (!tournament) {
      return;
    }

    const resolvedNames = new ResolvedModel({
      sport: sport.name,
      category: category.name,
      tournament: tournament.name,
      initRegionId: tournament.initRegionId,
      initAreaId: tournament.initAreaId
    });

    return resolvedNames;
  }

  bruteForceResolveByTournamentId(tournamentId: number, eventType: EventType = 'prematch', menuUniqueId: string = ''): any {
    let type = eventType;
    if (eventType === 'special' && this.sportQuery.selectedQuicklink.id === 7) {
      type = 'goalscorer';
    }

    const keys = Object.keys(this.resolverQuery.idCache[type].sports);

    for (const key of keys) {
      if (key === menuUniqueId || isNull(menuUniqueId) || menuUniqueId === '') {
        const sport = this.resolverQuery.idCache[type].sports[key];
        const categoryKeys = Object.keys(sport.categories);

        for (const categoryKey of categoryKeys) {
          const category = this.resolverQuery.idCache[type].sports[key].categories[categoryKey];
          const tournamentKeys = Object.keys(category.tournaments);

          for (const tournamentKey of tournamentKeys) {
            if (tournamentKey === tournamentId.toString()) {
              return {
                sportId: key,
                categoryId: categoryKey,
                tournamentId: tournamentKey
              };
            }
          }
        }
      }
    }
  }
}
