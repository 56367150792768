import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { ResolverStore } from 'src/app/core/state/resolver/resolver.store';
import { IdCacheModel, NameCacheModel, ResolverState } from 'src/app/shared/models/resolver.model';

@Injectable({
  providedIn: 'root'
})
export class ResolverQuery extends Query<ResolverState> {
  nameCache$ = this.select(state => state.nameCache);
  idCache$ = this.select(state => state.idCache);

  constructor(protected store: ResolverStore) {
    super(store);
  }

  get nameCache(): NameCacheModel {
    return this.getValue().nameCache;
  }

  get idCache(): IdCacheModel {
    return this.getValue().idCache;
  }
}
