import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { RedirectService } from 'src/app/core/services/redirect.service';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly loggerService: LoggerService,
    private readonly accountService: AccountService,
    private readonly redirectService: RedirectService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 401) {
          this.logout();
          return;
        } else if (error.status !== 403 && error.status !== 418) {
          // If the server error status response means forbidden or tea pot, we don't need to log that event
          this.loggerService.logEvent(error.name, error.message, SeverityLevel.Error, LogTypeModel.Network);
        }

        return throwError(error);
      })
    );
  }

  private logout(): void {
    this.accountService
      .logout()
      .pipe(
        first(),
        finalize(() => {
          // this.couponService.clearCoupon(); // TODO
          this.redirectService.handleUnauthorized();
          this.router.navigate(['/']);
        })
      )
      .subscribe(undefined, () => {
        // if an error occurs while logging out, manually clear the
        // user data
        this.accountService.clearUserData();
      });
  }
}
