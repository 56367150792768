import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguagePageComponent {
}
