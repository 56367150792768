<div [class.card]="card" [class.with-results]="endResult$ | async" class="match">
  <div class="match-top-header" (click)="openLiveMatch()">
    <!--
        *****
        *****   Sport icon
        *****
    -->
    <div class="sport-icon icon-{{ match.sportId }}"></div>

    <!--
        *****
        *****   Match details
        *****
    -->
    <div class="details">
      <!--
            ***
            ***     Date / Live status
            ***
        -->
      <div class="middle-row">
        <h6 [class.running]="isLive$ | async" class="date-live">
          <!--  Not live - display event date  -->
          <ng-container *ngIf="!(isLive$ | async) && !(isMatchToday$ | async)"
            >{{ match.eventDate | formatDate : 'dd MMM' }},
            <span class="time-bold">{{ match.eventDate | formatDate : 'HH:mm' }}</span></ng-container
          >
          <ng-container *ngIf="!(isLive$ | async) && (isMatchToday$ | async)"
            ><span transloco="Today"></span>, <span class="time-bold">{{ match.eventDate | formatDate : 'HH:mm' }}</span></ng-container
          >

          <!--  Live with live match available - display match period and score  -->
          <ng-container *ngIf="(isLive$ | async) && (hasLiveMatch$ | async)">
            <span (click)="openLiveMatch()" *ngIf="matchStatusLabel$ | async">{{ matchStatusLabel$ | async }} </span>
            <span (click)="openLiveMatch()" *ngIf="liveScore$ | async">({{ liveScore$ | async }})</span>
          </ng-container>

          <!--  Live without live match available - display event date and '(Live)'  -->
          <ng-container *ngIf="(isLive$ | async) && !(hasLiveMatch$ | async)">
            <span>{{ match.eventDate | formatDate : 'HH:mm' }} </span>
            (<span transloco="Live"></span>)
          </ng-container>

          <ng-container *ngIf="!(isLive$ | async) && !isEventPrematch"> (<span transloco="Live"></span>)</ng-container>
        </h6>
        <div class="outcome">
          <h5 *ngIf="isWon" class="status won" transloco="Won"></h5>
          <h5 *ngIf="isLost" class="status lost" transloco="Lost"></h5>
          <h5 *ngIf="isCancelled" class="status cancelled" transloco="Cancelled"></h5>
        </div>
      </div>
    </div>
  </div>

  <!--
            ***
            ***     Match name and status
            ***
        -->
  <div [class.with-status]="isWon || isLost || isCancelled" class="top-row" (click)="openLiveMatch()">
    <div class="event">
      <div class="event-name">
        <div>
          {{ (eventName$ | async)?.split(' - ')[0] }}
        </div>
        <div class="result">{{ this.homeResult$ | async }}</div>
      </div>
      <div class="event-name">
        <div>
          {{ (eventName$ | async)?.split(' - ')[1] }}
        </div>
        <div class="result">{{ this.awayResult$ | async }}</div>
      </div>
    </div>

    <!-- Status -->
    <!-- <div class="outcome">
            <h5 *ngIf="isWon" class="status won" transloco="Won"></h5>
            <h5 *ngIf="isLost" class="status lost" transloco="Lost"></h5>
            <h5 *ngIf="isCancelled" class="status cancelled" transloco="Cancelled"></h5>
            <span *ngIf="(endResult$ | async) && !(liveScore$ | async)" class="end-result">({{ endResult$ | async }})</span>
          </div> -->
  </div>

  <!--
                ***
                ***   Market and selection
                ***
            -->
  <div class="market-selection">
    <h6 class="selection">
      <span class="market-name truncate" [class.too-long-text]="isMarketNameTooLong(match.marketName)">
        {{ match.marketName }}
      </span>
    </h6>
    <h6 class="selection odd">
      <span
        class="selection-value"
        [class.too-long-text]="
          isMarketNameTooLong(match.selectionName + (match.spreadValue && !hideSpread(match) ? '(' + match.spreadValue + ')' : ''))
        "
        >{{ match.selectionName + (match.spreadValue && !hideSpread(match) ? '(' + match.spreadValue + ')' : '') }}</span
      >
      <span>
        {{ match.oddValue.toFixed(2) }}
      </span>
    </h6>
  </div>

  <!--
                ***
                ***   Card results
                **
                 *
            -->
  <div *ngIf="card && (endResult$ | async)" class="card-results">
    <div *ngFor="let result of match.results | keyvalue" class="result">
      <div class="title">{{ result.value[0].family }}</div>
      <div class="value">{{ result.value[0].value }}-{{ result.value[1].value }}</div>
    </div>
  </div>
</div>
