import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { ApplicationService } from '../../../core/services/application.service';
import { ChatPopupService } from '../../../core/services/chat-popup.service';
import { ChatPopupQuery } from '../../../core/state/chat-popup/chat-popup.query';

@Component({
  selector: 'app-chat-popup',
  templateUrl: './chat-popup.component.html',
  styleUrls: ['./chat-popup.component.scss']
})
export class ChatPopupComponent implements OnInit {
  closing$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    public chatPopupQuery: ChatPopupQuery,
    private chatPopupService: ChatPopupService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.closeOnRouteChange();
    this.chatPopupService.getPopupLabel().subscribe();
  }

  close(): void {
    this.closing$.next(true);

    setTimeout(() => this.chatPopupService.close(), 1000);
  }

  openChat() {}

  private closeOnRouteChange(): void {
    this.router.events.pipe(first()).subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.close();
      }
    });
  }
}
