<ng-container>
  <app-breadcrumb [title]="'Deposit' | transloco" faIcon="fa-money">
  </app-breadcrumb>


  <div class="deposit-wrapper success" [@fadeIn]>
    <div class="success-icon"><i class="fa fa-check-circle"></i></div>

    <app-card [titleHeaderText]="'Deposit Successful!' | transloco">
      <div body>

        <div *ngIf="showExtraDetails">
          <div class="user-labels-container border-btm-close">
            <div class="title" [innerHTML]="extraDetailsTitle"></div>
            <div class="body">{{extraDetailsBody}}</div>
          </div>
        </div>

        <div class="btn-container">
          <button class="btn return" (click)="returnDepositList()">
            <span class="btn-text" transloco="Perform another Deposit"></span>
          </button>
          <button class="btn return" (click)="returnSportsPage()">
            <span class="btn-text" transloco="Return to Home Page"></span>
          </button>
        </div>
      </div>
    </app-card>
  </div>
</ng-container>
