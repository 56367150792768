import { Injectable } from '@angular/core';

import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CashoutModel, CashoutState } from 'src/app/shared/models/cashout.model';
import { CashoutStore } from './cashout.store';

@Injectable({ providedIn: 'root' })
export class CashoutQuery extends QueryEntity<CashoutState, CashoutModel> {
  pendingCashoutList$ = this.select(state => state.pendingCashoutList);

  constructor(protected store: CashoutStore) {
    super(store);
  }

  getCashout(code: string): Observable<CashoutModel> {
    return this.selectAll({
      filterBy: e => e.couponCode === code
    }).pipe(map(cashouts => cashouts[0]));
  }

  pendingCashoutList(): CashoutModel[] {
    return this.getValue().pendingCashoutList;
  }

  isInPendingList(cashoutId: number, couponCode: string): boolean {
    // supports checking by either the cashoutId or the couponCode
    const isInPending = this.pendingCashoutList().find(
      i => (cashoutId != undefined && i.cashoutId === cashoutId) || (couponCode != undefined && i.couponCode === couponCode)
    );

    return isInPending !== undefined;
  }
}
