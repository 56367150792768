import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from 'src/app/core/services/account/account.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import { translate } from '@ngneat/transloco';
import { ApplicationService } from 'src/app/core/services/application.service';
import { HelpService } from 'src/app/core/services/help.service';
import { ThirdPartyService } from 'src/app/core/services/third-party.service';
import { ThirdPartyQuery } from 'src/app/core/state/third-party/third-party.query';
import { UserModel } from 'src/app/shared/models/account.model';
import { ButtonType } from 'src/app/shared/models/button.model';
import { UploadFileModel } from 'src/app/shared/models/third-party.model';

import { FormatBytesPipe } from 'src/app/shared/pipes/format-bytes.pipe';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit, OnDestroy {
  @Input() page: string;

  inputValueLimits: any = {
    allowedFiles: [ 'png', 'jpeg', 'gif', 'jpg', 'pdf' ],
    minFiles: 1,
    maxFiles: 2,
    maxSize: 10000000 // 10mb
  }

  userData: UserModel = this.accountQuery.userData;

  uploadFiles$ = new BehaviorSubject<UploadFileModel[]>([]);
  internalUploadFiles: UploadFileModel[] = [];

  errorMessage$ = new BehaviorSubject<string>(undefined);
  showLoader$ = new BehaviorSubject<boolean>(false);
  uploadInProgress$ = new BehaviorSubject<boolean>(false);

  buttonType = ButtonType;
  formatBytesPipe: FormatBytesPipe;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly thirdPartyQuery: ThirdPartyQuery,
    readonly accountService: AccountService,
    readonly applicationService: ApplicationService,
    readonly helpService: HelpService,
    private readonly thirdPartyService: ThirdPartyService,
    private readonly notificationService: NotificationService
  ) {
    this.formatBytesPipe = new FormatBytesPipe();
  }

  ngOnInit(): void {
    if(this.page === 'withdrawal') {
      this.inputValueLimits.minFiles = 2;
    }
  }

  uploadFile(event: any): void {
    const files: FileList = event.target.files;

    this.errorMessage$.next(undefined);

    // retrieve the uploaded file
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);

          if (this.internalUploadFiles.length < this.inputValueLimits.maxFiles) {
            if (this.performChecks(file)) {
              // save local file details and prepare for the uploaded call details
              let fileId: number;
              if (this.internalUploadFiles.length === 0) {
                  fileId = 1;
              } else {
                  fileId = this.internalUploadFiles[this.internalUploadFiles.length - 1].id + 1;
              }

              this.internalUploadFiles.push({
                  id: fileId,
                  localDetails: {
                      name: file.name,
                      size: file.size,
                      type: file.type && file.type.indexOf('/') !== -1 ? file.type.substring(file.type.indexOf('/') + 1) : file.type
                  },
                  uploadedDetails: {
                    thumbnail: undefined,
                    token: undefined
                  },
                  uploadLoader: true,
                  progressInterval: undefined,
                  progressBar: 0,
                  deleteLoader: false
              });

              this.uploadFiles$.next(this.internalUploadFiles);

              const currIndex = this.internalUploadFiles.findIndex(u => u.id === fileId);

              this.internalUploadFiles[currIndex].progressInterval = window.setInterval(() => {
                if (this.internalUploadFiles[currIndex].progressBar >= 80) {
                  clearInterval(this.internalUploadFiles[currIndex].progressInterval);
                }
                  // update by 5/90ths
                  const updateAmount = (10 / 90) * 100;
                  this.internalUploadFiles[currIndex].progressBar += updateAmount;

                  this.uploadFiles$.next(this.internalUploadFiles);
              }, 500);

              this.uploadInProgress$.next(true);

              this.thirdPartyService.uploadFile(file)
                .pipe(
                  first(),
                  finalize(() => {
                    // set progress bar to 100% and remove spinners
                    this.internalUploadFiles[currIndex].uploadLoader = false;

                    clearInterval(this.internalUploadFiles[currIndex].progressInterval);
                    this.internalUploadFiles[currIndex].progressBar = 100;

                    this.uploadFiles$.next(this.internalUploadFiles);

                    event.target.value = '';

                    if(!this.checkOtherFilesInProgress(fileId)) {
                      this.uploadInProgress$.next(false);
                    }
                  })
                )
                .subscribe(
                  () => {
                    // fill uplaoded details
                    this.internalUploadFiles[currIndex].uploadedDetails = {
                      thumbnail: this.thirdPartyQuery.uploadFile.thumbnail,
                      token: this.thirdPartyQuery.uploadFile.token
                    };

                    this.uploadFiles$.next(this.internalUploadFiles);
                  },
                  err => {
                    clearInterval(this.internalUploadFiles[currIndex].progressInterval);

                    // remove local image
                    this.internalUploadFiles.splice(currIndex, 1);

                    this.uploadFiles$.next(this.internalUploadFiles);

                    this.errorMessage$.next(translate('File upload was not successful. Please try again.'));
                  }
                );
          }
        } else {
          event.target.value = '';

          this.errorMessage$.next(
            translate('Maximum number of files that can be submitted is {{maxFiles}}', {
              maxFiles: this.inputValueLimits.maxFiles.toString()
            })
          );
        }
      }
    } else {
      event.target.value = '';

      this.errorMessage$.next(translate('Please attach the files to submit the documents.'));
    }
  }

  performChecks(file: File): boolean {
    if (!new RegExp(this.inputValueLimits.allowedFiles.join("|")).test(file.type)) {
      this.errorMessage$.next(
        translate('Please attach the file with the supported format: {{allowedFiles}}', {
          allowedFiles: this.inputValueLimits.allowedFiles.join(", ")
        })
      );
      return false;
    } else if (file.size > this.inputValueLimits.maxSize) {
      this.errorMessage$.next(
        translate('Please attach files of the size lower than {{maxSize}}', {
          maxSize: this.formatBytesPipe.transform(this.inputValueLimits.maxSize)
        })
      );
      return false;
    }
    return true;
  }

  checkOtherFilesInProgress(fileId: number): boolean {
    const otherFilesInProgress = this.internalUploadFiles.find(u => u.id !== fileId && u.uploadLoader);
    return otherFilesInProgress !== undefined; // if no files found, means that there is no other files in Progress
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.thirdPartyService.clearOnDestroy();
  }

  indexTrackBy(index: string): string {
    return index;
  }

  submitFiles(): void {
    if (this.uploadInProgress$.value) {
      return;
    }

    if (this.internalUploadFiles.length > 0) {
      if (this.internalUploadFiles.length >= this.inputValueLimits.minFiles) {
        const uploadTokens = [];

        this.internalUploadFiles.forEach((file) => {
          if (file.uploadedDetails) {
            uploadTokens.push(file.uploadedDetails.token);
          }
        });

        let emailType = 'Registracija';
        if(this.page === 'withdrawal') {
          emailType = 'Podaci za isplatu';
        }

        const bodyData = {
            request: {
                requester: {
                    name: this.userData.email
                },
                subject: `Korisnički ID: ${this.userData.id}`,
                comment: {
                    body: emailType,
                    uploads: uploadTokens
                }
            }
        };

        this.showLoader$.next(true);
        this.uploadInProgress$.next(true);
        this.thirdPartyService.submitFiles(bodyData)
          .pipe(
            first(),
            finalize(() => {
              this.showLoader$.next(false);
              this.uploadInProgress$.next(false);
            })
          )
          .subscribe(
            () => {
              // clear local images and error messages
              this.internalUploadFiles = [];
              this.uploadFiles$.next(this.internalUploadFiles);

              this.errorMessage$.next(undefined);

              // show success message
              this.notificationService.showSuccessMessage(translate('Your files are successfully sent!'));
            },
            err => {
              // show error message
              this.notificationService.showErrorMessage(translate('Your files could not be sent! Please try again.'));
            }
          );
        } else {
          this.errorMessage$.next(
            translate('Upload failed: Minimum files required is {{minFiles}}', {
              minFiles: this.inputValueLimits.minFiles
            })
          );
        }
    } else {
      this.errorMessage$.next(translate('Please attach the files to submit the documents.'));
    }
  }

  deleteFile(id:number): void {
    if (this.uploadInProgress$.value) {
      return;
    }

    // find file
    const currIndex = this.internalUploadFiles.findIndex(u => u.id === id);

    // // get token by file id
    // const token = this.internalUploadFiles[currIndex].uploadedDetails.token;

    // // start deleteLoader spinner
    // this.internalUploadFiles[currIndex].deleteLoader = true;
    // this.uploadFiles$.next(this.internalUploadFiles);

    // this.uploadInProgress$.next(true);

    // this.thirdPartyService.deleteFile(token)
    //   .pipe(
    //     first(),
    //     finalize(() => {
    //       // end deleteLoader spinner
    //       this.internalUploadFiles[currIndex].deleteLoader = false;

    //       this.uploadFiles$.next(this.internalUploadFiles);
    //       this.uploadInProgress$.next(false);
    //     })
    //   )
    //   .subscribe(
    //     () => {
    //       // remove local image
    //       this.internalUploadFiles.splice(currIndex, 1);
    //       this.uploadFiles$.next(this.internalUploadFiles);
    //     }
    //   );

    // remove local image
    this.internalUploadFiles.splice(currIndex, 1);
    this.uploadFiles$.next(this.internalUploadFiles);
  }
}
