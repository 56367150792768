<div class="market">
  <div class="separator" *ngIf="separatorText"
    [class.last-minute]="(applicationQuery.activeUrl$ | async)[0] === 'last-minute'">
    {{ separatorText }}
  </div>
  <div class="markets" [class.separator]="separator">
    <div>
      <div *ngFor="let selection of markets; trackBy: marketTrackBy" class="market">
        {{ selection.name }}
      </div>
    </div>
  </div>
</div>
