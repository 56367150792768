import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

// tslint:disable: member-ordering
@Injectable({
  providedIn: 'root'
})
export class InsufficientBalanceService {
  private readonly checkBalanceSubject = new Subject<any>();
  private readonly showPopUpSubject = new Subject<any>();
  showPopUp$ = this.showPopUpSubject.asObservable();

  constructor(private readonly appService: AppConfigService, private readonly accountQuery: AccountQuery) {
    this.checkBalanceSubject.pipe(withLatestFrom(this.accountQuery.totalFunds$)).subscribe(([_, balance]) => {
      if (balance <= 0) {
        this.showPopUpSubject.next();
      }
    });
  }

  checkBalance(): void {
    this.checkBalanceSubject.next();
  }
}
