<div class="account-statement-search-container">
  <form [formGroup]="accountStatementSearchForm" class="accoutn-statement-search-form">
    <div class="form-item transfer-type">
      <label transloco="Transfer Type"></label>
      <div class="dropdown">
        <select formControlName="transferType">
          <option [value]="0" transloco='All'></option>
          <option *ngFor="let transferType of (accountQuery.transferTypes$ | async); trackBy: transferTypeTrackBy"
            [value]="transferType.id">
            {{ transferType.description }}
          </option>
        </select>
      </div>
    </div>

    <div class="date-group" [@expandCollapse]="showDateFilters ? 'expanded' : 'collapsed'">
      <div class="form-item date-from">
        <label transloco="Date From"></label>
        <input type="date" formControlName="dateFrom" [min]="dateFromMinLimit" [max]="dateFromMaxLimit"
          onkeydown="return false;">
      </div>

      <div class="form-item date-to">
        <label transloco="Date To"></label>
        <input type="date" formControlName="dateTo" [min]="accountStatementSearchForm.value.dateFrom"
          [max]="dateToLimit" onkeydown="return false;">
      </div>
    </div>

    <div class="radio-button-container">
      <app-form-item-group [title]="'Period' | transloco">
        <div class="form-item period">
          <div class="radio-item-group">
            <input type="radio" name="period" value="0" formControlName="period">
            <div class="radio-visual" (click)="toggleRadioButton($event)"></div>
            <span transloco="Manual"></span>
          </div>
          <div class="radio-item-group">
            <input type="radio" name="period" value="1" formControlName="period">
            <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
            <span transloco="Today"></span>
          </div>
          <div class="radio-item-group">
            <input type="radio" name="period" value="2" formControlName="period">
            <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
            <span transloco="48h"></span>
          </div>
        </div>
      </app-form-item-group>
    </div>
    <app-form-message [hidden]="(accountQuery.noStatements$ | async) === false"
      [text]="'No statements found!' | transloco">
    </app-form-message>
    <app-form-message
      [hidden]="(!((accountStatementSearchForm.value.dateFrom < dateFromMinLimit || accountStatementSearchForm.value.dateFrom > dateFromMaxLimit)
        || accountStatementSearchForm.value.dateTo > dateToLimit) || accountStatementSearchForm.value.dateTo < accountStatementSearchForm.value.dateFrom)"
      [text]="'Search range must be within 2 months and not after today' | transloco">
    </app-form-message>
    <app-form-message [hidden]="accountStatementSearchForm.value.dateTo >= accountStatementSearchForm.value.dateFrom"
      [text]="'Date From must be before Date To' | transloco">
    </app-form-message>
    <div class="buttons">
      <app-button [text]="'Reset' | transloco" buttonType="normal" (btnClick)="onReset()"
        [disabled]="!accountStatementSearchForm.dirty"
        [@fadeInOut]="accountStatementSearchForm.dirty ? 'visible' : 'hidden'">
      </app-button>
      <app-button [text]="'Proceed' | transloco" buttonType="success" (btnClick)="onSubmit()" [disabled]="
      (accountStatementSearchForm.value.dateFrom < dateFromMinLimit || accountStatementSearchForm.value.dateFrom > dateFromMaxLimit)
      || (accountStatementSearchForm.value.dateTo < accountStatementSearchForm.value.dateFrom
            || accountStatementSearchForm.value.dateTo > dateToLimit)
        " [loading]="accountQuery.loading$ | async">
      </app-button>
    </div>
  </form>
</div>