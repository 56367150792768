<div class="bonus-progress-container">
  <div class="start" transloco="0%">
  </div>
  <div class="progress-bar">
    <div class="bars">
      <div class="progress-bar-fill" [style.width]="(currentProgress/nextRelease)*100 + '%'">
      </div>
      <div class="progress-bar-empty" [style.width]="100 - (currentProgress/nextRelease)*100 + '%'">
      </div>
    </div>
    <div class="progress-bar-text">
      {{ (currentProgress/nextRelease) | percent }}
      ({{ currentProgress | currencyFormat }})
    </div>
  </div>
  <div class="end" transloco="100%">
  </div>
</div>
