import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'account-bonus-details',
  templateUrl: './bonus-details.component.html',
  styleUrls: ['./bonus-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BonusDetailsComponent {
  @Input() currentProgress: number = 0;
  @Input() nextRelease: number = 0;
  @Input() expirationDate: Date;
  @Input() status: 'active' | 'paused' | 'missed' | 'inactive' | 'previous' | undefined;
}
