import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private readonly apiService: APIService, private readonly appConfig: AppConfigService) { }

  getAccessToken(username: string, password: string, grantType: string = 'otp'): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('brandId', this.appConfig.get('brandId').toString())
      .set('grant_type', grantType)
      .toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true
    });

    return this.apiService
      .post<any>(APIType.Guardian, 'oauth/Token', body, apiSettings)
      .pipe(map(tokenData => (tokenData ? tokenData.access_token : undefined)));
  }

  revokeToken(accessToken: string): Observable<any> {
    const body = new HttpParams().set('token', accessToken).toString();

    const apiSettings: APISettings = new APISettings({
      contentType: 'application/x-www-form-urlencoded',
      noAuthToken: true
    });

    return this.apiService.post<any>(APIType.Guardian, 'auth/revokeToken', body, apiSettings);
  }
}
