import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';

import { AccountStatementService } from 'src/app/core/services/account/account-statement.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStatementModel } from 'src/app/shared/models/account.model';

@Component({
  selector: 'account-statement-results',
  templateUrl: './account-statement-results.component.html',
  styleUrls: ['./account-statement-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountStatementResultsComponent {
  constructor(
    public accountQuery: AccountQuery,
    private readonly accountStatementService: AccountStatementService,
    private readonly router: Router
  ) {}

  backPressed(): void {
    this.accountStatementService.clearStatements();
  }

  viewCouponPressed(couponCode: string): void {
    this.router.navigate([`/coupon-check/${couponCode}`]);
  }

  statementsTrackBy(item: AccountStatementModel): number {
    return item.id;
  }
}
