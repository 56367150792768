import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { ApplicationSiteMessagesState } from 'src/app/shared/models/application.model';
import {
  ToastNotification,
  ToastNotificationPosition,
  ToastNotificationType,
} from 'src/app/shared/models/notification.model';

import { APIType } from '../../shared/models/api.model';
import { Notification } from '../../shared/models/cms.model';

@Injectable({
  providedIn: 'root'
})
export class SiteMessageService {
  constructor(
    private readonly applicationStore: ApplicationStore,
    private readonly apiService: APIService,
    private readonly notificationService: NotificationService,
    private readonly router: Router
  ) {
  }

  getSiteMessages(section: 'general' | 'sports'): void {
    let api = '';
    switch (section) {
      case 'general':
        api = 'SiteRoot/GetSiteMessages';
        break;
      case 'sports':
        api = 'Sports/GetSiteMessages';
        break;
      default:
        break;
    }
    this.apiService.get(APIType.CMS, 'notifications')
      .pipe(first())
      .subscribe(res => {
        const update = new ApplicationSiteMessagesState();
        const toasts = [];
        const currentTime = new Date();
        const messages = res.filter(notification => {
          const fromTime = new Date(notification.Notification.start);
          const toTime = new Date(notification.Notification.end);
          return (currentTime.getTime() <= toTime.getTime() && currentTime.getTime() > fromTime.getTime())
            || !(notification.Notification.start && notification.Notification.end);
        });
        messages.forEach(notification => {
          toasts.push(this.mapDataToToast(notification.Notification));
        });
        update[section] = toasts;
        this.applicationStore.updateSiteMessages({
          ...update
        });
        messages.forEach(notification => {
          if (this.router.url === notification.Notification.route || !notification.Notification.route) {
            this.addSiteMessageToToastList(this.mapDataToToast(notification.Notification));
          }
        });
      });
  }

  mapDataToToast(notification: Notification): ToastNotification {
    return new ToastNotification({
      id: notification.id,
      message: notification.content,
      duration: notification.duration,
      priority: this.getPriority(notification.priority),
      position: this.getPosition(notification.position)
    });
  }

  getPosition(value: string): number {
    switch (value) {
      case 'top':
        return ToastNotificationPosition.Top;

      case 'bottom':
        return ToastNotificationPosition.Bottom;

      default:
        return ToastNotificationPosition.Bottom;
    }
  }

  getPriority(value: string): number {
    switch (value) {
      case 'info':
        return ToastNotificationType.Info;

      case 'warning':
        return ToastNotificationType.Warning;

      case 'critical':
        return ToastNotificationType.Critical;

      default:
        return ToastNotificationType.Info;
    }
  }

  addSiteMessageToToastList(siteMessage: ToastNotification): void {
    // this.notificationService.addToast(siteMessage);
  }
}
