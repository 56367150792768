<div class="totals-container">
  <div class="details-row" *ngIf="!bookedCoupon">
    <div class="content">
      <div class="label" transloco="Selections"></div>
      <div class="value">{{ couponData.Odds.length }}</div>
    </div>

    <div class="content coupon-settings" [class.full-width]="!showStakeReduction">
      <div class="setting">
        <div class="label" transloco="Allow odd changes"></div>
        <app-toggle-switch
          [switch]="(couponQuery.couponSettings$ | async).allowOddChanges"
          (click)="updateCouponSetting('allowOddChanges', !couponQuery.couponSettings.allowOddChanges)"
          analytics
          [click]="true"
          [category]="'Allow Odd Changes Switch'"
          [label]="'Allow: ' + couponQuery.couponSettings.allowOddChanges"
        >
        </app-toggle-switch>
      </div>
      <div class="setting" *ngIf="showStakeReduction">
        <div class="label" transloco="Allow stake reduction"></div>
        <app-toggle-switch
          [switch]="(couponQuery.couponSettings$ | async).allowStakeReduction"
          (click)="updateCouponSetting('allowStakeReduction', !couponQuery.couponSettings.allowStakeReduction)"
        >
        </app-toggle-switch>
      </div>
    </div>
  </div>

  <div class="amount-container" *ngIf="!bookedCoupon">
    <div class="stake-amount">
      <span class="label">{{ 'Stake' | transloco }} ({{ currencySymbol }})</span>
      <div class="value">
        <input
          type="number"
          step=".01"
          #stakeInput
          class="stake-amount"
          [value]="couponData.StakeGross | number : '1.0-2' | removeComma"
          (change)="updateInputStakeValue($event)"
          (click)="setHighlightText($event)"
        />
        <div
          class="reset-stake"
          [hidden]="firstStakePress$ | async"
          (click)="resetStakeValue()"
          transloco="✕"
          analytics
          [click]="true"
          [category]="'Reset Stake Button'"
        ></div>
      </div>
    </div>
    <div class="delimiters-content">
      <app-button
        *ngFor="let amount of fixedStakes; trackBy: indexTrackBy"
        [allCaps]="false"
        [customButtonStyle]="stakeButtonStyle"
        [text]="amount | odd"
        [buttonType]="buttonType.Brand"
        (click)="addStakeValue(amount)"
        analytics
        [click]="true"
        [category]="'Predefined Stake Button'"
        [label]="amount | odd"
      >
      </app-button>
    </div>
  </div>

  <div class="details-row" *ngIf="!bookedCoupon">
    <div class="content">
      <div class="label" transloco="Total Stake"></div>
      <div class="value">{{ couponData.StakeGross | odd }}</div>
    </div>

    <div class="content total-odds" *ngIf="couponData.CouponType !== couponType.System">
      <div class="label" transloco="Total Odds"></div>
      <div class="value">{{ couponData.TotalOdds | odd }}</div>
    </div>
    <div class="content total-odds" *ngIf="couponData.CouponType === couponType.System">
      <div class="label" transloco="Max. Odds"></div>
      <div class="value">{{ couponData.MaxOdd | odd }}</div>
    </div>
  </div>

  <div class="details-row totals booked header" *ngIf="bookedCoupon">
    <div class="content code">
      <div class="label" transloco="Booking Code:"></div>
      <div class="value">{{ bookedCouponCode }}</div>
    </div>
  </div>

  <div class="details-row totals booked" *ngIf="bookedCoupon">
    <div class="content">
      <div class="label" transloco="Booked:"></div>
      <div class="value">{{ bookedCouponDate }}</div>
    </div>
    <div class="content">
      <div class="label" transloco="Type:"></div>
      <div class="value" [ngSwitch]="couponData.CouponType">
        <span *ngSwitchCase="couponType.Single" transloco="Single"></span>
        <span *ngSwitchCase="couponType.Multiple" transloco="Multiple"></span>
        <span *ngSwitchCase="couponType.System" transloco="System"></span>
        <span *ngSwitchCase="couponType.Unknown" transloco="Unknown"></span>
      </div>
    </div>
  </div>

  <div class="total-details-container">
    <!-- <div class="coupon-bonus-header" transloco="Potential Winnings"></div> -->

    <div class="details-row totals" *ngIf="bookedCoupon && couponData.TurnoverTax > 0">
      <div class="content">
        <div class="label" transloco="Stake Gross"></div>
        <div class="value">{{ couponData.StakeGross | odd }}</div>
      </div>
    </div>
    <div class="details-row totals" *ngIf="couponData.TurnoverTax > 0">
      <div class="content">
        <div class="label">
          {{ 'Excise Duty' | transloco }}
          <span class="perc" *ngIf="taxPercentageToShow">({{ taxPercentageToShow }})</span>
        </div>
        <div class="value">{{ couponData.TurnoverTax | odd }}</div>
      </div>
      <div class="content">
        <div class="label" transloco="Stake Net"></div>
        <div class="value">{{ couponData.StakeGross - couponData.TurnoverTax | odd }}</div>
      </div>
    </div>
    <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
      <div class="content">
        <div class="label" transloco="Min. Odds"></div>
        <div class="value">{{ couponData.MinOdd | odd }}</div>
      </div>
      <div class="content">
        <div class="label" transloco="Max. Odds"></div>
        <div class="value">{{ couponData.MaxOdd | odd }}</div>
      </div>
    </div>
    <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
      <div class="content bonus">
        <div class="label">
          {{ 'Min. Bonus' | transloco }}
          <span class="perc" *ngIf="couponData.MinPercentageBonus !== 0">({{ this.truncate(couponData.MinPercentageBonus * 100) }}%)</span>
        </div>
        <div class="value">{{ couponData.MinBonus | odd }}</div>
      </div>
      <div class="content bonus">
        <div class="label">
          {{ 'Max. Bonus' | transloco }}
          <span class="perc" *ngIf="couponData.MaxPercentageBonus !== 0">({{ this.truncate(couponData.MaxPercentageBonus * 100) }}%)</span>
        </div>
        <div class="value">{{ couponData.MaxBonus | odd }}</div>
      </div>
    </div>
    <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System">
      <div class="content">
        <div class="label" transloco="Min. Win"></div>
        <div class="value">{{ couponData.NetStakeMinWin | odd }}</div>
      </div>
      <div class="content">
        <div class="label" transloco="Max. Win"></div>
        <div class="value">{{ couponData.NetStakeMaxWin | odd }}</div>
      </div>
    </div>
    <div class="details-row totals" *ngIf="couponData.CouponType === couponType.System && couponData.MaxWithholdingTax > 0">
      <div class="content">
        <div class="label" transloco="Min. WTH Tax"></div>
        <div class="value">{{ couponData.MinWithholdingTax | odd }}</div>
      </div>
      <div class="content">
        <div class="label" transloco="Max. WTH Tax"></div>
        <div class="value">{{ couponData.MaxWithholdingTax | odd }}</div>
      </div>
    </div>
    <div class="details-row totals bonus" *ngIf="couponData.CouponType !== couponType.System">
      <div class="content">
        <div class="label" transloco="Bonus"></div>
        <div class="perc" *ngIf="couponData.MaxPercentageBonus !== 0">({{ this.truncate(couponData.MaxPercentageBonus * 100) }}%)</div>
        <div class="value">{{ couponData.MaxBonus | odd }}</div>
      </div>
    </div>
    <div class="details-row totals" *ngIf="couponData.CouponType !== couponType.System && couponData.MaxWithholdingTax > 0">
      <div class="content">
        <div class="label" transloco="Withholding Tax"></div>
        <div class="value">{{ couponData.MaxWithholdingTax | odd }}</div>
      </div>
    </div>
  </div>
</div>
