import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

/**
 * This guard ensures that when a user is locked, he is
 * redirected to the change-phone-number route
 * except for whitelisted routes.
 */
@Injectable()
export class VerifiedGuard implements CanActivate {
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly appConfigService: AppConfigService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    try {
      if (
        this.accountQuery.userData &&
        this.accountQuery.userData.mobile &&
        this.accountQuery.userData.mobile.verifyType === 'LCKD' &&
        this.appConfigService.get('account').routeWhiteListForLockedUsers.find(url => url === next.routeConfig.path) === undefined
      ) {
        this.router.navigate(['/account/change-phone-number']);
        return false;
      }
      return true;
    } catch {
      this.router.navigate(['/account/change-phone-number']);
      return false;
    }
  }
}
