import { Injectable, OnDestroy } from '@angular/core';

import { Store, StoreConfig } from '@datorama/akita';
import { format, subMonths } from 'date-fns';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AccountMenuLinkModel,
  AccountState,
  AccountStatementFilterModel,
  AccountStatementModel,
  AccountStatementTransferTypeModel,
  AccountUIState,
  BetSearchState,
  TransferCandidateModel,
  UserModel,
  VerifyAccountModel
} from 'src/app/shared/models/account.model';
import { BonusModel } from 'src/app/shared/models/bonus.model';

function createInitialState(): AccountState {
  return {
    userData: undefined,
    verifyAccountState: undefined,
    showUnverifiedTooltip: true,
    multipleUnconfirmedUsers: undefined,
    isAuthenticated: false,
    resetPasswordOption: 'none',
    resetOptionTriggered: false,
    changePhoneNumberOption: 'none',
    phoneOptionTriggered: false,
    menuItems: undefined,
    betSearch: {
      queryParams: undefined,
      isLoading: false,
      betSearchResult: undefined
    },
    helpMenuItems: undefined,
    bonuses: [],
    bonusInfo: undefined,
    viewingBonus: undefined,
    ui: {
      noStatements: false,
      refreshingBalance: false,
      bonus: {
        isViewingActive: false,
        isViewingPaused: false,
        isViewingInactive: false,
        isViewingPrevious: false,
        isViewingMissed: false,
        isViewingBomb: true
      }
    },
    accountStatementFilter: {
      transferType: '0',
      dateFrom: format(subMonths(new Date(), 2), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd'),
      period: '0'
    },
    transferTypes: [],
    accountStatements: [],
    financialAccountStatements: [],
    transferCandidates: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'account' })
export class AccountStore extends Store<AccountState> implements OnDestroy {
  destroy$ = new Subject<boolean>();
  defaultAccountStatementFilter: AccountStatementFilterModel = undefined;

  private readonly userDataKey = 'userData';
  private readonly verifyAccountDataKey = 'verifyAccountData';

  constructor(private readonly localStorage: LocalStorageService) {
    super(createInitialState());
    this.defaultAccountStatementFilter = this._value().accountStatementFilter;
    setTimeout(this.initUserData.bind(this), 0);
  }

  observerUserData(): void {
    this.localStorage
      .observe(this.userDataKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.updateUserData(value);
      });
  }

  updateAccountMenuItems(menuItems: AccountMenuLinkModel[]): void {
    this.update({ menuItems });
  }

  updateAccounteHelpMenuItems(helpMenuItems: AccountMenuLinkModel[]): void {
    this.update({ helpMenuItems });
  }

  updateResetPasswordOption(resetPasswordOption: string): void {
    this.update({ resetPasswordOption });
  }

  updateResetOptionTriggered(resetOptionTriggered: boolean): void {
    this.update({ resetOptionTriggered });
  }

  updateChangePhoneNumberOption(changePhoneNumberOption: string): void {
    this.update({ changePhoneNumberOption });
  }

  updatePhoneOptionTriggered(phoneOptionTriggered: boolean): void {
    this.update({ phoneOptionTriggered });
  }

  updateStoredWallets(userData: UserModel, wallets: any): void {
    this.localStorage.store(this.userDataKey, { ...userData, wallets });
  }

  updateBonuses(bonuses: BonusModel[]): void {
    this.update({ bonuses });
  }

  updateVerifyAccountState(verifyAccountState: VerifyAccountModel): void {
    this.update({ verifyAccountState });

    this.localStorage.store(this.verifyAccountDataKey, verifyAccountState);
  }

  updateShowUnverifiedTooltip(showUnverifiedTooltip: boolean): void {
    this.update({ showUnverifiedTooltip });
  }

  updateMultipleUnconfirmedUsers(multipleUnconfirmedUsers: boolean): void {
    this.update({ multipleUnconfirmedUsers });
  }

  updateUserData(userData: UserModel): void {
    if (!userData) {
      this.clearUserData();
      return;
    }
    const isAuthenticated: boolean = userData && userData.accessToken !== undefined;
    this.update({
      userData,
      isAuthenticated
    });
    this.localStorage.store(this.userDataKey, userData);
  }

  updateBonusInfo(bonusInfo: BonusModel[]): void {
    this.update({ bonusInfo });
  }

  updateUI(ui: Partial<AccountState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

  updateBonusUI(bonusUI: Partial<AccountUIState['bonus']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        bonus: {
          ...state.ui.bonus,
          ...bonusUI
        }
      }
    }));
  }

  updateUIPartial(ui: Partial<AccountUIState>): void {
    this.update(state => ({ ui: { ...state.ui, ...ui } }));
  }

  updateAccountStatementFilter(filter: AccountStatementFilterModel): void {
    this.update({ accountStatementFilter: filter });
  }

  clearUserData(): void {
    this.update({ userData: undefined, isAuthenticated: false });
    this.localStorage.clear(this.userDataKey);
  }

  updateBetSearch(betSearch: Partial<BetSearchState>): void {
    this.update(state => ({ ...state, betSearch: { ...state.betSearch, ...betSearch } }));
  }

  updateTransferTypes(transferTypes: AccountStatementTransferTypeModel[]): void {
    this.update({ transferTypes });
  }

  updateAccountStatements(accountStatements: AccountStatementModel[]): void {
    this.update({ accountStatements });
    this.destroy$.complete();
  }
  updatePayoutAccountStatements(financialAccountStatements: AccountStatementModel[]): void {
    this.update({ financialAccountStatements });
    this.destroy$.complete();
  }

  updateViewingBonus(viewingBonus: BonusModel): void {
    this.update({ viewingBonus });
  }

  updateTransferCandidates(transferCandidates: TransferCandidateModel[]): void {
    this.update({
      transferCandidates: transferCandidates.sort((tc1, tc2) => (tc1.name + tc1.surname).localeCompare(tc2.name + tc2.surname))
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initUserData(): void {
    this.updateUserData(this.localStorage.retrieve(this.userDataKey));
    this.observerUserData();
  }
}
