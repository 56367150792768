import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { kebabCase } from 'lodash-es';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { expandCollapse } from 'src/app/shared/animations';
import { BonusModel } from 'src/app/shared/models/bonus.model';

@Component({
  selector: 'app-menu-promos',
  templateUrl: './menu-promos.component.html',
  styleUrls: ['./menu-promos.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuPromosComponent implements OnInit, OnDestroy {
  viewBonusCode: string = undefined;
  bonuses: BonusModel[];
  section: string;
  tab: string;

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accountService: AccountService,
    readonly route: ActivatedRoute,
    readonly router: Router,
    private readonly scroller: ViewportScroller,
    private readonly appConfig: AppConfigService
  ) {}

  public visibleBonuses$ = this.accountQuery.bonusInfo$.pipe(
    takeUntil(this.destroy$),
    map(bonuses => {
      return bonuses?.filter(bonus => {
        return this.appConfig.get('hidePromotionIds').indexOf(+bonus.bonusCode) === -1;
      });
    })
  );

  ngOnInit(): void {
    this.accountQuery.bonusInfo$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.bonuses = res;
      this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(this.handleRouteParams.bind(this));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  bonusTrackBy(bonus: BonusModel): string {
    return bonus.bonusCode;
  }

  onMoreInfoClicked(bonus: BonusModel): void {
    this.viewBonusCode = bonus.bonusCode;
    this.router.navigate(['menu', 'promos', kebabCase(bonus.name)]).then(() => {
      this.scrollToElement(this.viewBonusCode);
    });
  }
  scrollToElement(elementId: string): void {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  onLessInfoClicked(): void {
    this.viewBonusCode = undefined;
  }

  private handleRouteParams(params: ParamMap): void {
    this.section = params.get('section');
    this.tab = params.get('tab');
    const activeBonusSection = this.bonuses && this.bonuses.find(bonus => kebabCase(bonus.name) === this.section);
    if (activeBonusSection) {
      this.viewBonusCode = activeBonusSection.bonusCode;
      setTimeout(() => {
        this.scrollToElement(this.viewBonusCode);
      }, 500);
    }
  }
}
