import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslocoService, translate } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MyBetsService } from 'src/app/core/services/my-bets.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { expandCollapseSimpler } from 'src/app/shared/animations';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';
import { format, isYesterday, isToday } from 'date-fns';
import { ApplicationService } from 'src/app/core/services/application.service';
import { TodaysEventsService } from 'src/app/core/services/todays-events.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recent-bet',
  templateUrl: './recent-bet.component.html',
  styleUrls: ['./recent-bet.component.scss'],
  animations: [expandCollapseSimpler()]
})
export class RecentBetComponent implements OnInit, OnDestroy {
  @Input() evaluation: boolean;
  @Input() bet: any;
  @Input() isFirst?: boolean;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();

  betFinalState: typeof BetFinalState = BetFinalState;
  dateTime$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  collapseElement$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  noCheck: boolean;
  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly myBetsService: MyBetsService,
    private readonly languageService: LanguageService,
    private readonly couponService: CouponService,
    private readonly notificationService: NotificationService,
    private readonly transloco: TranslocoService,
    private readonly applicationService: ApplicationService,
    private readonly todaysEventsService: TodaysEventsService,
    private router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.handleBetDateTime();
    this.observeUpdateBet();
    if (window.location.pathname.includes('/coupon-details') || window.location.pathname.includes('/coupon-check')) {
      this.noCheck = this.activatedRoute.snapshot.data.noCheck ?? false;
      this.getBetDetails(true, this.noCheck);
      this.collapseElement$.next(true);
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.collapseElement$.next(false);
  }

  repeatBet(): void {
    this.couponService
      .rebetCoupon(this.bet.couponCode, this.languageService.selectedLanguage.language)
      .pipe(first())
      .subscribe(data => {
        this.handleRepeatBetResponse(data);
        if (!data) {
          return;
        } else {
          if (!this.applicationService.isDesktop$.value) {
            this.applicationService.showQuickCoupon(true);
            this.applicationService.showCoupon$.next(true);
          } else if (location.pathname === '/my-bets' && this.applicationService.isDesktop$.value) {
            this.router.navigate(['/']);
          }

          this.todaysEventsService.activeSidebarTab$.next('coupon');
        }
      });
  }

  onRefresh(): void {
    this.refresh.emit(true);
  }

  toggleExpand(): void {
    this.bet.collapsed ? this.getBetDetails() : this.toggleBetDetails();
    this.collapseElement$.next(!this.collapseElement$.value);
  }
  isSystem(): boolean {
    return this.bet.couponType === 'Combinations' || this.bet.CouponTypeId === 3;
  }
  isRegular(): boolean {
    return this.bet.couponType === 'Single' || this.bet.couponType === 'Multiple';
  }
  async shareCoupon(): Promise<void> {
    try {
      const shareData = {
        url: `https://tiket.victory.rs/api/${this.bet.couponCode}`
      };
      await (navigator as any).share(shareData);
    } catch (err) {
      this.notificationService.showInfoMessage(translate('The coupon URL is copied to your clipboard'), 3000);
    }
  }

  get state(): BetFinalState {
    return this.bet.betFinalState;
  }

  get isLive(): boolean {
    return this.bet.isLive;
  }

  private handleBetDateTime(): void {
    let dayString = '';
    const day = new Date(this.bet.couponDate);

    const isBetToday = isToday(day);
    const isBetYesterday = isYesterday(day);

    if (isBetToday) {
      dayString = this.transloco.translate<string>('Today');
    } else if (isBetYesterday) {
      dayString = this.transloco.translate<string>('Yesterday');
    } else {
      dayString = format(day, 'dd.MM');
    }

    dayString += `, ${format(day, 'HH:mm')}`;
    this.dateTime$.next(dayString);
  }

  private observeUpdateBet(): void {
    this.myBetsService.updateBet$
      .pipe(
        filter(couponCode => this.bet.couponCode === couponCode),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.getBetDetails(false));
  }

  private handleRepeatBetResponse(data: any): void {
    if (!data) {
      this.notificationService.showInfoMessage(translate('The events are no longer available'), 3000);
    }
  }

  private getBetDetails(toggleCollapsed = true, noCheck: boolean = false): void {
    this.myBetsService
      .getBetDetails(this.bet, this.languageService.selectedLanguage.language, false, noCheck)
      .pipe(first())
      .subscribe(() => {
        if (toggleCollapsed) {
          this.toggleBetDetails();
        }
      });
  }

  private toggleBetDetails(): void {
    this.myBetsService.toggleCollapsed(this.bet);
  }
}
