import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { BannerRotatorStore } from 'src/app/core/state/banner-rotator/banner-rotator.store';
import { BannerRotatorModel, BannerRotatorVisibility, BannerState } from 'src/app/shared/models/banner-rotator.model';
import { AccountQuery } from '../account/account.query';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BannerRotatorQuery extends Query<BannerState> {
  loading$ = this.selectLoading();
  error$ = this.selectError();
  bannerDetails$ = this.select(state => (state.bannerDetails ? state.bannerDetails.filter(bd => this.authCheck(bd)) : []));
  withdrawalBannerLength$ = this.select(state => state.bannerDetails?.filter(bd => bd.route === 'Withdrawal')).pipe(
    map(res => res?.length)
  );
  depositBannerLength$ = this.select(state => state.bannerDetails?.filter(bd => bd.route === 'Deposit')).pipe(map(res => res?.length));

  constructor(protected store: BannerRotatorStore, private readonly accountQuery: AccountQuery) {
    super(store);
  }

  private authCheck(banner: BannerRotatorModel): boolean {
    return (
      banner.display === BannerRotatorVisibility.Always ||
      (banner.display === BannerRotatorVisibility.LoggedIn && this.accountQuery.isAuthenticated) ||
      (banner.display === BannerRotatorVisibility.LoggedOut && !this.accountQuery.isAuthenticated)
    );
  }
}
