<div
  class="proceed-bar-wrapper"
  [class.with-coupon]="(betSlipBadgeCount$ | async) > 0"
  [@slideUp]
  (click)="depth === 3 ? filter() : proceed()"
>
  <div class="left-elements">
    <div class="cancel-button" (click)="handleCancelButtonClick(); $event.stopPropagation()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
        <g clip-path="url(#a)">
          <path
            fill="#fff"
            d="M14.398 3.273A7.724 7.724 0 0 0 8.217.227C6.497.227 4.866.78 3.5 1.825c-.494.379-.94.815-1.33 1.299l-.347-1.966a.395.395 0 0 0-.458-.32L.327 1.02a.395.395 0 0 0-.321.458l.825 4.67a.395.395 0 0 0 .458.321l4.67-.824a.395.395 0 0 0 .321-.458L6.097 4.15a.395.395 0 0 0-.458-.32l-1.947.343c.277-.327.589-.623.93-.883a5.869 5.869 0 0 1 3.596-1.219c1.867 0 3.585.847 4.715 2.323a5.89 5.89 0 0 1 1.17 4.384 5.89 5.89 0 0 1-2.274 3.928 5.863 5.863 0 0 1-4.384 1.17 5.89 5.89 0 0 1-3.928-2.274A5.94 5.94 0 0 1 2.38 8.99a.395.395 0 0 0-.456-.324l-1.04.175a.395.395 0 0 0-.323.456 7.79 7.79 0 0 0 1.493 3.426 7.723 7.723 0 0 0 5.149 2.98 7.93 7.93 0 0 0 1.038.07c1.712 0 3.341-.554 4.71-1.603a7.722 7.722 0 0 0 2.98-5.149 7.721 7.721 0 0 0-1.532-5.748Z"
          />
        </g>
        <defs>
          <clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath>
        </defs>
      </svg>
      <span transloco="Cancel"></span>
    </div>
  </div>
  <div class="right-elements">
    <div class="label" transloco="Next"></div>
    <i class="fa fa-caret-right" aria-hidden="true"></i>
  </div>
</div>
