<div class="event-container" [attr.id]="'prematch-event-' + event?.id">
  <div
    [class.first]="first"
    [class.last]="last"
    [class.no-padding]="!showDetails"
    [class.show-border]="showBorder"
    [class.specials]="specials"
    class="event-wrapper"
  >
    <div class="event" [class.full-width]="!hasTeamAway" [class.specials]="specials">
      <div class="event-details">
        <app-event-header
          *ngIf="showDetails && event"
          [event]="event"
          [showTournament]="showTournament"
          [sportId]="sportId"
          [specials]="specials"
        >
        </app-event-header>
        <app-event-details
          (click)="navigateToMatch()"
          *ngIf="showDetails && event"
          [category]="'Match Details'"
          [click]="true"
          [event]="event"
          [hasTeamAway]="hasTeamAway"
          [showDate]="showDate"
          [specials]="specials"
          analytics
        >
        </app-event-details>
      </div>
      <div class="event-odds">
        <app-odd-group [odds]="event?.odds" [specials]="specials"></app-odd-group>
      </div>
      <!-- <div class="event-odds" *ngIf="hasTeamAway && !isAvailable">
        <app-not-available-message></app-not-available-message>
      </div> -->
    </div>
  </div>
</div>
