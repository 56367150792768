import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    Self,
    TemplateRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Directive({
    selector: '[appExpiryDateInputMask]',
})
export class ExpiryDateInputMask implements OnDestroy {
    @Input() month: any;
    @Input() year: any;
    private destroy$ = new Subject();

    constructor(@Self() private ngControl: NgControl, private el: ElementRef) { }

    ngAfterViewInit() {
    }

    @HostListener('keyup', ['$event']) keyUp(event) {
        const inputChar = String.fromCharCode(event.keyCode);
        const code = event.keyCode;
        const allowedKeys = [8];
        if (allowedKeys.indexOf(code) !== -1) {
            return;
        }

        const value = event.target.value
            .replace(/^([1-9]\/|[2-9])$/g, '0$1/')
            .replace(/^(0[1-9]|1[0-2])$/g, '$1/')
            .replace(/^([0-1])([3-9])$/g, '0$1/$2')
            .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, '$1/$2')
            .replace(/^([0]+)\/|[0]+$/g, '0')
            .replace(/[^\d\/]|^[\/]*$/g, '')
            .replace(/\/\//g, '/');
        this.setValue(value);
    }

    @HostListener('focusout', ['$event']) onFocusOut(event) {
        const regExp = /(1[0-2]|0[1-9]|\d)\/(20\d{2}|19\d{2}|0(?!0)\d|[1-9]\d)/;
        const matches = regExp.exec(event.target.value);

        if (matches?.length == 3) {
            this.month.setValue(matches[1]);
            this.year.setValue(matches[2]);
        }
        else {
            this.month.setValue('a');
            this.year.setValue('a');
        }
    }

    setValue(v) {
        this.ngControl.control.setValue(v, { emitEvent: false });
    }

    ngOnDestroy() {
        this.setValue('');
    }
}
