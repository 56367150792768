import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { interval, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LiveService } from 'src/app/core/services/live.service';
import { MyBetsService } from 'src/app/core/services/my-bets.service';
import { EventCategory, EventStatus } from 'src/app/shared/models/my-bets.model';
import { isToday } from 'date-fns';
import { ApplicationService } from 'src/app/core/services/application.service';

@Component({
  selector: 'app-recent-bet-match',
  templateUrl: './recent-bet-match.component.html',
  styleUrls: ['./recent-bet-match.component.scss']
})
export class RecentBetMatchComponent implements OnInit, OnDestroy {
  @Input() match: any;
  @Input() couponCode: string;
  @Input() card: boolean = false;

  hasLiveMatch$: ReplaySubject<boolean> = new ReplaySubject();
  liveScore$: ReplaySubject<string> = new ReplaySubject();
  matchStatusLabel$: ReplaySubject<string> = new ReplaySubject();
  isLive$: ReplaySubject<boolean> = new ReplaySubject();
  endResult$: ReplaySubject<string> = new ReplaySubject();
  homeResult$: ReplaySubject<string> = new ReplaySubject();
  awayResult$: ReplaySubject<string> = new ReplaySubject();
  eventName$: BehaviorSubject<string> = new BehaviorSubject('');

  isMatchToday$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private isLive = false;

  private readonly observeLiveMatchEveryMs: number = 7000;
  private liveMatch$: Observable<any>;
  private liveMatchID: string;

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly liveService: LiveService,
    private readonly myBetsService: MyBetsService,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService
  ) {}

  get isMatchResultUnset(): boolean {
    return this.match.result === 'Unset' || this.match.resultStatus === 'Unset';
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get isWon(): boolean {
    return this.match.eventStatusId === EventStatus.Won || this.match.resultStatusId === 1;
  }

  get isEventPrematch(): boolean {
    return this.match.eventCategory === EventCategory.Prematch;
  }

  get hasMatchStarted(): boolean {
    return new Date().getTime() > new Date(this.match.eventDate).getTime();
  }

  get isLost(): boolean {
    return this.match.eventStatusId === EventStatus.Lost || this.match.resultStatusId === 0;
  }

  ngOnInit(): void {
    this.handleEndResult();
    this.checkIsMatchLive().pipe(takeUntil(this.destroy$)).subscribe(this.fetchLiveMatch.bind(this));
    this.parseSpecialsPlayerName();

    this.isMatchToday$.next(isToday(new Date(this.match.eventDate)));
  }

  openLiveMatch(): void {
    if (!this.liveMatchID || this.isEventPrematch) {
      return;
    }
    this.router.navigateByUrl(`live/${this.liveMatchID}`);
  }

  get isCancelled(): boolean {
    return this.match.eventStatusId === EventStatus.Cancelled;
  }

  isMarketNameTooLong(match: string): boolean {
    return match.length > 10;
  }
  hideSpread(match: any): boolean {
    return (
      match.sportId === 1 &&
      (this.appConfig.get('sports')?.coupon?.hideMarketSpreadIds.indexOf(match.marketTypeId) > -1 ||
        this.appConfig.get('sports')?.coupon?.hideMarketSpreadIdsFromRecentBets.indexOf(match.marketTypeId) > -1)
    );
  }

  private parseSpecialsPlayerName(): void {
    if (!(this.match.eventName.includes('[') && this.match.eventName.includes(']'))) {
      this.eventName$.next(this.match.eventName);
      return;
    }
    let [homeTeam, awayTeam] = this.match.eventName.split(' - ');
    if (homeTeam.includes('[') && homeTeam.includes(']')) {
      awayTeam = this.getPlayerNameFromTeamName(homeTeam);
      homeTeam = homeTeam.replace(`[${awayTeam}]`, '');
    } else if (awayTeam.includes('[') && awayTeam.includes(']')) {
      homeTeam = awayTeam;
      awayTeam = this.getPlayerNameFromTeamName(homeTeam);
      homeTeam = homeTeam.replace(`[${awayTeam}]`, '');
    }

    this.eventName$.next(`${homeTeam} - ${awayTeam}`);
  }

  private getPlayerNameFromTeamName(teamName: string): string {
    const openingIdx = teamName.indexOf('[');
    const closingIdx = teamName.indexOf(']');
    return teamName.slice(openingIdx + 1, closingIdx);
  }

  private handleEndResult(): void {
    if (!this.match.results) {
      return;
    }

    if (this.match.results.ft) {
      this.endResult$.next(`${this.match.results.ft[0].value}:${this.match.results.ft[1].value}`);
      this.homeResult$.next(`${this.match.results.ft[0].value}`);
      this.awayResult$.next(`${this.match.results.ft[1].value}`);
    }

    if (this.match.results.length && this.match.results[0].Family === 'FT') {
      this.endResult$.next(`${this.match.results[0].Value}:${this.match.results[1].Value}`);
      this.homeResult$.next(`${this.match.results[0].Value}`);
      this.awayResult$.next(`${this.match.results[1].Value}`);
    }
  }

  private checkIsMatchLive(): Observable<string> {
    this.isLive = this.hasMatchStarted && this.isMatchResultUnset;
    this.isLive$.next(this.isLive);

    return this.isLive
      ? this.isEventPrematch
        ? this.mapPrematchAndLiveEventID()
        : of(`${this.match.eventID || this.match.eventId}`)
      : of(null);
  }

  private mapPrematchAndLiveEventID(): Observable<string> {
    return this.liveService.getLiveEvents(true).pipe(
      map(response => {
        const events = [];
        response.Sports.forEach((sport: any) => {
          sport.Tournaments.forEach((tournament: any) => events.push([...tournament.Events]));
        });

        return events
          .reduce((a, b) => a.concat(b), [])
          .filter((match: any) => match.ProviderId === this.match.providerID)
          .map((match: any) => (match ? `${match.Id}` : null));
      })
    );
  }

  private fetchLiveMatch(eventID: string): void {
    if (!eventID) {
      return;
    }

    this.liveMatchID = eventID;

    this.liveMatch$ = this.liveService.getCouponLiveEventById(eventID);
    this.liveMatch$.subscribe(this.handleLiveMatch.bind(this));
  }

  private handleLiveMatch(liveData: any): void {
    if (!liveData.Tournaments) {
      return;
    }

    this.hasLiveMatch$.next(true);
    this.setLiveScore(liveData);
    this.observeLiveScore();
  }

  private observeLiveScore(): void {
    interval(this.observeLiveMatchEveryMs)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.liveMatch$.subscribe(this.setLiveScore.bind(this)));
  }

  private setLiveScore(liveData: any): void {
    const matchEvent = liveData.Tournaments ? liveData.Tournaments[0].Events[0] : null;
    matchEvent.SportId = liveData.Id;
    if (!matchEvent || !matchEvent.Score) {
      this.myBetsService.updateBet$.next(this.couponCode);
      return;
    }
    this.liveScore$.next(matchEvent.Score);
    this.setMatchStatus(matchEvent.MatchStatus, matchEvent.SportId);
  }

  private setMatchStatus(matchStatus: EventStatus, sportId: number): void {
    this.matchStatusLabel$.next(this.liveService.getMatchStatusMapper(matchStatus, sportId));
  }
}
