<div class="account-statement-container">
  <app-breadcrumb [title]="'Transaction List' | transloco" faIcon="fa-id-card-o" (backButtonClick)="backPressed()">
  </app-breadcrumb>
  <app-card [titleHeaderText]="'Transaction List' | transloco">
    <div class="account-statement-card-container" body>
      <account-statement-search [hidden]="(accountQuery.accountStatements$ | async).length !== 0">
      </account-statement-search>
      <account-statement-results *ngIf="(accountQuery.accountStatements$ | async).length !== 0">
      </account-statement-results>
    </div>
  </app-card>
</div>
