import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CMSNotificationsService } from 'src/app/core/services/cms-notifications.service';
import { CMSNotification } from 'src/app/shared/models/cms-notification';

@Component({
  selector: 'app-cms-notifications',
  templateUrl: './cms-notifications.component.html',
  styleUrls: ['./cms-notifications.component.scss']
})
export class CmsNotificationsComponent implements OnInit {
  dialog$: Subject<CMSNotification> = new Subject();
  toasts$: Subject<CMSNotification[]> = new Subject();

  hasBackgroundOverlay$: Subject<boolean> = new Subject();

  constructor(private readonly notificationsService: CMSNotificationsService) {}

  ngOnInit(): void {
    this.handleDialogs();
    this.handleToasts();
  }

  private handleDialogs(): void {
    this.notificationsService.dialogs$
      .pipe(
        tap(dialogs => {
          if (dialogs.length) {
            this.hasBackgroundOverlay$.next(true);
            this.disableScroll();
          } else {
            this.hasBackgroundOverlay$.next(false);
            this.enableScroll();
          }
        })
      )
      .subscribe(dialogs => (dialogs.length ? this.dialog$.next(dialogs[0]) : this.dialog$.next(undefined)));
  }

  private handleToasts(): void {
    this.notificationsService.toasts$.subscribe(toasts => {
      this.toasts$.next(toasts);
    });
  }

  private disableScroll(): void {
    document.body.classList.add('disable-scrolling');
  }

  private enableScroll(): void {
    document.body.classList.remove('disable-scrolling');
  }
}
