/* tslint:disable */
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';

@Component({
  selector: 'app-payments-shared-promo',
  templateUrl: './payments-shared-promo.component.html',
  styleUrls: ['./payments-shared-promo.component.scss']
})
export class PaymentsSharedPromoComponent implements OnInit, OnDestroy, OnChanges {
  promoIframeUrl$ = new ReplaySubject<SafeResourceUrl | null>(null);
  hasPromoIframeUrl$ = this.promoIframeUrl$.pipe(map(url => !!url));
  constructor(
    private readonly route: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
    private readonly appConfig: AppConfigService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.setIframeUrl();
  }
  ngOnInit(): void {
    window.addEventListener(
      'message',
      event => {
        if (event.data.type === 'promotion-details') {
          window.location.href = 'lp/kes-na-vreme?btag=lp_kešnavreme_';
        }
        // …
      },
      false
    );
    this.setIframeUrl();
  }
  private setIframeUrl() {
    const iframeBaseUrl = this.appConfig.get('bombBonusUrl');
    const id = this.route.snapshot.paramMap.get('transactionId');
    const hash = this.route.snapshot.queryParamMap.get('sh');
    this.promoIframeUrl$.next(this.sanitizer.bypassSecurityTrustResourceUrl(`${iframeBaseUrl}payout-promo/${id}/share?sh=${hash}`));
  }
  ngOnDestroy(): void {}
}
