import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { BookBetService } from 'src/app/core/services/book-bet.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'app-saved-coupons',
  templateUrl: './saved-coupons.component.html',
  styleUrls: ['./saved-coupons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SavedCouponsComponent {
  @Input() withExpiredPrompt: boolean = false;
  @Output() readonly gotExpiredData = new EventEmitter<BookBetModel>();

  expiredBetData$ = new BehaviorSubject<BookBetModel>(undefined);

  constructor(
    private readonly bookBetService: BookBetService,
    readonly couponQuery: CouponQuery,
    private readonly notificationService: NotificationService,
    private readonly couponService: CouponService,
    
    private readonly languageService: LanguageService
  ) {}

  getBookBetData(bookCode: string): void {
    this.bookBetService.getBookBet(bookCode, this.languageService.selectedLanguage.language).subscribe(this.handleExpiredEvents);
  }

  removeBookedBet(couponCode: string): void {
    const confirmCallback = () => {
      this.couponService.removeBookedCoupon(couponCode);
    };

    this.notificationService.showNotification(
      new NotificationSettings({
        allowBackdropClose: true,
        confirmButtonCallback: confirmCallback,
        contentText: translate('Are you sure you want to remove this booked coupon?'),
        type: 'question',
        showConfirmButton: true,
        showCancelButton: true
      })
    );
  }

  onExpiredSubmit(): void {
    if (this.expiredBetData$.value) {
      this.bookBetService.loadBookedCoupon(this.expiredBetData$.value);
    }
    this.expiredBetData$.next(undefined);
  }

  onCancel(): void {
    this.expiredBetData$.next(undefined);
  }

  indexTrackBy(index: number, item: string): number {
    return index;
  }

  private readonly handleExpiredEvents = (res: BookBetModel) => {
    if (res.hasExpiredEvents) {
      this.expiredBetData$.next(res);
      this.gotExpiredData.emit(res);
    } else {
      this.expiredBetData$.next(undefined);
    }
  };
}
