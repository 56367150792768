<div class="sports-container" *ngIf="sportQuery.sportsList$ | async as sports">
  <ng-container *ngIf="favourites$ | async as favouriteSports">
    <app-sport-list
      *ngIf="favouriteSports.length > 0"
      [selectedSport]="selectedSport$ | async"
      [sports]="favouriteSports"
      [title]="'Favourites' | transloco"
    ></app-sport-list>
  </ng-container>
  <app-sport-list [selectedSport]="selectedSport$ | async" [sports]="allSports$ | async" [title]="'A-Z Sports' | transloco">
  </app-sport-list>
</div>
