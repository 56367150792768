/* tslint:disable */
import { Injectable } from '@angular/core';

import { ID } from '@datorama/akita';

import { translate } from '@ngneat/transloco';
import { NotificationSettings, ToastNotification } from 'src/app/shared/models/notification.model';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ToastNotificationQuery } from './../state/toast-notifications/toast-notifications.query';
import { ToastNotificationStore } from './../state/toast-notifications/toast-notifications.store';
import { SafeHTML } from 'src/app/shared/pipes/safe-html.pipe';

// The Swal library doesn't work correctly with undefined values, so for this file null values will be used instead
// tslint:disable: no-null-keyword
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly timeOutList = [];

  constructor(
    private readonly toastNotificationStore: ToastNotificationStore,
    private readonly toastNotificationQuery: ToastNotificationQuery
  ) {}

  /**
   * The customizable way to generate a notification
   * @param settings An instance of the NotificationSettings class which enables full control of the notification
   */
  showWithdrawalNotification(settings: NotificationSettings): void {
    const swalOptions = this.generateNewNotificationOption(settings);

    Swal.fire(swalOptions).then(result => {
      if (result.value) {
        // confirm button clicked
        if (settings.confirmButtonCallback) {
          settings.confirmButtonCallback();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // cancel button clicked
        if (settings.cancelButtonCallback) {
          settings.cancelButtonCallback();
        }
      } else {
        // notification dismissed through some other form
        if (settings.closeButtonCallback) {
          settings.closeButtonCallback();
        }
      }
    });
  }
  showNotification(settings: NotificationSettings): void {
    const swalOptions = this.generateNotificationOptions(settings);

    Swal.fire(swalOptions).then(result => {
      if (result.value) {
        // confirm button clicked
        if (settings.confirmButtonCallback) {
          settings.confirmButtonCallback();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // cancel button clicked
        if (settings.cancelButtonCallback) {
          settings.cancelButtonCallback();
        }
      } else {
        // notification dismissed through some other form
        if (settings.closeButtonCallback) {
          settings.closeButtonCallback();
        }
      }
    });
  }

  /**
   * Shorthand method to show a success notification
   * @param text The content text of the notification
   * @param title The title of the notification
   */
  showErrorReWithdrawalNotification(html: string, title?: string, cancelCallback?: Function) {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      cancelButtonText: 'Close notification',
      contentHtml: html,
      showCancelButton: true,
      cancelButtonCallback: cancelCallback,
      title: title,
      type: 'error'
    });
    this.showWithdrawalNotification(settings);
  }
  showSuccesReWithdrawalNotification(html: string, title?: string, cancelCallback?: Function) {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      cancelButtonText: 'Close notification',
      contentHtml: html,
      showCancelButton: true,
      cancelButtonCallback: cancelCallback,
      title: title,
      type: 'success'
    });
    this.showWithdrawalNotification(settings);
  }
  showPromoWithdrawalSuccessNotification(html: string, title?: string, confirmCallback?: Function, cancelCallback?: Function) {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'Find out more',
      cancelButtonText: 'Continue',
      contentHtml: html,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonCallback: confirmCallback,
      cancelButtonCallback: cancelCallback,
      title: title,
      type: 'success'
    });
    this.showWithdrawalNotification(settings);
  }
  showWithdrawalSuccessNotification(html: string, title?: string, confirmCallback?: Function, cancelCallback?: Function) {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'Continue',
      cancelButtonText: 'Close notification',
      contentHtml: html,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonCallback: confirmCallback,
      cancelButtonCallback: cancelCallback,
      title: title,
      type: 'success'
    });
    this.showWithdrawalNotification(settings);
  }
  showWithdrawalErrorNotification(html: string, title?: string, confirmCallback?: Function, cancelCallback?: Function) {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'Try again',
      cancelButtonText: 'Close notification',
      contentHtml: html,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonCallback: confirmCallback,
      cancelButtonCallback: cancelCallback,
      title: title,
      type: 'error'
    });
    this.showWithdrawalNotification(settings);
  }
  showWithdrawalLoadingNotification(text: string, title?: string) {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      contentText: text,
      title: title,
      type: 'info'
    });
    this.showWithdrawalNotification(settings);
  }
  showWithdrawalQuestionNotification(html: string, confirmCallback?: Function, cancelCallback?: Function) {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'Yes,cancel withdrawal',
      cancelButtonText: 'No,close notification',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonCallback: confirmCallback,
      cancelButtonCallback: cancelCallback,
      contentHtml: html,
      type: 'question'
    });
    this.showWithdrawalNotification(settings);
  }
  showSuccessNotification(text: string, title?: string, confirmCallback?: Function): void {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'OK',
      contentText: text,
      showConfirmButton: true,
      confirmButtonCallback: confirmCallback,
      title: title,
      type: 'success'
    });
    this.showNotification(settings);
  }

  /**
   * Shorthand method to show a warning notification
   * @param text The content text of the notification
   * @param title The title of the notification
   */
  showWarningNotification(text: string, title?: string): void {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'OK',
      contentText: text,
      showConfirmButton: true,
      title: title,
      type: 'warning'
    });
    this.showNotification(settings);
  }

  /**
   * Shorthand method to show an error notification
   * @param text The content text of the notification
   * @param title The title of the notification
   */
  showErrorNotification(text: string, title?: string): void {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'OK',
      contentText: text,
      showConfirmButton: true,
      title: title,
      type: 'error'
    });
    this.showNotification(settings);
  }

  /**
   * Shorthand method to show an information notification
   * @param text The content text of the notification
   * @param title The title of the notification
   */
  showInfoNotification(text: string, title?: string): void {
    const settings: NotificationSettings = new NotificationSettings({
      allowBackdropClose: true,
      confirmButtonText: 'OK',
      contentText: text,
      showConfirmButton: true,
      title: title,
      type: 'info'
    });
    this.showNotification(settings);
  }

  async showQueueNotifications(messagesSettings: NotificationSettings[]): Promise<void> {
    const swalOptions: SweetAlertOptions[] = [];
    if (messagesSettings.length > 0) {
      messagesSettings.forEach(settings => {
        swalOptions.push(this.generateNotificationOptions(settings));
      });

      for (let i = 0; i < swalOptions.length; i++) {
        const option = swalOptions[i];
        const settings = messagesSettings[i];
        const result = await Swal.fire(option);
        if (result.value && settings.confirmButtonCallback) {
          settings.confirmButtonCallback();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          messagesSettings.forEach(ms => ms.cancelButtonCallback?.());
          break;
        } else {
          messagesSettings.forEach(ms => ms.closeButtonCallback?.());
          break;
        }
      }
    }
  }

  /**
   * Shorthand method to show a success message
   * @param text The text to show in the message
   * @param timeout The time in ms until the toast is automatically closed (default 3000ms)
   */
  showSuccessMessage(text: string, timeout?: number): void {
    const settings: NotificationSettings = new NotificationSettings({
      contentHtml: this.generateMessageHtml(text, 'fa-check'),
      timeout: timeout || 2000,
      type: 'success'
    });

    const swalOptions = this.generateToastOptions(settings);
    Swal.fire(swalOptions);
  }

  /**
   * Shorthand method to show a warning message
   * @param text The text to show in the message
   * @param timeout The time in ms until the message is automatically closed (default 3000ms)
   * @param obstructing Set wheter the toast obstructs the user when displaying the notification
   */
  showWarningMessage(text: string, timeout?: number, obstructing: boolean = true): void {
    const settings: NotificationSettings = new NotificationSettings({
      contentHtml: this.generateMessageHtml(text, 'fa-exclamation-triangle'),
      timeout: timeout || 2000,
      type: 'warning',
      toast: !obstructing
    });

    const swalOptions = this.generateToastOptions(settings);
    Swal.fire(swalOptions);
  }

  /**
   * Shorthand method to show an error message
   * @param text The text to show in the message
   * @param timeout The time in ms until the message is automatically closed (default 3000ms)
   * @param obstructing Set wheter the toast obstructs the user when displaying the notification
   */
  showErrorMessage(text: string, timeout?: number, obstructing: boolean = true): void {
    const settings: NotificationSettings = new NotificationSettings({
      contentHtml: this.generateMessageHtml(text, 'fa-exclamation-triangle'),
      timeout: timeout || 2000,
      type: 'error',
      toast: !obstructing
    });

    const swalOptions = this.generateToastOptions(settings);
    Swal.fire(swalOptions);
  }

  /**
   * Shorthand method to show an information message
   * @param text The text to show in the message
   * @param timeout The time in ms until the message is automatically closed (default 3000ms)
   * @param obstructing Set whether the toast obstructs the user when displaying the notification
   */
  showInfoMessage(text: string, timeout?: number, obstructing: boolean = true): void {
    const settings: NotificationSettings = new NotificationSettings({
      contentHtml: this.generateMessageHtml(text, 'fa-info-circle'),
      timeout: timeout,
      type: 'info',
      toast: !obstructing
    });

    const swalOptions = this.generateToastOptions(settings);
    Swal.fire(swalOptions);
  }

  /**
   * Shorthand method to add a success toast to list
   * @param toast The toast object
   */
  addToast(toast: ToastNotification): void {
    this.toastNotificationStore.addToList(toast);
  }

  /**
   * Delete a toast after duration has been passed
   * @param id Id of toast object
   */
  toastDeleteAfterDuration(id: ID): void {
    const duration = this.toastNotificationQuery.getEntity(id).duration;

    this.timeOutList.push({
      id: id,
      timeout: setTimeout(() => {
        this.toastNotificationStore.removeFromListById(id);
      }, duration * 1000)
    });
  }

  /**
   * Remove a toast
   * @param id ID of toast to be removed
   */
  removeToast(id: ID): void {
    clearTimeout(this.timeOutList.filter(to => to.id === id)[0].timeout);
    this.toastNotificationStore.removeFromListById(id);
  }

  private generateNotificationOptions(settings: NotificationSettings): SweetAlertOptions {
    let singleButton: boolean = false;
    if ((settings.showCancelButton && !settings.showConfirmButton) || (!settings.showCancelButton && settings.showConfirmButton)) {
      singleButton = true;
    }

    return {
      allowOutsideClick: settings.allowBackdropClose,
      backdrop: 'rgba(0, 0, 0, 0.8)',
      background: settings.background || null,
      buttonsStyling: false,
      cancelButtonText: settings.cancelButtonText || translate('Cancel'),
      confirmButtonText: settings.confirmButtonText || translate('OK'),
      customClass: {
        container: 'notif-container',
        popup: `notif-popup type-${settings.type}`,
        // header: 'notif-header',
        title: 'notif-title',
        image: 'notif-image',
        // content: `notif-content ${settings.branded ? 'branded' : ''}`,
        input: 'notif-input',
        actions: `notif-actions ${singleButton ? 'single-button' : ''}`,
        cancelButton: 'notif-cancel-button',
        closeButton: 'notif-close-button',
        confirmButton: 'notif-confirm-button',
        footer: 'notif-footer'
      },
      heightAuto: false,
      html: settings.contentHtml || null,
      reverseButtons: true,
      showCancelButton: settings.showCancelButton,
      showCloseButton: settings.showCloseButton,
      showConfirmButton: settings.showConfirmButton,
      text: settings.contentText || null,
      titleText: settings.title || null,
      timer: settings.timeout || null
    };
  }
  private generateNewNotificationOption(settings: NotificationSettings): SweetAlertOptions {
    return {
      allowOutsideClick: settings.allowBackdropClose,
      backdrop: 'rgba(0, 0, 0, 0.8)',
      background: settings.background || null,
      buttonsStyling: false,
      cancelButtonText: translate(settings.cancelButtonText) || translate('Cancel'),
      confirmButtonText: translate(settings.confirmButtonText) || translate('OK'),
      customClass: {
        container: 'notif-wrapper',
        popup: `notif-popup type-${settings.type}`,
        // header: 'notif-header',
        title: 'notif-title',
        image: 'notif-image',
        // content: `notif-content ${settings.branded ? 'branded' : ''}`,
        input: 'notif-input',
        cancelButton: 'notif-cancel-button',
        closeButton: 'notif-close-button',
        confirmButton: 'notif-confirm-button',
        footer: 'notif-footer'
      },
      heightAuto: false,
      html: settings.contentHtml || null,
      reverseButtons: true,
      showCancelButton: settings.showCancelButton,
      showCloseButton: settings.showCloseButton,
      showConfirmButton: settings.showConfirmButton,
      text: settings.contentText || null,
      titleText: settings.title || null,
      timer: settings.timeout || null
    };
  }
  private generateMessageHtml(text: string, faIcon?: string): string {
    let toastHtml: string = `<div class='toast-content'>`;
    if (faIcon) {
      toastHtml += `<i class="fa ${faIcon} toast-icon" aria-hidden="true"></i>`;
    }
    toastHtml += `<span class='toast-text'>${text}</span></div>`;
    return toastHtml;
  }

  private generateToastOptions(settings: NotificationSettings): SweetAlertOptions {
    return settings.toast
      ? {
          buttonsStyling: false,
          customClass: {
            container: 'notif-container',
            popup: `notif-toast type-${settings.type}`,
            // header: 'notif-header',
            image: 'notif-image'
            // content: 'notif-content'
          },
          html: settings.contentHtml || null,
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          timer: settings.timeout || 2500,
          toast: settings.toast,
          position: 'top',
          width: '90vw'
        }
      : {
          backdrop: 'rgba(0, 0, 0, 0.8)',
          buttonsStyling: false,
          customClass: {
            container: 'notif-container',
            popup: `notif-toast type-${settings.type}`,
            // header: 'notif-header',
            image: 'notif-image'
            // content: 'notif-content'
          },
          html: settings.contentHtml || null,
          showCancelButton: false,
          showCloseButton: false,
          showConfirmButton: false,
          timer: settings.timeout || 1100,
          toast: settings.toast,
          position: 'center',
          width: null
        };
  }
}
