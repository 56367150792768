import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router, UrlCreationOptions } from '@angular/router';
import { BehaviorSubject, Observable, Subject, interval } from 'rxjs';
import { map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { MyBetsService, MyBetsTimePeriod } from 'src/app/core/services/my-bets.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { MyBetsQuery } from 'src/app/core/state/my-bets/my-bets.query';
import { BlogService } from 'src/app/modules/blog/blog.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Location } from '@angular/common';
import { UserModel } from 'src/app/shared/models/account.model';
import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  time$: Subject<string> = new Subject();
  private timeInterval: NodeJS.Timer;
  private readonly unsubscribe$: Subject<boolean> = new Subject();
  activeBetsLength$: BehaviorSubject<number> = new BehaviorSubject(0);
  totalBalance$: BehaviorSubject<number> = new BehaviorSubject(null);
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly myBetsQuery: MyBetsQuery,
    private readonly myBetsService: MyBetsService,
    private readonly router: Router,
    private readonly appService: ApplicationService,
    private readonly blogService: BlogService,
    private readonly languageService: LanguageService,
    private readonly appConfigService: AppConfigService,
    private readonly location: Location,
    private readonly accountPaymentService: AccountPaymentService
  ) {}

  ngOnInit(): void {
    // this.isLoggedIn$
    //   .pipe(
    //     filter(isLoggedIn => isLoggedIn),
    //     takeUntil(this.unsubscribe$)
    //   )
    //   .subscribe(() => {
    //     this.myBetsService.getOpenBets();
    //   });
    this.getTime();
    this.timeInterval = setInterval(this.getTime.bind(this), 1000);
    this.isLoggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        this.userData$
          .pipe(
            takeUntil(this.unsubscribe$),
            map(res => res.wallets)
          )
          .subscribe(res =>
            res.filter(val => {
              if (val.isBonusWallet === false) {
                return this.totalBalance$.next(val.balance);
              }
            })
          );

        this.handleLoggedIn();
      } else {
        this.unsubscribe$.next(true);
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.timeInterval);
    this.unsubscribe$.next(true);
    this.unsubscribe$.unsubscribe();
  }

  @HostListener('window:message', ['$event']) onWindowMessage(event: any): void {
    this.handleIframeMessage(event);
  }

  private handleIframeMessage(e: any): void {
    const {
      data: { type, message }
    } = e;

    if (type === 'changed-category') {
      const newUrl: URL = new URL(window.location.href);
      message === 2 ? newUrl.searchParams.set('categoryId', message) : newUrl.searchParams.delete('categoryId');
      window.history.replaceState('', '', newUrl.toString());
    }
  }

  openLogin(): void {
    this.accountService.showLogin$.next(true);
  }

  openCasino(): void {
    const navigationOptions: UrlCreationOptions = window.location.href.includes('/slot?categoryId=2')
      ? { queryParams: { categoryId: 0 } }
      : { queryParams: null };
    const url = this.router.createUrlTree(['/slot'], navigationOptions).toString();
    window.location.href = url;
  }

  openAviator(): void {
    const aviatorGameId = this.appConfigService.get('aviatorGameId');
    const url = this.router.createUrlTree(['/slot'], { queryParams: { openGameId: aviatorGameId } }).toString();
    window.location.href = url;
  }

  openVirtual(): void {
    const url = this.router.createUrlTree(['/slot'], { queryParams: { categoryId: 2 } }).toString();
    window.location.href = url;
  }

  openInfos(): void {
    this.router.navigateByUrl('/help');
  }

  openPromos(): void {
    this.router.navigateByUrl('/menu/promocije');
  }

  openAZMenu(): void {
    this.appService.showAZMenu$.next(true);
  }

  openCoupon(): void {
    this.appService.showCoupon$.next(true);
  }

  openAccount(): void {
    this.appService.showAccountMenu$.next(true);
  }

  openMyBets(): void {
    if (!this.appService.isDesktop$.value) {
      if (window.location.pathname.includes('/my-bets')) {
        this.location.back();
        return;
      }
    }
    this.router.navigateByUrl('/my-bets');
  }

  openLiveChat(): void {
    // this.appService.menuMethodCalls.loadLiveChat();
  }
  openDepositPage(): void {
    // if (this.accountPaymentService.showingIframe$.value) {
    //   this.accountPaymentService.showingIframe$.next(false);
    // } else {
    //   this.router.navigate(['account/deposit']);
    // }
  }
  openWithdrawalPage(): void {
    this.router.navigate(['/account/withdrawal']);
  }
  isPageActive(url: string): boolean {
    if (url === '/menu/promos') {
      return window.location.pathname.startsWith(url);
    }
    if (window.location.pathname === '/slot') {
      const search = window.location.search;
      return search ? url.includes(search) : window.location.pathname === url;
    }
    return window.location.pathname === url;
  }

  doesPageStartsWith(path: string): boolean {
    return window.location.pathname.includes(path);
  }

  openBigBoyz(): void {
    window.open('https://www.bigboyz.rs/', '_blank');
  }

  openSelectLanguageMenu(): void {
    this.appService.showLanguagePicker$.next(true);
  }
  private handleLoggedIn() {
    this.myBetsService.getAllOpenBets(MyBetsTimePeriod.ALL_TIME);
    const interval$ = interval(60000);
    const openBetCountUpdated$ = this.openBetsCount$.pipe(
      startWith(() => new Date().getTime()),
      map(() => new Date().getTime())
    );
    interval$.pipe(withLatestFrom(openBetCountUpdated$), takeUntil(this.unsubscribe$)).subscribe(values => {
      const apiCacheTime = 30000; // api caches open bets for 30 seconds
      const lastUpdateTime = values[1];
      const time = new Date().getTime();
      const difference = time - lastUpdateTime;
      if (difference < apiCacheTime) {
        setTimeout(() => {
          this.myBetsService.getAllOpenBets(MyBetsTimePeriod.ALL_TIME);
        }, apiCacheTime - difference);
      } else {
        this.myBetsService.getAllOpenBets(MyBetsTimePeriod.ALL_TIME);
      }
    });

    this.openBetsCount$.subscribe(count => this.activeBetsLength$.next(count));
  }

  get isSrLanguage$(): Observable<boolean> {
    return this.languageService.selectedLanguage$.pipe(map(languageModel => languageModel.language === 'sr'));
  }

  get isEnLanguage$(): Observable<boolean> {
    return this.languageService.selectedLanguage$.pipe(map(languageModel => languageModel.language === 'en'));
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.accountQuery.isAuthenticated$;
  }

  get openBetsCount$(): Observable<number> {
    return this.myBetsQuery.allOpenBetCount$;
  }
  get isBlogVisible$(): Observable<boolean> {
    return this.blogService.config$.pipe(map(config => !!config?.visible));
  }

  private getTime(): void {
    this.time$.next(new Date().toLocaleTimeString());
  }
  get totalFunds$(): Observable<number> {
    return this.accountQuery.totalFunds$;
  }
  get userData$(): Observable<UserModel> {
    return this.accountQuery.userData$;
  }
}
