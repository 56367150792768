import { APIService } from './../../../../core/services/api.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { format, subYears } from 'date-fns';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { HelpService } from 'src/app/core/services/help.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { ProfileEditability, VerifyAccountType } from 'src/app/shared/models/account.model';
import { ButtonType } from 'src/app/shared/models/button.model';
import { CityModel, CountryModel, StateModel, TelephoneExtensionModel } from 'src/app/shared/models/location.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import { CurrencyModel, LongFormRegistrationModel } from 'src/app/shared/models/registration.model';
import { brandInfo } from 'src/brand-info';
import { APIType } from 'src/app/shared/models/api.model';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  editProfileForm: FormGroup;
  registrationDetailsLoaded$ = new BehaviorSubject<boolean>(false);
  showTelExtDropdownArrow = true;
  dobUpperLimit = format(subYears(new Date(), 18), 'yyyy-MM-dd');
  quickRegistration = this.appConfig.get('registration').registrationType === 'quick';
  profileEditability: ProfileEditability = this.appConfig.get('account').profileEditability;
  mobilePhoneEditability: boolean = this.appConfig.get('account').mobilePhoneEditability;
  verifyData: any;
  verifyAccountType: typeof VerifyAccountType = VerifyAccountType;
  brandInfo = brandInfo;
  useStandardVerification = this.accountQuery.userData.useStandardVerification.value;
  private readonly staticPasswordValidation: ValidatorFn[] = this.accountService.generatePasswordValidators();
  private readonly staticUsernameValidation: ValidatorFn[] = this.accountService.generateUsernameValidators();

  buttonType = ButtonType;
  changeUsernameLoading$ = new BehaviorSubject(false);
  changePasswordLoading$ = new BehaviorSubject(false);
  showPassword$ = new BehaviorSubject(false);

  dateOfBirth$: BehaviorSubject<string> = new BehaviorSubject('');

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly verifyAccountDataKey = 'verifyAccountData';

  constructor(
    readonly accountQuery: AccountQuery,
    readonly registrationQuery: RegistrationQuery,
    readonly accountService: AccountService,
    readonly applicationService: ApplicationService,
    readonly helpService: HelpService,
    private readonly registrationService: RegistrationService,
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly localStorage: LocalStorageService,
    private readonly apiService: APIService
  ) {}

  ngOnInit(): void {
    this.registrationService.getRegisterDetails().subscribe(res => {
      this.registrationDetailsLoaded$.next(true);
    });
    this.accountService.getUserData(this.accountQuery.accessToken);
    this.accountService.getKYCStatus(this.accountQuery.accessToken).subscribe(() => {
      const KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
      if (KYCDocumentsStatus === 'documentsNeeded' || KYCDocumentsStatus === 'accountNumberNeeded' || KYCDocumentsStatus === 'pending') {
        this.accountService.getBlinkingSessionStatus(this.accountQuery.accessToken).subscribe(() => {
          this.checkFormControls();
        });
      }
    });

    this.verifyData = this.localStorage.retrieve(this.verifyAccountDataKey);

    this.registrationService.initService();
    this.registrationService.getCurrencies();
    this.registrationService.getCountries();

    this.editProfileForm = this.initialiseForm();

    this.registrationQuery.countries$.pipe(takeUntil(this.destroy$)).subscribe(s => {
      if (s !== undefined && s.length === 1) {
        this.editProfileForm.controls.country.setValue(s[0].threeLetterCode);
      }
    });

    this.initialiseListeners();

    this.accountQuery.error$.pipe(takeUntil(this.destroy$)).subscribe(err => {
      if (err) {
        const callback = () => {
          this.accountService.clearError();
        };
        this.notificationService.showNotification(
          new NotificationSettings({
            allowBackdropClose: true,
            closeButtonCallback: callback,
            confirmButtonCallback: callback,
            contentText: translate('Something went wrong! Please try again or contact support.'),
            type: 'error',
            showConfirmButton: true
          })
        );
      }
    });
  }

  onCancel(): void {
    this.editProfileForm.reset();
    this.editProfileForm = this.initialiseForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.registrationService.clearOnDestroy();
  }

  startBlinkingSession(): void {
    this.registrationService.getRegisterDetails().subscribe(() => {
      if (this.registrationQuery.blinkingServiceEnabled) {
        const KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
        let session;
        switch (KYCDocumentsStatus) {
          case 'documentsNeeded':
            session = 1;
            break;
          case 'accountNumberNeeded':
            session = 2;
            break;
          default:
            session = -1;
            break;
        }

        if (session !== -1) {
          this.registrationService.startBlinkingSession(session).subscribe();
        }
      }
    });
  }

  setMaxDate(): void {
    if (format(new Date(this.editProfileForm.controls.dateOfBirth.value), 'yyyy-MM-dd') === format(new Date('1900-01-01'), 'yyyy-MM-dd')) {
      this.editProfileForm.controls.dateOfBirth.setValue(this.accountQuery.dobUpperLimit);
    }
  }

  toggleCheckbox(event: any): void {
    const checkbox = event.target.parentElement.querySelector('input[type="checkbox"]');
    this.editProfileForm.controls[checkbox.getAttribute('formcontrolname')].setValue(checkbox.checked ? false : true);
    this.editProfileForm.controls[checkbox.getAttribute('formcontrolname')].markAsDirty();
    checkbox.value = checkbox.checked ? undefined : 'checked';
    this.onSubmit();
  }

  toggleRadioButton(event: any): void {
    const radio = event.target.parentElement.querySelector('input[type="radio"]');
    if (!radio.disabled) {
      radio.checked = radio.checked ? false : true;
      this.editProfileForm.controls[radio.getAttribute('formcontrolname')].setValue(radio.value);
      this.editProfileForm.controls[radio.getAttribute('formcontrolname')].markAsDirty();
    }
  }

  trackByCurrency(index: number, item: CurrencyModel): string {
    return item.isoCode;
  }

  trackByTelExt(index: number, item: TelephoneExtensionModel): string {
    return item.countryCode;
  }

  trackByCountry(index: number, item: CountryModel): string {
    return item.threeLetterCode;
  }

  trackByState(index: number, item: StateModel): string {
    return item.code;
  }

  trackByCity(index: number, item: CityModel): string {
    return item.id;
  }

  resetPassword(): void {
    this.router.navigate(['account/password-reset']);
  }
  changePassword(): void {
    const password = this.editProfileForm.controls?.password?.value;
    if (password.length === 0) {
      this.editProfileForm.controls?.password.markAsTouched();
      this.editProfileForm.controls?.password.setErrors({ required: true });
      return;
    }
    if (!this.editProfileForm.controls?.password.valid) {
      return;
    }
    this.changePasswordLoading$.next(true);

    this.apiService
      .post(APIType.Platform, 'api/Security/Accounts/OverridePassword', { NewPassword: password })
      .pipe(
        catchError((error: any) => {
          console.log(error);
          this.notificationService.showNotification(
            new NotificationSettings({
              allowBackdropClose: true,
              contentText: translate('Error Updating User.'),
              type: 'error',
              toast: true,
              timeout: 2000
            })
          );
          this.changePasswordLoading$.next(false);
          return throwError(error);
        })
      )
      .subscribe(res => {
        this.changePasswordLoading$.next(false);
        this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
        this.notificationService.showNotification(
          new NotificationSettings({
            allowBackdropClose: true,
            contentText: translate('Lozinka promenjena.'),
            type: 'success',
            toast: true,
            timeout: 2000
          })
        );
        console.log(res);
      });
  }

  togglePasswordVisibility(): void {
    this.showPassword$.next(!this.showPassword$.value);
  }

  changeUsername(): void {
    const username = this.editProfileForm.controls?.username?.value;
    if (username.length === 0) {
      this.editProfileForm.controls?.username.setErrors({ required: true });
      return;
    }
    if (!this.editProfileForm.controls?.username.valid) {
      return;
    }
    this.changeUsernameLoading$.next(true);
    this.apiService
      .post(APIType.Platform, 'api/Security/Accounts/OverrideUserName', { NewUserName: username })
      .pipe(
        catchError((error: any) => {
          console.log(error);
          this.changeUsernameLoading$.next(false);
          this.notificationService.showNotification(
            new NotificationSettings({
              allowBackdropClose: true,
              contentText: translate('Error Updating User'),
              type: 'error',
              toast: true,
              timeout: 2000
            })
          );
          return throwError(error);
        })
      )
      .subscribe(res => {
        this.changeUsernameLoading$.next(false);
        this.accountService.getUserData(this.accountQuery.accessToken).subscribe();

        this.notificationService.showNotification(
          new NotificationSettings({
            allowBackdropClose: true,
            contentText: translate('Korisničko ime promenjeno.'),
            type: 'success',
            toast: true,
            timeout: 2000
          })
        );
        console.log(res);
      });
  }

  modifyPhoneNumber(): void {}

  onSubmit(): void {
    let user: LongFormRegistrationModel;

    if (this.accountService.getKYCDocumentsStatus() !== 'documentsNeeded') {
      user = new LongFormRegistrationModel({
        consent: this.editProfileForm.controls.consent.value
      });
    } else {
      user = new LongFormRegistrationModel({
        consent: this.editProfileForm.controls.consent.value
      });
    }

    this.accountService
      .editProfile(user)
      .pipe(
        // tslint:disable-next-line: cyclomatic-complexity
        tap(() => {
          this.editProfileForm.markAsPristine();
        }),
        switchMap(() => this.accountService.updateUserProfile(this.accountQuery.accessToken)),
        finalize(() => {
          this.notificationService.showSuccessMessage(translate('Profile updated succesfully'));
        })
      )
      .subscribe();
  }

  passwordContainsUsername =
    (): ValidatorFn =>
    (control: AbstractControl): { containsUsername: any } => {
      let username: string;

      username = this.accountQuery.userData.username;
      if (username && control.value.toLowerCase().indexOf(username.toLowerCase()) !== -1) {
        return { containsUsername: true };
      }
      return undefined;
    };

  private checkFormControls(): void {
    const KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
    if (KYCDocumentsStatus !== 'documentsNeeded') {
      this.editProfileForm.controls.email.disable();
      this.editProfileForm.controls.firstName.disable();
      this.editProfileForm.controls.lastName.disable();
      this.editProfileForm.controls.gender.disable();
      this.editProfileForm.controls.dateOfBirth.disable();
      this.editProfileForm.controls.country.disable();
      this.editProfileForm.controls.city.disable();
      this.editProfileForm.controls.address.disable();
      this.editProfileForm.controls.bankAccountNumber.disable();
      this.editProfileForm.controls.citizenIdNumber.disable();
    }
  }

  private initialiseForm(): FormGroup {
    if (this.profileEditability === ProfileEditability.Always) {
      if (this.accountQuery.userData.address.countryCode) {
        this.registrationService.getStates(this.accountQuery.userData.address.countryCode);

        if (this.accountQuery.userData.address.state) {
          this.registrationService
            .getCities(this.accountQuery.userData.address.countryCode, this.accountQuery.userData.address.state)
            .subscribe();
        }
      }
    }

    this.dateOfBirth$.next(format(new Date(this.accountQuery.userData.dateOfBirth), 'dd.MM.yyyy.'));

    return new FormGroup({
      userId: new FormControl({ value: this.accountQuery.userData.id, disabled: true }),
      username: new FormControl({ value: this.accountQuery.userData.username, disabled: false }, this.staticUsernameValidation),
      email: new FormControl(
        {
          value: this.accountQuery.userData.email,
          disabled: true
        },
        this.accountService.generateEmailValidators()
      ),
      mobile: new FormControl(
        {
          value: this.accountQuery.userData?.mobile?.mobileNumber,
          disabled: true
        },
        this.accountService.generateEmailValidators()
      ),
      password: new FormControl({ value: '', disabled: false }, this.staticPasswordValidation.concat([this.passwordContainsUsername()])),
      consent: new FormControl(this.accountQuery.userData.marketingConsent.value),
      currency: new FormControl({ value: this.accountQuery.userData.currency.name, disabled: true }),
      firstName: new FormControl(
        {
          value: this.accountQuery.userData.name,
          disabled: true
        },
        this.accountService.generateLettersOnlyValidators()
      ),
      lastName: new FormControl(
        {
          value: this.accountQuery.userData.surname,
          disabled: true
        },
        this.accountService.generateLettersOnlyValidators()
      ),
      gender: new FormControl(
        {
          value: this.accountQuery.userData.gender,
          disabled: true
        },
        this.accountService.generateGenderValidators()
      ),
      dateOfBirth: new FormControl(
        {
          value: format(new Date(this.accountQuery.userData.dateOfBirth), 'yyyy-MM-dd'),
          disabled: true
        },
        this.accountService.generateDateValidators()
      ),
      country: new FormControl(this.accountQuery.userData.address.countryCode),
      city: new FormControl(
        {
          value: this.accountQuery.userData.address.cityCode,
          disabled: true
        },
        this.accountService.generateLettersOnlyValidators()
      ),
      address: new FormControl(
        {
          value: this.accountQuery.userData.address.addressLine,
          disabled: true
        },
        this.accountService.generateLettersOnlyValidators()
      ),
      citizenIdNumber: new FormControl(
        {
          value: this.accountQuery.userData.citizenIdNumber.value,
          disabled: true
        },
        this.accountService.generateDigitsOnlyAndLengthValidators(13, 13)
      ),
      bankAccountNumber: new FormControl(
        {
          value: this.accountQuery.userData.bankAccountDetails ? this.accountQuery.userData.bankAccountDetails.AccountNumber : '',
          disabled: true
          // disabled: this.disableFormControl(
          //    this.accountQuery.userData.bankAccountDetails ? this.accountQuery.userData.bankAccountDetails.AccountNumber : ''
          // )
        },
        this.accountService.generateDigitsOnlyAndLengthValidators(18, 20)
      )
    });
  }

  private disableFormControl(value: any): boolean {
    const KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
    if (KYCDocumentsStatus !== 'documentsNeeded') {
      return true;
    } else {
      switch (this.profileEditability) {
        case ProfileEditability.OneTime:
          // Only enable if item does not have a value.
          return !(value === null || value === undefined || value.length === 0 || value === 0);
        case ProfileEditability.Always:
          return false;
        case ProfileEditability.Off:
        default:
          return true;
      }
    }
  }

  private disableDateFormControl(date: any): boolean {
    const KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
    if (KYCDocumentsStatus !== 'documentsNeeded') {
      return true;
    } else {
      switch (this.profileEditability) {
        case ProfileEditability.OneTime:
          // Only enable if item does not have a value.
          return !(
            date === null ||
            date === undefined ||
            new Date(date).getTime() === 0 ||
            format(new Date(date), 'yyyy-MM-dd') === format(new Date('1900-01-01'), 'yyyy-MM-dd')
          );
        case ProfileEditability.Always:
          return false;
        case ProfileEditability.Off:
        default:
          return true;
      }
    }
  }

  private initialiseListeners(): void {
    this.dropdownDisabling();
  }

  private dropdownDisabling(): void {
    this.registrationQuery.countries$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.disableFormControl(this.editProfileForm.controls.country.value)) {
        this.editProfileForm.controls.country.disable();
      }
    });
  }
}
