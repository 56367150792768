<div class="legend-table-container">
  <div class="header" transloco="Outcome legend"></div>
  <div class="legends">
    <div class="legend">
      <span class="legend-icon in-progress">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </span>
      <span class="legend-name" transloco="Running"></span>
    </div>
    <div class="legend">
      <span class="legend-icon won"></span>
      <span class="legend-name" transloco="Won"></span>
    </div>
    <div class="legend">
      <span class="legend-icon partially-won"></span>
      <span class="legend-name" transloco="Partially Won"></span>
    </div>
    <div class="legend">
      <span class="legend-icon lost"></span>
      <span class="legend-name" transloco="Lost"></span>
    </div>
    <div class="legend">
      <span class="legend-icon cancelled"></span>
      <span class="legend-name" transloco="Cancelled"></span>
    </div>
    <div class="legend">
      <span class="legend-icon void"></span>
      <span class="legend-name" transloco="Void"></span>
    </div>
    <div class="legend">
      <span class="legend-icon in-evaluation"></span>
      <span class="legend-name" transloco="Evaluation"></span>
    </div>
    <div class="legend">
      <span class="legend-icon cashout"></span>
      <span class="legend-name" transloco="Cashout"></span>
    </div>
  </div>
</div>
