import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, merge } from 'rxjs';
import { RecentSearchesService } from 'src/app/core/services/recent-searches.service';
import { SearchState } from 'src/app/shared/models/search.model';
import { SearchStore } from './search.store';

@Injectable({ providedIn: 'root' })
export class SearchQuery extends Query<SearchState> {
  searchResults$ = this.select(state => state.searchResults);
  searchResultsSportModel$ = this.select(state => state.searchResults.data.map(sr => sr.toSportMatchModel()));
  searchKeyword$ = this.select(state => state.searchKeyword);
  noSearchResultsFor$ = this.select(state => state.ui.noSearchResultsFor);
  lastBadgeClicked$ = this.select(state => state.ui.lastBadgeClicked);
  searchIsLoading$ = this.select(state => state.ui.searchIsLoading);
  recentSearches$ = merge(
    new BehaviorSubject(this.localStorage.retrieve(this.recentSearchesService.recentSearchesKey)),
    this.localStorage.observe(this.recentSearchesService.recentSearchesKey)
  );

  constructor(
    protected store: SearchStore,
    private readonly recentSearchesService: RecentSearchesService,
    private readonly localStorage: LocalStorageService
  ) {
    super(store);
  }

  get noSearchResultsFor(): string {
    return this.getValue().ui.noSearchResultsFor;
  }
}
