<div class="account-menu-container" *ngIf="accountQuery.isAuthenticated$ | async">
  <div class="main-content" [class.authenticated]="accountQuery.isAuthenticated$">
    <app-card [canCollapse]="false" [titleHeaderText]="'Account Overview' | transloco">
      <div class="container account-overview" [@fadeIn] body>
        <div class="content">
          <span *ngIf="!quickRegistration" class="label" transloco="Username:"></span>
          <span *ngIf="quickRegistration" class="label" transloco="Phone Number:"></span>
          <span class="value uppercase strong">{{ accountQuery.userData.username }}</span>
        </div>
        <div class="content">
          <span class="label" transloco="UserID:"></span>
          <span class="value">{{ accountQuery.userData.id }}</span>
        </div>
        <div class="content" *ngFor="let wallet of (accountQuery.userData$ | async).wallets">
          <span class="label">{{ wallet.name }}: </span>
          <span class="value">{{ wallet.balance | currencyFormat }} </span>
        </div>
        <div class="content">
          <span class="label" transloco="Total Funds:"></span>
          <span class="value text-highlighted">{{ accountQuery.totalFunds$ | async | currencyFormat }} </span>
        </div>
        <div class="user-details">
          <div class="user-details-text">
            <span class="label">{{ accountQuery.userData.name }} {{ accountQuery.userData.surname }}</span>
            <span class="label">{{ 'Last Login:' | transloco }} {{ loginDate }}</span>
          </div>
          <app-button (click)="logout()" [category]="'Logout Button'" [click]="true" [text]="'Log Out' | transloco"
            analytics class="logout-btn">
          </app-button>
        </div>
      </div>
    </app-card>

    <app-card [canCollapse]="false" [titleHeaderText]="'Account Settings' | transloco">
      <div class="container split" [@fadeIn] body>
        <ng-container *ngFor="let item of menuItems$ | async; trackBy: menuItemTrackBy">
          <div *ngIf="
              (item.visibleToTheseUserTypes.length === 0 ||
              item.visibleToTheseUserTypes.includes((accountQuery.userData$ | async).userTypeCode)) 
             
            " class="links-content">
            <ng-container [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="linkButton"></ng-container>
          </div>
        </ng-container>
      </div>
    </app-card>

    <app-card [canCollapse]="false" [titleHeaderText]="'Contact & Help' | transloco">
      <div class="container split" [@fadeIn] body>
        <div *ngFor="let item of accountQuery.helpMenuItems$ | async; trackBy: menuItemTrackBy" class="links-content">
          <ng-container [ngTemplateOutletContext]="{ item: item }" [ngTemplateOutlet]="linkButton"></ng-container>
        </div>
      </div>
    </app-card>
  </div>
</div>

<ng-template #linkButton let-item="item">
  <button (click)="navigateToRoute(item.link, item.isLocked)" [category]="'Account Link'" [class.locked]="item.isLocked"
    [class.warning]="item.showWarningIcon" [click]="true" [label]="item.text" analytics class="link-btn">
    <div *ngIf="item.isCustomIcon" [appDynamicSvgBackground]="'images/account/' + item.iconFontValue"
      class="custom-icon"></div>
    <ng-container *ngIf="!item.isCustomIcon">
      <i [attr.data-message-count]="item.iconFontValue === 'fa-comment' && messageCount$ | async" aria-hidden="true"
        class="btn-icon fa fa-{{ item.iconFontValue }}"></i>
    </ng-container>
    <span class="btn-text">{{ item.text }}</span>
    <i *ngIf="
        item.showWarningIcon && !(accountNumberConfirmed$ | async)
      " aria-hidden="true" class="fa warning fa-clock-o"></i>
    <i class="btn-icon arrow-icon fa fa-angle-right" aria-hidden="true"></i>
  </button>
</ng-template>