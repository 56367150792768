import { Injectable } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private readonly queue$: BehaviorSubject<number>;

  constructor(
    private readonly applicationStore: ApplicationStore,
    private readonly applicationQuery: ApplicationQuery,
    private readonly router: Router
  ) {
    this.queue$ = new BehaviorSubject<number>(0);
  }

  /**
   * Subscribe to route change events to automatically show/hide the
   * loading spinner
   */
  initialize(): void {
    this.applicationStore.updateLoader({ noDelay: true });

    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        // When switching route always reset the $queue value to 0
        // to cater for cases when dequeueLoader() is not called due
        // to components being destroyed
        this.enqueueLoader(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.dequeueLoader();
      }
    });

    this.queue$.subscribe(queueCount => {
      this.applicationStore.updateLoader({ show: queueCount > 0 });
    });
  }

  /**
   * Increment the loader queue count
   * @param resetCount If true, the queue count will be reset to 0 before incrementing it
   */
  enqueueLoader(resetCount: boolean = false): void {
    const queueCount = resetCount ? 0 : this.queue$.value;
    this.queue$.next(queueCount + 1);
  }

  /**
   * Decrement the loader queue count
   */
  dequeueLoader(): void {
    const queueCount = this.queue$.value - 1;
    this.queue$.next(queueCount > 0 ? queueCount : 0);

    if (this.applicationQuery.loaderState.noDelay) {
      this.applicationStore.updateLoader({ noDelay: false });
    }
  }
}
