<ng-container *ngIf="couponQuery.couponData && couponQuery.couponData.Odds.length > 0">
  <div class="coupon-wrapper">
    <div class="main-content" [ngClass]="{ authenticated: (accountQuery.isAuthenticated$ | async) }">
      <app-coupon-selections></app-coupon-selections>

      <app-coupon-totals [couponData]="couponQuery.couponDataFiltered$ | async"></app-coupon-totals>

      <div class="buttons" [class.disabled]="(canPostCoupon$ | async) === false">
        <ng-container *ngIf="(hasOddChanges$ | async) === false || (couponQuery.couponSettings$ | async).allowOddChanges">
          <div class="setting">
            <app-toggle-switch [switch]="saveDefaultStake" [customClass]="'smaller'" (click)="updateDefaultStake()"> </app-toggle-switch>

            <div class="label" transloco="Save as default quickbet stake"></div>
          </div>

          <div class="place-bet" (click)="postCoupon()">
            {{ 'Place Bet Now' | transloco }}
            <i class="fa fa-caret-right"></i>
          </div>
        </ng-container>

        <ng-container *ngIf="(hasOddChanges$ | async) && (couponQuery.couponSettings$ | async).allowOddChanges === false">
          <div
            class="message"
            transloco="Some of the selected odds have changed. Please accept the changes to proceed or remove the items."
          ></div>
          <app-button
            text="Accept"
            [customButtonStyle]="actionButtonStyle"
            [buttonType]="buttonType.Success"
            (click)="acceptOddChanges()"
          ></app-button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #betInProgress>
  <app-loading-bar [show]="true" [transparent]="true"></app-loading-bar>
  <div class="loading-text" transloco="Placing a bet..."></div>
</ng-template>
