import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AccountQuery } from 'src/app/core/state/account/account.query';

import { BehaviorSubject } from 'rxjs';

import { VariablesService } from 'src/app/core/services/variables.service';
import { expandCollapse } from 'src/app/shared/animations';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'account-single-bonus',
  templateUrl: './single-bonus.component.html',
  styleUrls: ['./single-bonus.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [expandCollapse()]
})
export class SingleBonusComponent implements OnInit {
  url: SafeResourceUrl;
  isShown$ = new BehaviorSubject<boolean>(false);
  constructor(
    readonly accountQuery: AccountQuery,
    readonly variablesService: VariablesService,
    private readonly sanitizer: DomSanitizer,
    private readonly appConfig: AppConfigService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    this.setIframeUrl();
  }

  @HostListener('window:message', ['$event']) onWindowMessage(event: any): void {
    this.handleIframeMessage(event);
  }

  private setIframeUrl() {
    const iframeUrl = this.appConfig.get('bombBonusUrl');
    console.log(iframeUrl);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`${iframeUrl}?token=${this.accountQuery.accessToken}`);
  }

  private navigateTo(link: string): void {
    this.router.navigate([link]);
  }

  private handleIframeMessage(e: {
    data: {
      type: string;
      message: any;

    }
  }): void {
    const { data: { type } } = e;
    if (type === 'go-to-slot') {
      this.navigateTo('slot');
    }
    else if (type === 'deposit') {
      this.navigateTo('/account/deposit');
    }
    else if (type === 'show-bonus') {
      this.isShown$.next(true);
    }
  }
}
