<div [class.withdrawal]="page === 'withdrawal'" class="upload-documents-container">
  <div class="upload-documents-form">
    <div *ngIf="page === 'edit-profile'" class="header-title">
      <span class="label">
        <span transloco="Documentation"></span>
        <span class="asterisk"></span>
      </span>
      <span class="sub-label"
        transloco="You will need to upload 1 or 2 images (depending on the type of the document): Both sides of your ID card or passport.">
      </span>
    </div>
    <div *ngIf="page === 'withdrawal'" class="header-title">
      <span class="label"
        transloco="Please provide a selfie photo and a valid document stating your name and surname, along side your bank account number. Example: a bank statement / a photo of your bank card with the account number.">
      </span>
      <span class="sub-label"
        transloco="IMPORTANT: If you are using a credit or a debit card for your bank account number verification, the image you provide needs to preview only your bank account number, your name and surname. Please cover the 16-digit number on your bank card.">
      </span>
    </div>
    <div [class.has-errors]="(errorMessage$ | async)" class="field-input-cover">
      <label class="file-button-cover">
        <i class="fa fa-cloud-upload"></i>
        <span class="file-text" transloco="Drag and drop or browse files to upload"></span>
        <input (change)="uploadFile($event)"
                class="upload-files"
                multiple
                name="uploadFiles[]"
                type="file">
      </label>
      <span *ngIf="(errorMessage$ | async)" class="error-message">{{errorMessage$ | async}}</span>
      <ul class="file-list">
        <li *ngFor="let file of uploadFiles$ | async trackBy: indexTrackBy" [class.visible]="!file.uploadLoader">
          <div [class.has-thumbnail]="file.uploadedDetails.thumbnail" class="file-icon">
            <div *ngIf="file.uploadLoader" class="loader"></div>
            <div *ngIf="!file.uploadLoader && file.uploadedDetails.thumbnail" [ngStyle]="{'background-image': 'url(' + file.uploadedDetails.thumbnail + ')'}" class="thumbnail"></div>
            <div *ngIf="!file.uploadLoader && !file.uploadedDetails.thumbnail" class="no-image">
              <i class="fa fa-file-o"></i>
              <span>{{file.localDetails.type}}</span>
            </div>
          </div>
          <div class="file-name">
            <span class="file-name-text">{{file.localDetails.name}}</span>
            <div class="file-progress-total"></div>
            <div [ngStyle]="{'width': file.progressBar + '%'}" class="file-progress"></div>
            <span class="file-size-text">{{ file.localDetails.size | formatBytes }}</span>
          </div>
          <span *ngIf="!file.uploadLoader" (click)="deleteFile(file.id)" [class.loader]="file.deleteLoader"
              [title]="'Delete File' | transloco" class="delete-file" transloco="×"></span>
        </li>
      </ul>
    </div>
  </div>
  <div class="button-container">
    <app-button (click)="submitFiles()" [disabled]="uploadInProgress$ | async" [buttonType]="buttonType.Brand" [text]="'Submit Documents' | transloco"></app-button>
  </div>
</div>
<app-loading-bar [show]="showLoader$ | async"></app-loading-bar>
