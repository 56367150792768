import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AppConfigService } from './../services/app-config.service';

/**
 * This guard ensures the user will enter the appropriate registration
 * function as is configured for the brand.
 */
@Injectable()
export class RegistrationRouteGuard implements CanActivate {
  constructor(private readonly appConfigService: AppConfigService, private readonly router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    try {
      switch (this.appConfigService.get('registration').registrationType.toLowerCase()) {
        case 'normal':
          if (state.url === '/register' || state.url === '/register/long-form') {
            return true;
          }
          this.router.navigate(['/register']);
          break;
        case 'redirect':
          this.router.navigate([this.appConfigService.get('registration').registrationRedirect]);
          break;
        case 'quick':
          if (state.url === '/register/quick') {
            return true;
          }

          this.router.navigate(['/register/quick']);
          break;
        default:
          this.router.navigate(['/']);
      }
      return false;
    } catch {
      this.router.navigate(['/']);
      return false;
    }
  }
}
