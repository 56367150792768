<div (click)="toggleFavourite()" class="favourite-icon">
  <svg *ngIf="!isFavourite" fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m11 15.83 3.804 2.302c.697.421 1.55-.202 1.366-.99l-1.008-4.327L18.526 9.9c.614-.532.284-1.54-.523-1.604l-4.427-.376-1.732-4.088c-.312-.743-1.375-.743-1.687 0L8.424 7.91l-4.427.376C3.19 8.35 2.86 9.359 3.474 9.89l3.364 2.915-1.008 4.327c-.183.788.67 1.411 1.366.99L11 15.83Z"
      fill="#fff"
      opacity=".4"
    />
  </svg>
  <svg *ngIf="isFavourite" fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m11 15.83 3.804 2.302c.697.421 1.55-.202 1.366-.99l-1.008-4.327L18.526 9.9c.614-.532.284-1.54-.523-1.604l-4.427-.376-1.732-4.088c-.312-.743-1.375-.743-1.687 0L8.424 7.91l-4.427.376C3.19 8.35 2.86 9.359 3.474 9.89l3.364 2.915-1.008 4.327c-.183.788.67 1.411 1.366.99L11 15.83Z"
      fill="#FFC95F"
    />
  </svg>
</div>
