import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { PasswordResetTokenValidationModel } from 'src/app/shared/models/account.model';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  token: string;
  username: string;
  oneTimePassword: string;
  tokenStatus: 'success' | 'failed';
  tokenValidationResult$: BehaviorSubject<PasswordResetTokenValidationModel> = new BehaviorSubject(undefined);
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentPassword: FormControl = new FormControl('');
  newPasswordForm: FormGroup;
  passwordResetSuccess$ = new BehaviorSubject(undefined);
  currentPasswordError$ = new BehaviorSubject(undefined);
  isAuthenticatedStaticValue = this.accountQuery.isAuthenticated;

  showPassword$ = new BehaviorSubject<boolean>(false);

  constructor(
    public accountQuery: AccountQuery,
    public applicationService: ApplicationService,
    public applicationQuery: ApplicationQuery,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly accountService: AccountService,
    private readonly notificationService: NotificationService,

    private readonly appConfig: AppConfigService
  ) {
  }

  createFormGroup(): FormGroup {
    return new FormGroup(
      {
        newPassword: new FormControl('', this.accountService.generatePasswordValidators()),
        confirmNewPassword: new FormControl('', this.accountService.generatePasswordValidators())
      },
      {
        validators: [this.passwordConfirming, this.passwordContainsUsername]
      }
    );
  }

  passwordConfirming = (control: AbstractControl): { mismatch: any } => {
    if (control.get('newPassword').value !== control.get('confirmNewPassword').value) {
      return { mismatch: true };
    }
  };

  passwordContainsUsername = (control: AbstractControl): { containsUsername: any } => {
    let username: string;
    if (this.accountQuery.userData) {
      username = this.accountQuery.userData.username;
    } else if (this.tokenValidationResult$.value) {
      username = this.tokenValidationResult$.value.tokenOwnerName;
    }

    if (username && control.get('newPassword').value.toLowerCase().indexOf(username.toLowerCase()) !== -1) {
      return { containsUsername: true };
    }
  };

  onSubmit(): void {
    if (!this.accountQuery.isAuthenticated) {
      if (this.username && this.oneTimePassword) {
        this.accountService
          .postResetPasswordBySms(this.username, this.oneTimePassword, this.newPasswordForm.value.newPassword)
          .subscribe(() => {
            this.passwordResetSuccess$.next(true);

            this.accountService.login(this.username, this.newPasswordForm.value.newPassword).subscribe(() => {
              this.notificationService.showSuccessMessage(translate('Your password has been successfully updated.'));
              this.router.navigate(['/']);
            });
          });
      } else {
        this.accountService.postResetPassword(this.token, this.newPasswordForm.value.newPassword).subscribe(() => {
          this.passwordResetSuccess$.next(true);
          this.accountService
            .login(this.tokenValidationResult$.value.tokenOwnerName, this.newPasswordForm.value.newPassword, false, 'password')
            .subscribe(() => {
              this.notificationService.showSuccessMessage(translate('Your password has been successfully updated.'));
              this.router.navigate(['/']);
            });
        });
      }
    } else {
      this.accountService.changePassword(this.currentPassword.value, this.newPasswordForm.get('confirmNewPassword').value).subscribe(
        () => {
          this.passwordResetSuccess$.next(true);
          this.accountService.login(this.accountQuery.userData.username, this.newPasswordForm.value.newPassword, false, 'password').subscribe(() => {
            this.notificationService.showSuccessMessage(translate('Your password has been successfully updated.'));
            this.router.navigate(['/']);
          });
        },
        error => {
          if (error.error.SystemMessage === 'Passwords do not match!') {
            this.currentPasswordError$.next(true);
          }
          if (error && error.error && (error.error.ResponseMessage || error.error.SystemMessage)) {
            this.notificationService.showErrorMessage((error.error.ResponseMessage || error.error.SystemMessage));
          }
        }
      );
    }
  }

  closeLogin(): void {
    this.applicationService.updateUI({
      showLogin: false
    });
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  ngOnInit(): void {
    // If someone hit the /password-reset/:token url, he must come from the password recovery email
    // What's have a token to validate
    // If someone hit the /password-reset, he must be logged in and don't need a token to verify

    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (this.token) {
      this.accountService
        .validatePasswordReset(this.token)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          response => {
            this.tokenStatus = 'success';
            const tokenValidation = new PasswordResetTokenValidationModel({
              isValid: response.Result.IsValid,
              tokenOwnerName: response.Result.TokenOwnerName
            });
            this.tokenValidationResult$.next(tokenValidation);

            this.newPasswordForm = this.createFormGroup();
          },
          error => {
            this.tokenStatus = 'failed';
            const tokenValidation = new PasswordResetTokenValidationModel({
              isValid: error.error.Result.IsValid,
              tokenOwnerName: error.error.Result.TokenOwnerName,
              expired: error.error.SystemMessage === 'Reset password token has expired' ? true : false
            });
            this.tokenValidationResult$.next(tokenValidation);

            this.newPasswordForm = this.createFormGroup();
          }
        );
    } else {
      // If someone hit the /password-reset/:username/:onetimepassword url, he must come from the OTP process
      this.username = this.activatedRoute.snapshot.paramMap.get('username');
      this.oneTimePassword = this.activatedRoute.snapshot.paramMap.get('onetimepassword');

      if (this.username && this.oneTimePassword) {
        this.tokenStatus = 'success';
        const tokenValidation = new PasswordResetTokenValidationModel({
          isValid: 'true',
          tokenOwnerName: this.username
        });
        this.tokenValidationResult$.next(tokenValidation);

        this.newPasswordForm = this.createFormGroup();
      } else {
        this.newPasswordForm = this.createFormGroup();
      }
    }


  }

  togglePasswordVisibility(): void {
    this.showPassword$.next(!this.showPassword$.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
