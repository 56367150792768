import { NgModule } from '@angular/core';
import { AccountStatementResultsComponent } from 'src/app/modules/account/components/account-statement/components/account-statement-results/account-statement-results.component';
import { AccountStatementSearchComponent } from 'src/app/modules/account/components/account-statement/components/account-statement-search/account-statement-search.component';
import { ChangeBankAccountNumberComponent } from 'src/app/modules/account/components/change-bank-account-number/change-bank-account-number.component';
import { ChangePhoneNumberComponent } from 'src/app/modules/account/components/change-phone-number/change-phone-number.component';
import { DepositSuccessComponent } from 'src/app/modules/account/components/deposit/deposit.success.component';
import { EditProfileComponent } from 'src/app/modules/account/components/edit-profile/edit-profile.component';
import { PaymentCompletedComponent } from 'src/app/modules/account/components/payment-completed/payment-completed.component';
import { SpinePayPaymentCompletedComponent } from 'src/app/modules/account/components/spinepay-payment-completed/spinepay-payment-completed.component';
import { PhoneNumberVerificationComponent } from 'src/app/modules/account/components/phone-number-verification/phone-number-verification.component';
import { UploadDocumentsComponent } from 'src/app/modules/account/components/upload-documents/upload-documents.component';
import { VerificationChoiceComponent } from 'src/app/modules/account/components/verification-choice/verification-choice.component';
import { SharedModule } from 'src/app/shared/shared.module';

import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { AccountPageWrapperComponent } from './components/account-page-wrapper/account-page-wrapper.component';
import { AccountStatementComponent } from './components/account-statement/account-statement.component';
import { BetSearchFromComponent } from './components/bet-search-wrapper/bet-search-form/bet-search-form.component';
import { BetSearchResultsComponent } from './components/bet-search-wrapper/bet-search-results/bet-search-results.component';
import { BetSearchWrapperComponent } from './components/bet-search-wrapper/bet-search-wrapper.component';
import { BankTransferComponent } from './components/deposit/components/bank-transfer/bank-transfer.component';
import { DepositComponent } from './components/deposit/deposit.component';
import { FundTransferComponent } from './components/fund-transfer/fund-transfer.component';
import { LoginPageWrapperComponent } from './components/login-page-wrapper/login-page-wrapper.component';
import { LoginComponent } from './components/login/login.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PaymentsListComponent } from './components/payments-list/payments-list.component';
import { PaymentsListPromoComponent } from 'src/app/modules/account/components/payment-list-promo/payments-list-promo.component';
import { PaymentPromoPopupComponent } from './components/payment-promo-popup/payments-promo-popup.component';
import { SavedCardsComponent } from './components/saved-cards/saved-cards.component';
import { WithdrawalComponent } from './components/withdrawal/withdrawal.component';
import { PaymentsSharedPromoComponent } from './components/payment-shared-promo/payments-shared-promo.component';
import { SpinepayError } from 'src/app/modules/account/components/spinepay-payment-completed/spinepay-error/spinepay-error.component';
import { FreeSpinsAwardPopupComponent } from 'src/app/modules/account/components/free-spins-award-popup/free-spins-award-popup.component';

const COMPONENTS = [
  AccountComponent,
  AccountMenuComponent,
  AccountPageWrapperComponent,
  AccountStatementComponent,
  AccountStatementResultsComponent,
  AccountStatementSearchComponent,
  BetSearchFromComponent,
  BetSearchResultsComponent,
  BetSearchWrapperComponent,
  DepositComponent,
  DepositSuccessComponent,
  PaymentCompletedComponent,
  EditProfileComponent,
  ChangeBankAccountNumberComponent,
  UploadDocumentsComponent,
  VerificationChoiceComponent,
  FundTransferComponent,
  LoginComponent,
  LoginPageWrapperComponent,
  PasswordResetComponent,
  PaymentsListComponent,
  SpinePayPaymentCompletedComponent,
  SpinepayError,
  PaymentsListPromoComponent,
  PaymentsSharedPromoComponent,
  PaymentPromoPopupComponent,
  WithdrawalComponent,
  AccountPageWrapperComponent,
  AccountStatementComponent,
  AccountStatementSearchComponent,
  AccountStatementResultsComponent,
  ChangePhoneNumberComponent,
  PhoneNumberVerificationComponent,
  FreeSpinsAwardPopupComponent
];

@NgModule({
  imports: [SharedModule, AccountRoutingModule],
  declarations: [...COMPONENTS, SavedCardsComponent, BankTransferComponent],
  exports: [...COMPONENTS]
})
export class AccountModule {}
