<div [ngClass]="{ last: isLast, selected: isSelected }" class="sport-link">
  <div
    (click)="navigateTo(linkToSport[0])"
    [category]="'Menu - Sports'"
    [click]="true"
    [label]="sport.name"
    analytics
    class="sport-link__link"
  >
    <div class="left-elements">
      <div class="sport-icon icon-{{ sport.id }}"></div>
      <span class="sport-name">{{ sport.name }}</span>
    </div>
  </div>

  <div class="sport-link__favourite">
    <app-favourite-toggle [sport]="sport"></app-favourite-toggle>
  </div>
</div>
