<app-breadcrumb faIcon="fa-bolt" [title]="'Bonus' | transloco" (backButtonClick)="backPressed()"></app-breadcrumb>
<div class="bonus-container">
  <app-card [titleHeaderText]="cardName">
    <div class="bonus-content-container" *ngIf="(accountQuery.viewingBonus$ | async) === undefined" body>
      <div transloco="Boost your winnings with our fantastic bonuses">
      </div>
      <div class="bonus-sections" *ngIf="(accountQuery.bonusUI$ | async) as bonusUI">
         <account-bonus-section-external faIcon="fa-bomb" [sectionTitle]="'Bomba Bonus 555' | transloco"
          [isViewing]="bonusUI.isViewingBomb"
          (sectionClicked)="onSectionClicked($event)" sectionId="bomb"
          [noBonusesText]="'No inactive bonuses are available' | transloco">
        </account-bonus-section-external>
        <account-bonus-section faIcon="fa-rocket" [sectionTitle]="'My Bonuses' | transloco"
          [isViewing]="bonusUI.isViewingInactive" [bonuses]="accountQuery.inactiveBonuses$"
          (sectionClicked)="onSectionClicked($event)" sectionId="inactive"
          [noBonusesText]="'No inactive bonuses are available' | transloco">
        </account-bonus-section>
        <account-bonus-section faIcon="fa-bolt" [sectionTitle]="'Your Active Bonuses' | transloco"
          [isViewing]="bonusUI.isViewingActive" [bonuses]="accountQuery.activeBonuses$"
          (sectionClicked)="onSectionClicked($event)" sectionId="active"
          [noBonusesText]="'No active bonuses are available' | transloco">
        </account-bonus-section>
        <account-bonus-section faIcon="fa-pause-circle" [sectionTitle]="'Paused Bonuses' | transloco"
          [isViewing]="bonusUI.isViewingPaused" [bonuses]="accountQuery.pausedBonuses$"
          (sectionClicked)="onSectionClicked($event)" sectionId="paused"
          [noBonusesText]="'No paused bonuses are available' | transloco">
        </account-bonus-section>

        <account-bonus-section faIcon="fa-backward" [sectionTitle]="'Your Previous Bonuses' | transloco"
          [isViewing]="bonusUI.isViewingPrevious" [bonuses]="accountQuery.previousBonuses$"
          (sectionClicked)="onSectionClicked($event)" sectionId="previous"
          [noBonusesText]="'No previous bonuses are available' | transloco">
        </account-bonus-section>
        <account-bonus-section faIcon="fa-backward" [sectionTitle]="'Bonuses You Missed Out On' | transloco"
          [isViewing]="bonusUI.isViewingMissed" [bonuses]="accountQuery.missedBonuses$"
          (sectionClicked)="onSectionClicked($event)" sectionId="missed" lastSection=true
          [noBonusesText]="'You have not missed out on any bonuses!' | transloco">
        </account-bonus-section>
      </div>
    </div>
    <div class="bonus-view" *ngIf="accountQuery.viewingBonus$ | async" body>
      <account-bonus-view></account-bonus-view>
    </div>
  </app-card>
</div>