import { BetCoupon } from 'clientside-coupon';

import { BetSearchModel, BetSearchParams } from './bet-search.model';
import { BonusModel } from './bonus.model';
import { CurrencyModel } from './currency.model';

export interface AccountState {
  userData: UserModel;
  verifyAccountState: VerifyAccountModel;
  showUnverifiedTooltip: boolean;
  multipleUnconfirmedUsers: boolean;
  isAuthenticated: boolean;
  resetPasswordOption: string;
  resetOptionTriggered: boolean;
  changePhoneNumberOption: string;
  phoneOptionTriggered: boolean;
  menuItems: AccountMenuLinkModel[];
  betSearch: BetSearchState;
  helpMenuItems: AccountMenuLinkModel[];
  bonuses: BonusModel[];
  bonusInfo: BonusModel[];
  viewingBonus: BonusModel;
  ui: AccountUIState;
  accountStatementFilter: AccountStatementFilterModel;
  transferTypes: AccountStatementTransferTypeModel[];
  accountStatements: AccountStatementModel[];
  financialAccountStatements: AccountStatementModel[];
  transferCandidates: TransferCandidateModel[];
}

export class VerifyAccountModel {
  type: VerifyAccountType;
  gracePeriod: boolean;
  daysLeft: number;
  hoursLeft: number;
  mobileNumber: string;
  callback: Function;

  constructor(init: Partial<VerifyAccountModel>) {
    Object.assign(this, init);
  }
}

export enum VerifyAccountType {
  Verified,
  Locked,
  VerifyInProgress,
  AlreadyRegistered
}

export interface BetSearchState {
  queryParams: BetSearchParams;
  betSearchResult: BetSearchModel[];
  isLoading: boolean;
}

export interface AccountPaymentState {
  payments: PaymentsModel;
  qualifiedWithdrawals: PayoutPromotionTransaction[];
  payoutPromoConfig: PayoutPromoConfig;
  cards: DebitCardModel[];
  hints: {
    cvv?: string;
    saveCard?: string;
    spinePay?: string;
  };
  savedCardsDisclaimer: string;
}

export class PaymentsModel {
  paymentListExtraDetailsModel: PaymentListExtraDetailsModel;
  paymentsListDeposit: PaymentListModel[];
  paymentsListWithdrawal: PaymentListModel[];
  paymentDetails: PaymentDetailsModel;
  pendingWithdrawals: PendingWithdrawalsModel[];
  banksDetails: BankDetailsModel;
  banksList: BanksModel[];
  channelsList: ChannelsModel[];
}
export class DebitCardModel {
  id: number;
  firstName: string;
  lastName: string;
  type: string;
  lastDigits: number;
  referenceUuid: string;
  address: string;
  city: string;
  country: string;
  month: string;
  year: string;
}

export class AccountUIState {
  refreshingBalance: boolean;
  noStatements: boolean;
  acceptedEuro2020Terms?: boolean;

  bonus: {
    isViewingActive: boolean;
    isViewingPaused: boolean;
    isViewingInactive: boolean;
    isViewingPrevious: boolean;
    isViewingMissed: boolean;
    isViewingBomb: boolean;
  };

  constructor(init: Partial<AccountUIState>) {
    Object.assign(this, init);
  }
}

export interface AccountStatementFilterModel {
  transferType: string;
  dateFrom: string;
  dateTo: string;
  period: string;
}

export class AccountStatementModel {
  id: number;
  transactionDate: Date;
  description: string;
  amount: number;
  transactionStatusCode: string;
  transactionTypeIdentifier: string;
  rollingBalance: number;
  couponCode: string;

  constructor(init: AccountStatementModel) {
    Object.assign(this, init);
  }
}
export type ExpandedAccountStatementModel = AccountStatementModel & {
  qualified: boolean;
  closedDate: Date;
  cashAward: number;
  spinsAward: number;
};

export class AccountMenuLinkModel {
  text: string;
  link: string;
  iconFontValue: string;
  isVisible: boolean = true;
  isLocked: boolean = false;
  showWarningIcon: boolean = false;
  visibleToTheseUserTypes: UserType[] = [];
  isCustomIcon?: boolean;

  constructor(init: Partial<AccountMenuLinkModel>) {
    Object.assign(this, init);
  }
}

export class UserModel {
  accessToken: string;
  skipSessionOne?: boolean;
  emailVerified?: boolean;
  address: {
    id: number;
    addressLine: string;
    countryCode: string;
    cityCode: string;
    state: string;
  };
  currency: CurrencyModel;
  dateOfBirth: Date;
  email: string;
  gender: string;
  id: number;
  loginDate: Date;
  citizenIdNumber: {
    id: number | undefined;
    value: number;
  };
  useStandardVerification: {
    id: number | undefined;
    value: boolean;
  };
  standardVerificationPending: {
    id: number | undefined;
    value: boolean;
  };
  isPoliticallyExposed: {
    id: number | undefined;
    value: boolean;
  };
  bankAccountDetails: any;
  marketingConsent: {
    id: number | undefined;
    value: boolean;
  };
  mobile: {
    id: number;
    mobileNumber: string;
    verifyType: string;
    lastStatusChangeDate: Date;
  };
  name: string;
  surname: string;
  username: string;
  userTypeCode: UserType;
  userStatus: string;
  wallets: Wallet[];
  KYCStatus: {
    badges: any;
    documentExpirationDate: Date;
    lastUpdatedBy: number;
    lastUpdateDateTime: Date;
  };
  blinkingSessionStatus: any;

  constructor(init: Partial<UserModel>) {
    Object.assign(this, init);
  }
}

export class Wallet {
  id: string;
  balance: number;
  name: string;
  isBonusWallet: boolean;
}

export class LoginResponseModel {
  success: boolean;
  errorMessage: string;
  loginMessages: any;
  id: any;

  constructor(init: Partial<LoginResponseModel>) {
    Object.assign(this, init);
  }
}

export class PasswordResetTokenValidationModel {
  isValid: string;
  tokenOwnerName: string;
  expired?: boolean;

  constructor(init: Partial<PasswordResetTokenValidationModel>) {
    Object.assign(this, init);
  }
}

export class AccountStatementTransferTypeModel {
  id: number;
  description: string;

  constructor(init: AccountStatementTransferTypeModel) {
    Object.assign(this, init);
  }
}

export class PaymentListExtraDetailsModel {
  instructions: string;

  constructor(init: PaymentListExtraDetailsModel) {
    Object.assign(this, init);
  }
}

export class PaymentListModel {
  name: string;
  status: number;
  description: string;
  imageIdentifer: string;
  fees: any;
  identifier: string;
  displayOrder: number;
  content: string;
  contentPopup: string;
  curreny: string;
  currencySymbol: string;
  minimumLimit: number;
  maximumLimit: number;

  constructor(init: Partial<PaymentListModel>) {
    Object.assign(this, init);
  }
}
export enum PaymentStatus {
  Online = 1,
  Offline = 0,
  Disabled = -1
}
export class PaymentDetailsModel {
  content: string;
  contentPopup: string;
  title: string;
  paymentTitle: string;
  paymentShortDesc: string;
  amount: number;
  quickLink: string;
  paymentId: string;
  paymentUI: number;
  paymentLogoUrl: string;
  displayOptions: PaymentDisplayState;
  status: number;

  constructor(init: Partial<PaymentDetailsModel>) {
    Object.assign(this, init);
  }
}

export class BankDetailsModel {
  lastAccNumber: number;
  lastBankId: number;

  constructor(init: Partial<BankDetailsModel>) {
    Object.assign(this, init);
  }
}

export class BanksModel {
  id: number;
  bankName: string;

  constructor(init: Partial<BanksModel>) {
    Object.assign(this, init);
  }
}

export class ChannelsModel {
  channels: string;

  constructor(init: Partial<ChannelsModel>) {
    Object.assign(this, init);
  }
}

export class PayoutPromotionTransaction {
  id: number;
  platformId: number;
  transactionDate: string;
  closedDate: string;
  qualifies: string;
  amount: number;
  status: string;
  username: string;
  cashAward: number;
  spinsAward: number;

  constructor(init: Partial<PayoutPromotionTransaction>) {
    Object.assign(this, init);
  }
}
export interface DepositPromotionClaimsResponse {
  claims: DepositPromotionClaimModel[];
}
export interface DepositPromotionClaimModel {
  amount: number;
  cashAward: number;
  cashAwardError: string;
  cashAwardedAt: string;
  claimDate: string;
  id: number;
  platformId: number;
  qualifies: string;
  spinsAward: number;
  spinsAwardError: string;
  spinsAwardedAt: string;
  status: string;
  username: string;
}
export interface DepositPromotion {
  id: number;
  config: { name: string };
}
export class PayoutPromoConfig {
  isActive: boolean;
  maximumAwardCash: number;
  maximumAwardSpins: number;
  payoutTimeLimitSeconds: number;
  suspensionReason: string | null;
  workingEndTimeSecondsInDay: number;
  workingStartTimeSecondsInDay: number;

  constructor(init: Partial<PayoutPromoConfig>) {
    Object.assign(this, init);
  }
}
export class PendingWithdrawalsModel {
  amount: number;
  furtherDetails: string;
  description: string;
  transectionDate: string;
  id: number;
  transactionTypeId: number;

  constructor(init: Partial<PendingWithdrawalsModel>) {
    Object.assign(this, init);
  }
}

export class PaymentUIState {
  identifier: string;
  displayOptions: any;

  constructor(init: Partial<PaymentUIState>) {
    Object.assign(this, init);
  }
}

export class TransferCandidateModel {
  userId: string;
  username: string;
  name: string;
  surname: string;
  balance: number;

  constructor(init: Partial<TransferCandidateModel>) {
    Object.assign(this, init);
  }
}

export enum DepositPaymentType {
  InterswitchWebPay = 'InterswitchWebpayDirectDeposit',
  InterswitchATMTransfer = 'InterswitchATMTransferDeposit',
  InterswitchQuickTeller = 'InterswitchQuickTellerWeb',
  InterswitchBank = 'InterswitchBankDeposit',
  GTCollections = 'GTCollections',
  GTBUSSD = 'GTBUSSD',
  GTPay = 'GTPay',
  UBADeposit = 'UBADeposit',
  UBAInternetBanking = 'UBAInternetBanking',
  UBAUSSD = 'UBAUSSDDeposit',
  ZenithBankUSSD = 'ZenithBankUSSDDeposit',
  ZenithBankXPath = 'ZenithBankXPathDeposit',
  BanksInstant = 'BanksInstantDeposit',
  Paystack = 'Paystack',
  AllSecureDeposit = 'AllSecureDeposit',
  MpesaDeposit = 'MpesaDepositOffline',
  MpesaExpress = 'MpesaDepositOnline',
  OPayDeposit = 'OPayDeposit',
  SpinePayDeposit = 'SpinePayDeposit'
}

export enum WithdrawalPaymentType {
  InterswitchQuickTeller = 'InterswitchQuickTellerWithdrawal',
  Paystack = 'Paystack',
  MpesaWithdrawal = 'MpesaWithdrawal',
  ZenithBankCIB = 'ZenithBankCIB',
  BankTransfer = 'BankTransfer'
}

export enum PaymentDisplayState {
  InfoOnly,
  AmountOnly,
  AmountAndBanks,
  AmountBankChannels,
  AmountChannels,
  InfoFeeCalculator,
  AmountFeeCalculator,
  AmountAndUserBankAccount
}

export enum ProfileEditability {
  Off = 'Off',
  OneTime = 'One-Time',
  Always = 'Always'
}

export enum UserType {
  Master = 'master',
  SuperAgent = 'superAgent',
  ShopOwner = 'shopOwner',
  Cashier = 'cashier',
  User = 'user'
}

export interface PersonalizedDefaultStake {
  UserId: number;
  StakeOfferSingle: number;
  StakeOfferMultiple: number;
}

export interface PersonalizedDefaultStakeReq {
  UserId: number;
  OldCouponData: BetCoupon;
  UpdatedCouponData: BetCoupon;
  DefaultStake: number;
}
