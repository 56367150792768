<app-breadcrumb
  *ngIf="accountPaymentQuery.paymentDetails$ | async as paymentDetails"
  title="{{ paymentDetails.title }}"
  faIcon="fa-minus-circle"
>
</app-breadcrumb>
<div class="withdrawal-wrapper" *ngIf="accountPaymentQuery.paymentDetails$ | async as paymentDetails" [@fadeIn]>
  <img
    onerror="this.src='assets/images/payments/noImage.svg'"
    *ngIf="accountPaymentQuery.paymentDetails$ | async as paymentDetails"
    src="../../../../../assets/images/payments/paymentBankLogo.svg"
    alt="payment-image"
    class="payment-logo"
    [@fadeIn]
  />
  <div class="header-wrapper">
    <h1>{{ 'Payment to current acount' | transloco }}</h1>
    <p>{{ 'Enter the desired amount to start the process.' | transloco }}</p>
  </div>
  <app-card>
    <div body>
      <form [formGroup]="paymentForm">
        <div *ngIf="showAmountInputs">
          <div class="delimiter-container" *ngIf="showAmountDelimiters">
            <button
              class="delimiter"
              [ngClass]="{ selected: amount === delimiter }"
              (click)="setAmount(delimiter)"
              *ngFor="let delimiter of delimiters; let i = index; trackBy: delimiterTrackBy"
            >
              {{ delimiter | currencyFormat : '1.0-0' }}
            </button>
          </div>

          <div class="input-container-payment">
            <span class="label" transloco="Amount"></span>
            <div class="input-wrapper">
              <input type="tel" class="input-amount" [placeholder]="'Amount' | transloco" formControlName="amount" />
              <span class="currency-txt">RSD</span>
            </div>
          </div>
          <div
            class="err-message"
            *ngIf="
              paymentForm.controls['amount'].errors?.min && (paymentForm.controls['amount'].touched || paymentForm.controls['amount'].dirty)
            "
          >
            {{ 'The minimum withdrawal allowed is' | transloco }} {{ minAmount | currencyFormat }}
          </div>
          <div
            class="err-message"
            *ngIf="
              paymentForm.controls['amount'].errors?.max && (paymentForm.controls['amount'].touched || paymentForm.controls['amount'].dirty)
            "
          >
            {{ 'The maximum withdrawal allowed is' | transloco }} {{ maxAmount | currencyFormat }}
          </div>
        </div>
        <div class="btn-container">
          <!--It will be usuful later in design-->
          <!-- <button class="btn cancel" (click)="onCancel()">
            <span class="btn-text" transloco="Cancel"></span>
          </button> -->
          <button
            class="btn proceed"
            type="submit"
            id="performWithdrawal"
            [disabled]="paymentForm.invalid"
            (click)="performWithdrawal()"
            *ngIf="showProceedBtn"
          >
            <i *ngIf="loading$ | async" class="fa fa-spin fa-spinner"></i> {{ proceedBtn$ | async }}
          </button>
        </div>
        <div class="info-wrapper">
          <p>{{ 'Cash on time title' | transloco }}</p>

          <div class="promo-not-active" *ngIf="!(accountPaymentQuery.payoutPromoConfig$ | async)?.isActive">
            <img src="../../../../../assets/images/payments/infoMarkIcon.svg" alt="" />
            <p>{{ 'Promotion not available at the moment.' | transloco }}</p>
          </div>

          <div class="time-wrapper">
            <img src="../../../../../assets/images/payments/clockElipse.svg" alt="" class="elipse" />
            <p>{{ '09:15 to 22:45h' | transloco }}</p>
          </div>
          <p class="info-txt">
            {{
              'We grant approved payment in 15 minutes. Otherwise, we pay penalties for each minute of delay - 100 RSD bonus funds and
               10 free spins for the first minute of delay, and 10 RSD bonus funds and 1 free spin for each subsequent minute.' | transloco
            }}
          </p>

          <p class="promo-link">
            {{ 'Learn more about the promotion' | transloco }}
            <a href="https://m.victory.rs/lp/kes-na-vreme?btag=lp_kešnavreme_">{{ 'Here' | transloco }}</a>
          </p>
        </div>

        <div *ngIf="showDepositChannels">
          <div class="input-container dropdown">
            <span class="label" transloco="Choose a deposit method :"></span>
            <select class="input-dropdown" formControlName="channel">
              <option *ngFor="let channel of channelDetails$ | async; let i = index; trackBy: channelsTrackBy" [value]="channel.channels">
                {{ channel.channels }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="showBankDetails">
          <div class="input-container dropdown">
            <span class="label" transloco="Choose a Bank:"></span>
            <select class="input-dropdown" formControlName="bank" *ngIf="accountPaymentQuery.bankList$ | async as bankList">
              <option
                *ngFor="let bank of bankList; let i = index; trackBy: banksTrackBy"
                [value]="bank.id"
                [hidden]="paymentId === zenithBank.name && bank.id !== zenithBank.id"
              >
                {{ bank.bankName }}
              </option>
            </select>
          </div>
          <div class="input-container">
            <span class="label">{{ 'Account Number' | transloco }} {{ paymentId === 'ZenithBankCIB' ? '' : ' (Nuban)' }} :</span>
            <input type="text" class="input-account-number" [placeholder]="'Account Number' | transloco" formControlName="accountNumber" />
          </div>
          <div type="danger" *ngIf="paymentForm.controls['accountNumber'].errors" [text]="'Invalid Account Number' | transloco"></div>
        </div>
        <div class="account-data-dropdown" (click)="showHideAccData()" appFaArrowRotate>
          <p>{{ 'Account Data' | transloco }}</p>
          <i class="fa fa-light fa-angle-right"></i>
        </div>
        <div class="account-data-wrapper" *ngIf="showHideAccountData">
          <div class="user-labels-container" *ngIf="showEmailCurrency">
            <span class="label" transloco="Email:"></span>
            <span class="value">{{ accountQuery.userData.email }}</span>
          </div>

          <div class="user-labels-container" *ngIf="showBankAccountNumber">
            <span class="label" transloco="Bank Account Number:"></span>
            <span class="value">{{ accountQuery.userData.bankAccountDetails.AccountNumber }}</span>
          </div>

          <div class="user-labels-container" *ngIf="showFees">
            <span class="label plus" transloco="Fees:"></span>
            <span class="value plus" [ngSwitch]="fees">
              <span *ngSwitchCase="'None'" transloco="None"></span>
              <span *ngSwitchCase="'Bank fees may be deducted'" transloco="Bank fees may be deducted"></span>
              <span *ngSwitchDefault>{{ fees }}</span>
            </span>
          </div>

          <div class="user-labels-container" *ngIf="showMinimumWithdrawal">
            <span class="label plus" transloco="Minimum payout:"></span>
            <span class="value plus">{{ minAmount | currencyFormat }}</span>
          </div>

          <div class="user-labels-container" *ngIf="showEmailCurrency && !showFeeCalculator">
            <span class="label" transloco="Currency:"></span>
            <span class="value">{{ accountQuery.userData.currency.name }}</span>
          </div>
          <!--According to design this is not needed, its commented only if designer change his mind.-->
          <!-- <div *ngIf="showAmountInputs && !showFeeCalculator">
            <div class="user-labels-container border-btm-close">
              <span class="label plus" transloco="Total Withdrawal:"></span>
              <span class="value plus">{{ paymentForm.controls['amount'].value || '0' | currencyFormat }}</span>
            </div>
          </div> -->
          <div *ngIf="showFeeCalculator">
            <div class="user-labels-container border-btm-close">
              <span class="label plus" transloco="Fee:"></span>
              <span class="value plus">{{ currentFee$ | async | currencyFormat }}</span>
            </div>
          </div>
        </div>
        <div class="info-note-dropdown" (click)="showHideNote()" appFaArrowRotate>
          <p>{{ 'Rules and Information' | transloco }}</p>
          <i class="fa fa-light fa-angle-right"></i>
        </div>
        <div class="info-note-wrapper" [style.display]="showHideInfoNote ? 'block' : 'none'">
          <div class="info-note" [innerHTML]="(accountPaymentQuery.paymentDetails$ | async).content | safeHTML"></div>
        </div>
      </form>
    </div>
  </app-card>
</div>
