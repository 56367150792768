import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { HelpService } from '../../../../core/services/help.service';

@Component({
  selector: 'app-phone-number-verification',
  templateUrl: './phone-number-verification.component.html',
  styleUrls: ['./phone-number-verification.component.scss']
})
export class PhoneNumberVerificationComponent implements OnInit, OnDestroy {
  phoneVerification$ = new BehaviorSubject<boolean>(false);
  alreadyRegistered$ = new BehaviorSubject<boolean>(false);
  resendCodeVerification$ = new BehaviorSubject<boolean>(false);

  verifyNumber: boolean = false;
  limitExceeded: boolean = false;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly applicationService: ApplicationService,
    readonly accountQuery: AccountQuery,
    private readonly activatedRoute: ActivatedRoute,
    private readonly accountService: AccountService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,

    private readonly helpService: HelpService
  ) {}

  ngOnInit(): void {
    this.verifyNumber = this.activatedRoute.snapshot.paramMap.get('verifynumber') === 'true';

    if (this.verifyNumber) {
      this.resendCodeVerification$.next(true);
    } else {
      if (this.accountQuery.multipleUnconfirmedUsers === undefined) {
        this.router.navigate(['/']);
      } else {
        if (this.accountQuery.multipleUnconfirmedUsers) {
          this.alreadyRegistered$.next(true);
        } else if (!this.accountQuery.multipleUnconfirmedUsers) {
          this.verifyPhoneNumber();
        } else {
          this.router.navigate(['/']);
        }
      }
    }
  }

  verifyPhoneNumber(): void {
    this.accountService.startVerificationBySMS(this.accountQuery.userData.id, this.accountQuery.userData.mobile.mobileNumber).subscribe(
      () => {
        this.limitExceeded = false;
        this.alreadyRegistered$.next(false);
        this.resendCodeVerification$.next(false);
        this.phoneVerification$.next(true);
      },
      error => {
        if (error.error.ResponseCode === 20805) {
          this.limitExceeded = true;
          this.alreadyRegistered$.next(false);
          this.resendCodeVerification$.next(false);
          this.phoneVerification$.next(true);
        }
      }
    );
  }

  logout(redirectPage: string = ''): void {
    this.accountService.logout().subscribe(
      () => {
        if (redirectPage !== '') {
          this.applicationService.navigateTo(redirectPage);
        } else {
          this.router.navigate(['/account/login']);
        }

        this.notificationService.showSuccessMessage(translate('Logout successful'));
      },
      error => {
        // if the revokeToken call fails, clear the userData anyway
        this.accountService.clearUserData();
      }
    );
  }

  cancel(): void {
    this.applicationService.navigateTo('/');
  }

  onCancelClick(): void {
    this.accountQuery.userData.userStatus !== 'ACT' ? this.logout() : this.cancel();
  }

  contactSupport(): void {
    if (this.verifyNumber) {
      this.logout(this.helpService.getHelpPageUrls().contactUs);
    } else {
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
