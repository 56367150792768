<div class="spinepay-completed-wrapper">
  <div class="success-payment" *ngIf="completeType === 'success'">
    <div class="info-wrapper">
      <div class="success-title">{{ 'Deposit Successful!' | transloco }}</div>
      <p class="success-text">
        {{ 'Congratulations, your payment from ' | transloco }}
        <span class="amount">{{ amountCharged$ | async | currencyFormat }}</span>
        {{ ' has been successfully processed, the funds have been transferred to your account.' | transloco }}
      </p>
    </div>
    <div class="payment-details">
      <div class="details">
        <div class="label" transloco="IPS reference:"></div>
        <div class="info value">{{ orderReference$ | async }}</div>
      </div>
      <div class="details">
        <div class="label" transloco="Amount and currency charged:"></div>
        <div class="info value">{{ amountCharged$ | async | currencyFormat }}</div>
      </div>
      <div class="details">
        <div class="label" transloco="Transaction ID:"></div>
        <div class="info">{{ transactionId }}</div>
      </div>
      <div class="details">
        <div class="label" transloco="Date & Time:"></div>
        <div class="info">{{ date$ | async }}</div>
      </div>
    </div>
    <div class="button-wrapper">
      <button class="continue-btn" (click)="continuePlaying()">{{ 'Go to offer' | transloco }}</button>
    </div>
  </div>
  <div class="payment-error" *ngIf="completeType === 'fail'">
    <div class="info-wrapper">
      <div class="error-title">{{ 'Unsuccessful payment!' | transloco }}</div>
      <p class="error-text">
        {{ 'Your payment from ' | transloco }}
        <span class="amount">{{ amountCharged$ | async | currencyFormat }}</span>
        {{ ' is not successful.' | transloco }}
      </p>
    </div>
    <div class="payment-details">
      <div class="details">
        <div class="label" transloco="IPS reference:"></div>
        <div class="info">{{ orderReference$ | async }}</div>
      </div>
      <div class="details">
        <div class="label" transloco="Amount and currency charged:"></div>
        <div class="info">{{ amountCharged$ | async | currencyFormat }}</div>
      </div>
      <div class="details">
        <div class="label" transloco="Transaction ID:"></div>
        <div class="info">{{ transactionId }}</div>
      </div>
      <div class="details">
        <div class="label" transloco="Date & Time:"></div>
        <div class="info">{{ date$ | async }}</div>
      </div>
    </div>
    <div class="button-wrapper">
      <button class="continue-btn" (click)="navigateToDeposit()">{{ 'Try again' | transloco }}</button>
    </div>
  </div>
</div>
