import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appRotatorContainer]'
})
export class RotatorContainerDirective implements AfterViewInit {
  private readonly el: ElementRef;
  private rotateto = 0;
  private itemCount = 0;
  private tcItemInitialRotation = 0;
  private tcZDistance = 0;

  constructor(element: ElementRef) {
    this.el = element;
  }

  ngAfterViewInit(): void {
    this.itemCount = this.el.nativeElement.children.length;
    this.tcItemInitialRotation = 360 / this.itemCount;
    this.tcZDistance = this.calculateZDistance(this.itemCount);

    const children = this.el.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      children[i].style.transform = `rotateY(${this.tcItemInitialRotation * i}deg) translateZ(${this.tcZDistance}px)`;
      children[i].setAttribute('tc-rotation', this.tcItemInitialRotation * i);
    }
  }

  onNextClick(): void {
    if (this.showArrows()) {
      this.rotateto -= this.tcItemInitialRotation;
      this.tcRotate(this.rotateto);
    }
  }

  onPreviousClick(): void {
    if (this.showArrows()) {
      this.rotateto += this.tcItemInitialRotation;
      this.tcRotate(this.rotateto);
    }
  }

  showArrows(): boolean {
    return this.itemCount > 1;
  }

  private tcRotate(tcdeg: number): void {
    this.el.nativeElement.style.transform = `rotateY(${tcdeg}deg)`;
  }

  private calculateZDistance(itemCount: number): number {
    let zDistance = (itemCount - 3) * 50 + 86.66;
    switch (itemCount) {
      case 1:
      case 2:
        zDistance = 0;
        break;
      case 3:
        break;
      case 4:
        zDistance = zDistance + 14;
        break;
      case 5:
        zDistance = zDistance + 20;
        break;
      case 6:
        zDistance = zDistance + 24;
        break;
      default:
        zDistance = zDistance + 25;
        break;
    }

    return zDistance;
  }
}
