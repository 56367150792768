import { Injectable, OnDestroy } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Subject } from 'rxjs';
import { BannerState } from 'src/app/shared/models/banner-rotator.model';

function createInitialState(): BannerState {
  return {
    bannerDetails: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'banners' })
export class BannerRotatorStore extends Store<BannerState> implements OnDestroy {
  destroy$ = new Subject<boolean>();

  constructor() {
    super(createInitialState());
  }

  updateUI(bannerDetails: Partial<BannerState['bannerDetails']>): void {
    this.update(state => ({
      bannerDetails: {
        ...state.bannerDetails,
        ...bannerDetails
      }
    }));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
