import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {BehaviorSubject, interval, Observable} from 'rxjs';
import {map, takeWhile} from 'rxjs/operators';

const STORE_KEY = 'userLastAction';

@Injectable(
  {providedIn: 'root'}
)
export class IdleService implements OnDestroy {
  static runTimer: boolean;

  USER_IDLE_TIMER: number;
  userIdlenessChecker: BehaviorSubject<string>;

  private sessionForIdle: Observable<number>;
  private userActivityChangeCallback: ($event: Event) => void;

  constructor(private zone: NgZone) {
    if (!this.userIdlenessChecker) {
      this.userIdlenessChecker = new BehaviorSubject<string>('INITIATE_TIMER');
    }
  }

  get lastAction(): number {
    return parseInt(localStorage.getItem(STORE_KEY), 10);
  }

  set lastAction(value: number) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  initialize(options?: {idleDuration: number}): void {
    IdleService.runTimer = true;
    this.USER_IDLE_TIMER = options?.idleDuration || 10;

    this.reset();
    this.initListener();
    this.initInterval()
  }

  handleUserActiveState(): void {
    this.reset();
  }

  reset(): void {
    this.lastAction = Date.now();
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.next('RESET_TIMER');
    }
  }

  removeActionFromStore(): void {
    localStorage.removeItem(STORE_KEY);
  }

  stopTimer(): boolean {
    IdleService.runTimer = false;
    return IdleService.runTimer;
  }

  removeIdlenessChecker(): void {
    if (this.userIdlenessChecker) {
      this.userIdlenessChecker.unsubscribe();
      this.userIdlenessChecker = undefined;
      this.removeActionFromStore()
    }
  }

  ngOnDestroy(): void {
    this.removeIdlenessChecker();
  }

  private initListener(): void {
    this.zone.runOutsideAngular(() => {
      this.userActivityChangeCallback = () => this.handleUserActiveState();
      window.document.addEventListener('click', this.userActivityChangeCallback.bind(this), true);
      window.document.addEventListener('mousemove', this.userActivityChangeCallback.bind(this), true);
      window.document.addEventListener('input', this.userActivityChangeCallback.bind(this), true);
      window.document.addEventListener('scroll', this.userActivityChangeCallback.bind(this), true);
    });
  }

  private initInterval(): void {
    const intervalDuration = 1000;
    this.sessionForIdle = interval(intervalDuration).pipe(
      map((tick: number) =>
        tick),
      takeWhile(() => IdleService.runTimer)
    );

    this.check();
  }

  private check(): void {
    this.sessionForIdle
      .subscribe(() => {
        const now = Date.now();
        const timeleft = this.lastAction + this.USER_IDLE_TIMER * 1000;
        const diff = timeleft - now;
        const isTimeout = diff < 0;

        this.userIdlenessChecker.next(`${diff}`);

        if (isTimeout) {
          window.document.removeEventListener('click', this.userActivityChangeCallback, true);
          window.document.removeEventListener('mousemove', this.userActivityChangeCallback.bind(this), true);
          window.document.removeEventListener('input', this.userActivityChangeCallback.bind(this), true);
          window.document.removeEventListener('scroll', this.userActivityChangeCallback.bind(this), true);

          this.zone.run(() => {
            if (this.userIdlenessChecker) {
              this.userIdlenessChecker.next('STOPPED_TIMER');
            }
            IdleService.runTimer = false;
          });
        }
      });
  }

}
