import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/core/services/application.service';

@Component({
  selector: 'app-my-bets-menu',
  templateUrl: './my-bets-menu.component.html',
  styleUrls: ['./my-bets-menu.component.scss']
})
export class MyBetsMenuComponent implements OnInit, OnDestroy {
  constructor(private readonly appService: ApplicationService) {}

  ngOnInit(): void {
    document.body?.classList?.add('disable-scrolling');
  }

  ngOnDestroy(): void {
    document.body?.classList?.remove('disable-scrolling');
  }

  close(): void {
    this.appService.showMyBets$.next(false);
  }
}
