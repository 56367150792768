import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';

import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { BonusModel } from 'src/app/shared/models/bonus.model';
import { BonusService } from '../../../../core/services/bonus.service';
import { CouponService } from '../../../../core/services/coupon/coupon.service';
import { AccountQuery } from '../../../../core/state/account/account.query';
import { expandCollapse } from '../../../../shared/animations';
import { NotificationService } from './../../../../core/services/notification.service';
import { Wallet } from 'src/app/shared/models/account.model';

const allowedSportsWallets = ['Bonus Sports', 'Bonus Sportovi', 'Main', 'Glavni'];

@Component({
  selector: 'app-coupon-wallet-selector',
  templateUrl: './coupon-wallet-selector.component.html',
  styleUrls: ['./coupon-wallet-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse()]
})
export class CouponWalletSelectorComponent implements OnInit {
  bonusId: number;
  open: boolean = true;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly couponQuery: CouponQuery,
    private readonly notificationService: NotificationService,
    private readonly couponService: CouponService,
    private readonly bonusService: BonusService
  ) {}

  ngOnInit(): void {
    this.bonusService.getUserBonuses().subscribe();
    this.accountQuery.bonuses$.pipe(filter(bonuses => !!bonuses)).subscribe(bonuses => {
      const activeBonus = bonuses.find(this.isActiveBonus.bind(this));

      if (activeBonus) {
        this.bonusId = activeBonus.id;
      }

      this.couponService.updateCouponSetting('useBonusFunds', !!activeBonus && !activeBonus.isPaused);
    });
  }

  toggleUsedWallet(value: boolean): void {
    if (value) {
      this.bonusService.resumeBonus(this.bonusId).subscribe(() => {
        this.bonusService.getUserBonuses().subscribe();
      });
    } else {
      this.bonusService.pauseBonus(this.bonusId).subscribe(() => {
        this.bonusService.getUserBonuses().subscribe();
      });
    }
    this.couponService.updateCouponSetting('useBonusFunds', value);
  }

  trackById(item: any): number {
    return item.id;
  }

  areBonusFundsSelected(): boolean {
    return this.couponQuery.couponSettings.useBonusFunds;
  }

  isWalletSelected(wallet: Wallet): boolean {
    return (wallet.isBonusWallet && this.areBonusFundsSelected()) || (!wallet.isBonusWallet && !this.areBonusFundsSelected());
  }

  get wallets$() {
    return this.accountQuery.wallets$.pipe(map(wallets => wallets.filter(wallet => allowedSportsWallets.includes(wallet.name))));
  }

  private isActiveBonus(bonus: BonusModel): boolean {
    return bonus.isActivated && !bonus.isCancelled && !bonus.isExpired;
  }
}
