import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBarComponent implements OnChanges {
  @Input() show: boolean = false;
  @Input() delay = 0;
  @Input() transparent = false;

  show$ = new BehaviorSubject(false);

  private showTimeout: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show) {
      this.propagateShowChange(changes.show.currentValue, changes.show.isFirstChange());
    }
  }

  private propagateShowChange(newValue: boolean, isFirstChange: boolean = false): void {
    if (!this.delay) {
      this.show$.next(newValue);
      return;
    }

    if (isFirstChange) {
      this.showTimeout = setTimeout(() => {
        this.show$.next(newValue);
      }, this.delay);
    } else {
      if (this.showTimeout) {
        clearTimeout(this.showTimeout);
      }
      this.show$.next(newValue);
    }
  }
}
