<div class="correct-scores-market-odds-container" [class.padding]="padding">
  <div class="correct-score-selections">
    <div class="selection" transloco="Home To Win"></div>
    <div class="selection" transloco="Teams To Draw"></div>
    <div class="selection" transloco="Away To Win"></div>
  </div>
  <div class="odd-collection" *ngIf="odd">
    <div class="home-to-win odds">
      <ng-container *ngFor="let oddHTW of odd.homeToWin; trackBy: oddTrackBy">
        <app-odd-with-label [selectionName]="oddHTW.selectionName" [odd]="oddHTW" [noborder]="true"></app-odd-with-label>
      </ng-container>
    </div>
    <div class="draw odds">
      <div class="draw-wrapper">
        <ng-container *ngFor="let oddD of odd.draw; trackBy: oddTrackBy">
          <app-odd-with-label [selectionName]="oddD.selectionName" [odd]="oddD" [noborder]="true"></app-odd-with-label>
        </ng-container>
      </div>
      <div class="other-wrapper">
        <ng-container *ngFor="let oddO of odd.others; trackBy: oddTrackBy">
          <app-odd-with-label [selectionName]="oddO.selectionName" [odd]="oddO" [noborder]="true"></app-odd-with-label>
        </ng-container>
      </div>
    </div>
    <div class="away-to-win odds">
      <ng-container *ngFor="let oddATW of odd.awayToWin; trackBy: oddTrackBy">
        <app-odd-with-label [selectionName]="oddATW.selectionName" [odd]="oddATW" [noborder]="true"></app-odd-with-label>
      </ng-container>
    </div>
  </div>
</div>
