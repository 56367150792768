import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatchService } from 'src/app/core/services/match.service';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-event-match-info',
  templateUrl: './event-match-info.component.html',
  styleUrls: ['./event-match-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventMatchInfoComponent {
  @Input() event: MatchModel;

  constructor(private readonly matchService: MatchService) {}

  navigateToMatch(): void {
    this.matchService.navigateToMatch(this.event.id, this.event.name);
  }
}
