import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { brandInfo } from '../../../../brand-info';
import { AppConfigService } from '../../../core/services/app-config.service';
import { ApplicationService } from '../../../core/services/application.service';
import { HelpService } from '../../../core/services/help.service';
import { LanguageService } from '../../../core/services/language.service';

interface SupportLink {
  name: string;
  href?: string | SafeUrl;
  FAIcon?: string;
  hasSVGIcon?: boolean;
  hasCallback?: boolean;
}

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportComponent {
  brandInfo = brandInfo;
  social = this.appConfigService.get('social');
  supportLinks: SupportLink[];

  constructor(
    private readonly applicationService: ApplicationService,
    private readonly appConfigService: AppConfigService,
    private readonly languageService: LanguageService,
    private readonly sanitized: DomSanitizer,
    private readonly helpService: HelpService
  ) {
    /*const sanitizedViberUrl = this.sanitized.bypassSecurityTrustUrl(this.social.viber);*/

    this.supportLinks = [
      {
        name: 'chat',
        href: `${helpService.getHelpPageUrls().contactUs}`,
        FAIcon: 'envelope-o'
      },
      {
        name: 'chat',
        href: '#',
        hasCallback: true,
        FAIcon: 'headphones'
      }
    ];
  }

  indexTrackBy(index: string): string {
    return index;
  }
}
