<ng-container *ngIf="{
  viewingBonus: accountQuery.viewingBonus$ | async
} as subscriptions">
  <div class="bonus-view-container">
    <div class="image">
      <div class="image-content {{ status }}" [ngStyle]="{ 'background-image': 'url(' + bonusInfo.imageURL + ')' }">
      </div>
      <div class="paused-text" *ngIf="status === 'paused'">
        <i class="fa fa-pause-circle" aria-hidden="true"></i>
        {{'Paused' | transloco}}
      </div>
    </div>
    <account-bonus-details
      *ngIf="subscriptions.viewingBonus && subscriptions.viewingBonus.isBonusTransferPossible !== false"
      [currentProgress]="subscriptions.viewingBonus.currentProgress"
      [nextRelease]="subscriptions.viewingBonus.nextReleaseAmount"
      [expirationDate]="subscriptions.viewingBonus.expirationDate" [status]="status">
    </account-bonus-details>
    <div class="transfer-not-possible" *ngIf="subscriptions.viewingBonus.isBonusTransferPossible === false">
      {{'Wagering requirement not met! Bonus balance is ZERO. You can no longer transfer from your bonus to main Wallet' | transloco}}
    </div>
    <div class="bonus-content" [innerHTML]="bonusInfo.content">
    </div>
    <div class="buttons" *ngIf="subscriptions.viewingBonus as bonus">
      <app-button *ngIf="status === 'active'" [text]="'Pause' | transloco" (btnClick)="onPauseClicked(bonus.id)">
      </app-button>
      <app-button *ngIf="status === 'paused'" buttonType="success" [text]="'Resume' | transloco"
        (btnClick)="onResumeClicked(bonus.id)"></app-button>
      <app-button *ngIf="status === 'inactive'" buttonType="success" [text]="'Activate' | transloco"
        (btnClick)="onActivateClicked(bonus.id)"></app-button>
    </div>
  </div>
</ng-container>
