import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appShowMore]'
})
export class ShowMoreDirective {
  @HostListener('click', ['$event.target']) onClick(target: any): void {
    target.previousElementSibling.classList.remove('show-less');
    target.style.display = 'none';
  }
}
