<div class="wallet-tabs" *ngIf="(accountQuery.currentBonuses$ | async) && (accountQuery.currentBonuses$ | async).length">
  <div class="tabs">
    <div
      *ngFor="let wallet of wallets$ | async; trackBy: trackById"
      (click)="toggleUsedWallet(wallet.isBonusWallet)"
      [class.active]="isWalletSelected(wallet)"
      class="wallet-tab"
    >
      {{ wallet.name }}
    </div>
  </div>
</div>
