<div class="new-stats-container" *ngIf="enableStatistics$ | async" [@fadeIn]>
  <!--
    *
    *   Tabs (h2h/tabels)
    *
  -->
  <div class="tabs">
    <div class="tab" [class.active]="(activeTab$ | async) === 'h2h'" (click)="setActiveTab('h2h')" transloco="H2H"></div>
    <div class="tab" [class.active]="(activeTab$ | async) === 'tabels'" (click)="setActiveTab('tabels')" transloco="Tabels"></div>
  </div>

  <ng-container *ngIf="(activeTab$ | async) === 'h2h'">
    <!--
    *
    *   H2H Last Outcomes
    *
  -->
    <div class="last-outcomes">
      <!-- HOME -->
      <div class="outcome-row">
        <div class="team-name truncate">{{ (statisticsQuery.lastMatchesData$ | async).homeTeamName }}</div>
        <div class="outcome-boxes">
          <ng-container *ngFor="let outcome of (statisticsQuery.lastMatchesData$ | async).homeLastResults; let i = index">
            <div
              *ngIf="i < 5"
              class="outcome-box"
              [class.win]="outcome?.result === 'W'"
              [class.draw]="outcome?.result === 'D'"
              [class.lose]="outcome?.result === 'L'"
            >
              {{ outcome.result }}
            </div>
          </ng-container>
        </div>
      </div>
      <!-- AWAY -->
      <div class="outcome-row">
        <div class="team-name truncate">{{ (statisticsQuery.lastMatchesData$ | async).awayTeamName }}</div>
        <div class="outcome-boxes">
          <ng-container *ngFor="let outcome of (statisticsQuery.lastMatchesData$ | async).awayLastResults; let i = index">
            <div
              *ngIf="i < 5"
              class="outcome-box"
              [class.win]="outcome?.result === 'W'"
              [class.draw]="outcome?.result === 'D'"
              [class.lose]="outcome?.result === 'L'"
            >
              {{ outcome.result }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!--
    *
    *   H2H History
    *
  -->
    <div class="h2h-history" *ngIf="h2hData$ | async as h2h">
      <div class="card-section-header">
        <div class="title">{{ 'H2H matches' | transloco }}</div>
      </div>
      <!--
      *
      *     Outcome legend
      *
    -->
      <div class="outcome-legend">
        <div class="outcome-item">
          <div class="green-circle"></div>
          <span class="truncate">{{ h2h.homeTeam.name }}</span>
        </div>
        <div class="outcome-item">
          <div class="orange-circle"></div>
          <span class="truncate" transloco="Draw(s)"></span>
        </div>
        <div class="outcome-item">
          <div class="blue-circle"></div>
          <span class="truncate">{{ h2h.awayTeam.name }}</span>
        </div>
      </div>

      <!--
      *
      *     Outcome bar
      *
    -->
      <div class="outcome-bar">
        <div class="bar green-bar" [ngStyle]="{ width: h2h.homeTeam.percentage + '%' }">{{ h2h.homeTeam.wins }}</div>
        <div class="bar orange-bar" [ngStyle]="{ width: h2h.drawPercentage + '%' }">
          {{ h2h.drawTimes }}
        </div>
        <div class="bar blue-bar" [ngStyle]="{ width: h2h.awayTeam.percentage + '%' }">{{ h2h.awayTeam.wins }}</div>
      </div>

      <!--
      *
      *     Matches
      *
    -->
      <div class="matches">
        <div class="match" *ngFor="let match of h2h.matches; trackBy: indexTrackBy">
          <div class="date">{{ match.date }}</div>
          <div class="teams">
            <div class="team home truncate" [class.active]="match.homeTeam.winner">{{ match.homeTeam.name }}</div>
            <div class="delimiter">—</div>
            <div class="team away truncate" [class.active]="match.awayTeam.winner">{{ match.awayTeam.name }}</div>
          </div>
          <div class="result">
            <span>{{ match.homeTeam.score }}-{{ match.awayTeam.score }}</span>
            <div class="circle" [class.green]="isHomeWinner(match)" [class.orange]="isDraw(match)" [class.blue]="isAwayWinner(match)"></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(activeTab$ | async) === 'tabels'">
    <!--
    *
    *     League rankings
    *
  -->
    <div class="leauge-rankings" *ngIf="statisticsQuery.leagueRankingData$ | async">
      <div class="card-section-header">
        <div class="title" transloco="League Ranking"></div>
      </div>
      <div>
        <div class="rank-header">
          <div transloco="Pos"></div>
          <div transloco="Team"></div>

          <div class="arrow arrow-left" [class.not-allowed]="(activeLeagueColumn$ | async) === 1" (click)="setActiveLeagueColumn(1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.608"
                d="m12.91 15.18-3.216-3.216 3.215-3.215"
              />
              <rect width="21" height="21" x="22.203" y="22.464" stroke="#fff" rx="2.715" transform="rotate(180 22.203 22.464)" />
            </svg>
          </div>
          <ng-container *ngIf="(activeLeagueColumn$ | async) === 1">
            <div class="line-center" transloco="P"></div>
            <div class="line-center" transloco="W"></div>
            <div class="line-center" transloco="D"></div>
          </ng-container>

          <ng-container *ngIf="(activeLeagueColumn$ | async) === 2">
            <div class="line-center" transloco="L"></div>
            <div class="line-center" transloco="GD"></div>
            <div class="line-center" transloco="Pts"></div>
          </ng-container>
          <div class="arrow arrow-right" [class.not-allowed]="(activeLeagueColumn$ | async) === 2" (click)="setActiveLeagueColumn(2)">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
              <path
                stroke="#fff"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.608"
                d="m9.911 15.18 3.215-3.216L9.911 8.75"
              />
              <rect width="21" height="21" x=".5" y="-.5" stroke="#fff" rx="2.715" transform="matrix(1 0 0 -1 .117 21.964)" />
            </svg>
          </div>
        </div>
        <div
          class="team"
          *ngFor="let team of (statisticsQuery.leagueRankingData$ | async).teams | orderBy : 'position'; trackBy: indexTrackBy"
        >
          <div class="position">{{ team.position }}</div>
          <div class="team-name">
            <div class="name truncate">{{ team.teamName }}</div>
          </div>
          <div></div>
          <ng-container *ngIf="(activeLeagueColumn$ | async) === 1">
            <div class="stats line-center">{{ team.played }}</div>
            <div class="stats line-center">{{ team.won }}</div>
            <div class="stats line-center">{{ team.drawn }}</div>
          </ng-container>
          <ng-container *ngIf="(activeLeagueColumn$ | async) === 2">
            <div class="stats line-center">{{ team.lost }}</div>
            <div class="stats line-center">{{ team.goalsDifference }}</div>
            <div class="points line-center">{{ team.points }}</div>
          </ng-container>
          <div></div>
        </div>
      </div>
    </div>

    <!--
    *
    *     Home Last Matches
    *
  -->
    <div class="last-matches" *ngIf="h2hData$ | async as h2h">
      <div class="matches">
        <div class="card-section-header">
          <div class="title">{{ h2h.homeTeam.name }}</div>
          <div class="section-label" transloco="Last 5 Matches"></div>
        </div>

        <div class="match" *ngFor="let match of (statisticsQuery.lastMatchesData$ | async).homeMatches; trackBy: indexTrackBy">
          <div class="date">{{ match.date }}</div>
          <div class="teams">
            <div class="team home truncate" [class.active]="match.teamHomeScore > match.teamAwayScore">{{ match.teamHomeName }}</div>
            <div class="delimiter">—</div>
            <div class="team away truncate" [class.active]="match.teamAwayScore > match.teamHomeScore">{{ match.teamAwayName }}</div>
          </div>
          <div class="result">
            <span>{{ match.teamHomeScore }}-{{ match.teamAwayScore }}</span>
          </div>
        </div>
      </div>
    </div>

    <!--
    *
    *     Away Last Matches
    *
  -->
    <div class="last-matches" *ngIf="h2hData$ | async as h2h">
      <div class="matches">
        <div class="card-section-header">
          <div class="title">{{ h2h.awayTeam.name }}</div>
          <div class="section-label" transloco="Last 5 Matches"></div>
        </div>

        <div class="match" *ngFor="let match of (statisticsQuery.lastMatchesData$ | async).awayMatches; trackBy: indexTrackBy">
          <div class="date">{{ match.date }}</div>
          <div class="teams">
            <div class="team home truncate" [class.active]="match.teamHomeScore > match.teamAwayScore">{{ match.teamHomeName }}</div>
            <div class="delimiter">—</div>
            <div class="team away truncate" [class.active]="match.teamAwayScore > match.teamHomeScore">{{ match.teamAwayName }}</div>
          </div>
          <div class="result">
            <span>{{ match.teamHomeScore }}-{{ match.teamAwayScore }}</span>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="statistics-container" id="statistics-container" *ngIf="enableStatistics$ | async" [@fadeIn]>
  <div class="close-icon" (click)="closeStatistics()" analytics [click]="true" [category]="'Close Statistics'">
    <div transloco="Close Stats"></div>
    <i class="fa fa-times" aria-hidden="true"></i>
  </div>
  <!--Head to Head-->
  <div
    class="header-tab"
    [class.selected]="headerTabSelected === headerTab.HeadToHead"
    *ngIf="statisticsQuery.headToHeadData$ | async"
    (click)="headerTabSelected = headerTab.HeadToHead"
  >
    <i class="fa fa-shield left-icon" aria-hidden="true"></i>
    <div transloco="Head To Head"></div>
    <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
  </div>
  <div
    class="head-to-head-content"
    [class.selected]="headerTabSelected === headerTab.HeadToHead"
    *ngIf="statisticsQuery.headToHeadData$ | async"
  >
    <!-- <div class="section-not-found" transloco="Head To Head are not available for this match"></div> -->
    <div class="section-found">
      <div class="team-wins-draws">
        <div class="team-home-wins">
          <span class="team-name">
            <span class="name">{{ (statisticsQuery.headToHeadData$ | async).homeTeamName }}</span>
            <span class="results-perc"
              >{{ (statisticsQuery.headToHeadData$ | async).teamHomeWins }} ({{
                (statisticsQuery.headToHeadData$ | async).teamHomeWinsPerc
              }}%)</span
            >
          </span>
          <div class="status-bar">
            <div [ngStyle]="{ width: (statisticsQuery.headToHeadData$ | async).teamHomeWinsPerc + '%' }"></div>
          </div>
        </div>
        <div class="team-draws">
          <span class="team-name">
            <span class="name" transloco="Draw(s)"></span>
            <span class="results-perc"
              >{{ (statisticsQuery.headToHeadData$ | async).teamDraws }} ({{
                (statisticsQuery.headToHeadData$ | async).teamDrawsPerc
              }}%)</span
            >
          </span>
          <div class="status-bar">
            <div [ngStyle]="{ width: (statisticsQuery.headToHeadData$ | async).teamDrawsPerc + '%' }"></div>
          </div>
        </div>
        <div class="team-away-wins">
          <span class="team-name">
            <span class="name">{{ (statisticsQuery.headToHeadData$ | async).awayTeamName }}</span>
            <span class="results-perc"
              >{{ (statisticsQuery.headToHeadData$ | async).teamAwayWins }} ({{
                (statisticsQuery.headToHeadData$ | async).teamAwayWinsPerc
              }}%)</span
            >
          </span>
          <div class="status-bar">
            <div [ngStyle]="{ width: (statisticsQuery.headToHeadData$ | async).teamAwayWinsPerc + '%' }"></div>
          </div>
        </div>
      </div>
      <div class="headToHeadHistory">
        <table>
          <tbody>
            <tr *ngFor="let match of (statisticsQuery.headToHeadData$ | async).headToHeadHistory; trackBy: indexTrackBy">
              <td class="team-home">
                <div class="team-name">
                  <span class="name" [ngClass]="{ won: match.winner === 'home' }">{{ match.teamHomeName }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamHomeLogo + ')' }"></span>
                  <span class="score">{{ match.teamHomeScore }}</span>
                </div>
                <div class="round">{{ match.round }}</div>
              </td>
              <td class="divider"><span transloco=":"></span></td>
              <td class="team-away">
                <div class="team-name">
                  <span class="score">{{ match.teamAwayScore }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamAwayLogo + ')' }"></span>
                  <span class="name" [ngClass]="{ won: match.winner === 'away' }">{{ match.teamAwayName }}</span>
                </div>
                <div class="date">{{ match.date }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--Last Matches-->
  <div
    class="header-tab"
    [class.selected]="headerTabSelected === headerTab.LastMatches"
    *ngIf="statisticsQuery.lastMatchesData$ | async"
    (click)="headerTabSelected = headerTab.LastMatches"
  >
    <i class="fa fa-long-arrow-left left-icon" aria-hidden="true"></i>
    <div transloco="Last 5 Matches"></div>
    <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
  </div>
  <div
    class="last-matches-content"
    [class.selected]="headerTabSelected === headerTab.LastMatches"
    *ngIf="statisticsQuery.lastMatchesData$ | async"
  >
    <!-- <div class="section-not-found" transloco="Last 5 Matches are not available for this match"></div> -->
    <div class="section-found">
      <div class="team-names">
        <div
          class="team-home"
          [class.selected]="lastMatchesTeamTabSelected === teamTab.Home"
          (click)="lastMatchesTeamTabSelected = teamTab.Home"
        >
          <div class="name">{{ (statisticsQuery.lastMatchesData$ | async).homeTeamName }}</div>
          <div class="last-results">
            <i class="fa fa-caret-left" aria-hidden="true"></i>
            <span
              *ngFor="let result of (statisticsQuery.lastMatchesData$ | async).homeLastResults; trackBy: indexTrackBy"
              [class]="result.result"
              ><span>{{ result.result }}</span></span
            >
          </div>
        </div>
        <div
          class="team-away"
          [class.selected]="lastMatchesTeamTabSelected === teamTab.Away"
          (click)="lastMatchesTeamTabSelected = teamTab.Away"
        >
          <div class="name">{{ (statisticsQuery.lastMatchesData$ | async).awayTeamName }}</div>
          <div class="last-results">
            <i class="fa fa-caret-left" aria-hidden="true"></i>
            <span
              *ngFor="let result of (statisticsQuery.lastMatchesData$ | async).awayLastResults; trackBy: indexTrackBy"
              [class]="result.result"
              ><span>{{ result.result }}</span></span
            >
          </div>
        </div>
      </div>
      <div class="last-matches-history" [class.selected]="lastMatchesTeamTabSelected === teamTab.Home">
        <table>
          <tbody>
            <tr *ngFor="let match of (statisticsQuery.lastMatchesData$ | async).homeMatches; trackBy: indexTrackBy">
              <td class="team-home">
                <div class="team-name">
                  <span class="name" [ngClass]="{ won: match.winner === 'home' }">{{ match.teamHomeName }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamHomeLogo + ')' }"></span>
                  <span class="score">{{ match.teamHomeScore }}</span>
                </div>
                <div class="round">{{ match.round }}</div>
              </td>
              <td class="divider"><span transloco=":"></span></td>
              <td class="team-away">
                <div class="team-name">
                  <span class="score">{{ match.teamAwayScore }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamAwayLogo + ')' }"></span>
                  <span class="name" [ngClass]="{ won: match.winner === 'away' }">{{ match.teamAwayName }}</span>
                </div>
                <div class="date">{{ match.date }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="last-matches-history" [class.selected]="lastMatchesTeamTabSelected === teamTab.Away">
        <table>
          <tbody>
            <tr *ngFor="let match of (statisticsQuery.lastMatchesData$ | async).awayMatches; trackBy: indexTrackBy">
              <td class="team-home">
                <div class="team-name">
                  <span class="name" [ngClass]="{ won: match.winner === 'home' }">{{ match.teamHomeName }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamHomeLogo + ')' }"></span>
                  <span class="score">{{ match.teamHomeScore }}</span>
                </div>
                <div class="round">{{ match.round }}</div>
              </td>
              <td class="divider"><span transloco=":"></span></td>
              <td class="team-away">
                <div class="team-name">
                  <span class="score">{{ match.teamAwayScore }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamAwayLogo + ')' }"></span>
                  <span class="name" [ngClass]="{ won: match.winner === 'away' }">{{ match.teamAwayName }}</span>
                </div>
                <div class="date">{{ match.date }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--Next Matches-->
  <div
    class="header-tab"
    [class.selected]="headerTabSelected === headerTab.NextMatches"
    *ngIf="statisticsQuery.nextMatchesData$ | async"
    (click)="headerTabSelected = headerTab.NextMatches"
  >
    <i class="fa fa-long-arrow-right left-icon" aria-hidden="true"></i>
    <div transloco="Next 5 Matches"></div>
    <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
  </div>
  <div
    class="next-matches-content"
    [class.selected]="headerTabSelected === headerTab.NextMatches"
    *ngIf="statisticsQuery.nextMatchesData$ | async"
  >
    <!-- <div class="section-not-found" transloco="Next 5 Matches are not available for this match"></div> -->
    <div class="section-found">
      <div class="team-names">
        <div
          class="team-home"
          [class.selected]="nextMatchesTeamTabSelected === teamTab.Home"
          (click)="nextMatchesTeamTabSelected = teamTab.Home"
        >
          <div class="name">{{ (statisticsQuery.nextMatchesData$ | async).homeTeamName }}</div>
        </div>
        <div
          class="team-away"
          [class.selected]="nextMatchesTeamTabSelected === teamTab.Away"
          (click)="nextMatchesTeamTabSelected = teamTab.Away"
        >
          <div class="name">{{ (statisticsQuery.nextMatchesData$ | async).awayTeamName }}</div>
        </div>
      </div>
      <div class="next-matches-history" [class.selected]="nextMatchesTeamTabSelected === teamTab.Home">
        <table>
          <tbody>
            <tr *ngFor="let match of (statisticsQuery.nextMatchesData$ | async).homeMatches; trackBy: indexTrackBy">
              <td class="team-home">
                <div class="team-name">
                  <span class="name" [ngClass]="{ won: match.winner === 'home' }">{{ match.teamHomeName }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamHomeLogo + ')' }"></span>
                  <span class="score">-</span>
                </div>
                <div class="round">{{ match.round }}</div>
              </td>
              <td class="divider"><span transloco=":"></span></td>
              <td class="team-away">
                <div class="team-name">
                  <span class="score">-</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamAwayLogo + ')' }"></span>
                  <span class="name" [ngClass]="{ won: match.winner === 'away' }">{{ match.teamAwayName }}</span>
                </div>
                <div class="date">{{ match.date }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="next-matches-history" [class.selected]="nextMatchesTeamTabSelected === teamTab.Away">
        <table>
          <tbody>
            <tr *ngFor="let match of (statisticsQuery.nextMatchesData$ | async).awayMatches; trackBy: indexTrackBy">
              <td class="team-home">
                <div class="team-name">
                  <span class="name" [ngClass]="{ won: match.winner === 'home' }">{{ match.teamHomeName }}</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamHomeLogo + ')' }"></span>
                  <span class="score">-</span>
                </div>
                <div class="round">{{ match.round }}</div>
              </td>
              <td class="divider"><span transloco=":"></span></td>
              <td class="team-away">
                <div class="team-name">
                  <span class="score">-</span>
                  <span class="logo" [ngStyle]="{ 'background-image': 'url(' + match.teamAwayLogo + ')' }"></span>
                  <span class="name" [ngClass]="{ won: match.winner === 'away' }">{{ match.teamAwayName }}</span>
                </div>
                <div class="date">{{ match.date }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--Over / Under-->
  <div
    class="header-tab"
    [class.selected]="headerTabSelected === headerTab.OverUnder"
    *ngIf="statisticsQuery.overUnderData$ | async"
    (click)="headerTabSelected = headerTab.OverUnder"
  >
    <i class="fa fa-random left-icon" aria-hidden="true"></i>
    <div transloco="Over / Under"></div>
    <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
  </div>
  <div
    class="over-under-content"
    [class.selected]="headerTabSelected === headerTab.OverUnder"
    *ngIf="statisticsQuery.overUnderData$ | async"
  >
    <!-- <div class="section-not-found" transloco="Over / Under are not available for this match"></div> -->
    <div class="section-found">
      <div class="top-section">
        <div class="left-selection">
          <div class="top">
            <div
              class="over-under-tab over-under-matches-tabs selected"
              [class.selected]="overUnderMatchesSelected === matchesTab.All"
              (click)="overUnderMatchesSelected = matchesTab.All"
              transloco="All Matches"
            ></div>
            <div
              class="over-under-tab over-under-matches-tabs"
              [class.selected]="overUnderMatchesSelected === matchesTab.Home"
              (click)="overUnderMatchesSelected = matchesTab.Home"
              transloco="Home Matches"
            ></div>
            <div
              class="over-under-tab over-under-matches-tabs"
              [class.selected]="overUnderMatchesSelected === matchesTab.Away"
              (click)="overUnderMatchesSelected = matchesTab.Away"
              transloco="Away Matches"
            ></div>
          </div>
          <div class="bottom">
            <div
              class="over-under-tab over-under-segment-tabs"
              [class.selected]="overUnderSegmentSelected === segmentTab.FullTime"
              (click)="overUnderSegmentSelected = segmentTab.FullTime"
              transloco="Full Time"
            ></div>
            <div
              class="over-under-tab over-under-segment-tabs"
              [class.selected]="overUnderSegmentSelected === segmentTab.FirstHalf"
              (click)="overUnderSegmentSelected = segmentTab.FirstHalf"
              transloco="1st Half"
            ></div>
            <div
              class="over-under-tab over-under-segment-tabs"
              [class.selected]="overUnderSegmentSelected === segmentTab.SecondHalf"
              (click)="overUnderSegmentSelected = segmentTab.SecondHalf"
              transloco="2nd Half"
            ></div>
          </div>
        </div>
        <div class="right-selection">
          <span transloco="Over / Under:"></span>
          <div
            *ngFor="let item of statisticsQuery.overUnderData$ | async | objectKeys; trackBy: indexTrackBy"
            class="tab-container"
            [class.selected]="item == overUnderMatchesSelected + overUnderSegmentSelected"
          >
            <span
              *ngFor="let value of (statisticsQuery.overUnderData$ | async)[item]; trackBy: indexTrackBy"
              class="over-under-spread-tabs"
              [class.selected]="overUnderSpreadSelected === value.id"
              (click)="overUnderSpreadSelected = value.id"
              >{{ value.spread }}</span
            >
          </div>
        </div>
      </div>
      <div
        *ngFor="let item of statisticsQuery.overUnderData$ | async | objectKeys; trackBy: indexTrackBy"
        class="over-under-container"
        [class.selected]="item == overUnderMatchesSelected + overUnderSegmentSelected"
      >
        <table
          *ngFor="let value of (statisticsQuery.overUnderData$ | async)[item]; trackBy: indexTrackBy; let j = index"
          class="spread-table"
          [class.selected]="overUnderSpreadSelected === value.id"
        >
          <tbody>
            <tr>
              <th transloco="Team"></th>
              <th class="over" colspan="3" transloco="Over"></th>
              <th class="under" colspan="3" transloco="Under"></th>
            </tr>
            <tr *ngFor="let team of value.teams | orderBy : 'order'; trackBy: indexTrackBy">
              <td class="team">
                <div class="team-name">
                  <div class="logo" [ngStyle]="{ 'background-image': 'url(' + team.teamLogo + ')' }"></div>
                  <div class="name">{{ team.teamName }}</div>
                </div>
              </td>
              <td class="over">{{ team.over }}</td>
              <td class="over-perc">{{ team.overPerc }}%</td>
              <td class="over-perc-bar">
                <div class="status-bar">
                  <div [ngStyle]="{ width: team.overPerc + '%' }"></div>
                </div>
              </td>
              <td class="under-perc-bar">
                <div class="status-bar">
                  <div [ngStyle]="{ width: team.underPerc + '%' }"></div>
                </div>
              </td>
              <td class="under-perc">{{ team.underPerc }}%</td>
              <td class="under">{{ team.under }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!--Player Stats-->
  <div
    class="header-tab"
    [class.selected]="headerTabSelected === headerTab.PlayerStats"
    (click)="headerTabSelected = headerTab.PlayerStats"
    *ngIf="(statisticsQuery.goalscorerData$ | async) || (statisticsQuery.assistsData$ | async) || (statisticsQuery.cardsData$ | async)"
  >
    <i class="fa fa-futbol-o left-icon" aria-hidden="true"></i>
    <div transloco="Player Stats"></div>
    <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
  </div>
  <div
    class="player-stats-content"
    *ngIf="(statisticsQuery.goalscorerData$ | async) || (statisticsQuery.assistsData$ | async) || (statisticsQuery.cardsData$ | async)"
    [class.selected]="headerTabSelected === headerTab.PlayerStats"
  >
    <div>
      <div class="stats-tabs">
        <div
          class="player-stats-tab"
          [class.selected]="playerStatsTabSelected === statsTab.Goals"
          (click)="playerStatsTabSelected = statsTab.Goals"
          transloco="Goals"
        ></div>
        <div
          class="player-stats-tab"
          [class.selected]="playerStatsTabSelected === statsTab.Assists"
          (click)="playerStatsTabSelected = statsTab.Assists"
          transloco="Assists"
        ></div>
        <div
          class="player-stats-tab"
          [class.selected]="playerStatsTabSelected === statsTab.Cards"
          (click)="playerStatsTabSelected = statsTab.Cards"
          transloco="Cards"
        ></div>
      </div>
      <div class="team-names">
        <div
          class="team-home player-stats-matches-tabs"
          [class.selected]="playerStatsTeamTabSelected === teamTab.Home"
          (click)="playerStatsTeamTabSelected = teamTab.Home"
        >
          <div class="name">
            {{
              ((statisticsQuery.goalscorerData$ | async) && (statisticsQuery.goalscorerData$ | async).homeTeamName) ||
                ((statisticsQuery.assistsData$ | async) && (statisticsQuery.assistsData$ | async).homeTeamName) ||
                ((statisticsQuery.cardsData$ | async) && (statisticsQuery.cardsData$ | async).homeTeamName)
            }}
          </div>
        </div>
        <div
          class="team-away player-stats-matches-tabs"
          [class.selected]="playerStatsTeamTabSelected === teamTab.Away"
          (click)="playerStatsTeamTabSelected = teamTab.Away"
        >
          <div class="name">
            {{
              ((statisticsQuery.goalscorerData$ | async) && (statisticsQuery.goalscorerData$ | async).awayTeamName) ||
                ((statisticsQuery.assistsData$ | async) && (statisticsQuery.assistsData$ | async).awayTeamName) ||
                ((statisticsQuery.cardsData$ | async) && (statisticsQuery.cardsData$ | async).awayTeamName)
            }}
          </div>
        </div>
      </div>
      <div class="player-stats-container goalscorer-container" [class.selected]="playerStatsTabSelected === statsTab.Goals">
        <div
          class="section-not-found"
          *ngIf="(statisticsQuery.goalscorerData$ | async) === undefined"
          transloco="Goals are not available for this match"
        ></div>
        <div class="section-found" *ngIf="statisticsQuery.goalscorerData$ | async">
          <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Home">
            <table>
              <thead>
                <tr>
                  <th transloco="Pos"></th>
                  <th transloco="Player"></th>
                  <th transloco="Matches"></th>
                  <th transloco="Goals"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let player of (statisticsQuery.goalscorerData$ | async).homeGoalscorers; trackBy: indexTrackBy">
                  <td class="position">{{ player.position }}</td>
                  <td class="team">
                    <div class="team-name">
                      <div class="logo" [ngStyle]="{ 'background-image': 'url(' + player.nationalityFlag + ')' }"></div>
                      <div class="name">{{ player.name }}</div>
                    </div>
                  </td>
                  <td class="stats">{{ player.matches }}</td>
                  <td class="stats">{{ player.goals }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Away">
            <table>
              <thead>
                <tr>
                  <th transloco="Pos"></th>
                  <th transloco="Player"></th>
                  <th transloco="Matches"></th>
                  <th transloco="Goals"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let player of (statisticsQuery.goalscorerData$ | async).awayGoalscorers; trackBy: indexTrackBy">
                  <td class="position">{{ player.position }}</td>
                  <td class="team">
                    <div class="team-name">
                      <div class="logo" [ngStyle]="{ 'background-image': 'url(' + player.nationalityFlag + ')' }"></div>
                      <div class="name">{{ player.name }}</div>
                    </div>
                  </td>
                  <td class="stats">{{ player.matches }}</td>
                  <td class="stats">{{ player.goals }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="player-stats-container assists-container" [class.selected]="playerStatsTabSelected === statsTab.Assists">
        <div
          class="section-not-found"
          *ngIf="(statisticsQuery.assistsData$ | async) === undefined"
          transloco="Assists are not available for this match"
        ></div>
        <div class="section-found" *ngIf="statisticsQuery.assistsData$ | async">
          <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Home">
            <table>
              <thead>
                <tr>
                  <th transloco="Pos"></th>
                  <th transloco="Player"></th>
                  <th transloco="Matches"></th>
                  <th transloco="Assists"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let player of (statisticsQuery.assistsData$ | async).homeAssists; trackBy: indexTrackBy">
                  <td class="position">{{ player.position }}</td>
                  <td class="team">
                    <div class="team-name">
                      <div class="logo" [ngStyle]="{ 'background-image': 'url(' + player.nationalityFlag + ')' }"></div>
                      <div class="name">{{ player.name }}</div>
                    </div>
                  </td>
                  <td class="stats">{{ player.matches }}</td>
                  <td class="stats">{{ player.assists }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Away">
            <table>
              <thead>
                <tr>
                  <th transloco="Pos"></th>
                  <th transloco="Player"></th>
                  <th transloco="Matches"></th>
                  <th transloco="Assists"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let player of (statisticsQuery.assistsData$ | async).awayAssists; trackBy: indexTrackBy">
                  <td class="position">{{ player.position }}</td>
                  <td class="team">
                    <div class="team-name">
                      <div class="logo" [ngStyle]="{ 'background-image': 'url(' + player.nationalityFlag + ')' }"></div>
                      <div class="name">{{ player.name }}</div>
                    </div>
                  </td>
                  <td class="stats">{{ player.matches }}</td>
                  <td class="stats">{{ player.assists }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="player-stats-container cards-container" [class.selected]="playerStatsTabSelected === statsTab.Cards">
        <div
          class="section-not-found"
          *ngIf="(statisticsQuery.cardsData$ | async) === undefined"
          transloco="Cards are not available for this match"
        ></div>
        <div class="section-found" *ngIf="statisticsQuery.cardsData$ | async">
          <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Home">
            <table>
              <thead>
                <tr>
                  <th transloco="Pos"></th>
                  <th transloco="Player"></th>
                  <th transloco="Matches"></th>
                  <th>
                    <span class="cards-icon">
                      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ffbc3c"></rect>
                        </g>
                      </svg>
                    </span>
                  </th>
                  <th>
                    <span class="cards-icon">
                      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <rect width="74.006226" height="111.00934" rx="9.2507782" ry="9.2507782" x="0" y="0" fill="#ffbc3c"></rect>
                          <rect
                            width="74.006226"
                            height="111.00934"
                            rx="9.2507782"
                            ry="9.2507782"
                            x="36.828461"
                            y="-7.268764"
                            transform="matrix(0.98480775,0.17364818,-0.17364818,0.98480775,0,0)"
                            fill="#ff131a"
                          ></rect>
                        </g>
                      </svg>
                    </span>
                  </th>
                  <th>
                    <span class="cards-icon">
                      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ff131a"></rect>
                        </g>
                      </svg>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let player of (statisticsQuery.cardsData$ | async).homeCards; trackBy: indexTrackBy">
                  <td class="position">{{ player.position }}</td>
                  <td class="team">
                    <div class="team-name">
                      <div class="logo" [ngStyle]="{ 'background-image': 'url(' + player.nationalityFlag + ')' }"></div>
                      <div class="name">{{ player.name }}</div>
                    </div>
                  </td>
                  <td class="stats">{{ player.matches }}</td>
                  <td class="cards">{{ player.yellowCards }}</td>
                  <td class="cards">{{ player.yellowRedCards }}</td>
                  <td class="cards">{{ player.redCards }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="player-stats-history" [class.selected]="playerStatsTeamTabSelected === teamTab.Away">
            <table>
              <thead>
                <tr>
                  <th transloco="Pos"></th>
                  <th transloco="Player"></th>
                  <th transloco="Matches"></th>
                  <th>
                    <span class="cards-icon">
                      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ffbc3c"></rect>
                        </g>
                      </svg>
                    </span>
                  </th>
                  <th>
                    <span class="cards-icon">
                      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <rect width="74.006226" height="111.00934" rx="9.2507782" ry="9.2507782" x="0" y="0" fill="#ffbc3c"></rect>
                          <rect
                            width="74.006226"
                            height="111.00934"
                            rx="9.2507782"
                            ry="9.2507782"
                            x="36.828461"
                            y="-7.268764"
                            transform="matrix(0.98480775,0.17364818,-0.17364818,0.98480775,0,0)"
                            fill="#ff131a"
                          ></rect>
                        </g>
                      </svg>
                    </span>
                  </th>
                  <th>
                    <span class="cards-icon">
                      <svg viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
                        <g>
                          <rect width="80" height="120" rx="10" ry="10" x="20" y="0" fill="#ff131a"></rect>
                        </g>
                      </svg>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let player of (statisticsQuery.cardsData$ | async).awayCards; trackBy: indexTrackBy">
                  <td class="position">{{ player.position }}</td>
                  <td class="team">
                    <div class="team-name">
                      <div class="logo" [ngStyle]="{ 'background-image': 'url(' + player.nationalityFlag + ')' }"></div>
                      <div class="name">{{ player.name }}</div>
                    </div>
                  </td>
                  <td class="stats">{{ player.matches }}</td>
                  <td class="cards">{{ player.yellowCards }}</td>
                  <td class="cards">{{ player.yellowRedCards }}</td>
                  <td class="cards">{{ player.redCards }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--League Ranking-->
  <div
    class="header-tab"
    [class.selected]="headerTabSelected === headerTab.LeagueRanking"
    *ngIf="statisticsQuery.leagueRankingData$ | async"
    (click)="headerTabSelected = headerTab.LeagueRanking"
  >
    <i class="fa fa-list-ol left-icon" aria-hidden="true"></i>
    <div transloco="League Ranking"></div>
    <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
  </div>
  <div
    class="league-ranking-content"
    [class.selected]="headerTabSelected === headerTab.LeagueRanking"
    *ngIf="statisticsQuery.leagueRankingData$ | async"
  >
    <!-- <div class="section-not-found" transloco="League Ranking are not available for this match"></div> -->
    <div class="section-found">
      <table>
        <tr>
          <th transloco="Pos"></th>
          <th transloco="Team"></th>
          <th transloco="P"></th>
          <th transloco="W"></th>
          <th transloco="D"></th>
          <th transloco="L"></th>
          <th transloco="GD"></th>
          <th transloco="Pts"></th>
        </tr>
        <tr *ngFor="let team of (statisticsQuery.leagueRankingData$ | async).teams | orderBy : 'position'; trackBy: indexTrackBy">
          <td class="position">{{ team.position }}</td>
          <td class="team">
            <div class="team-name">
              <div class="logo" [ngStyle]="{ 'background-image': 'url(' + team.teamLogo + ')' }"></div>
              <div class="name">{{ team.teamName }}</div>
            </div>
          </td>
          <td class="stats">{{ team.played }}</td>
          <td class="stats">{{ team.won }}</td>
          <td class="stats">{{ team.drawn }}</td>
          <td class="stats">{{ team.lost }}</td>
          <td class="stats">{{ team.goalsDifference }}</td>
          <td class="points">{{ team.points }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="header-tab" (click)="statisticsBetradarPopup(externalId, languageService.selectedLanguage)">
    <i class="fa fa-area-chart left-icon" aria-hidden="true"></i>
    <div transloco="More Statistics"></div>
    <i class="fa fa-caret-right right-icon" aria-hidden="true"></i>
  </div>
</div>
