import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { TranslocoService, translate } from '@ngneat/transloco';
import { indexOf } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { MyBetsStore } from 'src/app/core/state/my-bets/my-bets.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BetCashoutModel, BetCashoutResponseModel, CashoutStatus, CashoutStatusModel } from 'src/app/shared/models/cashout.model';
import { AccountQuery } from '../state/account/account.query';
import { CashoutQuery } from './../state/cashout/cashout.query';
import { APIService } from './api.service';
import { NotificationService } from './notification.service';

export interface CashoutErrorCodeMap {
  [key: number]: string;
}

@Injectable({
  providedIn: 'root'
})
export class CashoutService {
  refreshInterval = undefined;

  readonly cashoutErrorCodes: CashoutErrorCodeMap = {
    105: translate('Cashout unavailable when in-play'),
    300: translate('An error has occurred during the cashing out process. Please try again'),
    301: translate('Cash out for this coupon has already been requested'),
    302: translate("The number of odds being cashed out doesn't match the ones in the coupon"), // TODO: Better explanation
    303: translate('The offered cash out value count is smaller than the coupons to cash out count'), // TODO: Better explanation
    304: translate('An error has occurred during the cashing out process. Please try again'),
    305: translate('An unhandled error has occurred during cash out. Please try again.'),
    306: translate('Live not available for started match'),
    307: translate('Timeout exceeded for cashout request after cashout value calculation'),
    308: translate('Out of leeway'),
    309: translate('Hash integrity violation')
  };
  private readonly refreshTimer = 5000;

  constructor(
    private readonly myBetsStore: MyBetsStore,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly notificationService: NotificationService,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly cashoutStore: CashoutStore,
    private readonly cashoutQuery: CashoutQuery,

    private readonly translocoService: TranslocoService
  ) {}

  refreshCashout(id: ID, couponCode: string): Observable<boolean> {
    if (!couponCode) {
      return new Observable<boolean>();
    }

    this.cashoutStore.setActive(id);
    this.cashoutStore.cashoutInProgress(true);

    return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/cashoutvalue_new/${couponCode}`).pipe(
      finalize(() => {
        this.cashoutStore.cashoutInProgress(false);
      }),
      map(responseData => {
        if (!responseData) {
          return false;
        }

        const betCashout: BetCashoutModel = this.parseBetCashout(responseData);
        if (betCashout) {
          this.cashoutStore.updateBetCashoutData(betCashout);
        }
        return true;
      })
    );
  }

  cashout(id: ID, cashoutData: any, inBehalfOf: string): Observable<boolean> {
    if (!cashoutData) {
      return new Observable<boolean>();
    }

    const apiSettings: APISettings = new APISettings();
    if (inBehalfOf) {
      apiSettings.inBehalfOf = inBehalfOf;
    }

    this.cashoutStore.setActive(id);
    this.cashoutStore.cashoutInProgress(true);

    return this.apiService.post<any>(APIType.Sportsbook, `api/coupons/cashout_new`, [cashoutData], apiSettings).pipe(
      finalize(() => {
        this.cashoutStore.cashoutInProgress(false);
      }),
      map(responseData => {
        let cashoutResponse: BetCashoutResponseModel;

        if (!responseData) {
          cashoutResponse = this.parseCashoutResponse(undefined);
        } else {
          cashoutResponse = this.parseCashoutResponse(responseData);
        }

        if (cashoutResponse) {
          this.cashoutStore.updateCashoutResponseData(cashoutResponse);
        }
        return true;
      })
    );
  }

  addToPendingList(cashoutId: number, couponCode: string): void {
    this.cashoutStore.addToPendingList(cashoutId, couponCode);
  }

  pendingCashouts(): Observable<boolean> {
    this.cashoutStore.clearPendingCashoutList();

    return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/pendingcashout`).pipe(
      map(responseData => {
        if (!responseData || !responseData.length) {
          this.cashoutStore.clearPendingCashoutList();
          return false;
        }

        responseData.forEach(cashout => {
          this.cashoutStore.addToPendingList(cashout.CashoutID, cashout.CouponCode);
        });
        return true;
      })
    );
  }

  syncPendingCashouts(): Observable<any> {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }

    const pendingCashoutList = this.cashoutQuery.pendingCashoutList();

    const apiCalls: Observable<any>[] = [];
    pendingCashoutList.forEach(pendingCashout => {
      apiCalls.push(this.apiService.get<any>(APIType.Sportsbook, `api/coupons/pendingcashout/byCode/${pendingCashout.cashoutId}`));
    });

    return forkJoin(apiCalls).pipe(
      map(responseData => {
        responseData.forEach(data => {
          if (!data) {
            return;
          }

          const parsedData = this.parseCashoutStatusData(data);

          if (parsedData === undefined || parsedData.status === CashoutStatus.pending) {
            return;
          }

          this.cashoutStore.cashoutRequested(false);

          if (parsedData.status === CashoutStatus.approved) {
            this.cashoutStore.removeFromPendingList(parsedData.cashoutId);

            this.accountService.updateBalance();
            this.notificationService.showSuccessNotification(
              translate('Cashout is successfull ({{cashoutValue}})!', {
                cashoutValue: `${parsedData.cashoutValue} ${parsedData.currencySymbol}`
              })
            );
          } else if (parsedData.status === CashoutStatus.rejected) {
            this.cashoutStore.removeFromPendingList(parsedData.cashoutId);

            const updateBetCashout = new BetCashoutModel({
              availability: true,
              value: parsedData.cashoutValue
            });
            this.cashoutStore.updateBetCashoutData(updateBetCashout);

            this.notificationService.showErrorNotification(
              translate('Cashout for coupon {{couponCode}} has been rejected by operator.', {
                couponCode: parsedData.couponCode
              })
            );
          }
        });

        if (this.accountQuery.isAuthenticated) {
          this.refreshInterval = window.setInterval(() => {
            this.syncPendingCashouts().subscribe();
          }, this.refreshTimer);
        }
      })
    );
  }

  checkPendingCashouts(): void {
    this.accountQuery.isAuthenticated$.subscribe(auth => {
      if (auth) {
        this.pendingCashouts().subscribe(() => {
          this.syncPendingCashouts().subscribe();
        });
      } else {
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
        }

        this.myBetsStore.clear();
      }
    });
  }

  parseBetCashoutResponse(response: any): BetCashoutModel {
    // the list of CashoutResult codes which will show a message
    // to users if cashout is not available
    const codesWithDisplayMessage = [
      105 // coupon contains live events
    ];

    // the list of CashoutResult codes which will be shown the
    // 'Cash-out temporarily unavailable' generic message
    const temporarilyUnavailableCodes = this.appConfig.get('sports').cashout.unavailableCodes;

    const betCashout = new BetCashoutModel({
      availability: false,
      result: 2,
      value: -1,
      message: ''
    });

    if (!response || !response.length) {
      return betCashout;
    }

    // Need to store the original object since now it needs to be sent back during cashout
    betCashout.serverData = response[0];
    betCashout.availability = response[0].CashoutAvailability;
    betCashout.result = response[0].CashoutResult;

    if (betCashout.availability) {
      betCashout.value = response[0].CashoutValue;
      betCashout.valueNet = response[0].NetOfferedValue;
      betCashout.tax = response[0].WithholdingTax;
    } else if (indexOf(temporarilyUnavailableCodes, betCashout.result) > -1) {
      betCashout.message = translate('Cash-Out Temporarily Unavailable');
    } else if (indexOf(codesWithDisplayMessage, betCashout.result) > -1) {
      betCashout.message = this.getCashoutError(betCashout.result);
    }

    return betCashout;
  }

  getCashoutError(errorCode: number): string {
    return this.cashoutErrorCodes[errorCode];
  }

  isWhitelistedUserType$(): Observable<boolean> {
    // If not logged in, the default user type would be a USER, so not blacklisted
    return this.accountQuery.userData$.pipe(
      map(userData =>
        userData ? !(this.appConfig.get('sports').cashout.blackListedUserTypes || []).includes(userData.userTypeCode) : true
      )
    );
  }

  private parseBetCashout(response: any): BetCashoutModel {
    if (!response || !response.length) {
      return new BetCashoutModel({});
    }

    const betCashout = new BetCashoutModel({
      availability: response[0].CashoutAvailability,
      result: response[0].CashoutResult,
      value: response[0].CashoutValue,
      valueNet: response[0].NetOfferedValue,
      tax: response[0].WithholdingTax,
      serverData: response[0] // Need to store the original object since now it needs to be sent back during cashout
    });
    return betCashout;
  }

  private parseCashoutResponse(response: any): BetCashoutResponseModel {
    const cashoutResponse = new BetCashoutResponseModel({
      success: false,
      cashoutAccepted: false,
      userMessage: '',
      responseCode: 300,
      cashoutId: -1,
      couponCode: '',
      cashoutValue: -1,
      currencySymbol: ''
    });

    if (!response || !response.length) {
      return cashoutResponse;
    }

    cashoutResponse.success = response[0]?.CashoutRequested;
    cashoutResponse.cashoutAccepted = response[0]?.CashoutAccepted;
    cashoutResponse.responseCode = response[0]?.CashoutResult;
    cashoutResponse.cashoutId = response[0]?.CashoutId;
    cashoutResponse.couponCode = response[0]?.CouponCode;
    cashoutResponse.cashoutValue = response[0]?.CashoutValue;
    cashoutResponse.currencySymbol = response[0]?.Currency?.CurrencySymbol;

    // parse error message
    if (!cashoutResponse.success) {
      let errorMessage = translate('An error has occurred during the cashing out process. Please try again ({{responseCode}})', {
        responseCode: cashoutResponse.responseCode
      });

      if (cashoutResponse.responseCode >= 300 && cashoutResponse.responseCode < 400) {
        // error codes 300+ have specific messages
        errorMessage = this.getCashoutError(cashoutResponse.responseCode);

        if (cashoutResponse.responseCode === 304) {
          const betCashout: BetCashoutModel = new BetCashoutModel({
            value: response[0].CashoutValue
          });
          this.cashoutStore.updateBetCashoutData(betCashout);
        } else {
          this.cashoutStore.updateBetCashoutData(undefined);
        }
      }
      cashoutResponse.userMessage = errorMessage;
    } else {
      cashoutResponse.userMessage = translate('Cashout is being evaluated by operator. You will be notified shortly with the result.');
    }

    return cashoutResponse;
  }

  private parseCashoutStatusData(response: any): CashoutStatusModel {
    const cashoutResponse = new CashoutStatusModel({
      status: CashoutStatus.pending,
      cashoutId: -1,
      couponCode: '',
      cashoutValue: -1,
      currencySymbol: ''
    });

    if (response === undefined) {
      return cashoutResponse;
    }

    if (response.Status === 2) {
      cashoutResponse.status = CashoutStatus.approved;
    } else if (response.Status === 3) {
      cashoutResponse.status = CashoutStatus.rejected;
    }
    cashoutResponse.cashoutId = response.CashoutID;
    cashoutResponse.couponCode = response.CouponCode;
    cashoutResponse.cashoutValue = response.NetOfferedValue;
    cashoutResponse.currencySymbol = response.IDCurrency.CurrencySymbol;

    return cashoutResponse;
  }
}
