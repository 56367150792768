import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

@Injectable()
export class HttpOAuth2Interceptor implements HttpInterceptor {
  constructor(private readonly accountQuery: AccountQuery, private readonly appConfig: AppConfigService) {}

  intercept(initialRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken: string;
    let request: HttpRequest<any> = initialRequest.clone();
    const activitySource: string = this.appConfig.get('activitySource');

    if (request.headers.has('VND.noAuthToken')) {
      // no auth token should be added in this case
      request = request.clone({ headers: request.headers.delete('VND.noAuthToken') });
    } else if (request.headers.has('VND.forceAuthToken')) {
      accessToken = request.headers.get('VND.forceAuthToken');
      request = request.clone({ headers: request.headers.delete('VND.forceAuthToken') });
    } else if (this.accountQuery.isAuthenticated) {
      accessToken = this.accountQuery.accessToken;
    }

    const isSportRadarReq = request.url.startsWith('https://lmt.fn.sportradar.com');

    const spinePay = request.url.startsWith('https://pgw.spinepay');

    if (accessToken && !isSportRadarReq) {
      request = request.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` }
      });

      if (activitySource && !spinePay) {
        request = request.clone({
          setHeaders: { ActivitySource: activitySource.toString() }
        });
      }
    }

    return next.handle(request);
  }
}
