<div class="recent-bets-container" *ngIf="bets">
  <!--
    *****
    *****   Settled bets (won/lost)
    *****
  -->
  <div class="settled-status" *ngIf="isSettled">
    <div
      (click)="selectCouponStatus(betFinalState.Won)"
      [class.selected]="selectedCouponStatus === betFinalState.Won"
      class="status"
      transloco="Won"
    ></div>
    <div
      (click)="selectCouponStatus(betFinalState.Lost)"
      [class.selected]="selectedCouponStatus === betFinalState.Lost"
      class="status"
      transloco="Lost"
    ></div>
  </div>

  <!--
    *****
    *****   Loading bar
    *****
  -->
  <app-loading-bar *ngIf="!bets && loading" [show]="loading" [transparent]="true"></app-loading-bar>

  <ng-container *ngIf="(bets && bets.length > 0) || !loading">
    <!--
      *****
      *****   List of bets
      *****
    -->
    <div class="bet-content">
      <!--
        ***
        ***   Bets in evaluation
        ***
      -->
      <ng-container *ngIf="!isSettled && displayEvaluationBets">
        <app-recent-bet
          *ngFor="let item of evaluationQuery.evaluationList$ | async"
          [bet]="item.coupon"
          [evaluation]="true"
          (refresh)="onRefresh()"
        >
        </app-recent-bet>
      </ng-container>

      <!--
        ***
        ***   List of placed bets
        ***
      -->
      <div class="wrapper">
        <div *ngFor="let bet of bets; let idx = index; trackBy: myBetsTrackBy">
          <div class="recent-bets-date" *ngIf="idx === 0 || isNotSameDay(bets[idx], bets[idx - 1])">
            <ng-container *ngIf="isBetToday(bet.couponDate)"><span transloco="Today"></span> - </ng-container
            >{{ bet.couponDate | formatDate : 'dd.MM.yyyy.' }}
          </div>
          <app-recent-bet [bet]="bet" *ngIf="isBetNotInEvaluation(bet)" [isFirst]="idx === 0" (refresh)="onRefresh()"></app-recent-bet>
        </div>
      </div>
    </div>

    <!--
      *****
      *****   No bets (empty screen)
      *****
    -->
    <div class="warning-message-wrapper" *ngIf="bets.length === 0 && !couponCheckOrDetailsPage()">
      <div class="warning-text" transloco="There are no bets for the selected time period."></div>
      <div class="place-a-bet">
        <button (click)="navigateToRoute('/')" transloco="Place a bet"></button>
      </div>
    </div>
    <!--
      *****
      *****   Bet search CTA
      *****
    -->
    <div *ngIf="false" class="bet-search">
      <div
        class="label"
        transloco="Activity shown for the past 7 days, and most recent 20 bets. To view more data, go to bet search."
      ></div>
      <div
        (click)="navigateToRoute('/account/bet-search')"
        [category]="'Go To Bet List Button'"
        [click]="true"
        analytics
        class="secondary-button small"
      >
        {{ 'Go to Bet List' | transloco }}
      </div>
      <!-- <app-button [text]="'Go to Bet List' | transloco" [customButtonStyle]="{fontWeight: 'normal'}"
        (click)="navigateToRoute('/account/bet-search')" analytics [click]='true' [category]="'Go To Bet List Button'">
      </app-button> -->
    </div>
  </ng-container>
</div>
