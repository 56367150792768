import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { format } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { UserModel } from 'src/app/shared/models/account.model';

@Component({
  selector: 'app-account-side-menu',
  templateUrl: './account-side-menu.component.html',
  styleUrls: ['./account-side-menu.component.scss']
})
export class AccountSideMenuComponent implements OnInit, OnDestroy {
  loginDate$: BehaviorSubject<string> = new BehaviorSubject(format(new Date(this.accountQuery.userData?.loginDate), 'dd/MM/yyyy, HH:mm'));
  isVerified: string;
  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appService: ApplicationService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly accountPaymentService: AccountPaymentService
  ) {}

  ngOnInit(): void {
    document.body?.classList?.add('disable-scrolling');

    this.accountService.getUserData(this.accountQuery.accessToken).subscribe();

    this.userData$.subscribe(data => {
      if (data) {
        this.loginDate$.next(format(new Date(data.loginDate), 'dd/MM/yyyy, HH:mm'));
      }
    });
    this.observeBlinkingStatus();
  }

  ngOnDestroy(): void {
    document.body?.classList?.remove('disable-scrolling');
  }

  close(): void {
    this.appService.showAccountMenu$.next(false);
  }

  logout(): void {
    this.accountService.logout().subscribe(res => {
      if (res) {
        this.notificationService.showSuccessMessage(translate('Logout successful'));
      }
    });
    this.router.navigateByUrl('/');
    this.close();
  }

  showTransactions(): void {
    this.appService.showTransactionsMenu$.next(true);
    this.close();
  }

  openInfos(): void {
    this.router.navigateByUrl('/help');
    this.close();
  }

  openLiveChat(): void {
    this.close();
  }
  openDepositPage(): void {
    if (this.accountPaymentService.showingIframe$.value) {
      this.accountPaymentService.showingIframe$.next(false);
    } else {
      this.router.navigate(['account/deposit']);
    }
    this.close();
  }
  openWithdrawalPage(): void {
    this.router.navigate(['account/withdrawal']);
    this.close();
  }
  observeBlinkingStatus(): void {
    this.accountService.getKYCStatus(this.accountQuery.accessToken).subscribe(() => {
      let tempIsVerified = this.accountService.getKYCDocumentsStatus();

      if (tempIsVerified === 'documentsNeeded' || tempIsVerified === 'accountNumberNeeded') {
        this.accountService.getBlinkingSessionStatus(this.accountQuery.accessToken).subscribe(() => {
          tempIsVerified = this.accountService.getKYCDocumentsStatus();
          this.isVerified = tempIsVerified; // Update isVerified when needed
        });
      } else {
        this.isVerified = tempIsVerified; // Update isVerified immediately if conditions not met
      }
    });
  }
  get userData(): UserModel {
    return this.accountQuery.userData;
  }

  get userData$(): Observable<UserModel> {
    return this.accountQuery.userData$;
  }

  get totalFunds$(): Observable<number> {
    return this.accountQuery.totalFunds$;
  }

  get mainWalletFunds$(): Observable<number> {
    return this.accountQuery.userData$.pipe(map(user => user.wallets.find(wallet => !wallet.isBonusWallet)?.balance ?? 0));
  }

  get bonusWallets$() {
    return this.accountQuery.userData$.pipe(map(user => user.wallets.filter(wallet => wallet.isBonusWallet)));
  }
}
