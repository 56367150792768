import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MetaDataState } from 'src/app/modules/meta/models/meta-data.model';
import { MetaDataStore } from 'src/app/modules/meta/store/meta-data.store';

@Injectable({ providedIn: 'root' })
export class MetaDataQuery extends Query<MetaDataState> {
  metaData$ = this.select(state => state.metaData);
  cmsUrl$ = this.select(state => state.cmsUrl);
  language$ = this.select(state => state.language);

  get cmsUrl(): any {
    return this.getValue().cmsUrl;
  }

  get metaData(): any {
    return this.getValue().metaData;
  }

  get language(): any {
    return this.getValue().language;
  }

  constructor(protected store: MetaDataStore) {
    super(store);
  }
}
