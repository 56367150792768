import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {kebabCase} from "lodash-es";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {ApplicationService} from 'src/app/core/services/application.service';
import {BonusService} from 'src/app/core/services/bonus.service';
import {MenuService} from 'src/app/core/services/menu.service';
import {AccountQuery} from 'src/app/core/state/account/account.query';
import {ApplicationQuery} from 'src/app/core/state/application/application.query';
import {MenuQuery} from 'src/app/core/state/menu/menu.query';
import {MenuTab, MenuTabModel} from 'src/app/shared/models/menu.model';
import {smoothScrollTo} from '../../../utils/smooth-scroll-to';

@Component({
  selector: 'app-menu-tabs',
  templateUrl: './menu-tabs.component.html',
  styleUrls: ['./menu-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuTabsComponent implements OnInit, OnDestroy {
  menuTab = MenuTab;
  menuTabsIcons = {
    0: 'sports-atoz',
    1: 'bonus',
    2: 'virtuals',
    3: 'quicklinks',
    4: 'how-to-play'
  };
  tabs: MenuTabModel[];

  private activeTab!: string;


  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly menuService: MenuService,
    private readonly bonusService: BonusService,
    readonly applicationQuery: ApplicationQuery,
    readonly applicationService: ApplicationService,
    readonly menuQuery: MenuQuery,
    readonly accountQuery: AccountQuery,
    readonly router: Router,
    readonly route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    if (!this.accountQuery.hasBonusInfo) {
      this.bonusService.getBonuses().subscribe();
    }

    this.applicationQuery.sideBarMenu$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.tabs = res;
      this.route
        .paramMap
        .pipe(takeUntil(this.destroy$))
        .subscribe(this.handleRouteParams.bind(this));
    })

    this.applicationService.getSideMenuTabs();
    smoothScrollTo({top: 0});
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  changeTab(tab: any, section?: any): void {
    const routeArray = tab ? section ? ['menu', kebabCase(tab.title), section] : ['menu', kebabCase(tab.title)] : ['menu']
    this.menuService.changeTab(tab.type);
    this.router.navigate(routeArray);
  }

  private handleRouteParams(params: ParamMap): void {
    this.activeTab = params.get('tab');
    const section = params.get('section');
    const activeTab = this.tabs.find(res => kebabCase(res.title) === this.activeTab);
    if (activeTab) {
      this.changeTab(activeTab, section);
    }
  }


}
