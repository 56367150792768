import { OddModel } from 'src/app/shared/models/coupon.model';

export type EventType = 'prematch' | 'outright' | 'special' | 'goalscorer';

export class SportState {
  match: SportModel;
  selectedPrematch: EventSummaryModel[];
  sportsList: SportModel[];
  eventSelection: EventSelectionState;
  selectedPlayerIds: number[];
  playersData: PlayerViewModel[];
  isItCorrectScore: boolean;
  areas: AreaModel[];
  regions: RegionModel[];
  ui: SportUIState;
  favouriteSports: number[];

  constructor(init: Partial<SportState>) {
    Object.assign(this, init);
  }
}

export class EventSelectionState {
  selectedSport: SportModel;
  isSportsListOpened: boolean;
  quickLinksState: QuicklinksState;
  selectedQuicklink: SportQuicklink;
  eventSelectionQuicklinks: SportQuicklinks;
  competitionsAZ: FlattenedSportModel[];
  allCompetitionByCountry: SportModel[];
  topCompetitions: MatchModel[];
  outrights: SportModel[];
  specialSports: SportModel[];
  goalscorerSport: SportModel[];
  eventSelectionDepth: number;
  selectedMarket: MarketModel;
  selectedArea: AreaModel;
  selectedAreaId: number;
  areaMarkets: MarketModel[];
  filterToDepthFour: boolean;
  areaAndRegionCache: { areas: AreaModel[]; regions: RegionModel[]; visible: boolean }[];
  visibleCardsAtDepthFour: boolean[];
  selectedIdsAtDepthFour: number[];
  restoreAreaRegionsCache: boolean;
  eventSelectionLoaded: boolean;
  autoForwardHappened: boolean;

  constructor(init: Partial<EventSelectionState>) {
    Object.assign(this, init);
  }
}

export enum QuicklinksState {
  Pending,
  Success,
  Failed
}

export interface PlayerViewModel {
  tournamentId: number;
  data: PlayerViewDataModel[];
}

export type PlayerViewDataModel = [TeamDataForPlayerViewModel, TeamDataForPlayerViewModel, OddModel[]];

export class SportUIState {
  preMatchCloseAll: boolean;
  filterQuery: string;

  constructor(init: Partial<SportUIState>) {
    Object.assign(this, init);
  }
}

export class TeamDataForPlayerViewModel {
  extParentTeamID: number;
  data: MatchModel[];

  constructor(init: Partial<TeamDataForPlayerViewModel>) {
    Object.assign(this, init);
  }
}

export enum Team {
  Home,
  Away
}

export enum MarketType {
  Default = 0,
  Multiline = 1
}

export enum GroupingType {
  Prematch = 0,
  Outright = 1,
  GoalScorer = 2
}

export class SportModel {
  id: number;
  menuUniqueId: string;
  name: string;
  groupingType: GroupingType = GroupingType.Prematch;
  order: number = -1;
  oddCount: number = 0;
  categories: CategoryModel[] = [];

  constructor(init: Partial<SportModel>) {
    Object.assign(this, init);
  }
}

export class FlattenedSportModel {
  id: number;
  name: string;
  order: number = -1;
  oddCount: number = 0;
  tournaments: TournamentModel[];

  constructor(init: Partial<FlattenedSportModel>) {
    Object.assign(this, init);
  }
}

export class CategoryModel {
  id: number;
  name: string;
  oddCount: number = 0;
  selectedInView: boolean = false;
  sportId: number;
  menuUniqueId: string;
  tournaments: TournamentModel[] = [];

  constructor(init: Partial<CategoryModel>) {
    Object.assign(this, init);
  }
}

export class TournamentModel {
  id: number;
  name: string;
  categoryId: number;
  eventCount: number = 0;
  oddCount: number = 0;
  selectedInView: boolean = false;
  regions: RegionModel[] = [];
  matches: MatchModel[] = [];
  initRegionId: number = 0;
  initAreaId: number = 0;
  order: number = 0;

  constructor(init: Partial<TournamentModel>) {
    Object.assign(this, init);
  }
}

export class RegionModel {
  id: number;
  name: string;
  order: number = -1;
  isDefault: boolean = false; // Set to true if no region data is provided by api, and one is being created for structure purposes
  areas: AreaModel[];
  areaIds: number[];
  belongsTo: number[];

  constructor(init: Partial<RegionModel>) {
    Object.assign(this, init);
  }
}

export class AreaModel {
  id: number;
  name: string;
  order: number = -1;
  isDefault: boolean = false; // Set to true if no area data is provided by api, and one is being created for structure purposes
  groupingType: GroupingType = GroupingType.Prematch; // Duplicated with MarketModel to cater for different api responses
  markets: MarketModel[] = [];
  belongsTo: number[];

  constructor(init: Partial<AreaModel>) {
    Object.assign(this, init);
  }
}

export class MarketModel {
  id: number;
  name: string;
  groupingType: GroupingType = GroupingType.Prematch; // Duplicated with AreaModel to cater for different api responses
  marketType: MarketType = MarketType.Default; // Maps to MultilineType
  spreadValue: number; // Maps to SpecialBetValue
  spreadDisplayValue: string; // Maps to SpecialValueDisplay
  overUnderType?: number;
  selections: SelectionModel[];
  description: string;
  typeId: number;
  order: number;

  constructor(init: Partial<MarketModel>) {
    Object.assign(this, init);
  }
}

export class MarketModelSpreadValueGroup extends MarketModel {
  spreadValues: { spreadValue: number; spreadDisplayValue: string }[] = [];
  spreadValue = undefined;
}

export class SelectionModel {
  id: number;
  name: string;
  shortcut: string;
  spreadValue: number; // Maps to SpecialValue
  spreadDisplayValue: string; // Maps to SpecialValueDisplay
  order: number = -1;

  constructor(init: Partial<SelectionModel>) {
    Object.assign(this, init);
  }
}

export class MatchModel {
  id: number;
  date: Date;
  name: string;
  homeTeam: string;
  awayTeam: string;
  smartBetCode: string;
  sportId: number;
  sportName: string;
  categoryId: number;
  eventCategory: string;
  categoryName: string;
  categoryOrder: number;
  tournamentId: number;
  tournamentName: string;
  tournamentOrder: number;
  externalId: number;
  extParentItemID: number;
  extParentTeamID: number;
  extTeamOrder: number;
  oddCount: number = 0;
  odds: OddModel[] = [];
  correctScoreOdds?: CorrectScoreOddsModel;
  oddsGroupedBySpreadValue?: [OddModel, OddModel][];
  selectedInView: boolean = false;
  isBankerDisabled: boolean;
  combinability: number;
  fixed: boolean;
  allowFixed: boolean;
  isOutright: boolean = false;
  matchTime: number;
  matchStatus: number;
  score: number;

  constructor(init: Partial<MatchModel>) {
    Object.assign(this, init);
  }

  get matchDay(): string {
    return this.date.toString().substring(0, 10);
  }

  get playerName(): string {
    return this.name.lastIndexOf('[') > -1 ? this.name.substring(this.name.lastIndexOf('[') + 1, this.name.lastIndexOf(']')) : this.name;
  }
}

export class CorrectScoreOddsModel {
  homeToWin: OddModel[];
  awayToWin: OddModel[];
  draw: OddModel[];
  others: OddModel[];

  constructor(init: Partial<CorrectScoreOddsModel>) {
    Object.assign(this, init);
  }
}

export class EventSummaryModel {
  sportId: number;
  sportName: string;
  area: AreaModel;
  groupingType: number;
  multiLineType: number;
  overUnderType?: number;
  correctScoreType?: number;
  matches: MatchModel[];

  constructor(init: Partial<EventSummaryModel>) {
    Object.assign(this, init);
  }
}

export class RegionAreaDataModel {
  areas: AreaModel[];
  regions: RegionModel;

  constructor(init: Partial<RegionAreaDataModel>) {
    Object.assign(this, init);
  }
}

export type RegionAreaDropdownViewModel = [RegionAreaDataModel, RegionAreaDataModel, RegionAreaDataModel][];

export class SportQuicklinks {
  sportId: number;
  quicklinks: SportQuicklink[];

  constructor(init: Partial<SportQuicklinks>) {
    Object.assign(this, init);
  }
}

export class SportQuicklink {
  id: number;
  type: QuicklinkType;
  name: string;
  linkText?: string;
  linkUrl?: string;
  visible?: boolean;

  constructor(init: Partial<SportQuicklink>) {
    Object.assign(this, init);
  }
}

export type SpreadValueGroupedOdd = [OddModel, OddModel];

export enum QuicklinkType {
  PresetCollection,
  CustomLink
}

export enum PresetType {
  TopCompetitions,
  TodaysGames,
  AZCompetitions,
  LiveNow,
  AllByCountry,
  Outrights,
  SpecialSports,
  GoalScorer
}
