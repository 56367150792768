<div class="coupon-details-container">
  <div class="coupon-details-wrapper">
    <ng-container *ngIf="(getCouponDetails$ | async) && !(showNoCouponMessage$ | async)">
      <app-recent-bets [bets]="getCouponDetails$ | async"
        [loading]="myBetsQuery.allBetsLoading$ | async"></app-recent-bets></ng-container>
    <div class="no-coupon-wrapper" *ngIf="showNoCouponMessage$ | async">
      <p class="no-coupon-message">
        {{ 'Coupon no longer available' | transloco }}
      </p>
    </div>
  </div>
</div>