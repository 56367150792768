<app-breadcrumb [title]="'Phone Number' | transloco" faIcon="fa-mobile-phone"></app-breadcrumb>
<app-banner-rotator></app-banner-rotator>

<div class="number-already-registered-container" *ngIf="(alreadyRegistered$ | async) === true">
  <app-card [titleHeaderText]="'Verify Phone Number' | transloco">
    <div class="number-already-registered-card-container" body>
      <div class="number-already-registered">
        <div transloco="Your Highness,"></div>
        <div transloco='The number is already registered on another account. If you choose to proceed, all other accounts associated with this phone number will become inactive.'>
        </div>
      </div>

      <div class="verify-buttons">
        <button class="btn" (click)="verifyPhoneNumber()">
          <span class="btn-text" transloco="Verify the number above"></span>
        </button>
        <button class="btn" (click)="logout()">
          <span class="btn-text" transloco="Verify a different account"></span>
        </button>
      </div>
    </div>
  </app-card>
</div>

<div class="verification-container" *ngIf="(resendCodeVerification$ | async) === true">
  <app-card [titleHeaderText]="'Phone Number Verification' | transloco">
    <div class="verification-card-container" body>
      <div class="phone-verification-wrapper">
        <div class="header">
          <div class="header-top">
            <span transloco="Your account has not yet been verified!"></span>
          </div>
          <div class="header-bottom">
            <div transloco="Please take a moment to verify your phone number."></div>
            <div transloco="Click resend to send code via SMS."></div>
          </div>
        </div>
        <div class="content">
          <div class="buttons">
            <button class="btn cancel" (click)="onCancelClick()">
              <span class="btn-text" transloco="Cancel"></span>
            </button>
            <button class="btn proceed" (click)="verifyPhoneNumber()">
              <span class="btn-text" transloco="Resend Code"></span>
            </button>
          </div>
        </div>
      </div>

    </div>
  </app-card>
</div>

<div class="verification-container" *ngIf="(phoneVerification$ | async) === true">
  <app-card [titleHeaderText]="'Phone Number Verification' | transloco">
    <div class="verification-card-container" body>
      <app-phone-verification [OTPOption]="5" [mobileNumber]="accountQuery.userData.mobile.mobileNumber"
        [username]="accountQuery.userData.username" [limitExceeded]="limitExceeded">
      </app-phone-verification>
    </div>
  </app-card>
</div>
