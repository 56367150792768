import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { SidebarBox } from 'src/app/shared/models/application.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() side: 'left' | 'right';
  sidebar$: BehaviorSubject<SidebarBox[]> = new BehaviorSubject([]);
  destroy$: Subject<boolean> = new Subject();

  constructor(private readonly appService: ApplicationService) {}

  ngOnInit(): void {
    this.appService.sidebar$.pipe(takeUntil(this.destroy$)).subscribe(this.handleSidebarBoxes.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private handleSidebarBoxes(boxes: SidebarBox[]): void {
    if (!boxes) {
      return;
    }

    const targetBoxes = boxes.filter(box => this.isCorrectSide(box) && (this.isOnExactRoute(box) || this.isOnStartWithRoute(box)));
    this.sidebar$.next(targetBoxes);
  }

  private isCorrectSide(box: SidebarBox): boolean {
    return box.side === this.side;
  }

  private isOnExactRoute(box: SidebarBox): boolean {
    const currentRoute = window.location.pathname;
    const exactRoutes = box.exactRoutes ? box.exactRoutes.split(',').map(route => route.trim()) : '';
    return exactRoutes.includes(currentRoute);
  }

  private isOnStartWithRoute(box: SidebarBox): boolean {
    const currentRoute = window.location.pathname;
    const startWithRoutes = box.startWithRoutes ? box.startWithRoutes.split(',').map(route => route.trim()) : [''];
    return !!startWithRoutes.find(route => currentRoute.startsWith(route));
  }
}
