export const brandInfo = {
  brandName: 'Victory',
  brandUrl: 'Victory.rs',
  infoEmail: 'info@victory.rs',
  documentsEmail: 'podrska@victory.rs',
  companyName: 'Victory 247 d.o.o. Beograd - Novi Beograd',
  companyLicenseNo: '21493473',
  companyTIN: '111511063',
  companyAddress: 'Omladinskih brigada 102, 11070 Beograd',
  companyTelephoneNo: '+381 62 24 24 24',
  companyShortTelephoneNo: '062 24 24 24',
  companyAccountName: 'Victory 247 d.o.o., Omladinskih brigada 102, 11070 Beograd – Novi Beograd',
  companyAccountNumber: '160-6000000059816-51',
  copyrightYear: '2023',
  metaDataDefaults: {
    title: 'Best Online Sports Betting',
    description: 'Best online sports betting website.',
    'og:image': '',
    'og:type': 'website',
    'og:locale': 'en_GB',
    'twitter:card': 'summary_large_image'
  },
  footerNote: ''
};
