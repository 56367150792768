import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { MatchModel, SelectionModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-multi-line-event-without-selector',
  templateUrl: './multi-line-event-without-selector.component.html',
  styleUrls: ['./multi-line-event-without-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiLineEventWithoutSelectorComponent {
  @Input() event: MatchModel;
  @Input() markets: SelectionModel[];
  @Input() showDetails: boolean = true;
  @Input() showDate: boolean = true;
  @Input() sportId: number = undefined;
  @Input() isAvailable: boolean = true;

  constructor(readonly statisticsQuery: StatisticsQuery, private readonly matchService: MatchService) {}

  navigateToMatch(): void {
    this.matchService.navigateToMatch(this.event.id, this.event.name);
  }
}
