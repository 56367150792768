<div [id]="sectionId" class="bonus-section-container"
  [ngClass]="{'selected' : isViewing, 'last': lastSection, 'inactive-bonus': sectionId === 'inactive' && (bonuses | async) && (bonuses | async).length > 0}"
  (click)="onClick($event)">
  <div class="icon">
    <i class="fa {{ faIcon }}" aria-hidden="true"></i>
  </div>
  <div class="text">
    {{ sectionTitle }}
  </div>
  <div class="number-of-bonuses" *ngIf="(bonuses | async) && (bonuses | async).length > 0">
    {{ (bonuses | async).length }}</div>
  <div class="caret">
    <i class="fa fa-caret-right" aria-hidden="true" [appDropdownArrowRotator]="isViewing"></i>
  </div>
</div>
<div class="bonus-card" [@expandCollapse]="isViewing ? 'expanded' : 'collapsed'">
  <div class="bonus-card-content">
    <ng-container *ngFor="let bonus of (bonuses | async); trackBy: bonusTrackBy">
      <div class="bonus-content-wrapper bonus-content">
        <div class="header">
          <div class="title">{{accountQuery.bonusInfoByCode(bonus.bonusCode) ?
            accountQuery.bonusInfoByCode(bonus.bonusCode).contentTitle : ''}}</div>
          <div class="subtext"
            transloco="To withdraw from your bonus amount, you need to first meet your wagering requirements below.">
          </div>
        </div>
        <div class="image">
          <div class="image-content {{ sectionId }}"
            [ngStyle]="{ 'background-image': 'url(' + (accountQuery.bonusInfoByCode(bonus.bonusCode) ? accountQuery.bonusInfoByCode(bonus.bonusCode).imageURL : '') + ')' }">
          </div>
          <div class="paused-text" *ngIf="sectionId === 'paused'">
            <i class="fa fa-pause-circle" aria-hidden="true"></i>
            {{'Paused' | transloco}}
          </div>


        </div>
        <div class="bonus-details">
          <account-bonus-details *ngIf="bonus.isBonusTransferPossible" [currentProgress]="bonus.currentProgress"
            [nextRelease]="bonus.nextReleaseAmount" [expirationDate]="bonus.expirationDate">
          </account-bonus-details>
        </div>
        <div class="buttons">
          <app-button [text]="'More Info' | transloco" (btnClick)="onMoreInfoClicked(bonus.id)"></app-button>
          <app-button *ngIf="sectionId === 'inactive'" buttonType="success" [text]="'Activate' | transloco"
            (btnClick)="onActivateClicked(bonus.id)"></app-button>
          <app-button *ngIf="sectionId === 'active'" [text]="'Pause' | transloco" (btnClick)="onPauseClicked(bonus.id)">
          </app-button>
          <app-button *ngIf="sectionId === 'paused'" [buttonType]="buttonType.Accent"
            [customButtonStyle]="resumeButtonStyle" [text]="'Resume' | transloco"
            (btnClick)="onResumeClicked(bonus.id)"></app-button>
        </div>
      </div>
    </ng-container>
    <app-form-message type='info' [text]="noBonusesText" *ngIf="(bonuses | async).length === 0"></app-form-message>
  </div>
</div>