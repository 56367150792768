import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coupon-check',
  templateUrl: './coupon-check.component.html',
  styleUrls: ['./coupon-check.component.scss']
})
export class CouponCheckComponent {
  couponCode = new FormControl('', Validators.required);

  constructor(private readonly router: Router) {}

  checkCoupon(): void {
    if (this.couponCode.invalid) {
      return;
    }
    this.router.navigateByUrl(`/coupon-check/${this.couponCode.value}`);
  }
}
