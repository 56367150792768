import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { BetCoupon, BetCouponGlobalVariable, BetCouponOdd, Bonus, Dictionary, StringDictionary } from 'clientside-coupon';
import { map, tap } from 'rxjs/operators';
import {
  BookedCoupon,
  CouponSettings,
  CouponState,
  CouponUIState,
  DefaultCouponStake,
  OddChanges
} from 'src/app/shared/models/coupon.model';
import { MarketModel, SelectionModel } from 'src/app/shared/models/sport.model';
import { CouponStore } from './coupon.store';

@Injectable({ providedIn: 'root' })
export class CouponQuery extends Query<CouponState> {
  bookedCoupons$ = this.select(state => state.bookedCoupons);
  couponInitialized$ = this.select(state => state.couponInitialized);
  couponData$ = this.select(state => state.couponData);
  couponDataFiltered$ = this.select(state => state.couponData).pipe(
    map(couponData => {
      if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
        return undefined;
      }
      return couponData;
    })
  );
  couponSelectionIds$ = this.select(state => (state.couponData ? state.couponData.Odds.map(odd => odd.SelectionId) : [])); //
  couponSettings$ = this.select(state => state.couponSettings);
  defaultCouponStake$ = this.select(state => state.defaultCouponStake);
  editCouponData$ = this.select(state => state.editCouponData);
  oddChanges$ = this.select(state => state.oddChanges);
  editCouponSelectionHeaderData$ = this.editCouponData$.pipe(
    map(data => {
      const selections: SelectionModel[] = [];
      if (data) {
        data.odds.forEach(element => {
          selections.push({
            id: element.selectionId,
            name: element.selectionName,
            shortcut: '',
            spreadValue: element.spreadValue,
            spreadDisplayValue: element.spreadValue.toString(),
            order: -1
          });
        });
      }
      return new MarketModel({ selections });
    })
  );
  couponforEdit$ = this.select(state => state.ui.couponForEdit);
  groupingsTabSelected$ = this.select(state => state.groupingsTabSelected);
  showCoupon$ = this.select(state => state.ui.showCoupon);
  showQuickCoupon$ = this.select(state => state.ui.showQuickCoupon);
  selections$ = this.select(state => state.selections);
  selectionMarketMatches$ = this.select(state => state.selectionMarketMatches);

  constructor(protected store: CouponStore) {
    super(store);
  }

  get showCoupon(): boolean {
    return this.getValue().ui.showCoupon;
  }

  get showQuickCoupon(): boolean {
    return this.getValue().ui.showQuickCoupon;
  }

  get uiState(): CouponUIState {
    return this.getValue().ui;
  }

  get couponData(): BetCoupon {
    const couponData: BetCoupon = this.getValue().couponData;
    if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
      return undefined;
    }
    return couponData;
  }

  get selectedForEdit(): { matchId: number; marketTypeId: number } {
    return this.getValue().ui.couponForEdit;
  }

  get couponSettings(): CouponSettings {
    const couponSettings: CouponSettings = this.getValue().couponSettings;
    if (couponSettings === null || couponSettings === undefined) {
      return new CouponSettings();
    }
    return couponSettings;
  }

  get defaultCouponStake(): DefaultCouponStake {
    return this.getValue().defaultCouponStake;
  }

  get oddChanges(): OddChanges[] {
    return this.getValue().oddChanges;
  }

  couponContainsOddChanges(): boolean {
    if (this.getValue().oddChanges === undefined) {
      return false;
    }

    return !this.getValue().oddChanges.every(o => o.firstOddValue === o.latestOddValue);
  }

  get bookedCoupons(): BookedCoupon[] {
    const bookedCoupons = this.getValue().bookedCoupons;
    if (bookedCoupons === null || bookedCoupons === undefined) {
      return [];
    }
    return bookedCoupons;
  }

  get couponInitialized(): boolean {
    return this.getValue().couponInitialized;
  }

  get bonusList(): Bonus[] {
    return this.getValue().bonusList;
  }

  get globalVariables(): BetCouponGlobalVariable {
    return this.getValue().globalVariables;
  }

  get marketExceptions(): Dictionary<number, number[]> {
    return this.getValue().marketExceptions;
  }

  get correctScoreOddsMatrix(): any {
    return this.getValue().correctScoreOddsMatrix;
  }

  get groupingsTabSelected(): any {
    return this.getValue().groupingsTabSelected;
  }

  get correctScoreOddsMatrixData(): StringDictionary<string[]> {
    const correctScoreOddsMatrix = this.getValue().correctScoreOddsMatrix;
    if (!correctScoreOddsMatrix) {
      return correctScoreOddsMatrix;
    }
    return correctScoreOddsMatrix.data;
  }

  getSameMatchSelection(matchId: number): BetCouponOdd {
    return this.getValue().couponData ? this.getValue().couponData.Odds.find(o => o.MatchId === matchId) : undefined;
  }
}
