import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CityModel, TelephoneExtensionModel } from 'src/app/shared/models/location.model';

import { RegistrationState } from 'src/app/shared/models/registration.model';
import { RegistrationStore } from './registration.store';

@Injectable({
  providedIn: 'root'
})
export class RegistrationQuery extends Query<RegistrationState> {
  // Data
  activationOption$ = this.select(s => s.activationOption);
  registerOptionTriggered$ = this.select(s => s.registerOptionTriggered);
  verificationInProgress$ = this.select(s => s.verificationInProgress);
  registerConfirmation$ = this.select(s => s.registerConfirmation);
  isActivated$ = this.select(s => s.isActivated);
  resendSucceeded$ = this.select(s => s.resendSucceeded);
  activationErrorMessage$ = this.select(s => s.activationErrorMessage);
  resendErrorMessage$ = this.select(s => s.resendErrorMessage);
  activationUsername$ = this.select(s => s.activationUsername);
  blinkingServiceEnabled$ = this.select(s => s.blinkingServiceEnabled);
  currencies$ = this.select(s => s.currencies);
  countries$ = this.select(s => s.countries);
  statesOfResidence$ = this.select(s => s.statesOfResidence);
  cities$ = this.select(s => s.cities);
  telephoneExtensions$ = this.select(s => s.telephoneExtensions);

  // New Agent
  newAgentTitle$ = this.select(s => s.newAgentForm.title);
  newAgentForm$ = this.select(s => s.newAgentForm.formContent);
  hasNewAgentData = this.getValue().newAgentForm.formContent;

  // State checks
  hasActivationOption =
    this.getValue().activationOption !== undefined &&
    this.getValue().activationOption !== 'none' &&
    this.getValue().activationOption.length > 0;
  hasCurrencies = this.getValue().currencies !== undefined && this.getValue().currencies.length > 0;
  hasCountries = this.getValue().countries !== undefined && this.getValue().countries.length > 0;
  hasStates = this.getValue().statesOfResidence !== undefined && this.getValue().statesOfResidence.length > 0;
  hasCities = this.getValue().cities !== undefined && this.getValue().cities.length > 0;
  hasTelephoneExtensions = this.getValue().telephoneExtensions !== undefined && this.getValue().telephoneExtensions.length > 0;

  // Credentials
  credentials$ = this.select(s => s.credentials);

  loading$ = this.selectLoading();

  constructor(protected store: RegistrationStore) {
    super(store);
  }

  get activationOption(): string {
    return this.getValue().activationOption;
  }

  get registerOptionTriggered(): boolean {
    return this.getValue().registerOptionTriggered;
  }

  get firstCity(): CityModel {
    return this.getValue().cities[0];
  }

  getTelephoneExtensionFromCode(code: string): TelephoneExtensionModel {
    return this.getValue().telephoneExtensions.find(prefix => prefix.countryCode === code);
  }

  get credentials(): any {
    return this.getValue().credentials;
  }

  get activationUsername(): string {
    return this.getValue().activationUsername;
  }

  get blinkingServiceEnabled(): boolean {
    return this.getValue().blinkingServiceEnabled;
  }
}
