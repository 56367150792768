import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { tap } from 'rxjs/operators';

import { CouponDetailsModel, CouponDetailsState } from 'src/app/shared/models/coupon-details.model';
import { CouponDetailsStore } from './coupon-details.store';

@Injectable({
  providedIn: 'root'
})
export class CouponDetailsQuery extends Query<CouponDetailsState> {
  // UI
  isLoading$ = this.select(state => state.ui.isLoading);
  wrongCouponCode$ = this.select(state => state.ui.wrongCouponCode);
  checkOutValueLoading$ = this.select(state => state.ui.checkOutValueLoading);

  // Data
  couponDetails$ = this.select(state => state.couponDetails);

  constructor(protected store: CouponDetailsStore) {
    super(store);
  }

  get couponDetails(): CouponDetailsModel {
    return this.getValue().couponDetails;
  }

  get wrongCouponCode(): boolean {
    return this.getValue().ui.wrongCouponCode;
  }

  get couponType(): string {
    return this.getValue().couponDetails.couponType;
  }

  get checkOutValueLoading(): boolean {
    return this.getValue().ui.checkOutValueLoading;
  }
}
