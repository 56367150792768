import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { addDays, format, isAfter, isBefore, subDays, subMonths } from 'date-fns';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { AccountStatementService } from 'src/app/core/services/account/account-statement.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { AccountStatementModel } from 'src/app/shared/models/account.model';

@Component({
  selector: 'app-transactions-side-menu',
  templateUrl: './transactions-side-menu.component.html',
  styleUrls: ['./transactions-side-menu.component.scss']
})
export class TransactionsSideMenuComponent implements OnInit, OnDestroy {
  activeType$: BehaviorSubject<'all' | 'bets' | 'slot' | 'slot-bonus' | 'deposit'> = new BehaviorSubject('all');
  activeDate$: BehaviorSubject<'today' | '3-days' | '7-days' | 'custom-date'> = new BehaviorSubject('today');
  dateFromMaxLimit = this.accountStatementService.defaultFilter.dateTo;
  dateFromMinLimit = format(addDays(subMonths(new Date(), 30), 1), 'yyyy-MM-dd');
  dateToLimit = this.accountStatementService.defaultFilter.dateTo;
  destroy$: Subject<boolean> = new Subject();

  transactionTypesInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  openCloseDateFilter$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private customDateLoaded: boolean = false;
  dateForm = new FormGroup({
    dateFrom: new FormControl(this.accountStatementService.defaultFilter.dateFrom),
    dateTo: new FormControl(this.accountStatementService.defaultFilter.dateTo)
  });
  ifScrolledLeft$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  ifScrolledRight$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor(
    private readonly appService: ApplicationService,
    private readonly accountStatementService: AccountStatementService,
    private readonly accountQuery: AccountQuery,
    private readonly accountStore: AccountStore,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    document.body?.classList?.add('disable-scrolling');

    this.accountStatementService
      .getTransactionTypes()
      .pipe(first())
      .subscribe(() => {
        this.transactionTypesInitialized$.next(true);
        this.getTransactions();
      });

    this.dateForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(sub => {
      if (sub.dateFrom === '') {
        this.dateForm.controls.dateFrom.setValue(
          isAfter(new Date(sub.dateTo), new Date(this.accountStatementService.defaultFilter.dateFrom))
            ? this.accountStatementService.defaultFilter.dateFrom
            : format(subDays(new Date(sub.dateTo), 1), 'yyyy-MM-dd')
        );
      } else if (sub.dateTo === '') {
        this.dateForm.controls.dateTo.setValue(
          isBefore(new Date(sub.dateFrom), new Date(this.accountStatementService.defaultFilter.dateTo))
            ? this.accountStatementService.defaultFilter.dateTo
            : format(addDays(new Date(sub.dateFrom), 1), 'yyyy-MM-dd')
        );
      } else {
        this.dateFromMaxLimit = sub.dateTo;
        this.accountStatementService.updateAccountStatementFilter(sub as any);
      }
    });
  }

  ngOnDestroy(): void {
    document.body?.classList?.remove('disable-scrolling');

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  close(): void {
    this.appService.showTransactionsMenu$.next(false);
  }

  setType(type: 'all' | 'bets' | 'slot' | 'slot-bonus' | 'deposit'): void {
    this.activeType$.next(type);
  }

  setDate(date: 'today' | '3-days' | '7-days' | 'custom-date'): void {
    this.activeDate$.next(date);
    if (date === 'custom-date' && this.customDateLoaded) {
      this.openCloseDateFilter$.next(!this.openCloseDateFilter$.value);
      return;
    }
    this.getTransactions();
    if (date === 'custom-date') {
      this.customDateLoaded = true;
      this.openCloseDateFilter$.next(!this.openCloseDateFilter$.value);
    } else {
      this.customDateLoaded = false;
      this.openCloseDateFilter$.next(false);
    }
  }
  searchByTimePeriod(): void {
    this.getTransactions();
    this.openCloseDateFilter$.next(false);
  }

  transactionsTrackBy(transaction: AccountStatementModel): number {
    return transaction.id;
  }

  get transactions$(): Observable<AccountStatementModel[]> {
    return this.accountQuery.accountStatements$.pipe(
      map(transactions => transactions.filter(transaction => transaction.amount !== 0)),
      map(transactions => transactions.filter(this.isTransactionOfSelectedType.bind(this)))
    );
  }

  get noTransactions$(): Observable<boolean> {
    return this.accountQuery.noStatements$;
  }

  get loading$(): Observable<boolean> {
    return this.accountQuery.loading$;
  }

  private getTransactions(): void {
    if (!this.transactionTypesInitialized$.value) {
      return;
    }

    const dates = this.getDateRange();

    this.accountStore.updateAccountStatementFilter({
      dateFrom: dates.from,
      dateTo: dates.to,
      transferType: this.accountStatementService.defaultFilter.transferType,
      period: this.accountStatementService.defaultFilter.period
    });

    this.accountStatementService.getAccountStatements().subscribe();
  }

  private isTransactionOfSelectedType(transaction: AccountStatementModel): boolean {
    const activeType = this.activeType$.value;

    if (activeType === 'bets') {
      return transaction.transactionTypeIdentifier.startsWith('SPORTSBOOK');
    } else if (activeType === 'slot-bonus') {
      return (
        (transaction.transactionTypeIdentifier.includes('CASINO') && transaction.description.includes('bonus')) ||
        transaction.description.toLocaleLowerCase().includes('vegas bonus')
      );
    } else if (activeType === 'slot') {
      return transaction.transactionTypeIdentifier.includes('CASINO') && !transaction.description.includes('bonus');
    } else if (activeType === 'deposit') {
      return transaction.transactionTypeIdentifier.includes('FINANCE');
    } else {
      return true;
    }
  }
  isScrollHappened(event: Event): void {
    const betTabContainer = this.elementRef.nativeElement.querySelector('.scroll-container');
    const isAtLeftEnd = betTabContainer.scrollLeft === 0;
    const isAtRightEnd = betTabContainer.scrollLeft >= betTabContainer.scrollWidth - betTabContainer.clientWidth - 2;

    this.ifScrolledLeft$.next(!isAtLeftEnd);
    this.ifScrolledRight$.next(!isAtRightEnd);
  }

  scrollLeft(): void {
    this.scrollToPosition(0);
  }

  scrollRight(): void {
    const betTabContainer = this.elementRef.nativeElement.querySelector('.scroll-container');
    const scrollWidth = betTabContainer.scrollWidth - betTabContainer.clientWidth - 2;
    this.scrollToPosition(scrollWidth);
  }

  private scrollToPosition(position: number): void {
    const betTabContainer = this.elementRef.nativeElement.querySelector('.scroll-container');
    this.renderer.setProperty(betTabContainer, 'scrollLeft', position);

    this.isScrollHappened(null); // Trigger scroll checks after scrolling
  }
  private getDateRange(): { from: string; to: string } {
    const today = new Date().toDateString();

    if (this.activeDate$.value === 'today') {
      return {
        from: today,
        to: today
      };
    } else if (this.activeDate$.value === '3-days') {
      return {
        from: subDays(new Date(), 3).toDateString(),
        to: today
      };
    } else if (this.activeDate$.value === '7-days') {
      return {
        from: subDays(new Date(), 7).toDateString(),
        to: today
      };
    } else {
      const dateFrom = new Date(this.dateForm.controls.dateFrom.value).toDateString();
      const dateTo = new Date(this.dateForm.controls.dateTo.value).toDateString();

      return {
        from: dateFrom,
        to: dateTo
      };
    }
  }
}
