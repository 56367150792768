import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { addDays, differenceInDays, differenceInHours, differenceInMilliseconds, format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, finalize, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { VictoryAnalyticsService } from 'src/app/core/services/analytics/victory-analytics.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AppInsightsService } from 'src/app/core/services/app-insights.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import {
  AccountMenuLinkModel,
  AccountUIState,
  LoginResponseModel,
  TransferCandidateModel,
  UserModel,
  UserType,
  VerifyAccountModel,
  VerifyAccountType,
  Wallet
} from 'src/app/shared/models/account.model';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { CurrencyModel } from 'src/app/shared/models/currency.model';
import { LongFormRegistrationModel, RegistrationApiModel } from 'src/app/shared/models/registration.model';

import { MenuItemModel } from '../../../shared/models/cms.model';
import { APIService } from '../api.service';
import { AuthenticationService } from '../authentication.service';
import { ShopOwnerService } from '../shop-owner.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  canUserBetOnVirtuals$: Observable<boolean> = this.accountQuery.accessToken$.pipe(
    distinctUntilChanged(),
    switchMap(accessToken => (accessToken ? this.isVirtualsEnabled(accessToken).pipe(map(response => response.Result)) : of(true))),
    catchError(() => of(true)), // we shouldn't disable the button when something unexpected happens, the server will throw either way
    shareReplay()
  );

  DEFAULT_MENUITEMS: AccountMenuLinkModel[] = [];

  DEFAULT_HELPMENUITEMS: AccountMenuLinkModel[] = [];
  showLogin$ = new BehaviorSubject<boolean>(false);
  showChooseYourPromo$ = new BehaviorSubject<boolean>(false);

  private readonly verifyAccountDataKey = 'verifyAccountData';
  constructor(
    private readonly apiService: APIService,
    private readonly authService: AuthenticationService,
    private readonly accountStore: AccountStore,
    private readonly accountQuery: AccountQuery,
    private readonly languageService: LanguageService,
    private readonly appInsightsService: AppInsightsService,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly localStorage: LocalStorageService,
    private readonly shopOwnerService: ShopOwnerService,
    private readonly victoryAnalytics: VictoryAnalyticsService
  ) {}

  initAccountSection(): void {
    this.DEFAULT_MENUITEMS = [
      // new AccountMenuLinkModel({
      //   text: translate('Deposit'),
      //   iconFontValue: 'plus-circle',
      //   link: '/account/deposit'
      // }),
      new AccountMenuLinkModel({
        text: translate('Bet List'),
        iconFontValue: 'search',
        link: '/account/bet-search'
      }),
      new AccountMenuLinkModel({
        text: translate('Virtuals Bet List'),
        iconFontValue: 'v-search',
        link: '/account/virtuals-bet-search',
        isCustomIcon: true
      }),
      new AccountMenuLinkModel({
        text: translate('Transaction List'),
        iconFontValue: 'id-card-o',
        link: '/account/account-statement'
      }),
      new AccountMenuLinkModel({
        text: translate('Withdraw'),
        iconFontValue: 'minus-circle',
        link: '/account/withdrawal',
        showWarningIcon: true
      }),
      new AccountMenuLinkModel({
        text: translate('Fund Transfer'),
        iconFontValue: 'exchange',
        link: '/account/transfer',
        visibleToTheseUserTypes: [UserType.Master, UserType.ShopOwner, UserType.SuperAgent]
      }),
      new AccountMenuLinkModel({
        text: translate('Bonuses'),
        iconFontValue: 'bolt',
        link: '/account/bonus'
      }),
      new AccountMenuLinkModel({
        text: translate('My Profile'),
        iconFontValue: 'pencil-square-o',
        link: '/account/edit-profile',
        showWarningIcon: true
      }),
      new AccountMenuLinkModel({
        text: translate('Messages'),
        iconFontValue: 'envelope-o',
        link: '/message-center'
      })
    ];

    this.DEFAULT_HELPMENUITEMS = [
      new AccountMenuLinkModel({
        text: translate('Contact Us'),
        iconFontValue: 'comments-o',
        link: '/help/contact-us'
      }),
      new AccountMenuLinkModel({
        text: translate('Terms & Conditions'),
        iconFontValue: 'quote-left',
        link: '/help/terms-and-conditions'
      }),
      new AccountMenuLinkModel({
        text: translate('Help & FAQs'),
        iconFontValue: 'question',
        link: '/help'
      })
    ];

    this.getAccountSectionItems();
  }

  openLogin(): void {
    this.showLogin$.next(true);
  }

  closeLogin(): void {
    this.showLogin$.next(false);
  }
  clearUserData(): void {
    this.shopOwnerService.clearSubUsersList();
    this.accountStore.clearUserData();
    if (this.appInsightsService.isInitialized) {
      this.appInsightsService.appInsights.clearAuthenticatedUserContext();
    }
  }

  /**
   * Handles login of users
   * @param username username to login with
   * @param password password to login with
   * @param preventLogout Prevent logging out before logging in. This is required during registration.
   */
  login(username: string, password: string, preventLogout: boolean = false, grantType: string = 'otp'): Observable<LoginResponseModel> {
    if (!preventLogout && this.accountQuery.isAuthenticated) {
      this.logout().subscribe();
    }
    return this.authService.getAccessToken(username, password, grantType).pipe(concatMap(accessToken => this.getUserData(accessToken)));
  }

  /**
   * Converts from brand specific type code to a general one
   * @param userTypeCode Type code from API
   */
  getUserType(userTypeCode: string): UserType {
    const accountSettings = this.appConfig.get('account');
    if (!accountSettings || !accountSettings.accountTypes || typeof accountSettings.accountTypes !== 'object') {
      console.error(`UserType couldn't be determined because config.account.accountTypes doesn't exist`);
      return UserType.User;
    }

    const userTypes = accountSettings.accountTypes;
    return (Object.keys(userTypes).find(key => userTypes[key] === userTypeCode) as UserType) || UserType.User;
  }

  getUserData(accessToken: string): Observable<LoginResponseModel> {
    return forkJoin(
      this.getUserProfile(accessToken),
      this.getWallets(accessToken),
      this.getUserStatus(accessToken),
      this.getLoginMessages(accessToken),
      this.getBankAccountDetails(accessToken),
      this.getKYCStatus(accessToken)
    ).pipe(
      map(([profileData, wallets, userStatus, loginMessages, bankAccountDetails, KYCStatus]) => {
        if (!profileData) {
          return new LoginResponseModel({ success: false, errorMessage: 'User data not found' });
        }

        let mobileDetails: any;
        if (profileData.PhoneContactDetails) {
          profileData.PhoneContactDetails.forEach(phoneContactDetails => {
            if (phoneContactDetails.PhoneContactTypeCode === 'MB') {
              mobileDetails = phoneContactDetails;
            }
          });
        }

        if (profileData.UserDetails) {
          this.victoryAnalytics.addUserDataToSession(profileData.UserDetails.UserId, profileData.UserDetails.Username);
        }

        const userData = this.parseUserData(accessToken, profileData, wallets, userStatus, mobileDetails, bankAccountDetails, KYCStatus);
        if (!userData) {
          return new LoginResponseModel({ success: false, errorMessage: 'Invalid user data' });
        }

        // data cleanse: verify account
        let verifyType: VerifyAccountType;
        if (this.appConfig.get('enableOTPbySms') && mobileDetails) {
          switch (userData.mobile.verifyType) {
            case undefined: // No Status
            case '': // No Status
            case 'UNCF': // Unconfirmed
            case 'STRVER': // Started Verification
              verifyType = VerifyAccountType.VerifyInProgress;
              break;
            case 'VER': // Verified
              verifyType = VerifyAccountType.Verified;
              break;
            case 'LCKD': // Blocked
              verifyType = VerifyAccountType.Locked;
              break;
            default:
              // Verified
              verifyType = VerifyAccountType.Verified;
              break;
          }
        } else {
          verifyType = VerifyAccountType.Verified;
        }

        let daysLeft;
        let hoursLeft;
        let gracePeriod: boolean = false;
        const registrationConfig: any = this.appConfig.get('registration');
        if (registrationConfig && registrationConfig.deadlineDate !== '') {
          const deadlineDate = new Date(registrationConfig.deadlineDate);

          const currentTime = new Date();
          const duration = differenceInMilliseconds(deadlineDate, currentTime);

          daysLeft = differenceInDays(deadlineDate, currentTime);
          hoursLeft = differenceInHours(deadlineDate, addDays(currentTime, daysLeft));

          if (duration <= 0 && verifyType !== VerifyAccountType.Verified) {
            verifyType = VerifyAccountType.Locked;
            userData.mobile.verifyType = 'LCKD';
            gracePeriod = true;
          }
        }

        this.accountStore.updateUserData(userData);

        const verifyAccountState = new VerifyAccountModel({
          type: verifyType,
          gracePeriod: gracePeriod,
          daysLeft: daysLeft,
          hoursLeft: hoursLeft,
          mobileNumber: userData.mobile ? userData.mobile.mobileNumber : ''
        });

        this.accountStore.updateVerifyAccountState(verifyAccountState);

        if (this.appInsightsService.isInitialized) {
          this.appInsightsService.appInsights.setAuthenticatedUserContext(userData.id.toString(), undefined, true);
        }

        return new LoginResponseModel({ success: true, loginMessages: loginMessages, id: profileData.UserDetails.UserId });
      })
    );
  }

  updateShowUnverifiedTooltip(showUnverifiedTooltip: boolean): void {
    this.accountStore.updateShowUnverifiedTooltip(showUnverifiedTooltip);
  }

  updateMultipleUnconfirmedUsers(multipleUnconfirmedUsers: boolean): void {
    this.accountStore.updateMultipleUnconfirmedUsers(multipleUnconfirmedUsers);
  }

  logout(): Observable<boolean> {
    return this.authService.revokeToken(this.accountQuery.accessToken).pipe(
      map(() => {
        // remove user from local storage
        this.clearUserData();
        return true;
      })
    );
  }

  getWallets(accessToken: string): Observable<any> {
    const apiSettings: APISettings = new APISettings();
    if (accessToken) {
      apiSettings.forceAuthToken = accessToken;
    }

    return this.apiService.get<any>(APIType.Platform, 'api/Finance/Accounts/ByPlaySource/web', apiSettings).pipe(
      map(responseData => {
        if (!responseData || !responseData.Result) {
          return undefined;
        }

        return responseData.Result.map(wallet => ({
          id: wallet.Id,
          name: wallet.Name,
          balance: wallet.Balance,
          isBonusWallet: this.isBonusWallet(wallet)
        }));
      })
    );
  }

  parseWalletName(wallet: any): string {
    return this.isBonusWallet(wallet) ? 'Bonus' : wallet.Name;
  }

  isBonusWallet(wallet: any): boolean {
    return wallet.Name.startsWith('Bonus');
  }

  updateBalance(): void {
    this.updateRefreshingBalance(true);
    this.updateBalanceSubscription()
      .pipe(
        finalize(() => {
          this.updateRefreshingBalance(false);
        })
      )
      .subscribe();
  }

  updateBalanceSubscription(): Observable<any> {
    return this.getWallets(this.accountQuery.accessToken).pipe(
      map(wallets => {
        this.accountStore.updateStoredWallets(this.accountQuery.userData, wallets);
      })
    );
  }

  // tslint:disable-next-line: cyclomatic-complexity
  getKYCDocumentsStatus(): string {
    if (!this.accountQuery.isAuthenticated || !this.accountQuery.userData.KYCStatus) {
      return 'verified';
    }

    const blinkingSessionStatus = this.accountQuery.userData.blinkingSessionStatus
      ? this.accountQuery.userData.blinkingSessionStatus.Status
      : undefined;

    if (!this.accountQuery.userData.KYCStatus.badges) {
      // badges do not exist for some reason, check if standard Pending
      if (this.accountQuery.userData.standardVerificationPending.value) {
        return 'pending';
      }
      return 'documentsNeeded';
    } else {
      const blockedCustomer = this.getKYCBadgeInfo('blocked_customer');
      const rejectedCustomer = this.getKYCBadgeInfo('rejected_customer');

      if ((blockedCustomer && blockedCustomer.Value) || (rejectedCustomer && rejectedCustomer.Value)) {
        return 'fraud';
      }

      // we need to check the status of the session
      if (
        this.accountQuery.userData.standardVerificationPending.value ||
        (blinkingSessionStatus &&
          (blinkingSessionStatus === 'blinking in progress' || blinkingSessionStatus === 'pending back office approval'))
      ) {
        return 'pending';
      }

      const currentTime = new Date();

      const govIssuedDocumentConfirmed = this.getKYCBadgeInfo('government_issued_document_confirmed');
      const govIssuedDocumentConfirmedDate = govIssuedDocumentConfirmed ? new Date(govIssuedDocumentConfirmed.ValidUntil) : undefined;
      const govIssuedDocumentConfirmedDuration = differenceInMilliseconds(govIssuedDocumentConfirmedDate, currentTime);

      // badges exist, is gov Issued Document Confirmed expired?
      if (govIssuedDocumentConfirmed && govIssuedDocumentConfirmedDuration <= 0) {
        // gov Issued Document Confirmed is expired
        return 'documentsNeeded';
      } else {
        // gov Issued Document Confirmed is not expired, is gov Issued Document Confirmed missing for kyc?
        if (govIssuedDocumentConfirmed && !govIssuedDocumentConfirmed.Value) {
          return 'documentsNeeded';
        } else {
          const personalImageConfirmed = this.getKYCBadgeInfo('personal_image_confirmed');
          const personalImageConfirmedDate = personalImageConfirmed ? new Date(personalImageConfirmed.ValidUntil) : undefined;
          const personalImageConfirmedDuration = differenceInMilliseconds(personalImageConfirmedDate, currentTime);

          // badges exist, is personal Image Confirmed expired?
          if (personalImageConfirmed && personalImageConfirmedDuration <= 0) {
            // personal Image Confirmed is expired
            return 'accountNumberNeeded';
          } else {
            // personal Image Confirmed is not expired, is personal Image Confirmed missing for kyc?
            if (personalImageConfirmed && !personalImageConfirmed.Value) {
              return 'accountNumberNeeded';
            } else {
              const accountNumberConfirmed = this.getKYCBadgeInfo('account_number_confirmed');

              // no kyc badges missing, is account number missing?
              if (accountNumberConfirmed && !accountNumberConfirmed.Value) {
                return 'accountNumberNeeded';
              }
            }
          }
        }
      }
    }

    return 'verified';
  }
  canUploadBankAccountImage() {
    const accountNumberConfirmed = this.getKYCBadgeInfo('account_number_confirmed');
    return accountNumberConfirmed && !accountNumberConfirmed.Value;
  }
  getKYCBadgeInfo(name: string): any {
    if (!this.accountQuery.isAuthenticated || !this.accountQuery.userData.KYCStatus || !this.accountQuery.userData.KYCStatus.badges) {
      return undefined;
    }
    const badge = this.accountQuery.userData.KYCStatus.badges.filter(val => val.Name === name)[0];
    if (badge != undefined) {
      return badge;
    } else {
      return undefined;
    }
  }

  getChangePhoneNumberOption(): Observable<any> {
    if (!this.accountQuery.hasChangePhoneNumberOption) {
      return this.apiService.get(APIType.Platform, 'api/Security/Accounts/RequestChangePhoneNumber/Current').pipe(
        first(),
        map(responseData => {
          this.accountStore.updateChangePhoneNumberOption(responseData.Result);
          this.accountStore.updatePhoneOptionTriggered(true);
        }),
        catchError((err: HttpErrorResponse) => {
          this.accountStore.setError(err);
          return throwError(err.message);
        })
      );
    }
  }

  updatePhoneOptionTriggered(phoneOptionTriggered: boolean): void {
    this.accountStore.updatePhoneOptionTriggered(phoneOptionTriggered);
  }

  getResetPasswordOption(): Observable<any> {
    if (!this.accountQuery.hasResetPasswordOption) {
      return this.apiService.get(APIType.Platform, 'api/Security/Accounts/RequestPasswordReset/Current').pipe(
        first(),
        map(responseData => {
          this.accountStore.updateResetPasswordOption(responseData.Result);
          this.accountStore.updateResetOptionTriggered(true);
        }),
        catchError((err: HttpErrorResponse) => {
          this.accountStore.setError(err);
          return throwError(err.message);
        })
      );
    }
  }

  updateResetOptionTriggered(resetOptionTriggered: boolean): void {
    this.accountStore.updateResetOptionTriggered(resetOptionTriggered);
  }

  validatePasswordReset(token: string): Observable<any> {
    return this.apiService.post<any>(APIType.Platform, `api/Security/Accounts/ValidatePasswordReset?token=${token}`, {});
  }

  forgottenPassword(userName: string, type: string = 'none'): Observable<boolean> {
    const body: any = {
      Username: userName
    };

    if (type !== 'SMS') {
      body.PasswordResetPageUrl = this.languageService.enableLanguageSelection$.value
        ? `${window.location.origin}/${this.languageService.selectedLanguage.language}/account/password-reset/{0}`
        : `${window.location.origin}/account/password-reset/{0}`;
    }

    if (type === 'none') {
      return this.apiService.post(APIType.Platform, `api/Security/Accounts/RequestPasswordReset`, body);
    } else {
      return this.apiService.post(APIType.Platform, `api/Security/Accounts/RequestResetPasswordBy${type}`, body);
    }
  }

  postResetPassword(token: string, password: string): Observable<boolean> {
    const body = {
      ResetToken: token,
      NewPassword: password
    };

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/CompletePasswordReset', body);
  }

  postResetPasswordBySms(username: string, oneTimePassword: string, password: string): Observable<boolean> {
    const body = {
      OneTimePassword: oneTimePassword,
      UserName: username,
      Password: password
    };

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/Request/Activation', body);
  }

  changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    const body = {
      CurrentPassword: currentPassword,
      NewPassword: newPassword
    };

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/ChangePassword', body);
  }

  validateOneTimePassword(username: string, code: string, OTPOption: number): Observable<boolean> {
    const body = {
      Username: username,
      OneTimePassword: code,
      VerifyOTPOption: OTPOption
    };

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/ValidateOneTimePassword', body);
  }

  startVerificationBySMS(userId: number, mobileNumber: string): Observable<boolean> {
    const body = {
      UserId: userId,
      Mobile: mobileNumber
    };

    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/Request/StartVerificationBySMS', body);
  }

  regenerateTokenForService(body: any): Observable<boolean> {
    return this.apiService.post<any>(APIType.Platform, 'api/Security/Accounts/RegenerateTokenForService', body);
  }

  markMessageAsRead(messageId: number): Observable<boolean> {
    return this.apiService.post<any>(APIType.Platform, `api/User/Messages/${messageId}/MarkRead`, {});
  }

  updateUI(ui: AccountUIState): void {
    this.accountStore.updateUI(ui);
  }

  generatePasswordValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.maxLength(25),
      Validators.minLength(6),
      this.hasSpaces(),
      this.needsLowercase()
      // this.needsDigits(),
      // this.needsLetters()
    ];
  }

  generateUsernameValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.maxLength(50),
      Validators.minLength(5),
      this.hasSpaces(),
      this.needsLowercase()
      // this.needsDigits(),
      // this.needsLetters()
    ];
  }

  generateEmailValidators(): ValidatorFn[] {
    return [Validators.required, Validators.pattern(new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/))];
  }

  generateLettersOnlyValidators(): ValidatorFn[] {
    return [
      Validators.required,
      Validators.pattern(
        new RegExp(
          /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑđĐßÇŒÆČŠŽ∂ð]+(?:[\s-][a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑđĐßÇŒÆČŠŽ∂ð]+)*$/
        )
      )
    ];
  }

  generateDigitsOnlyAndLengthValidators(minLength: number, maxLength: number): ValidatorFn[] {
    return [
      Validators.required,
      Validators.minLength(minLength),
      Validators.maxLength(maxLength),
      Validators.pattern(new RegExp(/^[0-9-]*$/))
    ];
  }
  hasValidAge() {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      const today = new Date();
      const birthDate = new Date(control.value);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if (age < 18) {
        return { hasInvalidAge: true };
      }
      return undefined;
    };
  }
  generateDateValidators(): ValidatorFn[] {
    return [Validators.required, this.hasDefaultDate(), this.hasValidAge()];
  }

  generateGenderValidators(): ValidatorFn[] {
    return [Validators.required, this.hasDefaultGender()];
  }

  updateReferrer(referrer: string): Observable<any> {
    return this.apiService.post<any>(
      APIType.Platform,
      'api/User/Profile',
      {
        ExtraDetails: [
          {
            PropertyName: 'ReferralCode',
            PropertyValue: referrer
          }
        ]
      },
      { forceAuthToken: this.accountQuery.accessToken }
    );
  }

  editProfile(user: LongFormRegistrationModel): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    this.accountStore.setLoading(true);

    const callBody = {
      // PhoneContactDetails: [],
      ExtraDetails: [
        {
          Id: this.accountQuery.userData.marketingConsent.id,
          PropertyName: 'ReceiveMarketingMessages',
          PropertyValue: `${user.consent}`
        }
      ]
    };

    // if (this.accountQuery.userData.mobile) {
    //   callBody.PhoneContactDetails.push({
    //     Id: this.accountQuery.userData.mobile.id,
    //     PhoneContactTypeCode: 'MB',
    //     ContactNumber: this.accountQuery.userData.mobile.mobileNumber
    //   });
    // } else {
    //   delete callBody.PhoneContactDetails;
    // }

    const apiCalls = [this.apiService.post<any>(APIType.Platform, 'api/User/Profile', callBody, apiSettings)];

    // if (user.bankAccountNumber) {
    //   const bankAccountDetailsCallBody = {
    //     AccountNumber: user.bankAccountNumber
    //   };

    //   apiCalls.push(
    //     this.apiService.post<any>(APIType.Platform, 'api/Finance/BankTransferDetails', bankAccountDetailsCallBody, apiSettings)
    //   );

    // }

    return forkJoin(apiCalls).pipe(
      map(() => {
        this.accountStore.setLoading(false);
      }),
      catchError((err: HttpErrorResponse) => {
        this.accountStore.setError(err);
        this.accountStore.setLoading(false);
        return throwError(err.message);
      })
    );
  }

  changeBankAccountNumber(user: LongFormRegistrationModel): Observable<any> {
    if (user.bankAccountNumber) {
      const apiSettings: APISettings = new APISettings({
        forceAuthToken: this.accountQuery.accessToken
      });

      this.accountStore.setLoading(true);

      const bankAccountDetailsCallBody = {
        AccountNumber: user.bankAccountNumber
      };

      return this.apiService.post(APIType.Platform, 'api/Finance/BankTransferDetails', bankAccountDetailsCallBody, apiSettings).pipe(
        first(),
        map(() => {
          this.accountStore.setLoading(false);
        }),
        catchError((err: HttpErrorResponse) => {
          this.accountStore.setError(err);
          this.accountStore.setLoading(false);
          return throwError(err.message);
        })
      );
    }
  }

  editPhoneNumber(user: LongFormRegistrationModel): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    const mobileNumber: string = `${user.mobilePrefix}${user.mobileNumber}`;

    this.accountStore.setLoading(true);

    const callBody: RegistrationApiModel = {
      UserDetails: {
        BirthDate: format(new Date(this.accountQuery.userData.dateOfBirth), 'yyyy-MM-dd'),
        Name: this.accountQuery.userData.name,
        Surname: this.accountQuery.userData.surname,
        GenderCode: this.accountQuery.userData.gender,
        Currency: this.accountQuery.userData.currency.code,
        Username: this.accountQuery.userData.username,
        Email: this.accountQuery.userData.email,
        CountryCode: this.accountQuery.userData.address.countryCode,
        LanguageCode: 'eng'
      },
      UserAddressDetails: [
        {
          Id: this.accountQuery.userData.address.id,
          CountryCode: this.accountQuery.userData.address.countryCode,
          Region: this.accountQuery.userData.address.state,
          Province: '',
          City: this.accountQuery.userData.address.cityCode,
          Line1: this.accountQuery.userData.address.addressLine,
          PostalCode: 'N/A',
          AddressTypeCode: 'MAIN'
        }
      ],
      PhoneContactDetails: [
        {
          Id: this.accountQuery.userData.mobile ? this.accountQuery.userData.mobile.id : 0,
          PhoneContactTypeCode: 'MB',
          ContactNumber: mobileNumber
        }
      ],
      ExtraDetails: [
        {
          Id: this.accountQuery.userData.marketingConsent.id,
          PropertyName: 'ReceiveMarketingMessages',
          PropertyValue: `${this.accountQuery.userData.marketingConsent.value}`
        }
      ],
      SecurityDetails: {
        SecurityQuestionCode: 'QST1',
        SecurityAnswer: 'Not Provided'
      }
    };

    return this.apiService.post(APIType.Platform, 'api/User/Profile', callBody, apiSettings).pipe(
      first(),
      map(() => {
        this.accountStore.setLoading(false);
      }),
      catchError((err: HttpErrorResponse) => {
        this.accountStore.setError(err);
        this.accountStore.setLoading(false);
        return throwError(err.message);
      })
    );
  }

  acceptEuro2020Terms(): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: this.accountQuery.accessToken
    });

    this.accountStore.setLoading(true);

    const callBody: Partial<RegistrationApiModel> = {
      UserDetails: {
        BirthDate: format(new Date(this.accountQuery.userData.dateOfBirth), 'yyyy-MM-dd'),
        Name: this.accountQuery.userData.name,
        Surname: this.accountQuery.userData.surname,
        GenderCode: this.accountQuery.userData.gender,
        Currency: this.accountQuery.userData.currency.code,
        Username: this.accountQuery.userData.username,
        Email: this.accountQuery.userData.email,
        CountryCode: this.accountQuery.userData.address.countryCode,
        LanguageCode: 'eng'
      },
      ExtraDetails: [
        {
          PropertyName: 'AcceptedEuro2020Terms',
          PropertyValue: 'true'
        }
      ],
      SecurityDetails: {
        SecurityQuestionCode: 'QST1',
        SecurityAnswer: 'Not Provided'
      }
    };

    return this.apiService.post(APIType.Platform, 'api/User/Profile', callBody, apiSettings).pipe(
      first(),
      map(() => {
        this.accountStore.setLoading(false);
      }),
      catchError((err: HttpErrorResponse) => {
        this.accountStore.setError(err);
        this.accountStore.setLoading(false);
        return throwError(err.message);
      })
    );
  }

  clearError(): void {
    this.accountStore.setError(undefined);
  }

  mobileNumberCleanup(value: string): string {
    return value[0] === '0' ? value.substr(1) : value;
  }

  updateTransferCandidates(transferCandidates: any): void {
    this.accountStore.updateTransferCandidates(transferCandidates.map(tc => this.mapTransferCandidatesResponseToModel(tc)));
  }

  getTransferCandidates(statusFilter: string): Observable<any> {
    return this.apiService
      .get<any>(
        APIType.Platform,
        `api/Finance/Accounts/Agents/TransferCandidates?UserStatusesFilterBy=${statusFilter}&DirectDescendantsOnly=true&Recursive=false&AgentsOnly=false`
      )
      .pipe(
        first(),
        map(m => {
          this.updateTransferCandidates(m.Result);
        })
      );
  }

  transferFunds(type: 'D' | 'W', userId: number, amount: number): Observable<boolean> {
    return this.apiService
      .post<any>(APIType.Platform, `api/Finance/Accounts/Agents/Transfer${type === 'D' ? 'To' : 'From'}`, {
        UserId: userId,
        Amount: amount
      })
      .pipe(
        first(),
        map(m => m.Result && m.SystemMessage === 'Success')
      );
  }

  isVirtualsEnabled(accessToken: string): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get(APIType.Platform, 'api/GamingVendors/BetagyVirtuals/IsEnabled', apiSettings);
  }

  getKYCStatus(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get<any>(APIType.Platform, 'api/blinking/kycstatus', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }

        const userData = this.accountQuery.userData;
        if (userData) {
          const kycStatus: any = {
            badges: responseData.Result.Badges,
            documentExpirationDate: responseData.Result.DocumentExpirationDate,
            lastUpdatedBy: responseData.Result.LastUpdatedBy,
            lastUpdateDateTime: responseData.Result.LastUpdateDateTime
          };

          const userDataCopy: UserModel = cloneDeep(userData);
          userDataCopy.KYCStatus = kycStatus;

          this.accountStore.updateUserData(userDataCopy);
        }

        return responseData.Result;
      })
    );
  }

  getBlinkingSessionStatus(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get<any>(APIType.Platform, 'api/blinking/sessionstatus', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }

        const userData = this.accountQuery.userData;
        const userDataCopy: UserModel = cloneDeep(userData);
        userDataCopy.blinkingSessionStatus = responseData.Result;

        this.accountStore.updateUserData(userDataCopy);

        return responseData.Result;
      })
    );
  }

  getUserProfile(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get<any>(APIType.Platform, 'api/User/Profile', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }
        return responseData.Result;
      })
    );
  }

  updateUserProfile(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get<any>(APIType.Platform, 'api/User/Profile', apiSettings).pipe(
      // tslint:disable-next-line: cyclomatic-complexity
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }

        const profileData = responseData.Result;

        const receiveMarketingMessages: any = profileData.ExtraDetails.find(item => item.PropertyName === 'ReceiveMarketingMessages');
        const citizenIdNumber: any = profileData.ExtraDetails.find(item => item.PropertyName === 'Blinking.CitizenId');
        const emailVerified: boolean = !!profileData.ExtraDetails.find(item => item.PropertyName === 'EmailVerifiedDate');
        const skipSessionOne: boolean = !!profileData.ExtraDetails.find(item => item.PropertyName === 'SkipSessionOneCheck');

        const useStandardVerification: any = profileData.ExtraDetails.find(item => item.PropertyName === 'UseStandardVerification');
        const standardVerificationPending: any = profileData.ExtraDetails.find(item => item.PropertyName === 'StandardVerificationPending');

        const userData = this.accountQuery.userData;
        const userDataCopy: UserModel = cloneDeep(userData);
        userDataCopy.name = profileData.UserDetails.Name;
        userDataCopy.surname = profileData.UserDetails.Surname;
        userDataCopy.dateOfBirth = new Date(profileData.UserDetails.BirthDate);
        userDataCopy.gender = profileData.UserDetails.GenderCode;
        userDataCopy.email = profileData.UserDetails.Email;

        userDataCopy.marketingConsent = {
          id: receiveMarketingMessages !== undefined ? receiveMarketingMessages.Id : undefined,
          value: receiveMarketingMessages !== undefined ? receiveMarketingMessages.PropertyValue.toLowerCase() === 'true' : undefined
        };
        userDataCopy.citizenIdNumber = {
          id: citizenIdNumber !== undefined ? citizenIdNumber.Id : undefined,
          value: citizenIdNumber !== undefined ? citizenIdNumber.PropertyValue : undefined
        };
        userDataCopy.useStandardVerification = {
          id: useStandardVerification !== undefined ? useStandardVerification.Id : undefined,
          value: useStandardVerification !== undefined ? useStandardVerification.PropertyValue.toLowerCase() === 'true' : undefined
        };
        userDataCopy.standardVerificationPending = {
          id: standardVerificationPending !== undefined ? standardVerificationPending.Id : undefined,
          value: standardVerificationPending !== undefined ? standardVerificationPending.PropertyValue.toLowerCase() === 'true' : undefined
        };
        userDataCopy.address = {
          id: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].Id : undefined,
          countryCode: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].CountryCode : undefined,
          state: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].Region : undefined,
          cityCode: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].City : undefined,
          addressLine:
            profileData.UserAddressDetails.length > 0
              ? profileData.UserAddressDetails[0].Line2
                ? `${profileData.UserAddressDetails[0].Line1} ${profileData.UserAddressDetails[0].Line2}`
                : profileData.UserAddressDetails[0].Line1
              : undefined
        };
        userDataCopy.emailVerified = emailVerified;
        userDataCopy.skipSessionOne = skipSessionOne;
        this.accountStore.updateUserData(userDataCopy);

        return responseData.Result;
      })
    );
  }

  private mapTransferCandidatesResponseToModel(transferCandidate: any): TransferCandidateModel {
    return new TransferCandidateModel({
      userId: transferCandidate.UserId,
      username: transferCandidate.Username,
      name: transferCandidate.Name,
      surname: transferCandidate.Surname,
      balance: transferCandidate.Balance
    });
  }

  private needsUppercase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (control.value.toLowerCase() === control.value) {
        return { needsUppercase: true };
      }
      return undefined;
    };
  }

  private needsLowercase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (control.value.toUpperCase() === control.value) {
        return { needsLowercase: true };
      }
      return undefined;
    };
  }

  private needsDigits(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (!new RegExp(/(?:\D*\d){2}/).test(control.value)) {
        return { needsDigits: true };
      }
      return undefined;
    };
  }

  private needsLetters(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (control.value.replace(/[0-9]/g, '').length < 4) {
        return { needsLetters: true };
      }
      return undefined;
    };
  }

  private hasSpaces(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (control.value.replace(/ /g, '') !== control.value) {
        return { hasSpaces: true };
      }
      return undefined;
    };
  }

  private hasDefaultDate(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (format(new Date(control.value), 'yyyy-MM-dd') === format(new Date('1900-01-01'), 'yyyy-MM-dd')) {
        return { hasDefaultDate: true };
      }
      return undefined;
    };
  }

  private hasDefaultGender(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | undefined => {
      if (control.value === 'U') {
        return { hasDefaultGender: true };
      }
      return undefined;
    };
  }

  private updateRefreshingBalance(refreshingBalance: boolean): void {
    this.accountStore.updateUI({ refreshingBalance });
  }

  private getUserStatus(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get<any>(APIType.Platform, 'api/Security/Accounts/Status', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }
        return responseData.Result;
      })
    );
  }

  private getLoginMessages(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get<any>(APIType.Platform, 'api/User/Messages/LoginMessages', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }
        return responseData.Result;
      })
    );
  }

  private getBankAccountDetails(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }

    const apiSettings: APISettings = new APISettings({
      forceAuthToken: accessToken
    });

    return this.apiService.get<any>(APIType.Platform, 'api/Finance/BankTransferDetails', apiSettings).pipe(
      map(responseData => {
        if (responseData === undefined || responseData.Result === undefined) {
          return undefined;
        }
        return responseData.Result[0];
      })
    );
  }

  private getAccountSectionItems(): void {
    const mobilePhoneEditability: boolean = this.appConfig.get('account').mobilePhoneEditability;
    const verifyData: any = this.localStorage.retrieve(this.verifyAccountDataKey);
    const verifyAccountType: typeof VerifyAccountType = VerifyAccountType;
    const endpoint2 = this.languageService.selectedLanguage.language === 'en' ? 'menus/18' : 'menus/5';

    this.apiService
      .get(APIType.CMS, endpoint2)
      .pipe(first())
      .subscribe(
        data => {
          if (data !== undefined && data.Menu) {
            this.accountStore.updateAccountMenuItems(data.Menu.map(item => this.mapCMSLinkToAccountMenuLinkModel(item)));
          }
        },
        () => {
          if (this.DEFAULT_MENUITEMS.length > 0) {
            const menuItemsCopy: AccountMenuLinkModel[] = this.DEFAULT_MENUITEMS;

            menuItemsCopy.forEach(item => {
              item.isLocked = this.applicationService.isAccountMenuLocked(item.link);

              if (item.link === '/account/change-phone-number') {
                item.isVisible = mobilePhoneEditability && verifyData && verifyData.type !== verifyAccountType.Verified;
              }
            });

            this.accountStore.updateAccountMenuItems(menuItemsCopy);
          }
        }
      );
    const endpoint = this.languageService.selectedLanguage.language === 'en' ? 'menus/17' : 'menus/6';

    this.apiService
      .get(APIType.CMS, endpoint)
      .pipe(first())
      .subscribe(
        data => {
          if (data !== undefined && data.Menu && data.Menu.length > 0) {
            this.accountStore.updateAccounteHelpMenuItems(data.Menu.map(item => this.mapCMSLinkToAccountMenuLinkModel(item)));
          }
        },
        () => {
          if (this.DEFAULT_HELPMENUITEMS.length > 0) {
            const helpMenuItemsCopy: AccountMenuLinkModel[] = this.DEFAULT_HELPMENUITEMS;

            helpMenuItemsCopy.forEach(item => {
              item.isLocked = this.applicationService.isAccountMenuLocked(item.link);
            });

            this.accountStore.updateAccounteHelpMenuItems(helpMenuItemsCopy);
          }
        }
      );
  }

  private mapCMSLinkToAccountMenuLinkModel(cmsLink: MenuItemModel): AccountMenuLinkModel {
    return new AccountMenuLinkModel({
      text: cmsLink.title,
      link: cmsLink.link,
      iconFontValue: cmsLink.icon,
      visibleToTheseUserTypes: [],
      isLocked: this.applicationService.isAccountMenuLocked(cmsLink.link),
      isCustomIcon: !cmsLink.font_awesome,
      showWarningIcon: cmsLink.showWarningIcon
    });
  }

  // tslint:disable-next-line: cyclomatic-complexity
  private parseUserData(
    accessToken: string,
    profileData: any,
    wallets: any,
    userStatus: any,
    mobileDetails: any,
    bankAccountDetails: any,
    KYCStatusData: any
  ): UserModel {
    try {
      const receiveMarketingMessages: any = profileData.ExtraDetails.find(item => item.PropertyName === 'ReceiveMarketingMessages');
      const citizenIdNumber: any = profileData.ExtraDetails.find(item => item.PropertyName === 'Blinking.CitizenId');
      const useStandardVerification: any = profileData.ExtraDetails.find(item => item.PropertyName === 'UseStandardVerification');
      const standardVerificationPending: any = profileData.ExtraDetails.find(item => item.PropertyName === 'StandardVerificationPending');
      const isPoliticallyExposed: any = profileData.ExtraDetails.find(item => item.PropertyName === 'IsPoliticallyExposed');
      const sportsConfig: any = this.appConfig.get('sports');
      let defaultCurrency: string = '';
      if (sportsConfig && sportsConfig.coupon && sportsConfig.coupon.defaultCurrency) {
        defaultCurrency = sportsConfig.coupon.defaultCurrency;
      }

      const kycStatus: any = {
        badges: KYCStatusData.Badges,
        documentExpirationDate: KYCStatusData.DocumentExpirationDate,
        lastUpdatedBy: KYCStatusData.LastUpdatedBy,
        lastUpdateDateTime: KYCStatusData.LastUpdateDateTime
      };
      const emailVerified: boolean = !!profileData.ExtraDetails.find(item => item.PropertyName === 'EmailVerifiedDate');
      const skipSessionOne: boolean = !!profileData.ExtraDetails.find(item => item.PropertyName === 'SkipSessionOneCheck');

      const userData = new UserModel({
        emailVerified,
        skipSessionOne,
        id: profileData.UserDetails.UserId,
        name: profileData.UserDetails.Name,
        surname: profileData.UserDetails.Surname,
        username: profileData.UserDetails.Username,
        email: profileData.UserDetails.Email,
        userTypeCode: this.getUserType(profileData.UserDetails.UserTypeCode),
        userStatus: userStatus.Code,
        accessToken: accessToken,
        loginDate: new Date(),
        wallets: wallets,
        currency: new CurrencyModel({
          // defaultCountry should never be empty, but we still need to supply a value or an exception would be thrown
          code: profileData.UserDetails.Currency || defaultCurrency,
          name: profileData.UserDetails.Currency,
          symbol: profileData.UserDetails.CurrencySymbol
        }),
        dateOfBirth: new Date(profileData.UserDetails.BirthDate),
        gender: profileData.UserDetails.GenderCode,
        marketingConsent: {
          id: receiveMarketingMessages !== undefined ? receiveMarketingMessages.Id : undefined,
          value: receiveMarketingMessages !== undefined ? receiveMarketingMessages.PropertyValue.toLowerCase() === 'true' : undefined
        },
        citizenIdNumber: {
          id: citizenIdNumber !== undefined ? citizenIdNumber.Id : undefined,
          value: citizenIdNumber !== undefined ? citizenIdNumber.PropertyValue : undefined
        },
        useStandardVerification: {
          id: useStandardVerification !== undefined ? useStandardVerification.Id : undefined,
          value: useStandardVerification !== undefined ? useStandardVerification.PropertyValue.toLowerCase() === 'true' : undefined
        },
        standardVerificationPending: {
          id: standardVerificationPending !== undefined ? standardVerificationPending.Id : undefined,
          value: standardVerificationPending !== undefined ? standardVerificationPending.PropertyValue.toLowerCase() === 'true' : undefined
        },
        isPoliticallyExposed: {
          id: isPoliticallyExposed !== undefined ? isPoliticallyExposed.Id : undefined,
          value: isPoliticallyExposed !== undefined ? isPoliticallyExposed.PropertyValue.toLowerCase() === 'true' : undefined
        },
        address: {
          id: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].Id : undefined,
          countryCode: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].CountryCode : undefined,
          state: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].Region : undefined,
          cityCode: profileData.UserAddressDetails.length > 0 ? profileData.UserAddressDetails[0].City : undefined,
          addressLine:
            profileData.UserAddressDetails.length > 0
              ? profileData.UserAddressDetails[0].Line2
                ? `${profileData.UserAddressDetails[0].Line1} ${profileData.UserAddressDetails[0].Line2}`
                : profileData.UserAddressDetails[0].Line1
              : undefined
        },
        bankAccountDetails: bankAccountDetails,
        KYCStatus: kycStatus
      });

      if (mobileDetails) {
        userData.mobile = {
          id: mobileDetails.Id,
          mobileNumber: mobileDetails.ContactNumber,
          verifyType: mobileDetails.PhoneContactVerificationStatus,
          lastStatusChangeDate: mobileDetails.LastStatusChangeDate
        };
      }

      return userData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}
