<div class="selection-container">
  <ng-container *ngFor="let selection of couponQuery.selections$ | async; trackBy: selectionTrackBy">
    <div *ngFor="let tournament of selection.categories[0].tournaments; trackBy: tournamentTrackBy" class="selection-content">
      <ng-container *ngFor="let match of tournament.matches; trackBy: matchTrackBy">
        <div class="event-details-container">
          <div class="event-details">
            <div class="left-elements">
              <div class="product-icon sport-icon icon-{{ selection.id }}"></div>
              <div class="match-odd">
                <span class="match-name">{{ match.name }} |</span>
                <span class="market-name">{{ tournament.regions[0].areas[0].markets[0].name }}:</span>
                <span class="selection-name">{{ match.odds[0].selectionName }}</span>
              </div>
            </div>
            <div [ngClass]="hasOddChanges$ | async | oddChanged: match.odds[0].id" class="right-elements">
              <div class="odd-value">{{ match.odds[0].value | number: '1.2-2' }}</div>
              <div class="close-odd" (click)="removeOdd(match.odds[0].id)" transloco="×"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
