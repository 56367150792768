import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

@Component({
  selector: 'app-choose-your-promo-section',
  templateUrl: './choose-your-promo-section.component.html',
  styleUrls: ['./choose-your-promo-section.component.scss']
})
export class ChooseYourPromoSectionComponent implements OnInit, OnDestroy {
  seeDetails$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  destroy$: Subject<boolean> = new Subject();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accountService: AccountService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(this.handleRouteParams.bind(this));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onChooseYourPromoActivateButtonClick() {
    this.accountService.showChooseYourPromo$.next(true);
  }

  handleReadMoreClick() {
    if (!this.seeDetails$.value) {
      this.router.navigate(['menu', 'promos', 'biraj-svoju-promociju']);
    }
    this.seeDetails$.next(!this.seeDetails$.value);
  }

  private handleRouteParams(params: ParamMap): void {
    const section = params.get('section');
    if (section === 'biraj-svoju-promociju') {
      this.seeDetails$.next(true);
    }
  }
}
