import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { CorrectScoreEventService } from 'src/app/core/services/correct-score-event.service';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-correct-score-event',
  templateUrl: './correct-score-event.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectScoreEventComponent implements OnChanges {
  parsedEvent$: BehaviorSubject<MatchModel> = new BehaviorSubject(undefined);

  @Input() event: MatchModel;
  /**
   * This only applies for event with no selector and when showDetails is true
   */
  @Input() sportId: number;
  /**
   * This only applies for event with no selector and when showDetails is true
   */
  @Input() showDate = true;
  @Input() showDetails: boolean = true;
  @Input() withSelector: boolean = false;
  @Input() isAvailable: boolean = true;
  /**
   * This only applies for event with selector
   */
  @Output() readonly toggleSelector = new EventEmitter();

  constructor(private readonly correctScoreEventService: CorrectScoreEventService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.parsedEvent$.next(
      this.event.correctScoreOdds === undefined ? this.correctScoreEventService.matchFactoryForCorrectScoreView(this.event) : this.event
    );
  }

  clickSelector(): void {
    this.toggleSelector.emit();
  }
}
