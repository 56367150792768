import { EntityState, guid, ID } from '@datorama/akita';
import { CouponDetailsGroupModel } from 'src/app/shared/models/coupon-details.model';

export interface MyAllBetsState extends EntityState<RecentBetModel> {}
export interface MyOpenBetsState extends EntityState<RecentBetModel> {}
export interface MyAllOpenBetsState extends EntityState<RecentBetModel> {}
export interface MySettledBetsState extends EntityState<RecentBetModel> {
  lostBetsLoaded: boolean;
  ui: {
    selectedCouponStatus: BetFinalState;
  };
}

export interface MyBetsState {
  selectedTab: BetsTab;
}

export enum CouponStatus {
  All = 1,
  Open = 2,
  Won = 3,
  Lost = 4
}

export enum BetFinalState {
  Placed = -1,
  InEvaluation = 0,
  Won = 1,
  PartiallyWon = 2,
  Lost = 3,
  Void = 4,
  Cashout = 5,
  Promotion = 6
}

export enum BetsTab {
  All,
  Open,
  Won,
  Cashout,
  Lost
}

export enum SettledTab {
  Won,
  Lost
}

export class RecentBetModel {
  id: ID = guid();
  couponCode: string;
  couponDate: string;
  couponStatus: string;
  couponStatusId: number;
  betFinalState: BetFinalState;
  currencySymbol: string;
  stakeGross: number;
  userId: string;
  betDetails: BetDetailsModel;
  collapsed: boolean = true;
  overviewWin: number;
  wonAmount: number;
  isLive: boolean;
  totalOdds: number;
  couponType: string;

  constructor(init: Partial<RecentBetModel>) {
    Object.assign(this, init);
  }

  get isWinningBet(): boolean {
    return [BetFinalState.Won, BetFinalState.PartiallyWon, BetFinalState.Cashout].indexOf(this.betFinalState) > -1;
  }
}

export class BetDetailsModel {
  totalOdds: number;
  totalCombinations: number;
  netStakeMaxWin: number;
  maxWin: number;
  maxWinNet: number;
  maxWinTax: number;
  maxBonus: number;
  minWinNet: number;
  events: EventModel[] | EventsPerRoundModel[] | FullEventModel[];
  won: number;
  wonTax: number;
  couponType: string;
  minOdds?: number;
  maxOdds?: number;
  groupings?: CouponDetailsGroupModel[];

  constructor(init: Partial<BetDetailsModel>) {
    Object.assign(this, init);
  }
}

export interface EventModel {
  sportId: string;
  eventName: string;
  eventDate: Date;
  eventStatusId: EventStatus;
  marketName: string;
  selectionName: string;
  fixedOdd: boolean;
}

export interface FullEventModel extends EventModel {
  result: string;
  results: ResultRound[];
  eventID: number;
  providerID: number;
  eventCategory: EventCategory;
  oddValue?: number;
  spreadValue?: string;
  marketTypeId?: number;
}

export interface ResultRound {
  Value: number;
  Family: string;
  Symbol: string;
}

export enum EventStatus {
  Running = -1,
  Won = 1,
  Lost = 3,
  Cancelled = 4,
  Cashout = 5
}

export enum EventCategory {
  Live = 'L',
  Prematch = 'F'
}

export interface EventWithResults extends EventModel {
  halfTimeScore?: string;
  fullTimeScore?: string;
}

export interface EventsPerRoundModel {
  categoryName: string;
  leagueNo: number;
  roundNumber: number;
  tournamentDate: string;
  roundDisplayName: string;
  events: EventWithResults[];
}

export class RecentBetRequestModel {
  couponStatus: number;
  dateFrom: string;
  dateTo: string;
  pageSize: number;
  requestedPage: number;
  includUsers: boolean;
  lang: string;
  couponCode: string;
  settlementDate: boolean;

  constructor(init: Partial<RecentBetRequestModel>) {
    Object.assign(this, init);
  }
}
