import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { ToastNotification } from 'src/app/shared/models/notification.model';
import { MenuTabModel } from './menu.model';

export class ApplicationState {
  ui: ApplicationUIState;
  sideBarQuickLinks: MenuItemModel[];
  sideBarMenu: MenuTabModel[];
  activeUrl: string[];
  siteMessages: ApplicationSiteMessagesState;
  cms: CmsModel;
  selectedLanguage: LanguageModel;
  activeProduct: ProductType;

  constructor(init: Partial<ApplicationState>) {
    Object.assign(this, init);
  }
}

export enum ProductType {
  SportsBook = 1,
  Virtuals = 2
}

export class CmsModel {
  cacheTTL: number;
  fallbackTimer: number;
  lastQuicklinksUpdate: number;
  lastSportsBannerRotatorUpdate: number;
  lastPaymentsBannerRotatorUpdate: number;
  lastLandingPageMenuItemsUpdate: number;
  lastSidebarQuicklinksUpdate: number;

  constructor(init: Partial<CmsModel>) {
    Object.assign(this, init);
  }
}

export class LanguageModel {
  language: string;
  languageDescription: string;
  languageIsoCode: string;
  locale: string;
  umbracoLocale: string;
  dateLocale: Locale;
  languageBetradar: string;

  constructor(init: LanguageModel) {
    Object.assign(this, init);
  }
}

export class ApplicationSiteMessagesState {
  general: ToastNotification[];
  sports: ToastNotification[];
}

export class ApplicationUIState {
  configUI: ConfigUIModel;
  showMenu: boolean;
  showAccountMenu: boolean;
  showNavBar: boolean;
  showLogin: boolean;
  showCoupon: boolean;
  showQuickCoupon: boolean;
  showMyBets: boolean;
  showDropdownOverlay: boolean;
  showVirtualsBetslip: boolean;
  loginState: 'Login' | 'Password Recovery';
  isZohoLiveChatLoading: boolean;
  loadingQuicklinks: boolean;
  landingMenuExpanded: boolean;
  loadingSFKChat: boolean;
  showSFKChat: boolean;
  scrollY: number;
  loader: LoaderState;

  constructor(init: Partial<ApplicationUIState>) {
    Object.assign(this, init);
  }
}

export interface LoaderState {
  /** Controls the full screen loader */
  show: boolean;
  /** Normally the loader shows with a delay, use this to bypass it and show immidiately */
  noDelay: boolean;
}

export interface ConfigUIModel {
  landing: ConfigLandingUIModel;
  live: ConfigLiveUIModel;
  footer: ConfigFooterUIModel;
}

export interface ConfigLandingUIModel {
  headerType: 'center' | 'left' | 'right';
}

export interface ConfigLiveUIModel {
  showLiveBadge: boolean;
}

export interface ConfigFooterUIModel {
  showBrandLogo: boolean;
  showAwardIcons: boolean;
  footerShowPaymentMethodImage: boolean;
}

export enum LoginState {
  Login,
  PasswordRecovery
}

export interface MenuMethodCalls {
  // loadLiveChat(): void;
  openBetSlip(): void;
}

export interface SidebarBox {
  title?: string;
  subtitle?: string;
  thumb?: {
    url: string;
    alt: string;
  };
  background?: {
    url: string;
    alt: string;
  };
  fullImage?: {
    url: string;
    alt: string;
  };
  exactRoutes: string;
  startWithRoutes: string;
  side: 'left' | 'right';
  buttons: {
    text: string;
    style: 'primary_button' | 'secondary_button' | 'primary_link' | 'secondary_link' | 'tertiary_link';
    url: string;
  }[];
}
