import { Injectable } from '@angular/core';
import { translate, TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, interval, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { LiveQuery } from 'src/app/core/state/live/live.query';
import { LiveStore } from 'src/app/core/state/live/live.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  EventModel,
  LiveEventByArea,
  LiveFeedModel,
  MarketModel,
  SelectionModel,
  TeamModel,
  TournamentModel
} from 'src/app/shared/models/live.model';
import { SortType } from 'src/app/shared/models/todays-events.model';
import { APIService } from './api.service';
import { brandInfo } from 'brands/victory/ts/brand-info';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ApplicationService } from './application.service';

@Injectable({
  providedIn: 'root'
})
export class LiveService {
  liveEventsFeed$: Observable<LiveFeedModel[]>;

  sortType$: BehaviorSubject<SortType> = new BehaviorSubject(SortType.ByTournament);
  searchActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  searchTerm: string = '';
  searchTerm$: BehaviorSubject<string> = new BehaviorSubject('');
  sportMarketIdSelected: { [sportId: number]: number } = {};
  lastOpenedEventId: number;
  selectedEventId$: BehaviorSubject<string | undefined> = new BehaviorSubject(undefined);
  isMatchTrackerAndDetailsOpen$ = new BehaviorSubject(false);
  isMatchTrackerOpen$ = new BehaviorSubject(false);
  isMatchDetailsOpen$ = new BehaviorSubject(false);
  toggleLiveTracker = { matchAndDetails: false, match: false, details: false };
  shouldDisplayDraggableMatchTracker$ = new BehaviorSubject(false);
  liveMatchTrackerUrl$: BehaviorSubject<SafeResourceUrl | undefined> = new BehaviorSubject(undefined);
  liveMatchDetailsUrl$: BehaviorSubject<SafeResourceUrl | undefined> = new BehaviorSubject(undefined);
  liveApiBaseUrl: string;

  private readonly matchStatusMapper: any = {
    0: translate('Not Set'),
    1: translate('Not started'),
    2: translate('Started'),
    3: translate('First period'),
    4: translate('Second period'),
    5: translate('Third period'),
    6: translate('Fourth period'),
    7: translate('Fifth period'),
    8: translate('First set'),
    9: translate('Second set'),
    10: translate('Third set'),
    11: translate('Fourth set'),
    12: translate('Fifth set'),
    13: translate('Sixth set'),
    14: translate('Seventh set'),
    15: translate('First quarter'),
    16: translate('Second quarter'),
    17: translate('Third quarter'),
    18: translate('Fourth quarter'),
    19: translate('Match pause'),
    20: translate('First pause'),
    21: translate('Second pause'),
    22: translate('Third pause'),
    23: translate('Fourth pause'),
    24: translate('Fifth pause'),
    25: translate('Sixth pause'),
    26: translate('Overtime'),
    27: translate('Awaiting overtime'),
    28: translate('First half overtime'),
    29: translate('Overtime half time'),
    30: translate('Second half overtime'),
    31: translate('After overtime'),
    32: translate('Awaiting penalities'),
    33: translate('Penalty shootout'),
    34: translate('After penalities'),
    35: translate('Ended'),
    36: translate('Postponed'),
    37: translate('Delayed'),
    38: translate('Cancelled'),
    39: translate('Cancelled'),
    40: translate('Interrupted'),
    41: translate('Abandoned'),
    42: translate('Walkover'),
    43: translate('Walkover 1'),
    44: translate('Walkover 2'),
    45: translate('Retired'),
    46: translate('Retired 1'),
    47: translate('Retired 2'),
    48: translate('Top of 1st inning'),
    49: translate('Bottom of 1st inning'),
    50: translate('Top of 2nd inning'),
    51: translate('Bottom of 2nd inning'),
    52: translate('Top of 3rd inning'),
    53: translate('Bottom of 3rd inning'),
    54: translate('Top of 4th inning'),
    55: translate('Bottom of 4th inning'),
    56: translate('Top of 5th inning'),
    57: translate('Bottom of 5th inning'),
    58: translate('Top of 6th inning'),
    59: translate('Bottom of 6th inning'),
    60: translate('Top of 7th inning'),
    61: translate('Bottom of 7th inning'),
    62: translate('Top of 8th inning'),
    63: translate('Bottom of 8th inning'),
    64: translate('Top of 9th inning'),
    65: translate('Bottom of 9th inning'),
    66: translate('Top of Extra inning'),
    67: translate('Bottom of Extra inning'),
    68: translate('Break Top of 1st - Bottom of 1st'),
    69: translate('Break Top of 2nd - Bottom of 1st'),
    70: translate('Break Top of 2nd - Bottom of 2nd'),
    71: translate('Break Top of 3rd - Bottom of 2nd'),
    72: translate('Break Top of 3rd - Bottom of 3rd'),
    73: translate('Break Top of 4th - Bottom of 3rd'),
    74: translate('Break Top of 4th - Bottom of 4th'),
    75: translate('Break Top of 5th - Bottom of 4th'),
    76: translate('Break Top of 5th - Bottom of 5th'),
    77: translate('Break Top of 6th - Bottom of 5th'),
    78: translate('Break Top of 6th - Bottom of 6th'),
    79: translate('Break Top of 7th - Bottom of 6th'),
    80: translate('Break Top of 7th - Bottom of 7th'),
    81: translate('Break Top of 8th - Bottom of 7th'),
    82: translate('Break Top of 8th - Bottom of 8th'),
    83: translate('Break Top of 9th - Bottom of 8th'),
    84: translate('Break Top of 9th - Bottom of 9th'),
    85: translate('Break Top of EI - Bottom of 9th'),
    86: translate('Break Top of EI - Bottom of EI'),
    87: translate('Golden Set'),
    88: translate('Sudden Death'),
    89: translate('After Sudden Death'),
    90: translate('In Progress'),
    91: translate('Session Break'),
    92: translate('Defaulted 1'),
    93: translate('Defaulted 2'),
    94: translate('1st innings, home team'),
    95: translate('1st innings, away team'),
    96: translate('2nd innings, home team'),
    97: translate('2nd innings, away team'),
    98: translate('Awaiting super over'),
    99: translate('Super over, home team'),
    100: translate('Super over, away team'),
    101: translate('After super over'),
    102: translate('Innings break'),
    103: translate('Super over break'),
    104: translate('Lunch break'),
    105: translate('Tea break'),
    106: translate('Stumps'),
    107: translate('Injury Break'),
    108: translate('Awaiting Sudden Death'),
    109: translate('First Map'),
    110: translate('Second Map'),
    111: translate('Third Map'),
    112: translate('Fourth Map'),
    113: translate('Fifth Map'),
    114: translate('Sixth Map'),
    115: translate('Seventh Map'),
    116: translate('Awaiting Golden Set'),
    117: translate('After Golden Set'),
    118: translate('First Game'),
    119: translate('Second Game'),
    120: translate('Third Game'),
    121: translate('Fourth Game'),
    122: translate('Fifth Game')
  };

  private readonly matchStatusToSportMapper: any = {
    1: {
      3: translate('1st Half'),
      4: translate('2nd Half'),
      19: translate('Half Time'),
      118: translate('Half Time')
    },
    2: {
      119: translate('Match pause'),
      120: translate('Match pause'),
      121: translate('Match pause')
    }
  };

  constructor(
    private readonly apiService: APIService,
    private readonly liveStore: LiveStore,
    private readonly liveQuery: LiveQuery,
    private readonly appConfig: AppConfigService,
    private readonly transloco: TranslocoService,
    private readonly languageService: LanguageService,
    private readonly sanitizer: DomSanitizer,
    private readonly httpClient: HttpClient,
    private readonly applicationService: ApplicationService
  ) {
    this.liveEventsFeed$ = interval(this.appConfig.get('live').pollingTimer).pipe(switchMap(() => this.getLiveEvents(false)));
    this.liveApiBaseUrl = this.appConfig.get('apiBaseUrl').liveApiBaseUrl;
  }

  getMatchStatusMapper(matchStatus: number, sportId?: number): string {
    if (this.matchStatusToSportMapper[sportId]) {
      return this.matchStatusToSportMapper[sportId][matchStatus] ?? this.matchStatusMapper[matchStatus];
    }

    return this.matchStatusMapper[matchStatus];
  }

  getLiveMatchWidgetAndEventId(externalEventId: number): Observable<{ eventId: number; hasWidget: boolean }> {
    return this.httpClient.get<{ eventId: number; hasWidget: boolean }>(
      `${this.liveApiBaseUrl}/LiveMatchTracker?eventId=${externalEventId}`
    );
  }

  initialiseExefeedWidgets(event: { eventId: number; hasWidget: boolean }): void {
    if (!event.hasWidget) {
      this.liveMatchTrackerUrl$.next(undefined);
      this.liveMatchDetailsUrl$.next(undefined);
      return;
    }
    const brandName = brandInfo.brandName.toLowerCase();
    const language = this.languageService.selectedLanguage.language;
    const baseExefeedIframeUrl = 'https://tracker.exefeed.com/match-tracker';
    const liveMatchTracker = `${baseExefeedIframeUrl}/index.php?e=${event.eventId}&ln=${language}&uid=${brandName}&bgc=%231a1c1f&fc
    =%23ffffff`;
    const liveMatchDetails = `${baseExefeedIframeUrl}/vStat.php?e=${event.eventId}&ln=${language}&uid=${brandName}&bgc=%231a1c1f&fc
    =%23ffffff`;
    this.liveMatchTrackerUrl$.next(this.sanitizer.bypassSecurityTrustResourceUrl(liveMatchTracker));
    this.liveMatchDetailsUrl$.next(this.sanitizer.bypassSecurityTrustResourceUrl(liveMatchDetails));
  }

  getLiveEvents(isItFirstRun: boolean): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });
    if (isItFirstRun) {
      this.liveStore.updateUI({ liveFeedsLoading: true });
    }
    return this.apiService
      .get(
        APIType.SportsbookFeed,
        `api/feeds/live/areaOddsByLayout/${this.languageService.selectedLanguage.language}/0/3/0/false/false/true/true`,
        apiSettings
      )
      .pipe(tap(data => this.parseLiveEvents(data, isItFirstRun)));
  }

  getLiveEventById(eventId: string, areaId: number, isItFirstRun: boolean = false): Observable<any> {
    if (!eventId) {
      return of(undefined);
    }

    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });
    if (isItFirstRun) {
      this.liveStore.updateUI({ selectedLiveEventLoading: true });
    }

    let url = `api/feeds/live/byArea/${eventId}/${areaId}/${this.languageService.selectedLanguage.language}`;
    if (areaId === 0) {
      // if '0' get all areas
      url = `api/feeds/live/${eventId}/${this.languageService.selectedLanguage.language}`;
    }

    return this.apiService.get(APIType.SportsbookFeed, url, apiSettings).pipe(tap(data => this.parseLiveEvent(data, isItFirstRun)));
  }

  getCouponLiveEventById(eventId: string): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true
    });

    let url = `api/feeds/live/${eventId}/${this.languageService.selectedLanguage.language}`;

    return this.apiService.get(APIType.SportsbookFeed, url, apiSettings);
  }

  clearSelectedLiveEvent(): void {
    this.liveStore.updateSelectedLiveEvent(undefined);
  }

  mergeMarketsByGroupNo(markets: MarketModel[]) {
    const mergedMarkets = [];
    const groupNoProcessed = new Set();

    markets.forEach(market => {
      if (market.groupNo > 0) {
        if (!groupNoProcessed.has(market.groupNo)) {
          const marketsToMerge = markets.filter(m => m.groupNo === market.groupNo);
          const selectionsMerged = marketsToMerge.reduce((acc, m) => acc.concat(m.selections), []);

          mergedMarkets.push({ ...marketsToMerge[0], selections: selectionsMerged });
          groupNoProcessed.add(market.groupNo);
        }
      } else {
        mergedMarkets.push(market);
      }
    });

    return mergedMarkets;
  }

  parseLiveEvent(response: any, isItFirstRun: boolean = false): any {
    const retVal: LiveEventByArea = {
      areas: [],
      currentArea: { id: undefined, name: undefined, order: undefined },
      tournaments: [],
      eventCount: response.EventCount,
      id: response.Id,
      name: response.Name,
      noOfOdds: response.NoOfOdds
    };
    if (!response.Tournaments || !response.Tournaments.length) {
      this.liveStore.updateUI({ notValidEventId: true });
      this.liveStore.updateSelectedLiveEvent(undefined);
      this.liveStore.updateUI({ selectedLiveEventLoading: false });
      return;
    } else if (this.liveQuery.notValidEventId) {
      this.liveStore.updateUI({ notValidEventId: false });
    }
    if (response.Areas) {
      // add 'all' to get all areas
      // retVal.areas.push(new AreaModel({ id: 0, name: translate('All'), order: 0 }));

      response.Areas.forEach(responseAreas => {
        const newArea = new AreaModel({ id: responseAreas.Id, name: responseAreas.Name, order: responseAreas.Order });
        retVal.areas.push(newArea);
      });
      if (response.CurrentArea) {
        const newCurrentArea = new AreaModel({
          id: response.CurrentArea.Id,
          name: response.CurrentArea.Name,
          order: response.CurrentArea.Order
        });

        retVal.currentArea = newCurrentArea;
      }
    }

    response.Tournaments.forEach(responseTournament => {
      const newTournament = new TournamentModel({
        events: [],
        id: responseTournament.Id,
        name: responseTournament.Name,
        noOfOdds: responseTournament.NoOfOdds
      });
      responseTournament.Events.forEach(responseEvents => {
        const newEvent = new EventModel({
          aamsMatchID: responseEvents.AAMSMatchID,
          aamsTemplateID: responseEvents.AAMSTemplateID,
          awayGameScore: responseEvents.AwayGameScore,
          categoryId: responseEvents.CategoryId,
          categoryName: responseEvents.CategoryName,
          date: responseEvents.Date,
          hasWidget: responseEvents.HasWidget,
          homeGameScore: responseEvents.HomeGameScore,
          id: responseEvents.Id,
          incompatibleEvents: responseEvents.IncompatibleEvents,
          isStreamed: responseEvents.IsStreamed,
          markets: [],
          matchStatus: responseEvents.MatchStatus,
          matchTime: responseEvents.MatchTime,
          name: responseEvents.Name,
          popularity: responseEvents.Popularity,
          providerId: responseEvents.ProviderId,
          providerSourceID: responseEvents.ProviderSourceID,
          score: responseEvents.Score,
          selectionCount: responseEvents.SelectionCount,
          serviceOwner: responseEvents.ServiceOwner,
          setScores: responseEvents.SetScores,
          smartCode: responseEvents.SmartCode,
          sportId: response.Id,
          status: responseEvents.Status,
          teams: [],
          tournamentName: responseEvents.TournamentName
        });

        responseEvents.Teams.forEach(responseTeam => {
          const newTeam = new TeamModel({
            id: responseTeam.Id,
            itemOrder: responseTeam.ItemOrder,
            name: responseTeam.Name
          });
          newEvent.teams.push(newTeam);
        });

        responseEvents.Markets.forEach(responseMarkets => {
          let specifier: string = responseMarkets.Specifier;
          if (specifier) {
            const specifierSplit = specifier.split('~');
            specifier = specifierSplit.length > 1 ? specifierSplit[1] : specifierSplit[0];
          }

          const newMarket = new MarketModel({
            combinability: responseMarkets.Combinability,
            compatibleMarkets: responseMarkets.CompatibleMarkets,
            groupNo: responseMarkets.GroupNo,
            id: responseMarkets.Id,
            marketNameRaw: responseMarkets.MarketNameRaw,
            name: responseMarkets.Name,
            scheduleStatus: responseMarkets.ScheduleStatus,
            selections: [],
            spreadSelectionGroups: [],
            specialValue: responseMarkets.SpecialValue,
            specifier: +(specifier || 0),
            status: responseMarkets.Status,
            typeId: responseMarkets.TypeId,
            typeIdGroup: responseMarkets.TypeIdGroup
          });

          responseMarkets.Selections.forEach(responseSelections => {
            const newSelection = new SelectionModel({
              id: responseSelections.Id,
              name: responseSelections.Name,
              odds: [],
              typeId: responseSelections.TypeId
            });
            responseSelections.Odds.forEach(responseOdds => {
              const newOdd = this.createOddModel({
                odd: responseOdds,
                selection: responseSelections,
                event: responseEvents,
                market: responseMarkets,
                tournament: responseTournament,
                sport: response
              });
              newSelection.odds.push(newOdd);
            });
            newMarket.selections.push(newSelection);
          });

          const swapList = this.appConfig.get('sports').liveSelectionsSwap;
          if (swapList && swapList.indexOf(newMarket.typeId) !== -1) {
            newMarket.selections = [newMarket.selections[1], newMarket.selections[0]];
          }

          const allMarketSelectionsOdds = newMarket.selections.map(selection => selection.odds[0]?.value).map(odd => !!odd);
          const shouldMarketBeExcluded = !allMarketSelectionsOdds.find(oddValid => oddValid);

          if (shouldMarketBeExcluded) {
            return;
          }

          newEvent.markets.push(newMarket);
        });

        // ***
        // ***
        // ***  1. Group live markets by: TypeId
        // ***
        // ***
        const groupMarketTypeIds = Array.from(
          new Set(
            newEvent.markets
              .filter(market => market.selections.length === 2)
              .map(market => market.typeId)
              .filter((e, i, a) => a.indexOf(e) !== i)
              .filter((marketTypeId: number) => this.appConfig.get('sports').liveGroupingTypeIds.includes(marketTypeId))
          )
        );

        groupMarketTypeIds.forEach(marketTypeId => {
          const firstIndexOfTypeId = newEvent.markets.findIndex(market => market.typeId === marketTypeId);
          if (firstIndexOfTypeId === -1) {
            return;
          }

          const allMarketsOfTypeId = newEvent.markets.filter(market => market.typeId === marketTypeId);
          allMarketsOfTypeId.forEach(market => {
            newEvent.markets[firstIndexOfTypeId].spreadSelectionGroups.push({
              specifier: market.specifier,
              selections: [...market.selections]
            });
          });

          const namesOfMarketsOfTypeId = allMarketsOfTypeId.map(market => market.name);
          let commonMarketName = '';
          const firstMarketName = namesOfMarketsOfTypeId[0].split(' ');
          const secondMarketName = (namesOfMarketsOfTypeId.length > 1 ? namesOfMarketsOfTypeId[1] : '').split(' ');
          firstMarketName.forEach((seq, idx) => {
            if (secondMarketName[idx] === seq) {
              commonMarketName += `${seq} `;
            }
          });
          commonMarketName = commonMarketName.trim();
          newEvent.markets[firstIndexOfTypeId].name = commonMarketName;

          newEvent.markets = newEvent.markets.filter(market => market.typeId !== marketTypeId || market.spreadSelectionGroups.length > 0);
        });

        // ***
        // ***
        // ***  2. Group live markets by: GroupNo
        // ***
        // ***
        newEvent.markets = this.mergeMarketsByGroupNo(newEvent.markets);

        newTournament.events.push(newEvent);
      });
      retVal.tournaments.push(newTournament);
    });

    const newLiveEvent = new LiveEventByArea(retVal);
    this.liveStore.updateSelectedLiveEvent(newLiveEvent);
    if (isItFirstRun) {
      this.liveStore.updateUI({ selectedLiveEventLoading: false });
    }
    return newLiveEvent;
  }

  parseLiveEvents(response: any, isItFirstRun: boolean): LiveFeedModel[] {
    const retVal = [];
    if (!response.Sports || response.Sports.length === 0) {
      this.liveStore.updateUI({ noLiveFeedData: true });
      this.liveStore.updateLiveFeed(undefined);
      return;
    } else if (this.liveQuery.notValidEventId) {
      this.liveStore.updateUI({ noLiveFeedData: false });
    }
    let newFavourites = [];
    response.Sports.forEach(responseSport => {
      const liveFeedData = new LiveFeedModel({
        areas: responseSport.Areas,
        currentArea: responseSport.CurrentArea,
        eventCount: responseSport.EventCount,
        id: responseSport.Id,
        name: responseSport.Name,
        noOfOdds: responseSport.NoOfOdds,
        tournaments: [],
        markets: []
      });

      response.SportsMarkets.forEach(sportMarket => {
        if (sportMarket.Id === responseSport.Id) {
          sportMarket.Markets.forEach(market => {
            const newSportMarket = new MarketModel({
              combinability: market.Combinability,
              compatibleMarkets: market.CompatibleMarkets,
              groupNo: market.GroupNo,
              id: market.Id,
              marketNameRaw: market.MarketNameRaw,
              name: market.Name,
              scheduleStatus: market.ScheduleStatus,
              selections: [],
              specialValue: market.SpecialValue,
              status: market.Status,
              typeId: market.TypeId,
              typeIdGroup: market.TypeIdGroup
            });
            market.Selections.forEach(selection => {
              if (!selection) {
                return;
              }
              const newSelection = new SelectionModel({
                id: selection.Id,
                name: selection.Name,
                odds: [],
                typeId: selection.TypeId
              });
              newSportMarket.selections.push(newSelection);
            });
            liveFeedData.markets.push(newSportMarket);
          });
        }
      });

      responseSport.Tournaments.forEach(responseTournament => {
        const newTournament = new TournamentModel({
          events: [],
          id: responseTournament.Id,
          name: responseTournament.Name,
          noOfOdds: responseTournament.NoOfOdds
        });

        responseTournament.Events.forEach(responseEvents => {
          const newEvent = new EventModel({
            aamsMatchID: responseEvents.AAMSMatchID,
            aamsTemplateID: responseEvents.AAMSTemplateID,
            awayGameScore: responseEvents.AwayGameScore,
            categoryId: responseEvents.CategoryId,
            categoryName: responseEvents.CategoryName,
            date: responseEvents.Date,
            hasWidget: responseEvents.HasWidget,
            homeGameScore: responseEvents.HomeGameScore,
            id: responseEvents.Id,
            incompatibleEvents: responseEvents.IncompatibleEvents,
            isStreamed: responseEvents.IsStreamed,
            markets: [],
            matchStatus: responseEvents.MatchStatus,
            matchTime: responseEvents.MatchTime,
            name: responseEvents.Name,
            popularity: responseEvents.Popularity,
            providerId: responseEvents.ProviderId,
            providerSourceID: responseEvents.ProviderSourceID,
            score: responseEvents.Score,
            selectionCount: responseEvents.SelectionCount,
            serviceOwner: responseEvents.ServiceOwner,
            setScores: responseEvents.SetScores,
            smartCode: responseEvents.SmartCode,
            sportId: responseSport.Id,
            status: responseEvents.Status,
            teams: [],
            tournamentName: responseTournament.Name,
            tournamentId: responseTournament.Id
          });

          responseEvents.Teams.forEach(responseTeam => {
            const newTeam = new TeamModel({
              id: responseTeam.Id,
              itemOrder: responseTeam.ItemOrder,
              name: responseTeam.Name
            });
            newEvent.teams.push(newTeam);
          });

          responseEvents.Markets.forEach(responseMarkets => {
            const newMarket = new MarketModel({
              combinability: responseMarkets.Combinability,
              compatibleMarkets: responseMarkets.CompatibleMarkets,
              groupNo: responseMarkets.GroupNo,
              id: responseMarkets.Id,
              marketNameRaw: responseMarkets.MarketNameRaw,
              name: responseMarkets.Name,
              scheduleStatus: responseMarkets.ScheduleStatus,
              selections: [],
              specialValue: responseMarkets.SpecialValue,
              status: responseMarkets.Status,
              typeId: responseMarkets.TypeId,
              typeIdGroup: responseMarkets.TypeIdGroup
            });
            responseMarkets.Selections.forEach(responseSelections => {
              if (!responseSelections) {
                return;
              }
              const newSelection = new SelectionModel({
                id: responseSelections.Id,
                name: responseSelections.Name,
                odds: [],
                typeId: responseSelections.TypeId
              });
              responseSelections.Odds.forEach(responseOdds => {
                if (!responseOdds) {
                  return;
                }
                const newOdd = this.createOddModel({
                  odd: responseOdds,
                  selection: responseSelections,
                  event: responseEvents,
                  market: responseMarkets,
                  tournament: responseTournament,
                  sport: responseSport
                });
                newSelection.odds.push(newOdd);
              });
              newMarket.selections.push(newSelection);
            });
            newEvent.markets.push(newMarket);
          });
          newTournament.events.push(newEvent);
          if (!!this.liveQuery.favourites.find(fav => fav.id === newEvent.id)) {
            newFavourites = newFavourites.concat([newEvent]);
          }
        });
        liveFeedData.tournaments.push(newTournament);
      });

      const overI18n = this.transloco.translate('Over');
      const underI18n = this.transloco.translate('Under');

      // liveFeedData.markets.forEach(market => {
      //   if (
      //     market.typeId === 160 ||
      //     market.typeId === 285 ||
      //     market.typeId === 10016 ||
      //     market.typeId === 10553 ||
      //     market.typeId === 10283 ||
      //     market.typeId === 10284
      //   ) {
      //     market.selections = market.selections.map(selection => {
      //       if (selection.name.trim() === '+') {
      //         selection.name = overI18n;
      //       } else if (selection.name.trim() === '-') {
      //         selection.name = underI18n;
      //       }
      //       return selection;
      //     });
      //     market.selections = [market.selections[1], market.selections[0], ...market.selections.slice(2)];
      //   }
      // });
      retVal.push(liveFeedData);
    });

    // Handles setting active match (for desktop view)
    // and handles when a selected event expires to switch to a different match
    if (!this.selectedEventId$.value || !this.liveQuery.selectedLiveEvent) {
      // Select first event for desktop
      if (
        retVal?.length > 0 &&
        retVal[0].tournaments?.length > 0 &&
        retVal[0].tournaments[0].events?.length > 0 &&
        this.applicationService.isDesktop$.value
      ) {
        this.getLiveEventById(`${retVal[0].tournaments[0].events[0].id}`, 0)
          .pipe(first())
          .subscribe(() => {
            if (!this.selectedEventId$.value) {
              this.selectedEventId$.next(`${retVal[0].tournaments[0].events[0].id}`);
            }
          });
      }
    } else {
      // Check does selected event id still exist, if it ended switch to a different match
      const selectedEventId = +this.selectedEventId$.value;
      const allEventIds: number[] = [];
      retVal.forEach(market => {
        market.tournaments.forEach(tournament => {
          tournament.events.forEach(event => {
            allEventIds.push(event.id);
          });
        });
      });
      if (!allEventIds.find(eventId => selectedEventId === eventId)) {
        this.getLiveEventById(`${retVal[0].tournaments[0].events[0].id}`, 0)
          .pipe(first())
          .subscribe(() => {
            this.selectedEventId$.next(`${retVal[0].tournaments[0].events[0].id}`);
          });
      }
    }

    this.liveStore.updateFavourites(newFavourites);
    this.liveStore.updateLiveFeed(retVal);

    if (isItFirstRun) {
      if (retVal[0] && !this.liveQuery.selectedFeed) {
        this.updateSelectedFeed(retVal[0].id);
        this.updateSelectedMarket({ ...retVal[0].markets[0] });
      }
      this.liveStore.updateUI({ liveFeedsLoading: false });
    }
    return retVal;
  }

  updateSelectedFeed(selectedFeedId: number): void {
    if (!selectedFeedId) {
      return;
    }
    this.liveStore.updateSelectedFeed(selectedFeedId);
  }

  updateShowFavourites(showFavourites: boolean): void {
    this.liveStore.updateShowFavourites(showFavourites);
  }

  updateSelectedMarket(market: MarketModel): void {
    this.liveStore.updateSelectedMarket(market);
  }

  updateLiveViewEnabledByUser(liveViewEnabledByUser: boolean): void {
    this.liveStore.updateLiveViewEnabledByUser(liveViewEnabledByUser);
  }

  updateSelectedAreaInMatchView(selectedAreaInMatchView: AreaModel): void {
    this.liveStore.updateSelectedAreaInMatchView(selectedAreaInMatchView);
  }

  clearSelectedAreaInMatchView(): void {
    this.liveStore.clearSelectedAreaInMatchView();
  }

  private createOddModel({
    odd,
    selection,
    event,
    market,
    tournament,
    sport
  }: {
    odd: any;
    selection: any;
    event: any;
    market: any;
    tournament: any;
    sport: any;
  }): OddModel {
    const oddModel: Partial<OddModel> = {
      id: selection.Id,
      value: odd.Value,
      sportId: sport.Id,
      sportName: sport.Name,
      tournamentId: tournament.Id,
      tournamentName: tournament.Name,
      matchId: event.Id,
      matchName: event.Name,
      matchDate: event.Date,
      marketTypeId: market.TypeId,
      marketName: market.Name,
      marketId: market.Id,
      smartCode: event.SmartCode,
      combinability: market.Combinability,
      selectionId: selection.TypeId,
      selectionName: selection.Name,
      selectionTypeId: selection.TypeId,
      categoryId: event.CategoryId,
      categoryName: event.CategoryName,
      enabled: Boolean(odd.Status),
      eventCategory: 'L', // F = prematch, L = live
      spreadValue: market.SpecialValue || 0,
      incompatibleEvents: event.IncompatibleEvents
    };

    return new OddModel(oddModel);
  }
}
