<!-- Cashout requested section -->
<ng-container *ngIf="item$ | async as item">
  <div class="content" [class.border]="border" *ngIf="item.cashoutStatusTab === cashoutStatusTab.Requested">
    <div class="cash-out-value" [class.big-title]="bigTitle">
      <div class="label" transloco="Cash Out:"></div>
      <div class="value" transloco="In evaluation"></div>
    </div>
  </div>
  <!-- Cashout available section -->
  <div class="content cash-out" [class.border]="border" *ngIf="item.cashoutStatusTab === cashoutStatusTab.Available">
    <div class="cashout-refresh-animation" *ngIf="cashoutAnimation$ | async"></div>
    <div class="cash-out-value" [class.big-title]="bigTitle">
      <div class="label full" transloco="Cashout dobitak:"></div>
      <div class="label short" transloco="Cashout:"></div>

      <div class="value" [class.refreshing]="cashoutAnimation$ | async">{{ item.betCashout.valueNet | currencyFormat }}</div>
      <svg
        class="refresh-icon"
        [class.anim]="cashoutAnimation$ | async"
        (click)="onRefreshCashout(item)"
        [class.disabled]="cashoutAnimation$ | async"
        width="18"
        height="18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 4.5v2.25l3-3-3-3V3C5.685 3 3 5.685 3 9c0 1.178.345 2.273.93 3.195L5.025 11.1A4.403 4.403 0 014.5 9c0-2.482 2.018-4.5 4.5-4.5zm5.07 1.305L12.975 6.9c.33.63.525 1.342.525 2.1 0 2.482-2.018 4.5-4.5 4.5v-2.25l-3 3 3 3V15c3.315 0 6-2.685 6-6a5.948 5.948 0 00-.93-3.195z"
          fill="#fff"
          opacity=".5"
        />
      </svg>
    </div>
    <div class="cashout-unavailable-disclaimer" *ngIf="(enableCashout$ | async) === false">
      <!-- Cashout blocked disclaimer section -->
      <span *ngIf="(cashoutWhitelist$ | async) === false" transloco="Account not eligible for cashout"> </span>
      <span
        *ngIf="
          (cashoutWhitelist$ | async) &&
          ((cashoutLoggedIn$ | async) === false || (cashoutSameUser$ | async) === false) &&
          (accountQuery.isShopOwner$ | async) === false
        "
      >
        {{ "Please log in with coupon user's account to cashout" | transloco }}
      </span>
      <span *ngIf="(cashoutWhitelist$ | async) && (accountQuery.isShopOwner$ | async) && (cashoutShopOwnerSubUser$ | async) === false">
        {{ 'Coupon not created by a user of your shop' | transloco }}
      </span>
    </div>
    <div
      *ngIf="enableCashout$ | async"
      class="cash-out-button"
      transloco="Cash Out"
      (click)="onCashout(item)"
      [class.disabled]="item.cashoutInProgress"
    ></div>
  </div>
  <!-- Cashout unavailable section -->
  <div class="content" [class.border]="border" *ngIf="item.cashoutStatusTab === cashoutStatusTab.Unavailable">
    <div class="cash-out-value">
      <div class="label unavailable-cashout">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 20 15" fill="none">
          <path
            d="M8.6508 8.88995C8.65962 9.41813 8.74139 9.94246 8.89373 10.4479H0.1V0.1H15.65V3.73019C15.1388 3.57108 14.6201 3.4801 14.1 3.46972V3.41598H14C13.5625 3.41598 13.1429 3.24141 12.8334 2.93053C12.5239 2.61963 12.35 2.19786 12.35 1.75799V1.65799H12.25H3.5H3.4V1.75799C3.4 2.67888 2.66559 3.41598 1.75 3.41598H1.65V3.51598V7.03196V7.13196H1.75C2.18746 7.13196 2.60709 7.30653 2.91657 7.61742C3.22605 7.92832 3.4 8.35008 3.4 8.78995V8.88995H3.5H8.6508ZM7.87677 2.73697L7.87689 2.73697C9.19313 2.71202 10.243 3.78831 10.3692 4.89183C9.42334 5.77854 9.02582 6.68468 8.78779 7.63482C8.48984 7.74988 8.17888 7.81072 7.87678 7.81096C6.43976 7.75918 5.35 6.70971 5.35 5.27397C5.35 3.83609 6.44191 2.76241 7.87677 2.73697ZM11.9196 10.1932L11.1036 11.0128C9.72233 8.75293 11.3308 5.81347 14 5.81347H14.1V5.71347V4.63718L15.8276 6.37272L14.1 8.10826V7.03196V6.93196H14C12.3299 6.93196 11.2321 8.68302 11.9196 10.1932ZM16.0804 8.26572L16.8964 7.44607C18.2777 9.70598 16.6692 12.6454 14 12.6454H13.9V12.7454V13.8217L12.1723 12.0862L13.9 10.3506V11.4269V11.5269H14C15.6701 11.5269 16.7679 9.77589 16.0804 8.26572Z"
            fill="#fff"
            stroke="#262B2F"
            stroke-width="0.2"
          />
        </svg>
        {{ item.betCashout.message }}
      </div>
    </div>
  </div>
</ng-container>
