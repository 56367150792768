import { Component } from '@angular/core';
import { SportQuery } from 'src/app/core/state/sport/sport.query';

@Component({
  selector: 'app-proceed-bars-container',
  templateUrl: './proceed-bars-container.component.html',
  styleUrls: ['./proceed-bars-container.component.scss']
})
export class ProceedBarsContainerComponent {
  constructor(public sportQuery: SportQuery) {}
}
