import { OddModel } from 'src/app/shared/models/coupon.model';

export class LiveState {
  favourites: EventModel[];
  liveFeeds: LiveFeedModel[];
  selectedFeed: number;
  selectedMarket: MarketModel;
  selectedAreaInMatchView: AreaModel;
  selectedLiveEvent: LiveEventByArea;
  liveViewEnabledByUser: boolean;
  showFavourites: boolean;
  ui: LiveUIState;
}

export class LiveUIState {
  noLiveFeedData: boolean;
  notValidEventId: boolean;
  liveFeedsLoading: boolean;
  selectedLiveEventLoading: boolean;

  constructor(init: Partial<LiveUIState>) {
    Object.assign(this, init);
  }
}

export class SelectionModel {
  id: number;
  name: string;
  typeId: number;
  odds: OddModel[];

  constructor(init: Partial<SelectionModel>) {
    Object.assign(this, init);
  }
}

export class SpreadSelectionGroup {
  specifier: number;
  selections: SelectionModel[];

  constructor(init: Partial<SpreadSelectionGroup>) {
    Object.assign(this, init);
  }
}

export class MarketModel {
  id: number;
  name: string;
  marketNameRaw?: any;
  typeId: number;
  typeIdGroup?: any;
  status: number;
  selections: SelectionModel[];
  spreadSelectionGroups: SpreadSelectionGroup[];
  specialValue: string;
  specifier: number;
  scheduleStatus: number;
  combinability: number;
  groupNo: number;
  compatibleMarkets?: any;

  constructor(init: Partial<MarketModel>) {
    Object.assign(this, init);
  }
}

export class TeamModel {
  id: number;
  name: string;
  itemOrder: number;

  constructor(init: Partial<TeamModel>) {
    Object.assign(this, init);
  }
}

export class EventModel {
  id: number;
  providerId: number;
  name: string;
  status: number;
  score: string;
  homeGameScore: number;
  awayGameScore: number;
  markets: MarketModel[];
  date: string;
  teams: TeamModel[];
  smartCode: number;
  serviceOwner: number;
  setScores?: any;
  matchTime: number;
  matchStatus: number;
  popularity: number;
  sportId: number;
  isStreamed: boolean;
  aamsMatchID?: any;
  aamsTemplateID?: any;
  selectionCount: number;
  tournamentName: any;
  tournamentId: number;
  providerSourceID: number;
  hasWidget: boolean;
  categoryId: number;
  categoryName: any;
  incompatibleEvents?: any;

  constructor(init: Partial<EventModel>) {
    Object.assign(this, init);
  }
}

export class TournamentModel {
  id: number;
  name: string;
  events: EventModel[];
  noOfOdds: number;

  constructor(init: Partial<TournamentModel>) {
    Object.assign(this, init);
  }
}

export class LiveFeedModel {
  id: number;
  name: string;
  eventCount: number;
  tournaments: TournamentModel[];
  currentArea?: any;
  areas?: any;
  noOfOdds: number;
  markets?: MarketModel[];

  constructor(init: Partial<LiveFeedModel>) {
    Object.assign(this, init);
  }
}

export class LiveEventByArea {
  areas: AreaModel[];
  currentArea: AreaModel;
  eventCount: number;
  id: number;
  name: string;
  noOfOdds: number;
  tournaments: TournamentModel[];

  constructor(init: Partial<LiveEventByArea>) {
    Object.assign(this, init);
  }
}
export class AreaModel {
  id: number;
  name: string;
  order: number;

  constructor(init: Partial<AreaModel>) {
    Object.assign(this, init);
  }
}
