<div class="choose-promo-wrapper">
  <h2 class="choose-promo-title">Izaberi svoju promociju - poklon tiket ili free spinovi!</h2>
  <img src="https://res.cloudinary.com/dhzivl2ju/image/upload/v1694003817/bsp_long_16de31e98f.jpg"
    alt="Choose your promo" />
  <p class="intro-text">VICTORY POKLANJA - Izaberi svoju promociju, bez uslova!</p>

  <div class="buttons">
    <div class="full-info" *ngIf="seeDetails$ | async">
      <p>
        Svi novoregistrovani korisnici prilikom registracije mogu da izaberu POKLON TIKET u iznosu od 100 RSD, bez
        uslova ili 15 BESPLATNIH
        SPINOVA u okviru sekcije ,,Dobro došli u Vegas".
      </p>
      <p>
        Poklon tiket će odmah biti dostupan na tvom korisničkom nalogu, a spinove možeš da iskoristiš na prvoj igri u
        koju uđeš u okviru
        sekcije ,,Dobro došli u Vegas" -  u zavisnosti od promocije za koju se odlučiš.
      </p>
      <h6 class="secondary-text">Uživaj u nikad boljoj igri!</h6>
    </div>

    <div class="logged-in-buttons" *ngIf="accountQuery.accessToken$ | async">
      <app-button (btnClick)="onChooseYourPromoActivateButtonClick()" [allCaps]="false"
        [category]="'Menu - Promos - Choose Your Promo'" [click]="true" [label]="'Activate'"
        [text]="'Activate' | transloco" analytics></app-button>

      <app-button (btnClick)="handleReadMoreClick()" [allCaps]="false" [category]="'Menu - Promos - Choose Your Promo'"
        [click]="true" [label]="'More/Less Info'"
        [text]="((seeDetails$ | async) ? 'Less Info' : 'More Info') | transloco" analytics></app-button>
    </div>

    <div class="not-logged-in-cta" *ngIf="!(accountQuery.accessToken$ | async)">
      <p class="login-to-activate">Prijavi se da aktiviraš</p>
      <app-button (btnClick)="handleReadMoreClick()" [allCaps]="false" [category]="'Menu - Promos - Choose Your Promo'"
        [click]="true" [label]="'More/Less Info'"
        [text]="((seeDetails$ | async) ? 'Less Info' : 'More Info') | transloco" analytics></app-button>
    </div>
  </div>
</div>