import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { expandCollapseSimpler } from 'src/app/shared/animations';
import { AccountService } from './../../../../core/services/account/account.service';
import { NotificationService } from './../../../../core/services/notification.service';
import { AccountQuery } from './../../../../core/state/account/account.query';
import { TransferCandidateModel } from './../../../../shared/models/account.model';

@Component({
  selector: 'account-fund-transfer',
  templateUrl: './fund-transfer.component.html',
  styleUrls: ['./fund-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapseSimpler()]
})
export class FundTransferComponent implements OnInit, OnDestroy {
  transferForm: FormGroup;
  currentUserBalance: number = 0;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly notificationService: NotificationService,
    
  ) { }

  ngOnInit(): void {
    this.transferForm = this.initialiseForm();
    this.initialiseFormChangesListeners();
    this.accountService.getTransferCandidates(this.transferForm.controls.status.value).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  toggleRadioButton(event: any): void {
    const radio = event.target.parentElement.querySelector('input[type="radio"]');
    radio.checked = radio.checked ? false : true;
    this.transferForm.controls[radio.getAttribute('formcontrolname')].setValue(radio.value);
  }

  trackByTransferCandidates(index: number, item: TransferCandidateModel): string {
    return item.userId;
  }

  transferFunds(): void {
    this.accountService.transferFunds(
      this.transferForm.controls.type.value,
      this.transferForm.controls.user.value,
      this.transferForm.controls.amount.value
    ).subscribe(s => {
      if (s) {
        this.accountService.getTransferCandidates(this.transferForm.controls.status.value)
          .subscribe(
            () => {
              this.updateCurrentUserBalance(this.transferForm.controls.user.value);
              this.accountService.updateBalance();
              this.transferForm.controls.amount.reset();
              this.notificationService.showSuccessMessage(translate('Funds have been transferred successfully'));
            }
          )
      } else {
        this.notificationService.showErrorMessage(translate('Could not transfer funds'));
      }
    });
  }

  private initialiseFormChangesListeners(): void {
    this.transferForm.controls.status.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((status: string) => {
        this.accountService.getTransferCandidates(status);
        this.transferForm.controls.user.setValue('');
      });

    this.transferForm.controls.user.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val: string) => {
        this.updateCurrentUserBalance(val);
        if (this.transferForm.controls.type.value === 'W') {
          this.setAmountValidators(this.transferForm.controls.type.value);
        }
      });

    this.transferForm.controls.type.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val: string) => {
        this.setAmountValidators(val);
      });

    this.transferForm.controls.status.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val: string) => {
        this.accountService.getTransferCandidates(val).subscribe();
      });
  }

  private setAmountValidators(type: string): void {
    this.transferForm.controls.amount.reset();
    this.transferForm.controls.amount.clearValidators();
    this.transferForm.controls.amount.setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(type === 'D' ? this.accountQuery.userData.wallets[0].balance : this.currentUserBalance)
    ]);
  }

  private updateCurrentUserBalance(user: string): void {
    this.currentUserBalance = user !== ''
      ? this.accountQuery.transferCandidateBalance(user)
      : 0
  }

  private initialiseForm(): FormGroup {
    return new FormGroup(
      {
        status: new FormControl('ACT|PCHANG'),
        user: new FormControl('', [Validators.required]),
        type: new FormControl('D'),
        amount: new FormControl('', [Validators.required, Validators.min(1), Validators.max(this.accountQuery.userData.wallets[0].balance)])
      }
    );
  }
}
