import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { fadeIn } from 'src/app/shared/animations';
import { BankDetailsModel, BanksModel, ChannelsModel, PaymentDisplayState, PaymentListModel } from 'src/app/shared/models/account.model';
import { smoothScrollTo } from 'src/app/shared/utils/smooth-scroll-to';
import { brandInfo } from 'src/brand-info';

import { NotificationSettings } from '../../../../../../shared/models/notification.model';

@Component({
  selector: 'app-bank-transfer',
  templateUrl: './bank-transfer.component.html',
  styleUrls: ['./bank-transfer.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankTransferComponent implements OnInit, OnDestroy {
  paymentForm: FormGroup;

  brandInfo = brandInfo;

  externalErrorCodes: any = {
    'integration_key|errors.configuration': translate('Invalid Integration Key'),
    'integration_key|errors.system': translate('System Error'),
    'number|errors.blank': translate('Card number empty'),
    'number|errors.invalid': translate('Invalid card number'),
    'cvv|errors.blank': translate('CVV/CVC Code empty'),
    'cvv|errors.invalid': translate('Invalid CVV/CVC'),
    'month|errors.blank': translate('Expiration month empty'),
    'month|errors.invalid': translate('Expiration month invalid'),
    'year|errors.blank': translate('Expiration year empty'),
    'year|errors.invalid': translate('Expiration year invalid'),
    'year|errors.expired': translate('Card expired'),
    'card_holder|errors.blank': translate('Card holder empty'),
    'first_name|errors.blank': translate('First name empty'),
    'last_name|errors.blank': translate('Last name empty')
  };

  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  countryCode: string;
  countryName: string;

  amount: number;
  fees: string;
  minAmount: number = 50;
  maxAmount: number;
  paymentId: string = '';
  delimiters: any = [];
  invalidateProceed: boolean = true;
  showAmountInputs: boolean = false;
  showBankDetails: boolean = false;
  showEmailCurrency: boolean = true;
  showDepositChannels: boolean = false;
  showFeeCalculator: boolean = false;
  showProceedBtn: boolean = false;
  proceedBtn: string = translate('Accept');
  loading: boolean = false;
  proceedBtn$ = new BehaviorSubject<string>(this.proceedBtn);
  loading$ = new BehaviorSubject<boolean>(false);
  withIFrameDeposit: boolean = true;
  currentFee$ = new BehaviorSubject<number>(0);

  contentPopup: string;

  bankAccountDetails$: BehaviorSubject<BankDetailsModel> = new BehaviorSubject<BankDetailsModel>(undefined);
  channelDetails$: BehaviorSubject<BankDetailsModel> = new BehaviorSubject<BankDetailsModel>(undefined);
  destroy$: Subject<boolean> = new Subject<boolean>();

  iFrameSource$ = new BehaviorSubject<string>(undefined);

  redirectUrl$ = new BehaviorSubject<SafeResourceUrl>(undefined);
  showingIframe$ = new BehaviorSubject<boolean>(false);

  returnParam1 = undefined;
  returnParam2 = undefined;
  returnParam3 = undefined;

  depositApiPayload$ = new BehaviorSubject(undefined);
  clientSideInteg: boolean;

  paymentJs: any;

  constructor(
    readonly accountPaymentQuery: AccountPaymentQuery,
    readonly accountQuery: AccountQuery,
    readonly accountService: AccountService,
    private readonly accountPaymentService: AccountPaymentService,
    private readonly registrationService: RegistrationService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly appConfigService: AppConfigService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userId = this.accountQuery.userData.id;
    this.email = this.accountQuery.userData.email;
    this.firstName = this.accountQuery.userData.name ? this.accountQuery.userData.name : undefined;
    this.lastName = this.accountQuery.userData.surname ? this.accountQuery.userData.surname : undefined;
    this.address = this.accountQuery.userData.address.addressLine ? this.accountQuery.userData.address.addressLine : undefined;
    this.city = this.accountQuery.userData.address.cityCode ? this.accountQuery.userData.address.cityCode : undefined;
    this.countryCode = this.accountQuery.userData.address.countryCode ? this.accountQuery.userData.address.countryCode : undefined;

    if (this.countryCode) {
      this.registrationService.getCountries();

      this.registrationQuery.countries$.pipe(takeUntil(this.destroy$)).subscribe(countries => {
        if (countries) {
          const foundCountry = countries.filter(val => val.threeLetterCode === this.countryCode)[0];
          this.countryName = foundCountry.name;
        }
      });
    }

    this.paymentId = this.route.snapshot.paramMap.get('paymentCodeId');
    this.clientSideInteg = this.paymentId === 'AllSecureDeposit' ? true : false;
    this.getPaymentLimits(this.paymentId);
    this.delimiters = this.appConfigService.get('account').depositDelimiters;
    // required default values on accNo, bank and channel
    this.setValues(this.minAmount, 'amount');

    this.getPaymentDetails();
  }

  getPaymentLimits(paymentId: string): void {
    this.accountPaymentQuery.paymentsListDeposit$.pipe(takeUntil(this.destroy$)).subscribe(payments => {
      if (payments) {
        const returningData: PaymentListModel = payments.find(list => list.identifier === paymentId);
        this.minAmount = returningData.minimumLimit;
        this.maxAmount = returningData.maximumLimit;
        this.fees = returningData.fees;
      }
      this.paymentForm = this.initialiseForm(this.minAmount, this.maxAmount);
    });
  }
  setValues(value: number, param: string): void {
    this.paymentForm.controls[param].setValue(value);
  }

  onCancel(): void {
    this.router.navigate(['/account/deposit']);
  }
  getBankDetails(): void {
    this.accountPaymentService
      .getPaymentBanks()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.setValues(this.accountPaymentQuery.lastAccNumber, 'accountNumber');
        this.setValues(this.accountPaymentQuery.lastBankId, 'bank');
      });
  }

  setUIDetails(): void {
    this.accountPaymentQuery.paymentDetails$.subscribe(details => {
      // NOTE: client side payment integration is apart from this UI setup
      if (details) {
        this.contentPopup = details.contentPopup;

        this.showProceedBtn = [PaymentDisplayState.InfoOnly, PaymentDisplayState.InfoFeeCalculator].includes(details.displayOptions)
          ? false
          : true;

        if (details.displayOptions !== PaymentDisplayState.InfoOnly) {
          this.showAmountInputs = true;

          if (
            details.displayOptions === PaymentDisplayState.AmountAndBanks ||
            details.displayOptions === PaymentDisplayState.AmountBankChannels
          ) {
            this.showBankDetails = true;

            this.getBankDetails();
            if (details.displayOptions === PaymentDisplayState.AmountBankChannels) {
              this.setValues(this.accountPaymentQuery.channelsList$[0], 'channel');
              this.showDepositChannels = true;
            }
          } else if (details.displayOptions === PaymentDisplayState.AmountChannels) {
            this.setValues(this.accountPaymentQuery.channelsList$[0], 'channel');
            this.getBankDetails();
            this.showDepositChannels = true;
          }

          if (
            details.displayOptions === PaymentDisplayState.InfoFeeCalculator ||
            details.displayOptions === PaymentDisplayState.AmountFeeCalculator
          ) {
            this.withIFrameDeposit = false;
            this.showFeeCalculator = true;
            this.paymentForm.controls.amount.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(s => {
              if (!this.paymentForm.controls.amount.invalid) {
              }
            });
            this.paymentForm.controls.amount.valueChanges.pipe(debounceTime(250), takeUntil(this.destroy$)).subscribe(s => {
              if (!this.paymentForm.controls.amount.invalid) {
              }
            });
          }
        }

        if (details.status !== 1) {
          this.onCancel();
        }
      } else {
        this.getPaymentDetails();
      }
    });
  }

  getPaymentDetails(): void {
    this.accountPaymentService
      .getPaymentDetails(this.paymentId, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.setUIDetails();
        },
        error => {
          this.onCancel();
          this.notificationService.showErrorNotification(translate('An error has occurred. Please try again.'));
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initialiseForm(min: number, max: number): FormGroup {
    if (this.clientSideInteg) {
      return new FormGroup({
        amount: new FormControl(min, [Validators.required, Validators.min(min), Validators.max(max)]),
        firstName: new FormControl({ value: this.firstName, disabled: this.firstName !== undefined }, [
          Validators.required,
          Validators.pattern(new RegExp(/^\D*$/))
        ]),
        lastName: new FormControl({ value: this.lastName, disabled: this.lastName !== undefined }, [
          Validators.required,
          Validators.pattern(new RegExp(/^\D*$/))
        ]),
        address: new FormControl({ value: this.address, disabled: this.address !== undefined }, Validators.required),
        city: new FormControl({ value: this.city, disabled: this.city !== undefined }, Validators.required),
        year: new FormControl('', [Validators.required, Validators.pattern(new RegExp(/^\d*$/))]),
        month: new FormControl('', [Validators.required, Validators.pattern(new RegExp(/^\d*$/))]),
        acceptance: new FormControl(false, Validators.requiredTrue),
        save: new FormControl(false)
      });
    } else {
      return new FormGroup({
        amount: new FormControl(min, [Validators.required, Validators.min(min), Validators.max(max)]),
        accountNumber: new FormControl(9999999999, this.showBankDetails ? Validators.required : undefined),
        bank: new FormControl('000', this.showBankDetails ? Validators.required : undefined),
        channel: new FormControl('bank', Validators.required)
      });
    }
  }
}
