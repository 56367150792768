import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccountStore } from 'src/app/core/state/account/account.store';
import { APIType } from 'src/app/shared/models/api.model';
import { BetSearchModel, BetSearchParams, BetSearchRequestModel } from 'src/app/shared/models/bet-search.model';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BetSearchService {
  constructor(
    private readonly apiService: APIService,
    private readonly accountStore: AccountStore,
    private readonly appConfig: AppConfigService,
    private readonly accountQuery: AccountQuery,
    private readonly languageService: LanguageService
  ) {}

  searchBets(params: BetSearchParams, isVirtuals: boolean = false): Observable<any> {
    const lastSearchParams = this.accountQuery.betSearch.queryParams;

    // If the request is the same as the previous one, do nothing
    if (isEqual(params, lastSearchParams)) {
      return of(false);
    }

    const isItRequestForAll = params.couponStatus === 0;
    const payload = this.generateSearchBetRequest(params);

    this.accountStore.updateBetSearch({ queryParams: params, isLoading: true });
    const url = 'api/couponsListByPost';
    return this.apiService.post<any>(APIType.Sportsbook, url, payload).pipe(
      tap(data => {
        const betSearchResult = this.parseSearchBetResponse(data, isItRequestForAll);
        this.accountStore.updateBetSearch({ betSearchResult });
      }),
      finalize(() => {
        this.accountStore.updateBetSearch({ isLoading: false });
      })
    );
  }

  resetState(): void {
    this.accountStore.updateBetSearch({ queryParams: undefined });
  }

  private generateSearchBetRequest(params: BetSearchParams): BetSearchRequestModel {
    const betSearchPayload = new BetSearchRequestModel({
      // tslint:disable-next-line: no-null-keyword
      couponStatus: params.couponStatus === 0 ? null : params.couponStatus,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      pageSize: 99999,
      requestedPage: 1,
      includUsers: true,
      lang: this.languageService.selectedLanguage.language,
      couponCode: params.couponCode ? params.couponCode : '',
      settlementDate: false
    });

    return betSearchPayload;
  }

  private parseSearchBetResponse(response: any, isItRequestForAll: boolean = false): BetSearchModel[] {
    const retVal = [];
    response.Coupons.forEach(data => {
      if (isItRequestForAll || (!isItRequestForAll && data.BetFinalState !== BetFinalState.InEvaluation)) {
        retVal.push(
          new BetSearchModel({
            couponCode: data.CouponCode,
            couponDate: data.CouponDate,
            couponStatusId: data.CouponStatusId,
            stake: data.Stake,
            stakeGross: data.StakeGross,
            settlementDate: data.SettlementSentDate,
            won: data.Won,
            currencyCode: data.Currency.CurrencyCode,
            currencySymbol: data.Currency.CurrencySymbol,
            betFinalState: data.BetFinalState,
            channelId: data.ChannelId,
            isEvaluation: data.EvalCouponStatusId !== 0,
            isAgentSettled: data.IsAgentSettled,
            isLive: data.IsLive,
            externalUserId: data.UserId,
            userName: data.UserName
          })
        );
      }
    });
    return retVal;
  }
}
