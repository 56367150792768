import { Injectable, OnDestroy } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { CouponDetailsQuery } from 'src/app/core/state/coupon-details/coupon-details.query';
import { CouponDetailsStore } from 'src/app/core/state/coupon-details/coupon-details.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BetCashoutModel, CashoutModel, CashoutSource } from 'src/app/shared/models/cashout.model';
import {
  BetFinalState,
  CouponDetailsGroupModel,
  CouponDetailsModel,
  CouponDetailsOddModel,
  CouponDetailsUIState,
  CouponStatus,
  CouponType,
  OddResultsModel
} from 'src/app/shared/models/coupon-details.model';
import { RecentBetModel } from 'src/app/shared/models/my-bets.model';
import { APIService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CouponDetailsService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly couponDetailsStore: CouponDetailsStore,
    private readonly couponDetailsQuery: CouponDetailsQuery,
    private readonly cashoutStore: CashoutStore,
    private readonly cashoutService: CashoutService
  ) {}

  updateCouponDetailsUI(ui: CouponDetailsUIState): void {
    this.couponDetailsStore.updateUI(ui);
  }

  getCouponDetails(couponCode: string, language: string = 'en', noCheck: boolean = false): Observable<any> {
    this.couponDetailsStore.updateCouponDetailsData(undefined);
    if (!couponCode) {
      return undefined;
    }

    const couponCodeArr = couponCode.split(',');
    const code = couponCodeArr[0];
    let inBehalfOf: string;
    if (couponCodeArr.length > 1) {
      inBehalfOf = couponCodeArr[1];
    }

    const apiSettings = new APISettings();
    const uiUpdate = new CouponDetailsUIState({ isLoading: true });

    this.updateCouponDetailsUI(uiUpdate);

    let couponCheckByCodeApi: string;
    if (noCheck) {
      couponCheckByCodeApi = `api/coupons/noCheck/allByCode/${code}/language/${language}`;
      apiSettings.noAuthToken = true;
    } else {
      couponCheckByCodeApi = `api/coupons/allByCodeWithSettlementDate/${code}/language/${language}`;

      if (inBehalfOf) {
        apiSettings.inBehalfOf = inBehalfOf;
      }
    }

    return this.apiService.get<any>(APIType.Sportsbook, couponCheckByCodeApi, apiSettings).pipe(
      mergeMap(betDetailsData => {
        if (!betDetailsData) {
          return new Observable<any>();
        }

        if (!betDetailsData.CouponCode) {
          const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: true, isLoading: false });
          this.updateCouponDetailsUI(uiUpdate2);
          return new Observable<any>();
        } else if (betDetailsData.CouponCode) {
          const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: false, isLoading: false });
          this.updateCouponDetailsUI(uiUpdate2);
        }

        let couponStatus: CouponStatus;
        switch (betDetailsData.CouponStatusId) {
          case 1:
            couponStatus = CouponStatus.Running;
            break;
          case 2:
            couponStatus = CouponStatus.Lost;
            break;
          case 3:
            couponStatus = CouponStatus.Won;
            break;
          case 4:
            couponStatus = CouponStatus.Cancelled;
            break;
          case 5:
            couponStatus = CouponStatus.SystemEvaluation;
            break;
          default:
            couponStatus = CouponStatus.Unknown;
            break;
        }

        let couponType: string;
        switch (betDetailsData.CouponTypeId) {
          case CouponType.Single:
            couponType = translate('Single');
            break;
          case CouponType.Multiple:
            couponType = translate('Multiple');
            break;
          case CouponType.System:
            couponType = translate('System');
            break;
          default:
            couponType = translate('Unknown');
            break;
        }

        let rebetEnabled = false;

        for (const odd of betDetailsData.Odds) {
          if (odd.Result === 'Unset') {
            rebetEnabled = true;
            break;
          }
        }

        const couponDetails = new CouponDetailsModel({
          betFinalState: betDetailsData.BetFinalState,
          couponCode: betDetailsData.CouponCode,
          couponDate: betDetailsData.CouponDate,
          couponStatus: couponStatus,
          couponType: couponType,
          couponTypeId: betDetailsData.CouponTypeId,
          currencySymbol: betDetailsData.Currency.CurrencySymbol,
          groups: [],
          maxBonus: betDetailsData.MaxBonus,
          maxOdd: betDetailsData.MaxOdd,
          maxPotWin: parseFloat(betDetailsData.NetStakeMaxWin) + parseFloat(betDetailsData.MaxBonus),
          maxPotWinNet: betDetailsData.MaxWinNet,
          maxPotWinTax: betDetailsData.MaxWithholdingTax,
          maxWin: betDetailsData.MaxWin,
          minBonus: betDetailsData.MinBonus,
          minOdd: betDetailsData.MinOdd,
          minWin: betDetailsData.MinWin,
          minWinNet: betDetailsData.MinWinNet,
          minWinTax: betDetailsData.MinWithholdingTax,
          netStakeMaxWin: betDetailsData.NetStakeMaxWin,
          netStakeMinWin: betDetailsData.netStakeMinWin,
          odds: [],
          paymentDate: betDetailsData.PaymentDate,
          rePrint: betDetailsData.IsPrinted,
          rebetEnabled,
          stake: betDetailsData.StakeGross,
          stakeNet: betDetailsData.Stake,
          stakeTax: betDetailsData.TurnoverTax,
          totalCombinations: betDetailsData.TotalCombinations,
          totalOdds: betDetailsData.TotalOdds,
          userId: betDetailsData.UserId,
          userName: betDetailsData.UserName,
          won: betDetailsData.Won,
          wonTax: betDetailsData.TotalTaxed
        });

        betDetailsData.Odds.forEach(odd => {
          const halftimeScore = odd.Results.filter(oddResults => oddResults.Family === 'HT');
          const fulltimeScore = odd.Results.filter(oddResults => oddResults.Family === 'FT');
          let results = {};
          let batch = [];
          for (const result of odd.Results) {
            batch.push(
              new OddResultsModel({
                family: result.Family,
                symbol: result.Symbol,
                value: result.Value
              })
            );
            if (batch.length === 2) {
              results[batch[0].family.toLowerCase()] = batch;
              batch = [];
            }
          }

          if (!Object.keys(results).length) {
            results = undefined;
          }
          couponDetails.odds.push(
            new CouponDetailsOddModel({
              eventId: odd.IDEvent,
              orderId: odd.IDOrder,
              sportName: odd.SportName,
              sportId: odd.IDSport,
              categoryName: odd.CategoryName,
              tournamentName: odd.Championship,
              roundNumber: odd.RoundNumber,
              eventCategory: odd.EventCategory,
              eventDate: odd.EventDate,
              eventName: odd.EventName,
              isBanker: odd.FixedOdd,
              marketId: odd.IDMarketType,
              marketName: odd.MarketName,
              selectionName: odd?.SelectionName,
              oddValue: odd.OddValue,
              leagueNo: odd.LeagueNo,
              spreadValue: odd.SpecialValue,
              marketTypeId: odd.IDMarketType,
              results,
              resultStatus: odd.Result,
              resultStatusId: odd.Win,
              resultHTScore:
                halftimeScore.length !== 0
                  ? {
                      teamOne: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT1').Value,
                      teamTwo: halftimeScore.find(halfTimeScore => halfTimeScore.Symbol === 'HT2').Value
                    }
                  : undefined,
              resultFTScore:
                fulltimeScore.length !== 0
                  ? {
                      teamOne: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT1').Value,
                      teamTwo: fulltimeScore.find(fullTimeScore => fullTimeScore.Symbol === 'FT2').Value
                    }
                  : undefined
            })
          );
        });

        betDetailsData.Groupings.forEach(group => {
          couponDetails.groups.push(
            new CouponDetailsGroupModel({
              combinations: group.Combinations,
              grouping: group.Grouping,
              maxBonus: group.MaxBonus,
              maxWin: group.MaxWin,
              minBonus: group.MinBonus,
              minWin: group.MinWin,
              netStakeMaxWin: group.netStakeMaxWin,
              netStakeMinWin: group.NetStakeMinWin,
              stake: group.Stake,
              stakeNet: group.NetStake,
              stakeTax: group.TurnoverTax
            })
          );
        });
        this.couponDetailsStore.updateCouponDetailsData(couponDetails);

        const uiUpdate2 = new CouponDetailsUIState({ isLoading: false });
        this.updateCouponDetailsUI(uiUpdate2);

        // Cashout is not implemented for virtuals yet
        if (couponDetails.betFinalState === BetFinalState.Placed) {
          return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/cashoutvalue_new/${betDetailsData.CouponCode}`).pipe(
            map(cashoutData => {
              const betCashout: BetCashoutModel = this.cashoutService.parseBetCashoutResponse(cashoutData);

              const cashout: CashoutModel = new CashoutModel({
                betCashout,
                betFinalState: betDetailsData.BetFinalState,
                cashoutSource: CashoutSource.couponDetails,
                couponCode: betDetailsData.CouponCode,
                userId: betDetailsData.UserId
              });
              this.cashoutStore.addCashoutData(cashout);
            })
          );
        } else {
          return new Observable<any>();
        }
      })
    );
  }

  clearCashouts(): void {
    this.cashoutStore.remove(entity => entity.cashoutSource === CashoutSource.couponDetails);
  }

  getCoupon(recentBet: RecentBetModel): CouponDetailsModel {
    return new CouponDetailsModel({
      betFinalState: recentBet.betFinalState
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
