<div class="payment-completed-wrapper">
  <div class="payment success" *ngIf="completeType === 'success'">
    <div class="payment-icon">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </div>
    <div class="payment-title" transloco="Successful Transaction!"></div>
    <div class="payment-details">
      <div class="details-title" transloco="Payment Details - Deposit"></div>
      <div class="details-info">
        <div class="info-label" transloco="Payment order reference:"></div>
        <div class="info-value">{{ orderReference$ | async }}</div>
      </div>
      <div class="details-info status">
        <div class="info-label" transloco="Status:"></div>
        <div class="info-value">{{ status$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Name:"></div>
        <div class="info-value">{{ cardHolder$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Address:"></div>
        <div class="info-value">{{ address$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Amount and currency charged:"></div>
        <div class="info-value">{{ (amountCharged$ | async) + ' ' + (currency$ | async) }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Payment Method:"></div>
        <div class="info-value">{{ paymentMethod$ | async }}</div>
      </div>
      <div class="details-info" *ngIf="brandInfo.companyName !== ''">
        <div class="info-label" transloco="Company Name:"></div>
        <div class="info-value">{{ brandInfo.companyName }}</div>
      </div>

      <div class="details-info">
        <div class="info-label" transloco="Date & Time:"></div>
        <div class="info-value">{{ date$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Bank Authorization Code:"></div>
        <div class="info-value">{{ (bankCode$ | async) || '' }}</div>
      </div>
    </div>
    <div class="button-wrapper">
      <button class="continue-btn" (click)="continuePlaying()">
        {{ !isParentTopWindow ? ('Continue Game' | transloco) : ('Go to offer' | transloco) }}
      </button>
    </div>
  </div>

  <div class="payment cancel" *ngIf="completeType === 'cancel'">
    <div class="payment-icon">
      <i class="fa fa-times-circle" aria-hidden="true"></i>
    </div>
    <div class="payment-title" transloco="Cancelled Transaction!"></div>
    <div class="error-message generic" transloco="An error has occurred, please try again"></div>
    <!-- <div class="error-message" *ngIf="errorMessage$ | async">{{errorMessage$ | async}}</div> -->

    <div class="payment-details">
      <div class="details-title" transloco="Payment Details - Deposit"></div>
      <div class="details-info">
        <div class="info-label" transloco="Payment order reference:"></div>
        <div class="info-value">{{ orderReference$ | async }}</div>
      </div>
      <div class="details-info status">
        <div class="info-label" transloco="Status:"></div>
        <div class="info-value">{{ status$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Name:"></div>
        <div class="info-value">{{ cardHolder$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Address:"></div>
        <div class="info-value">{{ address$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Amount and currency charged:"></div>
        <div class="info-value">{{ amountCharged$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Payment Method:"></div>
        <div class="info-value">{{ paymentMethod$ | async }}</div>
      </div>
      <div class="details-info" *ngIf="brandInfo.companyName !== ''">
        <div class="info-label" transloco="Company Name:"></div>
        <div class="info-value">{{ brandInfo.companyName }}</div>
      </div>

      <div class="details-info">
        <div class="info-label" transloco="Date & Time:"></div>
        <div class="info-value">{{ date$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Bank Authorization Code:"></div>
        <div class="info-value">{{ bankCode$ | async }}</div>
      </div>
    </div>
  </div>

  <div class="payment error" *ngIf="completeType === 'error'">
    <div class="payment-icon">
      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    </div>
    <div class="payment-title" transloco="Decline!"></div>
    <div class="error-message generic" transloco="An error has occurred, please try again"></div>
    <!-- <div class="error-message" *ngIf="errorMessage$ | async">{{errorMessage$ | async}}</div> -->

    <div class="payment-details">
      <div class="details-title" transloco="Payment Details - Deposit"></div>
      <div class="details-info">
        <div class="info-label" transloco="Payment order reference:"></div>
        <div class="info-value">{{ orderReference$ | async }}</div>
      </div>
      <div class="details-info status">
        <div class="info-label" transloco="Status:"></div>
        <div class="info-value">{{ status$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Name:"></div>
        <div class="info-value">{{ cardHolder$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Address:"></div>
        <div class="info-value">{{ address$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Amount and currency charged:"></div>
        <div class="info-value">{{ currency$ | async }} {{ amountCharged$ | async | number : '1.2-2' }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Payment Method:"></div>
        <div class="info-value">{{ paymentMethod$ | async }}</div>
      </div>
      <div class="details-info" *ngIf="brandInfo.companyName !== ''">
        <div class="info-label" transloco="Company Name:"></div>
        <div class="info-value">{{ brandInfo.companyName }}</div>
      </div>

      <div class="details-info">
        <div class="info-label" transloco="Date & Time:"></div>
        <div class="info-value">{{ date$ | async }}</div>
      </div>
      <div class="details-info">
        <div class="info-label" transloco="Bank Authorization Code:"></div>
        <div class="info-value">{{ bankCode$ | async }}</div>
      </div>
    </div>
    <div class="button-wrapper">
      <button class="continue-btn" (click)="tryDepositAgain()">
        {{ 'Try again' | transloco }}
      </button>
    </div>
  </div>
</div>
