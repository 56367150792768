<div class="login-container">
  <app-card>
    <div class="content" *ngIf="(applicationQuery.loginState$ | async) === 'Login'" body>
      <div class="header" *ngIf="loginSectionOnly === false">
        <div appDynamicSvgBackground="images/logo/brand-logo" class="brand-logo"></div>
      </div>
      <div class="forms">
        <div class="form" [formGroup]="loginForm" [@fadeIn] (keydown.enter)="login()">
          <span class="title" transloco="Already have an account?"></span>
          <div class="username-with-prefix" *ngIf="loginPrefix">
            <input [value]="loginPrefix" class="prefix" readonly />
            <input (blur)="loginMobileNumberCleanup()" [focus]="true" analytics
              [category]="'Input - ' + (quickRegistration ? 'Phone Number' : 'Username')"
              [placeholder]="(quickRegistration ? 'Phone Number' : 'Username') | transloco"
              class="login-element username" formControlName="userName" />
          </div>
          <input *ngIf="!loginPrefix" [category]="'Input - ' + (quickRegistration ? 'Phone Number' : 'Username')"
            [focus]="true" [placeholder]="(quickRegistration ? 'Phone Number' : 'Username') | transloco" analytics
            class="login-element" formControlName="userName" />
          <div class="login-element password">
            <input [category]="'Input - Password'" [focus]="true" [placeholder]="'Password' | transloco"
              [type]="(showPassword$ | async) ? 'text' : 'password'" analytics class="login-element"
              formControlName="password" />
            <span (click)="togglePasswordVisibility()" [category]="'Toggle Password Visibility'"
              [class.fa-eye-slash]="showPassword$ | async" [class.fa-eye]="(showPassword$ | async) === false"
              [click]="true" analytics class="fa toggle-password"></span>
          </div>

          <app-form-message *ngIf="(loginError$ | async) && errorMessage$.value" [category]="'Login'" [emitError]="true"
            [label]="errorMessage$.value" [text]="errorMessage$.value" analytics type="danger"></app-form-message>
          <div (click)="login()" [class.disabled]="loginForm.invalid" class="secondary-button large">
            {{ 'login' | transloco }}
          </div>
          <div class="forgot-password-container login-element">
            <span (click)="applicationService.toggleLoginState()" [category]="'Forgot Password Button'" [click]="true"
              analytics class="forgot-password-button" transloco="Forgot Password?"></span>
          </div>
        </div>
        <div class="form" *ngIf="loginSectionOnly === false">
          <span class="title">{{ 'Register with' | transloco }} {{ brandInfo.brandName }}</span>
          <p class="register-text"
            transloco="If you don’t yet have an account, click the link below - you’re 5 minutes away from becoming a King!">
          </p>

          <div (click)="closeAndRegister()" class="primary-button large">
            {{ 'Register Now' | transloco }}
          </div>
        </div>
      </div>
    </div>

    <div body
      *ngIf="(accountQuery.resetOptionTriggered$ | async) === true && (applicationQuery.loginState$ | async) === 'Password Recovery'"
      class="content">
      <div class="header" *ngIf="loginSectionOnly === false">
        <div appDynamicSvgBackground="images/logo/brand-logo" class="brand-logo"></div>
      </div>

      <div (keydown.enter)="forgottenPassword()" *ngIf="(verificationInProgress$ | async) === false" [@fadeIn]
        class="password-recovery">
        <div
          *ngIf="(passwordRecoveryOutcome$ | async) !== 'success' && (passwordRecoveryOutcome$ | async) !== 'backend-error'"
          class="text">
          {{ 'Please enter the' | transloco }} <span *ngIf="!quickRegistration">{{ 'Username' | transloco }}</span><span
            *ngIf="quickRegistration">{{ 'Phone
            Number' | transloco }}</span>
          {{ 'you used to register your account with:' | transloco }}
        </div>
        <app-form-message *ngIf="(passwordRecoveryOutcome$ | async) === 'success'" size="big" text="{{ 'An email has been sent to the email registered with username:' | transloco }} “{{ passwordRecoveryUserName.value }}” {{
            'with instructions on how to reset your password.' | transloco
          }}" type="success">
        </app-form-message>
        <app-form-message *ngIf="(passwordRecoveryOutcome$ | async) === 'backend-error'"
          [category]="'Password Recovery Error'" [emitError]="true" [label]="'Please try again. An error has occurred.'"
          [text]="'Please try again. An error has occurred.' | transloco" analytics type="danger">
        </app-form-message>
        <div
          *ngIf="(passwordRecoveryOutcome$ | async) !== 'success' && (passwordRecoveryOutcome$ | async) !== 'backend-error' && loginPrefix"
          class="username-with-prefix">
          <input [value]="loginPrefix" class="prefix" readonly />
          <input (blur)="passwordResetMobileNumberCleanup()" [formControl]="passwordRecoveryUserName"
            [placeholder]="(quickRegistration ? 'Phone Number' : 'Username') | transloco"
            class="login-element username" />
        </div>
        <input [category]="'Input - Password Recovery ' + (quickRegistration ? 'Phone Number' : 'Username')"
          [focus]="true" [formControl]="passwordRecoveryUserName"
          *ngIf="(passwordRecoveryOutcome$ | async) !== 'success' && (passwordRecoveryOutcome$ | async) !== 'backend-error' && !loginPrefix"
          [placeholder]="(quickRegistration ? 'Phone Number' : 'Username') | transloco" analytics
          class="login-element" />
        <app-form-message *ngIf="(passwordRecoveryOutcome$ | async) === 'wrong-username'"
          [category]="'Password Recovery Error'" [emitError]="true"
          [label]="'User does not exist, please register to place bet'"
          [text]="'User does not exist, please register to place bet' | transloco" analytics type="danger">
        </app-form-message>
        <app-form-message *ngIf="(passwordRecoveryOutcome$ | async) === 'account-not-activated'"
          [category]="'Password Recovery Error'" [emitError]="true" [label]="'Account not activated'"
          [text]="'Account not activated' | transloco" analytics type="danger">
        </app-form-message>
        <div
          *ngIf="(passwordRecoveryOutcome$ | async) !== 'success' && (passwordRecoveryOutcome$ | async) !== 'backend-error'"
          class="action-buttons">
          <app-button (click)="clearData(); applicationService.toggleLoginState()"
            [category]="'Password Recovery - Cancel Button'" [click]="true" [text]="'Cancel' | transloco" analytics
            buttonType="danger" class="login-element">
          </app-button>
          <app-button (click)="forgottenPassword()" [disabled]="passwordRecoveryUserName.invalid"
            [text]="'Proceed' | transloco" buttonType="success" class="login-element"></app-button>
        </div>
        <div class="action-buttons success" *ngIf="(passwordRecoveryOutcome$ | async) === 'success'">
          <app-button (click)="clearData(); closeLogin()" [category]="'Password Recovery Success - OK Button'"
            [click]="true" [text]="'OK' | transloco" analytics buttonType="success" class="login-element">
          </app-button>
        </div>
        <div class="action-buttons" *ngIf="(passwordRecoveryOutcome$ | async) === 'backend-error'">
          <app-button (click)="closeLogin()" [category]="'Password Recovery Error - Cancel Button'" [click]="true"
            [text]="'Cancel' | transloco" analytics buttonType="danger" class="login-element"></app-button>
          <app-button (click)="restartRecoveryProcess()" [category]="'Password Recovery Error - Back Button'"
            [click]="true" [text]="'Back' | transloco" analytics buttonType="success" class="login-element">
          </app-button>
        </div>
      </div>

      <!--Forgot Password via Mobile number - Has been commented for future use.-->
      <!-- <div class="password-recovery" *ngIf="(accountQuery.resetPasswordOption$ | async) === 'BySms'" [@fadeIn]
      [formGroup]="forgotPasswordSMSForm" (keydown.enter)="forgottenPasswordSMS()">
      <div class="text" transloco="Please enter the mobile number you used to register your account with"></div>
      <app-form-message *ngIf="(passwordRecoveryOutcome$ | async) === 'backend-error'" type="danger"
        text="Please try again. An error has occurred."></app-form-message>

      <div class="form-item mobile-number">
        <div>
          <label transloco="New Mobile number"></label>
          <div class="form-item-row">
            <div class="dropdown" [class.no-arrow]="!showTelExtDropdownArrow"
              [class.valid]="forgotPasswordSMSForm.controls['mobilePrefix'].valid">
              <select formControlName="mobilePrefix">
                <option value="" disabled transloco="Select prefix"></option>
                <option
                  *ngFor="let telephoneExt of (registrationQuery.telephoneExtensions$ | async); trackBy: trackByTelExt"
                  [value]="telephoneExt.prefixNumber">
                  {{ telephoneExt.prefixNumber }} ({{ telephoneExt.countryCode }})
                </option>
              </select>
            </div>
            <div class="form-input">
              <input type="tel" [placeholder]="'e.g 60 123 4567' | transloco" class="mobile-number-input"
                formControlName="mobileNumber">
              <i class="fa fa-check" *ngIf="forgotPasswordSMSForm.controls['mobileNumber'].valid"></i>
            </div>
          </div>
          <app-form-message type="danger" *ngIf="forgotPasswordSMSForm.controls['mobileNumber'].errors?.pattern
            && forgotPasswordSMSForm.controls['mobileNumber'].touched" [text]="'Mobile number is invalid' | transloco">
          </app-form-message>
        </div>
      </div>

      <div class="action-buttons" *ngIf="(passwordRecoveryOutcome$ | async) !== 'success'">
        <app-button text="Cancel" class="login-element" buttonType="danger"
          (click)="clearData(); applicationService.toggleLoginState()">
        </app-button>
        <app-button text="Proceed" [disabled]="forgotPasswordSMSForm.controls['mobileNumber'].invalid"
          class="login-element" buttonType="success" (click)="forgottenPasswordSMS()"></app-button>
      </div>
      <div class="action-buttons success" *ngIf="(passwordRecoveryOutcome$ | async) === 'success'">
        <app-button text="OK" class="login-element" buttonType="success" (click)="clearData(); closeLogin()">
        </app-button>
      </div>
      <div class="action-buttons" *ngIf="(passwordRecoveryOutcome$ | async) === 'backend-error'">
        <app-button text="Cancel" class="login-element" buttonType="danger" (click)="closeLogin()"></app-button>
        <app-button text="Back" class="login-element" buttonType="success" (click)="restartRecoveryProcess()">
        </app-button>
      </div>
    </div> -->

      <div [@fadeIn]
        *ngIf="(accountQuery.resetOptionTriggered$ | async) === true && (verificationInProgress$ | async) === true"
        class="verification-container">
        <app-card [titleHeaderText]="'Phone Number Verification' | transloco">
          <div class="verification-card-container" body>
            <app-phone-verification [OTPOption]="2" [limitExceeded]="limitExceeded"
              [username]="loginPrefix + passwordRecoveryUserName.value">
            </app-phone-verification>
          </div>
        </app-card>
      </div>
    </div>
  </app-card>
</div>

<app-loading-bar
  [show]="(accountQuery.resetOptionTriggered$ | async) === false && (applicationQuery.loginState$ | async) !== 'Login'">
</app-loading-bar>