import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { MySettledBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';
import { MySettledBetsStore } from './my-settled-bets.store';

@Injectable({ providedIn: 'root' })
export class MySettledBetsQuery extends QueryEntity<MySettledBetsState, RecentBetModel> {
  allBets$ = this.selectAll();
  selectedCouponStatus$ = this.select(state => state.ui.selectedCouponStatus);
  isLoading$ = this.selectLoading();

  betsInSelectedStatus$ = combineLatest(this.allBets$, this.selectedCouponStatus$).pipe(
    map(([bets, status]) =>
      bets.filter(bet => {
        console.log('bet:', bet);
        if (status === 1) {
          // if betfinalstate is 'Won', include 'Partial Won', 'Void' and 'Cashout' bets to be shown
          return bet.betFinalState === 1 || bet.betFinalState === 2 || bet.betFinalState === 4 || bet.betFinalState === 5;
        } else {
          return bet.betFinalState === status;
        }
      })
    )
  );

  cashoutBets$ = combineLatest(this.allBets$, this.selectedCouponStatus$).pipe(
    map(([bets, status]) =>
      bets.filter(bet => {
        if (status === 1) {
          // if betfinalstate is 'Won', include 'Partial Won', 'Void' and 'Cashout' bets to be shown
          return bet.betFinalState === 5;
        } else {
          return bet.betFinalState === status;
        }
      })
    )
  );

  lostBets$ = combineLatest(this.allBets$, this.selectedCouponStatus$).pipe(
    map(([bets, status]) =>
      bets.filter(bet => {
        if (status === 1) {
          return bet.betFinalState === 3;
        } else {
          return bet.betFinalState === status;
        }
      })
    )
  );

  wonBets$ = combineLatest(this.allBets$, this.selectedCouponStatus$).pipe(
    map(([bets, status]) =>
      bets.filter(bet => {
        console.log('bet:', bet);
        if (status === 1) {
          return bet.betFinalState === 1 || bet.betFinalState === 2;
        } else {
          return bet.betFinalState === status;
        }
      })
    )
  );

  constructor(protected store: MySettledBetsStore) {
    super(store);
  }

  get hasData(): boolean {
    return this.getCount() > 0;
  }

  get lostBetsLoaded(): boolean {
    return this.getValue().lostBetsLoaded;
  }
}
