import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { addDays, format, isAfter, isBefore, subDays, subMonths, subWeeks } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { BetSearchService } from 'src/app/core/services/bet-search.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponStatus } from 'src/app/shared/models/bet-search.model';

@Component({
  selector: 'account-bet-search-form',
  templateUrl: './bet-search-form.component.html',
  styleUrls: ['./bet-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetSearchFromComponent implements OnInit, OnDestroy {
  @Input() isVirtuals: boolean;

  betSearchForm: FormGroup;
  today = format(new Date(), 'yyyy-MM-dd');
  loading$ = this.accountQuery.betSearch$.pipe(map(state => state.isLoading));

  oneWeekBeforeToday = format(subWeeks(new Date(), 1), 'yyyy-MM-dd');

  couponStatus = [
    { name: translate('All'), id: 1 },
    { name: translate('Running'), id: 2 },
    { name: translate('Lost'), id: 4 },
    { name: translate('Won'), id: 3 },
    { name: translate('Cancelled'), id: 5 }
  ];

  minFromDate$ = new BehaviorSubject(format(subMonths(new Date(this.today), 3), 'yyyy-MM-dd'));

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly betSearchService: BetSearchService,
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    
  ) {}

  get couponCodePlaceholder(): string {
    return this.isVirtuals ? 'e.g. 123456789-1' : 'e.g. GF2B8M-6EA9-FD5HX4';
  }

  onKeydown(event: KeyboardEvent): void {
    event.preventDefault();
  }

  generateBetSearchForm(): FormGroup {
    return new FormGroup({
      dateFrom: new FormControl(this.oneWeekBeforeToday, Validators.required),
      dateTo: new FormControl(this.today, Validators.required),
      couponStatus: new FormControl(CouponStatus.Any, Validators.required),
      couponCode: new FormControl('')
    });
  }

  outcomeTrackBy(index: number, outcome: string): string {
    return outcome;
  }

  searchBets(): void {
    this.betSearchService.resetState();

    this.betSearchService.searchBets(this.betSearchForm.value, this.isVirtuals).subscribe(() =>
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.betSearchForm.value,
        queryParamsHandling: 'merge'
      })
    );
  }

  checkMinFromDate(): void {
    this.betSearchForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.dateFrom === '') {
        this.betSearchForm.controls.dateFrom.setValue(
          isAfter(new Date(data.dateTo), new Date(this.oneWeekBeforeToday))
            ? this.oneWeekBeforeToday
            : format(subDays(new Date(data.dateTo), 1), 'yyyy-MM-dd')
        );
      } else if (data.dateTo === '') {
        this.betSearchForm.controls.dateTo.setValue(
          isBefore(new Date(data.dateFrom), new Date(this.today)) ? this.today : format(subDays(new Date(data.dateFrom), 1), 'yyyy-MM-dd')
        );
      } else {
        this.minFromDate$.next(format(addDays(subMonths(new Date(data.dateTo), 3), 1), 'yyyy-MM-dd'));
      }
    });
  }

  ngOnInit(): void {
    this.betSearchForm = this.generateBetSearchForm();
    this.checkMinFromDate();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
