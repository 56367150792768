import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';

import { OddModel } from 'src/app/shared/models/coupon.model';
import { SelectionModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-multi-line-market-odds',
  templateUrl: './multi-line-market-odds.component.html',
  styleUrls: ['./multi-line-market-odds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiLineMarketOddsComponent implements OnInit {
  @Input() odds: OddModel[];
  @Input() markets: SelectionModel[];
  @Input() bordered: boolean = true;
  selections: OddModel[] = [];
  displayMarkets: SelectionModel[];
  displayOddsInSingleLine$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly appConfig: AppConfigService) {}

  ngOnInit() {
    if (this.appConfig.get('sports')?.prematchSelectionsSwap.indexOf(this.odds[0].marketTypeId) !== -1) {
      this.selections = [this.odds[1], this.odds[0]];
      this.displayMarkets = [this.markets[1], this.markets[0]];
    } else {
      this.selections = this.odds.slice();
      this.displayMarkets = this.markets.slice();
    }

    const displayOddsInSingleLine = this.appConfig.get('sports')?.multiLineMarketOddsInSingleLine.includes(this.markets[0].id);
    this.displayOddsInSingleLine$.next(displayOddsInSingleLine);
  }

  marketTrackBy(index: number, market: SelectionModel): number {
    return market ? market.id : index;
  }

  oddTrackBy(index: number, odd: OddModel): number {
    return index;
  }

  getLongestSelectionNameLength() {
    let maxNameLength = 0;
    this.odds.forEach(odd => {
      const currentLength = (odd as unknown as OddModel)?.selectionName.length;
      maxNameLength = currentLength > maxNameLength ? currentLength : maxNameLength;
    });
    return maxNameLength;
  }
}
