import { Injectable } from '@angular/core';
import { subDays, setHours, setMinutes, subMonths } from 'date-fns';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { MyBetsQuery } from 'src/app/core/state/my-bets/my-bets.query';
import { MyBetsStore } from 'src/app/core/state/my-bets/my-bets.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BetCashoutModel, CashoutModel, CashoutSource } from 'src/app/shared/models/cashout.model';
import { BetFinalState, CouponDetailsGroupModel } from 'src/app/shared/models/coupon-details.model';
import { BetDetailsModel, CouponStatus, FullEventModel, RecentBetModel, RecentBetRequestModel } from 'src/app/shared/models/my-bets.model';
import { AppConfigService } from './app-config.service';
import { CashoutService } from './cashout.service';

export enum MyBetsTimePeriod {
  TODAY = 'Today',
  LAST_3_DAYS = 'Last 3 Days',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_30_DAYS = 'Last 30 Days',
  CUSTOM_PERIOD = 'Custom Period',
  ALL_TIME = 'All time' /**added this for checking all time active bets in header my-bets icon */
}

@Injectable({
  providedIn: 'root'
})
export class MyBetsService {
  updateOpenBets$: Subject<boolean> = new Subject();
  updateBet$: Subject<string> = new Subject();
  constructor(
    private readonly myBetsStore: MyBetsStore,
    private readonly myBetsQuery: MyBetsQuery,
    private readonly apiService: APIService,
    private readonly accountQuery: AccountQuery,
    private readonly appConfig: AppConfigService,
    private readonly cashoutStore: CashoutStore,
    private readonly cashoutService: CashoutService,
    private readonly languageService: LanguageService
  ) {}

  getAllBets(period: MyBetsTimePeriod, from?: string, to?: string): void {
    this.myBetsStore.setAllBetsLoading();

    this.getApiRequest(CouponStatus.All, period, from, to).subscribe(this.handleAllBetsResponse);
  }

  getOpenBets(period: MyBetsTimePeriod, from?: string, to?: string): void {
    this.myBetsStore.setOpenLoading();
    this.getApiRequest(CouponStatus.Open, period, from, to).subscribe(this.handleOpenBetsResponse);
  }
  getAllOpenBets(period: MyBetsTimePeriod, from?: string, to?: string): void {
    this.myBetsStore.setAllOpenLoading();
    this.getApiRequest(CouponStatus.Open, period, from, to).subscribe(this.handleAllOpenBetsResponse);
  }

  getWonBets(period: MyBetsTimePeriod, from?: string, to?: string): void {
    this.myBetsStore.setSettledLoading();
    this.getApiRequest(CouponStatus.Won, period, from, to).subscribe(this.handleWonResponse);
  }

  getLostBets(period: MyBetsTimePeriod, from?: string, to?: string): void {
    this.myBetsStore.setSettledLoading();
    this.getApiRequest(CouponStatus.Lost, period, from, to).subscribe(this.handleLostResponse);
  }

  addNewBet(couponCode: string): void {
    this.myBetsStore.addNewBet(couponCode);
  }
  private getApiUrl(noCheck: boolean, couponCode: string, language: string) {
    let apiSettings = new APISettings();

    let apiEndpoint: string;

    if (noCheck) {
      apiEndpoint = `api/coupons/noCheck/allByCode/${couponCode}/language/${language}`;
      apiSettings.noAuthToken = true;
    } else {
      apiEndpoint = `api/coupons/byCode/${couponCode}/language/${language}`;
    }
    return { apiEndpoint, apiSettings };
  }
  getBetDetails(bet: RecentBetModel, language: string = 'sr', toggleCollapsed = true, noCheck: boolean = false): Observable<boolean> {
    if (!bet || !bet.couponCode) {
      return new Observable<boolean>();
    }
    if (toggleCollapsed) {
      this.toggleCollapsed(bet);
    }
    const { apiEndpoint, apiSettings } = this.getApiUrl(noCheck, bet.couponCode, language);

    if (this.appConfig.get('sports').cashout.enabled && bet.betFinalState === BetFinalState.Placed) {
      return forkJoin(
        this.apiService.get<any>(APIType.Sportsbook, apiEndpoint, apiSettings),
        this.apiService.get<any>(APIType.Sportsbook, `api/coupons/cashoutvalue_new/${bet.couponCode}`)
      ).pipe(
        map(([betDetailsData, cashoutData]) => {
          if (!betDetailsData) {
            return false;
          }

          const betDetails: BetDetailsModel = this.parseBetDetailsResponse(betDetailsData);
          const betCashout: BetCashoutModel = this.cashoutService.parseBetCashoutResponse(cashoutData);
          const cashout: CashoutModel = new CashoutModel({
            betCashout,
            betFinalState: betDetailsData.BetFinalState,
            cashoutSource: CashoutSource.recentBets,
            couponCode: betDetailsData.CouponCode,
            userId: betDetailsData.UserId
          });

          if (betDetails) {
            this.myBetsStore.updateBetDetails(bet.id, betDetails, bet.betFinalState);
            this.cashoutStore.addCashoutData(cashout);
          }

          return true;
        })
      );
    } else {
      return this.apiService.get<any>(APIType.Sportsbook, apiEndpoint, apiSettings).pipe(
        map(responseData => {
          if (!responseData) {
            return false;
          }

          const betDetails: BetDetailsModel = this.parseBetDetailsResponse(responseData);
          if (betDetails) {
            this.myBetsStore.updateBetDetails(bet.id, betDetails, bet.betFinalState);
          }
          return true;
        })
      );
    }
  }

  getBetDetailsByCode(couponCode: string, language: string = 'sr', noCheck: boolean = false): Observable<any> {
    const { apiEndpoint, apiSettings } = this.getApiUrl(noCheck, couponCode, language);

    return this.apiService.get<any>(APIType.Sportsbook, apiEndpoint, apiSettings).pipe(
      map(betDetailsData => {
        if (!betDetailsData) {
          return undefined;
        }
        const betDetails: BetDetailsModel = this.parseBetDetailsResponse(betDetailsData);

        const recentBet: RecentBetModel = new RecentBetModel({
          couponCode: betDetailsData.CouponCode,
          couponDate: betDetailsData.CouponDate,
          couponStatus: betDetailsData.CouponStatus,
          couponStatusId: betDetailsData.CouponStatusId,
          betFinalState: betDetailsData.BetFinalState,
          currencySymbol: betDetailsData.CurrencySymbol,
          stakeGross: betDetailsData.StakeGross,
          userId: betDetailsData.UserId,
          betDetails: betDetails,
          collapsed: false,
          overviewWin: betDetailsData.MaxWinNet,
          wonAmount: betDetailsData.Won,
          isLive: betDetailsData.IsLive,
          totalOdds: betDetailsData.TotalOdds,
          couponType: betDetailsData.CouponType
        });

        return recentBet;
      })
    );
  }
  toggleCollapsed(bet: RecentBetModel): void {
    this.myBetsStore.toggleCollapsed(bet);
  }

  // setSelectedCouponStatus(betFinalState: BetFinalState): void {
  //   if (betFinalState === BetFinalState.Lost && !this.myBetsQuery.lostBetsLoaded) {
  //     this.getLostBets();
  //   }

  //   this.myBetsStore.setSelectedCouponStatus(betFinalState);
  // }

  clearCashouts(): void {
    this.cashoutStore.remove(entity => entity.cashoutSource === CashoutSource.recentBets);
  }

  clearSettledBets(): void {
    this.myBetsStore.clearSettledBets();
  }

  removeFromOpenBets(couponCode: string): void {
    this.myBetsStore.removeOpenBet(couponCode);
  }

  // resetBets(): void {
  //   this.getOpenBets();
  //   this.clearSettledBets();
  // }

  getApiRequest(betFinalState: BetFinalState | CouponStatus, period: MyBetsTimePeriod, from?: string, to?: string): Observable<any> {
    const requestModel = this.generateRecentBetRequest(betFinalState, period, from, to);
    return this.apiService.post<any>(APIType.Sportsbook, 'api/couponsListByPost', requestModel);
  }

  private readonly handleAllBetsResponse = (response): void => {
    const bets = this.parseAllBetsResponse(response.Coupons);
    this.myBetsStore.setAllBets(bets);
  };

  private readonly handleOpenBetsResponse = (response): void => {
    const bets = this.parseRecentBetResponse(response.Coupons);
    this.myBetsStore.setOpenBets(bets);
  };
  private readonly handleAllOpenBetsResponse = (response): void => {
    const bets = this.parseRecentBetResponse(response.Coupons);
    this.myBetsStore.setAllOpenBets(bets);
  };
  private readonly handleWonResponse = (won): void => {
    const bets = this.parseRecentBetResponse(won.Coupons, false);
    this.myBetsStore.setSettledBets(bets);
  };

  private readonly handleLostResponse = (lost): void => {
    const bets = this.parseRecentBetResponse(lost.Coupons, false);
    this.myBetsStore.addLostBets(bets);
  };

  private generateRecentBetRequest(
    couponStatus: BetFinalState | CouponStatus,
    period: MyBetsTimePeriod,
    from?: string,
    to?: string
  ): RecentBetRequestModel {
    let dateFrom;
    let dateTo;
    switch (period) {
      case MyBetsTimePeriod.TODAY:
        dateFrom = setHours(setMinutes(new Date(), 0), 0).toISOString();
        break;
      case MyBetsTimePeriod.LAST_3_DAYS:
        dateFrom = setHours(setMinutes(subDays(new Date(), 2), 0), 0).toISOString();
        break;
      case MyBetsTimePeriod.LAST_7_DAYS:
        dateFrom = setHours(setMinutes(subDays(new Date(), 6), 0), 0).toISOString();
        break;
      case MyBetsTimePeriod.LAST_30_DAYS:
        dateFrom = setHours(setMinutes(subDays(new Date(), 29), 0), 0).toISOString();
        break;
      case MyBetsTimePeriod.CUSTOM_PERIOD:
        dateFrom = setHours(setMinutes(new Date(from), 0), 0).toISOString();
        dateTo = setHours(setMinutes(new Date(to), 0), 0).toISOString();
        break;
      case MyBetsTimePeriod.ALL_TIME:
        dateFrom = setHours(setMinutes(subDays(subMonths(new Date(), 24), 0), 0), 0).toISOString();
        break;

      default:
        dateFrom = setHours(setMinutes(new Date(from), 0), 0).toISOString();
    }
    const recentBetRequest = new RecentBetRequestModel({
      couponStatus: couponStatus,
      dateFrom: dateFrom,
      dateTo: dateTo ? dateTo : new Date().toISOString(),
      pageSize: 500,
      requestedPage: 1,
      includUsers: true,
      lang: this.languageService.selectedLanguage.language,
      couponCode: '',
      settlementDate: false
    });

    return recentBetRequest;
  }

  private parseAllBetsResponse(response: any): RecentBetModel[] {
    const retVal = [];
    response.forEach(data =>
      retVal.push(
        new RecentBetModel({
          couponCode: data.CouponCode,
          couponDate: new Date(data.CouponDate).toISOString(),
          // format(new Date(data.CouponDate), 'd MMMM yyyy HH:mm:ss', { locale: this.languageService.selectedLanguage.dateLocale }),
          couponStatusId: data.CouponStatusId,
          stakeGross: data.StakeGross,
          currencySymbol: data.Currency.CurrencySymbol,
          betFinalState: data.BetFinalState,
          isLive: data.IsLive,
          totalOdds: data.TotalOdds,
          userId: data.UserId,
          overviewWin: data.MaxWinNet,
          wonAmount: data.Won,
          couponType: data.CouponType
        })
      )
    );

    return retVal;
  }

  private parseRecentBetResponse(response: any, isOpen: boolean = true): RecentBetModel[] {
    const retVal = [];

    response.forEach(data => {
      if (data.BetFinalState !== BetFinalState.InEvaluation) {
        retVal.push(
          new RecentBetModel({
            couponCode: data.CouponCode,
            couponDate: new Date(data.CouponDate).toISOString(),
            couponStatusId: data.CouponStatusId,
            stakeGross: data.StakeGross,
            currencySymbol: data.Currency.CurrencySymbol,
            betFinalState: data.BetFinalState,
            isLive: data.IsLive,
            totalOdds: data.TotalOdds,
            userId: data.UserId,
            overviewWin: data.MaxWinNet,
            wonAmount: data.Won,
            couponType: data.CouponType
          })
        );
      }
    });

    return retVal;
  }

  private parseBetDetailsResponse(response: any): BetDetailsModel {
    let betDetails: BetDetailsModel;
    const events: FullEventModel[] = [];

    let minOdds = Infinity;
    let maxOdds = 0;

    if (response.Odds) {
      response.Odds.forEach(odd => {
        if (odd.OddValue < minOdds) {
          minOdds = odd.OddValue.toFixed(2);
        }

        if (odd.OddValue > maxOdds) {
          maxOdds = odd.OddValue.toFixed(2);
        }

        let eventStatusId;
        if (response.BetFinalState === BetFinalState.Cashout) {
          eventStatusId = 5; // cashout
        } else {
          if (odd.Win === -1) {
            eventStatusId = 4; // cancelled
          } else if (odd.Win === 0) {
            eventStatusId = 3; // lost
          } else if (odd.Win === 1) {
            eventStatusId = 1; // won
          } else {
            eventStatusId = -1; // running
          }
        }

        events.push({
          eventName: odd.EventName,
          sportId: odd.IDSport,
          eventDate: odd.EventDate,
          eventStatusId: eventStatusId,
          marketName: odd.MarketName,
          selectionName: odd?.SelectionName,
          result: odd.Result,
          results: odd.Results,
          eventID: odd.IDEvent,
          providerID: odd.IDProvider,
          eventCategory: odd.EventCategory,
          oddValue: odd.OddValue,
          fixedOdd: odd.FixedOdd,
          spreadValue: odd.SpecialValue,
          marketTypeId: odd.IDMarketType
        });
      });
    }

    const groupings: CouponDetailsGroupModel[] = [];
    (response.Groupings ?? []).forEach(group => {
      groupings.push({
        combinations: group.Combinations,
        grouping: group.Grouping,
        maxBonus: group.MaxBonus,
        maxWin: group.MaxWin,
        minBonus: group.MinBonus,
        minWin: group.MinWin,
        netStakeMaxWin: group.netStakeMaxWin,
        netStakeMinWin: group.NetStakeMinWin,
        stake: group.Stake,
        stakeNet: group.NetStake,
        stakeTax: group.TurnoverTax
      });
    });
    betDetails = new BetDetailsModel({
      couponType: response.CouponType,
      totalOdds: response.TotalOdds,
      totalCombinations: response.TotalCombinations,
      maxWin: response.MaxWin,
      netStakeMaxWin: response.NetStakeMaxWin,
      maxWinNet: response.MaxWinNet,
      minWinNet: response.MinWinNet,
      maxWinTax: response.MaxWithholdingTax,
      maxBonus: response.MaxBonus,
      won: response.Won,
      wonTax: response.TotalTaxed,
      events: events,
      groupings,
      minOdds,
      maxOdds
    });
    return betDetails;
  }
}
