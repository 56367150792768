import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { CouponDetailsGroupModel } from 'src/app/shared/models/coupon-details.model';
import { BetFinalState, RecentBetModel } from 'src/app/shared/models/my-bets.model';
import { copyToClipboard } from 'src/app/shared/utils/copy-to-clipboard';

@Component({
  selector: 'app-recent-bet-details',
  templateUrl: './recent-bet-details.component.html',
  styleUrls: ['./recent-bet-details.component.scss']
})
export class RecentBetDetailsComponent implements OnInit {
  @ViewChild('couponCode', { static: true }) couponCode: ElementRef;
  @Input() bet: RecentBetModel;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();

  copiedCouponCodeToClipboard$: Subject<boolean> = new Subject();
  betFinalState: typeof BetFinalState = BetFinalState;

  systemName$: BehaviorSubject<string> = new BehaviorSubject('');
  systemTarget$: BehaviorSubject<string> = new BehaviorSubject('');
  events$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  numberOfFixes$: BehaviorSubject<number> = new BehaviorSubject(0);

  private isSameYear: boolean;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly appService: ApplicationService
  ) {}

  ngOnInit() {
    this.isSameYear = new Date().getFullYear() === new Date(this.bet.couponDate).getFullYear();

    const sortedEventsByFixedOddsAndTime = [...this.bet.betDetails.events]
      .sort((event1: any, event2: any) => new Date(event1.eventDate).getTime() - new Date(event2.eventDate).getTime())
      .sort((event1: any, event2: any) => event2.fixedOdd - event1.fixedOdd);

    this.events$.next(sortedEventsByFixedOddsAndTime);

    if (this.isCouponTypeSystem()) {
      this.createSystemName();
      this.numberOfFixes$.next(sortedEventsByFixedOddsAndTime.filter((match: any) => match.fixedOdd).length);
    }
  }

  get dateFormat(): string {
    return this.isSameYear ? 'dd. MMM, HH:mm' : 'dd. MMM yyyy, HH:mm';
  }

  get isMyBet(): boolean {
    return this.bet.userId === this.accountQuery.userData.id.toString();
  }

  copyCouponCode(): void {
    copyToClipboard(this.bet.couponCode);
    this.copiedCouponCodeToClipboard$.next(true);

    setTimeout(() => this.copiedCouponCodeToClipboard$.next(false), 2000);
  }

  onRefresh(): void {
    this.refresh.emit(true);
  }

  openFullBetDetails(): void {
    const couponCode = this.isMyBet ? this.bet.couponCode : [this.bet.couponCode, this.bet.userId].join(',');
    this.appService.showMyBets$.next(false);
    this.router.navigate([`/account/bet-search/coupon-details/${couponCode}`]);
  }

  isCouponTypeSystem(): boolean {
    const type = this.bet.betDetails.couponType ?? this.bet.couponType;
    return type === 'Combinations';
  }

  isCouponTypeRegular(): boolean {
    const type = this.bet.betDetails.couponType ?? this.bet.couponType;
    return type === 'Single' || type === 'Multiple';
  }

  getBonus(): number {
    const bonus = Math.round((this.bet.betDetails.maxBonus * 100) / (this.bet.betDetails.maxWinNet - this.bet.betDetails.maxBonus));
    return isNaN(bonus) ? 0 : bonus;
  }

  private createSystemName(): void {
    const numberOfFixes = ((this.bet.betDetails.events as any[]) || []).filter(match => match.fixedOdd).length;

    let systemMatchesTarget = '';
    const groupings = [...this.bet.betDetails.groupings].sort(
      (a: CouponDetailsGroupModel, b: CouponDetailsGroupModel) => a.grouping - b.grouping
    );
    groupings.forEach(group => {
      systemMatchesTarget = `${systemMatchesTarget}${group.grouping - numberOfFixes},`;
    });
    systemMatchesTarget = systemMatchesTarget.substring(0, systemMatchesTarget.length - 1);

    const systemMatchesTotal = ((this.bet.betDetails.events as any[]) || []).length - numberOfFixes;

    this.systemTarget$.next(`${systemMatchesTarget}/${systemMatchesTotal}`);

    if (numberOfFixes > 0) {
      this.systemName$.next(`${numberOfFixes} Fix + ${systemMatchesTarget}/${systemMatchesTotal}`);
    } else {
      this.systemName$.next(`${systemMatchesTarget}/${systemMatchesTotal}`);
    }
  }
}
