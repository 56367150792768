import { AnalyticsPageDetails } from "src/app/shared/models/analytics.model";

export const getAnalyticsPage = (url: string): AnalyticsPageDetails => {
    if (url === '/') { return homePage; }

    const page: AnalyticsPageDetails = {
        Title: '',
        Path: '',
        Location: window.location.href
    };

    page.Path = getPagePath(url);
    page.Title = getPageTitle(page.Path);

    return page;
}

const homePage: AnalyticsPageDetails = {
    Title: 'Home',
    Path: '/',
    Location: window.location.href
};

const getPagePath = (url: string): string => {
    if (url === '/') {
        return url;
    }

    let pagePath = url
        .split('/')
        .filter(part => part.length)
        .map(part => {
            const cleanPart = part.split("?")[0];
            if (cleanPart.length < 4) {
                return cleanPart;
            }

            if (isNaN(cleanPart as any)) {
                const numberOfDashes = (cleanPart.match(/-/g) || []).length;
                const numberOfDigits = (cleanPart.match(/\d/g) || []).length;

                if (numberOfDashes === 3 && numberOfDigits >= 4) {
                    return '[coupon-id]';
                }
            }
            else {
                return '[match-id]';
            }

            return cleanPart;
        })
        .reduce((a, b) => `${a}/${b}`, '');

    if (pagePath.startsWith('/sports/match/[match-id]')) {
        pagePath = '/sports/match/[match-id]';
    }

    return pagePath;
};

const getPageTitle = (path: string): string => {
    let title = '';
    path
        .split('/')
        .filter(part => part.length)
        .forEach(part => {
            if (part === '[match-id]') {
                title += 'Match Details';
                return;
            }
            if (part === '[coupon-id]') {
                title += 'Coupon Details';
                return;
            }

            // Skip, so we don't have duplicate 'Coupon Details'
            if (part === 'coupon-details') {
                return;
            }

            const cleanString = part.replace('-', ' ');
            cleanString.split(' ').forEach(s => title += `${capitalize(s)} `);
        });

    return title.trim();
}

const capitalize = (s: string): string => s && s[0].toUpperCase() + s.slice(1);