import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { AnalyticsEventAction } from 'src/app/shared/models/analytics.model';
import { OddModel } from 'src/app/shared/models/coupon.model';

@Component({
  selector: 'app-odd-with-label',
  templateUrl: './odd-with-label.component.html',
  styleUrls: ['./odd-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OddWithLabelComponent implements OnInit, OnDestroy {
  @Input() odd: OddModel | undefined;
  @Input() selectionName: string;
  @Input() longestSelectionName?: number;

  // Inputs for styling
  @Input() multiple = false;
  @Input() first = false;
  @Input() last = false;
  @Input() fullWidthLabel = false;
  @HostBinding('class.noborder') @Input() noborder = false;

  @HostBinding('class.outright') @Input() outright = false;

  @HostBinding('class.selected') selected: boolean;

  private readonly destroy$ = new Subject<any>();

  @HostBinding('class.no-radius-left') get noRadiusLeft(): boolean {
    return this.multiple && !this.first;
  }

  @HostBinding('class.no-radius-right') get noRadiusRight(): boolean {
    return this.multiple && !this.last;
  }

  constructor(
    private readonly couponService: CouponService,
    private readonly couponQuery: CouponQuery,
    private readonly cdr: ChangeDetectorRef,
    private readonly languageService: LanguageService,
    private readonly analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.couponQuery.couponData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      const selected = this.odd && this.couponService.isOddInCoupon(this.odd.id);
      if (selected !== this.selected) {
        this.selected = selected;
        this.cdr.markForCheck();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('click') selectOdd(): void {
    if (this.odd && this.odd.value !== 1) {
      this.selected
        ? this.couponService.removeOdd(this.odd.id, this.odd.marketId)
        : this.couponService.addOdd(this.odd, this.languageService.selectedLanguage.language);
    }

    this.reportSelectOdd();
  }

  public getSelectionNameLength(): number {
    return this.longestSelectionName ? this.longestSelectionName : 1;
  }

  private reportSelectOdd(): void {
    this.analyticsService.sendEvent({
      Action: AnalyticsEventAction.Click,
      Category: 'Select Match Odd',
      Label: `${this.selected ? 'Selected' : 'Unselected'}`
    });
  }
}
