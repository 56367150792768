import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Pipe({
  name: 'filterByTermAndProperty'
})
export class FilterByTermAndPropertyPipe implements PipeTransform {
  // Filters an array based on the given term in the given property of the object
  // Array: the input variable
  // Term: the string what you are searching, for example: 'Juventus'
  // Property: what is the key in the object to use.
  // For example: array: [{sportName: 'Juventus}], term: 'Juventus', property: 'sportName' is a match.

  constructor() {}

  transform(array: any[], term: string, property: string): any {
    if (term === translate('All') || !term || !array) {
      return array;
    }
    if (array[0][property]) {
      return array.filter(item => item[property].toLowerCase().includes(term.toLowerCase()));
    } else if (array[0][0][property]) {
      return array.filter(item => item[0][property].toLowerCase().includes(term.toLowerCase()));
    }
  }
}
