import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { SelectionModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-events-markets-header',
  templateUrl: './events-markets-header.component.html',
  styleUrls: ['./events-markets-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsMarketsHeaderComponent implements OnInit, OnDestroy {
  @Input() markets: SelectionModel[];
  @Input() separatorText: string;
  @Input() separator: boolean = true;
  destroy$ = new Subject<boolean>();

  constructor(public applicationQuery: ApplicationQuery, ) {}

  marketTrackBy(index: number, selection: SelectionModel): number {
    return selection.id;
  }

  ngOnInit(): void {
    this.applicationQuery.activeUrl$.pipe(takeUntil(this.destroy$)).subscribe(url => {
      if (url[0] === 'last-minute') {
        this.separatorText = translate('Starting soon');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
