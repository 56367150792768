import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LocalStorageService } from 'ngx-webstorage';
import {
  AreaModel,
  EventModel,
  LiveEventByArea,
  LiveFeedModel,
  LiveState,
  LiveUIState,
  MarketModel
} from 'src/app/shared/models/live.model';

function createInitialState(): LiveState {
  return {
    favourites: [],
    liveFeeds: undefined,
    selectedFeed: undefined,
    selectedMarket: undefined,
    selectedAreaInMatchView: undefined,
    selectedLiveEvent: undefined,
    liveViewEnabledByUser: false,
    showFavourites: false,
    ui: {
      noLiveFeedData: false,
      notValidEventId: false,
      liveFeedsLoading: false,
      selectedLiveEventLoading: false
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'live' })
export class LiveStore extends Store<LiveState> {
  favouriteLiveEventsKey = 'liveFavourites';

  constructor(private readonly localStorage: LocalStorageService) {
    super(createInitialState());
    this.readFavouritesFromLocalStorage();
  }

  updateUI(ui: Partial<LiveUIState>): void {
    this.update(state => ({ ui: { ...state.ui, ...ui } }));
  }

  updateLiveFeed(liveFeeds: LiveFeedModel[]): void {
    this.update({ liveFeeds });
  }

  updateSelectedFeed(selectedFeedId: number): void {
    this.update({ selectedFeed: selectedFeedId });
  }

  updateShowFavourites(showFavourites: boolean): void {
    this.update({ showFavourites });
  }

  updateFavourites(favourites: EventModel[]): void {
    this.localStorage.store(this.favouriteLiveEventsKey, favourites);
    this.update({ favourites });
  }

  updateSelectedLiveEvent(selectedLiveEvent: LiveEventByArea): void {
    this.update({ selectedLiveEvent });
  }

  updateSelectedAreaInMatchView(selectedAreaInMatchView: AreaModel): void {
    this.update({ selectedAreaInMatchView });
  }

  clearSelectedAreaInMatchView(): void {
    this.update({
      selectedAreaInMatchView: undefined
    });
  }

  updateSelectedMarket(selectedMarket: MarketModel): void {
    this.update({ selectedMarket });
  }

  updateLiveViewEnabledByUser(liveViewEnabledByUser: boolean): void {
    this.update({ liveViewEnabledByUser });
  }

  private readFavouritesFromLocalStorage(): void {
    const events = this.localStorage.retrieve(this.favouriteLiveEventsKey);

    if (Array.isArray(events)) {
      this.updateFavourites(events);
    }
  }
}
