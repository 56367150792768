<form [formGroup]="betSearchForm" class="bet-search-form">
  <div class="form-field">
    <div class="label" transloco="Date From:"></div>
    <div class="date-picker">
      <input type="date" id="start" [value]="oneWeekBeforeToday" [min]="minFromDate$ | async"
        [max]="betSearchForm.value.dateTo" formControlName="dateFrom">
    </div>
  </div>
  <div class="form-field">
    <div class="label" transloco="Date To:"></div>
    <div class="date-picker">
      <input type="date" id="start" (keydown)="onKeydown($event)" [value]="today"
        [min]="betSearchForm.controls['dateFrom'].value" [min]="betSearchForm.value.dateFrom" [max]="today"
        formControlName="dateTo">
    </div>
  </div>
  <div class="form-field">
    <div class="label" transloco="Outcome:"></div>
    <div class="dropdown">
      <select formControlName="couponStatus">
        <option *ngFor="let outcome of couponStatus; trackBy: outcomeTrackBy" [ngValue]="outcome.id">
          {{ outcome.name }}
        </option>
      </select>
    </div>
  </div>
  <div class="form-field">
    <div class="label" transloco="Or enter a specific coupon code:"></div>
    <div>
      <input formControlName="couponCode" type="text" [placeholder]="couponCodePlaceholder">
    </div>
  </div>
  <div class="action-buttons">
    <app-button [disabled]="betSearchForm.invalid" [loading]="loading$ | async" [text]="'proceed' | transloco" buttonType="success"
      (btnClick)="searchBets()">
    </app-button>
  </div>
</form>
