import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { BlogService } from 'src/app/modules/blog/blog.service';

interface Jackpot {
  JackpotName: string;
  JackpotValue: number;
}

@Component({
  selector: 'app-a-z-menu',
  templateUrl: './a-z-menu.component.html',
  styleUrls: ['./a-z-menu.component.scss']
})
export class AZMenuComponent implements OnInit, OnDestroy {
  jackpots$: Subject<Jackpot[]> = new Subject();

  constructor(
    private readonly appService: ApplicationService,
    private readonly appConfigService: AppConfigService,
    private readonly httpService: HttpClient,
    private readonly languageService: LanguageService,
    private readonly router: Router,
    private readonly blogService: BlogService
  ) {}

  ngOnInit(): void {
    document.body?.classList?.add('disable-scrolling');
    this.getJackpots();
  }

  ngOnDestroy(): void {
    document.body?.classList?.remove('disable-scrolling');
  }

  close(): void {
    this.appService.showAZMenu$.next(false);
  }

  openSelectLanguageMenu(): void {
    this.appService.showLanguagePicker$.next(true);
  }

  openAviator(): void {
    const aviatorGameId = this.appConfigService.get('aviatorGameId');
    const url = this.router.createUrlTree(['/slot'], { queryParams: { openGameId: aviatorGameId } }).toString();
    window.location.href = url;
    this.close();
  }
  openLiveVegas(): void {
    const url = this.router.createUrlTree(['/slot'], { queryParams: { categoryId: 1 } }).toString();
    window.location.href = url;
    this.close();
  }

  openInstantGames(): void {
    const url = this.router.createUrlTree(['/slot'], { queryParams: { categoryId: 4 } }).toString();
    window.location.href = url;
    this.close();
  }

  openVegas(): void {
    const url = this.router.createUrlTree(['/slot'], { queryParams: { categoryId: 0 } }).toString();
    window.location.href = url;
    this.close();
  }
  openStats(): void {
    window.location.href = 'https://s5.sir.sportradar.com/victoryserbia/sr';
  }

  openBigBoyz(): void {
    window.location.href = 'https://www.bigboyz.rs/';
  }

  openLiveChat(): void {}

  get isSrLanguage$(): Observable<boolean> {
    return this.languageService.selectedLanguage$.pipe(map(languageModel => languageModel.language === 'sr'));
  }

  get isEnLanguage$(): Observable<boolean> {
    return this.languageService.selectedLanguage$.pipe(map(languageModel => languageModel.language === 'en'));
  }
  get isBlogVisible$(): Observable<boolean> {
    return this.blogService.config$.pipe(map(config => !!config?.visible));
  }
  private getJackpots(): void {
    this.httpService
      .get(`${this.appConfigService.get('apiBaseUrl').promotionsEngine}/promotions/active-jackpots`)
      .subscribe((jackpots: Jackpot[]) => {
        if (jackpots.length === 3) {
          this.jackpots$.next([jackpots[2], jackpots[0], jackpots[1]]);
        } else {
          this.jackpots$.next(jackpots);
        }

        setTimeout(this.jackpotScrollAnimation.bind(this), 1000);
      });
  }

  private jackpotScrollAnimation(): void {
    const el = document.getElementById('jackpot-slider') as HTMLDivElement;

    const width = el.clientWidth;
    const scrollWidth = el.scrollWidth;

    el.scrollTo({ left: (scrollWidth - width) / 2, behavior: 'smooth' });
  }
}
