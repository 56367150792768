import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { MenuStore } from 'src/app/core/state/menu/menu.store';
import { MenuItemModel as CMSMenuItem } from 'src/app/shared/models/cms.model';
import { MenuItemModel, MenuTab } from 'src/app/shared/models/menu.model';
import { APIType } from '../../shared/models/api.model';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly menuStore: MenuStore,
    private readonly languageService: LanguageService
  ) {}

  changeTab(tab: MenuTab): void {
    this.menuStore.changeTab(tab);
  }

  getQuicklinks(): Observable<CMSMenuItem[]> {
    const endpoint = this.languageService.selectedLanguage.language === 'en' ? 'menus/14' : 'menus/2';

    return this.apiService.get(APIType.CMS, endpoint).pipe(
      map(res => {
        const menuItems = [];
        console.log('res je', res);
        res.Menu.forEach(menuItem => {
          menuItems.push(
            new MenuItemModel({
              title: menuItem.title,
              icon: menuItem.icon,
              iconSuffixText: menuItem.icon_suffix,
              isFontAwesomeIcon: menuItem.font_awesome,
              linkURL: menuItem.link,
              functionName: menuItem.function,
              newTab: menuItem.new_tab,
              showWhenLoggedIn: menuItem.show_logged_in,
              showWhenLoggedOut: menuItem.show_logged_out,
              isNew: false,
              isLocked: false,
              showWarningIcon: menuItem.showWarningIcon
            })
          );
        });
        this.menuStore.update({ quickLinks: menuItems });
        return menuItems;
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
