<div [class.error]="dialog.style === notificationStyle.Error" [class.warning]="dialog.style === notificationStyle.Warning" class="dialog">
  <h1 *ngIf="dialog?.title" class="title">{{ dialog.title }}</h1>
  <p *ngIf="dialog?.text" class="text">{{ dialog.text }}</p>

  <div
    (click)="toggleDontShowMeAgain()"
    *ngIf="dialog.recurrence === notificationRecurrence.AlwaysWithDismissOption"
    class="dont-show-me-again"
  >
    <div [class.selected]="dontShowMeAgain" class="checkbox">
      <i *ngIf="dontShowMeAgain" class="fa fa-check"></i>
    </div>
    <span transloco="Don't show again"></span>
  </div>

  <!--
    ***
    ***   Buttons
    ***
  -->
  <div
    *ngIf="dialog?.buttons?.length"
    [class.many-buttons]="dialog?.buttons?.length > 2"
    [class.two-buttons]="dialog?.buttons?.length === 2"
    class="buttons"
  >
    <div
      (click)="handleButtonClick(button)"
      *ngFor="let button of dialog.buttons"
      [class.flat-button]="button.style === 'flat'"
      [class.primary-button]="button.style === 'primary'"
      [class.secondary-button]="button.style === 'secondary'"
      [class.tertiary-button]="button.style === 'tertiary'"
      class="button large wider"
    >
      {{ button.label }}
    </div>
  </div>

  <!--
    ***
    ***   Default button if buttons aren't set
    ***
  -->
  <div *ngIf="!dialog?.buttons?.length" class="buttons">
    <div (click)="close()" class="button secondary-button large wider">OK</div>
  </div>
</div>
