import { Injectable } from '@angular/core';
import { CityModel, CountryModel, StateModel, TelephoneExtensionModel } from 'src/app/shared/models/location.model';

import { Store, StoreConfig } from '@datorama/akita';

import { CurrencyModel, RegistrationState } from 'src/app/shared/models/registration.model';

function createInitialState(): RegistrationState {
  return {
    activationOption: 'none',
    registerOptionTriggered: false,
    verificationInProgress: false,
    registerConfirmation: false,
    isActivated: true,
    resendSucceeded: false,
    activationErrorMessage: undefined,
    resendErrorMessage: undefined,
    activationUsername: undefined,
    blinkingServiceEnabled: true,
    currencies: undefined,
    countries: undefined,
    statesOfResidence: undefined,
    cities: undefined,
    telephoneExtensions: undefined,
    newAgentForm: {
      title: undefined,
      formContent: undefined
    },
    credentials: {
      user: undefined,
      pass: undefined
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'registration' })
export class RegistrationStore extends Store<RegistrationState> {
  constructor() {
    super(createInitialState());
  }

  updateActivationOption(activationOption: string): void {
    this.update({ activationOption });
  }

  updateRegisterOptionTriggered(registerOptionTriggered: boolean): void {
    this.update({ registerOptionTriggered });
  }

  updateVerificationInProgress(verificationInProgress: boolean): void {
    this.update({ verificationInProgress });
  }

  updateRegisterConfirmation(registerConfirmation: boolean): void {
    this.update({ registerConfirmation });
  }

  updateIsActivated(isActivated: boolean): void {
    this.update({ isActivated });
  }

  updateResendSucceeded(resendSucceeded: boolean): void {
    this.update({ resendSucceeded });
  }

  updateActivationErrorMessage(activationErrorMessage: string): void {
    this.update({ activationErrorMessage });
  }

  updateResendErrorMessage(resendErrorMessage: string): void {
    this.update({ resendErrorMessage });
  }

  updateActivationUsername(activationUsername: string): void {
    this.update({ activationUsername });
  }

  updateBlinkingServiceEnabled(blinkingServiceEnabled: boolean): void {
    this.update({ blinkingServiceEnabled });
  }

  updateCurrencies(currencies: CurrencyModel[]): void {
    this.update({ currencies });
  }

  updateCountries(countries: CountryModel[]): void {
    this.update({ countries });
  }

  updateStates(statesOfResidence: StateModel[]): void {
    this.update({ statesOfResidence });
  }

  updateCities(cities: CityModel[]): void {
    this.update({ cities });
  }

  updateTelephoneExtensions(telephoneExtensions: TelephoneExtensionModel[]): void {
    this.update({ telephoneExtensions });
  }

  updateNewAgentForm(newAgentData: any): void {
    this.update({ newAgentForm: newAgentData });
  }

  updateCredentials(credentialsData: any): void {
    this.update({ credentials: credentialsData });
  }

  clearCredentials(): void {
    this.update({
      credentials: {
        user: undefined,
        pass: undefined
      }
    });
  }
}
