import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { fadeInOutBy } from 'src/app/shared/animations';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { MarketModel, MatchModel, SelectionModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-multi-line-event-with-selector',
  templateUrl: './multi-line-event-with-selector.component.html',
  styleUrls: ['./multi-line-event-with-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutBy()]
})
export class MultiLineEventWithSelectorComponent implements OnInit, OnChanges {
  @Input() event: MatchModel;
  @Input() markets: SelectionModel[];
  @Input() isAvailable: boolean = true;
  @Output() readonly toggleSelector = new EventEmitter();
  displayedMarkets$: BehaviorSubject<SelectionModel[]>;
  constructor(
    readonly statisticsQuery: StatisticsQuery,
    readonly statisticsService: StatisticsService,
    private readonly matchService: MatchService,
  ) {
      this.displayedMarkets$ = new BehaviorSubject(this.markets);
  }

  ngOnInit() {
    this.displayedMarkets$.next(this.markets.filter(market => !!this.event.odds.find(odd => odd?.selectionId === market?.id)))
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.displayedMarkets$.next(this.markets.filter(market =>  !!this.event.odds.find(odd => odd?.selectionId === market?.id)))
  }

  toggleCheckbox(): void {
    this.toggleSelector.emit();
  }

  getOdds(selectedMarket: MarketModel): OddModel[] {
    return selectedMarket.selections.map(m =>
      this.event.odds.find(odd => odd.marketTypeId === selectedMarket.typeId && odd.selectionId === m.id)
    );
  }

  oddTrackBy(index: number, odd: OddModel | undefined): number {
    return odd ? odd.id : index;
  }

  navigateToMatch(): void {
    this.matchService.navigateToMatch(this.event.id, this.event.name);
  }

  openStatistics(): void {
    this.statisticsService.statisticsBetRadar(this.event.externalId);
  }
}
