<div class="virtuals-container">
  <div class="virtuals-wrapper" [@fadeInByStaggered]="{value: '', params: {duration: 150}}"
    *ngIf="(menuQuery.selectedTab$ | async) === menuTab.Virtuals">
    <div *ngFor="let virtual of virtuals; last as last; trackBy: virtualsTrackBy" class="virtual staggered"
      [ngClass]="{last: last, disabled: !virtual.link}" (click)="applicationService.navigateTo(virtual.link)">
      <div class="left-elements">
        <div class="icon">
          <i *ngIf="virtual.icon" class="fa fa-{{virtual.icon}} virtual-icon" aria-hidden="true"></i>
          <div *ngIf="!virtual.icon" class="masked virtual-icon"
            appDynamicSvgMask="images/quicklink-icons/quicklinks-virtuals"></div>
        </div>
        <span class="virtual-name">{{ virtual.title }}</span>
      </div>
      <div class="right-elements">
        <span class="virtual-badge-icon">
          <i class="fa fa-angle-right" aria-hidden="true"></i>
        </span>
      </div>
      <ng-container *ngIf="showRibbon$ | async">
        <div class="ribbon" *ngIf="virtual.isNew" transloco="NEW!"></div>
      </ng-container>
    </div>
  </div>
</div>
