export interface VictoryAnalyticsEventDTO {
  UserID?: string;
  Username?: string;
  ConnectionID?: string;
  SessionID?: string;
  Event: AnalyticsEventDetails;
  Page: AnalyticsPageDetails;
  Metadata?: string;
}

export interface GoogleAnalyticsEventDTO {
  Event: AnalyticsEventDetails;
  LoggedIn: boolean;
}

export interface GoogleAnalyticsPageViewDTO {
  Page: AnalyticsPageDetails;
  LoggedIn: boolean;
}

export interface AnalyticsEventDetails {
  Action: AnalyticsEventAction | string;
  UserId?: string;
  Category?: string;
  Label?: string;
  Value?: number;
}

export interface AnalyticsWsMessage {
  data: any;
  event: string;
}

export interface AnalyticsPageDetails {
  Title: string;
  Location: string;
  Path: string;
}

export enum AnalyticsEventAction {
  PageView = 'Page View',
  Click = 'Click',
  Error = 'Error',
  Scroll = 'ScrollY %',
  InputFocus = 'Input Focus',
  InputBlur = 'Input Blur',
  FormSubmit = 'Form Submit',
  DefaultStakeApplied = 'Default Stake Applied'
}

export interface AnalyticsEventDto {
  data: any;
  metadata: any;
}

export interface SessionDto {
  ConnectionID: string;

  ID?: string;
  UserID?: string;
  Username?: string;
  DeviceType?: string;
  MobileOS?: string;
  Browser?: string;
  BrowserUID?: string;
  UserAgent?: string;
  Referrer?: string;
  UTM?: string;
}

export interface SportradarAnaylyticsDto {
  event: string;
  payload: {
    action: string;
    userId?: string;
  };
}
