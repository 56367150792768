export enum APIType {
  BetRadarResults,
  BetRadarStatistics,
  CMS,
  ThirdParty,
  Guardian,
  Local,
  Platform,
  Sportsbook,
  SportsbookFeed,
  GraphQL,
  Analytics
}

export class APISettings {
  contentType?: string;
  noAuthToken?: boolean;
  forceAuthToken?: string;
  inBehalfOf?: string;
  thirdPartyBaseUrl?: string;
  skipLocale?: boolean;

  constructor(init?: Partial<APISettings>) {
    Object.assign(this, init);
  }
}

export class APIResult<T> {
  success = false;
  data: T;
  error: string;
}
