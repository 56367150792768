import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BetSearchService } from 'src/app/core/services/bet-search.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { BetSearchModel, BetSearchParams, BetSearchResultsTotal } from 'src/app/shared/models/bet-search.model';
import { BetFinalState } from 'src/app/shared/models/coupon-details.model';

@Component({
  selector: 'account-bet-search-results',
  templateUrl: './bet-search-results.component.html',
  styleUrls: ['./bet-search-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetSearchResultsComponent implements OnChanges {
  @Input() params: BetSearchParams;
  @Input() bets: BetSearchModel[];
  @Input() isVirtuals: boolean;

  totals: BetSearchResultsTotal = { stake: 0, winnings: 0 };
  betFinalState = BetFinalState;

  constructor(public accountQuery: AccountQuery, private readonly betSearchService: BetSearchService, private readonly router: Router) {}

  resultsTrackBy(index: number, result: BetSearchModel): string {
    return result.couponCode;
  }

  navigateTo(result: BetSearchModel): void {
    // With 'data.BetFinalState !== 0' we are block the navigation to a coupon what is in evaluation state, because for that kind of coupon
    // we can't request details (the coupon properties would be null).
    if (result.betFinalState === BetFinalState.InEvaluation) {
      return;
    }

    let couponCode = result.couponCode;

    if (result.externalUserId !== this.accountQuery.userData.id.toString()) {
      couponCode = [couponCode, result.externalUserId].join(',');
    }

    this.router.navigateByUrl(`/account/bet-search/coupon-details/${couponCode}`);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const betsChange = changes.bets;
    const paramsChange = changes.params;

    if (betsChange && betsChange.currentValue) {
      this.totals.stake = this.bets.reduce((previousValue: number, currentValue: BetSearchModel) => previousValue + currentValue.stake, 0);
      this.totals.winnings = this.bets.reduce((previousValue: number, currentValue: BetSearchModel) => previousValue + currentValue.won, 0);
    }

    if (paramsChange && paramsChange.currentValue) {
      this.betSearchService.searchBets(this.params, this.isVirtuals).subscribe();
    }
  }
}
