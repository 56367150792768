<!--
    ***
    ***     FAB
    ***
-->
<div
  #fab
  class="promo-fab"
  [class.dismiss]="isDismissing"
  [class.animate]="stickAnimation$ | async"
  [class.push-dismiss]="pushDownAnimation$ | async"
>
  <img src="{{ banner.fabImage.url }}" alt="Popup Banner Fab" (click)="handleClick()" />
</div>

<!--
    ***
    ***     Dismiss area
    ***
-->
<div class="dismiss-wrapper" [class.show-up]="isDragging" [class.dismiss]="isDismissing">
  <div #dimissCircle class="dismiss-circle" [class.scale-up]="(dismissAnimation$ | async) && isDragging">
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.707 6.707a1 1 0 00-1.414-1.414L12 10.586 6.707 5.293a1 1 0 00-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 101.414 1.414L12 13.414l5.293 5.293a1 1 0 001.414-1.414L13.414 12l5.293-5.293z"
        fill="#fff"
      />
    </svg>
  </div>
</div>
