import {Injectable} from '@angular/core';
import MD5 from 'crypto-js/md5';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  setValueToDataLayer(key: string, value: string): void {
    const global = window as any;
    global.dataLayer = global.dataLayer || [];
    const object = {
      event: `${key}`
    };
    object[key] = value;
    global.dataLayer.push(object);
  }

  pushToDataLayer(category: string, action: string, label?: string): void {
    const global = window as any;
    global.dataLayer = global.dataLayer || [];
    const object: {event: 'G_Event', eventCategory: string, eventAction: string, eventLabel?: string} = {
      event: 'G_Event',
      eventCategory: category,
      eventAction: action,
    };
    if(label) {
      object.eventLabel = label;
    }
    global.dataLayer.push(object);
  }

  toMD5Hash(data: any): string {
    return MD5(data).toString();
  }
}
