import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { BottomSheetState } from '../../../shared/models/bottom-sheet';

function createInitialState(): BottomSheetState {
  return {
    visible: false,
    title: undefined,
    content: undefined,
    installPWA: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bottomSheet' })
export class BottomSheetStore extends Store<BottomSheetState> {
  constructor() {
    super(createInitialState());
  }

  updateUI(state: Partial<BottomSheetState>): void {
    this.update(currentState => ({ ...currentState, ...state }));
  }
}
