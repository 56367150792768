<div class="phone-verification-wrapper">
  <form [formGroup]="verificationForm" class="verification-form">
    <div class="header">
      <div class="header-top" *ngIf="mobileNumber">
        <span transloco="An SMS has been sent to"></span>
        <span>{{ mobileNumber }}</span>
      </div>
      <div class="header-top" *ngIf="!mobileNumber">
        <span transloco="An SMS has been sent to the number registered with this username."></span>
      </div>
      <div class="header-bottom">
        <span transloco="Input the number you received in the space below."></span>
      </div>
    </div>
    <div [ngClass]="{ invalid: (invalidCode$ | async) || (limitExceeded$ | async) }" class="content">
      <div class="content-top">
        <div>
          <input
            #verificationCode
            (keyup)="onKey($event)"
            (paste)="pasteFromClipboard($event)"
            data-elem="1"
            formControlName="char1"
            maxlength="1"
            type="text"
          />
          <input
            #verificationCode
            (keyup)="onKey($event)"
            (paste)="pasteFromClipboard($event)"
            data-elem="2"
            formControlName="char2"
            maxlength="1"
            type="text"
          />
          <input
            #verificationCode
            (keyup)="onKey($event)"
            (paste)="pasteFromClipboard($event)"
            data-elem="3"
            formControlName="char3"
            maxlength="1"
            type="text"
          />
          <span class="separator" transloco="–"></span>
          <input
            #verificationCode
            (keyup)="onKey($event)"
            (paste)="pasteFromClipboard($event)"
            data-elem="4"
            formControlName="char4"
            maxlength="1"
            type="text"
          />
          <input
            #verificationCode
            (keyup)="onKey($event)"
            (paste)="pasteFromClipboard($event)"
            data-elem="5"
            formControlName="char5"
            maxlength="1"
            type="text"
          />
          <input
            #verificationCode
            (keyup)="onKey($event)"
            (paste)="pasteFromClipboard($event)"
            data-elem="6"
            formControlName="char6"
            maxlength="1"
            type="text"
          />

          <button (click)="resendCode()" [disabled]="(resendCodeTimer$ | async) > 0 && (limitExceeded$ | async)" class="btn resend-code">
            <i class="fa fa-refresh"></i>
            <span class="btn-text" transloco="Resend Code"
              ><span *ngIf="(resendCodeTimer$ | async) > 0 && (limitExceeded$ | async) === false">
                ({{ resendCodeTimer$ | async }}s)</span
              ></span
            >
          </button>
        </div>
        <div *ngIf="invalidCode$ | async" class="invalid-text">
          <span transloco="The inserted verification code is incorrect. Please try again."></span>
        </div>
        <div *ngIf="limitExceeded$ | async" class="invalid-text">
          <span transloco="Exceeded the limit of One Time Passwords for the day."></span>
          <span transloco="Please try again later."></span>
        </div>
      </div>
      <div class="content-bottom" *ngIf="showDNDNotice">
        <span
          transloco="Did not receive your pass code? Text “ALLOW” to 2442 to disable DND on your line. You may also text “12” to 2442 to activate partial DND on your line."
        ></span>
      </div>
    </div>
  </form>
</div>
