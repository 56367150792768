import { Component, Input } from '@angular/core';
import { CMSNotificationsService } from 'src/app/core/services/cms-notifications.service';
import {
  CMSNotification,
  NotificationButton,
  NotificationButtonAction,
  NotificationEventType,
  NotificationRecurrence,
  NotificationStyle
} from 'src/app/shared/models/cms-notification';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent {
  @Input() dialog: CMSNotification;

  hasDontShowMeAgain = false;
  dontShowMeAgain = false;

  notificationRecurrence = NotificationRecurrence;
  notificationStyle = NotificationStyle;

  constructor(private readonly notificationsService: CMSNotificationsService) {}

  handleButtonClick(button: NotificationButton): void {
    if (button.action === NotificationButtonAction.Redirect && button.redirectURL) {
      window.open(button.redirectURL);
    }

    if (button.action === NotificationButtonAction.InCodeAction && button.inCodeAction) {
      this.notificationsService.emitEvent({
        type: NotificationEventType.Action,
        name: button.inCodeAction
      });
    }

    this.close();
  }

  close(): void {
    this.notificationsService.remove(this.dialog, this.dontShowMeAgain);
  }

  toggleDontShowMeAgain(): void {
    this.dontShowMeAgain = !this.dontShowMeAgain;
  }
}
