import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AccountPaymentService } from 'src/app/core/services/account/account-payment.service';
import { AnalyticsTrackingService } from 'src/app/core/services/analytics/analytics-tracking.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { fadeIn } from 'src/app/shared/animations';
import { brandInfo } from 'src/brand-info';

@Component({
  selector: 'app-payment-completed',
  templateUrl: './payment-completed.component.html',
  styleUrls: ['./payment-completed.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentCompletedComponent implements OnInit, OnDestroy {
  completeType: string;
  transactionId: number;

  brandInfo = brandInfo;

  orderReference$ = new BehaviorSubject<string>(undefined);
  status$ = new BehaviorSubject<string>(undefined);
  cardHolder$ = new BehaviorSubject<string>(undefined);
  address$ = new BehaviorSubject<string>(undefined);
  currency$ = new BehaviorSubject<string>(undefined);
  amountCharged$ = new BehaviorSubject<string>(undefined);
  paymentMethod$ = new BehaviorSubject<string>(undefined);
  date$ = new BehaviorSubject<string>(undefined);
  bankCode$ = new BehaviorSubject<string>(undefined);
  errorMessage$ = new BehaviorSubject<string>(undefined);
  isParentTopWindow: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly accountPaymentQuery: AccountPaymentQuery,
    readonly accountQuery: AccountQuery,
    private readonly applicationService: ApplicationService,
    private readonly accountPaymentService: AccountPaymentService,
    private readonly appConfigService: AppConfigService,
    private readonly dataLayerService: DataLayerService,
    private readonly route: ActivatedRoute,
    private readonly analyticsTrackingService: AnalyticsTrackingService
  ) {
    this.applicationService.updateUI({ showNavBar: false });
  }

  ngOnInit(): void {
    this.isParentTopWindow = window.parent === window.top;
    this.route.queryParams.subscribe(params => {
      this.completeType = params.type;
      this.transactionId = params.id;

      if (this.transactionId) {
        this.getTransationInfo();
      }
    });
  }
  continuePlaying(): void {
    if (this.applicationService.isInIFrame()) {
      if (!this.isParentTopWindow) {
        window.parent.postMessage({ type: 'close-deposit' }, '*');
      } else {
        window.parent.location.href = '/';
      }
    }
  }
  tryDepositAgain(): void {
    if (this.applicationService.isInIFrame()) {
      if (!this.isParentTopWindow) {
        window.parent.postMessage({ type: 'try-deposit-again' }, '*');
        return;
      }
      window.parent.postMessage({ type: 'try-deposit-again' }, '*');
    }
  }
  getTransationInfo(): void {
    this.accountPaymentService
      .queryAllSecureDeposit(this.transactionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        // tslint:disable-next-line: cyclomatic-complexity
        response => {
          if (response.Result) {
            const transactionInfo = response.Result;

            this.orderReference$.next(transactionInfo.PurchaseId ? transactionInfo.PurchaseId : '');
            this.status$.next(transactionInfo.Status ? transactionInfo.Status.toLowerCase() : '');
            this.cardHolder$.next(transactionInfo.CardHolderName ? transactionInfo.CardHolderName : '');
            this.address$.next(
              transactionInfo.Address && transactionInfo.City && transactionInfo.Country
                ? `${transactionInfo.Address}, ${transactionInfo.City}, ${transactionInfo.Country}`
                : ''
            );
            this.currency$.next(transactionInfo.Currency ? transactionInfo.Currency : '');
            this.amountCharged$.next(transactionInfo.Amount ? transactionInfo.Amount : '');
            this.paymentMethod$.next(
              transactionInfo.CardDetails ? `${transactionInfo.CardDetails.Type} **** ${transactionInfo.CardDetails.LastFourDigits}` : ''
            );
            this.date$.next(transactionInfo.LastUpdate ? transactionInfo.LastUpdate : '');
            this.bankCode$.next(transactionInfo.AuthorizationCode ? transactionInfo.AuthorizationCode : '');
            this.errorMessage$.next(transactionInfo.ErrorMessage ? transactionInfo.ErrorMessage : '');
            if (this.appConfigService.get('enableDataLayer') && !transactionInfo.ErrorMessage) {
              this.dataLayerService.pushToDataLayer('deposit payment', 'successful');
            } else if (this.appConfigService.get('enableDataLayer') && transactionInfo.ErrorMessage) {
              this.dataLayerService.pushToDataLayer('deposit payment', 'failed');
            }

            if (!transactionInfo.ErrorMessage) {
              this.analyticsTrackingService.logMetaEvent('Purchase', { currency: 'RSD', value: transactionInfo.Amount });

              this.analyticsTrackingService.logGTMEvent({ ecommerce: null }, false);
              this.analyticsTrackingService.logGTMEvent(
                {
                  event: 'purchase',
                  ecommerce: {
                    transaction_id: transactionInfo.PurchaseId, // Unique Victory transaction ID
                    affiliation: 'Victory.rs',
                    value: transactionInfo.Amount,
                    tax: 0,
                    shipping: 0,
                    currency: 'RSD',
                    items: [
                      {
                        item_id: 'Card', // Fill with IPS or Card or Vcash or Uplatnica
                        item_name: 'Deposit',
                        affiliation: 'Victory.rs',
                        currency: 'RSD',
                        quantity: 1
                      }
                    ]
                  }
                },
                true
              );
            }
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
