import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatchService } from 'src/app/core/services/match.service';
import { SportService } from 'src/app/core/services/sport.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { SportId } from 'src/app/shared/models/sport-id.model';

import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-single-line-event',
  templateUrl: './single-line-event.component.html',
  styleUrls: ['./single-line-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleLineEventComponent implements OnChanges, OnInit, OnDestroy {
  parsedEvent$: BehaviorSubject<MatchModel> = new BehaviorSubject(undefined);
  displayMatchMarketGroup$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  destroy$: Subject<boolean> = new Subject();

  @Input() event: MatchModel;
  /**
   * This only applies for event with no selector and when showDetails is true
   */
  @Input() sportId: number;
  /**
   * This only applies for event with no selector and when showDetails is true
   */
  @Input() showDate = true;
  /**
   * This only applies for event with no selector and when showDetails is true
   */
  @Input() showBorder: boolean = true;
  /**
   * This only applies for event with no selector
   */
  @Input() showDetails: boolean = true;

  @Input() editing: boolean = false;

  /**
   * This only applies for event with no selector
   */
  @Input() first?: boolean;

  @Input() last?: boolean;

  @Input() isAvailable: boolean = true;

  @Input() withSelector: boolean = false;

  @Input() showTournament: boolean = true;

  @Input() selectedMarket: MarketModel = undefined;

  @Input() hasTeamAway: boolean = true;

  @Input() withMarketGroup: boolean;

  @Input() specials?: boolean;

  /**
   * This only applies for event with selector
   */
  @Output() readonly toggleSelector = new EventEmitter();

  @Output() readonly displayMarketGroup = new EventEmitter<boolean>();

  @Output() scrollToMatchElement = new EventEmitter();

  constructor(
    private readonly sportService: SportService,
    readonly statisticsQuery: StatisticsQuery,
    private readonly matchService: MatchService,
    private readonly router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.parsedEvent$.next(
      this.selectedMarket && this.sportId === SportId.Soccer && this.selectedMarket.selections.length <= 4
        ? new MatchModel({
            ...this.event,
            odds: this.selectedMarket.selections.map(m =>
              this.event.odds.find(odd => odd.marketTypeId === this.selectedMarket.typeId && odd.selectionId === m.id)
            )
          })
        : this.event
    );
  }

  ngOnInit(): void {
    this.sportService.activeMatchIdMarketGroup$.pipe(takeUntil(this.destroy$)).subscribe(matchId => {
      this.displayMatchMarketGroup$.next(matchId === this.event.id);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  toggleCheckbox(): void {
    this.toggleSelector.emit();
  }

  toggleMatchMarketGroup(): void {
    const displayMarketGroup = !this.displayMatchMarketGroup$.getValue();
    this.displayMatchMarketGroup$.next(displayMarketGroup);
    this.displayMarketGroup.emit(displayMarketGroup);
    this.sportService.activeMatchIdMarketGroup$.next(displayMarketGroup ? this.event.id : undefined);
    this.scrollToMatchElement.emit();
  }

  shouldMoreButtonForSportBeHidden(): boolean {
    const isCouponPage = this.router.url.includes('coupon');
    const isLastMinutePage = this.router.url.includes('last-minute');
    return environment.appConfigDefaults.sports.marketGroups[this.sportId]?.[0]?.marketIds?.length < 2 || isCouponPage || isLastMinutePage;
  }

  get isOnHome(): boolean {
    return this.router.url === '/';
  }
}
