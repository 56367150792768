import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { kebabCase } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ProceedToEventsService } from 'src/app/core/services/proceed-to-events.service';
import { SportService } from 'src/app/core/services/sport.service';
import { SportsSelectionService } from 'src/app/core/services/sports-selection.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { slideUp } from 'src/app/shared/animations';
import { CategoryModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-proceed-bar',
  templateUrl: './proceed-bar.component.html',
  styleUrls: ['./proceed-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideUp()]
})
export class ProceedBarComponent implements OnDestroy {
  @Input() depth: number;
  @Input() eventType: string;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public sportQuery: SportQuery,
    private readonly sportService: SportService,
    private readonly sportSelectionService: SportsSelectionService,
    private readonly proceedToEventsService: ProceedToEventsService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly couponQuery: CouponQuery
  ) {}

  betSlipBadgeCount$: Observable<number> = this.couponQuery.couponData$.pipe(
    map(coupon => (coupon && coupon.Odds && coupon.Odds.length) || 0)
  );

  proceed(): void {
    if (this.proceedToEventsService.proceed(this.depth)) {
      window.scrollTo(0, 0);
    } else {
      if (this.eventType === 'prematch') {
        this.sportQuery.allCompetitionByCountrySelectedElements$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (!data || !data.length) {
            return;
          }
          const selectedSport = this.sportQuery.sportsList.filter(sport => sport.id === data[0].sportId)[0];
          this.sportService.updateEventSelection({ eventSelectionDepth: 2, selectedSport });

          let urlString = '';

          data.forEach((category: CategoryModel, index: number) => {
            if (index + 1 === data.length) {
              urlString += `${category.id}`;
            } else {
              urlString += `${category.id}-`;
            }
          });

          this.router.navigateByUrl(`/sports/${kebabCase(this.sportQuery.selectedSport.name)}/leagues/${this.eventType}/${urlString}`);
        });
      } else if (this.eventType === 'special') {
        if (this.sportQuery.selectedQuicklink.id === 6) {
          this.sportQuery.specialSportsSelectedElements$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            if (!data || !data.length) {
              return;
            }

            const selectedSport = this.sportQuery.specialSports.filter(sport => sport.menuUniqueId === data[0].menuUniqueId)[0];
            this.sportService.updateEventSelection({ selectedSport });

            let urlString = '';

            data.forEach((category: CategoryModel, index: number) => {
              if (index + 1 === data.length) {
                urlString += `${category.id}`;
              } else {
                urlString += `${category.id}-`;
              }
            });

            this.router.navigateByUrl(`/sports/${kebabCase(this.sportQuery.selectedSport.name)}/leagues/${this.eventType}/${urlString}`);
          });
        } else if (this.sportQuery.selectedQuicklink.id === 7) {
          this.sportQuery.goalscorerSportSelectedElements$.pipe(takeUntil(this.destroy$)).subscribe(data => {
            if (!data || !data.length) {
              return;
            }

            const selectedSport = this.sportQuery.goalscorerSport.filter(sport => sport.menuUniqueId === data[0].menuUniqueId)[0];
            this.sportService.updateEventSelection({ selectedSport });

            let urlString = '';

            data.forEach((category: CategoryModel, index: number) => {
              if (index + 1 === data.length) {
                urlString += `${category.id}`;
              } else {
                urlString += `${category.id}-`;
              }
            });

            this.router.navigateByUrl(`/sports/${kebabCase(this.sportQuery.selectedSport.name)}/leagues/${this.eventType}/${urlString}`);
          });
        }
      } else if (this.eventType === 'outright') {
        this.sportQuery.outrightsSelectedElements$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (!data || !data.length) {
            return;
          }

          const selectedSport = this.sportQuery.outrights.filter(sport => sport.id === data[0].sportId)[0];
          this.sportService.updateEventSelection({ eventSelectionDepth: 2, selectedSport });

          let urlString = '';

          data.forEach((category: CategoryModel, index: number) => {
            if (index + 1 === data.length) {
              urlString += `${category.id}`;
            } else {
              urlString += `${category.id}-`;
            }
          });

          this.router.navigateByUrl(`/sports/${kebabCase(this.sportQuery.selectedSport.name)}/leagues/${this.eventType}/${urlString}`);
        });
      }
    }
  }

  filter(): void {
    this.sportService.updateEventSelection({ filterToDepthFour: true });
    this.sportService.updateEventSelection({
      visibleCardsAtDepthFour: this.sportQuery.hasPlayersData ? this.filterOnPlayersData() : this.filterOnSelectedPrematch()
    });
  }

  handleCancelButtonClick(): void {
    // this.sportQuery.selectedQuicklink.id ===
    const isDeepSelectionFilter = this.router.url.split('/').length > 3;

    if (!isDeepSelectionFilter) {
      this.sportSelectionService.deselectAllCountries();
    }

    this.sportSelectionService.deselectTournaments();
    this.sportSelectionService.deselectOutrights();

    this.sportService.refreshingList$.next(true);
    setTimeout(() => {
      this.sportService.refreshingList$.next(false);
    }, 0);
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private filterOnPlayersData(): boolean[] {
    return this.sportQuery.playersData.map(tournament =>
      tournament.data.some(match => {
        // Checking Home Team players
        const homeCheck = match[0].data.some(homeTeamData => {
          if (homeTeamData.selectedInView) {
            return true;
          }
        });
        if (homeCheck) {
          return true;
        } else {
          // Checking Away Team players
          return match[1].data.some(homeTeamData => {
            if (homeTeamData.selectedInView) {
              return true;
            }
          });
        }
      })
    );
  }

  private filterOnSelectedPrematch(): boolean[] {
    return this.sportQuery.selectedPrematch.map(element =>
      element.matches.some(match => {
        if (match.selectedInView) {
          return true;
        }
      })
    );
  }
}
