import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountQuery } from '../../../../core/state/account/account.query';

import { translate } from '@ngneat/transloco';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AnalyticsService } from 'src/app/core/services/analytics/analytics.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CMSNotificationsService } from 'src/app/core/services/cms-notifications.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { InsufficientBalanceService } from 'src/app/core/services/insufficient-balance.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { PwaService } from 'src/app/core/services/pwa.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { CasinoService } from 'src/app/modules/casino/casino.service';
import { fadeIn, slideInToggle } from 'src/app/shared/animations';
import { LoginResponseModel, VerifyAccountType } from 'src/app/shared/models/account.model';
import { AnalyticsEventAction } from 'src/app/shared/models/analytics.model';
import { ButtonType } from 'src/app/shared/models/button.model';
import { NotificationEventType, NotificationTrigger } from 'src/app/shared/models/cms-notification';
import { InputType } from 'src/app/shared/models/input.model';
import { TelephoneExtensionModel } from 'src/app/shared/models/location.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import { brandInfo } from 'src/brand-info';
import { HelpService } from '../../../../core/services/help.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeIn(), slideInToggle()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() loginSectionOnly: boolean = false;
  @Input() urlAfterLogin: string;
  @Input() presetFunctionAfterLogin: string;
  @Input() inForgotPassword: boolean = false;

  inputType: typeof InputType = InputType;
  buttonType = ButtonType;
  passwordRecoveryUserName = new FormControl('', Validators.required);
  passwordRecoveryOutcome$: BehaviorSubject<'success' | 'backend-error' | 'wrong-username' | 'account-not-activated'> = new BehaviorSubject(
    undefined
  );
  loginForm = new FormGroup({
    userName: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });
  loginError$ = new BehaviorSubject(false);
  errorMessage$ = new BehaviorSubject(undefined);
  brandInfo = brandInfo;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loginInProgress$ = new BehaviorSubject(false);
  quickRegistration = this.appConfig.get('registration').registrationType === 'quick';
  loginPrefix = this.appConfig.get('account').loginPrefix;
  limitExceeded: boolean = false;
  unregisteredUser: boolean = false;

  verificationInProgress$ = new BehaviorSubject(false);

  showPassword$ = new BehaviorSubject<boolean>(false);

  casinoGameId: string;

  // forgotPasswordSMSForm: FormGroup;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly applicationService: ApplicationService,
    readonly applicationQuery: ApplicationQuery,
    readonly registrationQuery: RegistrationQuery,
    readonly helpService: HelpService,
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly accountService: AccountService,
    private readonly analyticsService: AnalyticsService,
    private readonly appConfig: AppConfigService,
    private readonly dataLayerService: DataLayerService,
    private readonly insufficientBalanceService: InsufficientBalanceService,
    private readonly pwaService: PwaService,
    private readonly notificationsService: CMSNotificationsService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly casinoService: CasinoService
  ) { }

  ngOnInit(): void {
    this.accountService.updateResetOptionTriggered(false);

    if (this.registrationQuery.activationUsername) {
      this.loginForm.controls.userName.setValue(this.registrationQuery.activationUsername);
      this.registrationService.updateActivationUsername(undefined);
    }

    if (this.appConfig.get('enableOTPbySms')) {
      this.accountService.getResetPasswordOption().subscribe();
    } else {
      this.accountService.updateResetOptionTriggered(true);
    }

    if (this.casinoService.openGameId) {
      this.casinoGameId = this.casinoService.openGameId;
      this.casinoService.openGameId = undefined;
    }

    this.applicationService.updateUI({
      loginState: 'Password Recovery'
    });
  }

  togglePasswordVisibility(): void {
    this.showPassword$.next(!this.showPassword$.value);
  }

  login(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.reportSubmitFormEvent();

    this.loginInProgress$.next(true);
    this.accountService
      .login(
        this.loginPrefix ? `${this.loginPrefix}${this.loginForm.value.userName}` : this.loginForm.value.userName,
        this.loginForm.value.password
      )
      .subscribe(
        (response: LoginResponseModel) => {
          if (!response || !response.success) {
            this.loginError$.next(true);
            this.loginInProgress$.next(false);
            if (response && response.errorMessage) {
              this.errorMessage$.next(response.errorMessage);
            } else {
              this.errorMessage$.next(translate('Login Failed'));
            }

            return;
          }

          this.clearData();
          this.errorMessage$.next(undefined);
          this.loginInProgress$.next(false);
          let KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
          if (!this.applicationQuery.showLogin) {
            if (this.presetFunctionAfterLogin) {
              this.presetFunction(this.presetFunctionAfterLogin);
            } else if (KYCDocumentsStatus === 'documentsNeeded') {
              this.presetFunction('startBlinkingSession');
            } else {
              let redirectUrl = this.urlAfterLogin || '/';
              if (this.casinoGameId) {
                redirectUrl = `${redirectUrl}?casinoGameId=${this.casinoGameId}`;
                this.casinoGameId = undefined;
              }

              this.notificationsService.emitEvent({
                type: NotificationEventType.Trigger,
                name: NotificationTrigger.AfterLogin
              });
              this.router.navigateByUrl(redirectUrl);
              this.pwaService.askToInstallApp();
            }
          } else {
            this.closeLogin();
          }

          if (
            this.accountQuery.userData &&
            this.accountQuery.userData.userStatus !== 'UNCONF' &&
            (!this.accountQuery.verifyAccountState || this.accountQuery.verifyAccountState.type === VerifyAccountType.Verified)
          ) {
            if (response.loginMessages && response.loginMessages.length > 0) {
              this.showLoginMessages(response.loginMessages);
            } else {
              this.notificationService.showSuccessMessage(translate('Login successful'));

              if (KYCDocumentsStatus === 'documentsNeeded' || KYCDocumentsStatus === 'accountNumberNeeded') {
                // load Session Status call after the KYCStatus call and login success
                this.accountService.getBlinkingSessionStatus(this.accountQuery.accessToken).subscribe(() => {
                  // check status again if Session Status is needed
                  KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
                  if (KYCDocumentsStatus === 'verified') {
                    // if blinking verified check balance to load deposit popup
                    this.insufficientBalanceService.checkBalance();
                  }
                });
              } else {
                if (KYCDocumentsStatus === 'verified') {
                  // if blinking verified check balance to load deposit popup
                  this.insufficientBalanceService.checkBalance();
                }
              }
            }
          } else {
            this.accountService.logout().subscribe(() => {
              this.notificationService.showSuccessNotification(
                translate('Please confirm your account through the email which was sent to you.'),
                translate('Account Confirmation')
              );
            });
          }

          if (this.accountQuery.userData && this.accountQuery.userData.userStatus === 'PCHANG') {
            this.router.navigate(['/account/password-reset']);
          }

          if (this.appConfig.get('enableDataLayer')) {
            const hashedUserID = this.dataLayerService.toMD5Hash(this.accountQuery.userData.id);
            this.dataLayerService.setValueToDataLayer('userID', hashedUserID);
            this.dataLayerService.pushToDataLayer('account', 'login');
          }
        },
        error => {
          this.loginError$.next(true);
          this.loginInProgress$.next(false);
          if (error && error.error && error.error.error_description) {
            // updated the errors to replicate the desktop errors
            let errorMessage: string;
            if (error.error.error_description.toLowerCase() === 'invalid credentials') {
              errorMessage = this.quickRegistration
                ? translate('Invalid phone number or password')
                : translate('Invalid username or password');
            } else if (error.error.error_description.toLowerCase() === 'exceeded maximum authentication failures') {
              errorMessage = translate('You have exceeded the maximum number of log in trials. Try again in 10 minutes.');
            } else if (error.error.error_description.toLowerCase() === 'invalid user status') {
              errorMessage = translate('Your access is disabled. Please contact customer support for more information.');
            } else {
              errorMessage = error.error.error_description;
            }

            this.errorMessage$.next(errorMessage);
          } else {
            this.errorMessage$.next(translate('Login Failed'));
          }
        }
      );
  }

  presetFunction(functionName: string): void {
    switch (functionName) {
      case 'startBlinkingSession':
        this.startBlinkingSession();
        break;
      default:
        break;
    }
  }

  startBlinkingSession(): void {
    this.registrationService.getRegisterDetails().subscribe(() => {
      if (this.registrationQuery.blinkingServiceEnabled) {
        this.router.navigate(['/account/verification-choice'], { queryParams: { sessionType: 1 } });
      } else {
        this.router.navigate(['/account/edit-profile']);
      }
    });
  }

  closeAndRegister(): void {
    this.closeLogin();
    this.router.navigate(['/register']);
  }

  closeLogin(): void {
    this.applicationService.updateUI({
      showLogin: false
    });
  }

  clearData(): void {
    this.loginForm.reset();

    this.passwordRecoveryUserName.reset();
    this.loginError$.next(false);
    this.passwordRecoveryOutcome$.next(undefined);
  }

  showLoginMessages(messages: any): void {
    const messagesSettings: NotificationSettings[] = [];

    messages.forEach(message => {
      messagesSettings.push(
        new NotificationSettings({
          allowBackdropClose: true,
          showCloseButton: true,
          confirmButtonText: 'OK',
          contentHtml: message.Contents,
          showConfirmButton: true,
          title: message.Title,
          type: 'info',
          confirmButtonCallback: () => {
            this.accountService.markMessageAsRead(message.Id).subscribe();
          }
        })
      );
    });

    this.notificationService.showQueueNotifications(messagesSettings);
  }

  forgottenPassword(): void {
    this.reportForgottenPasswordSubmit();

    if (!this.passwordRecoveryUserName.invalid) {
      if (this.accountQuery.resetPasswordOption === 'ByEmail') {
        this.accountService
          .forgottenPassword(`${this.loginPrefix}${this.passwordRecoveryUserName.value}`, 'Email')
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.passwordRecoveryOutcome$.next('success');
            },
            error => {
              switch (error.error.ResponseCode) {
                case 20204:
                  this.passwordRecoveryOutcome$.next('wrong-username');
                  break;
                case 20218:
                  this.passwordRecoveryOutcome$.next('account-not-activated');
                  break;
                default:
                  this.passwordRecoveryOutcome$.next('backend-error');
                  break;
              }
            }
          );
      } else if (this.accountQuery.resetPasswordOption === 'BySms') {
        this.accountService
          .forgottenPassword(`${this.loginPrefix}${this.passwordRecoveryUserName.value}`, 'SMS')
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.limitExceeded = false;
              this.unregisteredUser = false;
              this.verificationInProgress$.next(true);
            },
            error => {
              this.limitExceeded = false;
              this.unregisteredUser = false;
              if (error.status === 500) {
                this.passwordRecoveryOutcome$.next('wrong-username');

                if (error.error.ResponseCode === 20805) {
                  this.limitExceeded = true;
                  this.verificationInProgress$.next(true);
                } else if (error.error.ResponseCode === 20204) {
                  this.unregisteredUser = true;
                }
              } else {
                this.passwordRecoveryOutcome$.next('backend-error');
              }
            }
          );
      } else {
        this.accountService
          .forgottenPassword(`${this.loginPrefix}${this.passwordRecoveryUserName.value}`)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.passwordRecoveryOutcome$.next('success');
            },
            error => {
              switch (error.error.ResponseCode) {
                case 20204:
                  this.passwordRecoveryOutcome$.next('wrong-username');
                  break;
                case 20218:
                  this.passwordRecoveryOutcome$.next('account-not-activated');
                  break;
                default:
                  this.passwordRecoveryOutcome$.next('backend-error');
                  break;
              }
            }
          );
      }
    }
  }

  restartRecoveryProcess(): void {
    this.passwordRecoveryOutcome$.next(undefined);
  }

  ngOnDestroy(): void {
    this.closeLogin();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  trackByTelExt(index: number, item: TelephoneExtensionModel): string {
    return item.countryCode;
  }

  loginMobileNumberCleanup(): void {
    if (this.quickRegistration && this.loginForm.controls.userName.value) {
      this.loginForm.controls.userName.setValue(this.accountService.mobileNumberCleanup(this.loginForm.controls.userName.value));
    }
  }

  passwordResetMobileNumberCleanup(): void {
    if (this.quickRegistration && this.passwordRecoveryUserName.value) {
      this.passwordRecoveryUserName.setValue(this.accountService.mobileNumberCleanup(this.passwordRecoveryUserName.value));
    }
  }

  private reportSubmitFormEvent(): void {
    this.analyticsService.sendEvent({
      Action: AnalyticsEventAction.FormSubmit,
      Category: 'Login Form'
    });
  }

  private reportForgottenPasswordSubmit(): void {
    this.analyticsService.sendEvent({
      Action: AnalyticsEventAction.FormSubmit,
      Category: 'Password Recovery Form'
    });
  }
}
