/* tslint:disable */
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { filter } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(
    private readonly accountService: AccountService,
    private readonly localStorage: LocalStorageService,
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly appConfig: AppConfigService
  ) {}

  init(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const redirect = this.localStorage.retrieve('redirect');

      if (redirect && this.accountQuery.accessToken) {
        this.handleTokenReceived(this.accountQuery.accessToken);
      }
    });

    this.route.queryParams.subscribe(this.handleQueryParams.bind(this));
  }

  public handleUnauthorized = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('redirect')) {
      this.handleUnauthenticated(urlSearchParams.get('redirect'));
    }
  };

  private handleQueryParams(param: any): void {
    if (param.redirect) {
      this.handleRedirect(param.redirect);
    }
  }

  private handleRedirect(redirect: string): void {
    const whiteListedUrls: string[] = this.appConfig.get('redirectWhitelist');
    const isWhitelistedUrl = whiteListedUrls.find(item => redirect.includes(item));
    if (!isWhitelistedUrl) {
      return;
    }
    this.accountQuery?.accessToken ? this.handleAuthenticated(redirect) : this.handleUnauthenticated(redirect);
  }

  private handleTokenReceived = (token: string): void => {
    const redirect = this.localStorage.retrieve('redirect');

    if (token && redirect && !window?.location?.href?.includes('register')) {
      this.handleAuthenticated(redirect);
    }
  };

  private handleUnauthenticated = (redirect: string): void => {
    this.storeRedirect(redirect);
    this.accountService.openLogin();
    this.accountQuery.accessToken$.subscribe(this.handleTokenReceived);
  };

  private handleAuthenticated = (redirect: string): void => {
    const walletId = this.appConfig.get('walletId');
    const userId = this.accountQuery.userData.id;
    this.accountService.getUserData(this.accountQuery.accessToken).subscribe(
      () => {
        if (this.isVerified() && redirect) {
          this.localStorage.clear('redirect');
          const redirectUrl = `${redirect}${redirect.includes('?') ? '&' : '?'}identifier=${userId}&toWallet=${walletId}`;
          window.location.href = encodeURI(redirectUrl);
        }
      },
      err => {
        console.log(err);
        this.handleUnauthenticated(redirect);
      }
    );
  };

  private storeRedirect = (url: string): void => {
    this.localStorage.store('redirect', url);
  };

  private isVerified() {
    return (
      (this.accountQuery.hasValidId && this.accountQuery.hasValidMobilePhone && this.accountQuery.emailVerified) ||
      this.accountQuery.skipSessionOne
    );
  }
}
