import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { CouponType } from 'clientside-coupon';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponEditService } from 'src/app/core/services/coupon/coupon-edit.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { MatchService } from 'src/app/core/services/match.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { expandCollapse } from 'src/app/shared/animations';
import { MatchModel, SportModel, TournamentModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-coupon-selections',
  templateUrl: './coupon-selections.component.html',
  styleUrls: ['./coupon-selections.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponSelectionsComponent implements OnInit {
  @Input() bookedCoupon: boolean = false;
  couponType: typeof CouponType = CouponType;
  hasOddChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    readonly couponQuery: CouponQuery,
    private readonly matchService: MatchService,
    private readonly couponService: CouponService,
    private readonly couponEditService: CouponEditService,
    private readonly applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());

    this.couponQuery.oddChanges$.subscribe(oddChanges => {
      if (oddChanges) {
        this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());
      }
    });
  }

  removeOdd(selectionId: number): void {
    if (!this.bookedCoupon) {
      this.couponService.removeOdd(selectionId);
    }
  }

  selectionTrackBy(item: SportModel): number {
    return item.id;
  }

  tournamentTrackBy(item: TournamentModel): number {
    return item.id;
  }

  matchTrackBy(item: MatchModel): number {
    return item.id;
  }
}
