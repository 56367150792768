import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { BetFinalState, MySettledBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';

function createInitialState(): MySettledBetsState {
  return {
    lostBetsLoaded: false,
    ui: {
      selectedCouponStatus: BetFinalState.Won
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-settled-bets', resettable: true })
export class MySettledBetsStore extends EntityStore<MySettledBetsState, RecentBetModel> {
  constructor() {
    super(createInitialState());
  }

  setSelectedCouponStatus(status: BetFinalState): void {
    this.update(state => ({
      ui: {
        selectedCouponStatus: status
      }
    }));
  }

  toggleCollapsed(): void {
    this.updateActive(entity => ({
      collapsed: !entity.collapsed
    }));
  }

  clear(): void {
    this.reset();
  }
}
