<div
  class="label"
  [class.long-text]="getSelectionNameLength() >= 4"
  [class.too-long-text]="getSelectionNameLength() >= 10"
  [class.full-width-label]="fullWidthLabel"
>
  <span>{{ selectionName }}</span>
</div>
<span
  class="odd"
  [class.long-text]="getSelectionNameLength() >= 4"
  [class.too-long-text]="getSelectionNameLength() >= 10"
  *ngIf="odd && odd.value !== 1; else missingOdd"
>
  {{ odd.value | formatOdd }}
</span>

<ng-template #missingOdd>
  <span class="odd">-</span>
</ng-template>
