<section class="recent-bet-details">
  <!--
        *****
        *****     Pay info (stake and odds)
        *****
    -->
  <!-- <div class="pay-info">
    <div class="column">
      <div class="label truncate">
        <span transloco="Stake"> </span>
        (<span [innerHTML]="bet.couponDate | formatDate: dateFormat"></span>)
      </div>
      <p>{{ bet.stakeGross | currencyFormat }}</p>
    </div>

    <div class="column">
      <div class="label">
        <span transloco="Odds"> </span>
      </div>
      <p>{{ bet.betDetails.totalOdds | number: '1.2-2' }}</p>
    </div>
  </div> -->

  <!--
        *****
        *****     Cashout
        *****
    -->
  <div class="cashout">
    <app-cashout [code]="bet.couponCode" (refresh)="onRefresh()"></app-cashout>
  </div>

  <!--
        *****
        *****     Matches
        *****
    -->
  <div class="matches">
    <div class="matches-title" *ngIf="bet.betDetails.events.length > 0">
      <span transloco="Events"></span> <span>({{ bet.betDetails.events.length }})</span>
    </div>

    <div class="matches-list">
      <ng-container *ngFor="let match of events$ | async; let idx = index">
        <!-- NUMBER OF FIXES -->
        <div class="system-label-section" *ngIf="isCouponTypeSystem() && idx === 0 && (numberOfFixes$ | async) > 0">
          {{ numberOfFixes$ | async }} Fix
        </div>
        <!-- SYSTEM -->
        <div class="system-label-section" *ngIf="isCouponTypeSystem() && (numberOfFixes$ | async) === idx">
          <span transloco="System"></span> {{ systemTarget$ | async }}
        </div>
        <app-recent-bet-match [couponCode]="bet.couponCode" [match]="match"> </app-recent-bet-match>
      </ng-container>
    </div>
  </div>

  <!--
        *****
        *****     Bottom line
        *****
    -->
  <div class="bet-summary-details">
    <!--
        *****
        *****     Top Summary
        *****
    -->
    <div class="top-summary">
      <!-- Coupon code wrapper -->
      <div class="coupon-code-wrapper">
        <div class="text truncate">
          <span class="top-summary-title" transloco="Coupon Code"></span><span class="top-summary-title">:</span> {{ bet.couponCode }}
        </div>
        <div class="copy-button" (click)="copyCouponCode()" transloco="Copy" *ngIf="!(copiedCouponCodeToClipboard$ | async)"></div>
        <div class="copy-button" (click)="copyCouponCode()" transloco="Successful!" *ngIf="copiedCouponCodeToClipboard$ | async"></div>
      </div>

      <!-- Coupon type -->
      <p class="coupon-type">
        <span transloco="Coupon Type"></span>: <ng-container *ngIf="isCouponTypeSystem()"><span transloco="Comb. Bet"></span></ng-container
        ><ng-container *ngIf="isCouponTypeRegular()"><span transloco="Multiple"></span></ng-container>
      </p>
    </div>

    <!--
        *****
        *****     Details
        *****
    -->
    <div class="coupon-summary-details">
      <!-- ALL: DATE & TIME -->
      <div class="card-item-row">
        <div class="title" transloco="Date & Time:"></div>
        <div class="value">{{ bet.couponDate | formatDate : dateFormat }}</div>
      </div>
      <!-- SYSTEM: NAME OF THE SYSTEM -->
      <div class="card-item-row" *ngIf="isCouponTypeSystem()">
        <div class="title" transloco="System"></div>
        <div class="value">{{ systemName$ | async }}</div>
      </div>
      <!-- SYSTEM: TOTAL COMBINATIONS -->
      <div class="card-item-row" *ngIf="isCouponTypeSystem()">
        <div class="title" transloco="No. Of Comb."></div>
        <div class="value">{{ bet.betDetails.totalCombinations }}</div>
      </div>
      <!-- ALL: BONUS -->
      <div class="card-item-row">
        <div class="title"><span *ngIf="isCouponTypeSystem()">Max. </span>Bonus ({{ getBonus() }}%)</div>
        <div class="value">{{ bet.betDetails.maxBonus | currencyFormat }}</div>
      </div>
      <!-- SYSTEM: MIN WIN -->
      <div class="card-item-row" *ngIf="isCouponTypeSystem()">
        <div class="title" transloco="Min. Win"></div>
        <div class="value">{{ bet.betDetails.minWinNet | currencyFormat }}</div>
      </div>
      <!-- ALL: TOTAL STAKE -->
      <div class="card-item-row">
        <div class="title" transloco="Total Deposit:"></div>
        <div class="value">{{ bet.stakeGross | currencyFormat }}</div>
      </div>
      <!-- REGULAR: TOTAL ODDS -->
      <div class="card-item-row" *ngIf="isCouponTypeRegular()">
        <div class="title" transloco="Total Odds"></div>
        <div class="value">{{ bet.betDetails.totalOdds || bet.totalOdds | number : '1.2-2' }}</div>
      </div>
      <!-- ALL: MAX WIN -->
      <div class="card-item-row last-info">
        <div class="title" *ngIf="isCouponTypeRegular()" transloco="Total Winnings"></div>
        <div class="title" *ngIf="isCouponTypeSystem()" transloco="Max. Win"></div>
        <div class="value">{{ bet.betDetails.maxWinNet || 0 | currencyFormat }}</div>
      </div>
    </div>
  </div>
</section>
