<app-breadcrumb [title]="'Saved cards' | transloco" faIcon="fa-user-plus"></app-breadcrumb>
<app-loading-bar [show]="loading$ | async"></app-loading-bar>
<div *ngIf="!isVerifiedUser()" class="no-kyc-documents-message">
  <div class="warning-message">
    <i aria-hidden="true" class="fa warning fa-exclamation-triangle"></i>
    <span *ngIf="!isVerifiedUser()"
      transloco="In order to make a deposit, you need to verify your personal information."></span>
  </div>
  <button (click)="strenghtenProfile()" *ngIf="!isVerifiedUser()" class="btn">
    <span class="btn-text" transloco="VERIFY DATA"></span>
  </button>
</div>
<div class="container">
  <div class="heading">
    <div class="card-logos">
      <img alt="" src="assets/images/account/mastercard.svg" />
      <img alt="" src="assets/images/account/maestro.svg" />
      <img alt="" src="assets/images/account/visa.svg" />
      <img alt="" src="assets/images/account/dyna.svg" />
    </div>
  </div>
  <ng-container *ngIf="!(loading$ | async)">
    <div *ngFor="let card of accountPaymentQuery.savedCards$ | async; let first = first; trackById" class="saved-card">
      <img alt="card type" src="assets/images/account/{{ card.type }}.svg" />
      <div class="info">
        <div class="title">{{ card.firstName + ' ' + card.lastName }}</div>
        <div class="sub-title">**** **** **** {{ card.lastDigits }}</div>
      </div>
      <div (click)="deleteCard(card.id)" class="radio-button">
        <i class="fa fa-trash"></i>
      </div>
    </div>
  </ng-container>
  <div class="no-cards">
    <div *ngIf="noCards$ | async" class="title">{{ 'No saved cards' | transloco }}</div>
    <div class="sub-title">{{ savedCardsDisclaimer$ | async }}</div>
  </div>
</div>