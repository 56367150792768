import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { EventSummaryModel } from './sport.model';

export interface LandingState {
  mostPopularEvents: EventSummaryModel;
  mostPopularEventsByTournament: EventSummaryModel;
  upcomingEvents: EventSummaryModel;
  liveBettingEvents: EventSummaryModel;
  liveBettingSports: LiveSportsModel[];
  menuItems: MenuItemModel[];
  ui: LandingUIState;
}

export interface LandingUIState {
  menuExpanded: boolean;
  mostPopularEventsLoading: boolean;
  mostPopularEventsByTournamentLoading: boolean;
  upcomingEventsLoading: boolean;
  liveBettingEventsLoading: boolean;
}

export class LiveSportsModel {
  id: number;
  name: string;
  events: EventSummaryModel;

  constructor(init: Partial<LiveSportsModel>) {
    Object.assign(this, init);
  }
}
