import { Component, OnInit } from '@angular/core';

import { HelpService } from 'src/app/core/services/help.service';
import { HelpQuery } from 'src/app/core/state/help/help.query';
import { HelpSection } from 'src/app/shared/models/help.model';

@Component({
  selector: 'app-help-items',
  templateUrl: './help-items.component.html',
  styleUrls: ['./help-items.component.scss']
})
export class HelpItemsComponent implements OnInit {
  helpSections$ = this.helpQuery.sections$;
  loading = true;

  constructor(
    private readonly helpService: HelpService,
    private readonly helpQuery: HelpQuery
  ) { }

  ngOnInit(): void {
    this.helpService.getHelp().subscribe(_ => this.loading = false);
  }

  trackByUrl(index: number, item: HelpSection): string {
    return item.url;
  }
}
