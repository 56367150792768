import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/app/core/services/application.service';
import { AccountStatementModel } from 'src/app/shared/models/account.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';

@Component({
  selector: 'app-transaction-preview',
  templateUrl: './transaction-preview.component.html',
  styleUrls: ['./transaction-preview.component.scss']
})
export class TransactionPreviewComponent {
  @Input() transaction: AccountStatementModel;

  constructor(
    private readonly router: Router,
    private readonly appService: ApplicationService,
    private currencyFormat: CurrencyFormatPipe
  ) {}

  openCoupon(): void {
    this.router.navigateByUrl(`/account/bet-search/coupon-details/${this.transaction.couponCode}`);
    this.closeMenu();
  }

  isVegasBonusTransaction(transaction: AccountStatementModel): boolean {
    return this.isBonusTransaction(transaction) && transaction.description.toLocaleLowerCase().includes('vegas');
  }

  isSportBonusTransaction(transaction: AccountStatementModel): boolean {
    return this.isBonusTransaction(transaction) && !transaction.description.toLocaleLowerCase().includes('vegas');
  }

  isBonusTransaction(transaction: AccountStatementModel): boolean {
    return transaction.description.toLocaleLowerCase().includes('bonus');
  }

  private closeMenu(): void {
    this.appService.showTransactionsMenu$.next(false);
  }
  isPositiveOrNegative(trans: AccountStatementModel) {
    const formatedAmount = this.currencyFormat.transform(trans.amount, '');

    if (trans.amount > 0) {
      return `+${formatedAmount}`;
    } else {
      return formatedAmount;
    }
  }
}
