import { EntityState } from '@datorama/akita';
export enum EvaluationStatus {
  pending = 1,
  accepted = 2,
  declined = 3,
  awaitingReply = 4
}

export interface EvaluationState extends EntityState<EvaluationModel> {}
export class EvaluationModel {
  coupon: any;
  status: EvaluationStatus;

  constructor(init: Partial<EvaluationModel>) {
    Object.assign(this, init);
  }
}
