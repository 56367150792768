import { Component, Input, OnInit } from '@angular/core';
import { SidebarBox } from 'src/app/shared/models/application.model';

@Component({
  selector: 'app-sidebar-box',
  templateUrl: './sidebar-box.component.html',
  styleUrls: ['./sidebar-box.component.scss']
})
export class SidebarBoxComponent implements OnInit {
  @Input() sidebarBox: SidebarBox;

  ngOnInit(): void {}

  isFullImage(): boolean {
    return !!this.sidebarBox.fullImage;
  }

  hasThumb(): boolean {
    return !!this.sidebarBox.thumb;
  }

  hasBackground(): boolean {
    return !!this.sidebarBox.background;
  }

  hasTitle(): boolean {
    return !!this.sidebarBox.title;
  }

  hasSubtitle(): boolean {
    return !!this.sidebarBox.subtitle;
  }

  getLocalRoute(route: string): string {
    if (route.startsWith('http')) {
      return undefined;
    }
    return route;
  }

  getFirstButtonUrl(): string {
    return this.sidebarBox.buttons?.[0].url;
  }
}
