import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { SportService } from 'src/app/core/services/sport.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() faIcon: string;
  @Input() navIcon: string;
  @Input() sportIcon: string;
  @Input() loggedOutAction: 'login' | 'register' = 'login';
  @Output() readonly backButtonClick = new EventEmitter();

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accountService: AccountService,
    private readonly applicationService: ApplicationService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly sportService: SportService,
    private readonly sportQuery: SportQuery,
    private readonly route: ActivatedRoute
  ) {}

  goHome(): void {
    this.router.navigateByUrl('/');
    this.applicationService.closeAnySlideUps();
    if (this.sportQuery.selectedPrematch) {
      this.sportService.updateEventSelection({ filterToDepthFour: false });
      this.sportService.restoreAreaRegionsCache();
    }
  }

  goBack(): void {
    if (this.hasCustomBackFunction()) {
      this.backButtonClick.emit();
    } else {
      if (this.router.url === '/slot') {
        // this.handleMessage();
        setTimeout(() => this.router.navigateByUrl('/'), 100);
      } else {
        this.location.back();
      }
    }
  }

  navigateToLogin(): void {
    this.accountService.openLogin();
    // this.router.navigate(['/account/login'], { queryParams: { urlAfterLogin: window.location.pathname } });
  }

  refreshBalance(): void {
    if (!this.accountQuery.refreshingBalance) {
      this.accountService.updateBalance();
    }
  }

  showAccount(): void {
    if (this.accountQuery.isAuthenticated) {
      this.applicationService.updateUI({
        showAccountMenu: true
      });
    } else {
      this.showLogin();
    }
  }

  showLogin(): void {
    this.applicationService.updateUI({
      showLogin: true
    });
  }

  get isAccountPage(): boolean {
    return this.router.url === '/account';
  }

  get isBonusPage(): boolean {
    return this.router.url === '/account/bonus';
  }

  private hasCustomBackFunction(): boolean {
    return this.backButtonClick.observers.length > 0;
  }
}
