<div class="my-bets-wrapper">
  <div class="main-content" [class.preview]="preview" [ngClass]="{ authenticated: (accountQuery.isAuthenticated$ | async) }">
    <app-card [minFullHeight]="true">
      <ng-container body>
        <!--
          *****
          *****   Time filters
          *****
        -->
        <div class="bets-time-filters">
          <!--
            ***
            ***   Days period
            ***
          -->
          <div class="days-wrapper">
            <div transloco="Today" [class.active]="isTimePeriod$(TimePeriod.TODAY) | async" (click)="setTimePeriod(TimePeriod.TODAY)"></div>
            <div
              transloco="3 Days"
              [class.active]="isTimePeriod$(TimePeriod.LAST_3_DAYS) | async"
              (click)="setTimePeriod(TimePeriod.LAST_3_DAYS)"
            ></div>
            <div
              transloco="7 days"
              [class.active]="isTimePeriod$(TimePeriod.LAST_7_DAYS) | async"
              (click)="setTimePeriod(TimePeriod.LAST_7_DAYS)"
            ></div>
            <div
              transloco="30 days"
              [class.active]="isTimePeriod$(TimePeriod.LAST_30_DAYS) | async"
              (click)="setTimePeriod(TimePeriod.LAST_30_DAYS)"
            ></div>
          </div>

          <!--
            ***
            ***   From-to button
            ***
          -->
          <div class="days-period-wrapper">
            <div
              (click)="setTimePeriod(TimePeriod.CUSTOM_PERIOD)"
              class="days-period-button"
              [class.active]="isTimePeriod$(TimePeriod.CUSTOM_PERIOD) | async"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
                <path
                  fill="#fff"
                  d="M16.5 5.438H12a.567.567 0 0 1-.563-.563c0-.308.256-.563.563-.563h4.5c.308 0 .563.255.563.563a.567.567 0 0 1-.563.563ZM4.5 5.438h-3a.567.567 0 0 1-.563-.563c0-.308.255-.563.563-.563h3c.308 0 .563.255.563.563a.567.567 0 0 1-.563.563Z"
                />
                <path
                  fill="#fff"
                  d="M7.5 8.063a3.194 3.194 0 0 1-3.188-3.188A3.194 3.194 0 0 1 7.5 1.687a3.194 3.194 0 0 1 3.188 3.188A3.194 3.194 0 0 1 7.5 8.063Zm0-5.25a2.061 2.061 0 1 0 0 4.125c1.14 0 2.063-.923 2.063-2.063 0-1.14-.923-2.063-2.063-2.063ZM16.5 13.688h-3a.567.567 0 0 1-.563-.563c0-.307.256-.563.563-.563h3c.308 0 .563.256.563.563a.567.567 0 0 1-.563.563ZM6 13.688H1.5a.567.567 0 0 1-.563-.563c0-.307.255-.563.563-.563H6c.308 0 .563.256.563.563a.567.567 0 0 1-.563.563Z"
                />
                <path
                  fill="#fff"
                  d="M10.5 16.313a3.194 3.194 0 0 1-3.188-3.188A3.194 3.194 0 0 1 10.5 9.937a3.194 3.194 0 0 1 3.188 3.188 3.194 3.194 0 0 1-3.188 3.188Zm0-5.25a2.061 2.061 0 1 0 0 4.125c1.14 0 2.063-.923 2.063-2.063 0-1.14-.923-2.063-2.063-2.063Z"
                />
              </svg>
            </div>
          </div>
          <div class="form-time-period" *ngIf="timePeriod$ | async as active">
            <form
              *ngIf="active === TimePeriod.CUSTOM_PERIOD && (openCloseDateFilter$ | async)"
              class="form"
              [class.halfWidth]="changeElSize() === true"
              [formGroup]="dateForm"
              [@fadeIn]
            >
              <div class="header">
                <div class="title">{{ 'Search for a bet' | transloco }}</div>
                <span class="close" (click)="closeDateFilter()" transloco="✕"></span>
              </div>
              <div class="date-picker-wrapper">
                <div class="date-from">
                  <label transloco="Date From"></label>
                  <input
                    type="date"
                    id="start"
                    [value]="oneWeekBeforeToday"
                    [min]="minFromDate$ | async"
                    [max]="dateForm.controls['dateTo'].value"
                    formControlName="dateFrom"
                  />
                </div>
                <div class="date-to">
                  <label transloco="Date To"></label>
                  <input
                    type="date"
                    id="start"
                    (keydown)="onKeydown($event)"
                    [value]="today"
                    [min]="dateForm.controls['dateFrom'].value"
                    [min]="dateForm.value.dateFrom"
                    [max]="today"
                    formControlName="dateTo"
                  />
                </div>
              </div>
              <button class="search-date" (click)="searchTimePeriod(TimePeriod.CUSTOM_PERIOD)">
                <div *ngIf="!(loadingCoupons$ | async)">{{ 'Begin search' | transloco }}</div>
                <div class="loader-container" *ngIf="loadingCoupons$ | async">
                  <div class="loader white"></div>
                </div>
              </button>
            </form>
          </div>
        </div>
        <div class="scroll-left-button" id="scroll-left-button" (click)="scrollLeft()" *ngIf="ifScrolledLeft$ | async">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 20 20" fill="currentColor" class="arrow">
            <path
              fillRule="evenodd"
              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div class="scroll-right-button" id="scroll-right-button" (click)="scrollRight()" *ngIf="ifScrolledRight$ | async">
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="3 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clip-rule="evenodd"
            />
          </svg>
        </div>

        <!--
          *****
          *****   Tabs
          *****
        -->
        <div class="bets-tab-container" (scroll)="isScrollHappened($event)" [class.preview]="preview">
          <!--
              ***
              ***   All bets
              ***
            -->
          <div
            class="bets-tab selected"
            [class.selected]="betsTabSelected === betsTab.All"
            (click)="setTab(betsTab.All)"
            transloco="All"
          ></div>
          <!--
              ***
              ***   Open bets
              ***
            -->
          <div
            class="bets-tab selected"
            [class.selected]="betsTabSelected === betsTab.Open"
            (click)="setTab(betsTab.Open)"
            transloco="Actives"
          ></div>

          <!--
              ***
              ***   Won bets
              ***
            -->
          <div class="bets-tab" [class.selected]="betsTabSelected === betsTab.Won" (click)="setTab(betsTab.Won)" transloco="Won Bets"></div>
          <!--
              ***
              ***   Cashout bets
              ***
            -->
          <div
            class="bets-tab"
            [class.selected]="betsTabSelected === betsTab.Cashout"
            (click)="setTab(betsTab.Cashout)"
            transloco="Cashout Bets"
          ></div>
          <!--
              ***
              ***   Lost bets
              ***
            -->
          <div
            class="bets-tab"
            [class.selected]="betsTabSelected === betsTab.Lost"
            (click)="setTab(betsTab.Lost)"
            transloco="Lost Bets"
          ></div>
        </div>

        <!--
          *****
          *****   Bets screens
          *****
        -->
        <ng-container [ngSwitch]="betsTabSelected">
          <!--
            ***
            ***   All bets
            ***
          -->
          <app-recent-bets
            *ngSwitchCase="betsTab.All"
            [bets]="myBetsQuery.myAllBets$ | async"
            [loading]="myBetsQuery.allBetsLoading$ | async"
            [firstTimeLoading]="firstTimeLoading$ | async"
            [displayEvaluationBets]="true"
            (refresh)="onRefresh()"
          >
          </app-recent-bets>
          <!--
            ***
            ***   Open bets
            ***
          -->
          <app-recent-bets
            *ngSwitchCase="betsTab.Open"
            [bets]="myBetsQuery.myOpenBets$ | async"
            [loading]="myBetsQuery.openLoading$ | async"
            [firstTimeLoading]="firstTimeLoading$ | async"
            [displayEvaluationBets]="true"
            (refresh)="onRefresh()"
          >
          </app-recent-bets>

          <!--
            ***
            ***   Won bets
            ***
          -->
          <app-recent-bets
            *ngSwitchCase="betsTab.Won"
            [bets]="myBetsQuery.wonBets$ | async"
            [loading]="myBetsQuery.settledLoading$ | async"
            (refresh)="onRefresh()"
          >
          </app-recent-bets>
          <!--
            ***
            ***   Cashout bets
            ***
          -->
          <app-recent-bets
            *ngSwitchCase="betsTab.Cashout"
            [bets]="myBetsQuery.cashoutBets$ | async"
            [loading]="myBetsQuery.settledLoading$ | async"
            (refresh)="onRefresh()"
          >
          </app-recent-bets>
          <!--
            ***
            ***   Lost bets
            ***
          -->
          <app-recent-bets
            *ngSwitchCase="betsTab.Lost"
            [bets]="myBetsQuery.lostBets$ | async"
            [loading]="myBetsQuery.settledLoading$ | async"
            (refresh)="onRefresh()"
          >
          </app-recent-bets>
        </ng-container>
      </ng-container>
    </app-card>
  </div>
</div>
