import { AccountQuery } from './../../core/state/account/account.query';
import { HostListener, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { AccountService } from 'src/app/core/services/account/account.service';
import { VictoryAnalyticsService } from 'src/app/core/services/analytics/victory-analytics.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { takeUntil } from 'rxjs/operators';

import { LocalStorageService } from 'ngx-webstorage';
import { SportradarAnalyticsService } from 'src/app/core/services/analytics/sportradar-analytics.service';
import { SoliticsAnalyticsService } from 'src/app/core/services/analytics/solitics-analytics.service';
import { CasinoService } from 'src/app/modules/casino/casino.service';
import { PwaService } from 'src/app/core/services/pwa.service';
import { environment } from 'src/environments/environment';
declare var window: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  url: SafeResourceUrl;
  isUsernameConfirmed$ = new BehaviorSubject<boolean>(false);
  displayPassAndCode$ = new BehaviorSubject<boolean>(false);
  displayForgotPassword$ = new BehaviorSubject<boolean>(false);

  displayPass$ = new BehaviorSubject<boolean>(false);
  displayCode$ = new BehaviorSubject<boolean>(false);
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  casinoGameId: string;

  constructor(
    private readonly solitics: SoliticsAnalyticsService,
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly sanitizer: DomSanitizer,
    private readonly transloco: TranslocoService,
    private readonly analytics: VictoryAnalyticsService,
    private readonly accountQuery: AccountQuery,
    public readonly accountService: AccountService,
    private readonly casinoService: CasinoService,
    private readonly localStorage: LocalStorageService,
    private readonly sportradarAnalytics: SportradarAnalyticsService
  ) {}

  ngOnInit(): void {
    this.observeAnalyticsSession();
    this.observeUserData();
    this.setupIframeUrl();

    if (this.casinoService.openGameId) {
      this.casinoGameId = this.casinoService.openGameId;
      this.casinoService.openGameId = undefined;
    }
  }

  @HostListener('window:message', ['$event']) onWindowMessage(event: any): void {
    this.handleIframeMessage(event);
  }

  @HostListener('window:resize', ['$event']) onResize(event: any): void {
    this.setupWindowHeight(event);
    setTimeout(this.setupWindowHeight.bind(this), 100);
    setTimeout(this.setupWindowHeight.bind(this), 300);
  }
  private setupWindowHeight(event: any): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  private observeAnalyticsSession(): void {
    this.analytics.sessionCreated$.pipe(takeUntil(this.destroy$)).subscribe(this.setupIframeUrl.bind(this));
  }

  private observeUserData(): void {
    this.accountQuery.userData$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.accountQuery?.userData?.accessToken) {
        if (!this.isVerified()) {
          this.completeVerification();
        } else if (this.isVerified() && window.location.href.includes('register')) {
          this.navigateTo('/');
        }
        this.close();
      }
    });
  }

  isVerified() {
    return (
      (this.accountQuery.hasValidId && this.accountQuery.hasValidMobilePhone && this.accountQuery.emailVerified) ||
      this.accountQuery.skipSessionOne
    );
  }

  completeVerification() {
    if (this.accountQuery.hasValidId && this.accountQuery?.userData?.mobile) {
      this.navigateTo('register');
    } else {
      this.navigateTo('strenghten-profile');
    }
  }

  open(): void {
    this.accountService.showLogin$.next(true);
  }

  close(): void {
    this.accountService.showLogin$.next(false);
  }

  private handleIframeMessage(e: any): void {
    const {
      data: { type, message }
    } = e;

    if (type === 'set-login-token') {
      this.accountService.getUserData(message).subscribe(res => {
        this.sportradarAnalytics.sendLoginSuccessEvent(res.id);
        this.solitics.sendLoginSuccessEvent({
          memberId: this.accountQuery.userData.id.toString(),
          email: this.accountQuery.userData.email
        });
        this.localStorage.store('sign-in-method', e.data?.method);
        if (this.casinoGameId) {
          console.log(this.casinoService.openGameId);
          console.log(this.casinoGameId);
          const url = this.router.createUrlTree(['/slot'], { queryParams: { openGameId: this.casinoGameId } });
          window.location.href = url;
          this.casinoService.openGameId = undefined;
        }
      });
    } else if (type === 'display-pass-and-code') {
      this.isUsernameConfirmed$.next(true);
      this.displayPassAndCode$.next(true);
      this.displayCode$.next(false);
      this.displayPass$.next(false);
    } else if (type === 'display-pass') {
      this.isUsernameConfirmed$.next(true);
      this.displayPass$.next(true);
      this.displayCode$.next(false);
      this.displayPassAndCode$.next(false);
      this.displayForgotPassword$.next(false);
    } else if (type === 'display-code') {
      this.isUsernameConfirmed$.next(true);
      this.displayCode$.next(true);
      this.displayPass$.next(false);
      this.displayPassAndCode$.next(false);
      this.displayForgotPassword$.next(false);
    } else if (type === 'username-cleared') {
      this.displayPassAndCode$.next(false);
      this.displayCode$.next(false);
      this.displayPass$.next(false);
      this.isUsernameConfirmed$.next(false);
      this.displayForgotPassword$.next(false);
    } else if (type === 'display-forgot-password') {
      this.displayForgotPassword$.next(true);
      this.displayCode$.next(false);
      this.displayPass$.next(false);
      this.displayPassAndCode$.next(false);
    } else if (type === 'navigate') {
      this.navigateTo(message);
    } else if (type === 'close') {
      this.close();

      document.querySelector('body').focus();
    } else if (type === 'open') {
      this.open();
    }
  }

  private navigateTo(link: string): void {
    this.router.navigate([link]);
  }

  private setupIframeUrl(): void {
    const analytics = {
      sessionId: this.analytics.sessionID,
      ...(this.analytics.connectionID && { connectionId: this.analytics.connectionID })
    };

    const lang = this.transloco.getActiveLang();
    const signUpUrl = environment.signUpUrl;
    let iframeUrl = `${signUpUrl}${lang}/sign-in`;

    iframeUrl = `${iframeUrl}?sessionId=${analytics.sessionId}&connectionId=${analytics.connectionId}&guest=true&method=${
      this.localStorage.retrieve('sign-in-method') || 'false'
    }`;

    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(iframeUrl);
  }
}
