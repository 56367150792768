import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { kebabCase } from 'lodash-es';
import { first } from 'rxjs/operators';

import { SportService } from 'src/app/core/services/sport.service';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { CategoryModel, MatchModel, SportModel } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root'
})
export class SportsSelectionService {
  constructor(
    private readonly sportQuery: SportQuery,
    private readonly sportStore: SportStore,
    private readonly sportService: SportService,
    private readonly router: Router
  ) {}

  // Country
  selectCountry(sportIndex: number, countryIndex: number, resetSelection?: boolean): void {
    this.sportService.updateEventSelection({
      allCompetitionByCountry: this.generateEventSelectionUpdate(
        this.sportQuery.allCompetitionByCountry,
        sportIndex,
        countryIndex,
        resetSelection
      )
    });
  }

  deselectAllCountries(): void {
    this.sportService.updateEventSelection({
      allCompetitionByCountry: this.sportQuery.allCompetitionByCountry.map(sport => ({
        ...sport,
        categories: sport.categories.map(category => ({ ...category, selectedInView: false }))
      }))
    });
  }

  selectCountryAndProceed(sportIndex: number, countryIndex: number, countryId: number, selectedSport: SportModel): void {
    this.selectCountry(sportIndex, countryIndex, true);
    this.sportService.updateEventSelection({ selectedSport, eventSelectionDepth: 2 });
    this.router.navigateByUrl(`/sports/${kebabCase(selectedSport.name)}/leagues/prematch/${countryId}`);
  }

  // All Competitions
  selectTournament(sportIndex: number, tournamentIndex: number): void {
    this.sportQuery.competitionsAZ$.pipe(first()).subscribe(az => {
      const competitionsAZ = [...az];
      const tournamentsUpdate = [...competitionsAZ[sportIndex].tournaments];
      const tournamentUpdate = { ...tournamentsUpdate[tournamentIndex] };
      const sportUpdate = { ...competitionsAZ[sportIndex] };

      tournamentUpdate.selectedInView = !tournamentUpdate.selectedInView;
      tournamentsUpdate[tournamentIndex] = tournamentUpdate;
      sportUpdate.tournaments = tournamentsUpdate;
      competitionsAZ[sportIndex] = sportUpdate;

      this.sportService.updateEventSelection({ competitionsAZ });
    });
  }

  deselectTournaments(): void {
    this.sportQuery.competitionsAZ$.pipe(first()).subscribe(competitions => {
      const competitionsAZ = competitions.map(model => ({
        ...model,
        tournaments: model.tournaments.map(tournament => ({ ...tournament, selectedInView: false }))
      }));
      this.sportService.updateEventSelection({
        competitionsAZ,
        allCompetitionByCountry: this.sportQuery.allCompetitionByCountry.map(sport => ({
          ...sport,
          categories: sport.categories.map(category => ({
            ...category,
            tournaments: category.tournaments.map(tournament => ({ ...tournament, selectedInView: false }))
          }))
        }))
      });
    });
  }

  // Goalscorer
  selectGoalscorer(sportIndex: number, goalscorerIndex: number): void {
    this.sportService.updateEventSelection({
      goalscorerSport: this.generateEventSelectionUpdate(this.sportQuery.goalscorerSport, sportIndex, goalscorerIndex, true)
    });
  }

  selectGoalscorerAndProceed(sportIndex: number, goalscorerIndex: number, categoryId: number, selectedSport: SportModel): void {
    this.selectGoalscorer(sportIndex, goalscorerIndex);
    this.sportService.updateEventSelection({ selectedSport, eventSelectionDepth: 2 });
    this.router.navigateByUrl(`/sports/${kebabCase(selectedSport.name)}/leagues/special/${categoryId}`);
  }

  // Special Sports
  selectSpecialSport(sportIndex: number, specialSportIndex: number): void {
    this.sportService.updateEventSelection({
      specialSports: this.generateEventSelectionUpdate(this.sportQuery.specialSports, sportIndex, specialSportIndex, true)
    });
  }

  selectSpecialSportAndProceed(sportIndex: number, specialSportIndex: number, categoryId: number, selectedSport: SportModel): void {
    this.selectSpecialSport(sportIndex, specialSportIndex);
    this.sportService.updateEventSelection({ selectedSport, eventSelectionDepth: 2 });
    this.router.navigateByUrl(`/sports/${kebabCase(selectedSport.name)}/leagues/special/${categoryId}`);
  }

  // Outrights
  selectOutright(sportIndex: number, outrightIndex: number, resetSelection?: boolean): void {
    this.sportService.updateEventSelection({
      outrights: this.generateEventSelectionUpdate(this.sportQuery.outrights, sportIndex, outrightIndex, resetSelection)
    });
  }

  deselectOutrights(): void {
    this.sportService.updateEventSelection({
      outrights: this.sportQuery.outrights.map(sport => ({
        ...sport,
        categories: sport.categories.map(category => ({ ...category, selectedInView: false }))
      }))
    });
  }

  selectOutrightAndProceed(sportIndex: number, outrightIndex: number, outrightId: number, selectedSport: SportModel): void {
    this.selectOutright(sportIndex, outrightIndex, true);
    this.sportService.updateEventSelection({ selectedSport, eventSelectionDepth: 2 });
    this.router.navigateByUrl(`/sports/${kebabCase(selectedSport.name)}/leagues/outright/${outrightId}`);
  }

  // Top Competitions
  selectTopCompetition(matchId: number): void {
    let matchIndex: number;

    const tournament = [...this.sportQuery.topCompetitions].find((match, index) => {
      if (match.id === matchId) {
        matchIndex = index;
        return match.id === matchId;
      }
    });

    const topCompetitions = [...this.sportQuery.topCompetitions];
    topCompetitions[matchIndex] = new MatchModel({ ...tournament, selectedInView: !tournament.selectedInView });

    this.sportService.updateEventSelection({ topCompetitions });
  }

  private generateEventSelectionUpdate(
    sportsList: SportModel[],
    sportIndex: number,
    categoryIndex: number,
    resetSelection: boolean
  ): SportModel[] {
    // Init new state with old values
    let newCategoryList: CategoryModel[] = [];
    const newCategory = { ...sportsList[sportIndex].categories[categoryIndex] };
    const newSport = { ...sportsList[sportIndex] };
    const newSportList = [...sportsList];

    if (resetSelection) {
      // Set all selections to false
      sportsList[sportIndex].categories.forEach(category => {
        newCategoryList.push({ ...category, selectedInView: false });
      });
    } else {
      newCategoryList = [...sportsList[sportIndex].categories];
    }

    // Toggle selection and update new state variables
    newCategory.selectedInView = !newCategory.selectedInView;
    newCategoryList[categoryIndex] = newCategory;
    newSport.categories = newCategoryList;
    newSportList[sportIndex] = newSport;

    return newSportList;
  }
}
