<app-breadcrumb [title]="'Fund Transfer' | transloco" faIcon="fa-exchange">
</app-breadcrumb>
<app-card [titleHeaderText]="'Fund Transfer' | transloco">
  <div class="fund-transfer-container" body>
    <form [formGroup]="transferForm" class="transfer-form">
      <app-form-item-group [title]="'Status' | transloco">
        <div class="form-item status">
          <div class="radio-item-group">
            <input type="radio" name="status" value="ACT|PCHANG" formControlName="status">
            <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
            <span transloco="Active"></span>
          </div>
          <div class="radio-item-group">
            <input type="radio" name="status" value="INACT|UNCONF|CONF|SUSP|BLOCK|CLSD" formControlName="status">
            <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
            <span transloco="Inactive"></span>
          </div>
        </div>
      </app-form-item-group>

      <div class="form-item user">
        <label transloco="User"></label>
        <div class="dropdown">
          <select formControlName="user">
            <option value="" disabled transloco="Please select user"></option>
            <option
              *ngFor="let candidate of (accountQuery.transferCandidates$ | async); trackBy:trackByTransferCandidates"
              [value]="candidate.userId">
              {{ candidate.name }} {{ candidate.surname }} [{{ candidate.userId }} - {{ candidate.username }}]
              ({{ candidate.balance | currencyFormat }})
            </option>
          </select>
        </div>
        <app-form-message [text]="'User is required' | transloco"
          *ngIf="transferForm.controls.user.hasError('required') && transferForm.controls.user.touched">
        </app-form-message>
      </div>

      <div class="user-balance-container" *ngIf="transferForm.controls.user.value" [@expandCollapseSimpler]>
        <div transloco='Main Balance' class="label first">
        </div>
        <div class="value first">
          {{ currentUserBalance | currencyFormat }}
          <span [class.more]="transferForm.controls.type.value === 'D'"
            [class.less]="transferForm.controls.type.value === 'W'" *ngIf="transferForm.controls.amount.value > 0">
            ({{ (transferForm.controls.type.value === 'W' ? transferForm.controls.amount.value * -1 : transferForm.controls.amount.value) | currencyFormat }})
          </span>
        </div>
        <div class="label final" *ngIf="transferForm.controls.amount.value > 0" transloco="Final Balance">
        </div>
        <div class="value final" *ngIf="transferForm.controls.amount.value > 0">
          <span *ngIf="transferForm.controls.type.value === 'D'">
            {{ currentUserBalance + transferForm.controls.amount.value | currencyFormat }}
          </span>
          <span *ngIf="transferForm.controls.type.value === 'W'">
            {{ currentUserBalance - transferForm.controls.amount.value | currencyFormat }}
          </span>
        </div>
      </div>

      <div *ngIf="transferForm.controls.user.value" [@expandCollapseSimpler]>
        <app-form-item-group [title]="'Type' | transloco">
          <div class="form-item type">
            <div class="radio-item-group">
              <input type="radio" name="type" value="D" formControlName="type">
              <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
              <span transloco="Deposit"></span>
            </div>
            <div class="radio-item-group">
              <input type="radio" name="type" value="W" formControlName="type">
              <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
              <span transloco="Withdrawal"></span>
            </div>
          </div>
        </app-form-item-group>

        <div class="form-item amount">
          <label>{{'Amount' | transloco}} ({{ (accountQuery.userData$ | async).currency.symbol }})</label>
          <input type="number" [placeholder]="'00.00' | transloco" formControlName="amount" step="50" min="0"
            [max]="(accountQuery.userData$ | async).wallets[0].balance">
          <app-form-message [text]="'Invalid Amount' | transloco"
            *ngIf="transferForm.controls.amount.invalid && transferForm.controls.amount.touched">
          </app-form-message>
        </div>

        <div class="user-balance-container" *ngIf="transferForm.controls.amount.value" [@expandCollapseSimpler]>
          <div transloco='Your Balance' class="label first">
          </div>
          <div class="value first" [class.less]="transferForm.controls.type.value === 'D'"
            [class.more]="transferForm.controls.type.value === 'W'">
            <span *ngIf="transferForm.controls.type.value === 'D'">
              {{ ((accountQuery.userData$ | async).wallets[0].balance - transferForm.controls.amount.value) | currencyFormat }}
            </span>
            <span *ngIf="transferForm.controls.type.value === 'W'">
              {{ ((accountQuery.userData$ | async).wallets[0].balance + transferForm.controls.amount.value) | currencyFormat }}
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-card>
<app-button [text]="'Proceed' | transloco" [disabled]="transferForm.invalid" (click)="this.transferFunds()"></app-button>
