import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-multi-line-event',
  templateUrl: './multi-line-event.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiLineEventComponent implements OnChanges {
  parsedEvent$: BehaviorSubject<MatchModel> = new BehaviorSubject(undefined);

  @Input() event: MatchModel;
  @Input() selectedMarket: MarketModel = undefined;
  /**
   * This only applies for event with no selector and when showDetails is true
   */
  @Input() sportId: number;
  /**
   * This only applies for event with no selector and when showDetails is true
   */
  @Input() showDate = true;
  /**
   * This only applies for event with no selector
   */
  @Input() showDetails: boolean = true;
  @Input() withSelector: boolean = false;
  @Input() isAvailable: boolean = true;
  /**
   * This only applies for event with selector
   */
  @Output() readonly toggleSelector = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    this.parsedEvent$.next(
      this.selectedMarket
        ? new MatchModel({
            ...this.event,
            odds: this.selectedMarket.selections.map(m =>
              this.event.odds.find(
                odd =>
                  (this.selectedMarket.name === undefined || odd.marketTypeId === this.selectedMarket.typeId)
                  && odd.selectionId === m.id
              )
            )
          })
        : this.event
    );
  }

  clickSelector(): void {
    this.toggleSelector.emit();
  }
}
