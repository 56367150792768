<div class="event">
  <ng-container *ngIf="showDetails">
    <div class="top">
      <div class="content-wrapper">
        <div class="left-elements">
          <div class="chart-wrapper" *ngIf="statisticsService.isStatisticsEnabled()">
            <i class="chart-icon fa fa-bar-chart" aria-hidden="true" *ngIf="event.externalId"
              (click)="openStatistics()"></i>
          </div>
          <div class="checkbox-wrapper" (click)="toggleCheckbox()">
            <div class="sports-checkbox sm responsive">
              <i class="fa fa-check" aria-hidden="true" *ngIf="event.selectedInView"
                [@fadeInOutBy]="{ value: '', params: { duration: 200 } }"></i>
            </div>
          </div>
        </div>
        <div class="right-elements" (click)="navigateToMatch()">
          <app-event-header [event]="event" [fullVersion]="false">
          </app-event-header>
          <div class="bottom-elements">
            <div class="match-info">
              <app-event-match-info [event]="event"></app-event-match-info>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [@expandCollapse]="{ value: (statisticsQuery.expandedStatistics$ | async) ? 'expanded' : 'collapsed', params: { minHeight: '0px' }}">
      <app-statistics *ngIf="event.externalId === (statisticsQuery.selectedExternalId$ | async)"
        [externalId]="event.externalId"></app-statistics>
    </div>
  </ng-container>
  <app-correct-scores-market-odds [odd]="event.correctScoreOdds" [padding]="showDetails" *ngIf="isAvailable">
  </app-correct-scores-market-odds>
  <app-not-available-message *ngIf="!isAvailable"></app-not-available-message>
</div>
