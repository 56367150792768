import { Injectable, OnDestroy } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Subject } from 'rxjs';
import { AccountPaymentState } from 'src/app/shared/models/account.model';

function createInitialState(): AccountPaymentState {
  return {
    payments: undefined,
    qualifiedWithdrawals: undefined,
    payoutPromoConfig: null,
    cards: [],
    hints: {
      cvv: null,
      saveCard: null
    },
    savedCardsDisclaimer: ''
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'account' })
export class AccountPaymentStore extends Store<AccountPaymentState> implements OnDestroy {
  destroy$ = new Subject<boolean>();

  constructor() {
    super(createInitialState());
  }

  updatePayments(payments: Partial<AccountPaymentState['payments']>): void {
    this.update(state => ({
      payments: {
        ...state.payments,
        ...payments
      }
    }));
  }

  updateQualifiedWithdrawals(withdrawals: AccountPaymentState['qualifiedWithdrawals']): void {
    this.update(state => ({
      qualifiedWithdrawals: [...withdrawals]
    }));
  }
  updatePayoutPromoConfig(config: AccountPaymentState['payoutPromoConfig']): void {
    this.update(state => ({
      payoutPromoConfig: config
    }));
  }
  updateCards(cards: Partial<AccountPaymentState['cards']>): void {
    this.update(state => ({
      cards: [...cards]
    }));
  }
  updateHints(hints: Partial<AccountPaymentState['hints']>): void {
    this.update(state => ({
      hints: {
        ...state.hints,
        ...hints
      }
    }));
  }
  updateSavedCardsDisclaimer(savedCardsDisclaimer: Partial<AccountPaymentState['savedCardsDisclaimer']>): void {
    this.update(state => ({
      savedCardsDisclaimer
    }));
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
