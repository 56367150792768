import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MenuState, MenuTab } from 'src/app/shared/models/menu.model';

function createInitialState(): MenuState {
  return {
    selectedTab: MenuTab.SportsAZ,
    quickLinks: undefined,
    ui: {
      isSportsLoading: false
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'menu' })
export class MenuStore extends Store<MenuState> {
  constructor() {
    super(createInitialState());
  }

  changeTab(selectedTab: MenuTab): void {
    this.update({ selectedTab });
  }

  updateUI(ui: Partial<MenuState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }
}
