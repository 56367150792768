<div class="transaction-preview">
  <div class="transaction-row">
    <p class="description">{{ transaction.description }}</p>
    <p class="amount" [class.positive]="transaction.amount > 0" [class.negative]="transaction.amount < 0">
      {{ isPositiveOrNegative(transaction) }}
    </p>
  </div>
  <div class="transaction-row secondary">
    <p class="date">{{ transaction.transactionDate | date : 'dd/MM/yyyy, HH:mm' }}</p>
    <p class="balance" *ngIf="transaction.rollingBalance">
      <span *ngIf="isVegasBonusTransaction(transaction)">Vegas bonus</span>
      <span *ngIf="isSportBonusTransaction(transaction)">Sport bonus</span>
      <span *ngIf="!isBonusTransaction(transaction)" transloco="Balance"></span>
      <span>:</span>
      {{ transaction.rollingBalance | currencyFormat }}
    </p>
  </div>
  <div class="transaction-row details">
    <div class="specifics">
      <div (click)="openCoupon()" class="view-coupon" *ngIf="transaction.couponCode">
        <span transloco="View Coupon"></span>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
          <path fill="#fff"
            d="M6.641 16.275a.625.625 0 0 1-.06-.813l.06-.07L12.032 10 6.642 4.61a.625.625 0 0 1-.061-.814l.06-.07a.625.625 0 0 1 .814-.06l.07.06 5.833 5.833c.222.222.243.57.061.814l-.06.07-5.834 5.833a.625.625 0 0 1-.884 0Z" />
        </svg>
      </div>
    </div>
    <div class="status">
      <span>#{{ transaction.id }}</span>
      <svg *ngIf="
          transaction.transactionStatusCode === 'COMPLETE' ||
          transaction.transactionStatusCode === 'VIRTUAL' ||
          transaction.transactionStatusCode === 'VALIDATED'
        " xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
        <path fill="#fff" fill-rule="evenodd"
          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z"
          clip-rule="evenodd" />
      </svg>
      <svg *ngIf="transaction.transactionStatusCode === 'PENDING'" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20"
        height="20" x="0" y="0" viewBox="-50 -20 420 420" xml:space="preserve" class="">
        <g>
          <path
            d="M173.721 347.442c95.919 0 173.721-77.802 173.721-173.721S269.64 0 173.721 0 0 77.802 0 173.721s77.802 173.721 173.721 173.721zm-12.409-272.99c0-6.825 5.584-12.409 12.409-12.409s12.409 5.584 12.409 12.409v93.313l57.39 45.912c5.336 4.281 6.204 12.098 1.923 17.434a12.342 12.342 0 0 1-9.679 4.653c-2.73 0-5.46-.869-7.755-2.73L165.966 183.4c-2.916-2.358-4.653-5.894-4.653-9.679V74.452z"
            fill="#fff" data-original="#000000" class="" opacity="1" />
        </g>
      </svg>
      <svg *ngIf="
          transaction.transactionStatusCode === 'REJECTED' ||
          transaction.transactionStatusCode === 'CANCELLED' ||
          transaction.transactionStatusCode === 'VOID' ||
          transaction.transactionStatusCode === 'FAILED'
        " xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:svgjs="http://svgjs.com/svgjs" version="1.1" width="20" height="20" x="0" y="0" viewBox="-50 -50 600 600"
        xml:space="preserve" class="">
        <g>
          <g fill-rule="evenodd" clip-rule="evenodd">
            <path fill="#fff" d="M256 0C114.8 0 0 114.8 0 256s114.8 256 256 256 256-114.8 256-256S397.2 0 256 0z"
              data-original="#000000" class="" opacity="1" />
            <path fill="black"
              d="M346.9 346.9c-6.2 6.2-16.4 6.2-22.6 0L256 278.6l-68.2 68.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l68.3-68.2-68.3-68.2c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0l68.2 68.2 68.2-68.2c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6L278.6 256l68.2 68.2c6.3 6.3 6.3 16.4.1 22.7z"
              data-original="#ffffff" />
          </g>
        </g>
      </svg>
    </div>
  </div>
</div>