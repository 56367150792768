import { Banner, BannerRoute } from './cms.model';

export class BannerState {
  bannerDetails: BannerRotatorModel[];

  constructor(init: Partial<BannerState>) {
    Object.assign(this, init);
  }
}

export class BannersResponseModel {
  site: {
    banners: Banner[];
  };
}
export class BannerRotatorModel {
  title: string;
  imageUrl: string;
  redirectionUrl: string;
  display: BannerRotatorVisibility;
  couponCode: string;
  route: BannerRoute;

  constructor(init: Partial<BannerRotatorModel>) {
    Object.assign(this, init);
  }
}

export enum BannerRotatorLocation {
  Sports = 'sports',
  Payments = 'payments'
}

export enum BannerRotatorVisibility {
  Always,
  LoggedIn,
  LoggedOut
}
