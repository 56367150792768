import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { MyAllOpenBetsState, RecentBetModel } from 'src/app/shared/models/my-bets.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'my-all-open-bets', resettable: true })
export class MyAllOpenBetsStore extends EntityStore<MyAllOpenBetsState, RecentBetModel> {
  constructor() {
    super();
  }

  removeByCouponCode(couponCode: string): void {
    this.remove(bet => bet.couponCode === couponCode);
  }

  clear(): void {
    this.reset();
  }
}
