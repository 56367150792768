import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { MyAllBetsQuery } from 'src/app/core/state/my-bets/my-all-bets.query';
import { MyAllOpenBetsQuery } from 'src/app/core/state/my-bets/my-all-open-bets-query';
import { MyBetsStore } from 'src/app/core/state/my-bets/my-bets.store';
import { MyOpenBetsQuery } from 'src/app/core/state/my-bets/my-open-bets-query';
import { MySettledBetsQuery } from 'src/app/core/state/my-bets/my-settled-bets.query';
import { BetsTab, MyBetsState } from 'src/app/shared/models/my-bets.model';

@Injectable({ providedIn: 'root' })
export class MyBetsQuery extends Query<MyBetsState> {
  myAllBets$ = this.myAllBetsQuery.bets$;
  myOpenBets$ = this.myOpenBetsQuery.bets$;
  openBetCount$ = this.myOpenBetsQuery.openBetsCount$;
  allOpenBetCount$ = this.myAllOpenBetsQuery.allOpenBetsCount$;
  mySettledBets$ = this.mySettledBetsQuery.betsInSelectedStatus$;
  cashoutBets$ = this.mySettledBetsQuery.cashoutBets$;
  wonBets$ = this.mySettledBetsQuery.wonBets$;
  lostBets$ = this.mySettledBetsQuery.lostBets$;
  openLoading$ = this.myOpenBetsQuery.isLoading$;
  settledLoading$ = this.mySettledBetsQuery.isLoading$;
  allBetsLoading$ = this.myAllBetsQuery.isLoading$;
  selectedSettledStatus$ = this.mySettledBetsQuery.selectedCouponStatus$;

  constructor(
    private readonly myAllBetsQuery: MyAllBetsQuery,
    private readonly myOpenBetsQuery: MyOpenBetsQuery,
    private readonly mySettledBetsQuery: MySettledBetsQuery,
    private readonly myAllOpenBetsQuery: MyAllOpenBetsQuery,
    protected myBetsStore: MyBetsStore
  ) {
    super(myBetsStore);
  }

  get hasSettledData(): boolean {
    return this.mySettledBetsQuery.hasData;
  }

  get lostBetsLoaded(): boolean {
    return this.mySettledBetsQuery.lostBetsLoaded;
  }

  get selectedTab(): BetsTab {
    return this.getValue().selectedTab;
  }
}
