import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MetaData, MetaDataState } from 'src/app/modules/meta/models/meta-data.model';

function createInitialState(): MetaDataState {
  return {
    metaData: undefined,
    cmsUrl: undefined,
    language: undefined
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'meta' })
export class MetaDataStore extends Store<MetaDataState> {
  constructor() {
    super(createInitialState());
  }

  updateMetaData(metaData: MetaData[]): void {
    this.update({ metaData });
  }

  updateCmsUrl(cmsUrl: string): void {
    this.update({ cmsUrl });
  }

  updateLanguage(language: string): void {
    this.update({ language });
  }
}
