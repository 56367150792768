export class BetSearchModel {
  betFinalState: number;
  channelId: number;
  couponCode: string;
  couponDate: string;
  couponStatus: string;
  couponStatusId: number;
  currencyCode: string;
  currencySymbol: string;
  externalUserId: string;
  isAgentSettled: boolean;
  isEvaluation: boolean;
  isLive: boolean;
  placementExchangeRate: number;
  remark?: any;
  remarkDate?: any;
  settlementDate: string;
  settlementExchangeRate: number;
  stake: number;
  stakeGross: number;
  userName: string;
  won: number;

  constructor(init: Partial<BetSearchModel>) {
    Object.assign(this, init);
  }
}

export enum BetSearchPage {
  Search,
  Results
}

export enum CouponStatus {
  Any,
  InProgress,
  Losers,
  Winnings,
  Cancelled
}

export class BetSearchRequestModel {
  couponStatus: number;
  dateFrom: string;
  dateTo: string;
  pageSize: number;
  requestedPage: number;
  includUsers: boolean;
  lang: string;
  couponCode: string;
  settlementDate: boolean;

  constructor(init: Partial<BetSearchRequestModel>) {
    Object.assign(this, init);
  }
}

export interface BetSearchParams {
  dateFrom: string;
  dateTo: string;
  couponStatus: CouponStatus;
  couponCode: string;
}

export class BetSearchResultsTotal {
  stake: number;
  winnings: number;

  constructor(init: Partial<BetSearchResultsTotal>) {
    Object.assign(this, init);
  }
}
