import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { format } from 'date-fns';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, first, map, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { APIService } from 'src/app/core/services/api.service';
import { HelpService } from 'src/app/core/services/help.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { RegistrationStore } from 'src/app/core/state/registration/registration.store';
import { APIType } from 'src/app/shared/models/api.model';
import { CityModel, CountryModel, StateModel, TelephoneExtensionModel } from 'src/app/shared/models/location.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import {
  CurrencyModel,
  LongFormRegistrationModel,
  QuickRegistrationModel,
  RegistrationModel,
} from 'src/app/shared/models/registration.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private readonly endpoints = {
    ACTIVATION_OPTIONS: 'api/Security/Registration/RegistrationActivationOptions/Current',
    CURRENCIES: 'api/Security/Registration/RegistrationCurrencies',
    COUNTRIES: 'api/Security/Registration/RegistrationCountries',
    STATES: 'api/Location/Countries/{0}/States',
    CITIES: 'api/Location/States/{0}_{1}/Cities',
    TEL_EXT: 'api/Location/TelephoneExtensions/',
    CHECK_USERNAME: 'api/Security/Registration/ValidateUsername?username={0}',
    CHECK_EMAIL: 'api/Security/Registration/ValidateEmailAddress?emailAddress={0}',
    CHECK_PASSWORD: 'api/Security/Registration/ValidatePassword',
    CHECK_MOBILE_NUMBER: 'api/Security/Registration/ValidateMobileNumber',
    REGISTER_USER: 'api/Security/Registration/RegisterNewUser',
    REGISTER_BY_EMAIL: 'api/Security/Registration/RegistrationByEmail',
    REGISTER_BY_SMS: 'api/Security/Registration/RegistrationBySMS',
    NEW_AGENT_FORM: 'NewAgent/NewAgentForm',
    ACTIVATE_USER: 'api/Security/Registration/ActivateAccount',
    RESEND_ACTIVATION: 'api/Security/Registration/ResendActivationCode',
    START_BLINKING_SESSION: '/api/blinking/startblinkingsession'
  };

  private readonly loadingQueue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly apiService: APIService,
    private readonly helpService: HelpService,
    private readonly accountService: AccountService,
    private readonly notificationService: NotificationService,
    private readonly localStorage: LocalStorageService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly registrationStore: RegistrationStore,
    private readonly router: Router,

    private readonly location: Location,
    private readonly languageService: LanguageService
  ) { }

  initService(): void {
    this.destroy$ = new Subject<boolean>();

    this.loadingQueue
      .pipe(
        map(m => {
          this.registrationStore.setLoading(m > 0);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  clearOnDestroy(): void {
    this.registrationStore.updateVerificationInProgress(false);
    this.registrationStore.updateRegisterConfirmation(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getActivationOption(): Observable<any> {
    if (!this.registrationQuery.hasActivationOption) {
      this.queueLoading();
      return this.apiService.get(APIType.Platform, this.endpoints.ACTIVATION_OPTIONS).pipe(
        first(),
        map(responseData => {
          this.registrationStore.updateActivationOption(responseData.Result);
          this.registrationStore.updateRegisterOptionTriggered(true);
          this.dequeueLoading();
        }),
        catchError((err: HttpErrorResponse) => {
          this.dequeueLoading();
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      );
    }
  }

  updateRegisterOptionTriggered(registerOptionTriggered: boolean): void {
    this.registrationStore.updateRegisterOptionTriggered(registerOptionTriggered);
  }

  updateRegisterConfirmation(registerConfirmation: boolean): void {
    this.registrationStore.updateRegisterConfirmation(registerConfirmation);
  }

  getCurrencies(): void {
    if (!this.registrationQuery.hasCurrencies) {
      this.queueLoading();
      this.apiService
        .get(APIType.Platform, this.endpoints.CURRENCIES)
        .pipe(
          first(),
          map(responseData => {
            this.registrationStore.updateCurrencies(this.mapCurrencyDataToModel(responseData));
            this.dequeueLoading();
          }),
          catchError((err: HttpErrorResponse) => {
            this.dequeueLoading();
            this.registrationStore.setError(err);
            return throwError(err.message);
          })
        )
        .subscribe();
    }
  }

  getCountries(): void {
    if (!this.registrationQuery.hasCountries) {
      this.queueLoading();
      this.apiService
        .get(APIType.Platform, this.endpoints.COUNTRIES)
        .pipe(
          first(),
          map(responseData => {
            this.registrationStore.updateCountries(this.mapCountryDataToModel(responseData));
            this.dequeueLoading();
          }),
          catchError((err: HttpErrorResponse) => {
            this.dequeueLoading();
            this.registrationStore.setError(err);
            return throwError(err.message);
          })
        )
        .subscribe();
    }
  }

  getStates(country: string): void {
    this.queueLoading();
    this.apiService
      .get(APIType.Platform, this.endpoints.STATES.replace('{0}', country))
      .pipe(
        first(),
        map(responseData => {
          this.registrationStore.updateStates(this.mapStateDataToModel(responseData));
          this.dequeueLoading();
        }),
        catchError((err: HttpErrorResponse) => {
          this.dequeueLoading();
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      )
      .subscribe();
  }

  getCities(country: string, state: string): Observable<any> {
    this.queueLoading();
    return this.apiService.get(APIType.Platform, this.endpoints.CITIES.replace('{0}', country).replace('{1}', state)).pipe(
      first(),
      map(responseData => {
        this.registrationStore.updateCities(this.mapCityDataToModel(responseData));
        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.registrationStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  getTelephoneExtensions(preSelected: boolean = false, country?: string): Observable<any> {
    if (!this.registrationQuery.hasTelephoneExtensions) {
      this.queueLoading();
      return this.apiService.get(APIType.Platform, `${this.endpoints.TEL_EXT}${country && preSelected ? country : ''}`).pipe(
        first(),
        map(responseData => {
          this.registrationStore.updateTelephoneExtensions(this.mapTelephoneExtensionToModel(responseData, preSelected));
          this.dequeueLoading();
        }),
        catchError((err: HttpErrorResponse) => {
          this.dequeueLoading();
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      );
    }
  }

  checkUserName(username: string): Observable<boolean> {
    return this.performCheckCall(this.endpoints.CHECK_USERNAME.replace('{0}', username));
  }

  checkEmail(email: string): Observable<boolean> {
    return this.performCheckCall(this.endpoints.CHECK_EMAIL.replace('{0}', email));
  }

  checkPassword(password: string): Observable<boolean> {
    const callBody = {
      Password: password
    };

    return this.performCheckCallPost(this.endpoints.CHECK_PASSWORD, callBody);
  }

  checkPhoneNumber(mobileExtension: string, mobileNumber: string): Observable<boolean> {
    const callBody = {
      PhoneContactTypeCode: 'MB',
      Prefix: mobileExtension,
      Number: mobileNumber
    };

    return this.apiService.post(APIType.Platform, this.endpoints.CHECK_MOBILE_NUMBER, callBody).pipe(
      first(),
      map(responseData => responseData.Result === null),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  registerUser(user: RegistrationModel | LongFormRegistrationModel | QuickRegistrationModel): Observable<any> {
    this.registrationStore.setError(undefined);
    const callBody =
      user instanceof RegistrationModel
        ? this.mapRegistrationModelToRequestBody(user)
        : user instanceof LongFormRegistrationModel
          ? this.mapLongFormRegistrationModelToRequestBody(user)
          : this.mapQuickRegistrationModelToRequestBody(user);

    this.queueLoading();
    return this.apiService.post(APIType.Platform, this.endpoints.REGISTER_USER, callBody).pipe(
      first(),
      tap(() => {
        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        this.dequeueLoading();
        return throwError(err.message);
      })
    );
  }

  registerByEmail(userName: string): Observable<boolean> {
    const body: any = {
      Username: userName
    };

    return this.apiService.post(APIType.Platform, this.endpoints.REGISTER_BY_EMAIL, body);
  }

  registerBySms(userName: string): Observable<boolean> {
    const body: any = {
      Username: userName
    };

    return this.apiService.post(APIType.Platform, this.endpoints.REGISTER_BY_SMS, body);
  }

  activateUser(username: string, userId: string, checkCode: string): Observable<any> {
    const body: any = {
      UserId: userId,
      CheckCode: checkCode
    };

    return this.apiService.post(APIType.Platform, this.endpoints.ACTIVATE_USER, body).pipe(
      first(),
      map(responseData => {
        this.registrationStore.updateIsActivated(responseData.ResponseCode === 0);
        this.registrationStore.updateActivationErrorMessage(responseData.SystemMessage || responseData.ResponseMessage);

        if (responseData.ResponseCode === 0) {
          this.registrationStore.updateActivationUsername(username);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.updateIsActivated(false);
        this.registrationStore.updateActivationErrorMessage(err.error.SystemMessage || err.error.ResponseMessage);

        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  resendActivation(userId: string): Observable<any> {
    const body: any = {
      UserId: userId
    };

    return this.apiService.post(APIType.Platform, this.endpoints.RESEND_ACTIVATION, body).pipe(
      first(),
      map(responseData => {
        this.registrationStore.updateResendSucceeded(responseData.ResponseCode === 0);
        this.registrationStore.updateResendErrorMessage(responseData.SystemMessage || responseData.ResponseMessage);
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.updateResendSucceeded(false);
        this.registrationStore.updateResendErrorMessage(err.error.SystemMessage || err.error.ResponseMessage);

        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  startBlinkingSession(session: number): Observable<any> {
    const body: any = {
      SessionType: session
    };

    return this.apiService.post(APIType.Platform, this.endpoints.START_BLINKING_SESSION, body).pipe(
      first(),
      map(responseData => {
        if (responseData && responseData.Result) {
          const url = responseData.Result.SessionUrl;

          if (url !== '') {
            const urlWithQueryString = `${url}${url.indexOf('?') === -1 ? '?' : '&'}lang=${this.languageService.selectedLanguage.language
              }&params=status&redirectURL=${window.location.origin}/${this.languageService.selectedLanguage.language}`;

            window.location.href = urlWithQueryString;
          } else {
            // this.applicationService.navigateTo(this.helpService.getHelpPageUrls().howToRegister);
            this.router.navigate(['/account/edit-profile']);
          }
        }
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        // this.applicationService.navigateTo(this.helpService.getHelpPageUrls().howToRegister);
        this.router.navigate(['/account/edit-profile']);
        return throwError(err);
      })
    );
  }

  getRegisterDetails(): Observable<any> {
    let blinkingServiceEnabled = true;
    return this.apiService.get(APIType.CMS, 'account').pipe(
      first(),
      map(responseData => {
        if (responseData) {
          blinkingServiceEnabled = responseData.BlinkingService;
        }

        this.registrationStore.updateBlinkingServiceEnabled(blinkingServiceEnabled);
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        this.registrationStore.updateBlinkingServiceEnabled(blinkingServiceEnabled);
        return throwError(err);
      })
    );
  }

  updateActivationUsername(activationUsername: string): void {
    this.registrationStore.updateActivationUsername(activationUsername);
  }

  updateCredentials(credentialsData: any): void {
    this.registrationStore.updateCredentials(credentialsData);
  }

  clearCredentials(): void {
    this.registrationStore.clearCredentials();
  }

  getNewAgentForm(): void {
    if (!this.registrationQuery.hasNewAgentData) {
      this.apiService
        .get(APIType.CMS, this.endpoints.NEW_AGENT_FORM)
        .pipe(
          first(),
          map(data => {
            this.registrationStore.updateNewAgentForm(data);
            this.dequeueLoading();
          }),
          catchError((err: HttpErrorResponse) => {
            this.registrationStore.setError(err);
            this.dequeueLoading();
            return throwError(err.message);
          })
        )
        .subscribe();
    }
  }

  onRegisterSuccess(username: string, password: string): void {
    if (this.registrationQuery.activationOption === 'ByEmail') {
      this.registerByEmail(username)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          // success
          // TODO By Email
        });
    } else if (this.registrationQuery.activationOption === 'BySms') {
      this.registerBySms(username)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            // success
            this.updateCredentials({
              user: username,
              pass: password
            });

            this.registrationStore.updateVerificationInProgress(true);
          },
          () => {
            // error
            this.notificationService.showNotification(
              new NotificationSettings({
                allowBackdropClose: true,
                contentText: translate('Registration successful! Could not verify Phone number. Please verify phone number when login.'),
                type: 'success'
              })
            );

            window.location.href = '/';
          }
        );
    } else {
      this.registeredSuccess(username, password);
    }
  }

  private registeredSuccess(username: string, password: string): void {
    const callback = () => {
      this.accountService
        .login(username, password, true)
        .pipe(
          first(),
          finalize(() => {
            this.location.back();
          })
        )
        .subscribe();
    };

    this.notificationService.showNotification(
      new NotificationSettings({
        allowBackdropClose: true,
        closeButtonCallback: callback,
        confirmButtonCallback: callback,
        contentText: translate('Registration successful'),
        type: 'success',
        showConfirmButton: true
      })
    );
  }

  private mapRegistrationModelToRequestBody(user: RegistrationModel): object {
    const userData = {
      Addresses: [
        {
          CountryCode: 'SRB',
          AddressTypeCode: 'MAIN'
        }
      ],
      AccountActivationResendUrl: this.getActivationLink(true, user.username),
      AccountActivationUrl: this.getActivationLink(false, user.username),
      BannerTag: this.localStorage.retrieve('btag'),
      DateOfBirth: format(new Date('1900-01-01'), 'yyyy-MM-dd'),
      Email: user.email,
      Username: user.username,
      Password: user.password,
      CurrencyISOCode: user.currency,
      CommunicationPrefferedLanguageISOCode: this.languageService.selectedLanguage.languageIsoCode,
      DepositLimitAmount: undefined,
      DepositLimitPeriodId: '1',
      PhoneContacts: [],
      ExtraRegistrationValues: {
        IsPoliticallyExposed: user.isPoliticallyExposed,
        ReceiveMarketingMessages: user.receiveEmails,
        PromoCode: user.promoCode
      }
    };

    if (user.mobileNumber) {
      userData.PhoneContacts.push({
        PhoneContactTypeCode: 'MB',
        Prefix: user.mobilePrefix,
        Number: user.mobileNumber
      });
    } else {
      delete userData.PhoneContacts;
    }

    return userData;
  }

  private mapLongFormRegistrationModelToRequestBody(user: LongFormRegistrationModel): object {
    return {
      FirstName: user.firstName,
      LastName: user.lastName,
      GenderCode: user.gender,
      DateOfBirth: format(new Date(user.dateOfBirth), 'yyyy-MM-dd'),
      Addresses: [
        {
          CountryCode: user.country,
          Region: user.state,
          Province: '',
          City: user.city,
          Line1: user.address,
          PostalCode: 'N/A',
          AddressTypeCode: 'MAIN'
        }
      ],
      Email: user.email,
      PhoneContacts: [
        {
          PhoneContactTypeCode: 'MB',
          Prefix: user.mobilePrefix,
          Number: user.mobileNumber
        }
      ],
      Username: user.username,
      Password: user.password,
      CurrencyISOCode: user.currency,
      PromotionalCode: user.promotionCode,
      TaxCode: 'N/A',
      SiteDiscoveryMethodCode: '',
      CommunicationPrefferedLanguageISOCode: this.languageService.selectedLanguage.languageIsoCode,
      PersonalQuestionCode: 'QST1',
      PersonalQuestionAnswer: 'Not Provided'
    };
  }

  private mapQuickRegistrationModelToRequestBody(user: QuickRegistrationModel): object {
    return {
      Addresses: [
        {
          CountryCode: user.country,
          AddressTypeCode: 'MAIN'
        }
      ],
      PhoneContacts: [
        {
          PhoneContactTypeCode: 'MB',
          Prefix: user.mobilePrefix,
          Number: user.mobileNumber
        }
      ],
      Password: user.password,
      CurrencyISOCode: user.currency,
      PromotionalCode: user.promotionCode
    };
  }

  private getActivationLink(resend: boolean, username: string): string {
    let url = `${window.location.origin}/activate?username=${username}`;
    if (resend) {
      url += '&resend=true&userid={userid}';
    } else {
      url += '&userid={userid}&checkcode={checkcode}';
    }
    return url;
  }

  private performCheckCall(api: string): Observable<boolean> {
    return this.apiService.get(APIType.Platform, api).pipe(
      first(),
      map(responseData => responseData.Result === null),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  private performCheckCallPost(api: string, callBody: any): Observable<boolean> {
    return this.apiService.post(APIType.Platform, api, callBody).pipe(
      first(),
      map(responseData => responseData.Result === null),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  private mapCurrencyDataToModel(responseData: any): CurrencyModel[] {
    const currencies: CurrencyModel[] = [];
    responseData.Result.forEach(currency => {
      currencies.push(
        new CurrencyModel({
          isoCode: currency.IsoCode,
          name: currency.Name,
          symbol: currency.Symbol
        })
      );
    });

    return currencies;
  }

  private mapCountryDataToModel(responseData: any): CountryModel[] {
    const countries: CountryModel[] = [];
    responseData.Result.forEach(country => {
      countries.push(
        new CountryModel({
          threeLetterCode: country.IsoCode,
          name: country.Name
        })
      );
    });

    return countries;
  }

  private mapStateDataToModel(responseData: any): StateModel[] {
    const states: StateModel[] = [];
    responseData.Result.forEach(state => {
      states.push(
        new StateModel({
          code: state.Code,
          name: state.Name
        })
      );
    });

    return states;
  }

  private mapCityDataToModel(responseData: any): CityModel[] {
    const cities: CityModel[] = [];
    responseData.Result.forEach(city => {
      cities.push(
        new CityModel({
          id: city.Id,
          name: city.Name
        })
      );
    });

    return cities;
  }

  private mapTelephoneExtensionToModel(responseData: any, preSelected: boolean): TelephoneExtensionModel[] {
    const telephoneExtensions: TelephoneExtensionModel[] = [];
    if (preSelected) {
      telephoneExtensions.push(
        new TelephoneExtensionModel({
          countryCode: responseData.Result.CountryCode,
          prefixNumber: responseData.Result.PrefixNumber
        })
      );
    } else {
      responseData.Result.forEach(telExt => {
        telephoneExtensions.push(
          new TelephoneExtensionModel({
            countryCode: telExt.CountryCode,
            prefixNumber: telExt.PrefixNumber
          })
        );
      });
    }

    return telephoneExtensions;
  }

  private queueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value + 1);
  }

  private dequeueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value - 1);
  }
}
