import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ApplicationService } from 'src/app/core/services/application.service';

import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { TodaysEventsQuery } from 'src/app/core/state/todays-events/todays-events.query';
import { expandCollapse } from 'src/app/shared/animations';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-single-line-event-without-selector',
  templateUrl: './single-line-event-without-selector.component.html',
  styleUrls: ['./single-line-event-without-selector.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleLineEventWithoutSelectorComponent {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showDate = true;
  @Input() showBorder: boolean = true;
  @Input() showDetails: boolean = true;
  @Input() first?: boolean;
  @Input() last?: boolean;
  @Input() isAvailable: boolean = true;
  @Input() showTournament: boolean = true;
  @Input() specials?: boolean;

  @Input() hasTeamAway = true;

  constructor(
    readonly statisticsQuery: StatisticsQuery,
    private readonly matchService: MatchService,
    private readonly todaysEventsQuery: TodaysEventsQuery,
    private readonly appService: ApplicationService
  ) {}

  navigateToMatch(): void {
    if (this.specials) {
      return;
    }

    this.matchService.navigateToMatch(this.event.id, this.event.name);
  }
}
