import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountPaymentQuery } from 'src/app/core/state/account/account.payments.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';

import { AccountPaymentService } from '../../../../core/services/account/account-payment.service';
import { AccountService } from '../../../../core/services/account/account.service';
import { AppConfigService } from '../../../../core/services/app-config.service';
import { RegistrationService } from '../../../../core/services/registration.service';
import { DebitCardModel } from '../../../../shared/models/account.model';
import { AccountQuery } from 'src/app/core/state/account/account.query';

@Component({
  selector: 'app-saved-cards',
  templateUrl: './saved-cards.component.html',
  styleUrls: ['./saved-cards.component.scss']
})
export class SavedCardsComponent implements OnInit {
  loading$ = new BehaviorSubject(false);
  noCards$ = new BehaviorSubject(false);
  savedCardsDisclaimer$ = new BehaviorSubject('');
  constructor(
    private readonly accountPaymentService: AccountPaymentService,
    public accountPaymentQuery: AccountPaymentQuery,
    private readonly registrationService: RegistrationService,
    public accountService: AccountService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly router: Router,
    private readonly appConfigService: AppConfigService,
    private readonly accountQuery: AccountQuery
  ) {}

  ngOnInit(): void {
    this.loading$.next(true);
    this.initDisclaimerListener();
    this.accountPaymentService.getRegisteredCards().subscribe(
      res => {
        this.loading$.next(false);
        this.noCards$.next(!res.length);
      },
      error => {
        this.loading$.next(false);
      }
    );

    this.isVerifiedUser();
  }
  initDisclaimerListener() {
    this.accountPaymentService.getDepositPaymentList(true).subscribe();
    this.accountPaymentQuery.savedCardsDisclaimer$.subscribe(savedCardsDisclaimer => {
      this.savedCardsDisclaimer$.next(savedCardsDisclaimer);
    });
  }
  trackById(card: DebitCardModel): number {
    return card.id;
  }
  isVerifiedUser(): boolean {
    return (
      (this.accountQuery.hasValidId && this.accountQuery.hasValidMobilePhone && this.accountQuery.emailVerified) ||
      this.accountQuery.skipSessionOne
    );
  }
  startBlinkingSession(): void {
    this.registrationService.getRegisterDetails().subscribe(() => {
      const KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();

      if (this.registrationQuery.blinkingServiceEnabled) {
        let session;
        switch (KYCDocumentsStatus) {
          case 'documentsNeeded':
            session = 1;
            break;
          case 'accountNumberNeeded':
            session = 2;
            break;
          case 'pending':
            session = 2;
            break;
          default:
            session = -1;
            break;
        }

        if (session !== -1) {
          this.router.navigate(['/account/verification-choice'], { queryParams: { sessionType: session } });
        }
      } else {
        if (this.accountService.canUploadBankAccountImage()) {
          this.router.navigate(['/account/change-bank-account-number']);
        } else {
          this.router.navigate(['/account/edit-profile']);
        }
      }
    });
  }
  strenghtenProfile(): void {
    this.router.navigate(['/strenghten-profile']);
  }
  deleteCard(id) {
    this.loading$.next(true);

    this.accountPaymentService.deleteSavedCard(id).subscribe(res => {
      this.accountPaymentService.getRegisteredCards().subscribe(res => {
        this.loading$.next(false);
        this.noCards$.next(!res.length);
      });
    });
  }
  navigateTo(link: string): void {
    this.router.navigate([link]);
  }
}
