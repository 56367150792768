import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe extends DecimalPipe implements PipeTransform {
  constructor(
    private readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService
  ) {
    super(languageService.selectedLanguage ? languageService.selectedLanguage.language : 'en');
  }

  transform(input: number, digitsInfo?: string): any {
    let value = input;
    if (value === undefined) {
      value = 0;
    }

    const isNegative = value < 0;
    // The above is used to trancate the value to 2 decimal places EG from 99.9999 to 99.99 Task BTK-2655
    const trancatedValue = Math.trunc(value * 100) / 100;

    const superFormat = super.transform(
      isNegative ? trancatedValue * -1 : trancatedValue,
      digitsInfo ? digitsInfo : this.currencyService.getCurrency(this.applicationQuery.currency).format,
      this.languageService.selectedLanguage.language
    );

    return this.currencyService.getCurrency(this.applicationQuery.currency).inverse
      ? `${isNegative ? '-' : ''}${superFormat}${this.currencyService.getCurrency(this.applicationQuery.currency).hasSpace ? ' ' : ''}${
          this.currencyService.getCurrency(this.applicationQuery.currency).symbol
        }`
      : `${isNegative ? '-' : ''}${this.currencyService.getCurrency(this.applicationQuery.currency).symbol}${
          this.currencyService.getCurrency(this.applicationQuery.currency).hasSpace ? ' ' : ''
        }${superFormat}`;
  }
}
