import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ApplicationService } from 'src/app/core/services/application.service';
import { PromoFabState } from 'src/app/shared/models/promo-fab.model';
import { PromoFabStore } from './promo-fab.store';

@Injectable({ providedIn: 'root' })
export class PromoFabQuery extends Query<PromoFabState> {
  isVisible$ = this.select(state => state.enabled && !state.hide && !this.appService.isInIFrame());

  state$ = this.select();

  images$ = this.select(state => ({
    image1: state.image1,
    image2: state.image2,
    image3: state.image3
  }));

  constructor(
    protected store: PromoFabStore,
    private appService: ApplicationService
  ) {
    super(store);
  }
}
