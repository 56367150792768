export enum InputType {
  Text,
  Number,
  Password
}

export class InputModel {
  placeholder: string;
  formControlName: string;
  inputType: InputType = InputType.Text;

  constructor(init: Partial<InputModel>) {
    Object.assign(this, init);
  }

  get textboxType(): string {
    switch (this.inputType) {
      case InputType.Text:
        return 'text';
      case InputType.Number:
        return 'number';
      case InputType.Password:
        return 'password';
      default:
        return 'text';
    }
  }
}
