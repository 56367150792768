import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { kebabCase } from 'lodash-es';

@Directive({
  selector: '[appDynamicSvgMask]'
})
export class DynamicSvgMaskDirective implements AfterViewChecked {
  @Input() appDynamicSvgMask: string;

  constructor(public elementRef: ElementRef, readonly renderer: Renderer2) {}

  ngAfterViewChecked(): void {
    const normalizedFileName = this.appDynamicSvgMask
      .split('/')
      .map(kebabCase)
      .join('/');

    this.renderer.setStyle(this.elementRef.nativeElement, 'webkitMaskImage', `url(./assets/${normalizedFileName}.svg)`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'webkitMaskSize', '100%');
  }
}
