<div>
  <div class="event">
    <div class="top-elements">
      <div class="left-elements">
        <div class="chart-wrapper" *ngIf="statisticsService.isStatisticsEnabled()">
          <i class="chart-icon fa fa-bar-chart" aria-hidden="true" *ngIf="event.externalId"
            (click)="openStatistics()"></i>
        </div>
        <div class="checkbox-wrapper" (click)="toggleCheckbox()">
          <div class="sports-checkbox sm responsive">
            <i class="fa fa-check" aria-hidden="true" *ngIf="event.selectedInView"
              [@fadeInOutBy]="{ value: '', params: { duration: 200 } }"></i>
          </div>
        </div>
      </div>
      <div class="right-elements">
        <app-event-header [event]="event" [fullVersion]="false">
        </app-event-header>
        <div class="match-info">
          <app-event-match-info [event]="event"></app-event-match-info>
        </div>
      </div>
    </div>
    <div class="odds-wrapper">
      <app-multi-line-market-odds [odds]="event.odds" [markets]="displayedMarkets$ | async" *ngIf="isAvailable">
      </app-multi-line-market-odds>
      <app-not-available-message *ngIf="!isAvailable"></app-not-available-message>
    </div>
  </div>
  <app-statistics *ngIf="event.externalId === (statisticsQuery.selectedExternalId$ | async)"
    [externalId]="event.externalId"></app-statistics>
</div>
