import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BannerRotatorService } from 'src/app/core/services/banner-rotator.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { PageVisibilityApi } from 'src/app/core/services/page-visibility-api.service';
import { BannerRotatorQuery } from 'src/app/core/state/banner-rotator/banner-rotator.query';
import { RotatorContainerDirective } from 'src/app/shared/directives/rotator-container.directive';
import { BannerRotatorLocation, BannerRotatorModel } from 'src/app/shared/models/banner-rotator.model';
import SwiperCore, { Autoplay, Pagination, SwiperOptions } from 'swiper';
import { BookBetService } from '../../../core/services/book-bet.service';
import { BannerRoute } from '../../models/cms.model';
SwiperCore.use([Autoplay, Pagination]);

@Component({
  selector: 'app-banner-rotator',
  templateUrl: './banner-rotator.component.html',
  styleUrls: ['./banner-rotator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerRotatorComponent implements OnInit, OnDestroy {
  @Input() location = BannerRotatorLocation.Sports;
  @Input() bannerRoute?: string = null;

  @ViewChildren(RotatorContainerDirective) viewChildren: QueryList<RotatorContainerDirective>;
  showArrows$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showBannerRotator$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  autoRotateInterval: any;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 10,
    speed: 1000,
    pagination: { clickable: true },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    }
  };

  private readonly destroy$ = new Subject<any>();
  private couponCodeClicked = false;

  constructor(
    readonly bannerRotatorQuery: BannerRotatorQuery,
    private readonly pageVisibilityApi: PageVisibilityApi,
    private readonly bannerRotatorService: BannerRotatorService,
    private readonly bookBetService: BookBetService,
    private readonly languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.bannerRotatorService.getBannersData(this.location).subscribe();

    this.bannerRotatorQuery.bannerDetails$.pipe(takeUntil(this.destroy$)).subscribe(bannerDetails => {
      this.disableAutoRotate();
      this.showArrows$.next(false);

      if (bannerDetails && bannerDetails.length > 0 && !!bannerDetails.find(banner => this.isDisplayedOnRoute(banner))) {
        this.showBannerRotator$.next(true);

        if (bannerDetails.length > 1) {
          this.enableAutoRotate();
          this.showArrows$.next(true);
        }
      }
    });

    this.pageVisibilityApi.isPageVisible$.subscribe(isPageVisible => {
      if (isPageVisible) {
        this.disableAutoRotate();
        this.enableAutoRotate();
      } else {
        this.disableAutoRotate();
      }
    });
  }

  ngOnDestroy(): void {
    this.disableAutoRotate();
    this.destroy$.next();
    this.destroy$.complete();
  }

  bannerDetailTrackBy(index: number): number {
    return index;
  }

  enableAutoRotate(): void {
    this.autoRotateInterval = window.setInterval(() => {
      this.viewChildren.first && this.viewChildren.first.onNextClick();
    }, 5000);
  }

  disableAutoRotate(): void {
    clearInterval(this.autoRotateInterval);
  }

  isDisplayedOnRoute(banner: BannerRotatorModel): boolean {
    if (!banner.route) {
      return true;
    }
    const targetRoute = (this.bannerRoute || window.location.pathname).toLowerCase();

    return targetRoute.includes(banner.route.toLocaleLowerCase());
  }

  onNextClick(): void {
    if (!this.showArrows$.value) {
      return;
    }
    this.disableAutoRotate();
    this.viewChildren.first && this.viewChildren.first.onNextClick();
    this.enableAutoRotate();
  }

  onPreviousClick(): void {
    if (!this.showArrows$.value) {
      return;
    }
    this.disableAutoRotate();
    this.viewChildren.first.onPreviousClick();
    this.enableAutoRotate();
  }

  clickBannerCouponCode(couponCode: string): void {
    if (!this.couponCodeClicked) {
      this.couponCodeClicked = true;
      this.bookBetService
        .getBookBet(couponCode, this.languageService.selectedLanguage.language)
        .subscribe(() => (this.couponCodeClicked = false));
    }
  }
}
