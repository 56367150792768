import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';

declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class SoliticsAnalyticsService {
  constructor(private readonly appConfig: AppConfigService) {}

  init(): void {
    const url: string = this.appConfig.get('apiBaseUrl')?.soliticsAnalytics;
    if (url) {
      this.loadScripts(url);
    } else {
      console.error('Solitics SDK not loaded');
    }
  }

  loadScripts(url: string): void {
    const script1 = document.createElement('script');
    script1.src = url;
    const script2 = document.getElementsByTagName('script')[0];
    script2.parentNode.insertBefore(script1, script2);
  }

  sendLoginSuccessEvent({ memberId, email }: { memberId: string; email: string }): void {
    window?.$solitics?.loginSuccess({
      memberId,
      email,
      branch: 'Victory247SRB',
      brand: this.appConfig.get('soliticsBrandId'),
      popupToken: this.appConfig.get('soliticsPopupToken')
    });
  }
}
