import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningMessageComponent {
  @Input() message: string = 'Something went wrong!';
}
