import { NgModule } from '@angular/core';

import { SharedModule } from 'src/app/shared/shared.module';
import { CouponGroupingsComponent } from './components/coupon-groupings/coupon-groupings.component';
import { CouponMenuComponent } from './components/coupon-menu/coupon-menu.component';
import { CouponPageWrapperComponent } from './components/coupon-page-wrapper/coupon-page-wrapper.component';
import { CouponSelectionsComponent } from './components/coupon-selections/coupon-selections.component';
import { CouponTotalsComponent } from './components/coupon-totals/coupon-totals.component';
import { CouponWalletSelectorComponent } from './components/coupon-wallet-selector/coupon-wallet-selector.component';
import { CouponRoutingModule } from './coupon-routing.module';
import { CouponComponent } from './coupon.component';

const COMPONENTS = [
  CouponComponent,
  CouponSelectionsComponent,
  CouponGroupingsComponent,
  CouponTotalsComponent,
  CouponWalletSelectorComponent,
  CouponMenuComponent,
  CouponPageWrapperComponent
];

@NgModule({
  imports: [SharedModule, CouponRoutingModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class CouponModule {}
