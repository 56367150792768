import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import * as dayjs from 'dayjs';
import { interval, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicationService } from 'src/app/core/services/application.service';
import { BottomSheetService } from 'src/app/core/services/bottom-sheet.service';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  promptEvent: any;

  installEventReady$: Subject<boolean> = new Subject();
  installEventReady = false;

  openPrompt$: Subject<boolean> = new Subject();

  private readonly checkForUpdateIntervalMs = 60000;

  private readonly askToInstallSkipDays = [1, 1, 3, 1, 1, 2];

  private readonly askToInstallSkipStepStorageKey = 'pwa-skip-day-step';
  private readonly askToInstallAtTimestampStorageKey = 'pwa-ask-timestamp';

  constructor(
    private readonly swUpdate: SwUpdate,
    private readonly appService: ApplicationService,
    private readonly bottomSheet: BottomSheetService
  ) {
    this.init();
    this.openPrompt$.subscribe(() => {
      this.promptEvent.prompt();
    });
  }

  askToInstallApp(): void {
    if (!this.installEventReady || this.shouldSkipAskingToInstall()) {
      return;
    }

    this.setLastAskToInstallTimestamp();
    this.bottomSheet.open({
      installPWA: true
    });
  }

  private init(): void {
    this.handleNewAppVersion();
    window.addEventListener('beforeinstallprompt', this.handleBeforeInstallPromptEvent.bind(this));
  }

  private handleNewAppVersion(): void {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.versionUpdates
      .pipe(filter((v: VersionEvent, i: number) => v.type === 'VERSION_READY'))
      .subscribe(this.reloadApp.bind(this));

    this.handleAppUpdate();
    this.checkForAppUpdate();

    interval(this.checkForUpdateIntervalMs).subscribe(this.checkForAppUpdate.bind(this));
  }

  private handleAppUpdate(): void {
    this.swUpdate.versionUpdates
      .pipe(filter((v: VersionEvent, i: number) => v.type === 'VERSION_READY'))
      .subscribe(this.reloadApp.bind(this));
  }

  private checkForAppUpdate(): void {
    this.swUpdate.checkForUpdate();
  }

  private reloadApp(): void {
    window.location.reload();
  }

  private handleBeforeInstallPromptEvent(event: any): void {
    event.preventDefault();
    this.promptEvent = event;

    if (this.appService.isInPWA()) {
      return;
    }

    this.installEventReady$.next(true);
    this.installEventReady = true;
  }

  private setLastAskToInstallTimestamp(): void {
    let askToInstallStep = +localStorage.getItem(this.askToInstallSkipStepStorageKey) || 0;
    askToInstallStep = askToInstallStep !== undefined ? askToInstallStep++ : 0;

    if (!this.askToInstallSkipDays[askToInstallStep]) {
      localStorage.setItem(this.askToInstallAtTimestampStorageKey, 'undefined');
      return;
    }

    localStorage.setItem(this.askToInstallSkipStepStorageKey, `${askToInstallStep}`);
    localStorage.setItem(
      this.askToInstallAtTimestampStorageKey,
      dayjs().add(this.askToInstallSkipDays[askToInstallStep], 'day').toISOString()
    );
  }

  private shouldSkipAskingToInstall(): boolean {
    const askToInstallAfterTimestamp = localStorage.getItem(this.askToInstallAtTimestampStorageKey);
    if (!askToInstallAfterTimestamp) {
      return false;
    }

    if (askToInstallAfterTimestamp === 'undefined') {
      return true;
    }

    return !dayjs().isAfter(askToInstallAfterTimestamp);
  }
}
