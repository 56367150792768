import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationState, ApplicationUIState, CmsModel, LoaderState, ProductType } from 'src/app/shared/models/application.model';
import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { ApplicationStore, createInitialApplicationState } from './application.store';

@Injectable({
  providedIn: 'root'
})
export class ApplicationQuery extends Query<ApplicationState> {
  // UI
  loaderState$ = this.select(state => state.ui.loader);
  showMenu$ = this.select(state => state.ui.showMenu);
  showAccountMenu$ = this.select(state => state.ui.showAccountMenu);
  showNavBar$ = this.select(state => state.ui.showNavBar);
  showLogin$ = this.select(state => state.ui.showLogin);
  showCoupon$ = this.select(state => state.ui.showCoupon);
  showQuickCoupon$ = this.select(state => state.ui.showQuickCoupon);
  showMyBets$ = this.select(state => state.ui.showMyBets);
  showDropdownOverlay$ = this.select(state => state.ui.showDropdownOverlay);
  showVirtualsBetslip$ = this.select(state => state.ui.showVirtualsBetslip);
  loginState$ = this.select(state => state.ui.loginState);
  isZohoLiveChatLoading$ = this.select(state => state.ui.isZohoLiveChatLoading);
  loadingSFKChat$ = this.select(state => state.ui.loadingSFKChat);
  showSFKChat$ = this.select(state => state.ui.showSFKChat);
  isLoadingQuicklinks$ = this.select(state => state.ui.loadingQuicklinks);
  landingMenuExpanded$ = this.select(state => state.ui.landingMenuExpanded);
  scrollY$ = this.select(state => state.ui.scrollY);
  activeUrl$ = this.select(state => state.activeUrl);
  currency$ = this.select(() =>
    this.accountQuery.isAuthenticated
      ? this.accountQuery.userData.currency.code
      : this.appConfigService.get('sports').coupon.defaultCurrency
  );
  activeProduct$ = this.select(state => state.activeProduct);

  generalSiteMessages$ = this.select(state => state.siteMessages.general);
  sportsSiteMessages$ = this.select(state => state.siteMessages.sports);
  cms$ = this.select(state => state.cms);
  sideBarMenu$ = this.select(state => state.sideBarMenu);
  landingHeaderType$ = this.select(state => state.ui.configUI.landing.headerType.toLowerCase());
  footerShowPaymentMethodImage$ = this.select(state => state.ui.configUI.footer.footerShowPaymentMethodImage);
  showLiveBadge$ = this.select(state => state.ui.configUI.live.showLiveBadge);
  showAwardIcons$ = this.select(state => state.ui.configUI.footer.showAwardIcons);
  showBrandLogo$ = this.select(state => state.ui.configUI.footer.showBrandLogo);

  // this combineLatest needs to include the show states of all the existing slide up panels
  isSlideUpVisible$ = combineLatest(
    this.showMenu$,
    this.showAccountMenu$,
    this.showLogin$,
    this.showCoupon$,
    this.showMyBets$,
    this.showDropdownOverlay$,
    this.showSFKChat$
  ).pipe(map(states => states.findIndex(state => state) >= 0));

  constructor(
    protected store: ApplicationStore,
    private readonly appConfigService: AppConfigService,
    private readonly accountQuery: AccountQuery
  ) {
    super(store);
  }

  get slideUpResetObject(): ApplicationUIState {
    return createInitialApplicationState().ui;
  }

  get currency(): string {
    return this.accountQuery.isAuthenticated && this.accountQuery.userData.currency.name
      ? this.accountQuery.userData.currency.name
      : this.appConfigService.get('sports').coupon.defaultCurrency;
  }
  get landingMenuExpanded(): boolean {
    return this.getValue().ui.landingMenuExpanded;
  }

  get activeUrl(): string[] {
    return this.getValue().activeUrl;
  }

  get cms(): CmsModel {
    return this.getValue().cms;
  }

  get loaderState(): LoaderState {
    return this.getValue().ui.loader;
  }

  get showSFKChat(): boolean {
    return this.getValue().ui.showSFKChat;
  }

  get showMenu(): boolean {
    return this.getValue().ui.showMenu;
  }

  get showAccountMenu(): boolean {
    return this.getValue().ui.showAccountMenu;
  }

  get showNavBar(): boolean {
    return this.getValue().ui.showNavBar;
  }

  get showLogin(): boolean {
    return this.getValue().ui.showLogin;
  }

  get showCoupon(): boolean {
    return this.getValue().ui.showCoupon;
  }

  get showQuickCoupon(): boolean {
    return this.getValue().ui.showQuickCoupon;
  }

  get showMyBets(): boolean {
    return this.getValue().ui.showMyBets;
  }

  get showDropdownOverlay(): boolean {
    return this.getValue().ui.showDropdownOverlay;
  }

  get showVirtualsBetslip(): boolean {
    return this.getValue().ui.showVirtualsBetslip;
  }

  get loginState(): string {
    return this.getValue().ui.loginState;
  }

  get hasSidebarQuicklinks(): boolean {
    return this.getValue().sideBarQuickLinks.length > 0;
  }

  get sideBarQuickLinks$(): Observable<MenuItemModel[]> {
    return this.select(state => state.sideBarQuickLinks).pipe(
      map((mim: MenuItemModel[]) =>
        mim.filter(item => (this.accountQuery.isAuthenticated ? item.showWhenLoggedIn : item.showWhenLoggedOut))
      )
    );
  }

  get activeProduct(): ProductType {
    return this.getValue().activeProduct;
  }
}
