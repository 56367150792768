import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { finalize, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { ProfileEditability, VerifyAccountType } from 'src/app/shared/models/account.model';
import { ButtonType } from 'src/app/shared/models/button.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import { LongFormRegistrationModel } from 'src/app/shared/models/registration.model';
import { brandInfo } from 'src/brand-info';

@Component({
  selector: 'app-change-bank-account-number',
  templateUrl: './change-bank-account-number.component.html',
  styleUrls: ['./change-bank-account-number.component.scss']
})
export class ChangeBankAccountNumberComponent implements OnInit, OnDestroy {
  @ViewChildren('bankAccountNumber') bankAccountNumber: QueryList<any>;
  changeBankAccountNumberForm: FormGroup;
  profileEditability: ProfileEditability = this.appConfig.get('account').profileEditability;
  verifyData: any;
  verifyAccountType: typeof VerifyAccountType = VerifyAccountType;
  brandInfo = brandInfo;

  buttonType = ButtonType;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly verifyAccountDataKey = 'verifyAccountData';

  constructor(
    readonly accountQuery: AccountQuery,
    readonly registrationQuery: RegistrationQuery,
    readonly accountService: AccountService,
    readonly applicationService: ApplicationService,
    private readonly registrationService: RegistrationService,
    private readonly appConfig: AppConfigService,
    private readonly notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.accountService.getKYCStatus(this.accountQuery.accessToken).subscribe(() => {
      const KYCDocumentsStatus = this.accountService.getKYCDocumentsStatus();
      if (KYCDocumentsStatus === 'documentsNeeded' || KYCDocumentsStatus === 'accountNumberNeeded') {
        this.accountService.getBlinkingSessionStatus(this.accountQuery.accessToken).subscribe();
      }
    });

    this.registrationService.initService();

    this.changeBankAccountNumberForm = this.initialiseForm();

    this.accountQuery.error$.pipe(takeUntil(this.destroy$)).subscribe(err => {
      if (err) {
        const callback = () => {
          this.accountService.clearError();
        };

        this.notificationService.showNotification(
          new NotificationSettings({
            allowBackdropClose: true,
            closeButtonCallback: callback,
            confirmButtonCallback: callback,
            contentText: translate('Something went wrong! Please try again or contact support.'),
            type: 'error',
            showConfirmButton: true
          })
        );
      }
    });
  }

  onKey(event: any): void {
    const keyCode = event.which || event.keyCode;

    const clickedEl = event.currentTarget;

    let el;
    if (clickedEl.value.length === 0 && (keyCode === 8 || keyCode === 37)) {
      el = this.bankAccountNumber.find(g => parseInt(g.nativeElement.dataset.elem, 10) === parseInt(event.target.dataset.elem, 10) - 1);
    } else if (
      clickedEl.value.length === clickedEl.maxLength &&
      ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))
    ) {
      el = this.bankAccountNumber.find(g => parseInt(g.nativeElement.dataset.elem, 10) === parseInt(event.target.dataset.elem, 10) + 1);
    }

    if (el) {
      el.nativeElement.focus();
    }
  }

  addZeros_blur(event: any): void {
    const clickedEl = event.currentTarget;
    const addZeros = clickedEl.value;
    const maxLength = clickedEl.maxLength;

    clickedEl.value = this.addZeros(addZeros, maxLength);
  }

  addZeros(str: string, maxLength: number): string {
    let zerosMaxLength = '';
    for (let i = 0; i < maxLength -1; i++) {
        zerosMaxLength += '0';
    }

    return str.replace(/\d+/g, m => zerosMaxLength.substr(m.length - 1) + m);
}

  onCancel(): void {
    this.changeBankAccountNumberForm.reset();

    this.changeBankAccountNumberForm = this.initialiseForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.registrationService.clearOnDestroy();
  }

  onSubmit(): void {
    if (this.changeBankAccountNumberForm.valid) {
      const bankAccountNumberPart1 = this.changeBankAccountNumberForm.controls.bankAccountNumberPart1.value;
      const bankAccountNumberPart2 = this.changeBankAccountNumberForm.controls.bankAccountNumberPart2.value
      const bankAccountNumberPart3 = this.changeBankAccountNumberForm.controls.bankAccountNumberPart3.value;
      const bankAccountNumber = `${bankAccountNumberPart1}-${bankAccountNumberPart2}-${bankAccountNumberPart3}`;

      const user = new LongFormRegistrationModel({
        bankAccountNumber: bankAccountNumber
      });

      this.accountService
        .changeBankAccountNumber(user)
        .pipe(
          // tslint:disable-next-line: cyclomatic-complexity
          tap(() => {
            this.changeBankAccountNumberForm.markAsPristine();
            if (this.profileEditability === ProfileEditability.OneTime) {
              if (this.changeBankAccountNumberForm.controls.bankAccountNumberPart1.value !== undefined &&
                  this.changeBankAccountNumberForm.controls.bankAccountNumberPart1.value !== '') {
                this.changeBankAccountNumberForm.controls.bankAccountNumberPart1.disable();
              }
              if (this.changeBankAccountNumberForm.controls.bankAccountNumberPart2.value !== undefined &&
                  this.changeBankAccountNumberForm.controls.bankAccountNumberPart2.value !== '') {
                this.changeBankAccountNumberForm.controls.bankAccountNumberPart2.disable();
              }
              if (this.changeBankAccountNumberForm.controls.bankAccountNumberPart3.value !== undefined &&
                  this.changeBankAccountNumberForm.controls.bankAccountNumberPart3.value !== '') {
                this.changeBankAccountNumberForm.controls.bankAccountNumberPart3.disable();
              }
            }
          }),
          switchMap(() => this.accountService.getUserData(this.accountQuery.accessToken)),
          finalize(() => {
            if (this.accountService.getKYCDocumentsStatus() !== 'verified') {
              this.notificationService.showSuccessNotification(
                translate(`To complete the registration, you need to send us your personal documents by clicking on the form 'Upload Documents'.`),
                translate('Your Bank Account Number has been saved successfully!')
              );
            } else {
              this.notificationService.showSuccessMessage(translate('Bank Account Number updated successfully'));
            }


          })
        )
        .subscribe();
    }
  }

  private splitBankAccountNumber(accountNumber: string, textbox: number): string {
    if (accountNumber.indexOf('-') !== -1) { // dashes found
      switch (textbox) {
        case 1:
          return accountNumber.split('-')[0];
        case 2:
          return accountNumber.split('-')[1];
        case 3:
          return accountNumber.split('-')[2];
        default:
          return '';
      }
    } else { // no dashes found
      switch (textbox) {
        case 1:
          return accountNumber.substring(0, 3);
        case 2:
          return accountNumber.substring(2, 13);
        case 3:
          return accountNumber.substring(12, accountNumber.length);
        default:
          return '';
      }
    }
  }

  private initialiseForm(): FormGroup {
    return new FormGroup({
      bankAccountNumberPart1: new FormControl(
        {
          value: this.accountQuery.userData.bankAccountDetails ?
             this.splitBankAccountNumber(this.accountQuery.userData.bankAccountDetails.AccountNumber, 1) : '',
          disabled: this.disableFormControl(
             this.accountQuery.userData.bankAccountDetails ? this.accountQuery.userData.bankAccountDetails.AccountNumber : ''
          )
        },
        this.accountService.generateDigitsOnlyAndLengthValidators(3, 3)
      ),
      bankAccountNumberPart2: new FormControl(
        {
          value: this.accountQuery.userData.bankAccountDetails ?
             this.splitBankAccountNumber(this.accountQuery.userData.bankAccountDetails.AccountNumber, 2) : '',
          disabled: this.disableFormControl(
             this.accountQuery.userData.bankAccountDetails ? this.accountQuery.userData.bankAccountDetails.AccountNumber : ''
          )
        },
        this.accountService.generateDigitsOnlyAndLengthValidators(1, 13)
      ),
      bankAccountNumberPart3: new FormControl(
        {
          value: this.accountQuery.userData.bankAccountDetails ?
             this.splitBankAccountNumber(this.accountQuery.userData.bankAccountDetails.AccountNumber, 3) : '',
          disabled: this.disableFormControl(
             this.accountQuery.userData.bankAccountDetails ? this.accountQuery.userData.bankAccountDetails.AccountNumber : ''
          )
        },
        this.accountService.generateDigitsOnlyAndLengthValidators(2, 2)
      ),
    });
  }

  private disableFormControl(value: any): boolean {
    switch (this.profileEditability) {
      case ProfileEditability.OneTime:
        // Only enable if item does not have a value.
        return !(value === null || value === undefined || value.length === 0 || value === 0);
      case ProfileEditability.Always:
        return false;
      case ProfileEditability.Off:
      default:
        return true;
    }
  }
}
