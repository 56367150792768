<ng-container *ngIf="displayBanner$ | async as banner">
  <div class="menu-wrapper" (click)="closeBanner(banner)">
    <div class="banner">
      <img (click)="handleBannerClick(banner); $event.stopPropagation()" class="banner-image" src="{{ banner.bannerImage.url }}" />
    </div>

    <div (click)="closeBanner(banner)" class="close-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
      </svg>
    </div>
  </div>
</ng-container>

<div class="fab-container" *ngIf="displayFAB$ | async as banner">
  <app-popup-banner-fab [banner]="banner"></app-popup-banner-fab>
</div>
