import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { APIService } from 'src/app/core/services/api.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { BannerRotatorStore } from 'src/app/core/state/banner-rotator/banner-rotator.store';
import { BannerRotatorLocation, BannerRotatorModel, BannerRotatorVisibility } from 'src/app/shared/models/banner-rotator.model';
import { APIType } from '../../shared/models/api.model';
import { Banner } from '../../shared/models/cms.model';

@Injectable({
  providedIn: 'root'
})
export class BannerRotatorService {
  constructor(
    private readonly apiService: APIService,
    private readonly bannerRotatorStore: BannerRotatorStore,
    private readonly applicationService: ApplicationService,
    private readonly applicationQuery: ApplicationQuery
  ) {}

  getBannersData(location: BannerRotatorLocation): Observable<Banner[]> {
    let url: string;
    let lastBannerUpdate: number;
    let storeUpdateParameter = '';

    switch (location) {
      case BannerRotatorLocation.Payments:
        url = 'Account/GetPaymentBanners';
        lastBannerUpdate = this.applicationQuery.cms.lastPaymentsBannerRotatorUpdate;
        storeUpdateParameter = 'lastPaymentsBannerRotatorUpdate';
        break;
      case BannerRotatorLocation.Sports:
      default:
        url = 'SiteRoot/GetBanners';
        lastBannerUpdate = this.applicationQuery.cms.lastSportsBannerRotatorUpdate;
        storeUpdateParameter = 'lastSportsBannerRotatorUpdate';
        break;
    }

    // Since we are doing an update, reset cache timers and current list state
    this.bannerRotatorStore.update({ bannerDetails: undefined });
    this.applicationService.resetBannerUpdateCaches();

    return  this.apiService.get(APIType.CMS, 'banners?_sort=rank').pipe(
      first(),
      map(res => {
        const banners = [];
        const currentTime = new Date();
        const filteredResponse = res.filter(banner => {
          const fromTime = new Date(banner.Banner.start);
          const toTime = new Date(banner.Banner.end);
          return (
            (currentTime.getTime() <= toTime.getTime() && currentTime.getTime() > fromTime.getTime()) ||
            !(banner.Banner.start && banner.Banner.end)
          );
        });
        filteredResponse.forEach(banner => {
          banners.push(
            new BannerRotatorModel({
              title: banner.Banner.title,
              imageUrl: banner.Banner.image.url,
              redirectionUrl: banner.Banner.url,
              display: this.getDisplayValue(banner.Banner.display),
              couponCode: '',
              route: banner.Banner.route
            })
          );
        });
        this.bannerRotatorStore.update({ bannerDetails: banners });
        return banners;
      })
    );
  }

  private getDisplayValue(strValue: string): number {
    switch (strValue) {
      case 'always':
        return BannerRotatorVisibility.Always;
        break;
      case 'logged_in':
        return BannerRotatorVisibility.LoggedIn;
        break;
      case 'logged_out':
        return BannerRotatorVisibility.LoggedOut;
        break;
      default:
        return BannerRotatorVisibility.Always;
    }
  }

  /**
   * If banner list is less than five, replicate the list by a number of times to fill in the banner rotator
   * depending on the list length
   * @param bannerList The banner list
   */
  private bannerReplication(bannerList: BannerRotatorModel[]): BannerRotatorModel[] {
    switch (bannerList.length) {
      case 2:
        // 2 items to be replicated 4 times
        return bannerList.concat(bannerList, bannerList, bannerList);
      case 3:
        // 3 items to be replicated 3 times
        return bannerList.concat(bannerList, bannerList);
      case 4:
      case 5:
        // 4 or 5 items to be replicated 2 times
        return bannerList.concat(bannerList);
      default:
        return bannerList;
    }
  }
}
