import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { ApplicationStore } from 'src/app/core/state/application/application.store';
import { ProductType } from 'src/app/shared/models/application.model';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {
  constructor(
    private readonly applicationStore: ApplicationStore,
    private readonly applicationQuery: ApplicationQuery,
    private readonly router: Router
  ) {}

  initialize(): void {
    this.router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        if (event.snapshot.data && event.snapshot.data.productType) {
          this.updateActiveProduct(event.snapshot.data.productType);
        }
      }
    });
  }

  updateActiveProduct(productType: ProductType): void {
    if (this.applicationQuery.activeProduct === productType) {
      return;
    }

    this.applicationStore.update({ activeProduct: productType });
  }
}
