import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { kebabCase } from 'lodash-es';
import { of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ResolverService } from 'src/app/core/services/resolver.service';
import { SportService } from 'src/app/core/services/sport.service';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { EventType } from './../../shared/models/sport.model';

@Injectable({
  providedIn: 'root'
})
export class ProceedToEventsService {
  constructor(
    private readonly sportQuery: SportQuery,
    private readonly resolverService: ResolverService,
    private readonly router: Router,
    private readonly sportService: SportService
  ) {}

  proceedToOneEvent(eventId: number, eventType: EventType, autoForwardHappened: boolean, menuUniqueId: string): void {
    const allIds = this.resolverService.bruteForceResolveByTournamentId(eventId, eventType, menuUniqueId);

    if (!allIds) {
      return;
    }
    const allNames = this.resolverService.convertToNames(allIds, eventType);

    const { sportId, categoryId, tournamentId } = this.resolverService.bruteForceResolveByTournamentId(eventId, eventType);

    const normalizedSportName = kebabCase(allNames.sport);
    const normalizedCategoryName = kebabCase(allNames.category);
    const normalizeTournamentName = kebabCase(allNames.tournament);

    if (eventType === 'prematch' && !this.isTournamentSpecials(categoryId)) {
      this.router.navigateByUrl(`/betting?selection=${sportId},${categoryId},${tournamentId}`);
      return;
    }

    if (this.isTournamentSpecials(categoryId)) {
      this.router.navigate([`/sports/events/prematch/${normalizedSportName}/${normalizedCategoryName}/${normalizeTournamentName}`]);
      return;
    }

    this.sportService.updateEventSelection({ autoForwardHappened });
    const url = `${eventType}/${normalizedSportName}/${normalizedCategoryName}/${normalizeTournamentName}`;
    this.router.navigate([`/sports/events/${url}`], { replaceUrl: autoForwardHappened });
  }

  /**
   * Used by the proceed bar
   * @param depth The current event selection depth
   */
  proceed(depth: number): boolean {
    let events$ = of([]);
    let eventType: EventType = 'prematch';

    if (depth === 1) {
      if (this.sportQuery.selectedQuicklink.id === 2) {
        events$ = this.sportQuery.competitionsAZSelectedElements$;
      } else if (this.sportQuery.selectedQuicklink.id === 0) {
        events$ = this.sportQuery.topCompetitionsSelectedElements$;
      } else {
        return false;
      }
    } else if (depth === 2) {
      if (this.sportQuery.selectedQuicklink.id === 4) {
        events$ = this.sportQuery.allCompetitionByCountrySelectedElementsDepth2$;
      } else if (this.sportQuery.selectedQuicklink.id === 5) {
        events$ = this.sportQuery.outrightsSelectedElementsDepth2$;
        eventType = 'outright';
      } else if (this.sportQuery.selectedQuicklink.id === 6) {
        events$ = this.sportQuery.specialSportsSelectedElementsDepth2$;
        eventType = 'special';
      } else if (this.sportQuery.selectedQuicklink.id === 7) {
        events$ = this.sportQuery.goalscorerSportSelectedElementsDepth2$;
        eventType = 'special';
      } else {
        return false;
      }
    } else {
      return false;
    }

    events$
      .pipe(
        filter(events => !!events),
        take(1)
      )
      .subscribe(data => {
        this.proceedToEvents(data, eventType);
      });

    return true;
  }

  private readonly proceedToEvents = (data, eventType) => {
    if (!data || !data.length) {
      return;
    }

    const tournamentId = data[0].id;
    const allIds = this.resolverService.bruteForceResolveByTournamentId(tournamentId, eventType);
    const allNames = this.resolverService.convertToNames(allIds, eventType);

    const normalizedSportName = kebabCase(allNames.sport);
    const normalizedCategoryName = kebabCase(allNames.category);
    const normalizeTournamentName = kebabCase(allNames.tournament);

    let url = `${eventType}/`;
    let queryParams = '';

    // if (data.length === 1) {
    //   url += `${normalizedSportName}/${normalizedCategoryName}/${normalizeTournamentName}`;
    // } else if (data.length > 1) {
    //   data.forEach((tournament, index) => {
    //     const allIdsForIdBasedUrl = this.resolverService.bruteForceResolveByTournamentId(tournament.id, eventType);

    //     url += `${allIdsForIdBasedUrl.sportId},${allIdsForIdBasedUrl.categoryId},${allIdsForIdBasedUrl.tournamentId}`;
    //     if (index < data.length - 1) {
    //       url += '-';
    //     }
    //   });
    // }

    let ids: any;
    data.forEach((tournament, index) => {
      const allIdsForIdBasedUrl = this.resolverService.bruteForceResolveByTournamentId(tournament.id, eventType);
      ids = allIdsForIdBasedUrl;

      url += `${allIdsForIdBasedUrl.sportId},${allIdsForIdBasedUrl.categoryId},${allIdsForIdBasedUrl.tournamentId}`;
      queryParams += `${allIdsForIdBasedUrl.sportId},${allIdsForIdBasedUrl.categoryId},${allIdsForIdBasedUrl.tournamentId}`;
      if (index < data.length - 1) {
        url += '-';
        queryParams += '-';
      }
    });

    this.sportService.updateEventSelection({ autoForwardHappened: this.sportQuery.eventSelectionDepth === 1 });

    if (eventType === 'prematch' && !this.isTournamentSpecials(ids.categoryId)) {
      this.router.navigateByUrl(`/betting?selection=${queryParams}`);
    } else {
      this.router.navigate([`/sports/events/${url}`]);
    }
  };

  private isTournamentSpecials(categoryId: string): boolean {
    return ['1618383', '412293', '1821978', '2953490', '3796350', '4520017'].includes(categoryId);
  }
}
