import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { SportId } from 'src/app/shared/models/sport-id.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-odd-group',
  templateUrl: './odd-group.component.html',
  styleUrls: ['./odd-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OddGroupComponent implements OnChanges {
  @Input() odds: OddModel[];
  @Input() specials?: boolean;

  spreadValue: number;
  parsedOdds: OddModel[] = [];

  isSpreadMarket: boolean;

  constructor(private readonly appConfig: AppConfigService) {}

  ngOnChanges(): void {
    this.handleOdds();
  }

  trackById(index: number, odd: OddModel): number {
    return odd ? odd.id : index;
  }

  get maxNumberOfSelections(): number {
    return this.isSpreadMarket ? 2 : 3;
  }

  private handleOdds(): void {
    const marketId = this.odds[0]?.marketId;
    const sportId = this.odds[0]?.sportId;
    const numberOfSelections = environment.appConfigDefaults.sports.marketGroupNumberOfSelections[marketId] ?? 3;
    const numberOfOdds = this.odds.length;
    if (numberOfOdds > 3) {
      if ((numberOfOdds / 2) % 2 === 0) {
        this.parsedOdds = [this.odds[numberOfOdds / 2 - 2], this.odds[numberOfOdds / 2 - 1]];
      } else {
        this.parsedOdds = [this.odds[numberOfOdds / 2 - 1], this.odds[numberOfOdds / 2]];
      }
    } else {
      this.parsedOdds = this.odds.slice(0, numberOfSelections);
    }

    this.spreadValue = this.parsedOdds?.[0]?.spreadValue;

    const selections = this.appConfig.get('sports')?.prematchSelectionsSwap as number[];
    if ((numberOfSelections === 2 || sportId === SportId.Handball) && selections.includes(marketId)) {
      const more = this.parsedOdds[0];
      const less = this.parsedOdds[1];
      this.parsedOdds = [less, more];
    }
  }
}
