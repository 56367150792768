<app-breadcrumb faIcon="fa-pencil-square-o" [title]="'My Profile' | transloco"> </app-breadcrumb>

<div class="new-profile-container">
  <!--
    *
    *     Profile Info
    *
  -->
  <div class="title" transloco="Account Data"></div>
  <div class="row">
    <div class="item">
      <div class="label" transloco="Username"></div>
      <div class="value">{{ accountQuery.userData.username }}</div>
    </div>
    <div class="item">
      <div class="label" transloco="User ID"></div>
      <div class="value">{{ accountQuery.userData.id }}</div>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <div class="label" transloco="Email"></div>
      <div class="value">{{ accountQuery.userData.email }}</div>
    </div>
    <div class="item">
      <div class="label" transloco="Mobile number"></div>
      <div *ngIf="accountQuery.userData?.mobile?.mobileNumber" class="value">{{ accountQuery.userData?.mobile?.mobileNumber }}</div>
      <div *ngIf="!accountQuery.userData?.mobile?.mobileNumber" class="value">—</div>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <div class="label" transloco="First Name"></div>
      <div class="value">{{ accountQuery.userData.name }}</div>
    </div>
    <div class="item">
      <div class="label" transloco="Last Name"></div>
      <div class="value">{{ accountQuery.userData.surname }}</div>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <div class="label" transloco="Citizen Id Number"></div>
      <div class="value">{{ accountQuery.userData.citizenIdNumber.value }}</div>
    </div>
    <div class="item">
      <div class="label" transloco="Date of Birth"></div>
      <div class="value">{{ dateOfBirth$ | async }}</div>
    </div>
  </div>
  <div class="row one-line">
    <div class="item">
      <div class="label" transloco="Bank Account Number"></div>
      <div class="value">
        {{ this.accountQuery.userData.bankAccountDetails ? this.accountQuery.userData.bankAccountDetails.AccountNumber : '—' }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <div class="label" transloco="City"></div>
      <div class="value">{{ accountQuery.userData.address.cityCode }}</div>
    </div>
    <div class="item">
      <div class="label" transloco="Address"></div>
      <div class="value">{{ accountQuery.userData.address.addressLine }}</div>
    </div>
  </div>

  <!--
    *
    *     Update Info
    *
  -->
  <div class="update-profile-section">
    <div class="update-profile-title" transloco="Update"></div>

    <form [formGroup]="editProfileForm" #ngForm="ngForm" class="edit-profile-form">
      <div class="edit-profile-card-container" body>
        <!-- Username -->
        <div class="form-item password">
          <div class="form-item-row">
            <div class="wrapper">
              <label transloco="Username"></label>
              <div class="form-input">
                <input type="text" formControlName="username" />
              </div>

              <app-form-message
                type="danger"
                *ngIf="editProfileForm.controls['username'].touched && editProfileForm.controls['username'].errors?.required"
                [text]="'The username is required' | transloco"
              >
              </app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['username'].touched &&
                  editProfileForm.controls['username'].errors?.minlength &&
                  !editProfileForm.controls['username'].errors?.required
                "
                [text]="'Username must be between 5 to 50 characters long' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['username'].touched &&
                  editProfileForm.controls['username'].errors?.maxlength &&
                  !editProfileForm.controls['username'].errors?.required
                "
                [text]="'Username must be between 5 to 50 characters long' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['username'].touched &&
                  editProfileForm.controls['username'].errors?.needsLowercase &&
                  !editProfileForm.controls['username'].errors?.required
                "
                [text]="'The username must contain a LOWERCASE letter' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['username'].touched &&
                  editProfileForm.controls['username'].errors?.hasSpaces &&
                  !editProfileForm.controls['username'].errors?.required
                "
                [text]="'The username must not contain spaces' | transloco"
              ></app-form-message>
            </div>

            <div class="button-wrapper">
              <button class="btn" [class.disabled]="changeUsernameLoading$ | async" (click)="changeUsername()">
                <span class="btn-text" transloco="Modify"></span>
              </button>
            </div>
          </div>
        </div>

        <!-- Password -->
        <div class="form-item password">
          <div class="form-item-row">
            <div class="wrapper">
              <label transloco="Password"></label>
              <div class="form-input">
                <input [type]="(showPassword$ | async) ? 'text' : 'password'" formControlName="password" />
              </div>
              <app-form-message
                type="danger"
                *ngIf="editProfileForm.controls['password'].touched && editProfileForm.controls['password'].errors?.required"
                [text]="'The password is required' | transloco"
              >
              </app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['password'].touched &&
                  editProfileForm.controls['password'].errors?.minlength &&
                  !editProfileForm.controls['password'].errors?.required
                "
                [text]="'Password must be between 6 to 25 characters long' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['password'].touched &&
                  editProfileForm.controls['password'].errors?.containsUsername &&
                  !editProfileForm.controls['password'].errors?.required
                "
                [text]="'The password must not contain the username' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['password'].touched &&
                  editProfileForm.controls['password'].errors?.maxlength &&
                  !editProfileForm.controls['password'].errors?.required
                "
                [text]="'Password must be between 6 to 25 characters long' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['password'].touched &&
                  editProfileForm.controls['password'].errors?.needsLowercase &&
                  !editProfileForm.controls['password'].errors?.required
                "
                [text]="'The password must contain a LOWERCASE letter' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="
                  editProfileForm.controls['password'].touched &&
                  editProfileForm.controls['password'].errors?.hasSpaces &&
                  !editProfileForm.controls['password'].errors?.required
                "
                [text]="'The password must not contain spaces' | transloco"
              ></app-form-message>
              <app-form-message
                type="danger"
                *ngIf="!!editProfileForm.errors?.containsUsername"
                [text]="'The password must not contain the username' | transloco"
              ></app-form-message>
              <span
                (click)="togglePasswordVisibility()"
                [category]="'Toggle Password Visibility'"
                [class.fa-eye-slash]="showPassword$ | async"
                [class.fa-eye]="(showPassword$ | async) === false"
                [click]="true"
                analytics
                class="fa toggle-password"
              ></span>
            </div>

            <div class="button-wrapper">
              <button class="btn" [class.disabled]="changePasswordLoading$ | async" (click)="changePassword()">
                <span class="btn-text" transloco="Modify"></span>
              </button>
            </div>
          </div>
        </div>

        <!-- Mobile Number -->
        <div class="form-item mobile-number hide-input">
          <div class="form-item">
            <label transloco="Mobile number"></label>
            <div class="mobile">
              <input type="text" [placeholder]="'Mobile number' | transloco" formControlName="mobile" />
            </div>
          </div>
        </div>
        <!-- Email -->
        <div class="form-item email hide-input">
          <label transloco="Email"></label>
          <div class="form-input">
            <input type="text" [placeholder]="'Email' | transloco" formControlName="email" />
          </div>
        </div>

        <div class="edit-profile-card-container no-shadow">
          <div class="form-item consent">
            <div class="form-item-row">
              <input type="checkbox" id="cbxConsent" formControlName="consent" />
              <span class="checkbox-visual" (click)="toggleCheckbox($event)"></span>
              <label
                for="cbxConsent"
                transloco="I agree to receive promotional messages and offers via email, SMS and other forms of communication."
              ></label>
            </div>
          </div>
        </div>
      </div>

      <div class="edit-profile-card-container">
        <!-- First Name -->
        <div class="form-item first-name hide-input">
          <label transloco="First Name"></label>
          <div class="form-input">
            <input type="text" [placeholder]="'First Name' | transloco" formControlName="firstName" />
          </div>
          <app-form-message
            type="danger"
            [text]="'This field is required' | transloco"
            *ngIf="
              ((editProfileForm.controls['firstName'].invalid && editProfileForm.controls['firstName'].dirty) ||
                (editProfileForm.controls['firstName'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['firstName'].errors?.required
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'Invalid format' | transloco"
            *ngIf="
              ((editProfileForm.controls['firstName'].invalid && editProfileForm.controls['firstName'].dirty) ||
                (editProfileForm.controls['firstName'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['firstName'].errors?.pattern
            "
          >
          </app-form-message>
        </div>

        <!-- Last Name -->
        <div class="form-item last-name hide-input">
          <label transloco="Last Name"></label>
          <div class="form-input">
            <input type="text" [placeholder]="'Last Name' | transloco" formControlName="lastName" />
          </div>
          <app-form-message
            type="danger"
            [text]="'This field is required' | transloco"
            *ngIf="
              ((editProfileForm.controls['lastName'].invalid && editProfileForm.controls['lastName'].dirty) ||
                (editProfileForm.controls['lastName'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['lastName'].errors?.required
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'Invalid format' | transloco"
            *ngIf="
              ((editProfileForm.controls['lastName'].invalid && editProfileForm.controls['lastName'].dirty) ||
                (editProfileForm.controls['lastName'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['lastName'].errors?.pattern
            "
          >
          </app-form-message>
        </div>

        <!-- Citizen ID Number -->
        <div class="form-item citizen-ID-number hide-input">
          <label transloco="Citizen Id Number"></label>
          <div class="form-input">
            <input
              type="text"
              [placeholder]="'Citizen Id Number' | transloco"
              minlength="13"
              maxlength="13"
              formControlName="citizenIdNumber"
            />
          </div>
          <app-form-message
            type="danger"
            [text]="'This field is required' | transloco"
            *ngIf="
              ((editProfileForm.controls['citizenIdNumber'].invalid && editProfileForm.controls['citizenIdNumber'].dirty) ||
                (editProfileForm.controls['citizenIdNumber'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['citizenIdNumber'].errors?.required
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'This field can only contain numbers' | transloco"
            *ngIf="
              ((editProfileForm.controls['citizenIdNumber'].invalid && editProfileForm.controls['citizenIdNumber'].dirty) ||
                (editProfileForm.controls['citizenIdNumber'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['citizenIdNumber'].errors?.pattern
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'Citizen ID Number must be 13 digits long' | transloco"
            *ngIf="
              ((editProfileForm.controls['citizenIdNumber'].invalid && editProfileForm.controls['citizenIdNumber'].dirty) ||
                (editProfileForm.controls['citizenIdNumber'].invalid && ngForm.submitted)) &&
              (editProfileForm.controls['citizenIdNumber'].errors?.minlength ||
                editProfileForm.controls['citizenIdNumber'].errors?.maxlength)
            "
          >
          </app-form-message>
        </div>

        <div class="date-of-birth-wrapper hide-input">
          <!-- DOB -->
          <div class="form-item date-of-birth">
            <label transloco="Date of Birth"></label>
            <div class="form-input">
              <input type="date" formControlName="dateOfBirth" [max]="accountQuery.dobUpperLimit" (click)="setMaxDate()" />
            </div>
            <app-form-message
              type="danger"
              [text]="'This field is required' | transloco"
              *ngIf="
                ((editProfileForm.controls['dateOfBirth'].invalid && editProfileForm.controls['dateOfBirth'].dirty) ||
                  (editProfileForm.controls['dateOfBirth'].invalid && ngForm.submitted)) &&
                editProfileForm.controls['dateOfBirth'].errors?.hasDefaultDate
              "
            >
            </app-form-message>
            <app-form-message
              type="danger"
              [text]="'Has to be over 18 years old' | transloco"
              *ngIf="
                ((editProfileForm.controls['dateOfBirth'].invalid && editProfileForm.controls['dateOfBirth'].dirty) ||
                  (editProfileForm.controls['dateOfBirth'].invalid && ngForm.submitted)) &&
                editProfileForm.controls['dateOfBirth'].errors?.hasInvalidAge
              "
            >
            </app-form-message>
          </div>

          <!-- Gender -->
          <div class="form-item gender hide-input">
            <label transloco="Gender"></label>
            <div class="flex radio-group">
              <div class="radio-item-group">
                <input type="radio" name="gender" value="M" formControlName="gender" />
                <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
                <span transloco="Male"></span>
              </div>
              <div class="radio-item-group">
                <input type="radio" name="gender" value="F" formControlName="gender" />
                <span class="radio-visual" (click)="toggleRadioButton($event)"></span>
                <span transloco="Female"></span>
              </div>
            </div>
            <app-form-message
              type="danger"
              [text]="'This field is required' | transloco"
              *ngIf="
                ((editProfileForm.controls['gender'].invalid && editProfileForm.controls['gender'].dirty) ||
                  (editProfileForm.controls['gender'].invalid && ngForm.submitted)) &&
                editProfileForm.controls['gender'].errors?.hasDefaultGender
              "
            >
            </app-form-message>
          </div>
        </div>
        <!-- User Id -->
        <div class="form-item userid hide-input">
          <label transloco="User ID"></label>
          <div class="form-input">
            <input type="text" formControlName="userId" readonly="readonly" />
          </div>
        </div>

        <!-- Bank Account Number -->
        <div class="form-item bank-account-number hide-input">
          <label transloco="Bank Account Number"></label>
          <div class="form-input">
            <input
              type="text"
              [placeholder]="'Bank Account Number' | transloco"
              minlength="18"
              maxlength="20"
              formControlName="bankAccountNumber"
            />
          </div>
          <app-form-message
            type="danger"
            [text]="'This field is required' | transloco"
            *ngIf="
              ((editProfileForm.controls['bankAccountNumber'].invalid && editProfileForm.controls['bankAccountNumber'].dirty) ||
                (editProfileForm.controls['bankAccountNumber'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['bankAccountNumber'].errors?.required
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'This field can only contain numbers' | transloco"
            *ngIf="
              ((editProfileForm.controls['bankAccountNumber'].invalid && editProfileForm.controls['bankAccountNumber'].dirty) ||
                (editProfileForm.controls['bankAccountNumber'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['bankAccountNumber'].errors?.pattern
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'Bank Account Number must be 18 digits long' | transloco"
            *ngIf="
              ((editProfileForm.controls['bankAccountNumber'].invalid && editProfileForm.controls['bankAccountNumber'].dirty) ||
                (editProfileForm.controls['bankAccountNumber'].invalid && ngForm.submitted)) &&
              (editProfileForm.controls['bankAccountNumber'].errors?.minlength ||
                editProfileForm.controls['bankAccountNumber'].errors?.maxlength)
            "
          >
          </app-form-message>
        </div>
        <!-- City -->
        <div class="form-item lga hide-input">
          <label transloco="City"></label>
          <div class="form-input">
            <input type="text" [placeholder]="'City' | transloco" formControlName="city" />
          </div>
          <app-form-message
            type="danger"
            [text]="'This field is required' | transloco"
            *ngIf="
              ((editProfileForm.controls['city'].invalid && editProfileForm.controls['city'].dirty) ||
                (editProfileForm.controls['city'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['city'].errors?.required
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'Invalid format' | transloco"
            *ngIf="
              ((editProfileForm.controls['city'].invalid && editProfileForm.controls['city'].dirty) ||
                (editProfileForm.controls['city'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['city'].errors?.pattern
            "
          ></app-form-message>
        </div>

        <!-- Address -->
        <div class="form-item address hide-input">
          <label transloco="Address"></label>
          <div class="form-input">
            <input type="text" [placeholder]="'Address' | transloco" formControlName="address" />
          </div>
          <app-form-message
            type="danger"
            [text]="'This field is required' | transloco"
            *ngIf="
              ((editProfileForm.controls['address'].invalid && editProfileForm.controls['address'].dirty) ||
                (editProfileForm.controls['address'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['address'].errors?.required
            "
          >
          </app-form-message>
          <app-form-message
            type="danger"
            [text]="'Invalid format' | transloco"
            *ngIf="
              ((editProfileForm.controls['address'].invalid && editProfileForm.controls['address'].dirty) ||
                (editProfileForm.controls['address'].invalid && ngForm.submitted)) &&
              editProfileForm.controls['address'].errors?.pattern
            "
          ></app-form-message>
        </div>
      </div>
    </form>
  </div>
  <div class="contact-support-wrapper">
    <app-contact-support></app-contact-support>
  </div>
</div>

<ng-template #loading>
  <app-loading-bar [show]="true"></app-loading-bar>
</ng-template>
