import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AccountPaymentStore } from 'src/app/core/state/account/account.payments.store';
import { AccountPaymentState } from 'src/app/shared/models/account.model';
import { DebitCardModel } from '../../../shared/models/account.model';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AccountPaymentQuery extends Query<AccountPaymentState> {
  loading$ = this.selectLoading();
  error$ = this.selectError();
  paymentsListExtraDetails$ = this.select(state => (state.payments ? state.payments.paymentListExtraDetailsModel : undefined));
  savedCards$ = this.select(state => state.cards);
  hints$ = this.select(state => state.hints);
  savedCardsDisclaimer$ = this.select(state => state.savedCardsDisclaimer);

  paymentsListDeposit$ = this.select(state =>
    state.payments && state.payments.paymentsListDeposit ? state.payments.paymentsListDeposit.filter(pld => pld.status !== 0) : undefined
  );
  paymentsListDepositNames$ = this.select(state =>
    state.payments && state.payments.paymentsListDeposit
      ? state.payments.paymentsListDeposit.filter(plds => plds.status !== 0 && plds.status !== -1).map(pldn => pldn.identifier)
      : undefined
  );
  paymentsListWithdrawal$ = this.select(state =>
    state.payments && state.payments.paymentsListWithdrawal
      ? state.payments.paymentsListWithdrawal.filter(plw => plw.status !== 0)
      : undefined
  );
  paymentDetails$ = this.select(state => (state.payments ? state.payments.paymentDetails : undefined));
  paymentsBankDetails$ = this.select(state => (state.payments ? state.payments.banksDetails : undefined));
  bankList$ = this.select(state => (state.payments ? state.payments.banksList : undefined));
  channelsList$ = this.select(state => (state.payments ? state.payments.channelsList : undefined));
  pendingWithdrawals$ = this.select(state => (state.payments ? state.payments.pendingWithdrawals : undefined));
  hasPendingWithdrawals$ = this.select(state => state.payments.pendingWithdrawals && state.payments.pendingWithdrawals.length > 0);
  qualifiedWithdrawals$ = this.select(state => state.qualifiedWithdrawals);
  payoutPromoConfig$ = this.select(state => state.payoutPromoConfig);
  constructor(protected store: AccountPaymentStore) {
    super(store);
  }

  get allSecureDetails(): any {
    return this.getValue().payments.paymentsListDeposit.find(res => res.identifier === 'AllSecureDeposit');
  }
  get lastAccNumber(): number {
    return this.getValue().payments.banksDetails.lastAccNumber;
  }
  get lastBankId(): number {
    return this.getValue().payments.banksDetails.lastBankId;
  }
  get lastUsedCard(): DebitCardModel {
    return this.getValue().cards[0];
  }

  get savedCards(): DebitCardModel[] {
    return this.getValue().cards;
  }
  get hasDepositPaymentsLists(): boolean {
    return this.getValue().payments !== undefined && this.getValue().payments.paymentsListDeposit !== undefined;
  }

  get hasWithdrawalsPaymentsLists(): boolean {
    return this.getValue().payments !== undefined && this.getValue().payments.paymentsListWithdrawal !== undefined;
  }
}
