<div class='cms-notifications'>
  <!--
    ***
    ***   Background overlay
    ***
  -->
  <div *ngIf='hasBackgroundOverlay$ | async' class='background-overlay'></div>

  <!--
    ***
    ***   Dialog
    ***
  -->
  <div *ngIf='dialog$ | async as dialog' class='dialog'>
    <app-notification-dialog [dialog]='dialog'></app-notification-dialog>
  </div>

  <!--
    ***
    ***   Toasts
    ***
  -->
  <div *ngIf='toasts$ | async as toasts' class='toasts'>
    <div *ngFor='let toast of toasts' class='toast'>
      <app-notification-toast [toast]='toast'></app-notification-toast>
    </div>
  </div>
</div>
