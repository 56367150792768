<app-breadcrumb [title]="'Phone Number' | transloco" faIcon="fa-mobile-phone"></app-breadcrumb>
<div class="change-number-container"
  *ngIf="(accountQuery.phoneOptionTriggered$ | async) === true && (phoneVerification$ | async) === false && (alreadyRegistered$ | async) === false">
  <app-card [titleHeaderText]="'Change Phone Number' | transloco">
    <div class="change-number-card-container" body>
      <div class="change-number-wrapper">
        <form [formGroup]="changeNumberForm" class="change-number-form">

          <div class="form-item current-number" *ngIf="accountQuery.userData.mobile">
            <div>
              <label>
                <span transloco='Current Mobile number:'></span>
                <span>{{ accountQuery.userData.mobile.mobileNumber }}</span>
              </label>
            </div>
          </div>

          <div class="form-item mobile-number">
            <div>
              <label transloco="New Mobile number"></label>
              <div class="form-item-row">
                <div class="dropdown" [class.no-arrow]="!showTelExtDropdownArrow"
                  [class.valid]="changeNumberForm.controls['mobilePrefix'].valid">
                  <select formControlName="mobilePrefix">
                    <option value="" disabled transloco="Select prefix"></option>
                    <ng-container *ngIf="showTelExtDropdownArrow">
                      <option
                        *ngFor="let telephoneExt of (registrationQuery.telephoneExtensions$ | async); trackBy: trackByTelExt"
                        [value]="telephoneExt.prefixNumber">
                        {{ telephoneExt.prefixNumber }} ({{ telephoneExt.countryCode }})
                      </option>
                    </ng-container>
                    <ng-container *ngIf="!showTelExtDropdownArrow">
                      <option
                        *ngFor="let telephoneExt of (registrationQuery.telephoneExtensions$ | async); trackBy: trackByTelExt"
                        [value]="telephoneExt.prefixNumber">
                        {{ telephoneExt.prefixNumber }}
                      </option>
                    </ng-container>
                  </select>
                </div>
                <div class="form-input">
                  <input type="tel" [placeholder]="'e.g 60 123 4567' | transloco" class="mobile-number-input"
                    formControlName="mobileNumber">
                </div>
              </div>
              <app-form-message type="danger"
                *ngIf="changeNumberForm.controls['mobileNumber'].errors?.pattern && changeNumberForm.controls['mobileNumber'].touched"
                [text]="'Mobile number is invalid' | transloco">
              </app-form-message>
              <app-form-message type="danger" *ngIf="mobileNumberAlreadyTakenOrInvalid$ | async"
                [text]="'Mobile Number is already taken or invalid' | transloco"></app-form-message>
            </div>
          </div>

          <div class="buttons">
            <button class="btn cancel" (click)="onCancel()">
              <span class="btn-text" transloco="Cancel"></span>
            </button>
            <button class="btn update" (click)="onSubmit()" [disabled]="changeNumberForm.invalid">
              <span class="btn-text" transloco="Update"></span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </app-card>
</div>

<div class="number-already-registered-container"
  *ngIf="(accountQuery.phoneOptionTriggered$ | async) === true && (alreadyRegistered$ | async) === true">
  <app-card [titleHeaderText]="'Change Phone Number' | transloco">
    <div class="number-already-registered-card-container" body>
      <div class="number-already-registered">
        <div transloco="Your Highness,"></div>
        <div transloco='The number is already registered on another account. If you choose to proceed, all other accounts associated with this phone number will become inactive.'>
        </div>
        <div *ngIf="verifyData && verifyData.type === verifyAccountType.Locked && verifyData.gracePeriod === false">
          <br />
          <i transloco='Your phone number is verified against another account. Update and verify your account to continue.'></i>
        </div>
        <div *ngIf="verifyData && verifyData.type === verifyAccountType.Locked && verifyData.gracePeriod === true">
          <br />
          <i transloco='Your phone number is yet to be confirmed. Update or Validate your phone number to access your account.'></i>
        </div>
      </div>

      <div class="verify-buttons">
        <button class="btn cancel" (click)="completeCallback()">
          <span class="btn-text" transloco="Verify the number above"></span>
        </button>
        <button class="btn cancel" (click)="logout()">
          <span class="btn-text" transloco="Verify a different account"></span>
        </button>
      </div>
    </div>
  </app-card>
</div>

<div class="verification-container"
  *ngIf="(accountQuery.phoneOptionTriggered$ | async) === true && (phoneVerification$ | async) === true">
  <app-card [titleHeaderText]="'Phone Number Verification'">
    <div class="verification-card-container" body>
      <app-phone-verification [OTPOption]="4" [username]="accountQuery.userData.username"
        [mobileNumber]="changeNumberForm.controls['mobilePrefix'].value + changeNumberForm.controls['mobileNumber'].value">
      </app-phone-verification>
    </div>
  </app-card>
</div>

<app-loading-bar [show]="(accountQuery.phoneOptionTriggered$ | async) === false">
</app-loading-bar>
