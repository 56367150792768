import { BetCoupon, BetCouponGlobalVariable, Bonus, Dictionary, Selection } from 'clientside-coupon';
import { QuickBetMarketModel } from 'src/app/shared/models/quick-bet.model';
import { SportModel } from 'src/app/shared/models/sport.model';
import { MatchModel } from './sport.model';

export interface CouponState {
  bookedCoupons: BookedCoupon[];
  bonusList: Bonus[];
  couponData: BetCoupon;
  selections: SportModel[];
  selectionMarketMatches: MarketMatchMap[];
  couponInitialized: boolean;
  couponSettings: CouponSettings;
  defaultCouponStake: DefaultCouponStake;
  oddChanges: OddChanges[];
  correctScoreOddsMatrix: any;
  editCouponData: MatchModel;
  globalVariables: BetCouponGlobalVariable;
  groupingsTabSelected: CouponGroupingType;
  marketExceptions: Dictionary<number, number[]>;
  ui: CouponUIState;
}

export class CouponUIState {
  showCoupon: boolean;
  showQuickCoupon: boolean;
  couponForEdit: {
    matchId: number;
    marketTypeId: number;
  };

  constructor(init: Partial<CouponUIState>) {
    Object.assign(this, init);
  }
}
export enum CouponGroupingType {
  Multiple = 1,
  Split = 2,
  Singles = 3,
  Combination = 4
}

export class GroupingTabsVisibleModel {
  multiple: boolean = false;
  split: boolean = false;
  singles: boolean = false;
  combination: boolean = false;

  constructor(init?: Partial<GroupingTabsVisibleModel>) {
    Object.assign(this, init);
  }
}

export class CouponSettings {
  allowOddChanges: boolean;
  allowStakeReduction: boolean;
  allowTransfer: boolean;
  transferUserId: string;
  useBonusFunds: boolean;

  constructor(init?: Partial<CouponSettings>) {
    Object.assign(this, init);
  }
}

export class DefaultCouponStake {
  allowSaveDefault: boolean;
  defaultStake: number;

  constructor(init?: Partial<DefaultCouponStake>) {
    Object.assign(this, init);
  }
}

export class OddChanges {
  oddId: number;
  initialOddValue: number;
  firstOddValue: number;
  latestOddValue: number;
  valueChanged: boolean;

  constructor(init?: Partial<OddChanges>) {
    Object.assign(this, init);
  }
}

export class CouponOddsModel {
  isLive: boolean;
  matchId: number;
  marketId: number;
  selectionId: number;
  selectionValue?: number;

  constructor(init?: Partial<CouponOddsModel>) {
    Object.assign(this, init);
  }
}

export class BookedCoupon {
  couponCode: string;
  date: string;

  constructor(init?: Partial<BookedCoupon>) {
    Object.assign(this, init);
  }
}

export class MarketMatchMap {
  marketId: number;
  matchIds: number[];

  constructor(init?: Partial<MarketMatchMap>) {
    Object.assign(this, init);
  }
}

export class OddModel {
  id: number;
  value: number;
  spreadValue: number = 0;
  spreadValueDisplay?: string;
  specialValueDisplay: string;
  sportId: number;
  sportName: string;
  categoryId: number;
  categoryName: string;
  tournamentId: number;
  tournamentName: string;
  matchId: number;
  matchName: string;
  matchDate: Date;
  marketId: number;
  marketTypeId: number;
  marketName: string;
  originalMarketName: string;
  originalMarketId: number;
  smartCode: number;
  eventCategory: string;
  combinability: number;
  selectionId: number;
  originalSelectionId: number;
  selectionTypeId: number;
  selectionName: string;
  incompatibleEvents: number[] = [];
  enabled: boolean = true;
  selected: boolean = false;

  static fromQuickBetMarketModel(init: QuickBetMarketModel): OddModel {
    return new OddModel({
      id: init.matchOddsID,
      smartCode: parseInt(init.smartCode, 10),
      marketId: init.marketID,
      marketName: init.marketName,
      originalMarketId: init.marketID,
      originalMarketName: init.marketName,
      marketTypeId: init.marketTypeID,
      value: init.oddValue,
      selectionId: init.selectionID,
      originalSelectionId: init.selectionID,
      selectionName: init.selectionName,
      spreadValue: init.spreadValue
    });
  }

  constructor(init: Partial<OddModel>) {
    Object.assign(this, init);
  }

  toSelection(): Selection {
    const selection: Selection = new Selection();
    selection.categoryId = this.categoryId;
    selection.categoryName = this.categoryName;
    selection.eventCategory = this.eventCategory;
    selection.combinability = this.combinability;
    selection.marketTypeId = this.marketTypeId;
    selection.marketId = this.originalMarketId;
    selection.marketName = this.originalMarketName;
    selection.matchDate = this.matchDate;
    selection.matchId = this.matchId;
    selection.matchName = this.matchName;
    selection.smartBetCode = this.smartCode;
    selection.oddId = this.id;
    selection.oddValue = this.value;
    selection.selectionTypeId = this.originalSelectionId;
    selection.selectionName = this.selectionName;
    selection.sportId = this.sportId;
    selection.sportName = this.sportName;
    selection.spreadValue = this.spreadValue;
    selection.tournamentId = this.tournamentId;
    selection.tournamentName = this.tournamentName;
    selection.incompatibleEvents = this.incompatibleEvents;

    return selection;
  }
}

export class BookedCouponModel {
  couponData: BetCoupon;
  bookedCouponCode: string;
  bookedCouponDate: string;

  constructor(init: Partial<BookedCouponModel>) {
    Object.assign(this, init);
  }
}

export class PostCouponResponse {
  validateCouponSuccess: boolean = false;
  updateCouponSuccess: boolean = false;
  updateStatusCode: number = -1;
  postCouponSuccess: boolean = false;
  postStatusCode: number = -1;
  couponId: number;
  couponCode: string;

  constructor(init: Partial<PostCouponResponse>) {
    Object.assign(this, init);
  }
}

export interface OddsByMarket {
  marketId: number;
  marketName: string;
  odds: OddModel[];
}
