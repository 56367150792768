<div [class.large]="large" class="user-avatar">
  <div class="wrapper">
    <div class="avatar">
      <span *ngIf="name" class="name">{{ name[0] }}{{ surname[0] }}</span>
      <svg *ngIf="!name" fill="none" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.333 14v-1.333A2.667 2.667 0 0010.667 10H5.333a2.667 2.667 0 00-2.666 2.667V14M8 7.333A2.667 2.667 0 108 2a2.667 2.667 0 000 5.333z"
          stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
      </svg>
    </div>

    <ng-container *ngIf="!avatarOnly">
      <h5 *ngIf="name" class="label">{{ name }}</h5>
      <h5 *ngIf="!name" class="label">{{'Account' | transloco}}</h5>
    </ng-container>
  </div>
</div>