<app-scroll-to-top></app-scroll-to-top>
<div class="account-statement-results-container">
  <div class="account-statement"
    *ngFor="let statement of accountQuery.accountStatements$ | async; trackBy: statementsTrackBy">
    <div class="row">
      <label transloco='Date'></label>
      <div class="value date">{{ statement.transactionDate | formatDate: 'dd MMM yyyy HH:mm' }}</div>
    </div>
    <div class="row">
      <label transloco='Desc.'></label>
      <div class="value desc">{{ statement.description }}</div>
    </div>
    <div class="row">
      <label transloco='Amount'></label>
      <div class="value amount" [ngClass]="statement.amount > 0 ? 'positive' : 'negative'">
        {{ statement.amount | currencyFormat}}</div>
    </div>
    <div class="row">
      <label transloco='Balance'></label>
      <div class="value balance">{{ statement.rollingBalance | currencyFormat }}
      </div>
    </div>
    <div class="row">
      <label transloco='ID'></label>
      <div class="value id">{{ statement.id }}</div>
      <label transloco='Status'></label>
      <div class="value status">
        <i class="fa" [ngClass]="
            {
              'fa-check': (statement.transactionStatusCode === 'VIRTUAL' || statement.transactionStatusCode === 'COMPLETE'),
              'fa-times': (statement.transactionStatusCode === 'CANCELLED' || statement.transactionStatusCode === 'FAILED' || statement.transactionStatusCode === 'REJECTED' || statement.transactionStatusCode === 'VOID'),
              'fa-clock-o': (statement.transactionStatusCode === 'PENDING' || statement.transactionStatusCode === 'PROCESSING' || statement.transactionStatusCode === 'ONHOLD'),
              'fa-spin fa-spinner': statement.transactionStatusCode === 'RUNNING'
            }" aria-hidden="true"></i>
      </div>
    </div>
    <div class="row view-coupon" *ngIf="statement.couponCode">
      <button (click)="viewCouponPressed(statement.couponCode)">
        <i class="fa fa-folder-open" aria-hidden="true"></i>
        <span transloco='View Coupon'></span>
      </button>
    </div>
  </div>
  <app-button [text]="'Back to Transaction List Search' | transloco" (btnClick)="backPressed()"></app-button>
  <div class="account-statement-legend">
    <div class="header" transloco='Status Legend'></div>
    <div class="content">
      <div class="icons-section">
        <div class="icons">
          <div class="icon"><i class="fa fa-times" aria-hidden="true"></i><span transloco='Failed/Rejected'></span></div>
          <div class="icon"><i class="fa fa-clock-o" aria-hidden="true"></i><span transloco='Pending'></span></div>
        </div>
      </div>
      <div class="icons-section">
        <div class="icons">
          <div class="icon"><i class="fa fa-spin fa-spinner" aria-hidden="true"></i><span transloco='Running'></span></div>
          <div class="icon"><i class="fa fa-check" aria-hidden="true"></i><span transloco='Virtual/Complete'></span></div>
        </div>
      </div>
    </div>
  </div>
</div>
