import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';

@Directive({
  selector: '[appFaArrowRotate]'
})
export class ArrowRotateDirective {
  constructor(private readonly elRef: ElementRef, private readonly renderer: Renderer2) {}

  @HostListener('click') onClick() {
    const clickedElement = this.elRef.nativeElement;
    const clickedArrow = clickedElement.querySelector('i');
    const allArrows = clickedElement.parentElement.querySelectorAll('i');

    allArrows.forEach(arrow => {
      const arrowRotation = window.getComputedStyle(arrow).getPropertyValue('transform');
      this.renderer.setStyle(arrow, 'transition', '0.1s ease');

      if (arrow === clickedArrow) {
        if (arrowRotation === 'none') {
          this.renderer.setStyle(arrow, 'transform', 'rotate(90deg)');
          this.renderer.setStyle(arrow, 'opacity', '1');
        } else {
          this.renderer.setStyle(arrow, 'transform', '');
          this.renderer.setStyle(arrow, 'opacity', '0.6');
        }
      } else {
        this.renderer.setStyle(arrow, 'transform', '');
        this.renderer.setStyle(arrow, 'opacity', '0.6');
      }
    });
  }
}
