import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { fadeIn } from 'src/app/shared/animations';
import { HeaderTab, MatchesTab, SegmentTab, SpreadTab, StatsTab, TeamTab } from 'src/app/shared/models/statistics.model';
import { LanguageModel } from '../../models/application.model';

interface H2HMatch {
  date: string;
  homeTeam: {
    name: string;
    score: number;
    winner: boolean;
  };
  awayTeam: {
    name: string;
    score: number;
    winner: boolean;
  };
}

interface H2HData {
  homeTeam: {
    name: string;
    wins: number;
    percentage: number;
  };
  awayTeam: {
    name: string;
    wins: number;
    percentage: number;
  };
  drawPercentage: number;
  drawTimes: number;
  matches: H2HMatch[];
}

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn()]
})
export class StatisticsComponent implements OnInit, OnDestroy {
  @Input() externalId: number;
  enableStatistics$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  headerTab: typeof HeaderTab = HeaderTab;
  teamTab: typeof TeamTab = TeamTab;
  matchesTab: typeof MatchesTab = MatchesTab;
  segmentTab: typeof SegmentTab = SegmentTab;
  spreadTab: typeof SpreadTab = SpreadTab;
  statsTab: typeof StatsTab = StatsTab;
  headerTabSelected: HeaderTab = HeaderTab.NotSelected;
  lastMatchesTeamTabSelected: TeamTab = TeamTab.Home;
  nextMatchesTeamTabSelected: TeamTab = TeamTab.Home;
  playerStatsTeamTabSelected: TeamTab = TeamTab.Home;
  playerStatsTabSelected: StatsTab = StatsTab.Goals;
  overUnderMatchesSelected: MatchesTab = MatchesTab.All;
  overUnderSegmentSelected: SegmentTab = SegmentTab.FullTime;
  overUnderSpreadSelected: SpreadTab;
  destroy$: Subject<boolean> = new Subject<boolean>();

  activeTab$: BehaviorSubject<string> = new BehaviorSubject('h2h');
  activeLeagueColumn$: BehaviorSubject<number> = new BehaviorSubject(1);
  h2hData$: BehaviorSubject<H2HData> = new BehaviorSubject(undefined);

  constructor(
    public statisticsQuery: StatisticsQuery,
    public languageService: LanguageService,
    private readonly statisticsService: StatisticsService,
    private readonly appConfigService: AppConfigService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    // show loader
    this.getMatchStatistics(this.externalId);

    setTimeout(() => {
      this.scrollIntoView();
    }, 500);
  }

  scrollIntoView(): void {
    const element = document.getElementById('statistics-container') as HTMLDivElement;
    if (!element) {
      return;
    }

    element.scrollIntoView({ block: 'end' });
  }

  getMatchStatistics(matchId: number): void {
    this.statisticsService
      .getMatchStatistics(matchId, this.languageService.selectedLanguage.languageBetradar)
      .pipe(takeUntil(this.destroy$))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.statisticsQuery.matchStatisticsData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.getStatistics(data.homeTeamId, data.awayTeamId, data.seasonId, this.languageService.selectedLanguage.languageBetradar);
          }
        });
      });
  }

  getStatistics(homeTeamId: number, awayTeamId: number, seasonId: number, language: string): void {
    this.statisticsService
      .getStatistics(homeTeamId, awayTeamId, seasonId, language)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.statisticsQuery.headToHeadData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            const h2hData: H2HData = {
              homeTeam: {
                name: data.homeTeamName,
                wins: data.teamHomeWins,
                percentage: +data.teamHomeWinsPerc
              },
              awayTeam: {
                name: data.awayTeamName,
                wins: data.teamAwayWins,
                percentage: +data.teamAwayWinsPerc
              },
              drawPercentage: +data.teamDrawsPerc,
              drawTimes: +data.teamDraws,
              matches: []
            };

            const teamIdName = {};
            teamIdName[data.homeTeamId] = data.homeTeamName;
            teamIdName[data.awayTeamId] = data.awayTeamName;

            (data.headToHeadHistory ?? []).forEach(match => {
              h2hData.matches.push({
                date: match.date,
                homeTeam: {
                  name: teamIdName[match.teamHomeId],
                  score: match.teamHomeScore,
                  winner: match.teamHomeScore > match.teamAwayScore
                },
                awayTeam: {
                  name: teamIdName[match.teamAwayId],
                  score: match.teamAwayScore,
                  winner: match.teamAwayScore > match.teamHomeScore
                }
              });
            });

            this.h2hData$.next(h2hData);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.HeadToHead;
            }
          }
        });

        this.statisticsQuery.lastMatchesData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.LastMatches;
            }
          }
        });

        this.statisticsQuery.nextMatchesData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.NextMatches;
            }
          }
        });

        this.statisticsQuery.overUnderData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.OverUnder;
            }

            Object.keys(data).forEach(key => {
              this.overUnderSpreadSelected = data[key][0] ? data[key][0].id : undefined;
            });
          }
        });

        this.statisticsQuery.goalscorerData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.PlayerStats;
            }
          }
        });

        this.statisticsQuery.assistsData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.PlayerStats;
            }
          }
        });

        this.statisticsQuery.cardsData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.PlayerStats;
            }
          }
        });

        this.statisticsQuery.leagueRankingData$.pipe(takeUntil(this.destroy$)).subscribe(data => {
          if (data) {
            this.enableStatistics$.next(true);

            setTimeout(() => {
              if (!this.statisticsQuery.expandedStatistics) {
                this.statisticsService.updateExpandedStatistics(true);
              }
            });

            if (this.headerTabSelected === HeaderTab.NotSelected) {
              this.headerTabSelected = HeaderTab.LeagueRanking;
            }
          }
        });
      });
  }

  statisticsBetradarPopup(externalId: number, language: LanguageModel): void {
    let url = this.appConfigService.get('apiBaseUrl').betRadarStatistics;

    if (url.endsWith('/')) {
      url = url.slice(0, -1);
    }

    window.open(`${url}/${this.languageService.selectedLanguage.languageBetradar || `en`}/match/${externalId}`, '_blank');
  }

  closeStatistics(): void {
    this.statisticsService.updateSelectedExternalId(this.externalId);
  }

  selectHeaderTab(tab: HeaderTab): void {
    this.headerTabSelected = tab;
  }

  indexTrackBy(index: string): string {
    return index;
  }

  setActiveTab(tab: string): void {
    this.activeTab$.next(tab);
  }

  isHomeWinner(match: H2HMatch): boolean {
    const homeTeamName = this.h2hData$.value.homeTeam.name;

    if (match.homeTeam.winner) {
      return match.homeTeam.name === homeTeamName;
    } else if (match.awayTeam.winner) {
      return match.awayTeam.name === homeTeamName;
    } else {
      return false;
    }
  }

  isAwayWinner(match: H2HMatch): boolean {
    const awayTeamName = this.h2hData$.value.awayTeam.name;

    if (match.homeTeam.winner) {
      return match.homeTeam.name === awayTeamName;
    } else if (match.awayTeam.winner) {
      return match.awayTeam.name === awayTeamName;
    } else {
      return false;
    }
  }

  isDraw(match: H2HMatch): boolean {
    return !match.homeTeam.winner && !match.awayTeam.winner;
  }

  setActiveLeagueColumn(column: number): void {
    this.activeLeagueColumn$.next(column);
  }
}
