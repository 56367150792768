import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getAnalyticsPage } from 'src/app/core/services/analytics/analytics.helper';
import { GoogleAnalyticsService } from 'src/app/core/services/analytics/google-analytics.service';
import { VictoryAnalyticsService } from 'src/app/core/services/analytics/victory-analytics.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import {
  AnalyticsEventAction,
  AnalyticsEventDetails,
  AnalyticsEventDto,
  AnalyticsPageDetails,
  GoogleAnalyticsEventDTO,
  GoogleAnalyticsPageViewDTO,
  VictoryAnalyticsEventDTO
} from 'src/app/shared/models/analytics.model';
@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  sendEvent$: Subject<AnalyticsEventDto> = new Subject();
  private readonly sendPageView$: Subject<string> = new Subject();

  constructor(
    private readonly googleAnalytics: GoogleAnalyticsService,
    private readonly victoryAnalytics: VictoryAnalyticsService,
    private readonly accountQuery: AccountQuery,
    private readonly router: Router,
    private readonly transloco: TranslocoService
  ) {
    this.initVictoryAnalytics();
    this.initGoogleAnalytics();

    this.initListeners();
  }

  sendPageView(url: string): void {
    this.sendPageView$.next(url);
  }

  sendEvent(data: AnalyticsEventDetails, metadata?: any): void {
    this.sendEvent$.next({ data, metadata });
  }

  private initListeners(): void {
    this.sendPageView$.pipe(debounceTime(500)).subscribe(this.emitPageView.bind(this));

    this.sendEvent$.pipe(debounceTime(300)).subscribe(this.emitEvent.bind(this));
  }

  private emitPageView(url: string): void {
    const page = getAnalyticsPage(url);
    this.sendGoogleAnalyticsPageView(page);
    this.sendVictoryAnalyticsEvent(page, { Action: AnalyticsEventAction.PageView }, { lang: this.transloco.getActiveLang() });
  }

  private emitEvent(event: AnalyticsEventDto): void {
    this.sendGoogleAnalyticsEvent(event.data);
    this.sendVictoryAnalyticsEvent(getAnalyticsPage(this.router.url), event.data, event.metadata);
  }

  private initVictoryAnalytics(): void {
    this.victoryAnalytics.init();
  }

  private initGoogleAnalytics(): void {
    this.googleAnalytics.init();
  }

  private sendGoogleAnalyticsPageView(page: AnalyticsPageDetails): void {
    const data: GoogleAnalyticsPageViewDTO = {
      Page: page,
      LoggedIn: this.accountQuery.isAuthenticated
    };

    this.googleAnalytics.sendPageView(data);
  }

  private sendGoogleAnalyticsEvent(event: AnalyticsEventDetails): void {
    const data: GoogleAnalyticsEventDTO = {
      Event: event,
      LoggedIn: this.accountQuery.isAuthenticated
    };

    this.googleAnalytics.sendEvent(data);
  }

  private sendVictoryAnalyticsEvent(page: AnalyticsPageDetails, event: AnalyticsEventDetails, metadata?: any): void {
    const data: VictoryAnalyticsEventDTO = {
      Event: event,
      Page: page,
      Metadata: JSON.stringify(metadata)
    };

    if (this.accountQuery.userData?.id) {
      data.UserID = `${this.accountQuery.userData.id}`;
    }

    if (this.accountQuery.userData?.username) {
      data.Username = `${this.accountQuery.userData.username}`;
    }

    this.victoryAnalytics.sendEvent(data);
  }
}
