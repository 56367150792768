import { Injectable } from '@angular/core';
import { BottomSheetState } from '../../shared/models/bottom-sheet';
import { BottomSheetStore } from '../state/bottom-sheet/bottom-sheet.store';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {
  constructor(private readonly store: BottomSheetStore) {}

  open(state: Partial<BottomSheetState>): void {
    this.store.updateUI({ ...state, visible: true });
  }

  close(): void {
    this.store.updateUI({ visible: false });
  }
}
