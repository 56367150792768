import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepositPromotion, DepositPromotionClaimsResponse } from 'src/app/shared/models/account.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DepositPromoService {
  promotionsEngineBaseUrl: string;

  constructor(private readonly http: HttpClient) {
    this.promotionsEngineBaseUrl = environment.appConfigDefaults.apiBaseUrl.promotionsEngine;
  }

  getDepositPromoQualificationData(): Observable<DepositPromotionClaimsResponse> {
    return this.http.get<DepositPromotionClaimsResponse>(`${this.promotionsEngineBaseUrl}/deposit-promotions/pending`);
  }

  getAvailableDepositPromotions(): Observable<{ promotions: DepositPromotion }> {
    return this.http.get<{ promotions: DepositPromotion }>(`${this.promotionsEngineBaseUrl}/deposit-promotions/available`);
  }

  getPublishedDepositPromotions(): Observable<{ promotions: DepositPromotion }> {
    return this.http.get<{ promotions: DepositPromotion }>(`${this.promotionsEngineBaseUrl}/deposit-promotions/published`);
  }

  sendUserHasSeenNotificaiton(promoId: number): Observable<any> {
    return this.http.post<any>(`${this.promotionsEngineBaseUrl}/deposit-promotions/${promoId}/seen`, {});
  }
}
