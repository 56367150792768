<div class="saved-book-bet" *ngIf="couponQuery.bookedCoupons$ | async as bookedCoupons">
  <ng-container *ngIf="withExpiredPrompt">
    <app-expired-booked-bet-prompt *ngIf="expiredBetData$ | async as expiredBetData" class="expired-prompt"
      [bookedBetData]="expiredBetData" (confirm)="onExpiredSubmit()" (cancel)="onCancel()">
    </app-expired-booked-bet-prompt>
  </ng-container>
  <div class="label" transloco="Your booked coupons"></div>
  <div class="content" *ngFor="let coupon of bookedCoupons; let i = index; trackBy: indexTrackBy">
    <div class="booked-bet">
      <div class="bet-code-date" (click)="getBookBetData(coupon.couponCode)">{{ i + 1 }}. {{ coupon.couponCode }} |
        {{'Booked:' | transloco}} {{ coupon.date }}</div>
      <div class="bet-remove" (click)="removeBookedBet(coupon.couponCode)"><i class="fa fa-times"
          aria-hidden="true"></i></div>
    </div>
  </div>
</div>
