<!-- <ng-container *ngIf="!(bookedCouponData$ | async) && couponQuery.couponData && couponQuery.couponData.Odds.length > 0"> -->
<ng-container *ngIf="!(bookedCouponData$ | async) && (hasOdds$ | async)">
  <!-- <div class="pot-win-panel">
    <div class="pot-win">{{ 'Pot. Win:' | transloco }} {{ couponQuery.couponData.MaxWinNet | currencyFormat }}</div>

    <button
      class="btn clear-bet"
      (click)="clearCouponData()"
      transloco="Clear"
      analytics
      [click]="true"
      [category]="'Clear Bet Button'"
    ></button>
  </div> -->

  <!-- <div class="groupings-tab-control" appResizeOnScroll [minHeight]="27" *ngIf="couponQuery.couponData.CouponType !== couponType.Single"> -->
  <div class="groupings-tab-control" appResizeOnScroll [minHeight]="27">
    <div class="close-button" (click)="closeCoupon()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" fill="none">
        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12.586h14" />
      </svg>
    </div>
    <div
      class="groupings-tab"
      [class.selected]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Multiple || !groupingTabsVisible.combination"
      (click)="groupingTabsVisible.combination && groupingTabClicked(groupingType.Multiple)"
      transloco="Multiple"
      analytics
      [click]="true"
      [category]="'Tab'"
      [label]="'Multiple'"
    ></div>
    <div
      class="groupings-tab"
      *ngIf="groupingTabsVisible.combination && allowCombinationBets"
      [class.selected]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination"
      (click)="groupingTabClicked(groupingType.Combination)"
      transloco="Comb. Bet"
      analytics
      [click]="true"
      [category]="'Tab'"
      [label]="'Comb. Bet'"
    ></div>
  </div>

  <div class="top-controls">
    <div class="clear-coupon" (click)="clearCouponData()" analytics [click]="true" [category]="'Clear Bet Button'">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.833 5.83333L15.1102 15.9521C15.0479 16.8243 14.3222 17.5 13.4478 17.5H6.55154C5.67714 17.5 4.95141 16.8243 4.88911 15.9521L4.16634 5.83333M8.33301 9.16667V14.1667M11.6663 9.16667V14.1667M12.4997 5.83333V3.33333C12.4997 2.8731 12.1266 2.5 11.6663 2.5H8.33301C7.87277 2.5 7.49967 2.8731 7.49967 3.33333V5.83333M3.33301 5.83333H16.6663"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <span transloco="Clear"></span>
    </div>
    <div class="selections">
      <div class="label"><span transloco="Selections"></span>:</div>
      <div class="value">{{ couponQuery.couponData.Odds.length }}</div>
    </div>
  </div>

  <div class="coupon-wrapper" cdkScrollable>
    <div class="main-content" [ngClass]="{ authenticated: (accountQuery.isAuthenticated$ | async) }">
      <app-coupon-selections></app-coupon-selections>

      <app-coupon-wallet-selector *ngIf="accountQuery.isAuthenticated$ | async"></app-coupon-wallet-selector>

      <!-- <app-coupon-totals [couponData]="couponQuery.couponDataFiltered$ | async"></app-coupon-totals> -->
      <!--
        *
        *   NEW DESIGN
        *
      -->
      <!-- <div class="coupon-totals-bg-mask"></div> -->
      <div *ngIf="couponQuery.couponDataFiltered$ | async as couponData" class="coupon-totals">
        <!-- <div class="header-row">
          <div class="top-column">
            <div class="label">Selekcije</div>
            <div class="value">{{ couponData.Odds.length }}</div>
          </div>
          <div class="top-column">
            <div class="label">Kvota</div>
            <div class="value">{{ couponData.TotalOdds | odd }}</div>
          </div>
        </div> -->

        <div class="balance-wrapper" *ngIf="isLoggedIn$ | async">
          <div class="value"><span transloco="Available Amount:"></span> {{ activeWalletFunds$ | async | currencyFormat }}</div>

          <div
            class="deposit-button"
            [class.not-available]="areBonusFundsSelected()"
            (click)="closeCoupon()"
            [routerLink]="['account/deposit']"
          >
            <span transloco="Deposit"></span>
            <span> ></span>
          </div>
        </div>

        <div class="system-wrapper" *ngIf="couponQuery.couponData.CouponType !== couponType.Single">
          <app-coupon-groupings
            [hidden]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Multiple"
            [groups]="couponQuery.couponData.AllGroupings"
          ></app-coupon-groupings>
        </div>

        <div class="odds-wrapper">
          <div class="setting">
            <div class="cursor-pointer">
              <app-toggle-switch
                [switch]="(couponQuery.couponSettings$ | async).allowOddChanges"
                (click)="updateCouponSetting('allowOddChanges', !couponQuery.couponSettings.allowOddChanges)"
                analytics
                [click]="true"
                [category]="'Allow Odd Changes Switch'"
                [customClass]="'smaller'"
                [label]="'Allow: ' + couponQuery.couponSettings.allowOddChanges"
              >
              </app-toggle-switch>
            </div>
            <div class="label" transloco="Allow odd changes"></div>
          </div>
          <div class="total-odds-wrapper">
            <div class="total-odds">
              <ng-container *ngIf="(couponQuery.groupingsTabSelected$ | async) == groupingType.Combination">
                <span class="label" transloco="Max. Odds"></span>
                <span class="value" *ngIf="couponData.TotalOdds != 0">{{ couponData.MaxOdd | odd }}</span>
              </ng-container>
              <ng-container *ngIf="(couponQuery.groupingsTabSelected$ | async) !== groupingType.Combination">
                <span class="label" transloco="Odds:"></span>
                <span class="value" *ngIf="couponData.TotalOdds != 0">{{ couponData.TotalOdds | odd }}</span>
              </ng-container>
              <svg *ngIf="couponData.TotalOdds == 0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                <path
                  fill="#222"
                  d="M5.5 22c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 4 20.5V9.65c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h1.75v-2.4c0-1.314.463-2.434 1.39-3.36C9.567 1.462 10.688 1 12.003 1s2.434.463 3.36 1.39c.925.926 1.387 2.046 1.387 3.36v2.4h1.75c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06V20.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-13Zm0-1.5h13V9.65h-13V20.5Zm6.504-3.5c.53 0 .984-.184 1.359-.55.375-.368.562-.81.562-1.325 0-.5-.189-.954-.567-1.363-.378-.408-.832-.612-1.362-.612-.53 0-.984.204-1.359.612-.375.409-.562.867-.562 1.375 0 .509.189.946.567 1.313.378.367.832.55 1.362.55ZM8.75 8.15h6.5v-2.4c0-.903-.316-1.67-.947-2.302-.631-.632-1.398-.948-2.3-.948a3.14 3.14 0 0 0-2.303.948 3.13 3.13 0 0 0-.95 2.302v2.4Z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="stake-presets">
          <div class="stake-preset" (click)="addStakeValue(500)">500</div>
          <div class="stake-preset" (click)="addStakeValue(2000)">2.000</div>
          <div class="stake-preset" (click)="addStakeValue(5000)">5.000</div>
          <div class="stake-preset" (click)="addStakeValue(10000)">10.000</div>
        </div>

        <div class="stake-input-wrapper">
          <div class="stake-input">
            <span class="label">{{ 'Stake' | transloco }} ({{ currencySymbol }})</span>
            <input
              type="number"
              step=".01"
              #stakeInput
              id="stake-amount"
              class="stake-amount"
              [value]="couponData.StakeGross | number : '1.0-2' | removeComma"
              (change)="updateInputStakeValue($event)"
              (click)="setHighlightText($event)"
            />
            <div
              class="reset-stake"
              [hidden]="firstStakePress$ | async"
              (click)="resetStakeValue()"
              analytics
              [click]="true"
              [category]="'Reset Stake Button'"
            >
              <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                  <path
                    d="M4.49957 13.6072L13.4987 4.53565M4.49957 4.53564L13.4987 13.6072"
                    stroke="#57595A"
                    stroke-width="1.69484"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>

        <div class="total-bonus-wrapper">
          <div class="content" *ngIf="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination">
            <div class="perc-wrapper">
              <div class="label" transloco="Min. Win"></div>
            </div>
            <div class="value" *ngIf="couponData.TotalOdds != 0">{{ couponData.MinWin | currencyFormat }}</div>
            <svg *ngIf="couponData.TotalOdds == 0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <path
                fill="#222"
                d="M5.5 22c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 4 20.5V9.65c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h1.75v-2.4c0-1.314.463-2.434 1.39-3.36C9.567 1.462 10.688 1 12.003 1s2.434.463 3.36 1.39c.925.926 1.387 2.046 1.387 3.36v2.4h1.75c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06V20.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-13Zm0-1.5h13V9.65h-13V20.5Zm6.504-3.5c.53 0 .984-.184 1.359-.55.375-.368.562-.81.562-1.325 0-.5-.189-.954-.567-1.363-.378-.408-.832-.612-1.362-.612-.53 0-.984.204-1.359.612-.375.409-.562.867-.562 1.375 0 .509.189.946.567 1.313.378.367.832.55 1.362.55ZM8.75 8.15h6.5v-2.4c0-.903-.316-1.67-.947-2.302-.631-.632-1.398-.948-2.3-.948a3.14 3.14 0 0 0-2.303.948 3.13 3.13 0 0 0-.95 2.302v2.4Z"
              />
            </svg>
          </div>
          <div class="content system-content" *ngIf="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination">
            <div class="perc-wrapper">
              <div class="label" transloco="No. Of Comb."></div>
            </div>
            <div class="value" *ngIf="couponData.TotalOdds != 0">{{ couponData.TotalCombinations }}</div>
            <svg *ngIf="couponData.TotalOdds == 0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <path
                fill="#222"
                d="M5.5 22c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 4 20.5V9.65c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h1.75v-2.4c0-1.314.463-2.434 1.39-3.36C9.567 1.462 10.688 1 12.003 1s2.434.463 3.36 1.39c.925.926 1.387 2.046 1.387 3.36v2.4h1.75c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06V20.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-13Zm0-1.5h13V9.65h-13V20.5Zm6.504-3.5c.53 0 .984-.184 1.359-.55.375-.368.562-.81.562-1.325 0-.5-.189-.954-.567-1.363-.378-.408-.832-.612-1.362-.612-.53 0-.984.204-1.359.612-.375.409-.562.867-.562 1.375 0 .509.189.946.567 1.313.378.367.832.55 1.362.55ZM8.75 8.15h6.5v-2.4c0-.903-.316-1.67-.947-2.302-.631-.632-1.398-.948-2.3-.948a3.14 3.14 0 0 0-2.303.948 3.13 3.13 0 0 0-.95 2.302v2.4Z"
              />
            </svg>
          </div>
          <div class="content" [class.system-content]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination">
            <div class="perc-wrapper">
              <div *ngIf="(couponQuery.groupingsTabSelected$ | async) !== groupingType.Combination" class="label" transloco="Bonus"></div>
              <div
                *ngIf="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination"
                class="label"
                transloco="Max. pot. bonus"
              ></div>
              <div class="perc" *ngIf="couponData.MaxPercentageBonus !== 0">
                ({{ this.truncate(couponData.MaxPercentageBonus * 100) }}%)
              </div>
            </div>
            <div class="value" *ngIf="couponData.TotalOdds != 0">{{ couponData.MaxBonus | odd }}</div>
            <svg *ngIf="couponData.TotalOdds == 0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
              <path
                fill="#222"
                d="M5.5 22c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 4 20.5V9.65c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h1.75v-2.4c0-1.314.463-2.434 1.39-3.36C9.567 1.462 10.688 1 12.003 1s2.434.463 3.36 1.39c.925.926 1.387 2.046 1.387 3.36v2.4h1.75c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06V20.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-13Zm0-1.5h13V9.65h-13V20.5Zm6.504-3.5c.53 0 .984-.184 1.359-.55.375-.368.562-.81.562-1.325 0-.5-.189-.954-.567-1.363-.378-.408-.832-.612-1.362-.612-.53 0-.984.204-1.359.612-.375.409-.562.867-.562 1.375 0 .509.189.946.567 1.313.378.367.832.55 1.362.55ZM8.75 8.15h6.5v-2.4c0-.903-.316-1.67-.947-2.302-.631-.632-1.398-.948-2.3-.948a3.14 3.14 0 0 0-2.303.948 3.13 3.13 0 0 0-.95 2.302v2.4Z"
              />
            </svg>
          </div>
        </div>

        <div
          *ngIf="
            (hasOddChanges$ | async) &&
            (couponQuery.couponSettings$ | async).allowOddChanges === false &&
            !(couponData.MaxWinNet < 1 || couponData.TotalOdds < 1)
          "
          class="odds-changed-message"
          transloco="Some of the selected odds have changed. Please accept the changes to proceed or remove the items."
        ></div>
        <div (click)="checkBonusAndPostCoupon(couponData)" class="create-ticket-button">
          <ng-container *ngIf="!(postingCoupon$ | async)">
            <!-- LOCKED -->
            <ng-container *ngIf="couponData.MaxWinNet < 1 || couponData.TotalOdds < 1">
              <div class="winnings locked" transloco="Coupon Locked"></div>
              <div class="coupon-locked-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
                  <path
                    fill="#fff"
                    d="M5.5 22c-.412 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 4 20.5V9.65c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44h1.75v-2.4c0-1.314.463-2.434 1.39-3.36C9.567 1.462 10.688 1 12.003 1s2.434.463 3.36 1.39c.925.926 1.387 2.046 1.387 3.36v2.4h1.75c.413 0 .766.147 1.06.44.293.294.44.648.44 1.06V20.5c0 .413-.147.766-.44 1.06-.294.293-.647.44-1.06.44h-13Zm0-1.5h13V9.65h-13V20.5Zm6.504-3.5c.53 0 .984-.184 1.359-.55.375-.368.562-.81.562-1.325 0-.5-.189-.954-.567-1.363-.378-.408-.832-.612-1.362-.612-.53 0-.984.204-1.359.612-.375.409-.562.867-.562 1.375 0 .509.189.946.567 1.313.378.367.832.55 1.362.55ZM8.75 8.15h6.5v-2.4c0-.903-.316-1.67-.947-2.302-.631-.632-1.398-.948-2.3-.948a3.14 3.14 0 0 0-2.303.948 3.13 3.13 0 0 0-.95 2.302v2.4Z"
                  />
                </svg>
              </div>
            </ng-container>

            <!-- PLACE BET -->
            <ng-container
              *ngIf="
                couponData.MaxWinNet >= 1 &&
                couponData.TotalOdds >= 1 &&
                !((hasOddChanges$ | async) && (couponQuery.couponSettings$ | async).allowOddChanges === false)
              "
            >
              <div class="winnings"><span transloco="Winnings"></span> {{ couponData.MaxWinNet | currencyFormat }}</div>
              <div class="cta" transloco="Place Bet"></div>
            </ng-container>

            <!-- ACCEPT ODD CHANGES -->
            <div
              *ngIf="
                (hasOddChanges$ | async) &&
                (couponQuery.couponSettings$ | async).allowOddChanges === false &&
                !(couponData.MaxWinNet < 1 || couponData.TotalOdds < 1)
              "
              transloco="Accept"
              class="cta"
            ></div>
          </ng-container>
          <div class="loader-container" *ngIf="postingCoupon$ | async">
            <div class="loader white"></div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="(hasOddChanges$ | async) && (couponQuery.couponSettings$ | async).allowOddChanges === false">
        <div
          class="odds-changed-message"
          transloco="Some of the selected odds have changed. Please accept the changes to proceed or remove the items."
        ></div>

        <div class="buttons">
          <div class="coupon-potential-winning-container">
            <div class="coupon-potential-winning">
              {{ couponQuery.couponData.MaxWinNet | currencyFormat }}
            </div>
          </div>

          <div class="oddsChanged">
            <button class="btn accept-changes" (click)="acceptOddChanges()">
              <i class="fa fa-check" aria-hidden="true"></i>
              <span transloco="Accept"></span>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="(hasOddChanges$ | async) === false || (couponQuery.couponSettings$ | async).allowOddChanges">
        <div class="buttons">
          <div class="coupon-button-container">
            <button
              class="btn book-bet"
              [disabled]="(canBookCoupon$ | async) === false"
              (click)="bookCoupon()"
              *ngIf="(canBookWhenLoggedIn || (accountQuery.isAuthenticated$ | async) === false) && couponQuery.couponData.HasLive != true"
            >
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
            </button>
          </div>

          <div class="coupon-potential-winning-container">
            <div class="coupon-potential-winning">
              {{ couponQuery.couponData.MaxWinNet | currencyFormat }}
            </div>
          </div>
          <div class="coupon-button-container">
            <button
              class="btn place-bet"
              *ngIf="canPlaceBet"
              [disabled]="(canPostCoupon$ | async) === false"
              (click)="checkBonusAndPostCoupon()"
              [class.no-bookbet]="(!canBookWhenLoggedIn && accountQuery.isAuthenticated$ | async) || couponQuery.couponData.HasLive"
            >
              <i class="fa fa-check" aria-hidden="true"></i>
              <span transloco="Place Bet"></span>
            </button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</ng-container>

<div class="coupon-empty-wrapper" *ngIf="!(bookedCouponData$ | async) && !(hasOdds$ | async)">
  <div class="coupon-empty-title" transloco="The coupon is currently empty."></div>
  <div class="coupon-empty-subtitle" transloco="Close Betslip and click on the odds to add your selections."></div>
  <div class="coupon-empty-button">
    <app-button
      [text]="'Continue Betting' | transloco"
      (click)="continueBetting()"
      analytics
      [click]="'Continue Betting Button'"
    ></app-button>
  </div>
</div>

<div class="coupon-booked-wrapper" *ngIf="bookedCouponData">
  <div class="booked-info">
    <div class="header-text" transloco="Congratulations! Your bet has been booked."></div>
    <div
      class="description-text"
      *ngIf="showBookedBetRetailMessage"
      transloco="You can use the displayed booking code to place this bet. Note that odds might change."
    ></div>

    <div class="book-another-bet">
      <app-button [text]="'Book Another Bet' | transloco" (click)="continueBetting()" [buttonType]="buttonType.Secondary"> </app-button>
    </div>

    <div *ngIf="(accountQuery.isAuthenticated$ | async) === false">
      <div class="header-text" transloco="Want to Bet Online?"></div>
      <div class="description-text" transloco="Log in to your account or register to be able to place your bet directly."></div>

      <div class="login-register">
        <app-button [text]="'Login' | transloco" (click)="accountService.openLogin()" [buttonType]="buttonType.Secondary"></app-button>
        <app-button
          [text]="'Register Now' | transloco"
          (click)="close()"
          [routerLink]="[this.appConfigService.get('registrationRedirect') || '/register']"
          [buttonType]="buttonType.Primary"
        >
        </app-button>
      </div>
    </div>
  </div>

  <div class="coupon-totals-container">
    <app-coupon-totals
      [couponData]="bookedCouponData.couponData"
      [bookedCouponCode]="bookedCouponData.bookedCouponCode"
      [bookedCouponDate]="bookedCouponData.bookedCouponDate"
      [bookedCoupon]="true"
    ></app-coupon-totals>
  </div>

  <app-coupon-selections [bookedCoupon]="true"></app-coupon-selections>
</div>

<ng-template #betInProgress>
  <app-loading-bar [show]="true" [transparent]="true"></app-loading-bar>
  <div class="loading-text" transloco="Placing a bet..."></div>
</ng-template>
