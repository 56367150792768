import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { translate } from '@ngneat/transloco';
import { format } from 'date-fns';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { MessagesQuery } from 'src/app/core/state/messages/messages.query';
import { fadeIn } from 'src/app/shared/animations';
import { AccountMenuLinkModel } from 'src/app/shared/models/account.model';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  animations: [fadeIn()],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountMenuComponent implements OnInit, OnDestroy {
  messageCount$: Observable<number>;
  loginDate: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  quickRegistration = this.appConfig.get('registration').registrationType === 'quick';
  accountNumberConfirmed$ = new BehaviorSubject(false);
  menuItems$ = new BehaviorSubject<AccountMenuLinkModel[]>(null);
  pending$ = new BehaviorSubject<boolean>(false);
  constructor(
    readonly accountQuery: AccountQuery,
    readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
    private readonly applicationService: ApplicationService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly notificationService: NotificationService,
    private readonly messageQuery: MessagesQuery,

  ) { }

  logout(): void {
    this.accountService.logout().subscribe(
      () => {
        if (!this.applicationQuery.showAccountMenu) {
          this.router.navigate(['/']);
        } else {
          this.applicationService.closeAnySlideUps();
        }
        this.notificationService.showSuccessMessage(translate('Logout successful'));
        // this.couponService.clearCoupon(); // TODO
      },
      error => {
        // this.couponService.clearCoupon(); // TODO
        // if the revokeToken call fails, clear the userData anyway
        this.accountService.clearUserData();
      }
    );
  }

  navigateToRoute(route: string, isLocked: boolean): void {
    if (route && !isLocked) {
      this.router.navigate([route]);
      this.applicationService.closeAnySlideUps();
    }
  }

  menuItemTrackBy(item: AccountMenuLinkModel): string {
    return item.link;
  }

  ngOnInit(): void {
    this.accountService.initAccountSection();

    this.accountService.updateBalance();
    this.accountService.getBlinkingSessionStatus(this.accountQuery.accessToken).subscribe(res => {
      if (res?.Status === 'pending back office approval') {
        this.pending$.next(true);
      }
      this.accountQuery.userData$.subscribe(user => {
        const accountNumberConfirmed = user?.KYCStatus?.badges?.find(item => item?.Name === 'account_number_confirmed')
        const governmentDocumentConfirmed = user?.KYCStatus?.badges?.find(item => item?.Name === 'government_issued_document_confirmed')

        this.accountNumberConfirmed$.next(accountNumberConfirmed?.Value && governmentDocumentConfirmed?.Value);
        if (user?.standardVerificationPending?.value) {
          this.pending$.next(true);
        }
      })

      this.accountQuery.menuItems$.subscribe(res => {
        const menuItems = res?.filter(item => !(item.link === '/account/verification-choice' && (this.accountNumberConfirmed$.value || this.pending$.value)))
        this.menuItems$.next(menuItems);
      })
    });

    this.loginDate = format(new Date(this.accountQuery.userData.loginDate), 'dd/MM/yyyy HH:mm:ss');


    this.messageCount$ = this.messageQuery.unreadMessagesCount$.pipe(
      filter(c => c > 0),
      map(c => (c > 99 ? 99 : c))
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
