<swiper [config]="config" *ngIf="showBannerRotator$ | async">
  <ng-container *ngFor="let banner of bannerRotatorQuery.bannerDetails$ | async; let i = index; trackBy: bannerDetailTrackBy">
    <ng-template *ngIf="isDisplayedOnRoute(banner)" swiperSlide>
      <div class="item">
        <a *ngIf="!banner.couponCode" [href]="banner.redirectionUrl" [attr.target]="'_parent'"
          ><img [src]="banner.imageUrl" [title]="banner.title" [alt]="banner.title"
        /></a>
        <img
          (click)="clickBannerCouponCode(banner.couponCode)"
          *ngIf="banner.couponCode"
          [category]="'Banner Image'"
          [click]="true"
          [label]="banner.title"
          [alt]="banner.title"
          [src]="banner.imageUrl"
          analytics
        />
      </div>
    </ng-template>
  </ng-container>
</swiper>
