import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AreaModel, MatchModel, RegionModel } from 'src/app/shared/models/sport.model';
import {
  AreaMarketModel,
  AreaMatchModel,
  DayModel,
  MatchByTournamentModel,
  RegionsWithAreasModel,
  SortType,
  SportModel,
  TodaysEventsState
} from 'src/app/shared/models/todays-events.model';

function createInitialState(): TodaysEventsState {
  return {
    areaMatches: undefined,
    regionsWithAreas: undefined,
    areas: undefined,
    regions: undefined,
    sports: undefined,
    tournamentNames: undefined,
    matchesByTournament: undefined,
    matchesByTime: undefined,
    isItMultiline: undefined,
    isItCorrectScores: undefined,
    selectedDay: undefined,
    selectedSport: undefined,
    selectedArea: undefined,
    selectedRegion: undefined,
    selectedMarket: undefined,
    sortType: SortType.ByTournament,
    selectedTournamentName: 'All',
    ui: {
      isEventsLoading: false,
      firstTimeLoadIsInProgress: true
    }
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'todaysEvents' })
export class TodaysEventsStore extends Store<TodaysEventsState> {
  constructor() {
    super(createInitialState());
  }

  updateAreas(areas: AreaModel[]): void {
    this.update({ areas });
  }

  updateRegions(regions: RegionModel[]): void {
    this.update({ regions });
  }

  updateFirstTimeLoadIsInProgress(firstTimeLoadIsInProgress: boolean): void {
    this.updateUI({ firstTimeLoadIsInProgress });
  }

  updateMultilineType(isItMultiline: boolean): void {
    this.update({ isItMultiline });
  }

  updateCorrectScoresType(isItCorrectScores: boolean): void {
    this.update({ isItCorrectScores });
  }

  updateSortType(sortType: SortType): void {
    this.update({ sortType });
  }

  updateSelectedDay(selectedDay: DayModel): void {
    this.update({ selectedDay });
  }

  updateSelectedMarket(selectedMarket: AreaMarketModel): void {
    if (selectedMarket && selectedMarket.selections) {
      const isItMultiline = selectedMarket.oddsType.multilineType === 1 || selectedMarket.selections.length > 3;
      this.update({ selectedMarket, isItMultiline });
    } else {
      this.update({ selectedMarket: undefined, isItMultiline: false });
    }
  }

  updateSelectedRegion(selectedRegion: { region: RegionModel; areas: AreaModel[] }): void {
    this.update({ selectedRegion });
  }

  updateSelectedArea(selectedArea: AreaModel): void {
    this.update({ selectedArea });
  }

  updateRegionsWithAreas(regionsWithAreas: RegionsWithAreasModel[]): void {
    this.update({ regionsWithAreas });
  }

  updateSelectedTournamentName(selectedTournamentName: string): void {
    this.update({ selectedTournamentName });
  }

  updateMatchesByTournament(matchesByTournament: MatchByTournamentModel[]): void {
    this.update({ matchesByTournament });
  }

  updateMatchesByTime(matchesByTime: MatchModel[]): void {
    this.update({ matchesByTime });
  }

  updateAreaMatches(areaMatches: AreaMatchModel[]): void {
    this.update({ areaMatches });
  }

  updateSports(sports: SportModel[]): void {
    this.update({ sports });
  }

  updateSelectedSports(selectedSport: SportModel): void {
    this.update({ selectedSport });
  }

  updateTournamentNames(tournamentNames: string[]): void {
    this.update({ tournamentNames });
  }

  updateUI(ui: Partial<TodaysEventsState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }
}
