import { Injectable } from '@angular/core';

import { EntityStore, StoreConfig } from '@datorama/akita';
import { subWeeks } from 'date-fns';

import { MessageFilterModel, MessageModel, MessagesState } from 'src/app/shared/models/message.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'messages' })
export class MessagesStore extends EntityStore<MessagesState, MessageModel> {
  defaultFilter: MessageFilterModel = undefined;
  defaultState: any;

  constructor() {
    super({
      ui: {
        unreadMessageCount: 0,
        totalMessageCount: 0,
        moreMessagesLoading: false,
        messagesPage: 1,
        filter: {
          filteredState: false,
          dateFrom: subWeeks(new Date(), 1),
          dateTo: new Date(),
          state: -1
        }
      },
      viewingMessage: undefined
    });
    this.defaultFilter = this._value().ui.filter;
    this.defaultState = this._value();
  }

  appendMessages(messages: MessageModel[]): void {
    this.add(messages);
  }

  setMessages(messages: MessageModel[]): void {
    if (messages === undefined) {
      this.remove();
    }
    this.set(messages);
  }

  updateViewingMessage(viewingMessage: MessageModel): void {
    this.update(() => ({
      viewingMessage
    }));
  }

  updateUI(ui: Partial<MessagesState['ui']>): void {
    this.update(state => ({
      ui: {
        ...state.ui,
        ...ui
      }
    }));
  }

  updateFilter(filter: Partial<MessagesState['filter']>): void {
    this.update(state => ({
      ui: {
        filter: {
          ...state.ui.filter,
          ...filter
        }
      }
    }));
  }

  clear(): void {
    this.update(this.defaultState);
  }
}
