import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { VerifyAccountType } from 'src/app/shared/models/account.model';
import { TelephoneExtensionModel } from 'src/app/shared/models/location.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import { LongFormRegistrationModel } from 'src/app/shared/models/registration.model';
import { HelpService } from '../../../../core/services/help.service';

@Component({
  selector: 'app-change-phone-number',
  templateUrl: './change-phone-number.component.html',
  styleUrls: ['./change-phone-number.component.scss']
})
export class ChangePhoneNumberComponent implements OnInit, OnDestroy {
  changeNumberForm: FormGroup;
  showTelExtDropdownArrow = true;
  mobileNumberAlreadyTakenOrInvalid$ = new BehaviorSubject<boolean>(false);
  mobileNumberAlreadyTakenUnconfirmed$ = new BehaviorSubject<boolean>(false);
  mobilePhoneEditability: boolean = this.appConfig.get('account').mobilePhoneEditability;
  verifyData: any;
  verifyAccountType: typeof VerifyAccountType = VerifyAccountType;

  isSubmitting$ = new BehaviorSubject<boolean>(false);

  phoneVerification$ = new BehaviorSubject<boolean>(false);
  alreadyRegistered$ = new BehaviorSubject<boolean>(false);
  completeCallback: Function;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly verifyAccountDataKey = 'verifyAccountData';

  constructor(
    private readonly appConfig: AppConfigService,
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly accountService: AccountService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    
    private readonly localStorage: LocalStorageService,
    readonly registrationQuery: RegistrationQuery,
    readonly applicationService: ApplicationService,
    readonly accountQuery: AccountQuery,
    readonly helpService: HelpService
  ) {}

  ngOnInit(): void {
    this.verifyData = this.localStorage.retrieve(this.verifyAccountDataKey);

    if (!this.mobilePhoneEditability) {
      window.location.href = '/';
      return;
    }

    this.changeNumberForm = this.initialiseForm();
    this.dropdownDisabling();

    this.accountService.updatePhoneOptionTriggered(false);

    if (this.appConfig.get('enableOTPbySms')) {
      this.accountService.getChangePhoneNumberOption().subscribe();
    } else {
      this.accountService.updatePhoneOptionTriggered(true);
    }

    this.registrationService.getTelephoneExtensions().subscribe(() => {
      this.changeNumberForm.controls.mobilePrefix.setValue(
        this.registrationQuery.getTelephoneExtensionFromCode(this.appConfig.get('registration').defaultCountry).prefixNumber
      );
    });
  }

  onCancel(): void {
    this.location.back();
  }

  onSubmit(): void {
    const prefix: string = this.changeNumberForm.controls.mobilePrefix.value;
    const mobile: string = this.changeNumberForm.controls.mobileNumber.value;

    if (!this.accountQuery.userData.mobile || this.accountQuery.userData.mobile.mobileNumber !== prefix + mobile) {
      if (this.changeNumberForm.valid) {
        this.isSubmitting$.next(true);
        this.performChecks(() => {
          if (this.isSubmitting$.value) {
            this.alreadyRegistered$.next(false);

            const user = new LongFormRegistrationModel({
              mobilePrefix: this.changeNumberForm.controls.mobilePrefix.value,
              mobileNumber: this.changeNumberForm.controls.mobileNumber.value
            });

            this.accountService.editPhoneNumber(user).subscribe(() => {
              if (this.accountQuery.changePhoneNumberOption === 'BySms') {
                this.phoneVerification$.next(true);
              } else {
                this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
                this.notificationService.showNotification(
                  new NotificationSettings({
                    allowBackdropClose: true,
                    contentText: translate('Phone number changed successful'),
                    type: 'success',
                    showConfirmButton: true
                  })
                );
              }
            });
          }
        });
      }
    } else {
      this.notificationService.showErrorNotification(
        translate('This number is the same that you already have. Please choose a new phone number'),
        translate('Change Phone Number Failed')
      );
    }
  }

  logout(): void {
    this.accountService.logout().subscribe(
      () => {
        this.router.navigate(['/account/login']);
        this.notificationService.showSuccessMessage(translate('Logout successful'));
      },
      error => {
        // if the revokeToken call fails, clear the userData anyway
        this.accountService.clearUserData();
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  trackByTelExt(index: number, item: TelephoneExtensionModel): string {
    return item.countryCode;
  }

  private performChecks(callback: Function): void {
    this.registrationService
      .checkPhoneNumber(this.changeNumberForm.controls.mobilePrefix.value, this.changeNumberForm.controls.mobileNumber.value)
      .pipe(first())
      .subscribe(
        () => {
          // Phone Number success
          this.mobileNumberAlreadyTakenOrInvalid$.next(false);

          // Checks complete
          callback();
        },
        err => {
          this.mobileNumberAlreadyTakenUnconfirmed$.next(false);
          if (err.error.ResponseCode === 20215) {
            // Mobile Number Already Verified
            this.isSubmitting$.next(false);
            this.mobileNumberAlreadyTakenOrInvalid$.next(true);
            this.notificationService.showErrorNotification(
              translate('Phone Number is already taken or invalid'),
              translate('Change Phone Number Failed')
            );
          } else if (err.error.ResponseCode === 20214 || err.error.ResponseCode === 20216) {
            // Same Mobile For Multiple Accounts OR UnConfirmed Mobile Number AlreadyExists
            this.mobileNumberAlreadyTakenOrInvalid$.next(false);

            if (this.appConfig.get('enableOTPbySms')) {
              this.mobileNumberAlreadyTakenUnconfirmed$.next(true);

              this.completeCallback = callback;
              this.alreadyRegistered$.next(true);

              this.isSubmitting$.next(false);
            } else {
              // Checks complete
              callback();
            }
          } else {
            this.isSubmitting$.next(false);
            this.notificationService.showErrorNotification(
              translate('An error has occurred. Please try again.'),
              translate('Change Phone Number Failed')
            );
          }
        }
      );
  }

  private dropdownDisabling(): void {
    this.registrationQuery.telephoneExtensions$.pipe(takeUntil(this.destroy$)).subscribe(s => {
      if (s === undefined || s.length < 2 || !!this.appConfig.get('registration').disableInternationalNumbers) {
        this.changeNumberForm.controls.mobilePrefix.disable();
        this.showTelExtDropdownArrow = false;
      } else {
        this.changeNumberForm.controls.mobilePrefix.enable();
        this.showTelExtDropdownArrow = true;
      }
    });
  }

  private initialiseForm(): FormGroup {
    return new FormGroup({
      mobilePrefix: new FormControl('', Validators.required),
      mobileNumber: new FormControl('', [Validators.required, Validators.pattern(this.appConfig.get('registration').mobileRegex)])
    });
  }
}
