import { Injectable } from '@angular/core';
import { AccountService } from 'src/app/core/services/account/account.service';
import { WindowService } from 'src/app/core/services/analytics/window.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AnalyticsEventDetails } from 'src/app/shared/models/analytics.model';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsTrackingService {
  private window: any;

  constructor(private readonly windowService: WindowService, private readonly accountQuery: AccountQuery) {
    this.window = windowService.window;
  }

  private pushGTMEvent(data: any) {
    if (data) {
      this.window.dataLayer.push({ ecommerce: null });
      this.window.dataLayer.push(data);
    }
  }

  logPageView(url: string) {
    this.pushGTMEvent({
      event: 'content-view',
      pageName: url
    });
  }

  logGTMEvent(eventData: any, attachUserId: boolean) {
    const data = { ...eventData };

    if (attachUserId && this.accountQuery.userData.id) {
      data.user_id = this.accountQuery.userData.id;
    }

    this.pushGTMEvent(data);
  }

  logMetaEvent(eventName: string, data: any) {
    this.window.fbq('track', eventName, data);
  }
}
